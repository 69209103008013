export const WORKFLOW_STATUS_LIST = [
  {
    name: 'Inbound',
    _id: 'Inbound',
    keys: ['01', '02', '03', '04', '05', '06', '07', '08', '09']
  },
  {
    name: 'Contacted',
    _id: 'Contacted',
    keys: ['11', '12', '13', '14', '15', '16', '17', '18', '19']
  },
  {
    name: 'Tour Scheduled',
    _id: 'Tour Scheduled',
    keys: ['21', '22', '23', '24', '25', '26', '27', '28', '29']
  },
  {
    name: 'Tour Cancelled',
    _id: 'Tour Cancelled',
    keys: ['31', '32', '33', '34', '35', '36', '37', '38', '39']
  },
  {
    name: 'No Show',
    _id: 'No Show',
    keys: ['41', '42', '43', '44', '45', '46', '47', '48', '49']
  },
  {
    name: 'Tour No Show',
    _id: 'Tour No Show',
    keys: ['41', '42', '43', '44', '45', '46', '47', '48', '49']
  },
  {
    name: 'Tour Completed',
    _id: 'Tour Completed',
    keys: ['61', '62', '63', '64', '65', '66', '67', '68', '69']
  },
  {
    name: 'Lost',
    _id: 'Lost',
    keys: ['71', '72', '73', '74', '75', '76', '77', '78', '79']
  },
  {
    name: 'Applied',
    _id: 'Applied',
    keys: ['81', '82', '83', '84', '85', '86', '87', '88', '89']
  }
];

export const MEDIUM_LIST = [
  {
    name: 'Email',
    _id: 'email'
  },
  {
    name: 'SMS',
    _id: 'sms'
  },
  {
    name: 'SMS then Email',
    _id: 'sms-email'
  },
  {
    name: 'Email & SMS',
    _id: 'email&sms'
  }
];
