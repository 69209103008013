import {
  GET_LEADS,
  LOADER_ACTIVATE,
  POST_LEADS_MSG,
  POST_NEW_LEADS_MSG,
  GET_LEAD_BY_PHONE,
  CLEAR_PHONE_REDUCER
} from '../Constant';

const initialState = {
  leadsData: '',
  leadByIdData: '',
  leadsDataLoader: true,
  leadMsg: '',
  newleadMsg: '',
  leadByPhoneData: ''
};
const Leads = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS:
      return { ...state, leadsData: action.data, leadsDataLoader: false };
    case LOADER_ACTIVATE:
      return { ...state, leadsDataLoader: action.data };
    case POST_LEADS_MSG:
      return { ...state, leadMsg: action.data };
    case POST_NEW_LEADS_MSG:
      return { ...state, newleadMsg: action.data };
    case GET_LEAD_BY_PHONE:
      return { ...state, leadByPhoneData: action.data };
    case CLEAR_PHONE_REDUCER:
      return { ...state, leadByPhoneData: action.data };
    default:
      return state;
  }
};

export default Leads;
