import { useMutation, useQuery } from '@apollo/client';
import { Button, Snackbar } from '@material-ui/core';
import {
  CardContent,
  Card,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Alert
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../../Component/Modal';
import { sensitiveInfoUpdate } from '../../../gql/mutations/sensitiveInfoUpdate';
import { LeadInfoById } from '../../../gql/queries/leadInfo';
import { sensitiveLeadInfo } from '../../../gql/queries/sensetiveLeadInfo';
import CopyIcon from '../../../Media/InboxV1/CpoyIcon';
import CrossIcon from '../../../Media/InboxV1/CrossIcon';
import EditIcon from '../../../Media/InboxV1/EditIcon';
import GreenTick from '../../../Media/InboxV1/GreenTick';
import InfoIcon from '../../../Media/InboxV1/InfoIcon';
import WrongPhone from '../../../Media/InboxV1/WrongPhone';

const styles = theme => ({
  multilineColor: {
    color: 'red'
  }
});
const ContactDetailsPopup = ({
  open,
  setOpen,
  name,
  smsEnabled,
  phoneType,
  selectedLeadId,
  refetchLeadDetails
}) => {
  const [editable, setEditable] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [updatedMetaData, setUpdatedMetaData] = useState({ name });
  const [snackBarMessage, setSnackbarMessage] = useState({});

  const { data, error, isLoading, refetch } = useQuery(sensitiveLeadInfo, {
    variables: { leadId: selectedLeadId },
    enabled: false,
    skip: !open
  });
  const sensitiveData = data?.leadById || {};
  const handleChange = e => {
    setUpdatedMetaData({ ...updatedMetaData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (sensitiveData && Object.keys(sensitiveData)?.length) {
      setUpdatedMetaData({ ...sensitiveData, name });
    }
  }, [sensitiveData]);
  useEffect(() => {
    if (open) {
      refetch(selectedLeadId);
    }
  }, [open]);

  const [sensitiveUpdate, { isSuccess }] = useMutation(sensitiveInfoUpdate, {
    refetchQueries: [
      { query: LeadInfoById, variables: { leadId: selectedLeadId } }
    ]
  });

  const saveNewLeadDetails = () => {
    const { name, email, phone } = updatedMetaData;
    sensitiveUpdate({
      variables: {
        leadId: selectedLeadId,
        record: { name, email, phone }
      }
    });
  };
  return (
    <>
      {isSuccess && (
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setSnackBarOpen(false);
          }}
          action={'Success'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={snackBarMessage.type}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      )}
      <CustomModal onClose={() => setOpen(false)} open={open}>
        <Card style={{ height: '300px', width: '350px' }}>
          <CardContent>
            <div>
              <div className="flex">
                <div style={{ fontWeight: '500', color: '#525F80' }}>
                  Personal Details
                </div>
                <div style={{ position: 'relative', left: '60px' }}>
                  {!editable ? (
                    <EditIcon
                      style={{
                        width: '24px',
                        height: '24px',
                        color: '#525F80'
                      }}
                      onClick={() => setEditable(true)}
                    />
                  ) : null}
                </div>
                <div>
                  <CrossIcon
                    style={{
                      cursor: 'pointer',
                      width: '24px',
                      height: '24px',
                      color: '#525F80'
                    }}
                    onClick={() => {
                      setOpen(false);
                      setEditable(false);
                    }}
                  />
                </div>
              </div>
              {editable ? (
                <>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      sx={{ input: { color: '#393939', fontSize: '14px' } }}
                      placeholder="Name"
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={updatedMetaData?.name || ''}
                      onChange={handleChange}
                      style={{ marginTop: '20px' }}
                    />
                  </div>
                  <div className=" " style={{ display: 'flex' }}>
                    <TextField
                      sx={{ input: { color: '#393939', fontSize: '14px' } }}
                      placeholder="Phone"
                      name="phone"
                      label="Phone"
                      fullWidth
                      value={updatedMetaData?.phone || ''}
                      variant="outlined"
                      onChange={handleChange}
                      style={{ marginTop: '10px' }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      sx={{ input: { color: '#393939', fontSize: '14px' } }}
                      placeholder="E-mail"
                      label="E-mail"
                      name="email"
                      fullWidth
                      value={updatedMetaData?.email || ''}
                      variant={'outlined'}
                      onChange={handleChange}
                      style={{ marginTop: '10px' }}
                    />
                  </div>
                  <div className="">
                    <Button
                      variant="contained"
                      style={{
                        textTransform: 'capitalize',
                        background: '#5267FF'
                      }}
                      onClick={() => {
                        //   setOpen(false);
                        saveNewLeadDetails(
                          {
                            ...updatedMetaData
                          },
                          true
                        );
                        setOpen(false);
                        setSnackBarOpen(true);
                        setEditable(false);
                      }}
                    >
                      <span style={{ color: '#FFFFFF' }}>Save</span>
                    </Button>
                    <Button
                      variant="text"
                      style={{ textTransform: 'capitalize' }}
                      onClick={() => {
                        setEditable(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="mt-10 mb-10"
                    style={{ display: 'flex', marginTop: '20px' }}
                  >
                    <Typography className="sub-container-key">Name:</Typography>
                    <Typography className="sub-container-value truncate">
                      <span style={{ color: '#393939', fontWeight: '400' }}>
                        {' '}
                        {name || ''}
                      </span>
                    </Typography>
                  </div>
                  <div
                    className="mt-10 mb-10"
                    style={{ display: 'flex', marginTop: '23px' }}
                  >
                    <Typography className="sub-container-key">
                      Phone number:{' '}
                      <span style={{ fontWeight: '400' }}>
                        {updatedMetaData?.phone || ''}
                      </span>
                    </Typography>

                    {smsEnabled ? (
                      <GreenTick
                        style={{ marginLeft: '10px', marginTop: '-1px' }}
                      />
                    ) : (
                      <WrongPhone
                        style={{ marginLeft: '10px', marginTop: '-1px' }}
                      />
                    )}
                    <Tooltip
                      enterDelay={500}
                      leaveDelay={200}
                      title={'Phone-Type: ' + phoneType || 'Not-available'}
                    >
                      <IconButton sx={{ padding: '0px', marginTop: '-6px' }}>
                        <InfoIcon
                          style={{
                            marginLeft: '10px',
                            width: '24px',
                            height: '24px',
                            background: '#ffffff',
                            color: '#525F80'
                          }}
                          onMouseOver={() => {
                            // handleTooltipOpen();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <CopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          updatedMetaData?.phone || ''
                        );
                      }}
                      style={{
                        position: 'relative',
                        left: '10px',
                        bottom: '2px',
                        color: '#525F80'
                      }}
                    />
                  </div>
                  <div> </div>
                  <div
                    className="mt-10 "
                    style={{ display: 'flex', marginTop: '20px' }}
                  >
                    <Typography className="sub-container-key">
                      Email:
                    </Typography>
                    <Typography className="sub-container-value truncate">
                      <span style={{ color: '#393939', fontWeight: '400' }}>
                        {updatedMetaData?.email || ''}
                      </span>
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </CustomModal>
    </>
  );
};

export default ContactDetailsPopup;
