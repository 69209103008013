import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import './styles.scss';
import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';
import CloseButton from '../../../Media/CloseButton.svg';
import SourceTag from '../../../Media/SourceTag.svg';

const TemplateRecommendation = props => {
  const { setMessageInput } = props;
  const {
    templateRecommendation,
    closeTemplateRecommendation,
    selectTemplateRecommendation
  } = useInboxContext();

  const cssClasses = recommendation => {
    const classes = ['templates-container'];

    if (recommendation.closed) {
      classes.push('fade-out-down');
    } else {
      classes.push('fade-in-up');
    }
    return classes.join(' ');
  };

  const truncatedSource = text => {
    if (text && text.length > 30) {
      return text.substring(0, 30) + '...';
    }
    return text;
  };

  const handleButtonClick = () => {
    selectTemplateRecommendation({
      setMessageInput
    });
  };

  const handleCloseButtonClick = () => {
    if (!templateRecommendation.closed) {
      closeTemplateRecommendation();
    }
  };

  return (
    <>
      {templateRecommendation && templateRecommendation.templateText && (
        <div className={cssClasses(templateRecommendation)}>
          <div
            className="template-close-button"
            onClick={() => handleCloseButtonClick()}
          >
            <img src={CloseButton} alt="Close Recommendation Popup" />
          </div>
          <div className="template-recommendation-header">
            <div className="template-header-title">Suggested Answer</div>
            <div className="template-source">
              <img src={SourceTag} alt="Template Source Tag" />
              <div className="template-source-text">
                <Tooltip
                  title={
                    <React.Fragment>
                      <span>{templateRecommendation?.source ?? 'Default'}</span>
                    </React.Fragment>
                  }
                  placement="top-start"
                >
                  <span>
                    {truncatedSource(templateRecommendation?.source) ??
                      'Default'}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div
              className={`template-button ${
                templateRecommendation.used ? 'pressed' : ''
              }`}
              onClick={() => handleButtonClick()}
            >
              {templateRecommendation.used
                ? 'Template Used'
                : 'Press Tab to Use'}
            </div>
          </div>
          <div className="template-recommendation-text">
            {templateRecommendation.templateText}
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateRecommendation;
