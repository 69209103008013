import React from 'react';
import Field from '../../Field';
import { Fields } from '../InfoContainer/info-container-styles';

const TourDetailsInfo = ({ sessionData }) => {
  const getTourName = tour => {
    if (tour === 'inperson') {
      return 'In-person';
    } else if (tour === 'virtual') {
      return 'Virtual';
    } else if (tour === 'selfguided') {
      return 'Self-guided';
    } else {
      return 'N/A';
    }
  };

  const slots = sessionData?.slots;
  const tourType = sessionData?.preferred_tour_type;
  return (
    <Fields>
      <Field
        isRequired
        label="Date"
        value={{ from: slots?.date || 'N/A' }}
        date
      />
      <Field
        isRequired
        label="Time"
        value={{ from: slots?.time || 'N/A' }}
        time
      />
      <Field
        label="Tour type"
        value={(tourType && getTourName(tourType)) || 'N/A'}
        isRequired
      />
    </Fields>
  );
};

export default TourDetailsInfo;
