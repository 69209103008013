const { default: styled } = require('styled-components');

const UnansweredQueriesList = styled.ul`
  flex-grow: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const EmptyState = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme?.text?.primary};
  margin: 10px 0 0 0;
`;

const SectionHeader = styled.div`
  margin-bottom: 10px;
  position: relative;
`;

export { UnansweredQueriesList, EmptyState, SectionHeader };
