import React from 'react';
import { BadgeWrapper, IconWrapper, InnerText } from './badge.styles';
import { Tooltip } from '@mui/material';

const Badge = ({
  children,
  color, // String: color code
  background, // String: color code
  border, // String
  borderRadius, // String
  fontSize, // String
  lineHeight, // String
  startAdornment, //JSX Element
  endAdornment, // JSX Element
  items = [], // Array of strings,
  maxWidth,
  padding = '3px 6px',
  tooltipJsx,
  onClick
}) => {
  const tooltipText = tooltipJsx ? tooltipJsx : items.join(', ');
  return (
    <Tooltip title={tooltipText}>
      <BadgeWrapper
        border={border}
        borderRadius={borderRadius}
        background={background}
        maxWidth={maxWidth}
        padding={padding}
        onClick={onClick}
      >
        {startAdornment && <IconWrapper>{startAdornment}</IconWrapper>}
        <InnerText fontSize={fontSize} lineHeight={lineHeight} color={color}>
          {children}
        </InnerText>
        {endAdornment && <IconWrapper>{endAdornment}</IconWrapper>}
      </BadgeWrapper>
    </Tooltip>
  );
};

export default Badge;
