import React, { useContext, useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ALL_TIME_ZONES } from '../../property/Constants';
import RefreshIcon from '../../../Media/SelfServe/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
  downloadCsv,
  getTenantDetails,
  ingestCommunity,
  ingestCommunityGet,
  onBoardCommunities as onBoardCommunitiesAction,
  uploadFiles
} from '../../../Redux/Actions/SelfServe';
import {
  addCommunityId,
  addCommunityName,
  addCommunityWebsite,
  addCompanyCode,
  addKnockRelatedDetails,
  addPropertyCode,
  addRentcafeApiToken,
  addSelectedAppartment,
  addTimeZone,
  listingCommunityNotification,
  openCommunity,
  removeSelectedAppartment
} from '../../../Redux/Reducer/SelfServe';
import Grid from '../../../Component/Common/CommunityGrid';
import CommunityInformation from './communityInformation';
import UploadTemplates from './uploadTemplates';
import UploadToCloudIcon from '../../../Media/Upload';
import InfinityLoaderIcon from '../../../Media/InfinityLoader';
import styles from './styles.module.scss';
import { CSVLink } from 'react-csv';
import { workingHours as hardCodedWorkingHours } from '../utils';
import { InputAdornment } from '@mui/material';
import { hasIn } from 'lodash';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';

const Communities = ({ tenantId, crmSettings }) => {
  const adminData = useSelector(state => state.Admin);
  const selfserve = useSelector(state => state.SelfServe);
  const integrationSettings = selfserve?.userData?.data?.integrationSettings;
  const enableKnockCrm = selfserve?.userData?.data?.enableKnockCRM;
  const pmsValue = integrationSettings && integrationSettings?.pms;
  const pmcId = integrationSettings && integrationSettings?.pmcId;
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [appartmentId, setAppartmentId] = useState('');
  const [workingHours, setworkingHours] = useState(null);
  const [selectedView, setSelectedView] = useState(false);
  const [cheatSheetUploaded, setCheatSheetUploaded] = useState(true);
  const [onboardNotification, setOnboardNotification] = useState({
    upload: false,
    success: false,
    msg: ''
  });
  const [fileData, setFileData] = useState(null);
  const [onboardedCommunites, setonboardedCommunites] = useState([]);
  const { appartmentData, selectedApprtment } = selfserve;
  const [template, setTemplate] = useState('');
  const [upload, setUpload] = useState(false);
  const [info, infoPopUPHandler] = useState(false);
  const uploadEvent = useRef(null);
  const [searchVal, setSearchVal] = useState('');
  const [showFilteredApartment, setShowFilteredApartment] =
    useState(appartmentData);
  const { userData } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const handleChange = event => {
    setTemplate(event.target.value);
  };
  const handleChangeSearch = value => {
    setSearchVal(value);
  };
  const gridClickHandler = (
    id,
    communityName,
    timeZone,
    workingHours,
    cmnId,
    kelseyId,
    houseId,
    CompanyCode,
    PropertyCode,
    rentcafeApiV2Token,
    website
  ) => {
    dispatch(openCommunity());

    if (website) {
      dispatch(addCommunityWebsite(website));
    }
    if (communityName) {
      dispatch(addCommunityName(communityName));
    }
    if (timeZone) {
      dispatch(addTimeZone({ value: timeZone, disable: true }));
    }
    if (workingHours) {
      if (workingHours.length === 0) {
        setworkingHours(hardCodedWorkingHours);
      } else {
        setworkingHours(workingHours);
      }
    }
    if (PropertyCode) {
      dispatch(addPropertyCode({ value: PropertyCode, disable: true }));
    }
    if (CompanyCode) {
      dispatch(addCompanyCode({ value: CompanyCode, disable: true }));
    }
    if (rentcafeApiV2Token) {
      dispatch(
        addRentcafeApiToken({ value: rentcafeApiV2Token, disable: true })
      );
    }
    if (cmnId && kelseyId && houseId) {
      dispatch(
        addKnockRelatedDetails({
          knockId: cmnId,
          kelsey: kelseyId,
          house: houseId
        })
      );
    }
    setAppartmentId(id);
    infoPopUPHandler(true);
  };

  const ingestCommunitiesOnLoad = onboardNotification => {
    if (tenantId) {
      if (pmsValue === 'yardi') {
        const {
          yardiUrl,
          yardiId,
          userName,
          password,
          database,
          platform,
          pms
        } = integrationSettings;
        const payload = {
          pms: 'yardi',
          payload: {
            _id: tenantId,
            name: selfserve?.userData?.data?.name,
            integrationSettings: {
              yardiUrl,
              yardiId,
              userName,
              password,
              database,
              platform,
              pms
            }
          }
        };
        dispatch(ingestCommunity(payload));
      }
      if (pmsValue === 'entrata') {
        const payload = {
          pms: 'entrata',
          payload: {
            _id: tenantId,
            pmcid: pmcId
          }
        };
        dispatch(ingestCommunity(payload));
      }

      if (pmsValue === 'realpage') {
        const payload = {
          pms: 'realpage',
          payload: {
            _id: tenantId,
            pmcid: pmcId
          }
        };
        dispatch(ingestCommunity(payload));
      }
    }
    if (onboardNotification?.success) {
      setOnboardNotification(onboardNotification);
    }
    dispatch(ingestCommunityGet(tenantId));
  };
  const handleFileUpload = e => {
    e.preventDefault();
    const { files } = e.target;

    setFileData(files[0]);
  };

  const csvHeaders = [
    { label: 'Intent', key: 'intent' },
    { label: 'Questions', key: 'key' }
  ];

  const uploadNotification = status => {
    setUploadSuccess(status);
  };

  const uploadFileToServer = () => {
    const formData = new FormData();
    formData.append('fileImage', fileData);
    dispatch(
      uploadFiles({
        payload: formData,
        uploadNotification: uploadNotification
      })
    );
  };

  useEffect(() => {
    if (appartmentData && appartmentData.length) {
      setShowFilteredApartment([...appartmentData]);
    }
  }, [appartmentData]);
  useEffect(() => {
    if (searchVal) {
      const updatedData = appartmentData.filter(
        ({ name }) =>
          name?.includes(searchVal) || name?.toLowerCase().includes(searchVal)
      );
      setShowFilteredApartment([...updatedData]);
    } else {
      setShowFilteredApartment([...appartmentData]);
    }
  }, [searchVal]);
  useEffect(() => {
    if (fileData) {
      uploadFileToServer();
    }
  }, [fileData]);
  useEffect(() => {
    if (adminData?.users?.data) {
      dispatch(getTenantDetails(tenantId));
    }
  }, [adminData?.users]);

  useEffect(() => {
    if (uploadSuccess) {
      let timer1 = setTimeout(() => setUploadSuccess(''), 4000);
      ingestCommunitiesOnLoad();
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (tenantId) {
      dispatch(ingestCommunityGet(tenantId));
      dispatch(downloadCsv({ params: tenantId }));
    }
  }, [userData]);

  const closeNotification = () => {
    if (onboardNotification?.upload) {
      setOnboardNotification(prev => ({
        ...prev,
        success: false,
        upload: false
      }));
    } else {
      dispatch(listingCommunityNotification());
    }
    setCheatSheetUploaded(true);
  };

  const uploadEventHandler = () => {
    uploadEvent.current.click();
  };

  const onBoardCommunities = data => {
    setonboardedCommunites(prev => {
      const filter = appartmentData.findIndex(
        item => item?._id === data && item?.tenantId.toString() === tenantId
      );
      dispatch(addSelectedAppartment(appartmentData[filter]));
      return [...prev, data];
    });
  };
  const removeOnboard = data => {
    setonboardedCommunites(prev => {
      const updatedData = prev.filter(item => item !== data);
      const filter = selectedApprtment?.filter(
        item => item?._id !== data && item?.tenantId.toString() === tenantId
      );
      dispatch(removeSelectedAppartment(filter));
      return updatedData;
    });
  };

  const handleOnboard = () => {
    const payload = {
      apartmentIds: onboardedCommunites
    };
    dispatch(
      onBoardCommunitiesAction({
        payload: payload,
        ingestCommunitiesOnLoad: ingestCommunitiesOnLoad
      })
    );
  };
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#4CAF50',
      '&:hover': {
        backgroundColor: '#4CAF50'
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50'
    }
  }));
  const integrationStatus =
    showFilteredApartment &&
    showFilteredApartment?.filter(item => item.cheatSheet?.length);
  const updateUiForSelectedView = selectedView
    ? selectedApprtment
    : showFilteredApartment;
  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.search}>
          {' '}
          <TextField
            onChange={e => handleChangeSearch(e.target.value)}
            placeholder="Search anything"
            variant="outlined"
            value={searchVal}
            InputProps={{
              type: 'search',
              style: {
                borderRadius: '4px',
                padding: '4px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#a2a2a2' }} />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.rightSection}>
          <div
            onClick={() => ingestCommunitiesOnLoad()}
            className={styles.refreshCont}
          >
            <RefreshIcon />
          </div>
          {(!selfserve?.pendingUpload || !uploadSuccess) &&
          integrationStatus?.length ? (
            <>
              <button className={styles.stdButton} onClick={handleOnboard}>
                Onboard
              </button>
            </>
          ) : (
            ''
          )}

          {uploadSuccess && (
            <div className={styles.uploadStatus}>
              <span className={styles.uploadingText}>{uploadSuccess}</span>
              <InfinityLoaderIcon />
            </div>
          )}
          {selfserve?.pendingUpload && (
            <div className={styles.uploadStatus}>
              <span className={styles.uploadingText}>Uploading Cheatsheet</span>
              <UploadToCloudIcon />
            </div>
          )}

          <div className={styles.selectContainer}>
            <FormControl fullWidth>
              <TextField
                select
                id="key"
                value={template}
                label="Cheatsheet"
                onChange={handleChange}
                size="small"
              >
                {selfserve?.downloadCsv?.data ? (
                  <MenuItem>
                    <CSVLink
                      style={{ color: '#393939', textDecoration: 'none' }}
                      headers={csvHeaders}
                      data={selfserve?.downloadCsv?.data}
                    >
                      Download
                    </CSVLink>
                  </MenuItem>
                ) : (
                  <MenuItem>Download</MenuItem>
                )}
                <MenuItem onClick={uploadEventHandler}>Upload</MenuItem>
              </TextField>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        {updateUiForSelectedView && updateUiForSelectedView.length ? (
          updateUiForSelectedView?.map(item => {
            return (
              <Grid
                key={item?._id}
                apptId={item?._id}
                header={item.name}
                address={item.addressId}
                timeLabel={item.createdAt}
                integrations={integrationStatus}
                error={
                  // !item?.timeZone ||
                  // (enableKnockCrm &&
                  //   !(
                  //     item?.meta?.knockCommunity?.communityId &&
                  //     item?.meta?.knockKelseyUserName &&
                  //     item?.meta?.knockHouseAgentUserName
                  //   )) ||
                  // (!enableKnockCrm &&
                  //   pmsValue === 'yardi' &&
                  //   (!item?.leasingAgents ||
                  //     !item?.leasingAgents?.Kelsey?.username ||
                  //     !item?.meta?.PropertyCode))
                  false
                }
                clickHandler={() =>
                  gridClickHandler(
                    item?._id,
                    item?.name,
                    item?.timeZone
                      ? {
                          label: ALL_TIME_ZONES[item.timeZone],
                          value: item.timeZone
                        }
                      : false,
                    item.workingHours,
                    item?.meta?.knockCommunity?.communityId || false,
                    item?.meta?.knockKelseyUserName || false,
                    item?.meta?.knockHouseAgentUserName || false,
                    integrationSettings?.CompanyCode || false,
                    item?.meta?.PropertyCode || false,
                    integrationSettings?.rentcafeApiV2Token || false,
                    item?.website
                  )
                }
                onBoardCommunities={onBoardCommunities}
                removeOnboard={removeOnboard}
                timeZone={item?.timeZone}
                setCheatSheetUploaded={setCheatSheetUploaded}
                cheatSheetUploaded={cheatSheetUploaded}
              />
            );
          })
        ) : (
          <p>No Data Available</p>
        )}
      </div>
      <CommunityInformation
        tenantId={tenantId}
        crmSettings={crmSettings}
        appartmentId={appartmentId}
        workingHours={workingHours}
        appartmentData={appartmentData}
        info={info}
        infoPopUPHandler={infoPopUPHandler}
        ingestCommunitiesOnLoad={ingestCommunitiesOnLoad}
      />
      {(!selfserve.listingCommunity ||
        !selfserve.uploadFiles ||
        !cheatSheetUploaded ||
        onboardNotification?.upload) && (
        <div
          className={
            onboardNotification?.success
              ? `${styles.snackbarCont} ${styles.successSnakc}`
              : styles.snackbarCont
          }
        >
          <Snackbar
            autoHideDuration={4000}
            open={
              !selfserve.listingCommunity ||
              onboardNotification?.upload ||
              !cheatSheetUploaded
            }
            onClose={closeNotification}
            message={
              onboardNotification?.upload && onboardNotification?.success
                ? 'onboarded successfully'
                : onboardNotification?.upload
                ? 'upload failed'
                : !cheatSheetUploaded
                ? 'please upload cheatsheet to onboard'
                : 'something went wrong'
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </div>
      )}

      <input
        ref={uploadEvent}
        className={styles.hidden}
        multiple={false}
        type="file"
        onChange={handleFileUpload}
      />
      <UploadTemplates upload={upload} setUpload={setUpload} />
    </div>
  );
};

export default Communities;
