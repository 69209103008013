import { Button, Card, CardContent } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../Component/Modal';
import CrossIcon from '../../../Media/InboxV1/CrossIcon';
import SecurityIcon from '../../../Media/InboxV1/SecurityIcon';

const ConsentModal = ({
  open,
  setOpen,
  setEditPopup,
  selectedLeadId,
  leadUpdateMutate
}) => {
  const dispatch = useDispatch();

  const handleSave = () => {
    leadUpdateMutate({
      variables: {
        leadId: selectedLeadId,
        record: { disablePrivacy: 'Accessed.' }
      }
    });

    setOpen(false);
    setEditPopup(true);
  };
  return (
    <CustomModal open={open} onClose={() => setOpen(false)}>
      <Card style={{ width: '338px' }}>
        <CardContent>
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <CrossIcon
              style={{
                position: 'absolute',
                right: '0',
                width: '24px',
                height: '24px',
                cursor: 'pointer'
              }}
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="flex-jst-alg-center">
            <SecurityIcon />
          </div>
          <div
            className="inter-600px-16px color-393939"
            style={{ textAlign: 'center', margin: '35px 0px' }}
          >
            Contains sensitive info. of lead.
            <span
              style={{ display: 'block', marginTop: '10px' }}
              className="text-color-red inter-12px-400"
            >
              Do you really want to access this information ?
            </span>
          </div>
          <div className="flex-jst-alg-center">
            <Button
              variant="contained"
              style={{ textTransform: 'capitalize', background: '#5267FF' }}
              onClick={() => {
                handleSave();
              }}
            >
              Yes
            </Button>
            <Button
              variant="text"
              style={{ textTransform: 'capitalize', color: '#393939' }}
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        </CardContent>
      </Card>
    </CustomModal>
  );
};

export default ConsentModal;
