import React, { useState } from 'react';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import DateInput from '../../../Component/DateInput';

import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import Button from '../../../globalcomponents/Button';

import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';
import {
  FlexContainer,
  HeaderOptions,
  HeaderTitle,
  MessageBody,
  MessageHeader,
  MessagesContainer,
  ScheduledMessageContainer
} from './styles.schudledmessages';
import { dummyMessages } from './dummyMessages';

import { msgIcon } from '../../../styles/Inboxstyle';
import Icon from '../../../globalcomponents/Icon';
import { Tooltip } from '@material-ui/core';
import { SingleMessage } from './SingleMessage';
import moment from 'moment';

const ScheduledMessagesPopup = ({ show, onClose, apartmentTimezone }) => {
  const { scheduledMessages, setScheduledMessages } = useInboxContext();
  const [buttonState, setButtonState] = useState('initial');
  const [date, setDate] = useState(new Date());

  const formattedMessages =
    scheduledMessages?.length &&
    scheduledMessages.map((message, index) => {
      return {
        id: message?._id,
        scheduledFor: moment
          .tz(message?.scheduledData?.scheduledAt, apartmentTimezone)
          .format('MMM D, YYYY h:mm A z'),
        message: message?.body,
        scheduledBy: message?.scheduledData?.scheduledBy?.name,
        createdAt: moment
          .tz(message?.timeStamp, apartmentTimezone)
          .format('MMM D, YYYY h:mm A z')
      };
    });
  const onSave = () => {
    setScheduledMessages(1);
    setButtonState('inprogress');
    setTimeout(() => {
      setButtonState('completed');
    }, 2000);
    setTimeout(() => {
      setButtonState('initial');
    }, 3000);
  };

  const customBackdropStyling = {
    top: '-67px',
    left:
      window && window.location.href.includes('inbox/searchlead')
        ? '-86px'
        : '-281px',
    width: '100vw',
    height: '100vh'
  };

  return show ? (
    <Popup
      show={show}
      onClose={onClose}
      width={'450px'}
      customBackdropStyling={customBackdropStyling}
    >
      <PopupTitle onClose={onClose} showCloseBtn>
        Scheduled Messages
      </PopupTitle>

      <PopupBody>
        <MessagesContainer>
          {formattedMessages?.length > 0 &&
            formattedMessages?.map((message, index) => (
              <SingleMessage message={message} key={index} />
            ))}
        </MessagesContainer>
      </PopupBody>
      <PopupFooter padding="0 20px 20px 20px" justifyContent=""></PopupFooter>
    </Popup>
  ) : null;
};

export default ScheduledMessagesPopup;
