//mtable
export const tableStyle = {
  boxShadow: '0px 1px 0px #e6e6e6',
  marginTop: '20px',
  marginRight: '20px',
  position: 'relative',
  zIndex: 0
};

//wcontent
export const navStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  margin: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};
export const MainColor = {
  color: '#20065f'
};
export const fadeColor = {
  color: '#828282'
};

//Wnaviagtion
export const CardStyle = {
  width: '300px',
  height: '100%',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  marginRight: '10px',
  position: 'fixed',
  marginTop: '-6px',
  zIndex: 1
};
export const GldivStyle = {
  padding: '14px 0px 14px 30px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '600',
  borderBottom: '1px solid #e2e1e1',
  cursor: 'pointer'
};

//wtable

export const tableStyle1 = {
  boxShadow: '0px 1px 0px #e6e6e6',
  marginTop: '20px',
  marginRight: '20px',
  position: 'relative',
  zIndex: 0
};

//RulesTab

export const tabContentStyle = {
  color: '#ffffff',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '400',
  paddingTop: '11px'
};
export const commonStyleDiv = {
  width: '187px',
  height: '40px',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '5px'
};
