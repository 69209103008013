import { gql } from '@apollo/client';

export const userUpdateById = gql`
  mutation updateOneUser($_id: MongoID!, $record: JSON!) {
    updateOneUser(userId: $_id, record: $record) {
      name
      userAccess {
        clients {
          trained {
            _id
            name
          }
          assigned {
            _id
            name
          }
        }
      }
      uid
      _id
      email
      userType
    }
  }
`;

export const updateUserNotes = gql`
  mutation updateOneUser($_id: MongoID!, $record: JSON!) {
    updateOneUser(userId: $_id, record: $record) {
      notes
    }
  }
`;

export const updateAgentStatus = gql`
  mutation updateAgentStatus($agentId: ID!, $status: String!) {
    updateAgentStatus(agentId: $agentId, status: $status) {
      _id
      agentId
      status
    }
  }
`;
