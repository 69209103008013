import { GET_ALL_APPLIANCES } from '../Constant';

const initialState = {
  AppliancesData: ' '
};
const Appliances = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_APPLIANCES:
      return { ...state, AppliancesData: action.data };
    default:
      return state;
  }
};

export default Appliances;
