import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DummyProperty from '../../Media/DummyProperty.png';
import UnitIcon from '../../Media/UnitIcon.png';
import BedroomIcon from '../../Media/BedroomIcon.png';
import BathRoomIcon from '../../Media/BathroomIcon.png';
import DoughnutChart from './DoughnutChart';
import { DeletePropertyAction } from '../../Redux/Actions/Property';
import DelIcon from '../../Media/DelCardIcon.png';
import EditIcon from '../../Media/EditCardIcon.png';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ListModal from './modal/ListModal';
import MoneyIcon from '../../Media/MoneyIcon.png';
import ConfirmModal from './modal/ConfirmModal';
import {
  cardStyle,
  CardContentStyle,
  dataCnt,
  infoCont,
  infoContData,
  HeadingStyle,
  popertyHeading,
  AddressStyle,
  cardFooter,
  ContentStyle,
  BlockContent,
  BlockContentIconsStyle,
  editIcon,
  deleteIcon,
  DivStyle,
  heroImage,
  listedStyle,
  draft,
  lstbtnStyle,
  drbtnStyle,
  toggleContainer
} from '../../styles/propertystyles/Propertystyle';

let _ = require('lodash');

const PropertyCard = props => {
  const [Status, setStatus] = useState(props.status);
  const [open, setOpen] = useState(false);
  const [openConf, setOpenConf] = useState(false);

  const dispatch = useDispatch();

  const openCraigslist = () => {
    props.history.push(`/property/edit/${props.id}`);
  };
  const deleteProperty = () => {
    dispatch(DeletePropertyAction(props.id));
  };
  const handleStatus = (event, newStatus) => {
    if (newStatus !== null) {
      setOpen(true);
      setStatus(newStatus);
    }
  };
  return (
    <div>
      <ConfirmModal
        open={openConf}
        setOpen={setOpenConf}
        proceed={deleteProperty}
        propVal={'delete'}
        Msg={
          'ARE YOU SURE YOU WANT TO DELETE THIS LISTING ? TYPE LISTING NAME TO CONFIRM'
        }
        propName={props.name}
      />
      <ListModal
        open={open}
        setOpen={setOpen}
        status={Status}
        setStatus={setStatus}
        id={props.id}
      />
      <Card style={cardStyle}>
        <CardContent style={CardContentStyle}>
          <div>
            <div style={heroImage}>
              <img
                style={heroImage}
                src={
                  props.heroImage
                    ? props.heroImage[0] === null ||
                      props.heroImage[0] === undefined
                      ? DummyProperty
                      : props.heroImage[0].name
                    : DummyProperty
                }
                alt="ProperyImage"
              />
            </div>
            <div style={HeadingStyle}>
              <div style={popertyHeading}>
                {props.name ? props.name : 'N/A'}
              </div>
              <div style={AddressStyle}>
                {props.address ? props.address : 'N/A'}
              </div>
            </div>
            <div style={DivStyle}>
              <img
                src={EditIcon}
                alt="EditIcon"
                style={editIcon}
                onClick={() => openCraigslist()}
              />
              <img
                src={DelIcon}
                alt="DelIcon"
                style={deleteIcon}
                onClick={() => setOpenConf(true)}
              />
            </div>
            <div style={toggleContainer}>
              <ToggleButtonGroup
                value={Status}
                size="medium"
                exclusive
                onChange={handleStatus}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="listed"
                  size="medium"
                  style={
                    Status === 'listed'
                      ? { ...lstbtnStyle, ...listedStyle }
                      : { ...draft, ...lstbtnStyle }
                  }
                >
                  listed
                </ToggleButton>
                <ToggleButton
                  value="draft"
                  size="medium"
                  style={
                    Status === 'draft'
                      ? { ...drbtnStyle, ...listedStyle }
                      : { ...draft, ...drbtnStyle }
                  }
                >
                  Unlisted
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div style={ContentStyle}>
            <div style={infoCont}>
              <div style={BlockContent}>
                <div>
                  <img
                    src={UnitIcon}
                    alt="UnitIcon"
                    style={BlockContentIconsStyle}
                  />
                </div>
                <div>
                  <div>Unit Name</div>
                  <div style={infoContData}>
                    {props.UnitName ? props.UnitName : 'N/A'}
                  </div>
                </div>
              </div>

              <div style={BlockContent}>
                <div>
                  <img
                    src={BedroomIcon}
                    alt="BedroomIcon"
                    style={BlockContentIconsStyle}
                  />
                </div>
                <div>
                  <div>Bedrooms</div>
                  <div style={infoContData}>
                    {props.Bedrooms ? props.Bedrooms : 'N/A'}
                  </div>
                </div>
              </div>

              <div style={BlockContent}>
                <div>
                  <img
                    src={BathRoomIcon}
                    alt="BathRoomIcon"
                    style={BlockContentIconsStyle}
                  />
                </div>
                <div>
                  <div>Bathrooms</div>
                  <div style={infoContData}>
                    {props.Bathrooms ? props.Bathrooms : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
            <div style={dataCnt}>
              <div style={{ marginBottom: '8px' }}>750 Views</div>
              <div>
                <DoughnutChart />
              </div>
            </div>
          </div>

          <div style={cardFooter}>
            <div style={{ display: 'flex', width: '100%' }}>
              <img
                src={MoneyIcon}
                alt="MoneyIcon"
                style={{ marginRight: '10px' }}
              />
              <div>Monthly Rent</div>
            </div>
            <div style={{ fontSize: '16px', fontWeight: 700 }}>
              ${props.MonthlyRent ? props.MonthlyRent : '0'}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PropertyCard;
