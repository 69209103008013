import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialogContainer2: props => ({
    display: 'flex',
    gap: '10px',
    marginTop: '20px'
  }),
  dialogContainer: {
    display: 'flex',
    gap: '10px'
  },
  dialogContainer3: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '70px',
    marginLeft: '60px',
    marginRight: '60px',
    flexDirection: 'column'
  },
  inputRoot: props => ({
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: theme.spacing(0.5),
      border: `0.5px solid ${props.border.primary}`,
      color: props.text.primary
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: '#3f51b5'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: props?.text?.placeholder
    }
  }),
  autoCompleteRoot: props => ({
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        padding: theme.spacing(0.5),
        border: `0.5px solid ${props.border.primary}`
      },
    '&.MuiAutocomplete-root.MuiAutocomplete-input.Mui-disabled': {
      color: props.text.primary,
      opacity: 0.5
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      WebkitTextFillColor: props?.text?.primary
    }
  }),
  labelRoot: props => ({
    '&.MuiTypography-root': {
      paddingBottom: theme.spacing(1),
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: theme.spacing(2),
      color: props.text.primary
    }
  }),
  calendarContainer: props => ({
    padding: '10px',
    background: props.background.gradient,
    display: 'flex',
    borderRadius: '6px 6px 0 0'
  }),
  customDatePicker: props => ({
    padding: '8px',
    background: 'transparent',
    color: props.text.primary,
    borderRadius: theme.spacing(0.5),
    border: `0.5px solid ${props.border.primary}`,
    display: 'flex',
    width: '150px'
  }),
  bodyContainer: {
    margin: '20px 0',
    borderRadius: theme.spacing(0.5)
  },
  datesWrapper: props => ({
    border: `0.5px solid ${props.border.primary}`,
    borderRadius: '4px',
    padding: '10px',
    color: props.text.label,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    marginRight: theme.spacing(1),
    minWidth: '52px'
  }),
  datesWrapperContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowY: 'scroll',
    marginLeft: '10px'
  },
  datesContainer: {
    display: 'flex',
    cursor: 'pointer'
  },
  dayWrapper: props => ({
    position: 'absolute',
    padding: '1px 6px',
    marginTop: '-16px',
    marginLeft: '8px',
    background: props.background.primary,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '10px',
    color: props.text.placeholder,
    borderRadius: '1px'
  }),
  timeWrapper: props => ({
    border: '0.5px solid #393939',
    borderRadius: '5px',
    padding: '12px 14px',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.heading,
    minWidth: '76px',
    textAlign: 'center',
    margin: 0,
    maxHeight: '16px',
    cursor: 'pointer'
  }),
  fadedTimeWrapper: {
    border: '0.5px solid grey',
    borderRadius: '5px',
    padding: '12px 14px',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: 'grey',
    minWidth: '76px',
    textAlign: 'center',
    margin: 0,
    cursor: 'pointer',
    opacity: '0.5'
  },
  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    height: '160px',
    overflowY: 'scroll'
  },
  timeSlotsText: props => ({
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.heading,
    padding: '10px 0',
    margin: 0
  }),
  forwardIcon: props => ({
    color: '#FFF',
    background: '#5267FF',
    borderRadius: '50%',
    padding: '8px'
  }),
  backIcon: props => ({
    color: '#000',
    background: '#fff',
    borderRadius: '50%',
    padding: '8px',
    border: '0.5px solid #C4C4C4,'
  }),
  stepperTitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#393939'
  },
  stepperDesc: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#C4C4C4'
  },
  disabledDot: {
    position: 'absolute',
    left: '20px',
    height: '14px',
    width: '14px',
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    display: 'inline-block',
    bottom: '30px'
  },
  activeDot: props => ({
    left: '40px',
    width: '27px',
    bottom: '30px',
    height: '14px',
    display: 'inline-block',
    position: 'absolute',
    borderRadius: '80px',
    backgroundColor: props.background.selection
  }),
  toggler: props => ({
    width: '202px',
    height: '31px',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(82, 103, 255, 0.1)',
    border: `0.543px solid ${props.border.selection}`,
    borderRadius: '4px',
    margin: '20px 0 0 0'
  }),
  activeTab: props => ({
    padding: '7px 10px 7px 10px',
    background: props.background.selection,
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: props.text.secondary
  }),
  inactiveTab: props => ({
    padding: '7px 10px 7px 10px',
    color: props.text.tag,
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px'
  }),
  save: {
    width: '105px',
    height: '40px',
    background: '#5267FF',
    borderRadius: '4px',
    color: '#FFFFFF'
  },
  saveText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    letterSpacing: '1.25px',
    margin: '8px 30px'
  },
  clear: {
    width: '110px',
    height: '40px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    marginLeft: '30px',
    marginTop: '12px'
  },
  slotsContainer: props => ({
    border: `solid 1px ${props?.border?.primary}`,
    borderRadius: '6px',
    flexGrow: 1
  }),
  calendarAndSlotsContainer: {
    paddingLeft: '28px',
    paddingBottom: '10px'
  },
  modalContainer: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minWidth: '636px !important'
    }
  },
  summaryContainer: {
    height: '256px',
    backgroundColor: '#FAFAFA',
    border: '1px solid rgba(82, 103, 255, 0.1)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    display: 'flex',
    padding: '0 20px 0 20px',
    flexDirection: 'column',
    alignItems: 'center'
  },
  summaryAppointmentContainer: {
    width: '154px',
    height: '39px',
    backgroundColor: 'rgba(82, 103, 255, 0.1)',
    border: '1px solid rgba(82, 103, 255, 0.2)',
    borderRadius: ' 0px 0px 8px 8px'
  },
  appointmentText: props => ({
    textAlign: 'center',
    marginTop: '10px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: props.text.primary
  }),
  summaryDetails: {
    marginTop: '20px',
    gap: '10px'
  },
  leftDetails: {
    width: '90px',
    height: '19px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'rgba(57, 57, 57, 0.9)'
  },
  rightDetails: {
    width: '97px',
    height: '19px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'rgba(57, 57, 57, 0.7)'
  },
  dateTime: props => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: props.text.primary
  }),
  detailsContainer: {
    marginTop: '10px',
    justifyContent: 'space-between',
    display: 'flex',
    width: '230px'
  },
  saveContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '120px',
    marginTop: '25px',
    alignItems: 'center'
  },
  yesButton: {
    padding: '8px 24px',
    backgroundColor: '#5267FF',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal'
  }
}));
