import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { updatePropertyAction } from '../../../Redux/Actions/Property';
import {
  BtnContainer,
  Cancel,
  SendFeed,
  FontStyle
} from '../../../styles/propertystyles/Modalstyle';

function ListModal(props) {
  const dispatch = useDispatch();

  const CancelThis = () => {
    if (props.status !== null) {
      let newState = props.status === 'listed' ? 'draft' : 'listed';
      props.setStatus(newState);
    }
    props.setOpen(false);
  };

  const ContinueThis = () => {
    if (props.status !== null) {
      let obj = {
        status: props.status
      };
      dispatch(updatePropertyAction(obj, props.id));
    }
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div>
                  {props.status === 'listed' ? (
                    <span style={FontStyle}>
                      ARE YOU SURE WANT TO PUBLISH THIS LISTING ?
                    </span>
                  ) : (
                    <span style={FontStyle}>
                      UNLISTING WILL REMOVE THE LISTING FROM RENTAL SITES AND
                      YOU WILL STOP RECIEVING LEADS. ARE YOU SURE YOU WANT TO
                      CONTINUE ?
                    </span>
                  )}
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={SendFeed}
                    onClick={() => ContinueThis()}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default ListModal;
