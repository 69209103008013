import styled from 'styled-components';
import theme from '../../../theme';
import { IconWrapper } from '../CommentModal/comment-modal.styles';

const inputConfig = {
  padding: {
    lg: '0.625rem 1.25rem',
    md: '0.625rem 0.625rem',
    sm: '0.5rem 0.625rem',
    xs: '0.5rem 0.625rem',
    xxs: '0.375rem 0.625rem'
  },
  fontSize: {
    lg: '1rem',
    md: '1rem',
    sm: '0.875rem',
    xs: '0.75rem',
    xxs: '0.625rem'
  }
};

const InputIconWrapper = styled(IconWrapper)`
  margin-left: ${({ append }) => append && '1rem'};
  /* margin-right: ${({ prepend }) => prepend && '1rem'}; */
  transform: ${({ inverse }) => (inverse ? 'rotate(180deg)' : 'rotate(0deg)')};
  line-height: 0;
`;

const InputWrapper = styled.div`
  border: ${({ borderBottom, borderNone }) =>
    borderNone
      ? 'none'
      : borderBottom
      ? 'none none 0.5px none'
      : '0.5px solid #c4c4c4'};

  border-bottom: ${({ borderBottom, borderNone }) =>
    borderBottom
      ? '0.5px solid #c4c4c4'
      : borderNone
      ? 'none'
      : '0.5px solid #c4c4c4'};
  padding: ${({ size }) => inputConfig['padding'][size]};
  font-size: ${({ size }) => inputConfig['fontSize'][size]};
  color: ${({ theme }) => theme.text.primary};
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};
  background-color: ${({ disabled }) =>
    disabled ? 'rgba(196, 196, 196, 0.10);' : 'none'};
  width: ${({ width }) => width};
`;

const InputStyle = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${({ size = 'md' }) => inputConfig['fontSize'][size]};
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #9d9d9d;
    font-weight: 300;
  }
`;

const CustomInputStyle = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${({ size = 'md' }) => inputConfig['fontSize'][size]};
  background-color: transparent;
  padding-left: 5px;
  font-family: 'Inter', sans-serif;
  border: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #9d9d9d;
    font-weight: 300;
  }
`;

const Label = styled.p`
  margin: 0;
  font-size: ${({ size = 'md' }) => inputConfig['fontSize'][size]};
  color: ${({ theme }) => theme.text.primary};
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  padding-bottom: 6px;
`;

const RangeInput = styled.div`
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  InputIconWrapper,
  InputWrapper,
  InputStyle,
  Label,
  RangeInput,
  CustomInputStyle
};
