import React from 'react';

import PropTypes from 'prop-types';
import {
  Badge,
  Indicator,
  IndicatorWrapper,
  RulerLeft
} from './styles.chatcomponentsv2';

const NewMessageIndicator = ({ show }) => {
  return (
    <IndicatorWrapper show={show}>
      <Indicator>
        <Badge>
          <span>New Message</span>
        </Badge>
        <RulerLeft />
      </Indicator>
    </IndicatorWrapper>
  );
};

NewMessageIndicator.propTypes = {
  show: PropTypes.bool
};

export default NewMessageIndicator;
