import React from 'react';
const SaveIcon = ({ onClick, style, width }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width={width || '16'}
      height={width || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4142 4.03088C13.7893 4.40595 14 4.91466 14 5.44509V13C14 13.2667 13.9 13.5 13.7 13.7C13.5 13.9 13.2667 14 13 14H3C2.73333 14 2.5 13.9 2.3 13.7C2.1 13.5 2 13.2667 2 13V3C2 2.73333 2.1 2.5 2.3 2.3C2.5 2.1 2.73333 2 3 2H10.5549C11.0853 2 11.594 2.21071 11.9691 2.58579L13.4142 4.03088ZM13 5.89509C13 5.36466 12.7893 4.85595 12.4142 4.48088L11.5191 3.58579C11.144 3.21071 10.6353 3 10.1049 3H3V13H11C12.1046 13 13 12.1046 13 11V5.89509ZM8 11.9167C8.47778 11.9167 8.88611 11.7472 9.225 11.4083C9.56389 11.0694 9.73333 10.6611 9.73333 10.1833C9.73333 9.70556 9.56389 9.29722 9.225 8.95833C8.88611 8.61944 8.47778 8.45 8 8.45C7.52222 8.45 7.11389 8.61944 6.775 8.95833C6.43611 9.29722 6.26667 9.70556 6.26667 10.1833C6.26667 10.6611 6.43611 11.0694 6.775 11.4083C7.11389 11.7472 7.52222 11.9167 8 11.9167ZM3.88333 4.475C3.88333 4.80177 4.14823 5.06667 4.475 5.06667H9.25833C9.5851 5.06667 9.85 4.80177 9.85 4.475C9.85 4.14823 9.5851 3.88333 9.25833 3.88333H4.475C4.14823 3.88333 3.88333 4.14823 3.88333 4.475ZM3 5.06667V13V3V5.06667Z"
        fill="#42526E"
      />
    </svg>
  );
};
export default SaveIcon;
