import styled from 'styled-components';

const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop ?? '0px 20px'};
`;
const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  color: #42526e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;
const ValueRow = styled.input`
  display: flex;
  border: 0.5px solid #c4c4c4;
  border-radius: 2px;
  padding: 10px 20px;
  background: ${({ isDeleteAble }) =>
    isDeleteAble ? 'rgba(196, 196, 196, 0.20)' : '#fff'};
`;

export { InputLabelWrapper, LabelRow, ValueRow };
