import { gql } from '@apollo/client';

export const getMessageTemplates = gql`
  query getMessageTemplates($filters: FiltersInputInput!) {
    getMessageTemplates(filters: $filters) {
      name
      _id
      vars
      body
      subject
      clients {
        _id
        name
      }
    }
  }
`;
