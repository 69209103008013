import React from 'react';
import { TabOption, Text } from './tabs.styles';

const Tab = ({
  children,
  active,
  padding,
  margin,
  color,
  fontSize,
  lineHeight,
  indicatorPosition = 'bottom',
  startAdornment,
  endAdornment,
  onClick,
  indicatorColor,
  alignment,
  inactiveOpacity = '0.5'
}) => {
  return (
    <TabOption
      active={active}
      indicatorPosition={indicatorPosition}
      onClick={onClick}
      color={color}
      indicatorColor={indicatorColor}
      alignment={alignment}
      inactiveOpacity={inactiveOpacity}
    >
      {startAdornment ?? null}
      <Text
        color={color}
        padding={padding}
        margin={margin}
        fontSize={fontSize}
        lineHeight={lineHeight}
        active={active}
      >
        {children}
      </Text>
      {endAdornment ?? null}
    </TabOption>
  );
};

export default Tab;
