import React, { useContext, useEffect, useState } from 'react';
import RightArrow from '../../Media/InboxV1/RightArrow';
import Swap from '../../Media/InboxV1/Swap';
import {
  LEAD_STAGE_NAMES,
  LEAD_STAGES_LIST
} from '../../Component/Constants/LeadStages';
import { getOwnerTransferOptions } from './inboxUtils';
import OwnerTransfer from './OwnerTransfer';
import LostReasonComp from './Common/LostReasonModal';
import { useDispatch } from 'react-redux';
import { updateStage } from '../../Redux/Actions/InboxV1';
import Pill from '../../globalcomponents/Pill';
import SentimentPopup from './SentimentPopup';
import CrossIcon from '../../Media/InboxV1/CrossIcon';
import DropdownArrow from '../../Media/InboxV1/DropdownArrow';
import Icon from '../../globalcomponents/Icon';
import {
  KanbanItem,
  KanbanListItem,
  MessagesScheduled,
  OwnerContainer,
  OwnerName,
  ScheduledMessageConatiner,
  StageContainerExpanded,
  StagesTitle,
  StyledClickableText,
  Title,
  TotalScheduledMessage
} from './styles.inboxv2';
import { useTheme } from 'styled-components';
import { useInboxContext } from '../../utils/ContextProviders/InboxContextProvider';
import { startCase } from 'lodash';
import ScheduledMessagesPopup from './ScheduledMessages/ScheduledMessagesPopup';

const LeadHeader = props => {
  const {
    selectedLeadId,
    leadData,
    apartmentData,
    leadUpdateMutate,
    getScheduledLeadMessages
  } = props;
  const { tenant, leasingAgent } = leadData;
  const [expandedView, setExpandedView] = useState(false);
  const [openReason, setOpenReason] = useState(false);
  const [lostReason, setLostReason] = useState(null);
  const [open, setOpen] = useState(false);
  const [showMoodPopup, setShowMoodPopup] = useState(false);
  const [showPill, setShowPill] = useState(false);
  const [classList, setClassList] = useState('');
  const [pillClassList, setPillClassList] = useState('');

  const dispatch = useDispatch();
  const theme = useTheme();
  const apartmentTimezone = apartmentData?.apartmentById?.timeZone;
  const {
    updateLeadMood,
    isLeadAngry,
    scheduledMessages,
    fetchScheduledMessages
  } = useInboxContext();

  useEffect(() => {
    if (isLeadAngry) {
      setPillClassList('scale-up');
      setShowPill(true);
    } else {
      setShowPill(false);
    }
  }, [isLeadAngry]);
  useEffect(() => {
    fetchScheduledMessages(selectedLeadId);
  }, [selectedLeadId]);

  const handleTourChange = name => {
    if (name === 'Lost') {
      setLostReason(true);
      setOpen(true);
    } else {
      dispatch(
        updateStage({
          stagePayload: {
            leadId: selectedLeadId,
            stage: name
          }
        })
      );
    }
  };

  const pillClickHandler = e => {
    setClassList('sentiment-scaleup');
    setShowMoodPopup(true);
    setShowPill(false);
  };

  const onSentimentAck = () => {
    dispatch(
      updateStage({
        stagePayload: {
          leadId: selectedLeadId,
          stage: 'Lost',
          closureReason: lostReason
        }
      })
    );
    leadUpdateMutate({
      variables: {
        leadId: selectedLeadId,
        record: {
          followupToggle: false,
          textOptIn: false,
          callOptIn: false
        }
      }
    });

    setTimeout(() => {
      setClassList('scale-down');
    }, 1500);

    setTimeout(() => {
      setShowMoodPopup(false);
      updateLeadMood(false);
    }, 1800);
  };

  const sentimentPopupCloseHandler = e => {
    e.preventDefault();
    setClassList('scale-down');
    setShowPill(true);
    setTimeout(() => {
      setShowMoodPopup(false);
    }, 300);
  };

  const reasonList = getOwnerTransferOptions(apartmentData, tenant);
  const [showSchedulesMessagesPopup, setShowSchedulesMessagesPopup] =
    useState(false);

  return (
    <div className="d-flex-row align-items-center justify-ct-space-between v1-kanban-tab">
      {lostReason === true ? (
        <>
          <LostReasonComp
            open={open}
            setOpen={setOpen}
            Stage="Lost"
            id={selectedLeadId}
            curruntStage="Lost"
            leadUpdateMutate={leadUpdateMutate}
          />
        </>
      ) : null}

      {expandedView ? (
        <StageContainerExpanded className="expanded-view">
          {' '}
          <KanbanListItem>
            <StagesTitle>Stages</StagesTitle>

            <CrossIcon
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '8px',
                right: '8px'
              }}
              onClick={() => {
                setExpandedView(false);
              }}
            />
          </KanbanListItem>
          {/* <div className="justify-ct-space-between kanban-list-item ">
            
          </div> */}
          {LEAD_STAGES_LIST.map(({ name, value }) => {
            return (
              <div
                className={
                  value === LEAD_STAGE_NAMES[leadData?.stage]
                    ? 'selected-item-wrapper'
                    : ''
                }
              >
                <KanbanListItem
                  isSelected={value === LEAD_STAGE_NAMES[leadData?.stage]}
                  isLostStage={leadData?.stage === 'Lost'}
                  onClick={e => {
                    e.preventDefault();
                    if (
                      value !== LEAD_STAGE_NAMES[leadData?.stage] ||
                      leadData?.stage === 'Lost'
                    ) {
                      handleTourChange(value);
                    }
                  }}
                >
                  {name}
                </KanbanListItem>
              </div>
            );
          })}
        </StageContainerExpanded>
      ) : (
        <div className="stage-container kanban-container gap10 d-flex-align-center">
          <div
            onClick={e => {
              if (leadData?.stage === 'Lost') {
                e.preventDefault();
                handleTourChange('Lost');
              }
              return;
            }}
            style={{ color: '#67C848' }}
            className={`selected-item-wrapper inter-500px-12px  ${
              leadData?.stage !== 'Lost' ? '' : 'cursor-pointer'
            }`}
          >
            <KanbanItem>{LEAD_STAGE_NAMES[leadData?.stage]}</KanbanItem>
          </div>
          {leadData?.stage !== 'Lost' ? (
            <StyledClickableText
              onClick={e => {
                e.preventDefault();
                handleTourChange('Lost');
              }}
            >
              Lost
            </StyledClickableText>
          ) : null}

          <Icon
            onClick={() => {
              setExpandedView(true);
            }}
            src={`dropdown-arrow-${
              theme.state == 'light' ? 'light' : 'dark'
            }.svg`}
            imgStyle={{ height: '18px', width: '15px' }}
          />
          {/* <DropdownArrow
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpandedView(true);
            }}
          /> */}
        </div>
      )}
      {scheduledMessages?.length > 0 && (
        <ScheduledMessageConatiner
          onClick={() => {
            getScheduledLeadMessages(selectedLeadId);
            fetchScheduledMessages();
            setShowSchedulesMessagesPopup(true);
          }}
        >
          <TotalScheduledMessage>
            {scheduledMessages.length}
          </TotalScheduledMessage>
          <MessagesScheduled>Message/s scheduled</MessagesScheduled>
        </ScheduledMessageConatiner>
      )}
      <div className="sentiment-wrapper">
        <div className={`sentiment-pill-wrapper ${pillClassList}`}>
          {showPill && (
            <Pill
              text={'Lead Sentiment: Angry'}
              color="#FFFFFF"
              bgColor="#D83A52"
              clickable
              onClick={pillClickHandler}
            />
          )}
        </div>
        <div className={`sentiment-container ${classList}`}>
          <SentimentPopup
            show={showMoodPopup}
            lostReason={lostReason}
            setLostReason={setLostReason}
            onAck={onSentimentAck}
            onClose={sentimentPopupCloseHandler}
          />
        </div>
      </div>
      <OwnerContainer>
        <OwnerName onClick={() => setOpenReason(true)}>
          <Title>{startCase(leasingAgent?.name)}</Title>
          <Icon src={`swap-${theme.state}.svg`} size="12px" />
        </OwnerName>
      </OwnerContainer>
      <OwnerTransfer
        {...{
          openReason,
          setOpenReason,
          reasonList,
          selectedLeadId,
          tenant,
          leasingAgent,
          leadUpdateMutate
        }}
      />
      <ScheduledMessagesPopup
        show={showSchedulesMessagesPopup}
        onClose={() => setShowSchedulesMessagesPopup(false)}
        apartmentTimezone={apartmentTimezone}
      />
    </div>
  );
};

export default LeadHeader;
