import React from 'react';

const Trash = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
      <path
        d="M10.7666 13.3337C10.9243 13.3338 11.0768 13.278 11.1972 13.1762C11.3176 13.0743 11.3979 12.9331 11.424 12.7777L12.5832 5.83366H12.0762M10.7666 13.3337H5.22996M10.7666 13.3337V12.8337H5.23011L5.22996 13.3337M10.7666 13.3337L10.767 12.8337C10.8063 12.8337 10.8443 12.8198 10.8743 12.7944C10.9043 12.769 10.9243 12.7338 10.9308 12.6951M12.0762 5.83366L12.1597 5.33366H3.84018L3.92343 5.83366M12.0762 5.83366L10.9308 12.6951M12.0762 5.83366H3.92343M3.92343 5.83366H3.41654L4.57263 12.777M3.92343 5.83366L5.06577 12.6945L4.57263 12.777M4.57263 12.777C4.59865 12.9325 4.67897 13.0738 4.79932 13.1757C4.91967 13.2777 5.07225 13.3336 5.22996 13.3337M4.57263 12.777L5.06584 12.6949L5.0658 12.6947C5.07234 12.7335 5.0924 12.7687 5.12245 12.7942C5.1525 12.8196 5.19058 12.8336 5.22996 12.8337V13.3337M10.9308 12.6951L10.9308 12.6953L11.4238 12.7776L10.9308 12.6951ZM9.99996 3.83366H10.5H12.6666C12.7108 3.83366 12.7532 3.85122 12.7845 3.88247C12.8157 3.91373 12.8333 3.95612 12.8333 4.00033V4.16699H3.16663V4.00033C3.16663 3.95612 3.18419 3.91373 3.21544 3.88247C3.2467 3.85122 3.28909 3.83366 3.33329 3.83366H5.49996H5.99996H9.99996Z"
        stroke="#42526E"
      />
    </svg>
  );
};

export default Trash;
