import styled from 'styled-components';

const Container = styled.div`
  align-self: end;
  background: ${({ theme }) => theme.background.secondary};
  border: 0.5px solid rgba(196, 196, 196, 0.7);
  border-radius: 6px;
  margin: 0px 10px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  background: ${props =>
    props.secondary
      ? `linear-gradient(180deg, rgba(255, 168, 0, 0.25) -14.15%, rgba(255, 214, 0, 0) 100%)`
      : `linear-gradient(180deg, rgba(196,196,196, 0.15) -11.29%, rgba(186, 161, 247, 0) 106.45%)`};
  border-radius: 6px;
`;

const Topic = styled.h4`
  font-family: 'Inter', sans-serif;
  font-size: 0.75rem;
  line-height: 15px;
  color: ${({ theme }) => theme?.text?.primary};
  font-weight: 500;
  font-style: normal;
  margin: 0;
`;

const Fields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

const FormContainer = styled.div`
  width: 100%;
  min-width: 250px;
  z-index: 10000000;
  pointer-events: auto !important;
`;

const Form = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 3;
  background-color: #fff;
  border: 0.5px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
`;

export { Container, Header, Topic, Fields, Form, FormContainer };
