import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import NewRules from './newRules/NewRules';
import Wtable from './Wtable';
import MtTable from './MtTable';
import SequenceContent from './Sequence/SequenceContent';
import {
  AddWorkflowAction,
  getAllWorkflowsAction,
  addSequenceContentAction
} from '../../Redux/Actions/workflow';
import CustomizedSnackbars from '../../Component/Toast';
import { navStyle, MainColor, fadeColor } from '../../styles/Workflowstyle';
import Button from '../../globalcomponents/Button';

const Wcontent = props => {
  const dispatch = useDispatch();
  const [showWorkflowAdd, setShowWorkflowAdd] = useState(false);
  //functionBlock
  const WorkFlowtable = () => {
    dispatch(AddWorkflowAction(false));
  };
  const SetBreadCrumb = () => {
    return props.NavState != 'Sequences' && props.NavState;
  };
  const toggleWorkflowAddVisible = e => {
    e.preventDefault();
    setShowWorkflowAdd(prevState => !prevState);
  };
  //lifeCycleBlock
  useEffect(() => {
    dispatch(AddWorkflowAction(false));
    dispatch(addSequenceContentAction(false));
    dispatch(getAllWorkflowsAction());
  }, []);

  const colStyle = {
    position: 'fixed',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '94%',
    overflowX: 'hidden',
    height: '100%'
  };
  const pb100 = {
    paddingBottom: '100px'
  };

  return (
    <>
      <CustomizedSnackbars
        open={props.Toast.toast}
        severity={props.Toast.severity}
        msg={props.Toast.msg}
      />
      <div style={{ ...colStyle }}>
        <div style={navStyle}>
          <span
            style={{ ...MainColor, cursor: 'pointer' }}
            onClick={() => WorkFlowtable()}
          >
            {SetBreadCrumb()}
          </span>
          <Button onClick={toggleWorkflowAddVisible}>Add template</Button>
        </div>
        <div>
          {props.NavState && (
            <div style={pb100}>
              <MtTable />
              {showWorkflowAdd && (
                <NewRules onClose={toggleWorkflowAddVisible} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  WorkflowAdd: state.Workflow.WorkflowAdd,
  sequenceAdd: state.Workflow.sequenceAdd,
  sequenceContentAdd: state.Workflow.sequenceContentAdd,
  Toast: state.Toast
});

export default connect(mapStateToProps)(Wcontent);
