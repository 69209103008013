import React, { useEffect, useState } from 'react';
import { SuccessIconContainer } from '../../globalcomponents/SliderValue/SuccessIcon/success-icon.styles';

const SuccessIcon = ({
  setCurrStep,
  newPassword,
  handleValueChange,
  background = '#258750'
}) => {
  const [showFullIcon, setShowFullIcon] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  useEffect(() => {
    if (!showIcon) {
      if (newPassword === 'error') {
        setCurrStep(0);
        handleValueChange('1');
        return;
      }
      setTimeout(() => setShowFullIcon(true), 1000);
      setTimeout(() => setShowIcon(true), 500);
      setTimeout(() => setCurrStep(2), 2000);
    }
  }, []);
  return (
    <>
      {' '}
      {showIcon ? (
        <SuccessIconContainer background={background}>
          <img
            src="https://assets.getzuma.com/client/images/SubmitSuccess.gif"
            alt="SubmitSuccess"
            width={30}
            height={30}
          />
        </SuccessIconContainer>
      ) : (
        <SuccessIconContainer background={background}>
          <img
            src="https://assets.getzuma.com/client/images/SubmitLoader.gif"
            alt="SubmitLoader"
            width={30}
            height={30}
          />
        </SuccessIconContainer>
      )}
    </>
  );
};

export default SuccessIcon;
