import React from 'react';
import IntegrationsIcon from '../../Media/SelfServe/IntegrationsIcon';
import PMSIcon from '../../Media/SelfServe/PMSIcon';
import CommunityIcon from '../../Media/Sidebar/Community';
import LeadsIcon from '../../Media/Sidebar/Leads';
import SettingsIcon from '../../Media/Sidebar/Settings';

export const TABS_DATA = [
  {
    name: 'Users',
    key: 'workspace',
    icon: <LeadsIcon color="#42526E" size="24" />
  },
  {
    name: 'Internal Settings',
    key: 'internalSettings',
    icon: <SettingsIcon color="#42526E" size="24" />
  },
  {
    name: 'PMS Setup',
    key: 'pms',
    icon: <PMSIcon />
  },
  {
    name: 'Communities',
    key: 'communities',
    icon: <CommunityIcon color="#42526E" size="24" />
  },
  {
    name: 'Go Live',
    key: 'integrations',
    icon: <IntegrationsIcon />
  }
];

export const SETTINGS_DATA = [
  {
    label: 'Name',
    key: 'name',
    checked: false
  },
  {
    label: 'Email',
    key: 'emailflag',
    checked: false
  },
  {
    label: 'Move in date',
    key: 'requestedMoveIn',
    checked: false
  },
  {
    label: 'Phone no',
    key: 'phoneNo',
    checked: false
  },
  {
    label: 'Bedrooms',
    key: 'bedrooms',
    checked: false
  },
  {
    label: 'Bathrooms',
    key: 'bathrooms',
    checked: false
  },
  {
    label: 'Budget Min',
    key: 'budgetMin',
    checked: false
  },
  {
    label: 'Budget Max',
    key: 'budgetMax',
    checked: false
  },
  {
    label: 'Occupants',
    key: 'occupants',
    checked: false
  },
  {
    label: 'Credit Score',
    key: 'creditScore',
    checked: false
  },
  {
    label: 'Income',
    key: 'income',
    checked: false
  },
  {
    label: 'Lead Terms',
    key: 'leadTerms',
    checked: false
  },
  {
    label: 'Text Opt-in',
    key: 'textOptIn',
    checked: false
  },
  {
    label: 'Call Opt-in',
    key: 'callOptIn',
    checked: false
  },
  {
    label: 'No of pets',
    key: 'noOfPets',
    checked: false
  },
  {
    label: 'Community of interest',
    key: 'communityOfInterest',
    checked: false
  },
  {
    label: 'Preferred Units',
    key: 'preferredUnits',
    checked: false
  },
  {
    label: 'Pet type',
    key: 'petType',
    checked: false
  },
  {
    label: 'Furnished',
    key: 'furnished',
    checked: false
  }
];

export const LEAD_TYPES = [
  {
    label: 'Call',
    value: 'call'
  },
  {
    label: 'Chat',
    value: 'chat'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Sms',
    value: 'sms'
  },
  {
    label: 'Landline',
    value: 'landline'
  },
  {
    label: 'Form',
    value: 'form'
  },
  {
    label: 'Internet',
    value: 'internet'
  },
  {
    label: 'Knock-Schedule',
    value: 'knock'
  },
  {
    label: 'Facebook',
    value: 'facebook'
  }
];
