import React, { useState } from 'react';
import { ReactComponent as Text } from '../../../Media/workflow/Text.svg';
import SmsIcon from '../../../Media/workflow/smsIcon.png';
import CallLight from '../../../Media/CALLLIGHT.png';
import EmailModal from './EmailModal';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DelayModal from './DelayModal';

const StepSelect = props => {
  const [open, setOpen] = useState(false);
  const [OpenDelay, setOpenDelay] = useState(false);

  const setOpenEmailFun = () => {
    setOpen(true);
  };
  return (
    <>
      <EmailModal
        open={open}
        setOpen={setOpen}
        paramid={props.paramid}
        handleClose={props.handleClose}
      />
      <DelayModal
        open={OpenDelay}
        setOpen={setOpenDelay}
        paramid={props.paramid}
        handleClose={props.handleClose}
      />
      <div style={{ width: '130px' }}>
        <div
          style={{
            color: '#525f80',
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 600,
            marginTop: '10px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenEmailFun()}
        >
          <span style={{ marginLeft: '7px', marginRight: '7px' }}>
            <img src={SmsIcon} style={{ width: '17px' }} />
          </span>
          Email
        </div>
        <div
          style={{
            color: '#525f80',
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 600,
            marginTop: '6px',
            cursor: 'pointer'
          }}
        >
          <span style={{ marginLeft: '7px', marginRight: '7px' }}>
            <img src={CallLight} style={{ width: '17px' }} />
          </span>
          Call
        </div>
        <div
          style={{
            color: '#525f80',
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 600,
            marginBottom: '6px',
            marginTop: '6px',
            cursor: 'pointer'
          }}
        >
          <span style={{ marginLeft: '7px', marginRight: '7px' }}>
            <Text style={{ width: '17px' }} />
          </span>
          Text
        </div>
        <div
          style={{
            color: '#525f80',
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 600,
            marginBottom: '6px',
            marginTop: '6px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenDelay(true)}
        >
          <span style={{ marginLeft: '7px', marginRight: '7px' }}>
            <ScheduleIcon style={{ width: '17px', marginBottom: '-6.5px' }} />
          </span>
          Delay
        </div>
      </div>
    </>
  );
};

export default StepSelect;
