import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Gear from '../../../../Media/workflow/Gear.svg';
import Mailred from '../../../../Media/workflow/Mailred.svg';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { updateStepAction } from '../../../../Redux/Actions/workflow';
const Summary = props => {
  const dispatch = useDispatch();
  const [DelayId, setDelayId] = useState('');
  const [Simpleval, setSimpleval] = useState('');
  const [SimpleUnit, setSimpleUnit] = useState('');
  const [valid, setvalid] = useState(false);
  const ColWidth = {
    width: 100
  };
  const colWidth50 = {
    width: 30
  };
  const colWidth20 = {
    width: 20
  };
  const RowHeight = {
    padding: '25px 18px',
    borderBottom: '1px solid #e6e6e6'
  };
  const p0 = {
    padding: 0,
    paddingBottom: '20px'
  };
  const textCent = {
    textAlign: 'center'
  };
  const fontHead = {
    fontSize: '12px',
    fontWeight: '600',
    color: '#20065f'
  };
  const fontCont14 = {
    fontSize: 14
  };
  const cursor = {
    cursor: 'pointer'
  };
  const h14 = {
    height: 14,
    ...cursor
  };
  const h15 = {
    height: 15,
    ...cursor
  };
  const delayDivStyle = {
    marginLeft: '113px',
    color: '#b0b8cb',
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0.1,
    textTransform: 'uppercase',
    background: '#f7f7f7'
  };
  const delaySpan = {
    padding: '0px 10px',
    ...cursor,
    background: '#f7f7f7',
    fontStyle: 'italic'
  };
  const width20 = {
    width: '63px',
    marginTop: '-13px'
  };
  const btnCont = {
    marginLeft: '10px',
    color: '#ff6f62',
    ...cursor
  };
  const delayDiv = {
    borderBottom: '1px solid rgb(230, 230, 230)',
    padding: '10px',
    background: '#f7f7f7',
    paddingTop: '10px'
  };
  const delayActive = {
    ...delayDiv,
    paddingTop: '22px'
  };

  // const setSimplevalFun = (e) => {
  //   if (parseInt(e.target.value) >= 0) {
  //     const onlyNums = e.target.value.replace(/[^0-9]/g, "");
  //     if (onlyNums >= 0) {
  //       setSimpleval(onlyNums);
  //     }
  //   } else {
  //     e.target.value = "";
  //   }
  // };

  const setDelayFun = data => {
    setDelayId(data._id);
    setSimpleval(data.delaySchema.delay);
    setSimpleUnit(data.delaySchema.unit);
  };
  const saveSimple = data => {
    if (
      Simpleval !== null &&
      Simpleval !== '' &&
      SimpleUnit !== null &&
      SimpleUnit !== '' &&
      parseInt(Simpleval) >= 0
    ) {
      let obj = data;
      obj.delaySchema.delay = Simpleval;
      obj.delaySchema.unit = SimpleUnit;
      obj.id = data._id;
      dispatch(updateStepAction(obj));
      setDelayId('');
      setSimpleval('');
      setSimpleUnit('');
    }
  };
  return (
    <Card>
      <CardContent style={p0}>
        <div>
          <div style={{ display: 'flex', ...RowHeight }}>
            <div style={colWidth50}></div>
            <div style={colWidth20}></div>
            <div style={colWidth50}></div>
            <div style={ColWidth}></div>
            <div style={{ ...ColWidth, ...fontHead }}>Sent</div>
            <div style={{ ...ColWidth, ...fontHead }}>Opened</div>
            <div style={{ ...ColWidth, ...fontHead }}>Clicked</div>
            <div style={{ ...ColWidth, ...fontHead }}>Replied</div>
            <div style={ColWidth}></div>
            <div style={ColWidth}></div>
          </div>

          <div>
            <div>
              {props.TableData.map((res, i) => {
                return (
                  <>
                    {res.type !== 'Delay' && (
                      <div style={{ display: 'flex', ...RowHeight }}>
                        <div style={{ ...colWidth20 }}>
                          <img src={Mailred} style={h14} alt="Mailred" />
                        </div>
                        <div
                          style={{ ...colWidth50, ...textCent, ...fontCont14 }}
                        >
                          {i + 1}
                        </div>
                        <div style={colWidth50}>
                          <img src={Gear} style={h15} alt="Gear" />
                        </div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>
                          {res.title}
                        </div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>0</div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>0%</div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>Off</div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>O%</div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>
                          A/B Test
                        </div>
                        <div style={{ ...ColWidth, ...fontCont14 }}>
                          Send Test
                        </div>
                      </div>
                    )}

                    {res.type === 'Delay' && (
                      <div style={DelayId === res._id ? delayActive : delayDiv}>
                        <div style={delayDivStyle}>
                          {res.type === 'Delay' && (
                            <>
                              {DelayId !== res._id && (
                                <span
                                  style={delaySpan}
                                  onClick={() => setDelayFun(res)}
                                >
                                  Wait {res.delaySchema.delay}{' '}
                                  {res.delaySchema.unit}
                                </span>
                              )}

                              {DelayId === res._id &&
                                res.delaySchema.type === 'Conditional' && (
                                  <span style={delaySpan}>
                                    if no reply in{' '}
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      style={width20}
                                      type="number"
                                    />{' '}
                                    days{' '}
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      style={width20}
                                    />{' '}
                                    hours{' '}
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      style={width20}
                                    />{' '}
                                    minutes{' '}
                                    <span
                                      style={btnCont}
                                      onClick={() => setDelayId('')}
                                    >
                                      SAVE
                                    </span>{' '}
                                    <span
                                      style={btnCont}
                                      onClick={() => setDelayId('')}
                                    >
                                      CANCEL
                                    </span>
                                  </span>
                                )}
                            </>
                          )}

                          {DelayId === res._id &&
                            res.delaySchema.type === 'Simple' && (
                              <span style={delaySpan}>
                                Wait{' '}
                                <TextField
                                  key={`${Math.floor(
                                    Math.random() * 1000
                                  )}-min`}
                                  variant="outlined"
                                  size="small"
                                  style={width20}
                                  onBlur={event => {
                                    event.target.value < 0
                                      ? (event.target.value = 0)
                                      : setSimpleval(event.target.value);
                                  }}
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  defaultValue={Simpleval}
                                />{' '}
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ marginTop: '-13px' }}
                                >
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={e =>
                                      setSimpleUnit(e.target.value)
                                    }
                                    value={SimpleUnit}
                                    defaultValue={SimpleUnit}
                                  >
                                    <MenuItem value={'seconds'}>
                                      seconds
                                    </MenuItem>
                                    <MenuItem value={'minutes'}>
                                      minutes
                                    </MenuItem>
                                    <MenuItem value={'hours'}>hours</MenuItem>
                                    <MenuItem value={'day'}>day</MenuItem>
                                  </Select>
                                </FormControl>
                                <span
                                  style={btnCont}
                                  onClick={() => saveSimple(res)}
                                >
                                  SAVE
                                </span>{' '}
                                <span
                                  style={btnCont}
                                  onClick={() => {
                                    setDelayId('');
                                    setSimpleval('');
                                    setSimpleUnit('');
                                  }}
                                >
                                  CANCEL
                                </span>
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Summary;
