import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import NoAccess from '../Component/NoAccess';
import { GlobalContext } from '../utils/ContextProviders/GlobalContextProvider';
import Loader from '../Component/Loader';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(GlobalContext);

  return userData?.user_id ? (
    userData?.role ? (
      <Route {...rest} render={props => <Component {...props} />} />
    ) : (
      <NoAccess />
    )
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '25% auto',
        height: '200px',
        width: '200px'
      }}
    >
      <Loader />
      <>Loading...</>
    </div>
  );
};

export default PrivateRoute;
