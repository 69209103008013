import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { connect, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgentClientsAction } from '../../Redux/Actions/AgentProfile';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';

const InteractiveList = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AgentClientsAction());
  }, []);

  const { userData } = useContext(GlobalContext);

  const clients = userData?.userAccess?.clients?.trained ?? [];
  // let newar = clients.map(x => x)
  // if(clients){
  //   for(var i = 0; i <= clients.length;i++){
  //     newar.push(clients[i])
  //   }
  // }
  return (
    <Box
      sx={{ flexGrow: 1, maxWidth: 752, height: '325px', overflow: 'scroll' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {clients?.map(({ _id, name }) => (
            <ListItem
              key={_id}
              sx={{
                bgcolor: '#bbdefb',
                width: '412px',
                padding: '0px',
                height: '50px',
                borderRadius: '5px',
                marginBottom: '2px'
              }}
              disableGutters
            >
              <div style={{ marginLeft: '10px' }}>{name || 'N/A'}</div>
            </ListItem>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    data: state
  };
};
export default connect(mapStateToProps)(InteractiveList);
