import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';

import FilterIcon from './FilterIcon';
import { useTheme } from 'styled-components';
// import FilterIcon from '../../../images/Svgs/FilterIcon';

const ZTextField = ({
  placeholder = 'Search',
  style,
  handleChange,
  withFilter = false,
  darkMode
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <TextField
        style={style}
        variant="outlined"
        className={classes.inputRoot}
        onChange={handleChange}
        InputProps={{
          type: 'search',
          style: {
            borderRadius: '4px',
            padding: '5px 4px',
            backgroundColor: theme.background.secondary,
            color: theme.text.placeholder,
            fontSize: '14px',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.04)'
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#a2a2a2' }} />
            </InputAdornment>
          )
        }}
        placeholder={placeholder}
      />
      {withFilter ? (
        <div className={classes.filterIconContainer}>
          <FilterIcon />
        </div>
      ) : null}
    </>
  );
};

export default ZTextField;
