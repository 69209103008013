import styled from 'styled-components';

const FormContainer = styled.div`
  width: 100%;
  min-width: 250px;
  position: ${({ isPositionAbsolute }) =>
    isPositionAbsolute ? 'absolute' : 'relative'};
  top: 0;
  right: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.background.secondary};
  border: 0.5px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  height: auto;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  position: relative;
  z-index: 10;
`;

const CTASection = styled.div`
  display: inline-flex;
  gap: 0.325rem;
  padding: 0 10px 10px;
  position: ${({ isPositionAbsolute }) =>
    isPositionAbsolute ? 'absolute' : 'relative'};
  bottom: ${({ isPositionAbsolute }) => (isPositionAbsolute ? '0' : '')};
`;

export { FormContainer, Fields, CTASection };
