import React from 'react';
import ArrowRightSmall from '../../../Media/KelseyInsight/ArrowRightSmall';
import {
  CommunityLink,
  FieldWrapper,
  IconWrapper,
  Label,
  Pad,
  Required,
  Value
} from './field.styles';

const Field = ({
  date,
  time,
  label = 'Bed',
  value = 'N/A',
  isRequired = false,
  redirectUrl = undefined
}) => {
  const dateTime = date || time;
  return (
    <FieldWrapper>
      <Label>{label}:</Label>
      {isRequired && <Required>*</Required>} {dateTime && <Pad />}
      {redirectUrl ? (
        <CommunityLink
          rel="noopener noreferrer"
          target="_blank"
          href={redirectUrl}
        >
          {value} ↗
        </CommunityLink>
      ) : (
        <Value>{dateTime ? value.from : value}</Value>
      )}
      {dateTime && value.to && (
        <IconWrapper>
          <ArrowRightSmall />
        </IconWrapper>
      )}
      {dateTime && value.to && <Value>{value.to}</Value>}
    </FieldWrapper>
  );
};

export default Field;
