import { gql } from '@apollo/client';

export const getTenantById = gql`
  query tenantById($tenantId: MongoID!) {
    tenantById(_id: $tenantId) {
      _id
      name
      enableKnockCRM
      integrationSettings
    }
  }
`;
