import { useState } from 'react';
import WaitlistPopup from '../../Container/LeadCardStatus/WaitListPopup';
import { WContainer } from './LeadWaitList.styles';
import { Typography } from '@mui/material';
import { useTheme } from 'styled-components';

const LeadWaitList = ({ leadId, waitListExists }) => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  const theme = useTheme();
  const sentimentPopupCloseHandler = e => {
    setShowWaitlist(false);
  };

  const waitlistClickHandler = e => {
    setShowWaitlist(true);
  };

  return (
    <div
      style={{ position: 'relative', background: theme?.background?.secondary }}
      className="right-side-cards inter-600px-14px"
    >
      <WContainer onClick={waitlistClickHandler}>
        <Typography
          style={{ color: theme?.text?.heading }}
          className="card-title"
        >
          Waitlist
        </Typography>
      </WContainer>
      <WaitlistPopup
        leadId={leadId}
        show={showWaitlist}
        waitListExists={waitListExists}
        onClose={sentimentPopupCloseHandler}
      />
      {/* </div> */}
    </div>
  );
};

export default LeadWaitList;
