import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GetPlacesAction } from '../../../Redux/Actions/Property';
import { postApartmentAction } from '../../../Redux/Actions/Apartment';
import {
  BtnContainer,
  Cancel,
  SendFeed,
  FontStyle,
  marginbtm
} from '../../../styles/propertystyles/Modalstyle';

const AddAparatment = props => {
  const dispatch = useDispatch();
  const [PropertyAddress, setPropertyAddress] = useState(null);
  const [Validator, setValidator] = useState(false);
  const [name, setname] = useState('');
  const [website, setwebsite] = useState('');
  const [shortDesc, setshortDesc] = useState('');
  const [longDesc, setlongDesc] = useState('');

  const [placeId, setplaceId] = useState('');
  const [PlaceObj, setPlaceObj] = useState([
    { description: 'place suggetions' }
  ]);

  const CancelThis = () => {
    props.setOpen(false);
  };
  const ContinueThis = () => {
    let obj = {
      name: name,
      addressId: PropertyAddress,
      website: website,
      shortDesc: shortDesc,
      longDesc: longDesc,
      placeId: placeId
    };
    dispatch(postApartmentAction(obj));
    props.setOpen(false);
  };
  const HeadingStyle = {
    textAlign: 'center',
    font: 'normal bold 16px Inter',
    margin: '0px auto 10px auto',
    color: '#20065F'
  };
  const AutoCompHandleChange = value => {
    setPropertyAddress({ description: value.description });
    setplaceId(value.place_id);
  };
  const placeHandleChange = event => {
    if (event.target.value.length >= 3) {
      dispatch(GetPlacesAction(event.target.value));
    }
  };
  useEffect(() => {
    if (props.PlaceObj.data && props.PlaceObj.data.predictions.length > 0) {
      setPlaceObj(props.PlaceObj && props.PlaceObj.data.predictions);
    }
  }, [props.PlaceObj]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div style={HeadingStyle}>Add New Apartment</div>
                <div style={FontStyle}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    fullWidth
                    style={marginbtm}
                    onChange={e => setname(e.target.value)}
                  />
                  <Autocomplete
                    style={marginbtm}
                    id="combo-box-demo"
                    options={PlaceObj || []}
                    value={PropertyAddress}
                    getOptionLabel={option => option.description}
                    onChange={(event, value) => AutoCompHandleChange(value)}
                    disableClearable={true}
                    renderInput={params => (
                      <TextField
                        error={Validator && PropertyAddress === null}
                        {...params}
                        variant="outlined"
                        onChange={event => placeHandleChange(event)}
                        placeholder="Please type to search address & select from dropdown options only"
                      />
                    )}
                  />
                  <TextField
                    variant="outlined"
                    label="Website"
                    fullWidth
                    style={marginbtm}
                    onChange={e => setwebsite(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    label="ShortDiscription"
                    fullWidth
                    style={marginbtm}
                    onChange={e => setshortDesc(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    label="LongDiscription"
                    fullWidth
                    style={marginbtm}
                    multiline
                    rows={3}
                    rowsMax={100}
                    onChange={e => setlongDesc(e.target.value)}
                  />
                </div>

                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={SendFeed}
                    onClick={() => ContinueThis()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  PlaceObj: state.Property.PlaceObj
});

export default connect(mapStateToProps)(AddAparatment);
