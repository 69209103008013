import React from 'react';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { stringAvatar } from '../stringAvatar';

const STATUS_COLOR = {
  online: '#67C848',
  onBreak: '#FF9900',
  offline: '#C4C4C4'
};

const StyledBadge = styled(Badge)(({ theme, myColor, animate }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: `${myColor}`,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    ...(animate && {
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation:
          myColor === '#67C848' && animate
            ? 'ripple 1.2s infinite ease-in-out'
            : '',
        border: '1px solid currentColor',
        content: '""'
      }
    })
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));

export default function BackgroundLetterAvatar(props) {
  const {
    agentName = '',
    avatarStyles = {},
    titleStyles = {},
    otherStyles = {},
    statusColor = '',
    avatarRoot = {
      height: 30,
      width: 30
    },
    animate = false
  } = props;
  return (
    <CardHeader
      sx={{
        '& .MuiCardHeader-title': {
          ...titleStyles
        },
        '& .MuiCardHeader-avatar': {
          ...avatarStyles
        },
        '& .MuiAvatar-root': {
          height: 30,
          width: 30,
          ...avatarRoot
        },
        ...otherStyles
      }}
      avatar={
        <StyledBadge
          myColor={STATUS_COLOR[statusColor]}
          sx={{ color: `${STATUS_COLOR[statusColor]}` }}
          animate={animate}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar {...stringAvatar(agentName)} />
        </StyledBadge>
      }
      title={agentName}
    />
  );
}
