import React, { useEffect } from 'react';
import { CustomModalWrapper, ModalContent, ModalWrapper } from './popup.styles';

const Popup = ({
  children,
  minHeight,
  maxHeight,
  onClose,
  width,
  position,
  top,
  left,
  show = false,
  visibility,
  boxShadow = 'none',
  overlayPosition = 'fixed',
  overlayRadius = 0,
  overlayColor = 'rgba(0, 0, 0, 0.5)',
  borderRadius = '8px',
  customBackdropStyling,
  zIndex
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return position == 'absolute' ? (
    <ModalContent
      width={width}
      visibility={visibility}
      minHeight={minHeight}
      onClick={e => e.stopPropagation()}
      position={position}
      top={top}
      left={left}
      boxShadow={boxShadow}
      maxHeight={maxHeight}
      borderRadius={borderRadius}
    >
      {children}
    </ModalContent>
  ) : customBackdropStyling ? (
    <CustomModalWrapper
      onClick={onClose}
      overlayPosition={overlayPosition}
      overlayRadius={overlayRadius}
      overlayColor={overlayColor}
      left={customBackdropStyling.left}
      top={customBackdropStyling.top}
      right={customBackdropStyling.right}
      bottom={customBackdropStyling.bottom}
    >
      <ModalContent
        width={width}
        minHeight={minHeight}
        onClick={e => e.stopPropagation()}
        boxShadow={boxShadow}
        maxHeight={maxHeight}
        borderRadius={borderRadius}
      >
        {children}
      </ModalContent>
    </CustomModalWrapper>
  ) : (
    <ModalWrapper
      onClick={onClose}
      overlayPosition={overlayPosition}
      overlayRadius={overlayRadius}
      overlayColor={overlayColor}
      zIndex={zIndex}
    >
      <ModalContent
        width={width}
        minHeight={minHeight}
        onClick={e => e.stopPropagation()}
        boxShadow={boxShadow}
        maxHeight={maxHeight}
        borderRadius={borderRadius}
      >
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default Popup;
