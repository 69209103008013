import React from 'react';
import ClientSetupError from '../../../Media/SelfServe/ClientSetupError';
import SuccessIcon from '../../../Media/SelfServe/SuccessIcon';

const ClientCard = ({
  _id,
  agentCount,
  isIntegrationConfigured,
  name,
  handleClientCardClick
}) => {
  return (
    <div
      onClick={() => handleClientCardClick(_id, name)}
      className="client-card"
    >
      <div className="client-name">{name}</div>
      <div className="client-info">
        <span className="client-setup" style={{ display: 'flex' }}>
          Setup
          {!isIntegrationConfigured ? <ClientSetupError /> : <SuccessIcon />}
        </span>
        <span className="separator"></span>
        <span className="client-count">Agents {agentCount}</span>
      </div>
    </div>
  );
};

export default ClientCard;
