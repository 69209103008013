import styled from 'styled-components';

const SidebarContainer = styled.div`
  padding-top: 20px;
  height: 100vh;
  width: 64px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  background: ${props => props.theme.sideNavBackgroundColor};
`;

export { SidebarContainer };
