import React, { createContext, useContext, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { setCookieWithExpiration } from '..';
import { socketEmitter } from '../socket';
import { GlobalContext } from './GlobalContextProvider';

export const AuthContext = createContext({});
export const AuthContextProvider = ({ children }) => {
  const path = useLocation();
  const history = useHistory();
  const url = new URL(path.search, 'https://app.getzuma.com');
  const searchParams = url.searchParams;
  const token = searchParams.get('token');
  const redirectionUrl = searchParams.get('redirectionUrl');
  const { userData } = useContext(GlobalContext);

  useEffect(() => {
    if (path.pathname === '/') {
      if (token) {
        setCookieWithExpiration('rb_access_token', `Bearer ${token}`);
        socketEmitter('saveAgentStatus', {
          data: {
            agentId: userData?.user_id,
            status: 'online',
            reason: 'login'
          }
        });
        history.push('/');
      }
    }
  }, []);

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
};
