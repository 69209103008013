import { gql } from '@apollo/client';

export const getNotificationUsers = gql`
  query getNotificationUsers(
    $searchWord: String
    $filters: FiltersInputInput!
  ) {
    getNotificationUsers(searchWord: $searchWord, filters: $filters) {
      name
      _id
    }
  }
`;
