import React from 'react';
import {
  Progress,
  ProgressBarContainer,
  ProgressContainer,
  Status
} from './styles.progressbar';

const ProgressBar = ({ total, completed, height, showStatus }) => {
  return (
    <ProgressBarContainer>
      {showStatus && (
        <Status data-testid="progress-status" height={height}>
          {completed}/{total}
        </Status>
      )}

      <ProgressContainer>
        <Progress
          total={total}
          completed={completed}
          data-testid="progress"
          height={height}
        />
      </ProgressContainer>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
