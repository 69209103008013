import React from 'react';

const AudioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" fill="white" fill-opacity="0.01" />
      <path
        d="M15.8332 5.56021V12.666C15.8332 13.2849 15.5873 13.8784 15.1498 14.316C14.7122 14.7535 14.1187 14.9994 13.4998 14.9994H13.1665C12.5477 14.9994 11.9542 14.7535 11.5166 14.316C11.079 13.8784 10.8332 13.2849 10.8332 12.666V12.3327C10.8332 11.7139 11.079 11.1204 11.5166 10.6828C11.9542 10.2452 12.5477 9.99938 13.1665 9.99938H13.4998C13.7315 9.99938 13.9557 10.0327 14.1665 10.096V6.86771L9.1665 7.38521V13.4994C9.1665 13.8058 9.10615 14.1092 8.98889 14.3923C8.87163 14.6754 8.69976 14.9326 8.48309 15.1493C8.26642 15.366 8.00919 15.5378 7.7261 15.6551C7.44301 15.7724 7.13959 15.8327 6.83317 15.8327H6.49984C5.881 15.8327 5.28751 15.5869 4.84992 15.1493C4.41234 14.7117 4.1665 14.1182 4.1665 13.4994V13.166C4.1665 12.5472 4.41234 11.9537 4.84992 11.5161C5.28751 11.0785 5.881 10.8327 6.49984 10.8327H6.83317C7.06484 10.8327 7.289 10.866 7.49984 10.9294V5.54438C7.49984 5.22771 7.75817 4.94355 8.09984 4.90771L15.2332 4.16938C15.5648 4.13521 15.8332 4.36521 15.8332 4.68188V5.56021Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default AudioIcon;
