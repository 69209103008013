import { gql } from '@apollo/client';

export const createMessageTemplate = gql`
  mutation createMessageTemplate($record: messageTemplateInput!) {
    createMessageTemplate(input: $record) {
      name
      _id
      vars
      clients {
        _id
        name
      }
    }
  }
`;

export const updateMessageTemplateById = gql`
  mutation updateMessageTemplate($record: messageTemplateInput!) {
    updateMessageTemplate(input: $record) {
      _id
      name
      subject
      body
      vars
      clients {
        _id
        name
      }
    }
  }
`;

export const deleteMessageTemplateById = gql`
  mutation deleteMessageTemplate($id: String!) {
    deleteMessageTemplate(uid: $id) {
      name
    }
  }
`;
