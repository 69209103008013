import React, { useRef } from 'react';
import '../styles.scss';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useContext, useState, useEffect } from 'react';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiRef,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { CheatsheetContext } from '../../../utils/ContextProviders/CheatsheetContextProvider';
import {
  addQuestionAcrossCommunities,
  deleteQuestionAcrossCommunities,
  updateCheatsheetQuestion,
  addCheatsheetQuestionAnswer
} from '../../../Redux/util/Apis/inbox';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import { flushSync } from 'react-dom';
import { Chip } from '@material-ui/core';
import Button from '../../../globalcomponents/Button';
import { useDispatch } from 'react-redux';
import { setSuccessInfo } from '../../../Redux/Reducer/GlobalError';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
function randomInteger() {
  let retval = Math.floor(Math.random() * 100000);
  return retval;
}
function AddQuestion(props) {
  const { setRows, setRowModesModel, rows, apiRef, canUpdateKB } = props;

  const handleClick = () => {
    const key = randomInteger();

    flushSync(() => {
      setRows(oldRows => [
        ...oldRows,
        { key, id: key, sno: oldRows.length + 1, q: '', isNew: true }
      ]);
      setRowModesModel(oldModel => ({
        ...oldModel,
        [key]: { mode: GridRowModes.Edit, fieldToFocus: 'q' }
      }));
    });

    //manually scrolling to the bottom
    apiRef.current.scrollToIndexes({
      rowIndex: rows.length - 2,
      colIndex: 0
    });
  };

  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        {canUpdateKB && (
          <Button startIcon={<AddIcon />} onClick={handleClick}>
            Add Question
          </Button>
        )}
      </div>

      <div>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
      </div>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const { data } = useContext(CheatsheetContext);
  const { userData, isZumaUser } = useContext(GlobalContext);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [columns, setColumns] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [updatedRowData, setUpdatedRowData] = useState({});
  const [oldRowData, setOldRowData] = useState({});
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const apiRef = useGridApiRef();
  const selectedCommunity = useRef(null);
  const dispatch = useDispatch();

  const communitiesList = data?.community_ids
    ?.map((id, idx) => {
      return {
        _id: id,
        name: data?.community_names[idx]
      };
    })
    .sort((a, b) => {
      if (a?.name < b?.name) return -1;
      else if (a?.name > b?.name) return 1;
      return 0;
    });

  const { permissions, name } = userData?.role;
  const canUpdateKB = permissions?.some(
    ({ name }) => name === 'KNOWLEDGE_BASE_UPDATE'
  );
  const canUpdateQuestions =
    canUpdateKB && !(name == 'CLIENT_ADMIN' || name == 'CLIENT_USER');

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const tenant_id = pathSegments[pathSegments.length - 1];
  useEffect(() => {
    if (data?.data) {
      setRows(
        data?.data.map((singleRow, index) => {
          return {
            ...singleRow,
            sno: index + 1,
            id: singleRow.q_id,
            isNew: false
          };
        })
      );
      const coumnKeysNew = data?.data ? Object.keys(data?.data[0]) : [];
      const columnKeysWithSNo = ['sno', ...coumnKeysNew, 'actions'];
      setColumns(
        columnKeysWithSNo?.map(name => {
          if (name === 'sno') {
            return {
              field: 'sno',
              headerName: 'S. No.',
              width: 60,
              headerClassName: 'knowledge-base-headers',
              hideable: false
            };
          } else if (name === 'q') {
            return {
              field: name,
              headerName: 'Question',
              width: 400,
              pinned: 'left',
              editable: true,
              headerClassName: 'knowledge-base-headers',
              hideable: false
            };
          } else if (name === 'actions') {
            return {
              field: 'actions',
              type: 'actions',
              width: 60,
              cellClassName: 'actions',
              headerClassName: 'knowledge-base-headers',
              getActions: ({ id }) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                  />
                ];
              }
            };
          } else {
            return {
              field: name,
              width: 400,
              editable: canUpdateKB,
              headerClassName: 'knowledge-base-headers'
            };
          }
        })
      );
    }
  }, [data]);

  const handleDeleteClick = id => () => {
    deleteQuestionAcrossCommunities(tenant_id, [
      {
        q_id: id,
        question: '',
        source: 'manual'
      }
    ]).then(res => {
      setRows(prevRows => prevRows.filter(row => row.id !== id));
      dispatch(setSuccessInfo('Question Deleted Successfully'));
    });
  };

  const processRowUpdate = (newRow, oldRow) => {
    if (newRow.isNew === true && newRow.q !== '') {
      addQuestionAcrossCommunities(tenant_id, [
        {
          question: newRow.q,
          source: 'manual'
        }
      ]).then(res => {
        let newQId = res?.data?.result?.qid_list[0];
        const updatedRow = {
          ...newRow,
          isNew: false,
          q_id: newQId,
          id: newQId
        };
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        dispatch(setSuccessInfo('Question Added Successfully'));
        return updatedRow;
      });
    } else {
      const updatedRow = { ...newRow, isNew: false };

      if (
        newRow[selectedCommunity.current] !== oldRow[selectedCommunity.current]
      ) {
        setShowConfirmationPopup(prevState => !prevState);
      }
      setUpdatedRowData({ ...updatedRow });
      setOldRowData(oldRow);
      return updatedRow;
    }
  };

  const batchSize = 10; // Set the batch size

  const performBatchUpdates = async (obj, oldAnswer) => {
    const updatePromises = [];

    selectedCommunities.forEach(({ _id }) => {
      let updateObj = {
        ...obj,
        source: 'manual',
        answer_source: ['manual']
      };

      if (oldAnswer === '' || oldAnswer === undefined) {
        updateObj.q_id = null;
        updateObj.question = updatedRowData.q;
        updateObj.intent = null;
      }

      if (oldAnswer === '' || oldAnswer === undefined) {
        updatePromises.push(addCheatsheetQuestionAnswer(_id, [updateObj]));
      } else {
        updateObj = { ...updateObj, question: updatedRowData.q, intent: null };
        updatePromises.push(updateCheatsheetQuestion(_id, [updateObj]));
      }
    });

    for (let i = 0; i < updatePromises.length; i += batchSize) {
      const batchPromises = updatePromises.slice(i, i + batchSize);

      await Promise.allSettled(batchPromises);

      if (i + batchSize < updatePromises.length) {
        console.log('adding delay');
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed
      }
    }
  };

  const updateCheatsheetQuestionHandler = e => {
    e.preventDefault();
    const oldAnswer = oldRowData[selectedCommunity.current];
    const newAnswer = updatedRowData[selectedCommunity.current];
    const obj = { q_id: updatedRowData.q_id, text: newAnswer };
    setRows(
      rows.map(row => {
        if (row.id === updatedRowData.id) {
          const updatedAnswers = {};
          selectedCommunities.map(
            ({ name }) => (updatedAnswers[`${name}`] = newAnswer)
          );
          return {
            ...updatedRowData,
            ...updatedAnswers
          };
        } else {
          return row;
        }
      })
    );

    performBatchUpdates(obj, oldAnswer);

    setShowConfirmationPopup(prevState => !prevState);
    setUpdatedRowData({});
    setOldRowData({});
  };

  const cancelUpdateHandler = e => {
    e.preventDefault();
    setRows(rows.map(row => row));
    setShowConfirmationPopup(false);
    setUpdatedRowData({});
    setOldRowData({});
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleCellDoubleClick = (params, event) => {
    selectedCommunity.current = params.field;
    setSelectedCommunities(
      communitiesList?.filter(item => item?.name === params.field)
    );
  };

  const renderConfirmationPopup = () => {
    if (!showConfirmationPopup) return null;
    return (
      <Popup>
        <PopupTitle showCloseBtn={false}>Are you sure?</PopupTitle>
        <PopupBody>
          <div style={{ color: '#525F80' }}>
            Do you wish to replicate this change for other communities?
          </div>
          <p className="cs-update-description">Question- {updatedRowData?.q}</p>
          <p className="cs-update-description">
            Answer- {updatedRowData[selectedCommunity.current]}
          </p>
          <div style={{ marginTop: '20px' }}>
            <FormControl
              className="margin-btm-0 update-cheatsheet-form"
              sx={{ m: 1, minWidth: '21rem' }}
            >
              <Autocomplete
                filterSelectedOptions
                freeSolo
                id="communities-list"
                disableClearable
                options={communitiesList}
                getOptionLabel={item => item?.name}
                onChange={(event, newValue) =>
                  setSelectedCommunities([...newValue])
                }
                defaultValue={communitiesList.filter(
                  item => item.name === selectedCommunity.current
                )}
                multiple
                disableCloseOnSelect
                includeInputInList={false}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      fontSize: '12px',
                      fontWeight: '400'
                    }}
                  >
                    {option?.name}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Select more"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: 'Select Reason',
                      style: {
                        borderRadius: '4px',
                        padding: '12px',
                        minWidth: '555px'
                      }
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        backgroundColor: '#5267FF', // Customize the chip background color
                        color: '#FFF' // Customize the chip text color
                      }}
                    />
                  ))
                }
              />
            </FormControl>
          </div>
        </PopupBody>
        <PopupFooter padding="20px">
          <div className="flex">
            <Button onClick={updateCheatsheetQuestionHandler}>Save</Button>
            <Button noStyle onClick={cancelUpdateHandler}>
              Cancel
            </Button>
          </div>
        </PopupFooter>
      </Popup>
    );
  };

  return (
    <div className="flex-col" style={{ marginLeft: '10px' }}>
      {isZumaUser && (
        <div className="inter-16px-500" style={{ marginTop: '20px' }}>
          {data?.tenant_name}
        </div>
      )}
      <div
        style={{
          height: 650,
          width: '92%',
          marginTop: '20px'
        }}
      >
        {renderConfirmationPopup()}

        <DataGridPro
          loading={!data?.data}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: AddQuestion
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              rows,
              apiRef: apiRef,
              canUpdateKB: canUpdateKB
            }
          }}
          initialState={{
            pinnedColumns: { left: ['sno', 'q'], right: ['actions'] },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                q_id: false
              }
            }
          }}
          onCellDoubleClick={handleCellDoubleClick}
          columnHeaderHeight={36}
          isCellEditable={params => {
            if (params.field === 'q') {
              if (params.row.isNew) {
                return true;
              } else return canUpdateQuestions;
            }
            return true;
          }}
          rowHeight={52}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? 'knowledge-base-row-even'
              : 'knowledge-base-row-odd'
          }
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            width: '100%'
          }}
          apiRef={apiRef}
        />
      </div>
    </div>
  );
}
