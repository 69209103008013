import React from 'react';
import CelebrationGif from '../../Media/celebration.gif';
import CheckCircleIcon from '../../Media/SelfServe/CheckCircleIcon';
import { useTheme } from 'styled-components';

const ArchiveConfirmation = ({ chatArchivedMsg, archiveClick }) => {
  const theme = useTheme();
  return (
    <div
      className="archive-confirm"
      style={{
        backgroundColor: theme?.background?.secondary
      }}
    >
      <div className="interaction-container">
        <div className="archive-msg">
          <CheckCircleIcon />
          <p style={{ margin: 0, paddingLeft: '10px' }}>{chatArchivedMsg}</p>
        </div>
        <img
          src={CelebrationGif}
          style={{ height: '100%', width: '100%' }}
        ></img>
      </div>
    </div>
  );
};

export default ArchiveConfirmation;
