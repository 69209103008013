import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './styles.module.scss';
import { Typography } from '@material-ui/core';
export const CustomInput = ({
  label = '',
  endAdornment,
  inputChange,
  value,
  name,
  clickHandler,
  step,
  disable
}) => {
  const handleChange = event => {
    if (inputChange) {
      inputChange(event);
    }
  };
  const onclickHandler = () => {
    clickHandler(step, name);
  };
  return (
    <div className={styles.container}>
      <FormHelperText id="outlined-weight-helper-text">{label}</FormHelperText>
      <OutlinedInput
        id="outlined-adornment-weight"
        value={value}
        disabled={disable}
        name={name}
        onChange={handleChange}
        endAdornment={
          endAdornment && (
            <InputAdornment onClick={onclickHandler} position="end">
              {!disable && endAdornment}
            </InputAdornment>
          )
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': label
        }}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export const CustomSelect = ({
  options = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 }
  ],
  label = 'Time zone ',
  selectChange,
  name = '',
  value = '',
  size = 'md',
  disabled = false
}) => {
  const onChangeHandler = e => {
    if (selectChange) {
      selectChange(e);
    }
  };
  return (
    <div className={`${styles.customSelect} ${styles[size]}`}>
      <label className={styles.selectLabel}>{label}</label>
      <FormControl fullWidth>
        <Select
          disabled={disabled}
          id={`demo-simple-select-${label}`}
          name={name}
          onChange={onChangeHandler}
          value={value}
        >
          {options.map(item => {
            return (
              <MenuItem key={item.label} value={item.value || item.label}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export const CustomCommunityWebsite = ({
  label = '',
  endAdornment,
  inputChange,
  value,
  name,
  clickHandler,
  step,
  disable,
  fetchedDate,
  fetchStatus
}) => {
  const [fetcKBStatus, setFetchKBStatus] = useState(fetchStatus);
  const [fetchedKBDateText, setFetchedKBDateText] = useState(fetchedDate);
  const dateAndStatusColor =
    fetcKBStatus == 'COMPLETE'
      ? '#50BE77'
      : fetcKBStatus == 'FAILED'
      ? '#D83A52'
      : '#CE821C';
  const handleChange = event => {
    if (inputChange) {
      inputChange(event);
    }
  };
  const onclickHandler = () => {
    clickHandler(step, name);

    setFetchKBStatus('IN_PROGRESS');
    setFetchedKBDateText('In Progress');
  };
  const [hasText, setHasText] = useState(value?.length > 0);
  const handleInputChange = event => {
    handleChange(event);
    setHasText(event.target.value.trim().length > 0);
  };
  return (
    <div className={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormHelperText id="outlined-weight-helper-text">
          {label} <span className={styles.requiredLabel}>*</span>
        </FormHelperText>
        {fetchedKBDateText && (
          <span
            className={styles.kbStatus}
            style={{
              color: `${dateAndStatusColor}`
            }}
            id="outlined-weight-helper-text"
          >
            {fetchedKBDateText}
          </span>
        )}
      </div>

      <OutlinedInput
        className="inputWithAdornment"
        id="outlined-adornment-weight"
        value={value}
        disabled={disable}
        name={name}
        onChange={handleInputChange}
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">
              {!disable && (
                <Typography
                  onClick={onclickHandler}
                  className={
                    hasText
                      ? 'custom-input-adornment'
                      : 'custom-input-adornment-blurred'
                  }
                >
                  {endAdornment}
                </Typography>
              )}
            </InputAdornment>
          )
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': label
        }}
        style={{ width: '100%' }}
      />
    </div>
  );
};
