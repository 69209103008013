import moment from 'moment-timezone';
export const getOwnerTransferOptions = (data, tenant) => {
  const apartmentData = data?.apartmentById;
  let obj = [];
  if (
    apartmentData?.meta?.knockKelseyUserName &&
    !apartmentData?.meta?.knockHouseAgentUserName
  ) {
    obj = [
      { name: 'kelsey', username: apartmentData?.meta?.knockKelseyUserName }
    ];
  } else if (
    apartmentData?.meta?.knockHouseAgentUserName &&
    !apartmentData?.meta?.knockKelseyUserName
  ) {
    obj = [
      {
        name: apartmentData?.name,
        username: apartmentData?.meta?.knockHouseAgentUserName
      }
    ];
  } else if (
    apartmentData?.meta?.knockKelseyUserName &&
    apartmentData?.meta?.knockHouseAgentUserName
  ) {
    obj = [
      { name: 'kelsey', username: apartmentData?.meta?.knockKelseyUserName },
      {
        name: apartmentData?.name,
        username: apartmentData?.meta?.knockHouseAgentUserName
      }
    ];
  } else {
    obj = [{ name: tenant?.name, username: tenant?.name }];
  }

  return obj;
};
let time;
export const debounce = (callBackFn, delay = 1000) => {
  return (...args) => {
    clearTimeout(time);
    time = setTimeout(() => callBackFn(...args), delay);
  };
};
export const getTimeZoneAbbreviation = timezone => {
  const currentTime = moment().tz(timezone);
  const abbreviation = currentTime.format('z');
  return abbreviation;
};

export const formatTimezone = timezone => {
  const abbreviation = getTimeZoneAbbreviation(timezone);
  return `${timezone} (${abbreviation})`;
};

export const throttle = (callback, delay) => {
  let lastCall = 0;

  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    callback(...args);
  };
};
export const truncateText = (text, length) => {
  return text.length > length ? `${text.slice(0, length)}...` : text;
};
export const clickToCopy = text => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log('Text copied to clipboard:', text);
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
    });
};
