import { FILTER_TOGGLE } from '../Constant';

const initialState = {
  FilterToggle: false
};
const Filter = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_TOGGLE:
      return { ...state, FilterToggle: action.data };
    default:
      return state;
  }
};

export default Filter;
