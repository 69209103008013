export function validURL(str) {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export const workingHours = [
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    status: 'closed',
    unavailable: [],
    day: 0
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 1
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 2
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 3
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 4
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 5
  },
  {
    available: [
      {
        hoursStart: 9,
        minsStart: 0,
        hoursEnd: 18,
        minsEnd: 0
      }
    ],
    unavailable: [],
    status: 'closed',
    day: 6
  }
];

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [
      // intlCode,
      '(',
      match[2],
      ')',
      ' ',
      match[3],
      '-',
      match[4],
      ''
    ].join('');
  }
  return null;
}
