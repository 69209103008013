import {
  GET_ALL_PROPERTY,
  GET_PLACE_AUTOSUGGEST,
  POST_PROPERTY,
  PROPERTY_BY_ID
} from '../Constant';
import { del, get, post, put } from '../networkClient';
import { ToastFireAction } from './Toast';

const PropertyById = data => ({
  type: PROPERTY_BY_ID,
  data: data
});
const postProperty = data => ({
  type: POST_PROPERTY,
  data: data
});
const GetAllProperty = data => ({
  type: GET_ALL_PROPERTY,
  data: data
});
const GetPlace = data => ({
  type: GET_PLACE_AUTOSUGGEST,
  data: data
});

export const getAllPropertyActionByPagination =
  (currentPage = 1) =>
  async dispatch => {
    await get(`/property/allbypagination-new?page=${currentPage}`)
      .then(resp => {
        if (resp.status === 200) {
          dispatch(GetAllProperty(resp.data));
        }
      })
      .catch(error => {});
  };

export const getAllPropertyAction = () => async dispatch => {
  await get('/property/all')
    .then(resp => {
      if (resp.status === 200) {
        dispatch(GetAllProperty(resp.data));
      }
    })
    .catch(error => {});
};

export const postPropertyAction = data => async dispatch => {
  await post('/property', data)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(postProperty(resp.data));
        dispatch(ToastFireAction(true, 'success', 'Property Added'));
        dispatch(getAllPropertyAction());
      } else {
        dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
      }
    })
    .catch(error => {});
};

export const updatePropertyAction = (data, id) => async dispatch => {
  await put('/property', data, { id: id }).then(resp => {
    if (resp.status === 200) {
      dispatch(getAllPropertyAction());
      dispatch(ToastFireAction(true, 'success', 'Property Updated'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};

export const DeletePropertyAction = id => async dispatch => {
  await del('/property', null, { id: id }).then(resp => {
    dispatch(getAllPropertyAction());
    dispatch(ToastFireAction(true, 'success', 'listing deleted'));
  });
};

export const PropertyByIdAction = id => async dispatch => {
  await get('/property', { id: id }).then(resp => {
    dispatch(PropertyById(resp.data));
  });
};

export const PropertyResetIdAction = data => async dispatch => {
  dispatch(PropertyById(data));
};

export const GetPlacesAction = data => async dispatch => {
  await get('/aux/place/autocomplete', { q: data }).then(resp => {
    dispatch(GetPlace(resp.data));
  });
};
