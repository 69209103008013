import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import {
  HeadingAStyle,
  ContainerA
} from '../../styles/propertystyles/Otherstyle';

const Appliances = props => {
  const SwitchTabs = (res, handle, Appliance) => {
    return (
      <Grid
        item
        xs={3}
        key={`${Math.floor(Math.random() * 1000)}-min`}
        style={ContainerA}
      >
        <Switch
          checked={
            Appliance &&
            Appliance.length > 0 &&
            Appliance.find(Appliance => Appliance.name === res.name)
          }
          onChange={event => handle(event, res._id)}
          name={res.name}
          color="primary"
        />
        <span style={{ marginRight: '40px' }}>
          <img
            src={res.url}
            style={{ position: 'absolute' }}
            alt="AirConIcon"
          />
        </span>
        <span style={HeadingAStyle}>{res.name}</span>
      </Grid>
    );
  };

  return (
    <>
      <Grid container>
        {props.data &&
          props.data.map(res => {
            return SwitchTabs(res, props.handleAppliance, props.Appliance);
          })}
      </Grid>
    </>
  );
};

export default Appliances;
