import React from 'react';

const RightArrow = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="12"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99505 4.99507C7.18252 5.1826 7.28783 5.4369 7.28783 5.70207C7.28783 5.96723 7.18252 6.22154 6.99505 6.40907L2.40205 11.0021C2.21576 11.1846 1.96494 11.2863 1.70412 11.285C1.44329 11.2837 1.19352 11.1795 1.00908 10.995C0.824644 10.8106 0.720446 10.5608 0.719129 10.3C0.717812 10.0392 0.819481 9.78836 1.00204 9.60207L4.90205 5.70207L1.00204 1.80207C0.819481 1.61578 0.717812 1.36497 0.719129 1.10414C0.720446 0.843312 0.824644 0.593541 1.00908 0.409105C1.19352 0.224668 1.44329 0.120471 1.70412 0.119153C1.96494 0.117836 2.21576 0.219506 2.40205 0.402069L6.99505 4.99507Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default RightArrow;
