import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import Modal from '../../../Component/Modal';
import Button from '../../../Component/Common/Button';
import styles from './styles.module.scss';
import { CustomInput, CustomSelect } from '../../../Component/Common/Input';
import { dupNewUser } from '../../../Redux/Actions/SelfServe';
import SuccessGif from '../../../Media/SuccessGif';

const ClientInfo = ({
  info,
  infoPopUPHandler,
  notification,
  setNotification
}) => {
  const initialState = {
    name: '',
    areaCode: '',
    pms: '',
    enableKnockCRM: '',
    followupToggle: true,
    enableFirstMessage: true,
    enableBot: false,
    PMSMappings: {}
  };
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const saveClickHandler = () => {
    const payload = {
      ...form,
      pms: form?.pms?.toLocaleLowerCase().replace(/ /g, ''),
      enableKnockCRM:
        form?.pms?.toLocaleLowerCase() !== 'entrata'
          ? form.enableKnockCRM === 'Knock'
          : false
    };

    dispatch(dupNewUser({ payload, showNotification }));
  };
  const handleForm = e => {
    const { value, name } = e.target;
    setForm(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };
  const clearHandler = e => {
    setForm(prev => {
      return {
        ...prev,
        ...initialState
      };
    });
  };

  const showNotification = () => {
    setNotification('Client account created');
  };

  const closeNotification = () => {
    setNotification('');
    infoPopUPHandler(false);
  };

  return (
    <Modal open={info} onClose={() => infoPopUPHandler(false)}>
      {notification ? (
        <div className={styles.success}>
          <div className="close-icon">
            <CloseIcon onClick={closeNotification} />
          </div>
          <SuccessGif />
          <p>{notification}</p>
        </div>
      ) : (
        <div className={styles.container}>
          <h6 className={styles.popUpHeader}>Client Information</h6>
          <div className={styles.formRow}>
            <CustomInput
              inputChange={handleForm}
              name={'name'}
              value={form.name}
              label={'Name'}
            />
          </div>
          <div className={styles.categories}>
            <div className={styles.box1}>
              <CustomSelect
                selectChange={handleForm}
                options={[
                  { label: 'Yardi' },
                  { label: 'Real page' },
                  { label: 'Entrata' }
                ]}
                size={'sm'}
                name={'pms'}
                label={'PMS'}
                value={form.pms}
              />
            </div>
            <div className={styles.box1}>
              <CustomSelect
                options={[{ label: 'Knock' }]}
                name={'enableKnockCRM'}
                disabled={form?.pms?.toLowerCase() === 'entrata'}
                size={'sm'}
                selectChange={handleForm}
                value={form.enableKnockCRM}
                label={'CRM'}
              />
            </div>
            <div className={styles.customArea}>
              <span>Or</span>
              <Button overRideClass={'transParentButton'} label={'Custom'} />
            </div>
          </div>
          <div className={styles.btnArea}>
            <Button
              overRideClass={!form.name && 'disableButton'}
              onClick={saveClickHandler}
              label={'Save'}
            />
            <Button onClick={clearHandler} label={'Clear'} />
          </div>
          <div className="close-icon">
            <CloseIcon onClick={() => infoPopUPHandler(false)} />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ClientInfo;
