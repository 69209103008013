import React from 'react';
import Button from '../../../Component/Common/Button';
import Modal from '../../../Component/Modal';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss';
import googleSheetIcon from '../../../Media/google_sheet.svg';

const UploadTemplates = ({ upload, setUpload }) => {
  return (
    <div>
      <Modal open={upload} onClose={() => setUpload(false)}>
        <div className={styles.uploadPopUp}>
          <div className="close-icon">
            <CloseIcon onClick={() => setUpload(false)} />
          </div>
          <h1 className={styles.popUpHeader}> uploadTemplates</h1>
          <div className={styles.popUpContent}>
            <div className={styles.box}>
              <img src={googleSheetIcon} alt="" />
              <p className={styles.title}>Drop your cheatsheet here</p>
              <div className={styles.browse}>
                <span>Or</span>
                <Button variant="inherit" overRideClass="clear-btn">
                  Browse
                </Button>
              </div>
            </div>
            <div className={styles.box}>
              <img src={googleSheetIcon} alt="" />
              <p className={styles.title}>Drop your cheatsheet here</p>
              <div className={styles.browse}>
                <span>Or</span>
                <Button variant="inherit" overRideClass="clear-btn">
                  Browse
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.btnGps}>
            <Button variant=" contained" overRideClass={'save-btn'}>
              Upload
            </Button>
            <Button variant="inherit" overRideClass="clear-btn">
              Clear
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadTemplates;
