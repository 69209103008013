import { FILE_UPLOAD, LOADING, FILE_UPLOAD_APART } from '../Constant';

const initialState = {
  Files: '',
  loading: false
};
const FileUpload = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD:
      return { ...state, Files: action.data, loading: false };
    case FILE_UPLOAD_APART:
      return { ...state, FilesApartment: action.data, loading: false };
    case LOADING:
      return { ...state, loading: action.data };
    default:
      return state;
  }
};

export default FileUpload;
