import React from 'react';
import { DrawerBodyWrapper } from '../drawer.styles';

const DrawerBody = ({
  padding,
  margin,
  background,
  border,
  borderRadius,
  children
}) => {
  return (
    <DrawerBodyWrapper
      padding={padding}
      margin={margin}
      background={background}
      border={border}
      borderRadius={borderRadius}
    >
      {children}
    </DrawerBodyWrapper>
  );
};

export default DrawerBody;
