import React from 'react';

const InboxIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 8.16671V19.8334H22.1667V8.16671H5.83333ZM22.1667 5.83337C23.45 5.83337 24.5 6.88337 24.5 8.16671V19.8334C24.5 21.1167 23.45 22.1667 22.1667 22.1667H5.83333C4.55 22.1667 3.5 21.1167 3.5 19.8334V8.16671C3.5 6.88337 4.55 5.83337 5.83333 5.83337H22.1667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41433 7.58337H3.64467C3.8185 8.09671 4.11017 8.57971 4.51967 8.98921L11.382 15.8504C11.7197 16.1884 12.1207 16.4565 12.5621 16.6395C13.0036 16.8224 13.4767 16.9166 13.9545 16.9166C14.4323 16.9166 14.9054 16.8224 15.3469 16.6395C15.7883 16.4565 16.1893 16.1884 16.527 15.8504L23.3893 8.98921C23.7977 8.57971 24.0893 8.09671 24.2632 7.58337H21.4947L14.8773 14.2007C14.7561 14.322 14.6121 14.4183 14.4537 14.4839C14.2953 14.5496 14.1254 14.5834 13.9539 14.5834C13.7824 14.5834 13.6126 14.5496 13.4541 14.4839C13.2957 14.4183 13.1517 14.322 13.0305 14.2007L6.41433 7.58337Z"
        fill="white"
      />
    </svg>
  );
};

export default InboxIcon;
