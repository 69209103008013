// Inbox >

// Inbox.js
export const flexContainer = {
  display: 'flex',
  position: 'fixed'
};

// LeadpropertyInfo.js
export const CardStyle = {
  width: '280px',
  height: '95%',
  position: 'fixed',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  overflow: 'scroll',
  zIndex: 2
  // marginLeft: "1024px",
};
export const contentStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  marginBottom: '100px'
};
export const dflex = {
  display: 'flex'
};
export const Sb = {
  justifyContent: 'space-between'
};
export const mb = {
  marginBottom: '10px',
  marginTop: '10px'
};
export const mbflex = {
  marginBottom: '5px'
};
export const mr = {
  marginRight: '10px'
};
export const NameStyle = {
  paddingTop: '10px',
  color: '#23165f',
  fontWeight: '700',
  fontSize: '18px'
};
export const hLine = {
  width: '65px',
  paddingTop: '3px',
  marginRight: '5px'
};
export const hlineR = {
  ...hLine,
  marginLeft: '5px'
};
export const grayCl = {
  color: 'gray'
};
export const money = {
  color: '#23165f',
  fontSize: '16px',
  marginTop: 12
};
export const empty = {
  textAlign: 'center',
  font: 'normal 16px Inter'
};
export const EditIconStyle = {
  cursor: 'pointer',
  color: '#a2a1a1'
};
export const acStyle = {
  color: '#ff6f62',
  fontFamily: 'Inter',
  fontSize: 14
};
export const CrSt = {
  cursor: 'pointer'
};

export const NameEditStyle = {
  marginLeft: '10px'
};

// LeadStageHeader.js

export const tabContentStyle = {
  color: '#ffffff',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '400',
  paddingTop: '11px'
};
export const commonStyleDiv = {
  width: '150px',
  height: '40px',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '-5px'
};

export const commonStyleDivS = {
  width: '150px',
  height: '40px',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '-5px'
  // marginLeft: "-270px",
};

// MsgScreen.js

export const container = { position: 'relative', width: '85vw' };
export const container2 = { position: 'relative', width: '89vw' };
export const rowOneStyle = {
  marginBottom: '100px',
  marginTop: '50px',
  height: '63vh',
  overflow: 'scroll'
};
export const rowOneStyleEmail = {
  ...rowOneStyle,
  height: '60vh'
};
export const rowOneStyleat = {
  width: '100%'
  // ...rowOneStyle,
  // marginBottom: "200px",
  // height: "61vh",
};
export const rowOneStyleat2 = {
  ...rowOneStyle,
  // marginBottom: "200px",
  height: '63vh'
};
export const rowOneStyleatEmail = {
  // ...rowOneStyleat,
  // height: "57vh",
  width: '100%'
};
export const rowTwoStyle = {
  position: 'fixed',
  bottom: '0',
  zIndex: '0',
  left: '400px',
  right: '290px',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const rowTwoStyleS = {
  position: 'fixed',
  bottom: '0',
  zIndex: '0',
  left: '101px',
  right: '290px',
  marginLeft: 'auto',
  marginRight: 'auto'
};
export const InboxLeadOpen = {
  // height: "270px",
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'absolute',
  bottom: '0%',
  borderRadius: '5px',
  marginLeft: '0px'
};
export const InboxLeadClose = {
  // height: "270px",
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  bottom: '0%',
  borderRadius: '5px'
};
export const LeadBoxOpen = {
  // height: "270px",
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  // bottom:'0%',
  borderRadius: '5px'
  // marginLeft:"-85px"
};
export const LeadBoxClose = {
  // height: "270px",
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  // bottom:'0%',
  borderRadius: '5px'
  // marginLeft:'-85px'
};
export const EmailInboxLeadOpen = {
  // height: "270px",
  // backgroundColor: "#f7f7f7",
  // display: "flex",
  // flexDirection:"column",
  // width: "52%",
  // paddingTop: "10px",
  // position:'fixed',
  // bottom:'0%',
  // borderRadius:'5px',
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'absolute',
  // bottom:'0%',
  borderRadius: '5px'
  // marginLeft:'0px'
};
export const EmailInboxLeadClose = {
  // height: "270px",
  backgroundColor: '#f7f7f7',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  bottom: '0%',
  borderRadius: '5px'
};
export const EmailLeadBoxOpen = {
  // height: "270px",
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  bottom: '0%',
  borderRadius: '5px'

  // marginLeft:"-85px"
};
export const EmailLeadBoxClose = {
  // height: "270px",
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '10px',
  // position:'fixed',
  bottom: '0%',
  borderRadius: '5px'

  // marginLeft:'-85px'
};
export const FooterCont2 = {
  // height: "225px",
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  paddingTop: '10px',
  position: 'fixed',
  borderRadius: '5px',
  bottom: '0%',
  marginLeft: '-80px'
};
export const FooterContNew = {
  width: '106%',
  marginLeft: '-80px'
};
// export const FooterContEmail = {
//   ...FooterCont
// };
export const FooterContAT = {
  height: '133px',
  backgroundColor: '#fff',
  paddingTop: '10px',
  bottom: '0%',
  marginLeft: '-80px'
};
export const FooterContnewAT = {
  height: '133px',
  backgroundColor: '#fff',
  paddingTop: '10px',
  bottom: '0%'
};
export const FooterContATEmail = {
  ...FooterContAT,
  height: '200px'
};
export const FooterContnewATEmail = {
  backgroundColor: '#fff',
  paddingTop: '10px',
  bottom: '0%',
  marginLeft: '0px',
  height: '200px'
};

export const leadStage = {
  zIndex: '1',
  left: '400px',
  right: '298px',
  marginLeft: 'auto',
  marginRight: 'auto',
  background: '#ebebeb',
  top: '0',
  paddingTop: '89px',
  position: 'fixed'
};
export const msgIcon = {
  paddingLeft: '10px',
  fontSize: '26px',
  color: '#22165f',
  height: '100%',
  with: '100%',
  cursor: 'pointer',
  marginLeft: '0.5rem'
};
export const TemplateBtn = {
  height: '40px',
  marginLeft: '10px',
  marginTop: '47px',
  color: '#22165f',
  background: 'white',
  border: '1px solid'
};

// SelectLead.js

export const CardSLStyle = {
  width: '280px',
  height: '90%',
  position: 'fixed',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  overflow: 'scroll',
  paddingBottom: '10px',
  zIndex: 2
};
export const divStyle = {
  color: 'rgb(34 22 95)',
  height: '100px',
  font: 'normal 14px Inter',
  cursor: 'pointer',
  borderBottom: '1px solid #ebebeb'
};
export const divStyleActive = {
  ...divStyle,
  background: '#f1f5ff'
};
export const leadState = {
  fontSize: '12px',
  color: 'rgb(34, 22, 95)',
  padding: '6px',
  display: 'flex',
  justifyContent: 'space-between'
};
export const activeLead = {
  ...leadState
};
export const leadName = {
  marginLeft: '15px',
  flex: 2,
  fontSize: '14px',
  fontWeight: 700
};
export const ContentSLStyle = {
  padding: 0,
  paddingBottom: '100px'
};
export const timeStyle = {
  paddingTop: '6px',
  fontSize: '11px',
  color: '#828282'
};
export const activeTime = {
  ...timeStyle
};
export const propertyStyle = {
  marginLeft: '15px',
  color: '#525f80',
  fontSize: '14px'
};
export const empty1 = {
  textAlign: 'center',
  paddingTop: '15px',
  font: 'normal 16px Inter'
};

export const empty2 = {
  textAlign: 'center',
  paddingTop: '75px',
  font: 'normal 16px Inter'
};
export const LeadDiv = {
  background: '#cdccec',
  width: '100%',
  borderRadius: '50px',
  maxWidth: '86px',
  padding: '5px',
  paddingLeft: '10px',
  fontSize: '11px'
};
export const latestIcon = {
  position: 'absolute',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: '#20065F',
  right: '8px',
  marginTop: '5px'
};
export const latestIcon1 = {
  position: 'absolute',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: '#FF6100',
  right: '8px',
  marginTop: '5px'
};

//styleBlock
export const cntStyl = {
  width: '300px',
  height: '365px'
};
export const SearchBox = {
  padding: '0px 10px 5px 10px',
  borderBottom: '1px solid #c7c7c7'
};
export const Templates = {
  font: 'normal 16px Inter',
  paddingTop: '5px',
  color: '#23165f',
  marginLeft: '2px',
  marginRight: '10px'
};
export const dflexcnt = {
  display: 'flex',
  padding: '8px',
  background: '#f1f5fe',
  color: '#23165f',
  cursor: 'pointer'
};
export const dflexcntUn = {
  ...dflexcnt,
  background: '#fff'
};
export const footer = {
  position: 'absolute',
  bottom: '0',
  font: 'normal 16px Inter',
  width: '100%',
  borderTop: '1px solid #c7c7c7'
};
export const footerCont = {
  padding: '8px',
  display: 'flex'
};
export const footerContdv = {
  marginLeft: '2px',
  cursor: 'pointer'
};

// Inbox > modal > StageChange.js

export const BtnContainer = {
  marginTop: '30px',
  textAlign: 'center',
  marginLeft: '25px'
};
export const Cancel = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  border: '1px solid #023e8a',
  color: '#023e8a',
  backgroundColor: '#ffffff',
  marginRight: '20px'
};
export const SendFeed = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#023e8a',
  color: '#fff',
  marginRight: '20px'
};

export const DeleteButton = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#f44336',
  color: '#fff',
  marginRight: '20px'
};
export const FontStyle = {
  textAlign: 'center',
  font: 'normal bold 18px Inter',
  marginTop: '-10px',
  color: '#023e8a'
};

// Inbox > InboxChatCard > IncommingMsg.js

export const containerIMStyle = {
  width: '750px',
  minHeight: '90px',
  marginBottom: '20px',
  display: 'flex'
  // flexDirection: "row-reverse",
};
export const mainIMCont = {
  display: 'flex',
  justifyContent: 'flex-start'
};

export const NameIMStyle = {
  font: '11px Inter',
  color: 'rgb(130, 130, 130)',
  paddingLeft: '10px'
};
export const timeIMStyle = {
  font: 'normal 11px Inter',
  color: '#828282'
};
export const FlexIMContent = {
  display: 'flex'
};
export const CircleIMBlock = {
  height: '50px',
  width: '50px',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '50%',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '25px',
  fontFamily: 'Inter',
  color: '#525f80',
  marginTop: '12px',
  marginLeft: '10px',
  boxShadow: '0px 1px 6px 0px'
};
export const TNIMBlock = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2px'
};
export const blockIM = {
  width: '275px',
  marginLeft: '5px',
  background: '#b0b8cb',
  color: '#fff',
  font: '16px Inter',
  wordBreak: 'break-word',
  padding: '10px',
  borderRadius: '0px 10px 10px 10px'
};
export const blockhangeIM = {
  ...blockIM,
  background: '#23165f',
  color: 'white'
};
export const blockhangeIMEmail = {
  ...blockIM,
  background: '#fff',
  color: '#000000',
  width: 420,
  maxHeight: 240,
  overflow: 'hidden'
};

// Inbox > InboxChatCard > OutgoingMsg.js

export const containerStyle = {
  width: '750px',
  minHeight: '90px',
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'flex-end'
};
export const mainCont = {
  display: 'flex',
  justifyContent: 'flex-end'
};
export const block = {
  width: '275px',
  marginLeft: '5px',
  background: '#e8584d',
  color: '#fff',
  font: '16px Inter',
  wordBreak: 'break-word',
  padding: '10px',
  borderRadius: '10px 0px 10px 10px',
  marginBottom: '0px'
};
export const block2 = {
  width: '275px',
  marginLeft: '5px',
  background: '#4CD099',
  color: '#fff',
  font: '16px Inter',
  wordBreak: 'break-word',
  padding: '10px',
  borderRadius: '10px 0px 10px 10px',
  marginBottom: '0px'
};
export const blockhange = {
  ...block,
  background: '#23165f',
  color: 'white'
};
export const blockhange1 = {
  ...block,
  background: 'orange',
  color: 'white'
};
export const blockChangeEmail = {
  ...block,
  background: '#fff',
  color: '#000000',
  width: 420,
  maxHeight: 140,
  overflow: 'hidden'
};
export const FlexContent = {
  display: 'flex',
  paddingRight: '15px'
};
export const NameOMStyle = {
  font: '11px Inter',
  color: 'rgb(130, 130, 130)'
};
export const timeOMStyle = {
  font: 'normal 11px Inter',
  color: '#828282',
  paddingLeft: '10px'
};

export const NameOMStyle1 = {
  font: '11px Inter',
  color: 'rgb(130, 130, 130)',
  display: 'flex',
  marginTop: '4px',
  marginLeft: '37%'
};
export const NameOMStyle2 = {
  font: '11px Inter',
  color: '#4CD099',
  display: 'flex',
  marginTop: '4px',
  marginLeft: '37%'
};
export const timeOMStyle1 = {
  font: 'normal 11px Inter',
  color: '#828282',
  paddingLeft: '10px',
  marginTop: '13px'
};
export const TNBlock = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2px'
};
export const CircleBlock = {
  height: '50px',
  width: '50px',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '50%',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '25px',
  fontFamily: 'Inter',
  color: '#525f80',
  marginTop: '12px',
  marginLeft: '10px',
  boxShadow: '0px 1px 6px 0px'
};

export const ReadMoreSt = {
  marginTop: '2px',
  color: '#ef6d60',
  marginLeft: '2px'
};
export const ReadModalSt = {
  width: '700px',
  maxHeight: '500px',
  overflow: 'scroll'
};
