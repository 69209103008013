import React from 'react';

const ForwardIcon = ({ handleClick, style }) => {
  return (
    <svg
      onClick={handleClick}
      style={style}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6039 14.0209L11.0425 12.4099C11.0425 12.4099 10.6048 12.0468 10.0651 12.3468L9.19455 12.8591C9.19455 12.8591 8.8388 13.0279 8.52715 12.6314C7.55449 11.4917 6.85761 10.3064 6.36799 8.89164C6.17544 8.33981 6.50497 8.20056 6.50497 8.20056L7.14363 7.82509C7.67226 7.50738 7.57705 6.94747 7.57705 6.94747L7.20533 4.66697C6.96111 3.62729 5.36395 3.9326 4.74771 4.70023C3.31527 6.48084 4.25197 8.8316 4.98755 10.6057C5.33643 11.4483 5.88657 12.2911 6.35172 12.9685C7.16726 14.1527 9.02045 16.4025 11.2804 16.0552C12.2522 15.905 13.3822 14.7522 12.6039 14.0209Z"
        fill="#42526E"
      />
      <path
        d="M10.7859 8.14062C10.5993 8.25885 10.5439 8.50597 10.6621 8.69259C10.7803 8.8792 11.0275 8.93464 11.2141 8.81641L10.7859 8.14062ZM15.3024 6.08756C15.3508 5.872 15.2152 5.65806 14.9997 5.6097L11.487 4.8217C11.2714 4.77335 11.0575 4.90889 11.0091 5.12445C10.9608 5.34001 11.0963 5.55395 11.3119 5.6023L14.4343 6.30274L13.7338 9.42515C13.6855 9.6407 13.821 9.85464 14.0366 9.903C14.2521 9.95136 14.4661 9.81581 14.5144 9.60026L15.3024 6.08756ZM11.2141 8.81641L15.1262 6.33789L14.698 5.66211L10.7859 8.14062L11.2141 8.81641Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default ForwardIcon;
