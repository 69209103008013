import React from 'react';
import Icon from '../../../globalcomponents/Icon';
import {
  CreatedByContainer,
  EventHeader,
  TourCancelledEventContainer,
  TourComponent
} from './styles.chatcomponents';
import moment from 'moment';
import SyncIcon from '../../../Media/InboxV1/SyncIcon';
import { SenderInitial } from '../styles.inboxv2';

const TourCancelledEvent = ({ props }) => {
  const tourCancelledEventprops = props.props;
  const { meta, timeStamp, body } = tourCancelledEventprops;

  const eventHeader = body;
  return (
    <TourComponent>
      <TourCancelledEventContainer>
        <EventHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              color: '#FFFFFF !important'
            }}
          >
            <Icon src="event-tour-successful-header.svg" />
            <div>{eventHeader}</div>
          </div>

          <div className="nunito-10px-400" style={{ padding: '5px 0' }}>
            <Icon src="sync-icon-white.svg" />
          </div>
        </EventHeader>
      </TourCancelledEventContainer>

      <CreatedByContainer>
        <div style={{ padding: '5px 0' }}>
          {moment(timeStamp).format('MMM DD, YYYY hh:mm a')}
        </div>
        {meta?.sync &&
        meta?.sync?.length &&
        meta?.sync[0].syncStatus === 'Success' ? (
          <div style={{ padding: '5px 0', display: 'flex' }}>
            <SyncIcon /> Sync'd w {meta?.sync[0].syncSource}
          </div>
        ) : null}
      </CreatedByContainer>
    </TourComponent>
  );
};

const TourCancelled = props => {
  const { type, customerName, meta } = props.props;
  return (
    <>
      <TourCancelledEvent props={props} />
      <SenderInitial>
        {type === 'incoming'
          ? customerName
            ? customerName[0]
            : 'C'
          : meta?.agentName
          ? meta?.agentName[0]
          : 'K'}
      </SenderInitial>
    </>
  );
};

export default TourCancelled;
