import { ModalFooter } from '../../Popup/popup.styles';
const PopupFooter = ({
  children,
  justifyContent = 'flex-start',
  padding = '1.25rem 1.25rem 0'
}) => {
  return (
    <ModalFooter
      key="popup-footer"
      justifyContent={justifyContent}
      padding={padding}
    >
      {children}
    </ModalFooter>
  );
};

export default PopupFooter;
