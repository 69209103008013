import React from 'react';
import Icon from '../../../globalcomponents/Icon';
import {
  CreatedByContainer,
  EventHeader,
  LeadInfoComponent,
  LeadInfoContainer
} from './styles.chatcomponents';
import moment from 'moment';
import { SenderInitial } from '../styles.inboxv2';
import SyncIcon from '../../../Media/InboxV1/SyncIcon';
import { useTheme } from 'styled-components';

const LeadInfoEvent = ({ props }) => {
  const theme = useTheme();
  const leadEventProps = props.props;
  const { meta, body, timeStamp } = leadEventProps;

  return (
    <LeadInfoComponent>
      <LeadInfoContainer>
        <EventHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            <Icon
              src={`event-info${theme.state === 'light' ? '' : '-dark'}.svg`}
            />
            <div
              dangerouslySetInnerHTML={{ __html: body }}
              style={{ paddingLeft: '5px' }}
            />
          </div>
        </EventHeader>
      </LeadInfoContainer>

      <CreatedByContainer>
        <div style={{ padding: '5px 0' }}>
          {moment(timeStamp).format('MMM DD, YYYY hh:mm a')}
        </div>
        {meta?.sync &&
        meta?.sync?.length &&
        meta?.sync[0].syncStatus === 'Success' ? (
          <div style={{ padding: '5px 0', display: 'flex' }}>
            <SyncIcon /> Sync'd w {meta?.sync[0].syncSource}
          </div>
        ) : null}
      </CreatedByContainer>
    </LeadInfoComponent>
  );
};

const LeadInfo = props => {
  const { type, customerName, meta } = props.props;

  return (
    <>
      <LeadInfoEvent props={props} />
      <SenderInitial>
        {type === 'incoming'
          ? customerName
            ? customerName[0]
            : 'C'
          : meta?.agentName
          ? meta?.agentName[0]
          : 'K'}
      </SenderInitial>
    </>
  );
};

export default LeadInfo;
