import { style } from '@mui/system';
import styled from 'styled-components';

const WContainer = styled.div`
  cursor: pointer !important;
  color: #42526e;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: max-content;
  cursor: pointer;
`;

const WTextArea = styled.textarea`
  width: 260px;
  height: 100px;
  border-radius: 2px;
  border: 0.5px solid ${({ theme }) => theme?.border?.primary};
  padding: 10px;
  margin-top: 10px;
  color: ${({ theme }) => theme.text.primary};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  background: ${({ theme }) => theme.background.secondary};
`;

const Labels = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px;
  margin-top: ${({ marginTop }) => marginTop ?? '0px'};
`;

const DeletePopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

const DateWaitListWrapper = styled.div`
  border: ${({ theme }) => `0.5px solid ${theme?.border?.primary}`};
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  .react-datepicker-wrapper {
    width: 100%;
    display: flex;
`;

const DeleteInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  // div {
  //   img {
  //     width: 80%;
  //   }
  // }
  span {
    color: ${({ theme }) => theme?.text?.primary};
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
  }
`;
export {
  WContainer,
  WTextArea,
  Labels,
  DeletePopup,
  DeleteInfo,
  DateWaitListWrapper
};
