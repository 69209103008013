import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useStyles } from './styles';
import { Checkbox, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ZDropDown = ({
  options = [],
  disabled = false,
  name,
  error,
  helperText,
  handleChange,
  value = '',
  label = 'Select',
  styles,
  multiple = false
}) => {
  const classes = useStyles();

  const listToLoop = options.filter(option => {
    if (option !== null) {
      return option;
    }
  });

  const getSelected = id => {
    listToLoop.map(optionItem => {
      if (optionItem._id === id) {
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <FormControl
      className={disabled === true ? classes.disableDropdown : classes.dropdown}
      sx={styles}
      fullWidth
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        error={error}
        disabled={disabled}
        helperText={helperText}
        value={value}
        renderValue={value => {
          return multiple ? (
            value.map(item => {
              return (
                <p className={classes.valueStyle}>
                  <span>{item.name}</span>
                  <CloseIcon
                    sx={{
                      fontSize: 18,
                      color: '#FF6F62',
                      position: 'relative',
                      top: 4
                    }}
                  />
                </p>
              );
            })
          ) : (
            <p>{value.name}</p>
          );
        }}
        label={label}
        multiple={multiple}
        name={name}
        onChange={e => handleChange(e.target.name, e.target.value)}
      >
        {listToLoop.map((item, i) => (
          <MenuItem key={i} value={item}>
            {multiple ? (
              <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: '#FF6F62'
                  }
                }}
                checked={getSelected(item._id)}
              />
            ) : null}
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '12px',
                color: '#393939'
              }}
            >
              {item.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ZDropDown;
