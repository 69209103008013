import React from 'react';

const SearchIcon = ({ style }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="search">
        <rect width="16" height="16" fill="white" fill-opacity="0.01" />
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9572 10.0563L13.5838 12.7296C13.7015 12.8568 13.765 13.0248 13.761 13.198C13.7569 13.3712 13.6855 13.5361 13.562 13.6576C13.4385 13.7791 13.2726 13.8478 13.0993 13.849C12.926 13.8503 12.7591 13.784 12.6338 13.6643L10.0085 10.9936C8.99553 11.7567 7.73043 12.1064 6.46935 11.9718C5.20828 11.8372 4.04547 11.2284 3.21638 10.2687C2.38729 9.30897 1.95388 8.07006 2.00389 6.80281C2.05391 5.53555 2.58363 4.33466 3.48578 3.44329C4.38793 2.55191 5.5951 2.03667 6.86286 2.00188C8.13063 1.9671 9.36424 2.41537 10.3139 3.25594C11.2636 4.0965 11.8584 5.26654 11.9778 6.52914C12.0972 7.79174 11.7324 9.05255 10.9572 10.0563V10.0563ZM6.99984 10.6663C7.9723 10.6663 8.90493 10.28 9.59256 9.59234C10.2802 8.9047 10.6665 7.97207 10.6665 6.99961C10.6665 6.02715 10.2802 5.09452 9.59256 4.40689C8.90493 3.71925 7.9723 3.33295 6.99984 3.33295C6.02738 3.33295 5.09475 3.71925 4.40711 4.40689C3.71948 5.09452 3.33317 6.02715 3.33317 6.99961C3.33317 7.97207 3.71948 8.9047 4.40711 9.59234C5.09475 10.28 6.02738 10.6663 6.99984 10.6663V10.6663Z"
          fill="#42526E"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
