import Button from '../../../globalcomponents/Button';
import InputLabelComponent from '../../../globalcomponents/InputLabelComponent';
import Popup from '../../../globalcomponents/Popup';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import DeleteIcon from '../../../Media/InboxV1/DeleteIcon';
import {
  DeletWrapper,
  LoaderBlue,
  LoaderWhite,
  PurchaseText
} from '../styles.callToText';

const AddPopup = ({
  show,
  handleClose,
  onSave,
  editData,
  setEditData,
  isEditable,
  onPurchase,
  sourceEditLoading,
  showTick = true,
  purchaseLoader,
  handleDeleteVanityNumber
}) => {
  return show ? (
    <Popup show={show} width={'400px'} onClose={handleClose}>
      <PopupTitle onClose={handleClose} showCloseBtn>
        {isEditable ? `Edit ${editData?.source}` : `Add New Source`}
      </PopupTitle>
      <PopupBody>
        {isEditable ? null : (
          <InputLabelComponent
            isRequired
            label={'Source'}
            value={editData?.source ?? ''}
            setValue={value => setEditData({ ...editData, source: value })}
          />
        )}

        <InputLabelComponent
          value={editData?.sscNumber ?? ''}
          marginTop={isEditable ? '0px' : '20px'}
          isRequired
          label={'SSC Number'}
          setValue={value => setEditData({ ...editData, sscNumber: value })}
        />

        <InputLabelComponent
          value={editData?.vanityNumber}
          isRequired
          marginTop="20px"
          label={'Vanity Number'}
          setValue={value =>
            setEditData({ ...editData, vanityNumber: editData?.vanityNumber })
          }
          isDeleteable={editData?.vanityNumber && !editData?.isSuggested}
        />

        {purchaseLoader ? (
          <LoaderWhite top={isEditable ? '82%' : '88%'} />
        ) : null}
        {editData?.isSuggested && !sourceEditLoading && !purchaseLoader ? (
          <PurchaseText
            disabled={!editData?.source}
            onClick={onPurchase}
            top={isEditable ? '84%' : '90%'}
          >
            Purchase Suggested Number
          </PurchaseText>
        ) : null}
        {!editData?.isSuggested && editData?.vanityNumber ? (
          <DeletWrapper
            onClick={handleDeleteVanityNumber}
            top={isEditable ? '82%' : '88%'}
          >
            <DeleteIcon height="18" />
          </DeletWrapper>
        ) : null}
        {/* <SuccessIconC2T showFull /> */}
      </PopupBody>
      <PopupFooter padding="20px" justifyContent="">
        <Button onClick={onSave}>
          {sourceEditLoading ? (
            <>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <LoaderBlue />
            </>
          ) : showTick ? (
            <>
              &nbsp;&nbsp;&nbsp;
              <img src="/successIcon.svg" alt="tick-icon" />
              &nbsp;&nbsp;&nbsp;
            </>
          ) : (
            'Save'
          )}
        </Button>
        <Button
          noStyle={true}
          onClick={() => setEditData({ source: editData?.source })}
        >
          Clear
        </Button>
      </PopupFooter>
    </Popup>
  ) : null;
};

export default AddPopup;
