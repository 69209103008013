import React from 'react';

const EditIcon = ({ onClick, style, width, index }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width={width || '16'}
      height={width || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
    >
      <rect width={width} height={width} fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        data-editIndex={index ?? 0}
        d="M2.68001 12.8198C2.65793 12.9278 2.66298 13.0396 2.6947 13.1452C2.72642 13.2508 2.78382 13.3468 2.86177 13.4247C2.93971 13.5027 3.03576 13.5601 3.14133 13.5918C3.2469 13.6235 3.35868 13.6286 3.46667 13.6065L6.00668 13.0865L3.20001 10.2798L2.68001 12.8198ZM6.62734 11.0732L5.21401 9.65984L10.8707 3.99984H10.872L12.286 5.41384L6.62667 11.0738L6.62734 11.0732ZM13.2293 4.47117L11.816 3.05651C11.6921 2.93256 11.5449 2.8343 11.3829 2.76738C11.2209 2.70045 11.0473 2.66617 10.872 2.66651C10.5307 2.66651 10.1893 2.79651 9.92867 3.05651L3.42401 9.56184L6.72401 12.8618L13.2287 6.35651C13.4786 6.10647 13.6191 5.76739 13.6191 5.41384C13.6191 5.06029 13.4786 4.72121 13.2287 4.47117H13.2293Z"
        fill="#525F80"
      />
    </svg>
  );
};

export default EditIcon;
