import React, { useEffect, useState } from 'react';
import { Loader, SuccessIconContainer, TickIcon } from './success-icon.styles';

const SuccessIcon = ({
  setCurrStep,
  newPassword,
  handleValueChange,
  background = '#42526e1a'
}) => {
  const [showFullIcon, setShowFullIcon] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  useEffect(() => {
    if (!showIcon) {
      if (newPassword === 'error') {
        setCurrStep(0);
        handleValueChange('1');
        return;
      }
      setTimeout(() => setShowFullIcon(true), 1000);
      setTimeout(() => setShowIcon(true), 500);
      setTimeout(() => setCurrStep(2), 1500);
    }
  }, []);
  return (
    <>
      {' '}
      {showIcon ? (
        <SuccessIconContainer background={background}>
          <TickIcon showFull={showFullIcon} />
        </SuccessIconContainer>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SuccessIcon;
