import React, { useContext, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import './sop-agent-notes.scss';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { useMutation } from '@apollo/client';
import { updateUserNotes } from '../../../gql/mutations/updateUser';
import { debounce } from '../inboxUtils';

const AgentNotes = ({ userNotes, updateNotesHandler, darkMode }) => {
  const { userData } = useContext(GlobalContext);
  const [editorText, setEditorText] = useState(userNotes);

  const [updateNotes] = useMutation(updateUserNotes, {
    onCompleted: updateNotesHandler
  });

  const quill = new Quill('custom-editor', {
    modules: {
      toolbar: {
        container: '#toolbar-11'
      }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    propTypes: {
      placeholder: 'Type here ..'
    },
    formats: [
      'bold',
      'italic',
      'underline',
      'list',
      'link',
      'bullet',
      'indent',
      'color'
    ],
    theme: 'bubble'
  });

  const handleChange = html => {
    setEditorText(html);
    debounce(
      () =>
        updateNotes({
          variables: {
            _id: userData?._id,
            record: { notes: html }
          }
        }),
      3000
    )();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <ReactQuill
        className={`custom-editor${darkMode ? '-dark' : ''}`}
        value={editorText}
        onChange={handleChange}
        formats={quill.formats}
        modules={quill.modules}
        editor={quill}
        placeholder="Type here.."
      />
    </div>
  );
};

export default AgentNotes;
