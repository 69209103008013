import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { deleteWorkflowAction } from '../../Redux/Actions/workflow';
import Modal from '@material-ui/core/Modal';
import NewRules from '../../Container/Workflow/newRules/NewRules';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const Wtable = props => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [WorkFlowUpdateData, setWorkFlowUpdateData] = useState();

  //function
  const handleOpenModal = data => {
    setWorkFlowUpdateData(data);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModalP = () => {
    setOpenP(false);
  };

  const openPropertyModal = (dataP, dataA) => {
    if (dataP.length > 0 || dataA.length > 0) {
      setTableData([...dataP, ...dataA]);
      setOpenP(true);
    }
  };
  //style
  const dflex = {
    display: 'flex'
  };
  const dfg = {
    ...dflex,
    flex: 1,
    alignItems: 'center'
  };
  const Header = {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: '600',
    color: '#22165f'
  };
  const Content = {
    ...Header,
    fontWeight: '400',
    color: '#22165f'
  };
  const cursor = {
    cursor: 'pointer'
  };
  const overflow = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '151px',
    textOverflow: 'ellipsis'
  };
  const propMdal = {
    height: 300,
    width: 300
  };
  const pModaldiv = {
    overflow: 'scroll',
    height: '300px'
  };
  const borderHeading = {
    borderBottom: '1px solid #bbb4b4',
    padding: '20px'
  };
  const border = {
    borderBottom: '1px solid #bbb4b4',
    padding: '5px 20px'
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <NewRules WUdata={WorkFlowUpdateData} update={true} setOpen={setOpen} />
      </Modal>
      <Modal
        open={openP}
        onClose={handleCloseModalP}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card style={propMdal}>
          <CardContent style={{ padding: 0 }}>
            <div style={{ ...Header, ...borderHeading, fontSize: '16px' }}>
              List of properties
            </div>
            <div style={pModaldiv}>
              {tableData.map(res => {
                return <div style={{ ...Content, ...border }}>{res.name}</div>;
              })}
            </div>
          </CardContent>
        </Card>
      </Modal>

      <Card>
        <CardContent style={{ padding: 0 }}>
          <div style={{ ...dflex, ...Header, ...borderHeading }}>
            <div style={dfg}>Template Name</div>
            <div style={dfg}>Channel</div>
            <div style={dfg}>Type</div>
            <div style={dfg}>Schedule</div>
            <div style={dfg}>List of property</div>
            <div style={dfg}>Actions</div>
          </div>
          {props.WorkFlowData &&
            props.WorkFlowData.data &&
            props.WorkFlowData.data.data.map(res => {
              return (
                <div style={{ ...dflex, ...Content, ...border }}>
                  <div style={dfg}>
                    {res.templateMessageId && res.templateMessageId.name
                      ? res.templateMessageId.name
                      : 'Template'}
                  </div>
                  <div style={dfg}>{res.channel}</div>
                  <div style={dfg}>{res.type}</div>
                  <div style={dfg}>
                    {res.delayObj
                      ? `${res.delayObj.val + ' ' + res.delayObj.unit}`
                      : `${res.delay + ' ' + 'milliseconds'}`}
                  </div>
                  <div
                    style={dfg}
                    onClick={() =>
                      openPropertyModal(res.properties, res.apartments)
                    }
                  >
                    <div style={overflow}>
                      {res.properties.map(res => {
                        return <span style={cursor}>{res.name} </span>;
                      })}
                      {res.apartments?.map(res => {
                        return <span style={cursor}>{res.name}</span>;
                      })}
                    </div>
                  </div>
                  <div style={{ ...dfg, paddingLeft: '14px' }}>
                    <div style={dflex}>
                      <div>
                        <EditIcon
                          style={cursor}
                          onClick={() => handleOpenModal(res)}
                        />
                      </div>
                      <div>
                        <DeleteIcon
                          style={cursor}
                          onClick={() =>
                            dispatch(deleteWorkflowAction(res._id))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
};
const mapStateToProps = state => ({
  WorkFlowData: state.Workflow.AllWorkflow
});

export default connect(mapStateToProps)(Wtable);
