import React from 'react';

const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={{ paddingLeft: '5px' }}
    >
      <path
        d="M7.77067 12.7916L13.6665 6.89579L12.7082 5.95829L7.77067 10.8958L5.27067 8.39579L4.33317 9.33329L7.77067 12.7916ZM8.99984 17.3333C7.81928 17.3333 6.72206 17.1215 5.70817 16.6979C4.69428 16.2743 3.81234 15.6875 3.06234 14.9375C2.31234 14.1875 1.72553 13.3055 1.30192 12.2916C0.87831 11.2777 0.666504 10.1805 0.666504 8.99996C0.666504 7.83329 0.87831 6.74301 1.30192 5.72913C1.72553 4.71524 2.31234 3.83329 3.06234 3.08329C3.81234 2.33329 4.69428 1.74301 5.70817 1.31246C6.72206 0.881904 7.81928 0.666626 8.99984 0.666626C10.1665 0.666626 11.2568 0.881904 12.2707 1.31246C13.2846 1.74301 14.1665 2.33329 14.9165 3.08329C15.6665 3.83329 16.2568 4.71524 16.6873 5.72913C17.1179 6.74301 17.3332 7.83329 17.3332 8.99996C17.3332 10.1805 17.1179 11.2777 16.6873 12.2916C16.2568 13.3055 15.6665 14.1875 14.9165 14.9375C14.1665 15.6875 13.2846 16.2743 12.2707 16.6979C11.2568 17.1215 10.1665 17.3333 8.99984 17.3333ZM8.99984 16.0833C11.0137 16.0833 12.6978 15.4062 14.0519 14.052C15.4061 12.6979 16.0832 11.0138 16.0832 8.99996C16.0832 6.98607 15.4061 5.30204 14.0519 3.94788C12.6978 2.59371 11.0137 1.91663 8.99984 1.91663C6.98595 1.91663 5.30192 2.59371 3.94775 3.94788C2.59359 5.30204 1.9165 6.98607 1.9165 8.99996C1.9165 11.0138 2.59359 12.6979 3.94775 14.052C5.30192 15.4062 6.98595 16.0833 8.99984 16.0833Z"
        fill="#67C848"
      />
    </svg>
  );
};

export default SuccessIcon;
