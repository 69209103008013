import React from 'react';

const MessageIcon = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V18C0.000305357 18.1772 0.0476888 18.3511 0.1373 18.504C0.226912 18.6569 0.355534 18.7832 0.51 18.87C0.659456 18.9547 0.828212 18.9995 1 19C1.17948 18.9999 1.35564 18.9516 1.51 18.86L6 16.14C6.16597 16.0412 6.35699 15.9926 6.55 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM6 9C5.80222 9 5.60888 8.94135 5.44443 8.83147C5.27998 8.72159 5.15181 8.56541 5.07612 8.38268C5.00043 8.19996 4.98063 7.99889 5.01921 7.80491C5.0578 7.61093 5.15304 7.43275 5.29289 7.29289C5.43275 7.15304 5.61093 7.0578 5.80491 7.01921C5.99889 6.98063 6.19996 7.00043 6.38268 7.07612C6.56541 7.15181 6.72159 7.27998 6.83147 7.44443C6.94135 7.60888 7 7.80222 7 8C7 8.26522 6.89464 8.51957 6.70711 8.70711C6.51957 8.89464 6.26522 9 6 9ZM10 9C9.80222 9 9.60888 8.94135 9.44443 8.83147C9.27998 8.72159 9.15181 8.56541 9.07612 8.38268C9.00043 8.19996 8.98063 7.99889 9.01921 7.80491C9.0578 7.61093 9.15304 7.43275 9.29289 7.29289C9.43275 7.15304 9.61093 7.0578 9.80491 7.01921C9.99889 6.98063 10.2 7.00043 10.3827 7.07612C10.5654 7.15181 10.7216 7.27998 10.8315 7.44443C10.9414 7.60888 11 7.80222 11 8C11 8.26522 10.8946 8.51957 10.7071 8.70711C10.5196 8.89464 10.2652 9 10 9ZM14 9C13.8022 9 13.6089 8.94135 13.4444 8.83147C13.28 8.72159 13.1518 8.56541 13.0761 8.38268C13.0004 8.19996 12.9806 7.99889 13.0192 7.80491C13.0578 7.61093 13.153 7.43275 13.2929 7.29289C13.4327 7.15304 13.6109 7.0578 13.8049 7.01921C13.9989 6.98063 14.2 7.00043 14.3827 7.07612C14.5654 7.15181 14.7216 7.27998 14.8315 7.44443C14.9414 7.60888 15 7.80222 15 8C15 8.26522 14.8946 8.51957 14.7071 8.70711C14.5196 8.89464 14.2652 9 14 9Z"
        fill="url(#paint0_linear_4737_56778)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4737_56778"
          x1="0.400195"
          y1="-0.474971"
          x2="22.3713"
          y2="15.7143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#36A247" />
          <stop offset="1" stop-color="#06AD0C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MessageIcon;
