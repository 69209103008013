import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import styles from './styles.module.scss';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#4CAF50',
    '&:hover': {
      backgroundColor: '#4CAF50'
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#4CAF50'
  }
}));

export const AvailableSlots = ({
  updateWorkingHours,
  timeZone,
  tenantId,
  appartmentId,
  workingHours,
  setWorkingHours
}) => {
  const [timeSlot, setTimeSlot] = useState([]);
  useEffect(() => {
    setTimeSlot(workingHours);
  }, [workingHours]);

  const [edit, setEdit] = useState(false);

  const handleUpdate = (e, elem, item) => {
    const { day } = item;
    const updatedSlots = timeSlot.map(slot => {
      if (slot.day === day) {
        if (elem === 'checked') {
          return {
            ...slot,
            status: slot.status === 'open' ? 'closed' : 'open'
          };
        }
        return { ...slot, [elem]: e.target.value };
      }
      return slot;
    });
    setTimeSlot(updatedSlots);
    setWorkingHours(updatedSlots);
  };

  const splitFun = (e, item, elem) => {
    const { day } = item;
    const time = e.target.value;
    const hr = time.slice(0, 2);
    const min = time.slice(3, 5);

    const updatedSlots = timeSlot.map(slot => {
      if (slot.day === day) {
        if (elem === 'start') {
          return {
            ...slot,
            available: [
              { ...slot.available[0], hoursStart: hr, minsStart: min }
            ]
          };
        } else if (elem === 'end') {
          return {
            ...slot,
            available: [{ ...slot.available[0], hoursEnd: hr, minsEnd: min }]
          };
        }
      }
      return slot;
    });

    setTimeSlot(updatedSlots);
  };

  const timefromval = (available, time) => {
    let hr = available ? available[`hours${time}`] : 0;
    let min = available ? available[`mins${time}`] : 0;
    if (hr && hr.toString().length === 1) {
      hr = `0${hr}`;
    }
    if (min && min.toString().length === 1) {
      min = `0${min}`;
    }
    return `${hr === 0 ? '00' : hr}:${min === 0 ? '00' : min}`;
  };

  const saveTimeHandler = () => {
    const payload = {
      tenantId,
      timezone: timeZone?.value,
      apartmentId: appartmentId,
      workingHours: [...timeSlot]
    };
    console.log('getting called', payload);
    updateWorkingHours(payload);
    setEdit(prev => !prev);
  };

  return (
    <div>
      <label className={styles.slotLabel}>
        <AccessTimeIcon />
        Office Hours{' '}
        <div className={styles.editIcon}>
          {!edit ? (
            <EditIcon onClick={() => setEdit(prev => !prev)} />
          ) : (
            <>
              <SaveIcon onClick={saveTimeHandler} style={{ color: '#0009' }} />
              <CloseIcon onClick={() => setEdit(prev => !prev)} />
            </>
          )}
        </div>
      </label>
      {timeSlot.map((item, index) => (
        <div key={item.day} className={styles.timeSlot}>
          <span className={styles.day}>{days[item.day]}</span>
          <div className={styles.availability}>
            <span className={styles.status}>
              {item.status === 'open' ? 'open' : 'closed'}
            </span>
            {edit && (
              <GreenSwitch
                size="small"
                onChange={e => handleUpdate(e, 'checked', item)}
                checked={item.status === 'open'}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          </div>
          {edit && (
            <div className={styles.timeZones}>
              <div className={styles.startTime}>
                <TextField
                  id="time"
                  name="start"
                  value={timefromval(item.available[0], 'Start')}
                  type="time"
                  onChange={e => splitFun(e, item, 'start')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    step: 300 // 5 min
                  }}
                  sx={{ width: 150 }}
                />
              </div>
              <div className={styles.endTime}>
                <TextField
                  id="to"
                  name="end"
                  value={timefromval(item.available[0], 'End')}
                  onChange={e => splitFun(e, item, 'end')}
                  type="time"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    step: 300 // 5 min
                  }}
                  sx={{ width: 150 }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AvailableSlots;
