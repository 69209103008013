import React from 'react';

function redirect() {
  const style = {
    font: 'normal 24px Inter',
    textAlign: 'center',
    marginTop: '20px'
  };
  return <div style={style}>Your account has been added successfully</div>;
}

export default redirect;
