import React, { useContext, useState } from 'react';
import PrimaryTopBar from '../Component/Navigation/TopBar';
import SideBarV1 from '../Container/SidebarV1/index';
import SnackbarComponent from './Common/GlobalError';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import { GlobalContext } from '../utils/ContextProviders/GlobalContextProvider';

const layout = props => {
  const { darkMode, toggleTheme } = useContext(GlobalContext);

  const { settingsAction, disableActions = false } = props;
  return (
    <ThemeProvider theme={darkMode ? theme.dark : theme.light}>
      <div
        style={{
          backgroundColor: darkMode
            ? theme.dark.background.universal
            : theme.light.background.universal,
          height: '100vh'
        }}
      >
        {disableActions ? (
          <div
            className={'hide-top-bar'}
            style={{
              background: darkMode ? '#FFF' : '#000'
            }}
          ></div>
        ) : null}
        <SnackbarComponent />
        <PrimaryTopBar
          settingsAction={settingsAction}
          history={props.history}
        />
        <div style={{ display: 'flex' }}>
          <SideBarV1 toggleDarkMode={toggleTheme} darkMode={darkMode} />
          <div
            style={{
              width: '100%',
              margin: '66px 10px 0 75px'
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default layout;
