import { Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BotReasonPopUp from '../../Container/InboxV1/BotReasonPopUp';
import ZSwitch from '../../globalcomponents/ZSwitch';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import { useTheme } from 'styled-components';
import { StatusContainer } from '../../Container/InboxV2/styles.inboxv2';

const LeadAISettings = props => {
  const {
    leadData,
    selectedLeadId,
    aiData: { clientEnabled, communityEnabled, leadEnabled } = {},
    leadUpdateMutate
  } = props;
  const [expandedView, setExpandedView] = useState(false);
  const [openBotModal, setOpenBotModal] = useState(false);
  const [leadLevelCheck, setLeadLevelCheck] = useState(false);
  const [botPopUp, setBotPopUp] = useState(false);
  const [botDisableReason, setBotDisabledReason] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { darkMode } = useContext(GlobalContext);
  const botEnabled =
    clientEnabled && communityEnabled && leadEnabled && leadLevelCheck;

  useEffect(() => {
    if (!botEnabled) {
      let reason = [];
      if (!clientEnabled) {
        reason.push('Turned off for client');
      }
      if (!communityEnabled) {
        reason.push('Turned off for community');
      }
      if (!leadEnabled || !leadLevelCheck) {
        leadData?.botTurningOffReason?.forEach(item => reason.push(item));
      }
      setBotDisabledReason([...reason]);
    }
  }, [botEnabled, leadData]);

  useEffect(() => {
    setLeadLevelCheck(leadEnabled);
  }, [leadEnabled]);

  useEffect(() => {
    setExpandedView(false);
  }, [selectedLeadId]);

  const handleLeadLevel = e => {
    if (leadLevelCheck === true && clientEnabled && communityEnabled) {
      let o = {};
      o.enableBot = e.target.checked;
      setOpenBotModal(true);
      setBotPopUp(true);
    } else {
      let o = {};
      o.enableBot = e.target.checked;

      if (leadLevelCheck) {
        setOpenBotModal(true);
        setBotPopUp(true);
      } else {
        leadUpdateMutate({ variables: { leadId: selectedLeadId, record: o } });
        setLeadLevelCheck(e.target.checked);
      }
    }
  };

  return (
    <div
      style={{ position: 'relative', background: darkMode ? '#1D202D' : '' }}
      className="right-side-cards inter-600px-14px"
    >
      <div>
        {botPopUp === true ? (
          <BotReasonPopUp
            openBotModal={openBotModal}
            communityEnabled={communityEnabled}
            clientEnabled={clientEnabled}
            setOpenBotModal={setOpenBotModal}
            leadId={selectedLeadId}
            setLeadLevelCheck={setLeadLevelCheck}
            leadUpdateMutate={leadUpdateMutate}
          />
        ) : null}
        <Typography
          style={{ color: darkMode ? '#fff' : '' }}
          className="card-title"
        >
          AI Settings
        </Typography>
        <div className="ai-bot-settings inter-12px-500">
          <div className="flex" style={{ color: darkMode ? '#fff' : '' }}>
            Bot Settings
            {expandedView ? (
              <KeyboardArrowUpIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setExpandedView(!expandedView)}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setExpandedView(!expandedView)}
              />
            )}
          </div>
          <div
            className={`ai-settings ${
              expandedView ? 'bot-settings__open' : 'bot-settings__close'
            }`}
          >
            <div
              className="flex"
              style={{ color: darkMode ? '#fff' : '', padding: '2px 0' }}
            >
              Lead Level{' '}
              <ZSwitch
                id="lead_bot_setting"
                checked={leadLevelCheck}
                onChange={handleLeadLevel}
              />
            </div>

            <div
              style={{
                background: 'rgba(217, 217, 217, 0.2)',
                borderRadius: ' 0px 3px 3px 0px',
                padding: '0 4px',
                margin: '0 -4px'
              }}
            >
              <div
                className="flex"
                style={{ padding: '2px 0', color: darkMode ? '#fff' : '' }}
              >
                Community Level{' '}
                <ZSwitch
                  id="community_bot_setting"
                  disabled
                  checked={communityEnabled}
                />
              </div>
              <div
                className="flex"
                style={{ padding: '2px 0', color: darkMode ? '#fff' : '' }}
              >
                Client Level{' '}
                <ZSwitch
                  id="client_bot_setting"
                  disabled
                  checked={clientEnabled}
                />
              </div>
            </div>
          </div>
          <StatusContainer>
            Status:
            <span
              className={`${
                !!leadLevelCheck &&
                !!leadEnabled &&
                !!communityEnabled &&
                !!clientEnabled
                  ? 'active-bot'
                  : 'inactive-bot'
              }`}
            >
              {!!leadLevelCheck &&
              !!leadEnabled &&
              !!communityEnabled &&
              !!clientEnabled
                ? 'Active'
                : 'Inactive'}{' '}
            </span>
          </StatusContainer>
        </div>
        {!botEnabled && (
          <div className="inter-12px-500">
            <span style={{ color: theme?.text?.primary }}>Reason: </span>
            <span style={{ color: theme?.text?.primary }}>
              {botDisableReason?.toString()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadAISettings;
