import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getDate } from '../../utils';
import LeadDetailsEdit from './LeadDetailsEdit';
import './LeadDetails.scss';
import Loader from '../Loader';
import { useSelector } from 'react-redux';
import CopyIcon from '../../Media/InboxV1/CpoyIcon';
import GreenTick from '../../Media/InboxV1/GreenTick';
import WrongPhone from '../../Media/InboxV1/WrongPhone';
import InfoIcon from '../../Media/InboxV1/InfoIcon';
import PrivacyLock from '../../Media/InboxV1/PrivacyLock';
import ConsentModal from './DataPrivacy/ConsentModal';
import ContactDetailsPopup from './DataPrivacy/ContactDetailsPopup';
import ZSwitch from '../../globalcomponents/ZSwitch';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import Icon from '../../globalcomponents/Icon';
import { useTheme } from 'styled-components';

const LeadDetails = props => {
  const {
    selectedLeadId,
    phoneType,
    firstContactType,
    refetchLeadDetails,
    leadData: {
      preference,
      textOptIn,
      callOptIn,
      apartment,
      email,
      phone,
      name,
      source,
      smsEnabled,
      meta,
      tenantId
    } = {},
    apartmentData,
    propertyData,
    leadUpdateMutate
  } = props;
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [consentOpen, setConsentOpen] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const { darkMode } = useContext(GlobalContext);
  const {
    bathroom,
    bedroom,
    budgetMax,
    budgetMin,
    creditScore,
    deposit,
    income,
    interest,
    leaseTerm,
    monthlyRent,
    moveIn,
    occupant,
    pet,
    petType,
    isFurnished,
    unit
  } = preference || {};

  const theme = useTheme();

  useEffect(() => {
    setShowEditDetails(false);
  }, [selectedLeadId]);

  const truncate = (str, length) => {
    if (str && str.length > length) {
      return str.substring(0, length) + '...';
    }
    return str;
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const { inboxMetaLoading } = useSelector(state => state.inboxMessages);

  const saveNewLeadDetails = (newMetaObject, callMessageApi) => {
    if (selectedLeadId) {
      leadUpdateMutate({
        variables: { leadId: selectedLeadId, record: newMetaObject }
      });
    }
  };
  return showEditDetails ? (
    <LeadDetailsEdit
      {...{
        bathroom,
        bedroom,
        budgetMax,
        budgetMin,
        creditScore,
        deposit,
        income,
        interest,
        leaseTerm,
        monthlyRent,
        moveIn,
        occupant,
        pet,
        setShowEditDetails,
        propertyData,
        apartmentData,
        callOptIn,
        tenantId,
        apartment,
        showEditDetails,
        selectedLeadId,
        email,
        phone,
        name,
        textOptIn,
        petType,
        isFurnished,
        unit,
        meta,
        smsEnabled,
        phoneType,
        showEditIcon,
        setShowEditIcon,
        consentOpen,
        setConsentOpen,
        leadUpdateMutate,
        firstContactType
      }}
    />
  ) : (
    <div
      style={{ position: 'relative', background: darkMode ? '#1D202D' : '' }}
    >
      {' '}
      <ConsentModal
        open={consentOpen}
        setOpen={setConsentOpen}
        setEditPopup={setEditPopup}
        selectedLeadId={selectedLeadId}
        leadUpdateMutate={leadUpdateMutate}
      />
      <ContactDetailsPopup
        open={editPopup}
        setOpen={setEditPopup}
        name={name}
        phoneType={phoneType}
        smsEnabled={smsEnabled}
        selectedLeadId={selectedLeadId}
        refetchLeadDetails={refetchLeadDetails}
      />
      {inboxMetaLoading ? (
        <div
          style={{
            width: '100px',
            position: 'absolute',
            top: '30%',
            left: '26%',
            height: '100px',
            textAlign: 'center'
          }}
        >
          <Loader />
        </div>
      ) : null}
      <div
        className="right-side-cards inter-600px-14px"
        style={{
          position: 'relative',
          background: darkMode ? '#1D202D' : ''
        }}
      >
        <div className="flex">
          <Typography
            style={{ color: darkMode ? '#fff' : '' }}
            className="card-title"
          >
            Lead Details
          </Typography>
          <Icon
            src={`edit-${theme?.state}.svg`}
            onClick={() => setShowEditDetails(true)}
            size="16px"
          />
        </div>
        <div className="flex-start" style={{ paddingTop: '10px' }}>
          <Typography
            style={{ color: darkMode ? '#fff' : '#393939' }}
            className="ld-sub-title"
          >
            Lead Id:
          </Typography>
          <div className="flex">
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="ld-sub-title "
            >
              &nbsp;
              {truncate(selectedLeadId, 15)}
            </Typography>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Add"
              >
                <CopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(selectedLeadId);
                    handleTooltipOpen();
                  }}
                />
              </Tooltip>
            </ClickAwayListener>
          </div>
        </div>

        {source ? (
          <div className="flex-start" style={{ paddingTop: '10px' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '#393939' }}
              className="ld-sub-title"
            >
              Source:
            </Typography>
            <div className="flex">
              <Typography
                style={{ color: darkMode ? '#fff' : '' }}
                className="ld-sub-title "
              >
                &nbsp;
                {truncate(source, 20)}
              </Typography>
            </div>
          </div>
        ) : null}

        {meta?.firstContactType ? (
          <div className="flex-start" style={{ paddingTop: '10px' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '#393939' }}
              className="ld-sub-title"
            >
              Contact Type:
            </Typography>
            <div className="flex">
              <Typography
                style={{ color: darkMode ? '#fff' : '' }}
                className="ld-sub-title "
              >
                &nbsp;
                {truncate(meta.firstContactType, 20)}
              </Typography>
            </div>
          </div>
        ) : null}

        <div className="sub-container">
          <Typography
            className="sub-container-title"
            onMouseOver={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
            style={{
              position: 'relative',
              display: 'flex',
              color: darkMode ? '#fff' : ''
            }}
          >
            Personal details{' '}
            {showEditIcon ? (
              <PrivacyLock
                style={{
                  position: 'absolute',
                  right: '0',
                  cursor: 'pointer'
                }}
                onClick={() => setConsentOpen(true)}
              />
            ) : null}
          </Typography>

          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Phone no:
            </Typography>
            <Typography
              className="sub-container-value truncate"
              style={{ display: 'flex', color: darkMode ? '#fff' : '' }}
            >
              xxxxxxxxxxx{' '}
              {smsEnabled ? (
                <GreenTick style={{ marginLeft: '10px' }} />
              ) : (
                <WrongPhone style={{ marginLeft: '10px' }} />
              )}
              <Tooltip
                enterDelay={500}
                leaveDelay={200}
                title={'Phone-Type: ' + phoneType || 'Not-available'}
              >
                <IconButton
                  sx={{ padding: '0px' }}
                  style={{ background: '#ffffff' }}
                >
                  <InfoIcon
                    style={{
                      marginLeft: '4px',
                      width: '24px',
                      height: '24px',
                      marginTop: '-4px'
                    }}
                    onMouseOver={() => {}}
                  />
                </IconButton>
              </Tooltip>
            </Typography>
          </div>
          <div className=" " style={{ display: 'flex', marginTop: '-4px' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Email:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              xxxxxxxxxxxxxxxxxxxx
            </Typography>
          </div>
        </div>
        <div className="sub-container">
          <Typography
            style={{ color: darkMode ? '#fff' : '' }}
            className="sub-container-title"
          >
            Unit Details
          </Typography>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key truncate"
            >
              Move-in-date:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value"
            >
              {' '}
              {getDate(moveIn) || 'NA'}
            </Typography>
          </div>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Occupants:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {occupant}
            </Typography>
          </div>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Intersted community:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {apartment?.name || 'NA'}
            </Typography>
          </div>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Preferred Unit:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {unit || 'NA'}
            </Typography>
          </div>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Price Range:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {'$' + (budgetMin || 'NA') + '- $' + (budgetMax || 'NA')}
            </Typography>
          </div>
          <div className="mt-10 mb-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Lease Length:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {leaseTerm || 'NA'}
            </Typography>
          </div>
          <div className="mt-10" style={{ display: 'flex' }}>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-key"
            >
              Furnished:
            </Typography>
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-value truncate"
            >
              {isFurnished ? 'Yes' : 'NA'}
            </Typography>
          </div>
        </div>

        <div className="sub-container disabled">
          <div className="d-flex-jst-space-around">
            <div className="flex inter-12px-500">
              <span
                className="lead-details-color"
                style={{ color: darkMode ? '#fff' : '' }}
              >
                Sms-Opt-In
              </span>
              <ZSwitch
                id="textOptIn"
                name="textOptIn_disabled"
                checked={textOptIn}
                small
                disabled
              />
            </div>
            <div className="flex inter-12px-500">
              <span
                className="lead-details-color"
                style={{ color: darkMode ? '#fff' : '' }}
              >
                Call-Opt-In
              </span>
              <ZSwitch
                id="callOptIn"
                name="callOptIn_disabled"
                checked={callOptIn}
                small
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDetails;
