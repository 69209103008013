import React, { useContext, useEffect, useRef } from 'react';
import Popup from '../../globalcomponents/Popup';
import PopupTitle from '../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../globalcomponents/PopupComponents/PopupBody';
import TableComponent from '../../globalcomponents/TableComponent/PinnedTable';
import data from './tableConfig';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Container/KelseyInsight/Input';
import CustomCreatableSelect from '../CustomSelectV1';
import {
  DateWrapper,
  FiltersConatiner,
  FlexSpaceBetween,
  LoaderContainer,
  NoResultContainer,
  SelectedUnit,
  SingleFilterWrapper,
  TextSpan,
  TopBarContainer
} from './styles.maximisedpnawidget';
import DateInput from '../DateInput';
import MinMaxRange from '../MinMaxRange';
import { throttle } from '../../Container/InboxV2/inboxUtils';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import Icon from '../../globalcomponents/Icon';
import moment from 'moment';
import { fetchPriceListings } from '../../Redux/Actions/InboxV1';
import Loader from '../Loader';

const MaximisedPnAWidget = ({
  onClose,
  isOpen,
  leadUpdateMutate,
  selectedLeadId,
  handleAutofillData
}) => {
  const { priceListingList, isPriceListingLoading } = useSelector(
    state => state.inboxMessages
  );

  const dispatch = useDispatch();
  const { unit, setUnit, leadData } = useContext(GlobalContext);
  const [moveIndate, setMoveinDate] = React.useState(new Date());
  const [updatedLeadId, setUpdatedLeadId] = React.useState(null);

  useEffect(() => {
    setUpdatedLeadId(selectedLeadId);
  }, [selectedLeadId]);

  useEffect(() => {
    if (leadData?.apartment?._id === undefined || !isOpen) return;
    const apartmentPayload = {
      apartmentId: leadData?.apartment?._id
    };

    dispatch(fetchPriceListings({ apartmentPayload }));
  }, [leadData, updatedLeadId, isOpen]);

  const saveLead = val => {
    let leadObj = {
      propertyId: val?._id,
      preference: {
        unit: val?._id
      }
    };

    leadUpdateMutate({
      variables: { leadId: updatedLeadId, record: leadObj }
    });
  };
  const [searchInput, setSearchInput] = React.useState('');

  const [showFilters, setShowFilters] = React.useState(false);
  const [bedRoomsAndBathrooms, setBedRoomsAndBathrooms] = React.useState({
    bedRooms: [{ value: 0, label: '0 Bed' }],
    bathRooms: [{ value: 0, label: '0 Bath' }]
  });

  const [filter, setFilter] = React.useState({
    bedRooms: null,
    bathRooms: null,
    maxPrice: null,
    minPrice: null
  });

  const filteredItems =
    priceListingList === null
      ? null
      : priceListingList?.filter(item => {
          const matchesSearchInput = Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchInput.toLowerCase())
          );

          const matchesBedrooms =
            filter.bedRooms === null ||
            filter.bedRooms === undefined ||
            item.bedRooms === filter.bedRooms;

          const matchesBathrooms =
            filter.bathRooms === null ||
            filter.bathRooms === undefined ||
            item.bathRooms === filter.bathRooms;

          const minPrice = filter.minPrice === '' ? null : filter.minPrice;
          const maxPrice = filter.maxPrice === '' ? null : filter.maxPrice;

          const matchesMinPrice =
            minPrice === null || item.rent.minRent >= parseFloat(minPrice);

          const matchesMaxPrice =
            maxPrice === null || item.rent.minRent <= parseFloat(maxPrice);

          return (
            matchesSearchInput &&
            matchesBedrooms &&
            matchesBathrooms &&
            matchesMinPrice &&
            matchesMaxPrice
          );
        });

  const createMaxBedroomList = max => {
    return Array.from({ length: max + 1 }, (_, i) => ({
      value: i,
      label: `${i}`
    }));
  };

  useEffect(() => {
    if (priceListingList.length) {
      const bedroomsSet = new Set();
      const bathroomsSet = new Set();

      priceListingList.forEach(item => {
        if (item.bedRooms !== undefined && item.bedRooms !== null)
          bedroomsSet.add(item.bedRooms);
        if (item.bathRooms !== undefined && item.bathRooms !== null)
          bathroomsSet.add(item.bathRooms);
      });

      const bedRoomsArray = Array.from(bedroomsSet)
        .sort((a, b) => a - b)
        .map(value => ({
          value,
          label: `${value}`
        }));

      const bathRoomsArray = Array.from(bathroomsSet)
        .sort((a, b) => a - b)
        .map(value => ({
          value,
          label: `${value}`
        }));

      setBedRoomsAndBathrooms({
        bedRooms: bedRoomsArray,
        bathRooms: bathRoomsArray
      });
    }
  }, [priceListingList]);

  const handleSelect = item => {
    saveLead(item);
    setUnit(item);
    handleAutofillData(item);
  };

  return (
    <>
      {isOpen && (
        <Popup top="112px" left="370px" width={'800px'}>
          <PopupTitle onClose={onClose}>
            Pricing, Availability and Amenities
          </PopupTitle>
          <PopupBody key={`${updatedLeadId}-popup`}>
            {!isPriceListingLoading ? (
              <div
                style={{
                  borderRadius: '2px',
                  background: 'rgba(196, 196, 196, 0.05)'
                }}
              >
                <FlexSpaceBetween>
                  <TopBarContainer>
                    <SelectedUnit>
                      <Icon
                        src="appartment.svg"
                        imgStyle={{ height: '20px', width: '20px' }}
                      />
                      <TextSpan>
                        {unit?.unitName === null
                          ? 'No Selected Unit'
                          : unit?.unitName}{' '}
                      </TextSpan>
                    </SelectedUnit>

                    <Input
                      key="search"
                      prepend
                      search
                      onChange={e => {
                        const searchTerm = e.target.value;
                        throttle(() => {
                          setSearchInput(searchTerm);
                        }, 500)();
                      }}
                      value={searchInput}
                      type="search"
                      placeholder="Search..."
                      borderBottom={true}
                      name="search"
                      size="sm"
                    />
                  </TopBarContainer>
                  <Icon
                    src="chevron-right-large.svg"
                    onClick={() => setShowFilters(!showFilters)}
                    imgStyle={{
                      margin: '0px 10px 0px 0px',
                      height: '24px',
                      width: '24px',
                      transform: showFilters
                        ? 'rotate(270deg)'
                        : 'rotate(90deg)'
                    }}
                  />
                </FlexSpaceBetween>
                <FiltersConatiner showFilters={showFilters}>
                  <SingleFilterWrapper width={91}>
                    <CustomCreatableSelect
                      key={`bedroomfilter${bedRoomsAndBathrooms?.bedRooms?.length}`}
                      handleSelect={(name, value) =>
                        setFilter(prev => {
                          return { ...prev, bedRooms: value?.value };
                        })
                      }
                      name="bedRooms"
                      value={{
                        value: filter.bedRooms,
                        label: `${filter.bedRooms ?? ''} Bed`
                      }}
                      options={bedRoomsAndBathrooms?.bedRooms}
                      width="100%"
                    />
                  </SingleFilterWrapper>
                  <SingleFilterWrapper width={91}>
                    <CustomCreatableSelect
                      key="bathroomfilter"
                      handleSelect={(name, value) =>
                        setFilter(prev => {
                          return { ...prev, bathRooms: value?.value };
                        })
                      }
                      name={`bathRoomsFilter${bedRoomsAndBathrooms?.bathRooms?.length}`}
                      value={{
                        value: filter.bathRooms,
                        label: `${filter.bathRooms ?? ''} Bath`
                      }}
                      options={bedRoomsAndBathrooms?.bathRooms}
                      width="100%"
                    />
                  </SingleFilterWrapper>
                  <DateWrapper
                    style={{
                      padding: '5px 10px',
                      height: '27px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <DateInput
                      key={'moveInDate'}
                      name="moveInDate"
                      id="moveInDate"
                      placeholderText="Move In Date"
                      selected={moveIndate}
                      onChange={date => {
                        setMoveinDate(date);
                        const apartmentPayload = {
                          moveInDate: moment(date).format('YYYY-MM-DD'),
                          apartmentId: leadData?.apartment?._id
                        };
                        dispatch(fetchPriceListings({ apartmentPayload }));
                      }}
                      dateFormat="dd-MMM-yyyy"
                      minDate={new Date()}
                      showTimeSelect={false}
                      includeDates={undefined}
                      showAll={true}
                    />
                  </DateWrapper>
                  <SingleFilterWrapper width={'224px'}>
                    <MinMaxRange
                      type="currency"
                      value={{ min: filter.minPrice, max: filter.maxPrice }}
                      onChange={value => {
                        setFilter(prev => {
                          return {
                            ...prev,
                            minPrice: value.min,
                            maxPrice: value.max
                          };
                        });
                      }}
                    />
                  </SingleFilterWrapper>
                </FiltersConatiner>

                <div
                  style={{
                    padding: '20px 10px',
                    position: 'relative',
                    height: '419px'
                  }}
                  key={`${updatedLeadId}-table`}
                >
                  {filteredItems?.length > 0 && updatedLeadId ? (
                    <TableComponent
                      headers={data.headers}
                      data={filteredItems}
                      onDoubleClickRow={handleSelect}
                      sourceEditLoading={false} // or true based on your application state
                      pinnedColumns={data.pinnedColumns}
                      height={419}
                      cellWeight={400}
                      cellSize={'12px'}
                      actionOnHover={handleSelect}
                      defaultSelectedRowId={unit?._id}
                      tooltipOnDoubleClick={'Unit Selected'}
                      key={`${updatedLeadId}-table-comp`}
                      selectedLeadId={updatedLeadId}
                    />
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)'
                      }}
                    >
                      <NoResultContainer>No results found</NoResultContainer>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </PopupBody>
        </Popup>
      )}
    </>
  );
};

export default MaximisedPnAWidget;
