import React, { useState } from 'react';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import DateInput from '../../../Component/DateInput';
import {
  DateWaitListWrapper,
  Labels
} from '../../../Component/LeadWaitList/LeadWaitList.styles';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import { LoaderBlue } from '../../../Component/CallToTextComponent/styles.callToText';
import Button from '../../../globalcomponents/Button';
import { Timezone } from './styles.chatinputbar';
import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';
import { formatTimezone } from '../inboxUtils';

const ScheduleMessagePopup = ({
  show,
  onClose,
  selectedLeadId,
  handleOutgoingScheduledMessage,
  sendNewScheduledMessage,
  apartmentTimezone
}) => {
  const { fetchScheduledMessages } = useInboxContext();
  const [buttonState, setButtonState] = useState('initial');
  const [date, setDate] = useState(new Date());
  const onSave = () => {
    // fetchScheduledMessages(selectedLeadId);

    sendNewScheduledMessage(date);
    setButtonState('inprogress');
    setTimeout(() => {
      setButtonState('completed');
    }, 2000);
    setTimeout(() => {
      setButtonState('initial');
    }, 3000);
    setTimeout(() => {
      onClose();
    }, 3500);
  };

  const customBackdropStyling = {
    top: '-67px',
    left:
      window && window.location.href.includes('inbox/searchlead')
        ? '-86px'
        : '-281px',
    width: '100vw',
    height: '100vh'
  };

  return show ? (
    <Popup
      show={show}
      onClose={onClose}
      width={'400px'}
      customBackdropStyling={customBackdropStyling}
    >
      <PopupTitle onClose={onClose} showCloseBtn padding={'0px'}>
        <div style={{ padding: '20px 20px 10px 20px' }}>Schedule Message</div>
      </PopupTitle>
      {apartmentTimezone && (
        <Timezone>{formatTimezone(apartmentTimezone)}</Timezone>
      )}

      <PopupBody padding={'20px'}>
        <Labels>
          <label> Date and Time</label>
        </Labels>
        <DateWaitListWrapper>
          <DateInput
            selected={date}
            showTimeSelect
            onChange={date => setDate(date)}
            dateFormat=" dd-MMM-yyyy hh:mm aa"
            showAll={true}
          />
        </DateWaitListWrapper>
      </PopupBody>
      <PopupFooter padding="0 20px 20px 20px" justifyContent="">
        <Button
          style={{
            width: '84px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          btnType="primary"
          onClick={onSave}
        >
          {buttonState === 'initial' ? (
            'Save'
          ) : buttonState === 'inprogress' ? (
            <img
              src="https://assets.getzuma.com/client/images/SubmitLoader.gif"
              alt="SubmitLoader"
              width={30}
              height={30}
            />
          ) : (
            <img
              src="https://assets.getzuma.com/client/images/SubmitSuccess.gif"
              alt="SubmitSuccess"
              width={30}
              height={30}
            />
          )}
        </Button>
        <Button noStyle={true} onClick={() => setDate(null)}>
          Clear
        </Button>
      </PopupFooter>
    </Popup>
  ) : null;
};

export default ScheduleMessagePopup;
