import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dropdown: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: 56
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: '#3f51b5'
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: '#FF6F62',
      background: '#FF6F621A',
      borderRadius: 10
    }
  },
  disableDropdown: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: 56
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: '#3f51b5'
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      display: 'none'
    }
  },
  valueStyle: {
    margin: 0,
    display: 'inline-block',
    marginRight: 6,
    background: '#FFF1EF',
    padding: '0 6px'
  },
  optionTextStyle: {
    fontSize: 14,
    padding: '6px 8px'
  }
}));
