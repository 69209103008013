import moment from 'moment';

export function setCookie(cname, cvalue, expires_in) {
  let d = new Date();
  let expires = '';

  if (expires) {
    d.setTime(d.getTime() + expires);
    expires = 'expires=' + d.toUTCString();
  }
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function setCookieWithExpiration(cname, cvalue) {
  // Calculate the expiration time (4 hours from now)
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + 12 * 60 * 60 * 1000); // 4 hours in milliseconds

  // Format the expiration date in the proper format for cookies
  const expires = 'expires=' + expiration.toUTCString();

  // Set the cookie with the specified expiration time and path
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(name) {
  let value = '; ' + document.cookie;
  let parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
}

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [
      // intlCode,
      '(',
      match[2],
      ')',
      ' ',
      match[3],
      '-',
      match[4],
      ''
    ].join('');
  }
  return null;
}

export const formatNumberOntype = number => {
  const x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (x.length <= 10) {
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }
};

export const DateStrip = Value => {
  const dateNow = new Date(Value);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
  return `${year}-${month}-${date}`;
};

export const MudateTime = Value => {
  const dateNow = new Date(Value);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
  let hr = dateNow.getHours();
  hr = hr < 10 ? '0' + hr : hr;
  let min = dateNow.getMinutes();
  min = min < 10 ? '0' + min : min;
  return `${year}-${month}-${date}T${hr}:${min}`;
};

export const inboxTime = Value => {
  const dateNow = new Date(Value);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
  let hr = dateNow.getHours();
  hr = hr < 10 ? '0' + hr : hr;
  let min = dateNow.getMinutes();
  min = min < 10 ? '0' + min : min;

  return `${hr}:${min}/${date}-${month}-${year}`;
};

export const getDate = (date, isReverse = false) => {
  const d = new Date(date);

  let month = ('0' + (d.getMonth() + 1)).slice(-2);
  let day = ('0' + d.getDate()).slice(-2);
  let year = d.getFullYear();
  if (isReverse) {
    return `${year}-${month}-${day}`;
  }
  return `${month}-${day}-${year}`;
};
export const MONTHS_LIST_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const getDateWithoutYear = (date, isReverse = false) => {
  const d = new Date(date);
  let month = d.getMonth();
  let day = ('0' + d.getDate()).slice(-2);
  let year = d.getFullYear();
  if (isReverse) {
    return `${year}-${month}-${day}`;
  }
  return `${day}-${MONTHS_LIST_SHORT[month]}`;
};
export const MONTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DAYS_LIST = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const TIME_SLOTS = [
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM'
];

export const removeTimeZonePart = dateString => {
  let finalDate = '';
  if (dateString.split('+').length > 1) {
    let b = dateString.split('+');
    finalDate = b[0];
  } else {
    let b = dateString.split('-');
    if (b.length > 1) {
      b.pop();
      finalDate = b.join('-');
    }
  }
  return finalDate;
};

export const getHoursWithTimeInterval = intervalTime => {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format());
    hours.push(
      moment({
        hour,
        minute: intervalTime
      }).format()
    );
  }
  return hours;
};

export const ConfirmMudateTime = Value => {
  let tcheck = Value?.substring(10, 11);
  if (tcheck === 'T') {
    let date2 = Value?.substring(0, 16);
    let m = moment(date2).format('lll');
    return m;
  } else {
    let date2 = Value?.substring(0, 18);
    let m = moment(date2).format('lll');
    return m;
  }
};
export const ConfirmMudate = Value => {
  let tcheck = Value?.substring(10, 11);
  if (tcheck === 'T') {
    let date2 = Value?.substring(0, 16);
    let m = moment(date2).format('ll');
    return m;
  } else {
    let date2 = Value?.substring(0, 18);
    let m = moment(date2).format('ll');
    return m;
  }
};

export const ConfirmEditMudateTime = Value => {
  const dateNow = new Date(Value);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getDate().toString().length < 2
      ? `0${dateNow.getDate()}`
      : dateNow.getDate();
  let hr = dateNow.getHours();
  hr = hr < 10 ? '0' + hr : hr;
  let min = dateNow.getMinutes();
  min = min < 10 ? '0' + min : min;
  return `${year}-${month}-${date}T${hr}:${min}`;
};

export const Mudate = Value => {
  const dateNow = new Date(Value);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
  let hr = dateNow.getHours();
  hr = hr < 10 ? '0' + hr : hr;
  let min = dateNow.getMinutes();
  min = min < 10 ? '0' + min : min;
  return `${year}-${month}-${date}`;
};

export const isValidUrl = urlString => {
  let urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const ROLES = {
  SUPER_ADMIN: 'SUPER ADMIN',
  CLIENT_ADMIN: 'CLIENT ADMIN',
  CLIENT_USER: 'CLIENT USER',
  AGENT: 'AGENT',
  MANAGER: 'MANAGER',
  ADMIN: 'ADMIN',
  TESTER: 'TESTER'
};

export const parseTokensLowerCase = (inputString, delimiter) => {
  const tokens = inputString.split(delimiter);
  return tokens
    .map(token => token.toLowerCase().trim())
    .filter(token => token.length > 0);
};

export const compareArraysOfObjects = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays based on the _id field
  const sortedArr1 = arr1.slice().sort((a, b) => a._id.localeCompare(b._id));
  const sortedArr2 = arr2.slice().sort((a, b) => a._id.localeCompare(b._id));

  // Check if all corresponding elements in both arrays have the same _id
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i]._id !== sortedArr2[i]._id) {
      return false;
    }
  }

  // If all elements have the same _id values
  return true;
};

export const areArraysOfObjectsDifferent = (array1, array2) => {
  if (array1.length !== array2.length) {
    return true;
  }

  const idSet1 = new Set(array1.map(obj => obj._id));
  const idSet2 = new Set(array2.map(obj => obj._id));

  if (idSet1.size !== idSet2.size) {
    return true;
  }

  for (const id of idSet1) {
    if (!idSet2.has(id)) {
      return true;
    }
  }

  return false;
};
