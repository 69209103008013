import styled from 'styled-components';

const FieldWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
`;

const Label = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.text.label};
`;

const Value = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.text.value};
`;

const CommunityLink = styled.a`
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-color: ${({ theme }) => theme?.text?.placeholder};
  color: ${({ theme }) => theme?.text?.primary};
  :visited {
    color: #393939;
  }
`;

const Required = styled.span`
  color: #ff4d00;
`;

const Pad = styled.div`
  height: 6px;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 10px;
`;
export {
  FieldWrapper,
  Label,
  Value,
  Required,
  Pad,
  IconWrapper,
  CommunityLink
};
