import { Button, InputAdornment, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientList } from '../../../Redux/Actions/SelfServe';
import ClientCard from './ClientCard';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';

const Client = ({ infoPopUPHandler, setTenantId, setTenantName }) => {
  const [searchedRes, setSearchRes] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const clientsList = useSelector(state => state.SelfServe.clientsList);
  const { userData } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const handleChange = value => {
    setSearchVal(value);
  };
  useEffect(() => {
    if (searchVal) {
      setSearchRes(() => {
        const filtered = clientsList.filter(
          client =>
            client?.name?.includes(searchVal) ||
            client?.name?.toLowerCase()?.includes(searchVal)
        );

        return filtered || [];
      });
    } else {
      setSearchRes([...clientsList]);
    }
  }, [clientsList, searchVal]);

  const handleClientClick = () => {
    infoPopUPHandler(true);
  };

  const handleClientCardClick = (tenantId, name) => {
    setTenantId(tenantId);
    setTenantName(name);
  };
  useEffect(() => {
    dispatch(updateClientList(userData?.clients));
  }, []);
  return (
    <div className="bg-white mt-20 mr-20 br-4 p-20 workspaceContainer">
      <div className="flex mb-20">
        <TextField
          onChange={e => handleChange(e.target.value)}
          placeholder="Search clients"
          variant="outlined"
          value={searchVal}
          InputProps={{
            type: 'search',
            style: {
              borderRadius: '4px',
              padding: '4px'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#a2a2a2' }} />
              </InputAdornment>
            )
          }}
        />

        {userData?.role?.name === `SUPER_ADMIN` && (
          <Button onClick={handleClientClick} className="save-btn">
            Add Client
          </Button>
        )}
      </div>
      <div className="clients-list">
        {searchedRes.map(
          clientInfo =>
            clientInfo?.name && (
              <ClientCard
                {...{ ...clientInfo, handleClientCardClick }}
                key={clientInfo?._id}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Client;
