import { Button, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '../../../Media/SelfServe/CheckCircleIcon';
import ErrorIcon from '../../../Media/SelfServe/ErrorIcon';
import {
  updateAccount,
  validatePms as validatePmsHandler
} from '../../../Redux/Actions/SelfServe';
import {
  userCreationNotification,
  validatePmsError
} from '../../../Redux/Reducer/SelfServe';
import ClientInfo from './clientInfo';
import styles from './styles.module.scss';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import LinkIcon from '../../../Media/linkOut.svg';

const PMS = ({ info, infoPopUPHandler, tenantId }) => {
  const dispatch = useDispatch();
  const { userData } = useContext(GlobalContext);
  const initialState = {
    tenantId: '',
    pms: '',
    username: '',
    password: '',
    url: '',
    yardiId: ''
  };
  const [form, setForm] = useState(initialState);
  const { username, password, url, sqlDataBase, pmcId, yardiId } = form;
  const selfServe = useSelector(state => state.SelfServe);
  const [verify, setVerify] = useState(false);
  const [notification, setNotification] = useState('');
  const integrationSettings =
    selfServe?.userData?.data?.integrationSettings || {};
  const pmsValue = integrationSettings && integrationSettings?.pms;
  const realPage = pmsValue?.toLowerCase()?.replace(/\s/g, '') === 'realpage';
  const yardi = pmsValue?.toLowerCase() === 'yardi';
  const entrata = pmsValue?.toLowerCase() === 'entrata';
  const entrataAndYardi = yardi || entrata;
  const validatePms = selfServe?.validatePms;
  const { verified, error } = validatePms;
  const handleForm = e => {
    const { value, name } = e.target;
    if (name === 'pmcId') {
      dispatch(validatePmsError());
    }
    setForm(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const validatePmsErrorCall = status => {
    dispatch(validatePmsError(status));
  };
  const validatePmsAction = () => {
    const pms = pmsValue?.toLowerCase().replace(/ /g, '');
    if (yardi) {
      const payload = {
        pms: 'yardi',
        integrationSettings: {
          pms: 'yardi',
          yardiUrl: url,
          yardiId: yardiId,
          userName: username,
          password: password,
          database: yardiId,
          platform: 'SQL Server'
        }
      };
      dispatch(
        validatePmsHandler({
          payload,
          errorCheck: validatePmsErrorCall
        })
      );
    }
    if (realPage) {
      const payload = {
        pms,
        integrationSettings: {
          pmcId
        }
      };
      dispatch(
        validatePmsHandler({
          payload,
          errorCheck: validatePmsErrorCall
        })
      );
    }
    if (entrata) {
      const payload = {
        pms: 'entrata',
        integrationSettings: {
          pms: 'entrata',
          username: username,
          password: password,
          url
        }
      };
      dispatch(
        validatePmsHandler({
          payload,
          errorCheck: validatePmsErrorCall
        })
      );
    }
  };

  const saveClickHandler = () => {
    if (!verified || error) {
      validatePmsAction();
      return;
    }
    if (userData && Object.keys(integrationSettings).length < 2) {
      const initialItem = {
        tenantId,
        pms: pmsValue?.toLowerCase().replace(/ /g, ''),
        isIntegrationConfigured: true
      };
      const payload = Object.keys(form).reduce((acc, item) => {
        return form[item] ? { ...acc, [item]: form[item] } : acc;
      }, initialItem);
      dispatch(updateAccount({ payload, showNotification }));
    }
  };

  useEffect(() => {
    if (
      realPage &&
      form['pmcId'] &&
      verified &&
      !error &&
      !integrationSettings?.pmcId
    ) {
      setVerify(true);
    }
    if (entrata && form['password'] && form['url'] && form['username']) {
      setVerify(true);
    }
    if (
      yardi &&
      form['password'] &&
      form['url'] &&
      form['username'] &&
      form['yardiId']
    ) {
      setVerify(true);
    }
  }, [form, realPage, entrata, yardi, verified, error, integrationSettings]);

  useEffect(() => {
    dispatch(validatePmsError());
    return () => {
      infoPopUPHandler(false);
    };
  }, []);

  const showNotification = () => {
    infoPopUPHandler(true);
    setNotification('Profile Updated');
  };
  const closeNotification = () => {
    dispatch(validatePmsError());
    dispatch(userCreationNotification());
  };

  return (
    <div className="bg-white mt-20 mr-20 br-4 p-20 pmsContainer">
      {entrataAndYardi && (
        <div className="flex-top mb-20">
          <div className="flex-row">
            <div className="flex-col mr-30">
              <p className="label-text">User Name</p>
              <div className="flex">
                <TextField
                  className={styles.inputMd}
                  value={integrationSettings?.username || username}
                  onChange={handleForm}
                  name="username"
                  placeholder="Enter User Name"
                  sx={{ width: '350px', marginRight: '10px' }}
                />
              </div>
            </div>

            <div className="flex-col">
              <p className="label-text">Password</p>
              <TextField
                className={styles.inputMd}
                value={integrationSettings?.password || password}
                onChange={handleForm}
                name="password"
                placeholder="Enter Password"
                sx={{ width: '350px' }}
                type="password"
              />
            </div>
          </div>
          {yardi && (
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'row',
                cursor: 'pointer'
              }}
              onClick={() => {
                window.open(
                  process.env.REACT_APP_YARDI_RETOOL_DIAGNOSE_URL ?? '',
                  'WindowName',
                  'noopener'
                );
              }}
            >
              <div
                style={{
                  marginRight: '6px',
                  fontWeight: '400',
                  color: '#5267FF'
                }}
              >
                Diagnose
              </div>
              <div
                style={{
                  padding: 'auto',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                <img src={LinkIcon} alt="Diagnose" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex-row mb-20">
        {entrataAndYardi && (
          <div className="flex-col mr-30">
            <p className="label-text">URL</p>
            <div className="flex">
              <TextField
                disabled={
                  verified ||
                  (yardi && integrationSettings?.yardiUrl) ||
                  (entrata && integrationSettings?.url)
                }
                className={styles.inputMd}
                value={
                  yardi
                    ? integrationSettings?.yardiUrl
                    : entrata
                    ? integrationSettings?.url
                    : url
                }
                onChange={handleForm}
                name="url"
                placeholder="Enter URL"
                sx={{ width: '350px', marginRight: '10px' }}
              />
              {/* <ErrorIcon /> */}
            </div>
          </div>
        )}
        {yardi && (
          <div className="flex-col">
            <p className="label-text">SQL Database</p>
            <TextField
              disabled={
                verified ||
                (integrationSettings?.yardiId && integrationSettings?.yardiUrl)
              }
              className={styles.inputMd}
              value={integrationSettings?.database || sqlDataBase}
              onChange={handleForm}
              name="yardiId"
              placeholder="Enter URL"
              sx={{ width: '350px' }}
            />
          </div>
        )}
      </div>
      {realPage && (
        <div className="flex-col">
          <p className="label-text">PMC Id</p>

          <div className="veryfyContainer">
            <TextField
              className={styles.inputMd}
              disabled={integrationSettings?.pmcId}
              onChange={handleForm}
              value={integrationSettings?.pmcId || pmcId}
              name="pmcId"
              placeholder="Enter PMC Id"
              sx={{ width: '350px' }}
            />
            {!integrationSettings?.pmcId && (
              <div className="veryfyIcons">
                {error ? (
                  <ErrorIcon />
                ) : verified && !error ? (
                  <CheckCircleIcon />
                ) : (
                  <span onClick={validatePmsAction}>Verify</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-40">
        <Button
          onClick={saveClickHandler}
          className={verify ? 'save-btn' : 'save-btn save-btn-disable'}
          variant="contained"
        >
          {verified && !error ? 'Save' : 'Verify'}
        </Button>
      </div>
      {(error || selfServe?.userDataError) && (
        <Snackbar
          style={{
            backgroundColor: 'red'
          }}
          autoHideDuration={4000}
          open={error || selfServe?.userDataError}
          onClose={closeNotification}
          message={
            validatePms?.message ??
            'Something went wrong please try again with diffrent values'
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      )}
      {notification && (
        <ClientInfo
          setNotification={setNotification}
          notification={notification}
          info={info}
          infoPopUPHandler={infoPopUPHandler}
        />
      )}
    </div>
  );
};

export default PMS;
