import { Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  emptypriceListing,
  fetchPriceListings
} from '../../Redux/Actions/InboxV1';
import CustomCreatableSelect from '../CustomSelectV1';
import CustomPriceTable from '../CustomPriceTable';
import ArrowDown from '../../Media/InboxV1/ArrowDown';
import ArrowUp from '../../Media/InboxV1/ArrowUp';
import { useTheme } from 'styled-components';
import Icon from '../../globalcomponents/Icon';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ListingDetailsCard = ({
  apartmentId,
  apartmentData,
  apartments = [],
  propertyData,
  handleAutofillData,
  leadData,
  selectedLeadId,
  leadUpdateMutate,
  showSearch,
  setApartmentPayload,
  apartmentPayload,
  tenantName,
  isMaximizedPnAOpen,
  handlePNAExpansionToggle
}) => {
  const maxBedRoomList = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ];

  const maxBathRoomList = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ];

  const minList = [
    { value: 0, label: '$0' },
    { value: 100, label: '$100' },
    { value: 200, label: '$200' },
    { value: 300, label: '$300' },
    { value: 400, label: '$400' },
    { value: 500, label: '$500' },
    { value: 600, label: '$600' },
    { value: 700, label: '$700' },
    { value: 800, label: '$800' },
    { value: 900, label: '$900' },
    { value: 1000, label: '$1000' }
  ];
  const maxList = [
    { value: 100, label: '$100' },
    { value: 200, label: '$200' },
    { value: 300, label: '$300' },
    { value: 400, label: '$400' },
    { value: 500, label: '$500' },
    { value: 600, label: '$600' },
    { value: 700, label: '$700' },
    { value: 800, label: '$800' },
    { value: 900, label: '$900' },
    { value: 1000, label: '$1000' },
    { value: 1100, label: '$1100' }
  ];

  const [apartment, setApartment] = useState(apartmentData?._id);
  const [tabValue, setTabValue] = React.useState(0);

  const dispatch = useDispatch();
  const theme = useTheme();

  const [filterApplied, setFilterApplied] = useState(false);
  const [unit, setunit] = useState({});
  const [showbedBath, setShowbedBath] = useState(true);
  const [filteredRes, setFilteredRes] = useState(0);
  const {
    isPriceListingLoading,
    priceListingList,
    priceListingError,
    propertyDataLoading
  } = useSelector(state => state.inboxMessages);

  useEffect(() => {
    setApartmentPayload({ ...apartmentPayload, apartmentId });
  }, [apartmentId]);

  useEffect(() => {
    setApartment(apartmentData?._id);
  }, [apartmentData]);

  const handleTabChange = (event, newValue, applied = false) => {
    setTabValue(newValue);
    apartmentPayload['apartmentId'] = apartmentId;
    if (applied) {
      setFilterApplied(true);
    }
  };

  const saveLead = val => {
    let leadObj = {
      propertyId: val?._id,
      preference: {
        unit: val?._id
      }
    };

    leadUpdateMutate({
      variables: { leadId: selectedLeadId, record: leadObj }
    });
  };

  const handleBathRoomClick = (name = '', obj) => {
    setApartmentPayload({ ...apartmentPayload, bathRooms: obj.value });
  };

  const handleBedRoomClick = (room, obj) => {
    setApartmentPayload({ ...apartmentPayload, bedRooms: obj.value });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (value >= 0) {
      const updatedApartmentPayload = { ...apartmentPayload };
      updatedApartmentPayload[name] = value;
      setApartmentPayload(updatedApartmentPayload);
    }
  };

  const handleDateChange = date => {
    const updatedApartmentPayload = { ...apartmentPayload };
    updatedApartmentPayload.moveInDate = date;
    setApartmentPayload(updatedApartmentPayload);
  };

  const handleClear = () => {
    const updatedApartmentPayload = {
      apartmentId: apartmentId,
      bedRooms: 0,
      bathRooms: 0,
      moveInDate: null,
      min: '',
      max: '',
      term: { value: null, label: '' }
    };
    setApartmentPayload(updatedApartmentPayload);
    setFilterApplied(false);
    dispatch(emptypriceListing());
  };

  useEffect(() => {
    if (selectedLeadId) {
      handleClear();
    }
  }, [selectedLeadId]);

  return (
    <div className="right-side-cards card-container">
      {showSearch && (
        <div
          className="tabs-container"
          sx={{
            '& .MuiTabs-flexContainer': {
              backgroundColor: theme?.background?.selection
            }
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                indicatorColor=""
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{
                    position: 'relative',
                    color: theme?.text?.secondary
                  }}
                  label={
                    <>
                      Units
                      <span
                        style={{ left: '26px' }}
                        className={tabValue === 0 ? 'tab-border' : ''}
                      />
                      {filterApplied ? (
                        <span className={'filter-length'}>{filteredRes}</span>
                      ) : (
                        <span className={'filter-length'}>
                          {priceListingList.length}
                        </span>
                      )}
                    </>
                  }
                />
                <Tab
                  style={{
                    position: 'relative',
                    color: theme?.text?.secondary
                  }}
                  label={
                    <>
                      Filter
                      <span className={tabValue === 1 ? 'tab-border' : ''} />
                      <span className={filterApplied ? 'filter-applied' : ''} />
                    </>
                  }
                />
                {leadData?.meta?.pms === 'yardi' && (
                  <span
                    style={{
                      marginLeft: 'auto',
                      marginRight: '5px',
                      alignSelf: 'center'
                    }}
                  >
                    {isMaximizedPnAOpen ? (
                      <Icon
                        src="minimize.svg"
                        id="minimize-pna"
                        alt="minimize-pna"
                        onClick={handlePNAExpansionToggle}
                      />
                    ) : (
                      <Icon
                        src={`maximize-${theme?.state}.svg`}
                        id="maximize-pna"
                        alt="maximize-pna"
                        onClick={handlePNAExpansionToggle}
                      />
                    )}
                  </span>
                )}
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <CustomPriceTable
                handleAutofillData={handleAutofillData}
                currentApartmentId={apartmentPayload?.apartmentId}
                data={
                  tenantName === 'Case and Associates'
                    ? priceListingList.filter(
                        item => !item?.unitName?.includes('Z')
                      )
                    : priceListingList
                }
                loading={isPriceListingLoading}
                bedRoom={apartmentPayload?.bedRooms}
                bathRoom={apartmentPayload?.bathRooms}
                tenantName={tenantName}
                min={apartmentPayload?.min}
                max={apartmentPayload?.max}
                saveLead={saveLead}
                setFilteredRes={setFilteredRes}
                priceListingError={priceListingError}
              />
            </TabPanel>
            <TabPanel
              style={{
                padding: '0 10px',
                background: theme?.background?.secondary
              }}
              value={tabValue}
              index={1}
            >
              <div>
                <div className="flex" style={{ marginTop: '10px' }}>
                  <div className="flex-col" style={{ width: '45%' }}>
                    <Typography
                      style={{
                        marginBottom: '6px',
                        color: theme?.text?.primary
                      }}
                      className="sub-title"
                    >
                      Move-in date
                    </Typography>
                    <ReactDatePicker
                      minDate={new Date()}
                      onChange={date => handleDateChange(date)}
                      selected={apartmentPayload.moveInDate}
                      className="custom-datepicker"
                      placeholderText="Select date"
                      width={'100%'}
                    />
                  </div>
                </div>
                <div
                  style={{
                    borderTop: '0.5px solid #c4c4c4',
                    marginTop: '20px',
                    position: 'relative',
                    marginBottom: '20px'
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '-10px',
                      borderRadius: '50%',
                      background: '#c4c4c4',
                      width: '20px',
                      height: '20px'
                    }}
                  >
                    {!showbedBath ? (
                      <ArrowDown
                        onClick={() => setShowbedBath(true)}
                        width={'20'}
                        height={'20'}
                        viewBox={'0 0 20 20'}
                      />
                    ) : (
                      <ArrowUp
                        onClick={() => setShowbedBath(false)}
                        width={'20'}
                        height={'20'}
                        viewBox={'0 0 20 20'}
                      />
                    )}
                  </div>
                </div>
                {showbedBath ? (
                  <>
                    <div className="flex" style={{ marginTop: '6px' }}>
                      <div className="flex-col" style={{ width: '48%' }}>
                        <Typography
                          style={{
                            marginBottom: '6px',
                            color: theme?.text?.primary
                          }}
                          className="sub-title"
                        >
                          Bedroom
                        </Typography>

                        <CustomCreatableSelect
                          handleSelect={handleBedRoomClick}
                          name="bedRooms"
                          value={{
                            value: apartmentPayload.bedRooms,
                            label: `${
                              apartmentPayload?.bedRooms
                                ? apartmentPayload.bedRooms
                                : 'Select'
                            }`
                          }}
                          options={maxBedRoomList}
                          placeholder={'BedRoom'}
                          width="100%"
                        />
                      </div>
                      <div className="flex-col" style={{ width: '48%' }}>
                        <Typography
                          style={{
                            marginBottom: '6px',
                            color: theme?.text?.primary
                          }}
                          className="sub-title"
                        >
                          Bathroom
                        </Typography>
                        <CustomCreatableSelect
                          handleSelect={handleBathRoomClick}
                          value={{
                            value: apartmentPayload.bathRooms,
                            label: `${
                              apartmentPayload?.bathRooms
                                ? apartmentPayload.bathRooms
                                : 'Select'
                            }`
                          }}
                          name="bathRooms"
                          options={maxBathRoomList}
                          placeholder={'Bathroom'}
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="flex-col" style={{ marginTop: '6px' }}>
                      <Typography
                        className="sub-title"
                        style={{
                          marginBottom: '6px',
                          color: theme?.text?.primary
                        }}
                      >
                        Price Range in $
                      </Typography>
                      <div className="flex">
                        <TextField
                          style={{
                            width: '48%'
                          }}
                          onChange={handleChange}
                          value={apartmentPayload.min}
                          name="min"
                          placeholder="Min"
                          sx={{
                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                              border: theme?.border?.primary,
                              color: theme?.text?.primary
                            }
                          }}
                        />
                        <TextField
                          style={{
                            width: '48%'
                          }}
                          onChange={handleChange}
                          value={apartmentPayload.max}
                          name="max"
                          placeholder="Max"
                          sx={{
                            border: theme?.border?.primary,
                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                              color: theme?.text?.primary
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div style={{ marginTop: '20px' }}>
                <Button
                  onClick={e => handleTabChange(e, 0, true)}
                  className="filter-btn"
                  variant="contained"
                  style={{
                    cursor: 'pointer',
                    background: theme?.background?.selection,
                    color: theme?.text?.secondary
                  }}
                >
                  Filter
                </Button>
                <Button
                  onClick={handleClear}
                  style={{
                    color: theme?.text?.primary,
                    fontSize: '12px',
                    textTransform: 'capitalize'
                  }}
                  variant="text"
                >
                  Clear
                </Button>
              </div>
            </TabPanel>
          </Box>
        </div>
      )}
    </div>
  );
};

export default ListingDetailsCard;
