import React, { useState } from 'react';
import NotesPopup from './ChatComponents/NotesPopup';
import Thinking from '../../Media/KelseyInsight/KelseyThinking.gif';
import { useSelector } from 'react-redux';
import { tiggerKelsey } from '../../Redux/util/Apis/inbox';
import { Tooltip } from '@mui/material';
import Icon from '../../globalcomponents/Icon';
import { useTheme } from 'styled-components';

const ArchiveContainer = props => {
  const {
    isArchived,
    selectedLeadId,
    leadUpdateMutate,
    setArchiveClick,
    archiveClick,
    newNoteInsertionInProgress,
    handleOutgoingMessage,
    handleNoteCreation,
    handleCallLogCreation,
    preference,
    apartmentId,
    leadTenantId,
    isBotActive
  } = props;
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [showTrigger, setShowTrigger] = useState(true);

  const { sessionData } = useSelector(state => state.inboxMessages);
  const updateArchiveStatus = () => {
    setArchiveClick(true);
    let archiveBody = {
      isArchived: !isArchived
    };
    if (!isArchived) {
      archiveBody.isAgentProcessingLead = false;
    }
    if (selectedLeadId) {
      if (leadUpdateMutate) {
        leadUpdateMutate({
          variables: { leadId: selectedLeadId, record: archiveBody }
        });
      }
    }
  };
  const handleKelseyTrigger = async () => {
    const res = await tiggerKelsey({
      leadId: selectedLeadId,
      apartmentId: apartmentId,
      unitId: preference?.unit,
      utterance: null,
      appId: 'zuma',
      event: 'kelsey-trigger',
      tenantId: leadTenantId
    });
    setShowTrigger(false);
    return res?.data?.success || false;
  };

  return (
    <div className="d-flex-row">
      {showTrigger && sessionData?.kelsey_trigger && isBotActive && (
        <Tooltip title="Save Kelsey">
          <figure
            className="figure cursor-pointer"
            onClick={handleKelseyTrigger}
            style={{ margin: '15px 8px' }}
          >
            <img width={'40px'} src={Thinking} alt="kelsey-text" />
          </figure>
        </Tooltip>
      )}
      <figure className="figure cursor-pointer" style={{ margin: '15px 8px' }}>
        {archiveClick ? (
          <div className="archive-loader" />
        ) : isArchived ? (
          theme.state === 'light' ? (
            <Icon
              src="archive-out.svg"
              tooltipText="Un-archive lead"
              onClick={updateArchiveStatus}
              alt="Unarchive lead"
            />
          ) : (
            <Icon
              src="archive-out-dark.svg"
              onClick={updateArchiveStatus}
              tooltipText="Un-archive lead"
              alt="Unarchive lead"
            />
          )
        ) : theme.state === 'light' ? (
          <Icon
            src="archive-in.svg"
            tooltipText="Archive lead"
            onClick={updateArchiveStatus}
            alt="Archive lead"
          />
        ) : (
          <Icon
            src="archive-dark.svg"
            onClick={updateArchiveStatus}
            tooltipText="Archive lead"
            alt="Archive lead"
          />
        )}
      </figure>

      <figure style={{ margin: '15px 8px' }} className="figure cursor-pointer">
        {theme.state === 'light' ? (
          <Icon
            src="notes.svg"
            tooltipText="Notes"
            onClick={() => setOpen(!open)}
          />
        ) : (
          <Icon
            src="notes-dark.svg"
            tooltipText="Notes"
            onClick={() => setOpen(!open)}
          />
        )}
      </figure>

      <NotesPopup
        {...{
          open,
          setOpen,
          selectedLeadId,
          newNoteInsertionInProgress,
          handleOutgoingMessage,
          handleNoteCreation,
          handleCallLogCreation
        }}
      />
    </div>
  );
};

export default ArchiveContainer;
