import React, { Component, useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import MySelect from './MySelect.js';
import { components } from 'react-select';
import './select.css';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          className="selectStyle"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props} style={{ color: 'red' }}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();
const ZSelect = ({
  optionsList,
  name,
  selectedOptions = [],
  placeholder = '',
  handleSelectedData
}) => {
  const [optionSelected, setOptionSelected] = useState([]);

  useEffect(() => {
    const selectedValues = selectedOptions.map(item => {
      return {
        label: item.name,
        value: item._id
      };
    });
    setOptionSelected(selectedValues);
  }, [selectedOptions]);

  const listToLoop = optionsList.filter(option => {
    if (option !== null) {
      return option;
    }
  });

  let dupLoop = listToLoop.map(item => {
    return {
      label: item.name,
      value: item._id
    };
  });

  const handleChange = selected => {
    setOptionSelected(selected);
    let selectedArr = [];
    if (selected !== null) {
      selectedArr = selected.map(item => {
        return {
          name: item.label,
          _id: item.value
        };
      });
    }

    handleSelectedData(name, selectedArr);
  };

  if (name === 'communities') {
    dupLoop = [{ label: 'unknown', value: null }, ...dupLoop];
  }
  return (
    <MySelect
      options={dupLoop}
      isMulti
      name={name}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue, animatedComponents }}
      onChange={handleChange}
      allowSelectAll={true}
      value={optionSelected}
    />
  );
};

export default ZSelect;
