import {
  GET_INBOX_MSG,
  GET_ALL_LEAD_MSG,
  GET_INBOX_META,
  BACKDROP_STATUS,
  ADD_LEAD_LOADER,
  FILTER_STATE
} from '../Constant';
const initialState = {
  InboxMessage: [],
  LeadMessage: [],
  InboxComment: [],
  Users: [],
  MandateFeedback: null,
  messageDataLoader: true,
  leadLoader: true,
  outMsgLoaderAdd: false,
  inboxMetaId: null,
  backdropStatus: false,
  timeSlots: [],
  MessageInTemplate: [],
  incommingAct: false,
  scheduleLoader: false,
  filterState: [],
  Count: 0
};
const Inbox = (state = initialState, action) => {
  switch (action.type) {
    case GET_INBOX_MSG:
      return {
        ...state,
        InboxMessage: { ...state.InboxMessage, ...action.data }
      };
    case GET_ALL_LEAD_MSG:
      return {
        ...state,
        LeadMessage: action.data,
        messageDataLoader: false,
        outMsgLoaderAdd: false
      };
    case GET_INBOX_META:
      return { ...state, inboxMetaId: action.data };
    case BACKDROP_STATUS:
      return { ...state, backdropStatus: action.data };
    case ADD_LEAD_LOADER:
      return { ...state, leadLoader: action.data };
    case FILTER_STATE:
      return { ...state, filterState: action.data };
    default:
      return state;
  }
};
export default Inbox;
