import React from 'react';

export const CreateLeadTimelineEvent = props => {
  const { meta } = props;
  const name = meta?.sync[0]?.syncSource;
  return (
    <div className="message-owner d-flex-just-align-center inter-12px-500">
      <span>{name || meta?.agentName}</span>
    </div>
  );
};
