import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from './styles';
import { TEMPLATE_CONSTANTS } from './constants';
import { TextField } from '@mui/material';
import ChatPopover from '../../Inbox/ChatElements/ChatPopover';
import GiphyPopover from '../../Inbox/ChatElements/GiphyPopover';
import _ from 'lodash';
import GifContainer from './GifContainer';

const SmsTemplate = ({ open, handleClose, handleSubmit, templateMessage }) => {
  const classes = useStyles();

  const [message, setMessage] = React.useState(templateMessage?.message);

  React.useEffect(() => {
    setMessage(templateMessage?.message);
  }, [templateMessage]);

  const addVariable = variable => {
    let temp = `${message}_${variable}_`;
    setMessage(temp);
  };

  const [gifData, setGifData] = React.useState([]);

  React.useEffect(() => {
    if (_.has(templateMessage, 'media')) {
      setGifData(templateMessage?.media);
    }
  }, [templateMessage]);

  const handleSaveClick = () => {
    if (!message.length) {
      return alert('Template message cannot be empty!');
    }
    handleSubmit(message, gifData);
  };

  const handleChatEmojis = (images, type) => {
    if (type === 'emoji') {
      setMessage(message + images);
    } else {
      const imagesData = [];
      imagesData.push(images);
      setGifData(imagesData);
    }
  };

  const handleCancelGif = event => {
    setGifData([]);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.modalHeader} id="responsive-dialog-title">
        Add New Template
      </DialogTitle>
      <DialogContent>
        <div className={classes.buttonContainer}>
          {TEMPLATE_CONSTANTS.map((item, i) => {
            return (
              <Button
                key={i}
                variant="contained"
                className={classes.templateButton}
                onClick={() => addVariable(item.value)}
              >
                {item.name}
              </Button>
            );
          })}
        </div>
        <div>
          <div className={classes.subHeading}>Template Message *</div>
          <TextField
            id="Template Message"
            variant="outlined"
            placeholder="Start typing your template"
            fullWidth
            className={classes.inputRoot}
            multiline
            // error={valid && (Message === null || Message === "")}
            value={message}
            rows={4}
            onChange={e => setMessage(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ width: '100px', height: '100px' }}>
              {gifData.map((gif, idx) => {
                return (
                  <GifContainer
                    key={idx}
                    gif={gif}
                    onCancel={handleCancelGif}
                  />
                );
              })}
            </div>
            <div style={{ display: 'flex' }}>
              <ChatPopover isInTemplate handleChatEmojis={handleChatEmojis} />
              <GiphyPopover handleChatEmojis={handleChatEmojis} />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.backButton}
          variant="outlined"
          sx={{ marginRight: '18px' }}
          autoFocus
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.formButton}
          onClick={handleSaveClick}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsTemplate;
