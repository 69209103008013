import { useState } from 'react';
import {
  FlexContainer,
  TootltipText
} from '../../Container/InboxV2/ScheduledMessages/styles.schudledmessages';
import { clickToCopy, truncateText } from '../../Container/InboxV2/inboxUtils';
import Badge from '../../globalcomponents/Badge';
import { BadgeWrapper } from './styles.maximisedpnawidget';

const AmenitiesColumn = ({ rowData }) => {
  const amenitiesArray = rowData.amenities ? JSON.parse(rowData.amenities) : [];

  return (
    <BadgeWrapper>
      {amenitiesArray.map((amenity, index) => {
        return <AmenityBadge key={index} amenity={amenity} />;
      })}
    </BadgeWrapper>
  );
};

const AmenityBadge = ({ amenity }) => {
  const truncatedText = truncateText(amenity, 15);

  const initialTooltipText = (
    <FlexContainer style={{ padding: '5px' }}>
      <TootltipText>*Click the pill to copy</TootltipText>
      <TootltipText>{amenity}</TootltipText>
    </FlexContainer>
  );

  const [tooltipContent, setTooltipContent] = useState(initialTooltipText);

  const handleClick = event => {
    event.stopPropagation();
    clickToCopy(amenity);
    setTooltipContent('Copied!');
    setTimeout(() => {
      setTooltipContent(initialTooltipText);
    }, 2000);
  };

  return (
    <Badge
      background="rgba(196,196,196,0.5)"
      color="#393939"
      borderRadius="40px"
      onClick={handleClick}
      items={[amenity]}
      tooltipJsx={tooltipContent}
    >
      {truncatedText}
    </Badge>
  );
};

export default AmenitiesColumn;
