import { LOGIN_ERROR } from '../Constant';

const initialState = {
  LoginToken: '',
  msg: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return { ...state, msg: action.data };
    default:
      return state;
  }
};
