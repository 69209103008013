import { Button, MenuItem, TextField } from '@material-ui/core';
import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDate } from '../../../utils';
import '../LeadDetails.scss';
import {
  BEDROOMS,
  FURNISHED_TYPES,
  LEASE_MONTHS,
  NO_OF_OCCUPANTS
} from '../../Constants/LeadStages';
import DropdownArrow from '../../../Media/InboxV1/DropdownArrow';
import CopyIcon from '../../../Media/InboxV1/CpoyIcon';
import { getInternalSettings } from '../../../Redux/Actions/SelfServe';
import ZSwitch from '../../../globalcomponents/ZSwitch';
import Input from '../../../Container/KelseyInsight/Input';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { LeadDetailsEditToggle, LeadEditLabel } from './leadDetailsEdit.styles';
import { useTheme } from 'styled-components';

const LeadDetailsEdit = props => {
  const dispatch = useDispatch();

  const {
    unit,
    bathroom,
    bedroom,
    budgetMax,
    budgetMin,
    creditScore,
    tenantId,
    deposit,
    income,
    interest,
    leaseTerm,
    monthlyRent,
    moveIn,
    occupant,
    pet,
    setShowEditDetails,
    callOptIn,
    apartment,
    selectedLeadId,
    email,
    phone,
    name,
    textOptIn,
    petType,
    isFurnished,
    apartmentData = [],
    propertyData = [],
    meta,
    leadUpdateMutate,
    firstContactType
  } = props;

  const settingsListData = useSelector(state => state.SelfServe.settingsList);

  useEffect(() => {
    dispatch(getInternalSettings());
  }, []);

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  let updatedLeadData = {
    callOptIn,
    email,
    name,
    phone,
    textOptIn,
    apartment,
    preference: {
      bathroom,
      bedroom,
      budgetMax,
      budgetMin,
      creditScore,
      income,
      interest,
      leaseTerm,
      moveIn,
      occupant,
      petType,
      isFurnished,
      unit,
      pet
    }
  };
  const [newLeadData, setNewLeadData] = useState({});
  const handleChange = e => {
    const name = e.target.name;
    const value =
      name == 'callOptIn' || name == 'textOptIn'
        ? e.target.checked
        : e.target.value;

    if (
      name == 'callOptIn' ||
      name == 'email' ||
      name == 'name' ||
      name == 'phone' ||
      name == 'textOptIn'
    ) {
      updatedLeadData = {
        ...updatedLeadData,
        [name]: value
      };
      setNewLeadData({
        ...newLeadData,
        [name]: value
      });
    } else {
      updatedLeadData = {
        ...updatedLeadData,
        preference: {
          ...updatedLeadData.preference,
          [name]: value
        }
      };

      setNewLeadData({
        ...newLeadData,
        preference: {
          ...newLeadData.preference,
          [name]: value
        }
      });
    }
  };
  const saveNewLeadDetails = (newMetaObject, callMessageApi) => {
    if (selectedLeadId) {
      if (newMetaObject?.preference?.budgetMax) {
        newMetaObject.preference.budgetMax = parseInt(
          newMetaObject.preference.budgetMax
        );
      }
      if (newMetaObject?.preference?.budgetMin) {
        newMetaObject.preference.budgetMin = parseInt(
          newMetaObject.preference.budgetMin
        );
      }
      if (newMetaObject?.preference?.creditScore) {
        newMetaObject.preference.creditScore = parseInt(
          newMetaObject.preference.creditScore
        );
      }
      if (newMetaObject?.preference?.income) {
        newMetaObject.preference.income = parseInt(
          newMetaObject.preference.income
        );
      }
      leadUpdateMutate({
        variables: {
          leadId: selectedLeadId,
          record: {
            ...newMetaObject
          }
        }
      });
    }

    setShowEditDetails(false);
  };
  const { darkMode } = useContext(GlobalContext);
  return (
    <>
      <div>
        <div
          style={{ background: darkMode ? '#1D202D' : '' }}
          className="right-side-cards inter-600px-14px"
        >
          <div className="flex">
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="card-title"
            >
              Lead Details
            </Typography>
          </div>
          <div className="flex-start mt-10 ">
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="ld-sub-title"
            >
              Lead Id:
            </Typography>
            <div className="flex-start">
              <Typography
                style={{ color: darkMode ? '#fff' : '' }}
                className="ld-sub-title"
              >
                {selectedLeadId}
              </Typography>
              <CopyIcon
                onClick={() => {
                  navigator.clipboard.writeText(selectedLeadId);
                }}
              />
            </div>
          </div>
          <div className="sub-container">
            <Typography
              style={{ color: darkMode ? '#fff' : '' }}
              className="sub-container-title-heading"
            >
              Unit Details
            </Typography>
            <div className="mt-10 mb-10 flex">
              <div style={{ width: '48%' }}>
                <label
                  style={{ color: darkMode ? '#fff' : '' }}
                  className="label-text-listing"
                >
                  Move-in Date
                </label>
                <div style={{ color: 'red' }}>
                  {settingsListData.requestedMoveIn === true ? '*' : null}
                </div>
                <TextField
                  placeholder="Move-in-date"
                  name={'moveIn'}
                  fullWidth
                  defaultValue={getDate(moveIn, true)}
                  variant={'standard'}
                  type="date"
                  onChange={handleChange}
                  InputProps={{
                    disableUnderline: true,
                    margin: 'none',
                    style: { color: theme.text.primary }
                  }}
                  size="small"
                  style={{
                    marginTop: '6px',
                    fontSize: 12,
                    lineHeight: '15px',
                    color: darkMode ? '#fff' : 'rgba(57, 57, 57, 0.7)',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                />
              </div>
              <div style={{ width: '48%' }}>
                <div style={{ color: 'red' }}>
                  {settingsListData.occupants === true ? '*' : null}
                </div>
                <LeadEditLabel>Occupant</LeadEditLabel>
                <TextField
                  fullWidth
                  name={'occupant'}
                  onChange={handleChange}
                  defaultValue={occupant || 0}
                  options={NO_OF_OCCUPANTS}
                  variant="standard"
                  select
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { color: theme.text.primary }
                  }}
                  style={{
                    marginTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                >
                  {NO_OF_OCCUPANTS.map(({ name, value }) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="mt-10 mb-10">
              <div>
                <LeadEditLabel>Interested Community</LeadEditLabel>

                <div style={{ color: 'red' }}>
                  {settingsListData.communityOfInterest === true ? '*' : null}
                </div>
                <TextField
                  fullWidth
                  name={'interest'}
                  onChange={handleChange}
                  defaultValue={apartment?.name || 0}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: { color: theme.text.primary }
                  }}
                  disabled={true}
                  style={{
                    marginTop: '6px'
                  }}
                ></TextField>
              </div>
            </div>
            <div className="mt-10 mb-10">
              <div>
                <LeadEditLabel>Preferred Unit</LeadEditLabel>
                <div style={{ color: 'red' }}>
                  {settingsListData.preferredUnits === true ? '*' : null}
                </div>
                <TextField
                  fullWidth
                  name={'unit'}
                  onChange={handleChange}
                  defaultValue={unit || 0}
                  variant="standard"
                  select
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { color: theme.text.primary }
                  }}
                  style={{
                    paddingTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                >
                  {propertyData.map(
                    ({ name, _id, sqft, bathRooms, bedRooms, monthlyRent }) => {
                      return (
                        <MenuItem
                          style={{
                            background: theme.background.secondary,
                            color: theme.text.primary
                          }}
                          value={_id}
                          key={_id}
                        >
                          {'$' +
                            monthlyRent +
                            ' - ' +
                            bedRooms +
                            'bd ' +
                            bathRooms +
                            'ba - ' +
                            (!sqft ? 0 + ' sqft - ' : sqft + ' sqft - ') +
                            name}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField>
              </div>
            </div>
            <div className="mt-10 mb-10 flex">
              <div style={{ width: '48%' }}>
                <LeadEditLabel> Min Budget</LeadEditLabel>
                <div style={{ color: 'red' }}>
                  {settingsListData.budgetMin === true ? '*' : null}
                </div>
                <Input
                  size="xs"
                  onChange={handleChange}
                  defaultValue={budgetMin}
                  label=" "
                  prepend
                  placeholder="Min Budget"
                  name="budgetMin"
                />
              </div>
              <div style={{ width: '48%' }}>
                <LeadEditLabel> Max Budget</LeadEditLabel>
                <div style={{ color: 'red' }}>
                  {settingsListData.budgetMax === true ? '*' : null}
                </div>
                <Input
                  size="xs"
                  onChange={handleChange}
                  defaultValue={budgetMax}
                  label=" "
                  prepend
                  placeholder="Max Budget"
                  name="budgetMax"
                />
              </div>
            </div>

            <div className="mt-10 mb-10 flex">
              <div style={{ width: '48%' }}>
                <LeadEditLabel> BedRoom </LeadEditLabel>
                <div style={{ color: 'red' }}>
                  {settingsListData.bedrooms === true ? '*' : null}
                </div>
                <TextField
                  fullWidth
                  name={'bedroom'}
                  onChange={handleChange}
                  defaultValue={bedroom || 0}
                  options={NO_OF_OCCUPANTS}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: { color: theme.text.primary }
                  }}
                  select
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                  style={{
                    marginTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                >
                  {BEDROOMS.map(({ name, value }) => (
                    <MenuItem
                      style={{
                        background: theme.background.secondary,
                        color: theme.text.primary
                      }}
                      value={value}
                      key={value}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ width: '48%' }}>
                <LeadEditLabel> Bath Room </LeadEditLabel>
                <div style={{ color: 'red' }}>
                  {settingsListData.bathrooms === true ? '*' : null}
                </div>
                <TextField
                  fullWidth
                  name={'bathroom'}
                  onChange={handleChange}
                  defaultValue={bathroom || 0}
                  options={NO_OF_OCCUPANTS}
                  variant="standard"
                  select
                  InputProps={{
                    disableUnderline: true,

                    style: { color: theme.text.primary }
                  }}
                  style={{
                    marginTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                >
                  {BEDROOMS.map(({ name, value }) => (
                    <MenuItem
                      style={{
                        background: theme.background.secondary,
                        color: theme.text.primary
                      }}
                      value={value}
                      key={value}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className="mt-10 mb-10 flex">
              <div style={{ width: '48%' }}>
                <LeadEditLabel> Lease Length</LeadEditLabel>
                <TextField
                  fullWidth
                  id="Lease-Term"
                  onChange={handleChange}
                  name={'leaseTerm'}
                  variant="standard"
                  defaultValue={leaseTerm || 0}
                  select
                  style={{
                    marginTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',
                    paddingLeft: '4px'
                  }}
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                  InputProps={{
                    disableUnderline: true,

                    style: { color: theme.text.primary }
                  }}
                >
                  {LEASE_MONTHS.map(({ name, value }) => (
                    <MenuItem
                      style={{
                        background: theme.background.secondary,
                        color: theme.text.primary
                      }}
                      value={value}
                      key={value}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ width: '48%' }}>
                <LeadEditLabel> Furnished </LeadEditLabel>
                <TextField
                  fullWidth
                  name={'isFurnished'}
                  onChange={handleChange}
                  placeholder="Furnished"
                  defaultValue={isFurnished || false}
                  variant="standard"
                  select
                  className="test-class"
                  style={{
                    marginTop: '6px',
                    border: '0.5px solid #C4C4C4',
                    borderRadius: '4px',

                    color: '#fff'
                  }}
                  SelectProps={{
                    IconComponent: () => <DropdownArrow />
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { color: theme.text.primary }
                  }}
                >
                  {FURNISHED_TYPES.map(({ name, value }) => (
                    <MenuItem
                      className="mui-options"
                      style={{
                        paddingTop: '0px',
                        background: theme.background.secondary,
                        color: theme.text.primary,
                        fontSize: '12px'
                      }}
                      value={value}
                      key={value}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>

          <div className="sub-container">
            <div className="d-flex-jst-space-around">
              <div className="flex inter-12px-500">
                <LeadDetailsEditToggle> Sms-Opt-In </LeadDetailsEditToggle>
                <ZSwitch
                  id="textOptIn"
                  name="textOptIn"
                  onChange={handleChange}
                  checked={textOptIn}
                  small
                />
              </div>
              <div className="flex inter-12px-500">
                <LeadDetailsEditToggle>Call-Opt-In</LeadDetailsEditToggle>
                <ZSwitch
                  id="callOptIn"
                  name="callOptIn"
                  onChange={handleChange}
                  checked={callOptIn}
                  small
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button
              variant="outlined"
              style={{
                background: '#5267FF',
                color: '#fff',
                border: 'none',
                textTransform: 'capitalize'
              }}
              onClick={() => saveNewLeadDetails(newLeadData)}
            >
              Save
            </Button>
            <Button
              style={{
                color: darkMode ? '#fff' : '',
                textTransform: 'capitalize'
              }}
              variant="inherit"
              onClick={() => setShowEditDetails(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadDetailsEdit;
