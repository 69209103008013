import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  configIntegrationApi,
  createInternalSettingsApi,
  createNewChannelAccount,
  deleteChannelAccountApi,
  deletePhoneNumber,
  downloadCheatSheetByApartmentIdsApis,
  downloadCsvApi,
  dupNewAccount,
  getChannelAccountDetailsApi,
  getIntegration as getIntegrationApi,
  getInternalSettingsApi,
  getTenantDetailsApi,
  getUserDataApi,
  getYardiCommunityAgentsApi,
  ingestCommuntiyApi,
  ingestedCommunitiesApiGet,
  onboardCommunitiesApi,
  postIntegrationFlagsApi,
  updateChannelAccountEmailStatusApi,
  updateLeasingAgentsApi,
  updatePortal,
  updateRestrictCommunicationApi,
  updateWorkingHoursApi,
  uploadCsv,
  validatePmsApi,
  verifyCommunityApi,
  verifyRentCafeApi
} from '../util/Apis/selfServe';

export const dupNewUser = createAsyncThunk(
  'selfServe/dupNewUser',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await dupNewAccount(payload);
      showNotification();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const channelAccountSettings = createAsyncThunk(
  'selfServe/channelAccountSettings',
  async (
    { email, outgoingEnabled, incomingEnabled, tenantId },
    { rejectWithValue }
  ) => {
    try {
      return await createNewChannelAccount({
        email,
        outgoingEnabled,
        incomingEnabled,
        tenantId
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteChannelAccount = createAsyncThunk(
  'selfServe/deleteChannelAccount',
  async ({ email }, { rejectWithValue }) => {
    try {
      return await deleteChannelAccountApi({ email: email });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const downloadCheatSheetByApartmentIds = createAsyncThunk(
  'selfServe/downloadCheatSheetByApartmentIds',
  async ({ apartmentIds: payload }, { rejectWithValue }) => {
    try {
      return await downloadCheatSheetByApartmentIdsApis(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateChannelAccountEmailStatus = createAsyncThunk(
  'selfServe/updateChannelAccountEmailStatus',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await updateChannelAccountEmailStatusApi(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateAccount = createAsyncThunk(
  'selfServe/updateAccount',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await updatePortal(payload);
      showNotification();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getTenantDetails = createAsyncThunk(
  'selfServe/getTenantDetails',
  async (params, { rejectWithValue }) => {
    try {
      return await getTenantDetailsApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChannelAccountDetails = createAsyncThunk(
  'selfServe/getChannelAccountDetails',
  async (params, { rejectWithValue }) => {
    try {
      return await getChannelAccountDetailsApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const verifyCommunity = createAsyncThunk(
  'selfServe/verifyCommunity',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await verifyCommunityApi(payload);
      if (showNotification) {
        showNotification();
      }
      return response;
    } catch (err) {
      if (showNotification) {
        showNotification(false, true);
      }
      return rejectWithValue(err);
    }
  }
);
export const getYardiCommunityAgents = createAsyncThunk(
  'selfServe/getYardiCommunityAgents',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await getYardiCommunityAgentsApi(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateLeasingAgents = createAsyncThunk(
  'selfServe/updateLeasingAgents',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await updateLeasingAgentsApi(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ingestCommunity = createAsyncThunk(
  'selfServe/ingestCommunity',
  async (params, { rejectWithValue }) => {
    try {
      return await ingestCommuntiyApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ingestCommunityGet = createAsyncThunk(
  'selfServe/ingestCommunityGet',
  async (params, { rejectWithValue }) => {
    try {
      return await ingestedCommunitiesApiGet(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIntegration = createAsyncThunk(
  'selfServe/getIntegration',
  async (params, { rejectWithValue }) => {
    try {
      return await getIntegrationApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserData = createAsyncThunk(
  'selfServe/getUserData',
  async (_args, { rejectWithValue }) => {
    try {
      return await getUserDataApi();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createInternalSettings = createAsyncThunk(
  'selfServe/createInternalSettings',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await createInternalSettingsApi(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateRestrictCommunication = createAsyncThunk(
  'selfServe/updateRestrictCommunication',
  async (payload, { rejectWithValue }) => {
    try {
      return await updateRestrictCommunicationApi(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyRentCafe = createAsyncThunk(
  'selfServe/verifyRentCafe',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await verifyRentCafeApi(payload);
      if (showNotification) {
        showNotification();
      }
      return response;
    } catch (err) {
      if (showNotification) {
        showNotification(false, true);
      }
      return rejectWithValue(err);
    }
  }
);

export const getInternalSettings = createAsyncThunk(
  'selfServe/getInternalSettings',
  async (params, { rejectWithValue }) => {
    try {
      return await getInternalSettingsApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const downloadCsv = createAsyncThunk(
  'selfServe/downloadCsv',
  async ({ params }, { rejectWithValue }) => {
    try {
      return await downloadCsvApi(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadFiles = createAsyncThunk(
  'selfServe/uploadFiles',
  async ({ payload, uploadNotification }, { rejectWithValue }) => {
    try {
      const response = await uploadCsv(payload);
      if (response?.data?.data?.errorCode) {
        uploadNotification('upload failed try upload csv file again');
      } else {
        uploadNotification('Mapping Details');
      }
      return response;
    } catch (err) {
      uploadNotification('upload failed try upload csv file again');
      return rejectWithValue(err);
    }
  }
);

export const validatePms = createAsyncThunk(
  'selfServe/validatePms',
  async ({ payload, errorCheck }, { rejectWithValue }) => {
    try {
      const response = await validatePmsApi(payload);
      console.log('validatePms response', response);
      if (errorCheck) {
        if (!response?.data?.data) {
          errorCheck(true);
        } else {
          errorCheck(false);
        }
      }
      return response;
    } catch (err) {
      errorCheck(true);
      return rejectWithValue(err);
    }
  }
);

export const updateWorkingHours = createAsyncThunk(
  'selfServe/updateWorkingHours',
  async (
    { payload, showNotification, onboard, ingestCommunitiesOnLoad },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateWorkingHoursApi(payload);
      if (response?.data) {
        showNotification(onboard);
        ingestCommunitiesOnLoad();
      } else {
        showNotification(false, true);
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const onBoardCommunities = createAsyncThunk(
  'selfserve/onBoardCommunities',
  async ({ payload, ingestCommunitiesOnLoad }, { rejectWithValue }) => {
    try {
      const response = await onboardCommunitiesApi(payload);
      if (ingestCommunitiesOnLoad) {
        ingestCommunitiesOnLoad({
          upload: true,
          success: true,
          msg: 'successFully onboarded'
        });
      }
      return response;
    } catch (err) {
      if (ingestCommunitiesOnLoad) {
        ingestCommunitiesOnLoad({
          upload: true,
          success: false,
          msg: 'onboard failed try again'
        });
      }
      return rejectWithValue(err);
    }
  }
);
export const applySettings = createAsyncThunk(
  'selfserve/applySettings',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await postIntegrationFlagsApi(payload);
      if (showNotification) {
        showNotification('success', true);
      }
      return response;
    } catch (err) {
      if (showNotification) {
        showNotification('failed try again', false);
      }
      return rejectWithValue(err);
    }
  }
);
export const configIntegration = createAsyncThunk(
  'selfserve/configIntegration',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await configIntegrationApi(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deletePhoneNumberApi = createAsyncThunk(
  'selfserve/deletePhoneNumber',
  async ({ payload, callTenenantApi }, { rejectWithValue }) => {
    try {
      const response = await deletePhoneNumber(payload);
      if (response) {
        callTenenantApi();
      }
      return response;
    } catch (err) {
      callTenenantApi();
      return rejectWithValue(err);
    }
  }
);

export const updateClientList = payload => ({
  type: `updateClientList`,
  payload
});
