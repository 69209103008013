import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React from 'react';
import SummaryTable from '../SummaryTable';

const CustomGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  width: '1320px',
  height: '240px',
  borderRadius: '8px'
}));
const CardItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  width: '440px',
  height: '410px',
  borderRadius: '8px'
}));

export default function Analytics() {
  return (
    <>
      <CustomPaper sx={{ flexGrow: 1 }}>
        <CustomGrid container flexDirection="column">
          <Grid item xs={2}>
            Mix panel graphs
          </Grid>
        </CustomGrid>
      </CustomPaper>
      <br />
      <Box
        sx={{
          flexGrow: 1
        }}
      >
        <Grid container spacing={2} columns={24}>
          <Grid item xs={8}>
            <SummaryTable type="agents" />
          </Grid>
          <Grid item xs={8}>
            <CardItem>
              <SummaryTable type="clients" />
            </CardItem>
          </Grid>
          <Grid item xs={8}>
            <CardItem></CardItem>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
