import React from 'react';
import InboxIcon from '../../Media/Sidebar/Inbox';
import LeadsIcon from '../../Media/Sidebar/Leads';
import CommunityIcon from '../../Media/Sidebar/Community';
import SettingsIcon from '../../Media/Sidebar/Settings';
import WorkflowIcon from '../../Media/Sidebar/Workflow';
import DashboardIcon from '../../Media/Sidebar/Dashboard';
import MessageBuilder from '../../Media/Sidebar/MessageBuilder';
import ToxicityIcon from '../../Media/Sidebar/ToxicityIcon';
import QaIcon from '../../Media/Sidebar/QaIcon';
import UsersIcon from '../../Media/Sidebar/UsersIcon';
import KnowledgeBaseIcon from '../../Media/Sidebar/KnowledgeBaseIcon';

export const SIDENAV_MENUS = {
  INBOX: {
    name: 'Inbox',
    logo: <InboxIcon />,
    route: '/',
    auth: ['ADMIN', 'AGENT'],
    access: ['/inbox', '/']
  },
  QA: {
    name: 'QA',
    logo: <QaIcon />,
    auth: ['ADMIN', 'AGENT'],
    newLink: 'qa',
    access: ['/qa']
  },
  LEADS: {
    name: 'Leads',
    logo: <LeadsIcon />,
    route: '/leads',
    auth: ['ADMIN', 'AGENT'],
    permissions: 'leads',
    access: ['/leads']
  },
  TOXICITY: {
    name: 'Toxicity',
    logo: <ToxicityIcon />,
    route: '/toxicity',
    auth: ['ADMIN'],
    access: ['/toxicity']
  },
  COMMUNITIES: {
    name: 'Community',
    logo: <CommunityIcon />,
    route: '/communities',
    auth: ['ADMIN'],
    access: ['/communities', '/community', '/knowledge-base', '/call-to-text']
  },
  USERS: {
    name: 'Users',
    logo: <UsersIcon />,
    auth: ['ADMIN', 'AGENT'],
    newLink: 'users',
    newUrl: 'app-v2',
    access: ['/users']
  },
  NEW_WORKFLOW: {
    name: 'Workflow',
    logo: <WorkflowIcon />,
    route: '/new-workflow',
    auth: ['ADMIN'],
    access: ['/new-workflow']
  },
  WORKFLOW: {
    name: 'Message Builder',
    logo: <MessageBuilder />,
    route: '/workflow',
    auth: ['ADMIN'],
    access: ['/workflow']
  },
  SELF_SERVE: {
    name: 'Admin',
    logo: <SettingsIcon />,
    route: '/admin',
    auth: ['ADMIN'],
    access: ['/admin']
  },
  KNOWLEDGE_BASE_UPDATE: {
    name: 'Knowledge Base',
    logo: <KnowledgeBaseIcon />,
    route: `/knowledge-base`,
    auth: ['CLIENT_ADMIN'],
    access: [`/knowledge-base`]
  },
  KNOWLEDGE_BASE_VIEW: {
    name: 'Knowledge Base',
    logo: <KnowledgeBaseIcon />,
    route: `/knowledge-base`,
    auth: ['CLIENT_USER'],
    access: [`/knowledge-base`]
  },
  USER_PROFILE: {
    name: 'Agent Profile',
    logo: '',
    route: '/agentProfile',
    auth: ['ADMIN', 'AGENT'],
    access: ['/agentProfile']
  }
};

export const SIDENAV_MENUS_SUPERADMIN = [
  {
    name: 'Dashboard',
    logo: <DashboardIcon />,
    route: '/dashboard',
    auth: ['ADMIN', 'AGENT']
  },
  {
    name: 'Leads',
    logo: <LeadsIcon />,
    route: '/leads',
    auth: ['ADMIN']
  },
  {
    name: 'Workflow',
    logo: <WorkflowIcon />,
    route: '/new-workflow',
    auth: ['ADMIN']
  },
  {
    name: 'Users',
    logo: <UsersIcon />,
    newLink: 'users',
    auth: ['ADMIN']
  },
  {
    name: 'Toxicity',
    logo: <ToxicityIcon />,
    route: '/toxicity',
    auth: ['ADMIN']
  },
  {
    name: 'Admin',
    logo: <SettingsIcon />,
    route: '/admin',
    auth: ['ADMIN']
  }
];
