import { io } from 'socket.io-client';
import { websocketUrl } from '../serverLinks';

export const socket = io(websocketUrl, {
  transports: ['websocket']
});

//Removing this temporarily
// socket.onAny((event, args) => {
//   datadogLogs.logger.info(event, { args });
// });

export const socketConnection = async user => {
  socket.auth = { username: user?.user_id };
  socket.connect();
};

export const socketEmitter = (eventName, args) => socket.emit(eventName, args);

export const socketListener = (eventName, args) => socket.on(eventName, args);

export const socketRemover = (eventName, args) => {
  console.log('Removing listener!');
  socket.off(eventName, args);
};

export const socketRemoveAll = () => socket.removeAllListeners();
