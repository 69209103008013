import { GET_ALL_UTILITY } from '../Constant';
import { get } from '../networkClient';

const getAllUtility = data => ({
  type: GET_ALL_UTILITY,
  data: data
});

export const getAllUtilityAction = () => async dispatch => {
  await get('/utilities/all').then(resp => {
    if (resp.status === 200) {
      dispatch(getAllUtility(resp.data));
    }
  });
};
