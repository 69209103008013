import React from 'react';
import Layout from '../Component/layout';
const NoAccess = () => {
  return (
    <>
      <Layout />
      <h1 style={{ position: 'absolute', left: '100px' }}>Sorry! No access.</h1>
    </>
  );
};

export default NoAccess;
