import styled, { keyframes } from 'styled-components';
const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  color: #fff;
  border-radius: 6px;
  max-height: calc(100% - 97px);
  tr {
    vertical-align: baseline;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #2f2f2f;
  height: 100%;
`;

const Column = styled.div`
  text-align: left;
  background: #2d2c35;
  width: 320px;

  &.pinned {
    position: sticky;
    left: 0;
    border-radius: 6px;
    background: #2d2c35;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
    z-index: 202;
  }

  &.normal {
  }
`;

const ColumnHeader = styled.div`
  background: #f6f8fa;
  padding: 10px 20px;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 201;
  ${({ isPinned }) =>
    isPinned &&
    `
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.25);
  `}
`;
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
`;

const StyledTableHead = styled.thead`
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledTableRow = styled.tr`
  cursor: pointer;
  position: relative;
  background-color: ${({ isSelected, isSelectable }) =>
    isSelected && isSelectable ? 'rgba(237, 239, 255, 1)' : 'auto'};
  &:hover {
    background-color: #f9f9f9;
  }
`;
const TableRowContainer = styled.div`
  position: relative;
`;

const TableTooltip = styled.div`
  position: absolute;
  background-color: #707070;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  pointer-events: none;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
`;

const StyledTableHeaderCell = styled.th`
  padding: 8px;
  text-align: left;
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};
  border-top-left-radius: ${props => (props.firstColumn ? '4px' : '0px')};
  border-top-right-radius: ${props => (props.lastColumn ? '4px' : '0px')};
  z-index: 1;
  min-width: ${props => props.minWidth ?? 'auto'};
  color: #42526e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.sorted-asc {
    background-color: #cfd8dc;
  }

  &.sorted-desc {
    background-color: #cfd8dc;
  }
`;

const StyledTableCell = styled.td`
  padding: 8px;
  text-align: left;
  color: ${({ isFaded }) => (isFaded ? 'rgba(57, 57, 57, 0.50);' : '#393939')};
  font-family: Inter;
  font-size: ${({ cellSize }) => (cellSize ? cellSize : '14px')};
  font-style: normal;
  font-weight: ${({ cellWeight }) => (cellWeight ? cellWeight : '500')};
  line-height: normal;
  vertical-align: baseline;
`;

const ClickableHeaderLinks = styled.div`
  color: #5267ff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(237, 239, 255, 0.8);
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled.button`
  background: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1;
  position: sticky;
  right: 10px;
`;
const ActionsContainer = styled.div`
  display: flex,
  alignItems: center,
  justifyContent: flex-end,
  paddingRight: 20px,
  gap: 10px
  `;
const FlexAlignItemsCenter = styled.div`
  display: flex;
  align-items: center;
`;
const WidthMaxContent = styled.div`
  width: max-content;
`;
export {
  TableContainer,
  ContentContainer,
  Column,
  ColumnHeader,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableHeaderCell,
  StyledTableCell,
  ClickableHeaderLinks,
  HoverOverlay,
  ActionButton,
  ActionsContainer,
  FlexAlignItemsCenter,
  WidthMaxContent,
  TableTooltip,
  TableRowContainer
};
