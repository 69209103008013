import { Autocomplete, FormControl, TextField } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateContextData,
  updateSessionData
} from '../../../../Redux/Actions/InboxV1';
import Form from '../../Form';
import Input from '../../Input';
import { Label } from '../../Input/input.styles';
import moment from 'moment';

const TourDetailsForm = ({ onClose, sessionData, leadData }) => {
  const dispatch = useDispatch();
  const tourData = {
    date: sessionData?.slots?.date,
    time: sessionData?.slots?.time,
    preferred_tour_type: sessionData?.preferred_tour_type
  };

  const [currData, setCurrData] = useState(tourData);
  const [tempTime, setTempTime] = useState(null);
  const [availableTourTypes, setAvailableTourTypes] = useState([]);
  const [selectedTourDate, setSelectedTourDate] = useState(null);
  const [isDateUpdated, setIsDateUpdated] = useState(false);
  const [isTimeUpdated, setIsTimeUpdated] = useState(false);

  const [updatedData, setUpdatedData] = useState({
    lead_id: leadData?._id,
    apartment_id: leadData?.apartment?._id,
    updated_by: 'portal',
    time: tourData.time
  });

  useEffect(() => {
    if (updatedData.time !== null) {
      const hhMMss = updatedData.time.split(':');
      const t = new Date();
      t.setHours(hhMMss[0]);
      t.setMinutes(hhMMss[1]);
      t.setSeconds(hhMMss[2]);
      setTempTime(t);
    } else {
      if (isTimeUpdated) {
        setTempTime(null);
      }
    }
  }, [updatedData]);

  const handleChange = (key, value) => {
    setUpdatedData(prevData => ({ ...prevData, [`${key}`]: value }));
    if (key === 'date') setIsDateUpdated(true);
    if (key === 'time') setIsTimeUpdated(true);
  };

  const getTourTypeObj = tour => {
    if (tour === 'inperson') {
      return {
        id: 'inperson',
        name: 'In-person'
      };
    } else if (tour === 'virtual') {
      return {
        id: 'virtual',
        name: 'Virtual'
      };
    } else if (tour === 'selfguided') {
      return {
        id: 'selfguided',
        name: 'Self-guided'
      };
    } else {
      return {
        id: 'null',
        name: 'Null'
      };
    }
  };

  useEffect(() => {
    const tourTypes = sessionData?.tour_options?.map(tour =>
      getTourTypeObj(tour)
    );
    tourTypes.push({ id: 'null', name: 'Null' });
    setAvailableTourTypes(tourTypes);
  }, [sessionData]);

  const createDateString = date => {
    if (!date) return null;

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let resultDate = `${year}-${month}-${day}`;
    return resultDate;
  };

  const createTimeString = date => {
    if (!date) return null;

    const objDate = new Date(date);

    let hours = objDate.getHours();
    let mins = objDate.getMinutes();
    let secs = objDate.getSeconds();

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    if (secs < 10) {
      secs = '0' + secs;
    }
    return `${hours}:${mins}:${secs}`;
  };

  const preferredTourDateChangeHandler = val => {
    setSelectedTourDate(val);
    handleChange('date', createDateString(val));
  };

  return (
    <Form
      header="Tour Details"
      onSave={() => {
        if ('preferred_tour_type' in updatedData) {
          dispatch(
            updateContextData({
              data: {
                lead_id: leadData?._id,
                apartment_id: leadData?.apartment?._id,
                updated_by: 'portal',
                context: 'Tour',
                preferred_tour_type: updatedData?.preferred_tour_type
              }
            })
          );
        }
        dispatch(updateSessionData({ data: updatedData }));
        onClose();
      }}
      onClose={onClose}
    >
      <Input
        size="xs"
        onDateChange={preferredTourDateChangeHandler}
        value={
          isDateUpdated
            ? updatedData?.date !== null
              ? selectedTourDate
              : null
            : currData?.date !== null
            ? new Date(moment(currData?.date).utc().format())
            : null
        }
        label="Date"
        date
        prepend
        width="100%"
      />
      <Input
        size="xs"
        onDateChange={val => {
          handleChange('time', createTimeString(val));
        }}
        value={tempTime}
        label="Time"
        time
        width="100%"
        prepend
      />
      <div style={{ zIndex: 0 }}>
        <Label size="xs">Tour type</Label>
        <FormControl className="custom-menu-dropdown" fullWidth focused>
          <Autocomplete
            value={
              (updatedData?.preferred_tour_type &&
                getTourTypeObj(updatedData.preferred_tour_type)) ||
              getTourTypeObj(tourData?.preferred_tour_type)
            }
            disabled={false}
            disableClearable
            disablePortal
            placeholder="Select tour type"
            options={availableTourTypes}
            className="custom-autocomplete"
            getOptionLabel={option => option.name}
            onChange={(e, val) => {
              handleChange('preferred_tour_type', val.id);
            }}
            key={uniqueId}
            renderInput={params => (
              <TextField
                {...params}
                className="custom-property-dropdown"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': {
                      border: 'none'
                    }
                  }
                }}
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </div>
    </Form>
  );
};

export default TourDetailsForm;
