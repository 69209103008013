import { gql } from '@apollo/client';

export const deleteMessage = gql`
  mutation deleteMessage(
    $messageId: ID!
    $deletedBy: DeletedByInput!
    $deletedAt: Date!
  ) {
    deleteMessage(
      messageId: $messageId
      deletedBy: $deletedBy
      deletedAt: $deletedAt
    ) {
      _id
      eventType
      scheduledData
    }
  }
`;
