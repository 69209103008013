import React from 'react';
import { AvatarContainer, AvatarImage } from './avatar.styles';

const Avatar = ({ size, clickable = false, onClick, image }) => {
  return (
    <AvatarContainer size={size} clickable={clickable} onClick={onClick}>
      <AvatarImage src={image} alt="avatar"></AvatarImage>
    </AvatarContainer>
  );
};

export default Avatar;
