import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  DeleteInfo,
  DeletePopup
} from '../../../../Component/LeadWaitList/LeadWaitList.styles';
import Button from '../../../../globalcomponents/Button';
import PopupBody from '../../../../globalcomponents/PopupComponents/PopupBody';
import PopupFooter from '../../../../globalcomponents/PopupComponents/PopupFooter';
import { deleteWaitlist } from '../../../../gql/mutations/waitList';
import { LeadInfoById } from '../../../../gql/queries/leadInfo';
import Error from '../../../../Media/InboxV1/Error';
import DeleteSvg from '../../../property/CallToTextV1/Delete';

const WaitListDeletePopup = ({
  leadId,
  existingDate,
  handleCancelDelete,
  handleEmptyWaitlistData
}) => {
  const [isItemDeleted, setIsItemDeleted] = useState(false);
  const [deleteItem] = useMutation(deleteWaitlist, {
    onCompleted() {
      setIsItemDeleted(true);
      handleEmptyWaitlistData();
    },
    refetchQueries: [{ query: LeadInfoById, variables: { leadId } }]
  });
  return (
    <DeletePopup>
      <PopupBody>
        {isItemDeleted ? (
          <DeleteInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <img src="/deleteWaitConfirm.png" />
            </div>
            <div> Waitlist Deleted!</div>
          </DeleteInfo>
        ) : (
          <DeleteInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <img src="/DeleteWait.svg" />
            </div>
            <div>Waitlist will be deleted. Are you sure?</div>
          </DeleteInfo>
        )}
      </PopupBody>
      {isItemDeleted ? null : (
        <PopupFooter justifyContent="center">
          <Button onClick={handleCancelDelete}>No</Button>
          <Button
            onClick={() =>
              deleteItem({
                variables: {
                  _id: leadId,
                  existingDate
                }
              })
            }
            noStyle={true}
          >
            Delete
          </Button>
        </PopupFooter>
      )}
    </DeletePopup>
  );
};

export default WaitListDeletePopup;
