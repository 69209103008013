import { Alert, Button, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callToTextUpdate } from '../../../Redux/Actions/Apartment';
import SavedField from './SavedField';

const CallTextBox = ({ callData = {}, updatedCallData, setUpdateCallData }) => {
  const [editable, setEditable] = useState(false);
  const [existingLeadType, setExistingLeadType] = useState(
    callData?.existingType || 'NA'
  );
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState({});

  const dispatch = useDispatch();
  const handleSave = () => {
    if (updatedCallData?.slots) {
      delete updatedCallData.slots;
    }
    const updatedData = {
      ...updatedCallData,
      existingType: existingLeadType === 'NA' ? null : existingLeadType,
      id: callData._id
    };
    dispatch(callToTextUpdate(updatedData));
    setSnackBarOpen(true);
    setSnackbarMessage({
      message: 'Details updated successfully.',
      type: 'success'
    });
    setEditable(false);
  };

  useEffect(() => {
    if (callData?.existingLeadType) {
      setExistingLeadType(callData?.existingLeadType);
    }
  }, [callData?.existingType]);
  return (
    <>
      {snackBarOpen && (
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setSnackBarOpen(false);
          }}
          action={'Success'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={snackBarMessage.type}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className="call-box">
        <div className="heading">
          <span>
            {editable ? (
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  style={{
                    background: '#5267FF',
                    marginRight: '10px',
                    textTransform: 'capitalize'
                  }}
                  size="small"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  style={{
                    textTransform: 'capitalize'
                  }}
                  onClick={() => setEditable(false)}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                variant="text"
                color="inherit"
                size="small"
                style={{
                  textTransform: 'capitalize'
                }}
                onClick={() => setEditable(true)}
              >
                Edit
              </Button>
            )}
          </span>
        </div>
        <div
          className="call-inputs"
          style={editable ? {} : { pointerEvents: 'none' }}
        >
          <SavedField
            key={'Vanity Number'}
            type={'select'}
            {...{
              editable,
              label: 'Vanity Number',
              updatedCallData,
              setUpdateCallData
            }}
            // zumaIvrPhone
            value={
              updatedCallData?.phone !== undefined
                ? updatedCallData?.phone
                : callData?.phone
            }
            id={callData._id}
            keyName="phone"
          />
          <SavedField
            type={'audio'}
            key={'Textable Number'}
            {...{
              editable,
              label: 'Textable Number',
              updatedCallData,
              setUpdateCallData
            }}
            value={
              updatedCallData?.textableVoiceUrl !== undefined
                ? updatedCallData?.textableVoiceUrl
                : callData?.textableVoiceUrl
            }
            keyName={'textableVoiceName'}
            keyUrl={'textableVoiceUrl'}
            keyVoiceId={'textableVoiceId'}
            fileName={
              updatedCallData?.textableVoiceName !== undefined
                ? updatedCallData?.textableVoiceName
                : callData?.textableVoiceName
            }
          />

          <SavedField
            type={existingLeadType}
            key={'Existing Number'}
            {...{
              editable,
              label: 'Existing Number',
              updatedCallData,
              setUpdateCallData
            }}
            value={
              existingLeadType === 'number'
                ? updatedCallData?.existingForwardingNumber !== undefined
                  ? updatedCallData?.existingForwardingNumber
                  : callData?.existingForwardingNumber
                : updatedCallData?.existingVoiceUrl !== undefined
                ? updatedCallData?.existingVoiceUrl
                : callData?.existingVoiceUrl
            }
            keyName={
              existingLeadType === 'number'
                ? 'existingForwardingNumber'
                : 'existingVoiceName'
            }
            keyUrl={'existingVoiceUrl'}
            keyVoiceId={'existingVoiceId'}
            showSelectType
            setExistingLeadType={setExistingLeadType}
            fileName={
              updatedCallData?.existingVoiceName !== undefined
                ? updatedCallData?.existingVoiceName
                : callData?.existingVoiceName
            }
            style={{ background: '#fff', justifyContent: 'end' }}
          />

          <SavedField
            type={'audio'}
            key={'Non-Textable Number'}
            {...{
              editable,
              label: 'Non-Textable Number',
              updatedCallData,
              setUpdateCallData
            }}
            value={
              updatedCallData?.nonTextableVoiceUrl !== undefined
                ? updatedCallData?.nonTextableVoiceUrl
                : callData?.nonTextableVoiceUrl
            }
            keyName={'nonTextableVoiceName'}
            keyUrl={'nonTextableVoiceUrl'}
            keyVoiceId={'nonTextableVoiceId'}
            fileName={
              updatedCallData?.nonTextableVoiceName !== undefined
                ? updatedCallData?.nonTextableVoiceName
                : callData?.nonTextableVoiceName
            }
          />
        </div>
      </div>
    </>
  );
};

export default CallTextBox;
