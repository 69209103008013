import React from 'react';

const NAIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 10.9474C4.04779 10.9474 2.05263 8.95221 2.05263 6.5C2.05263 5.66526 2.28321 4.86405 2.72316 4.15932L8.84068 10.2768C8.13595 10.7168 7.33474 10.9474 6.5 10.9474ZM4.17847 2.71153C4.87842 2.2791 5.67347 2.05263 6.5 2.05263C8.95221 2.05263 10.9474 4.04779 10.9474 6.5C10.9474 7.32653 10.7209 8.12158 10.2885 8.82153L4.17847 2.71153ZM6.5 0C2.91611 0 0 2.91611 0 6.5C0 10.0839 2.91611 13 6.5 13C10.0839 13 13 10.0839 13 6.5C13 2.91611 10.0839 0 6.5 0Z"
        fill="#42526E"
      />
      <path
        d="M0.342105 6.5C0.342105 9.90053 3.09947 12.6579 6.5 12.6579C9.90053 12.6579 12.6579 9.90053 12.6579 6.5C12.6579 3.09879 9.90053 0.342105 6.5 0.342105C3.09947 0.342105 0.342105 3.09879 0.342105 6.5ZM3.64411 2.66158C4.44258 2.067 5.42853 1.71053 6.5 1.71053C9.14516 1.71053 11.2895 3.85484 11.2895 6.5C11.2895 7.57216 10.9337 8.5581 10.3384 9.35589L3.64411 2.66158ZM1.71053 6.5C1.71053 5.42032 2.07247 4.42753 2.67458 3.627L9.373 10.3254C8.57179 10.9282 7.579 11.2895 6.5 11.2895C3.85484 11.2895 1.71053 9.14516 1.71053 6.5Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default NAIcon;
