import { createSlice } from '@reduxjs/toolkit';
import { fetchAvailableNumbers, uploadFile } from '../Actions/CallToText';

const initialState = {
  isNumberLoading: false,
  availableNumbers: [],
  uploadingFile: false,
  uploadingFileField: null,
  uploadedFileData: {}
};

const callTextSlice = createSlice({
  name: 'callText',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAvailableNumbers.pending]: state => {
      state.isNumberLoading = true;
    },
    [fetchAvailableNumbers.fulfilled]: (state, action) => {
      state.isNumberLoading = false;
      if (action?.payload?.data?.data?.data) {
        state.availableNumbers = [...action.payload.data.data.data];
      }
    },
    [uploadFile.pending]: (state, action) => {
      state.uploadingFile = true;
      state.uploadingFileField = action.meta.arg.type;
    },
    [uploadFile.fulfilled]: (state, action) => {
      state.uploadingFile = false;
      if (action?.payload) {
        state.uploadedFileData = { ...action?.payload };
      }
    }
  }
});

export default callTextSlice;
