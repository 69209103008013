import styled from 'styled-components';

const SliderSuccessContaniner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ActionsHeader = styled.p`
  color: ${({ theme }) => theme?.text?.label};
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
`;

const ActionsList = styled.ul`
  padding-left: 15px;
  color: ${({ theme }) => `${theme?.text?.label}80`};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

const ActionItem = styled.span`
  color: ${({ theme }) => theme?.text?.primary};
`;

export { SliderSuccessContaniner, ActionsHeader, ActionsList, ActionItem };
