import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Grid from './Grid';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../Component/Modal';
import SettingsIcon from '../../../Media/appartment.svg';
import styles from './styles.module.scss';

const OnLive = ({
  removeOnLive,
  addOnLiveData,
  handleRemoveLive,
  appartmentData,
  open,
  closePop,
  onLiveSettings
}) => {
  const [searchVal, setSearchVal] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const appartmentDataLive =
    appartmentData && appartmentData.length
      ? appartmentData.filter(item => item.isLive && item.integrations)
      : [];
  const handleChange = val => {
    setSearchVal(val);
    setFilteredData(prev => {
      const searchTerm = val.toLowerCase();
      const filtered = appartmentDataLive.filter(item =>
        item?.name?.toLowerCase().startsWith(searchTerm)
      );
      return filtered || [];
    });
  };

  const filteredLive = searchVal?.length ? filteredData : appartmentDataLive;
  const closeNotification = () => {
    closePop();
  };

  const handleRemoveData = () => {
    closePop();
    handleRemoveLive();
  };

  const count = appartmentData
    ?.map(item => {
      if (item?.integrations && item?.isLive) {
        return item;
      }
    })
    .filter(x => x != null).length;
  return (
    <Modal
      classes={'onLivePop'}
      disableEnforceFocus
      open={open}
      onClose={closePop}
    >
      <div className={styles.livePopupCont}>
        <h2 className={styles.pageHeader}>Live Communities</h2>
        <div className={styles.header}>
          <div className={styles.rightSectionHeader}>
            <TextField
              onChange={e => handleChange(e.target.value)}
              placeholder="Search anything"
              variant="outlined"
              value={searchVal}
              InputProps={{
                type: 'search',
                style: {
                  borderRadius: '4px',
                  padding: '4px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#a2a2a2' }} />
                  </InputAdornment>
                )
              }}
            />
            <p className={styles.textC}>Communities</p>
            <span className={styles.box}>
              <img src={SettingsIcon} alt="" />
              {count || 0}
            </span>
          </div>
          <button
            onClick={handleRemoveData}
            className={
              !onLiveSettings?.length
                ? `${styles.stdButton} ${styles.disableBtn}`
                : styles.stdButton
            }
          >
            Remove
          </button>
        </div>
        <div className={styles.itemContainer}>
          {filteredLive && filteredLive.length ? (
            filteredLive.map(item => {
              return (
                <Grid
                  tag={false}
                  key={item?._id}
                  apptId={item?._id}
                  header={item.name}
                  address={item.addressId}
                  timeLabel={item.createdAt}
                  applySettingsData={addOnLiveData}
                  removeOnboard={removeOnLive}
                />
              );
            })
          ) : (
            <p>No live communities available.</p>
          )}
        </div>

        <div className="close-icon">
          <CloseIcon onClick={closeNotification} />
        </div>
      </div>
    </Modal>
  );
};

export default OnLive;
