import * as React from 'react';
import Popover from '@mui/material/Popover';
import Smiley from '../../../Media/Inbox/Smiley';
import { IconButton } from '@mui/material';
import EmojiPicker from '../ChatOptions/EmojiPocker';

const ChatPopover = ({ handleChatEmojis, isInTemplate = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiClick = e => {
    handleChatEmojis(e.emoji, 'emoji');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ marginTop: isInTemplate ? '18px' : null }}>
      <IconButton
        style={{ padding: 0 }}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Smiley />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <EmojiPicker handleEmojiClick={handleEmojiClick} />
      </Popover>
    </div>
  );
};

export default ChatPopover;
