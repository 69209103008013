import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CustomModal from '../../../Component/Modal';
import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import closedReasons from './closedReasonsList';
import { updateStage } from '../../../Redux/Actions/InboxV1';
import { Autocomplete, TextField } from '@mui/material';

const LostReason = props => {
  const { leadUpdateMutate } = props;
  const [closedName, setClosedName] = useState('');

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setClosedName(newValue);
  };

  const CancelThis = () => {
    props.setOpen(false);
  };

  const ContinueThis = () => {
    if (closedName) {
      dispatch(
        updateStage({
          stagePayload: {
            leadId: props.id,
            stage: 'Lost',
            closureReason: closedName
          }
        })
      );

      props.setOpen(false);
    } else {
      alert('please select a reason for closure');
    }
  };

  return (
    <div>
      <CustomModal
        classes={'zoom-transition lostPopup'}
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div>
                  <div className="customPopUpHeader">Select lost reason</div>
                  <div className="close-icon">
                    <CloseIcon onClick={() => CancelThis()} />
                  </div>
                </div>
                <div>
                  <FormControl
                    className="margin-btm-0"
                    sx={{ m: 1, minWidth: '21rem' }}
                  >
                    <Autocomplete
                      freeSolo
                      id="lost-reason-popup"
                      disableClearable
                      options={closedReasons}
                      value={closedName}
                      onChange={handleChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Select Reason"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: 'Select Reason',
                            style: {
                              borderRadius: '4px',
                              padding: '12px'
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div>
                  <Button
                    className={closedName ? 'save-btn' : 'disable-btn'}
                    onClick={() => ContinueThis()}
                  >
                    Save
                  </Button>
                  <Button
                    style={{ textTransform: 'capitalize' }}
                    onClick={() => CancelThis()}
                    className="clearBtn"
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </CustomModal>
    </div>
  );
};

export default LostReason;
