import styled from 'styled-components';

const CenterPoint = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 0 auto;
`;

const PathContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Path = styled.div`
  width: 1px;
  height: 50%;
  background-color: ${({ color }) => color};
  margin: 0 auto;
`;
export { CenterPoint, PathContainer, Path };
