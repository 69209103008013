import styled from 'styled-components';

const PillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 5rem;
  background-color: ${({ bgColor }) =>
    bgColor === 'default' ? `rgba(82, 103, 255, 0.1)}` : bgColor};
  padding: 10px;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`;

const PillText = styled.div`
  color: ${({ color }) => (color === 'default' ? '#5267FF' : color)};
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Inter, sans-serif;
  height: fit-content;
`;

export { PillContainer, PillText };
