import React from 'react';

const InfoIcon = ({ onClick, style, onMouseOver }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9987 16.6668C8.23059 16.6668 6.5349 15.9645 5.28465 14.7142C4.03441 13.464 3.33203 11.7683 3.33203 10.0002C3.33203 8.23205 4.03441 6.53636 5.28465 5.28612C6.5349 4.03588 8.23059 3.3335 9.9987 3.3335C11.7668 3.3335 13.4625 4.03588 14.7127 5.28612C15.963 6.53636 16.6654 8.23205 16.6654 10.0002C16.6654 11.7683 15.963 13.464 14.7127 14.7142C13.4625 15.9645 11.7668 16.6668 9.9987 16.6668ZM9.9987 9.5835C9.77768 9.5835 9.56572 9.67129 9.40944 9.82757C9.25316 9.98385 9.16536 10.1958 9.16536 10.4168V12.5002C9.16536 12.7212 9.25316 12.9331 9.40944 13.0894C9.56572 13.2457 9.77768 13.3335 9.9987 13.3335C10.2197 13.3335 10.4317 13.2457 10.588 13.0894C10.7442 12.9331 10.832 12.7212 10.832 12.5002V10.4168C10.832 10.1958 10.7442 9.98385 10.588 9.82757C10.4317 9.67129 10.2197 9.5835 9.9987 9.5835ZM9.9987 8.646C10.3026 8.646 10.594 8.52527 10.8089 8.31039C11.0238 8.0955 11.1445 7.80406 11.1445 7.50016C11.1445 7.19627 11.0238 6.90482 10.8089 6.68994C10.594 6.47505 10.3026 6.35433 9.9987 6.35433C9.6948 6.35433 9.40336 6.47505 9.18847 6.68994C8.97359 6.90482 8.85286 7.19627 8.85286 7.50016C8.85286 7.80406 8.97359 8.0955 9.18847 8.31039C9.40336 8.52527 9.6948 8.646 9.9987 8.646Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default InfoIcon;
