import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useLazyQuery } from '@apollo/client';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import GifIcon from '../../../Media/InboxV2/Gif';
import SmileyOptions from '../ChatInputBarCOmponents/SmileyOptions';
import ChatOptions from '../ChatOptions';
import { Dialog } from '@mui/material';
import Giphy from '../Giphy';
import GifModal from '../ChatComponents/GifModal';
import ArchiveContainer from '../ArchiveContainer';
import { sendLeadMessage } from '../../../Redux/Actions/InboxV1';
import { ToastFireAction } from '../../../Redux/Actions/Toast';
import { useDispatch } from 'react-redux';
import { getMessageTemplates } from '../../../gql/queries/messageTemplate';
import SmsEmailOption from '../SmsEmailOption.js/index.js';
import './chat-styles.scss';
import SendButton from '../../../Media/InboxV1/SendIcon';
import { setGlobalError } from '../../../Redux/Reducer/GlobalError';
import { parseTokensLowerCase } from '../../../utils/index';
import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';
import Icon from '../../../globalcomponents/Icon';
import { useTheme } from 'styled-components';
import { FormatButton, ReactQuillContainer } from '../styles.inboxv2';
import { SendButtonWrapper, VerticalDivider } from './styles.chatinputbar';
import ScheduleMessagePopup from './ScheduleMessagePopup';

const TextEditor = props => {
  const theme = useTheme();
  const [openGif, setOpenGif] = useState(false);
  const [gifImage, setGifImage] = useState([]);
  const [gifMessageOpen, setGifMessageOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [showScheduleMessage, setShowScheduleMessage] = useState(false);
  const {
    custStyle,
    selectedLeadId,
    messageInput,
    setMessageInput,
    isArchived,
    onClick,
    preference,
    apartmentId,
    leadUpdateMutate,
    archiveClick,
    setArchiveClick,
    userId,
    updatedLeadId,
    messageSent,
    handleOutgoingMessage,
    newNoteInsertionInProgress,
    handleNoteCreation,
    handleCallLogCreation,
    textOptIn,
    emailOptIn,
    leadTenantId,
    isBotActive,
    tenantId,
    medium,
    setMedium,
    updateCheatSheetData,
    setOpenMessageSendingLoader,
    appear,
    setAppear,
    setMessageStatus,
    smsEmailOption,
    handleOutgoingScheduledMessage,
    apartmentTimezone
  } = props;

  const dispatch = useDispatch();
  const ref = useRef();
  const {
    getTemplateRecommendation,
    resetTemplateRecommendation,
    messageSentTemplateEvent,
    selectTemplateRecommendation,
    fetchScheduledMessages
  } = useInboxContext();

  const handleSelectEmoji = event => {
    setAnchorEl(event.currentTarget);
  };
  const [
    fetchMessageTemplates,
    { data: messageTemplateData, loading: templatesLoading }
  ] = useLazyQuery(getMessageTemplates, {
    onError: () => {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });

  const handleTemplateData = () => {
    fetchMessageTemplates({
      variables: {
        filters: {
          clients: []
        }
      }
    });
    setOpenTemplate(true);
  };

  const handleOpenGifSelector = event => {
    setOpenGif(true);
  };

  const onGifClick = (gif, e) => {
    e.preventDefault();
    setGifImage([gif.images.original.url]);
    setGifMessageOpen(true);
    setOpenGif(false);
  };

  const handleSubmitGif = message => {
    const messageDetails = {
      leadId: selectedLeadId,
      md: 'mms',
      msg: message,
      media: gifImage
    };
    if (message) {
      dispatch(sendLeadMessage({ messageDetails }));
    }
  };

  const handleGifModalClose = e => {
    e.preventDefault();
    setGifMessageOpen(false);
  };

  const getEditorText = () => {
    if (ref.current) {
      const text = ref.current.getEditor().getText();
      return text;
    }
    return '';
  };

  const handleChatEmojis = emoji => {
    setMessageInput(prevState => prevState + emoji);
  };

  const handleChange = (html, delta) => {
    setMessageInput(html);
    if (getEditorText().length <= 2) {
      resetTemplateRecommendation();
    }
  };

  const [Editor, setEditor] = useState({
    modules: {
      toolbar: {
        container: '#custom-toolbar',
        handlers: {
          gif: handleOpenGifSelector,
          templates: handleTemplateData
        }
      }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    propTypes: {
      placeholder: 'Type here ..'
    },
    formats: [
      'bold',
      'italic',
      'underline',
      'list',
      'link',
      'bullet',
      'indent',
      'header'
    ]
  });

  const sendNewMessage = e => {
    e.preventDefault();
    let messageDetails;
    const currentText = getEditorText();

    if (medium === 'sms') {
      if (!textOptIn) {
        dispatch(setGlobalError('Message not sent - SMS Opted Out'));
      } else {
        messageDetails = {
          body: {
            messageType: 'outgoing',
            leadId: selectedLeadId,
            md: medium,
            msg: currentText
          },
          user: {
            sub: userId,
            tenantId: tenantId
          }
        };
        messageSentTemplateEvent({
          messageSent: currentText,
          tenantId: leadTenantId,
          leadId: selectedLeadId
        });
        updateCheatSheetData(currentText);
        handleOutgoingMessage(messageDetails);
        setAppear(true);
        setOpenMessageSendingLoader(true);
        setMessageInput('');
      }
    } else if (medium === 'email') {
      if (!emailOptIn) {
        dispatch(setGlobalError('Message not sent - Email Opted Out'));
      } else {
        messageDetails = {
          body: {
            messageType: 'outgoing',
            leadId: selectedLeadId,
            md: medium,
            msg: messageInput
          },
          user: {
            sub: userId,
            tenantId: tenantId
          }
        };
      }
      messageSentTemplateEvent({
        messageSent: currentText,
        tenantId: leadTenantId,
        leadId: selectedLeadId
      });
      updateCheatSheetData(currentText);
      handleOutgoingMessage(messageDetails);
      setAppear(true);
      setOpenMessageSendingLoader(true);
      setMessageInput('');
    }
  };
  const sendNewScheduledMessage = scheduledAt => {
    let messageDetails;
    const currentText = getEditorText();

    if (medium === 'sms') {
      if (!textOptIn) {
        // dispatch(setGlobalError('Message not sent - SMS Opted Out'));
      } else {
        messageDetails = {
          body: {
            messageType: 'outgoing',
            leadId: selectedLeadId,
            md: medium,
            msg: currentText,
            scheduledAt: scheduledAt,
            scheduledBy: userId
          },
          user: {
            sub: userId,
            tenantId: tenantId
          }
        };
        messageSentTemplateEvent({
          messageSent: currentText,
          tenantId: leadTenantId,
          leadId: selectedLeadId
        });
        updateCheatSheetData(currentText);
        handleOutgoingScheduledMessage(messageDetails);

        setAppear(true);
        setOpenMessageSendingLoader(true);
        setMessageInput('');
        setTimeout(() => {
          fetchScheduledMessages(selectedLeadId);
        }, 3000);
      }
    } else if (medium === 'email') {
      if (!emailOptIn) {
        dispatch(setGlobalError('Message not sent - Email Opted Out'));
      } else {
        messageDetails = {
          body: {
            messageType: 'outgoing',
            leadId: selectedLeadId,
            md: medium,
            msg: messageInput
          },
          user: {
            sub: userId,
            tenantId: tenantId
          }
        };
      }
      messageSentTemplateEvent({
        messageSent: currentText,
        tenantId: leadTenantId,
        leadId: selectedLeadId
      });
      updateCheatSheetData(currentText);
      handleOutgoingScheduledMessage(messageDetails);
      setAppear(true);
      setOpenMessageSendingLoader(true);
      setMessageInput('');
      fetchScheduledMessages(selectedLeadId);
    }
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.getEditor().root.offsetParent.style.height = '60px';
    }
  }, []);

  useEffect(() => {
    if (messageSent === 'failed') {
      setTimeout(() => setAppear(false), 3000);
    }
    setMessageStatus(false);
  }, [sendNewMessage, appear]);

  // Function to detect keydown
  const inputKeyPressed = e => {
    // Check if key pressed is space
    if (e.key === ' ') {
      // If space, check for the number of tokens
      // If the number of tokens is greater than 1, get recommendation
      const messageText = getEditorText();
      const tokensList = parseTokensLowerCase(messageText, ' ');
      if (tokensList.length >= 1) {
        getTemplateRecommendation({
          leadId: selectedLeadId,
          templateSearchQuery: messageText,
          tenantId: leadTenantId
        });
      }
    }
    // Check if key pressed is tab
    if (e.key === 'Tab') {
      e.preventDefault();
      selectTemplateRecommendation({
        setMessageInput
      });
    }
  };

  useEffect(() => {
    // Reset template recommendation
    resetTemplateRecommendation();
  }, [selectedLeadId]);

  return (
    <React.Fragment>
      <div>
        <div
          id="custom-toolbar"
          style={{ background: theme.background.secondary }}
        >
          <div className="toolbar-wrapper">
            <div>{smsEmailOption}</div>
            {medium === 'email' && <div className="section-separator" />}
            <div
              className="formatting-controls"
              style={{
                width: `${medium === 'email' ? '230px' : 0}`
              }}
            >
              {/* <button id="tl-btn__format" className="ql-bold" />
            <button id="tl-btn__format" className="ql-italic" />
            <button id="tl-btn__format" className="ql-underline" />
            <button id="tl-btn__format" className="ql-link" />
            <button id="tl-btn__format" className="ql-list" value="ordered" />
            <button id="tl-btn__format" className="ql-list" value="bullet" /> */}
              <FormatButton id="tl-btn__format" className="ql-bold" />
              <FormatButton id="tl-btn__format" className="ql-italic" />
              <FormatButton id="tl-btn__format" className="ql-underline" />
              <FormatButton id="tl-btn__format" className="ql-link" />
              <FormatButton
                id="tl-btn__format"
                className="ql-list"
                value="ordered"
              />
              <FormatButton
                id="tl-btn__format"
                className="ql-list"
                value="bullet"
              />
            </div>
            <div className="section-separator" />
            <div>
              <button id="tl-btn__input">
                {theme.state === 'light' ? (
                  <Icon src="smiley.svg" onClick={handleSelectEmoji} />
                ) : (
                  <Icon src="smiley-dark.svg" onClick={handleSelectEmoji} />
                )}

                <SmileyOptions
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  handleChatEmojis={handleChatEmojis}
                />
              </button>
              <button id="tl-btn__input">
                {theme.state === 'light' ? (
                  <Icon src="file.svg" onClick={handleTemplateData} />
                ) : (
                  <Icon src="file-dark.svg" onClick={handleTemplateData} />
                )}

                <ChatOptions
                  loading={templatesLoading}
                  open={openTemplate}
                  setOpen={setOpenTemplate}
                  setMessageInput={setMessageInput}
                  selectedLeadId={selectedLeadId}
                  templateData={messageTemplateData?.getMessageTemplates || []}
                />
              </button>
              <button className="ql-gif" id="tl-btn__input">
                {theme.state === 'light' ? (
                  <GifIcon onClick={handleOpenGifSelector} />
                ) : (
                  <Icon src="gif-dark.svg" onClick={handleOpenGifSelector} />
                )}

                <Dialog
                  onClose={e => {
                    e.preventDefault();
                    setOpenGif(false);
                  }}
                  open={openGif}
                >
                  <Giphy onGifClick={onGifClick} />
                </Dialog>
                <GifModal
                  open={gifMessageOpen}
                  handleGifModalClose={handleGifModalClose}
                  image={gifImage}
                  handleSubmitGif={handleSubmitGif}
                />
              </button>
            </div>
          </div>
          <div>
            <ArchiveContainer
              {...{
                isArchived,
                selectedLeadId,
                onClick,
                updatedLeadId,
                leadUpdateMutate,
                archiveClick,
                setArchiveClick,
                newNoteInsertionInProgress,
                handleOutgoingMessage,
                handleNoteCreation,
                handleCallLogCreation,
                preference,
                apartmentId,
                tenantId,
                leadTenantId,
                userId,
                isBotActive
              }}
            />
          </div>
        </div>
      </div>

      <ReactQuillContainer>
        <ReactQuill
          ref={ref}
          className="text-editor"
          value={messageInput}
          readOnly={selectedLeadId ? false : true}
          onChange={handleChange}
          formats={Editor.formats}
          onKeyDown={inputKeyPressed}
          style={{ background: theme.background.secondary, ...custStyle }}
          modules={Editor.modules}
          placeholder={'Type here..'}
        />
        <div style={{ height: '100%' }}>
          <SendButtonWrapper>
            <Icon
              id="send-button"
              onClick={sendNewMessage}
              src={`send-button-dark.svg`}
              size="2"
              wrapperStyle={{
                cursor: 'pointer',
                transition: 'all 150ms ease-in-out',
                '&:hover': {
                  transform: 'scale(1.25)'
                }
              }}
            />
            <VerticalDivider />
            <Icon
              id="schedule-message-button"
              onClick={() => setShowScheduleMessage(true)}
              src={`schedule-message-button.svg`}
              size="24"
              wrapperStyle={{
                cursor: 'pointer',
                transition: 'all 150ms ease-in-out',
                '&:hover': {
                  transform: 'scale(1.25)'
                }
              }}
            />
          </SendButtonWrapper>

          <ScheduleMessagePopup
            show={showScheduleMessage}
            onClose={() => setShowScheduleMessage(false)}
            selectedLeadId={selectedLeadId}
            handleOutgoingScheduledMessage={handleOutgoingScheduledMessage}
            sendNewScheduledMessage={sendNewScheduledMessage}
            apartmentTimezone={apartmentTimezone}
          />
        </div>
      </ReactQuillContainer>
    </React.Fragment>
  );
};

export default TextEditor;
