import React from 'react';
import Icon from '../../../globalcomponents/Icon';
import {
  CreatedByContainer,
  NoteBody,
  NoteContainer,
  PrimaryInfo,
  ScheduledMessageComp,
  SecondaryInfoTitle
} from './styles.chatcomponents';
import { SenderInitial } from '../styles.inboxv2';
import SyncIcon from '../../../Media/InboxV1/SyncIcon';
import moment from 'moment';

const ScheduledMessageEvent = ({ props }) => {
  const compProps = props.props;
  const meta = compProps?.meta;
  const timeStamp = compProps?.timeStamp;
  const body = compProps?.body;
  const isDeleted = compProps?.isDeleted;
  const eventType = compProps?.eventType; //scheduled-message
  let scheduledAt = moment
    .tz(compProps?.scheduledAt, 'America/Los_Angeles')
    .format('MMM D, YYYY h:mm A z');
  let scheduledBy = compProps?.scheduledBy;
  let deletedBy = compProps?.deletedBy;
  let deletedAt = moment
    .tz(compProps?.deletedAt, 'America/Los_Angeles')
    .format('MMM D, YYYY h:mm A z');
  let notesBody = body;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ScheduledMessageComp isDeleted={isDeleted}>
        <NoteContainer>
          <NoteBody style={{ padding: '0', color: '#fff', fontWeight: 500 }}>
            {notesBody}
          </NoteBody>
        </NoteContainer>

        {!isDeleted ? (
          <PrimaryInfo>Scheduled @ {scheduledAt}</PrimaryInfo>
        ) : (
          <>
            <PrimaryInfo>
              Deleted by {deletedBy} @ {deletedAt}
            </PrimaryInfo>
            <PrimaryInfo>
              <SecondaryInfoTitle>
                Originally Scheduled By: &nbsp;
              </SecondaryInfoTitle>
              {scheduledBy} @ {scheduledAt}
            </PrimaryInfo>
          </>
        )}
      </ScheduledMessageComp>
      <CreatedByContainer>
        <div style={{ padding: '5px 0' }}>
          {moment(timeStamp).format('MMM DD, YYYY hh:mm a')}
        </div>
        {meta?.sync &&
        meta?.sync?.length &&
        meta?.sync[0].syncStatus === 'Success' ? (
          <div style={{ padding: '5px 0', display: 'flex' }}>
            <SyncIcon /> Sync'd w {meta?.sync[0].syncSource}
          </div>
        ) : null}
      </CreatedByContainer>
    </div>
  );
};

const ScheduledMessage = props => {
  const { type, customerName, meta } = props.props;
  return (
    <>
      <ScheduledMessageEvent props={props} />
      <SenderInitial>
        {type === 'incoming'
          ? customerName
            ? customerName[0]
            : 'C'
          : meta?.agentName
          ? meta?.agentName[0]
          : 'K'}
      </SenderInitial>
    </>
  );
};

export default ScheduledMessage;
