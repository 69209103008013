import React from 'react';
import Icon from '../../../globalcomponents/Icon';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from './styles.chatcomponentsv2';

const NewMessageButton = ({ onClick, show }) => {
  const theme = useTheme();

  return (
    <Button show={show} theme={theme} onClick={onClick}>
      <span>New Message</span>
      <Icon src="arrow-down-white.svg" alt="scroll-down-timeline" />
    </Button>
  );
};

NewMessageButton.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool
};

export default NewMessageButton;
