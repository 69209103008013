import styled from 'styled-components';

const CommentOptions = styled.div`
  display: flex;
  gap: 0.625rem;
  visibility: hidden;
`;

const CommentContainer = styled.li`
  height: min-content;
  padding: 0.625rem;
  margin: 10px 0;
  background-color: rgba(82, 103, 255, 0.1);
  border-radius: 0.5rem;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  list-style-type: ${({ hideBullets }) => (hideBullets ? 'none' : 'initial')};
  :first-child {
    margin-top: 0;
  }

  &:hover ${CommentOptions} {
    visibility: visible;
  }

  * {
    margin: 0;
  }
`;

const CommentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const CommentNum = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 15px;
  color: #5267ff;
  /* margin-bottom: 6px; */
`;

const CommentBody = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
  color: #393939;
`;

export {
  CommentContainer,
  CommentBody,
  CommentNum,
  CommentOptions,
  CommentTitle
};
