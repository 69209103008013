import React from 'react';
import { Container, Header, Topic } from './info-container-styles';
import Icon from '../../../../globalcomponents/Icon';
import { useTheme } from 'styled-components';

const InfoContainer = ({ children, tab, topic, secondary, onEdit }) => {
  const theme = useTheme();
  return (
    <Container>
      <Header secondary={false}>
        <Topic secondary={false}>{topic}</Topic>
        <Icon
          src={`edit-${theme.state}.svg`}
          onClick={() => onEdit && onEdit(tab)}
          tooltipText="Edit"
          size="16px"
        />
      </Header>
      {children}
    </Container>
  );
};

export default InfoContainer;
