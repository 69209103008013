// theme.js
const theme = {
  light: {
    state: 'light',
    background: {
      universal: '#F3F6FB',
      primary: '#393939',
      secondary: '#FFFFFF',
      gradient: '#E9EBFB',
      chatContainer: '#F8F8F8',
      selection: '#5267FF',
      primaryBadge: '#50BE77',
      hover: '#0000000d'
    },
    text: {
      primary: '#393939',
      secondary: '#FFFFFF',
      label: '#42526E',
      placeholder: '#C4C4C4',
      tag: '#5267FF',
      value: 'rgb(57, 57, 57)',
      heading: '#525F80'
    },
    button: {
      primary: '#5267FF',
      secondary: '#D9D9D9',
      primaryOP10: 'rgba(82, 103, 255, 0.10)'
    },
    border: {
      primary: '#C4C4C4',
      selection: '#5267FF',
      tertiary: '#5267FF'
    },
    size: {
      avatar: {
        sm: '20px',
        md: '24px',
        lg: '30px'
      }
    },
    backgroundColor: '#FFFFFF',
    textColor: '#333333',
    sideNavBackgroundColor:
      'linear-gradient(163deg, #242634 -75%, #11151B 75%)',
    defaultOutgoingMessage: '#5559DF',
    defaultIncomingMessage: '#FFF',
    leadCardBorderBackground:
      'linear-gradient(136deg, #E9EBFB -75%, rgba(233, 235, 251, 0.00) 75%)',
    leadCardBackground: '#FFF',
    leadCardSelected: '#E9EBFB'

    // Add other light mode variables here
  },
  dark: {
    state: 'dark',
    background: {
      universal: '#11141E',
      primary: '#03060E',
      secondary: '#1D202D',
      gradient: '#282B36',
      chatContainer: '#1B1D27',
      selection: '#FFFFFF',
      primaryBadge: '#50BE77',
      hover: '#ffffff1a'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#1D202D',
      label: '#FFFFFF',
      placeholder: '#C4C4C4',
      tag: '#FFFFFF',
      value: 'rgba(255, 255, 255, 0.70)',
      heading: '#FFFFFF'
    },
    button: {
      primary: '#5267FF',
      secondary: '#D9D9D9',
      primaryOP10: 'rgba(255, 255, 255, 0.10)'
    },
    border: {
      primary: '#C4C4C4',
      selection: '#1D202D',
      tertiary: '#c4c4c4'
    },
    size: {
      avatar: {
        sm: '20px',
        md: '24px',
        lg: '30px'
      }
    },
    sideNavBackgroundColor:
      'linear-gradient(129deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.00) 100%);',
    textColor: '#FFFFFF',
    defaultOutgoingMessage: '#5559DF',
    defaultIncomingMessage: '#FFF',
    leadCardBorderBackground:
      'linear-gradient(143deg, rgba(255, 255, 255, 0.44) -75%, rgba(255, 255, 255, 0.00) 75%)',
    leadCardBackground: `#1D202D`,
    leadCardSelected: '#282B36'

    // Add other dark mode variables here
  }
};

export default theme;
