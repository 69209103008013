import { GET_ALL_PET_POLICY } from '../Constant';

const initialState = {
  PetPolicyData: ' '
};
const PetPolicy = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PET_POLICY:
      return { ...state, PetPolicyData: action.data };
    default:
      return state;
  }
};

export default PetPolicy;
