import styled from 'styled-components';

const Loader = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30%;
  left: 35%;
`;

const SOPListContainer = styled.div`
  height: calc(100% - 100px);
  margin: 10px 0;
  overflow: scroll;
`;

const SOPCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BadgeHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SOPInstructions = styled.ul`
  padding: ${({ padding }) => padding ?? 0};
  margin: 0;
  margin-top: 10px;
  font-size: 12px;
  line-height: normal;
  color: ${({ theme }) => theme.text.primary};
  > li {
    margin: 4px 0;
    padding-left: 8px;
  }
`;

const AnnouncementsListContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 260px;
  overflow: scroll;
  position: relative;
  > div {
    position: relative;
    padding-bottom: 30px;
  }
`;

const SliderSuccessContaniner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const Timeline = styled.div`
  position: absolute;
  top: 10px;
  left: 5px;
  width: 0.5px;
  background-color: #c4c4c4;
  height: 100%;
`;

const TimelineMilestone = styled.div`
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  background-color: #c4c4c4;
`;

export {
  Loader,
  SOPListContainer,
  SOPCardHeader,
  BadgeHolder,
  SOPInstructions,
  AnnouncementsListContainer,
  SliderSuccessContaniner,
  Timeline,
  TimelineMilestone
};
