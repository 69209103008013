import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CustomCreatableSelect = ({
  options = [],
  name = '',
  value = '',
  handleSelect,
  placeholder = 'Select',
  width = '160px',
  isClearable = false
}) => {
  const handleChange = (newValue, actionMeta) => {
    console.log('newValue', newValue);
    console.log('value', value);
    const isAlreadySelected =
      value?.value !== null && value?.value === newValue?.value;

    if (isAlreadySelected) {
      handleSelect(name, null);
    } else {
      handleSelect(name, newValue);
    }
  };

  const customStyles = {
    container: () => ({
      width: width,
      position: 'relative'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      borderWidth: '0.5px',
      fontSize: '14px'
    }),
    menu: provided => ({
      ...provided,
      zIndex: 99 // Adjust the z-index value as needed
    })
  };

  return (
    <CreatableSelect
      isClearable={isClearable}
      styles={customStyles}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      name={name}
      value={value}
    />
  );
};

export default CustomCreatableSelect;
