import { GET_ALL_ACCESSIBILITY } from '../Constant';
import { get } from '../networkClient';

const getAllAccessibility = data => ({
  type: GET_ALL_ACCESSIBILITY,
  data: data
});

export const getAllAccessibilityAction = () => async dispatch => {
  await get('/accessibilities/all').then(resp => {
    if (resp.status === 200) {
      dispatch(getAllAccessibility(resp.data));
    }
  });
};
