import React from 'react';
import CallTextBox from './CallTextBox';
import IvrCall from './IvrCall';

const CallTextLayout = ({
  showZumaIvr,
  callData,
  updatedCallData,
  setUpdateCallData,
  enableKnockCRM,
  voiceMail,
  updateApartment,
  slotsEditable,
  setSlotsEditable
}) => {
  return (
    <div className="call-layout">
      {enableKnockCRM ? (
        <CallTextBox {...{ callData, updatedCallData, setUpdateCallData }} />
      ) : null}
      <IvrCall
        {...{
          showZumaIvr,
          updatedCallData,
          setUpdateCallData,
          callData,
          enableKnockCRM,
          voiceMail,
          updateApartment,
          slotsEditable,
          setSlotsEditable
        }}
      />
    </div>
  );
};

export default CallTextLayout;
