import styled, { css } from 'styled-components';

const TabsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  background: ${({ theme, background }) =>
    background ?? theme?.background?.universal};
  height: 100%;
  position: relative;
  padding: ${({ padding }) => padding ?? 0};
  border-radius: ${({ borderRadius }) => borderRadius ?? 0};
`;

const TabOption = styled.div`
  display: inline-flex;
  grid-column-start: auto;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  opacity: ${({ active, inactiveOpacity }) => (active ? '1' : inactiveOpacity)};

  ${({ active }) =>
    active &&
    css`
      &:after {
        content: '';
        position: absolute;
        background-color: transparent;
        height: 0px;
        bottom: ${({ indicatorPosition }) =>
          indicatorPosition === 'bottom' && '0px'};
        top: ${({ indicatorPosition }) => indicatorPosition === 'top' && '0px'};
        left: 0;
        width: 100%;
        border-bottom: ${({ theme, indicatorColor }) =>
          `4px solid ${indicatorColor ?? theme?.background?.selection}`};
        border-radius: ${({ indicatorPosition }) =>
          indicatorPosition === 'top' ? '0 0 4px 4px' : '4px 4px 0 0'};
      }
    `};
`;

const Text = styled.p`
  padding: ${({ padding }) => padding ?? 0};
  margin: ${({ margin }) => margin ?? '10px'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: ${({ lineHeight }) => lineHeight ?? '19px'};
  font-family: Inter, sans-serif;
  color: ${({ theme, color }) => color};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { TabsWrapper, TabOption, Text };
