import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import React, { useState } from 'react';

const giphyFetch = new GiphyFetch('jf0UmBojX6hS9OvLTPCHrHMeNbtRNxVR');

function Giphy({ onGifClick }) {
  const fetchGifs = offset => giphyFetch.search('@getzuma');

  return (
    <>
      <Grid
        onGifClick={onGifClick}
        fetchGifs={fetchGifs}
        width={600}
        columns={3}
        gutter={6}
      />
    </>
  );
}

export default Giphy;
