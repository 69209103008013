import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './styles.scss';

const Toaster = () => {
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState([]);

  const handleClick = message => () => {
    const updatedMessage = [...messageInfo];
    updatedMessage.push({ message: message });
    setMessageInfo(updatedMessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        className="custom-toast"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={
          <div>
            <ErrorOutlineIcon sx={{ position: 'absolute' }} />
            <div style={{ marginLeft: '30px' }}>
              {messageInfo.map((item, i) => {
                return (
                  <p style={{ margin: 0 }} key={i}>
                    {item.message}
                  </p>
                );
              })}
            </div>
          </div>
        }
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0, left: '-8px', top: '-6px', position: 'absolute' }}
              onClick={handleClose}
            >
              <CloseIcon
                sx={{
                  color: '#fff',
                  height: '16px',
                  width: '16px',
                  borderRadius: '100%',
                  background: '#42526E'
                }}
              />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default Toaster;
