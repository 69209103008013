import {
  GET_APARTMENT_AMENITIES,
  GET_BUILDING_BY_ID,
  GET_MASTER_CHEATSHEET,
  GET_ALL_BUILDING,
  GET_TOUR_TYPES_BY_APARTMENT
} from '../Constant';

const initialState = {
  ApartmentAmenities: '',
  ApartmentID: '',
  availableNumbers: '',
  masterCheatSheet: [],
  BuildingData: [],
  PropertyLoader: true,
  availableTourTypes: []
};
const Apartment = (state = initialState, action) => {
  switch (action.type) {
    case GET_APARTMENT_AMENITIES:
      return {
        ...state,
        ApartmentAmenities: action.data
      };
    case GET_BUILDING_BY_ID:
      return {
        ...state,
        ApartmentID: action.data
      };
    case GET_MASTER_CHEATSHEET:
      return {
        ...state,
        masterCheatSheet: action.data
      };

    case GET_ALL_BUILDING:
      return {
        ...state,
        BuildingData: action.data,
        PropertyLoader: false
      };
    case GET_TOUR_TYPES_BY_APARTMENT:
      return {
        ...state,
        availableTourTypes: action.data
      };
    default:
      return state;
  }
};

export default Apartment;
