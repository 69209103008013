import React, { forwardRef } from 'react';
import {
  CustomInputStyle,
  InputIconWrapper,
  InputStyle,
  InputWrapper,
  Label,
  RangeInput
} from './input.styles';
import { IconWrapper, Required } from '../Field/field.styles';
import DatePicker from 'react-datepicker';
import DropdownArrow from '../../../Media/InboxV1/DropdownArrow';
import TimeIcon from '../../../Media/KelseyInsight/TimeIcon';
import CalendarIcon from '../../../Media/KelseyInsight/CalendarIcon';
import DateInput from '../../../Component/DateInput';
import Icon from '../../../globalcomponents/Icon';
import { useTheme } from 'styled-components';

const Input = ({
  append,
  prepend,
  dropdown,
  search,
  date,
  time,
  dateRange,
  timeRange,
  size = 'md',
  placeholder = 'Search',
  width = '100%',
  label,
  required = false,
  children,
  onDateChange,
  value,
  name,
  borderBottom,
  customIcon,
  disabled,
  borderNone,
  ...props
}) => {
  const theme = useTheme();
  const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <div style={{ display: 'flex', alignItems: 'center' }} onClick={onClick}>
      <InputIconWrapper prepend>
        <CalendarIcon />
      </InputIconWrapper>
      <CustomInputStyle
        ref={ref}
        size="sm"
        value={value}
        placeholder={placeholder}
      />
    </div>
  ));

  const CustomTimeInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <div style={{ display: 'flex', alignItems: 'center' }} onClick={onClick}>
      <InputIconWrapper prepend>
        <TimeIcon />
      </InputIconWrapper>
      <CustomInputStyle
        ref={ref}
        size="sm"
        value={value}
        placeholder={placeholder}
      />
    </div>
  ));

  const newDateChange = date => {
    onDateChange(date);
  };

  return (
    <div>
      {label && (
        <Label size={size}>
          {label}
          {required && <Required>*</Required>}
        </Label>
      )}
      {dateRange ? (
        <RangeInput>
          <InputWrapper size={size} width={width}>
            {prepend && (
              <InputIconWrapper prepend>
                <CalendarIcon />
              </InputIconWrapper>
            )}
            <InputStyle size={size} {...props} />
            {append && (
              <InputIconWrapper append>
                <CalendarIcon />
              </InputIconWrapper>
            )}
          </InputWrapper>
          <IconWrapper></IconWrapper>
          <InputWrapper size={size} width={width}>
            {prepend && (
              <InputIconWrapper prepend>
                <CalendarIcon />
              </InputIconWrapper>
            )}
            <InputStyle size={size} {...props} />
            {append && (
              <InputIconWrapper append>
                <CalendarIcon />
              </InputIconWrapper>
            )}
          </InputWrapper>
        </RangeInput>
      ) : timeRange ? (
        <RangeInput>
          <InputWrapper size={size} width={width}>
            {prepend && (
              <InputIconWrapper prepend>
                <TimeIcon />
              </InputIconWrapper>
            )}
            <InputStyle size={size} placeholder="From" {...props} />
            {append && (
              <InputIconWrapper append>
                <TimeIcon />
              </InputIconWrapper>
            )}
          </InputWrapper>
          <InputWrapper size={size} width={width}>
            {prepend && (
              <InputIconWrapper prepend>
                <TimeIcon />
              </InputIconWrapper>
            )}
            <InputStyle size={size} placeholder="To" {...props} />
            {append && (
              <InputIconWrapper append>
                <TimeIcon />
              </InputIconWrapper>
            )}
          </InputWrapper>
        </RangeInput>
      ) : dropdown ? null : (
        <InputWrapper
          size={size}
          width={width}
          borderBottom={borderBottom}
          disabled={disabled}
          borderNone={borderNone}
        >
          {prepend && dropdown && (
            <InputIconWrapper prepend>
              <DropdownArrow />
            </InputIconWrapper>
          )}
          {prepend && date && (
            <DateInput
              selected={value}
              onChange={date => newDateChange(date)}
              placeholderText="Select date"
              dateFormat="yyyy-MM-dd"
              minDate={new Date()}
              isClearable
              customInput={<CustomDateInput />}
              name={name}
            />
          )}
          {prepend && time && (
            <DatePicker
              selected={value}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              onChange={date => newDateChange(date)}
              dateFormat="hh:mm aa"
              isClearable
              placeholderText="Select time"
              customInput={<CustomTimeInput />}
              name={name}
            />
          )}
          {prepend && search && (
            <InputIconWrapper prepend>
              <Icon
                src={`search-${theme.state}.svg`}
                imgStyle={{ height: '18px', width: '15px' }}
              />
            </InputIconWrapper>
          )}
          {prepend && customIcon && (
            <InputIconWrapper prepend>
              <Icon
                src={customIcon}
                imgStyle={{ height: '20px', width: '20px' }}
              />
            </InputIconWrapper>
          )}
          {prepend && !dropdown && !search && !date && !time && prepend}
          {!date && !time && (
            <InputStyle
              size={size}
              placeholder={placeholder}
              value={value}
              defaultValue={value}
              name={name}
              disabled={disabled}
              {...props}
            />
          )}

          {append && dropdown && (
            <InputIconWrapper append>
              <DownArrow />
            </InputIconWrapper>
          )}
          {append && search && (
            <InputIconWrapper append>
              <Icon
                src={`search-${theme.state}.svg`}
                imgStyle={{ height: '18px', width: '15px' }}
              />
            </InputIconWrapper>
          )}
          {append && !dropdown && !search && append}
        </InputWrapper>
      )}
    </div>
  );
};

export default Input;
