import { gql } from '@apollo/client';

export const updateApartmentById = gql`
  mutation updateApartments($aptIds: [ID!]!, $record: JSON!) {
    updateApartments(aptIds: $aptIds, record: $record) {
      _id
      zumaIVRSetting {
        ivrType
        isEnabled
        vanityPhoneNumber
        voicemail
      }
    }
  }
`;
export const updateIvrSetting = gql`
  mutation updateZumaIVR($aptId: ID!, $record: JSON!, $type: String!) {
    updateZumaIVR(aptId: $aptId, record: $record, type: $type) {
      _id
      zumaIVRSetting {
        slots {
          name
          enabled
          description
          action
          forwardTo
        }
      }
    }
  }
`;
