import styled, { css } from 'styled-components';
import ReactQuill from 'react-quill';

const LeadListMain = styled.div`
  width: 15%;
  border-radius: 8px;
  overflow: auto;
  height: 90vh;
  min-width: 195px;
`;
const LeadChatCard = styled.div`
  font: 14px Inter;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${props =>
    props.selected
      ? props.theme.leadCardSelected
      : props.theme.leadCardBackground};

  &.disable-click {
    pointer-events: none;
  }

  &.bg-Selected-lgBlue {
    background: ${props => props.theme.leadCardSelected} !important;
    color: #fff;
    border: 0.5px solid ${({ theme }) => theme?.border?.tertiary};
  }

  &.fade-out {
    animation: fadeOut 0.25s ease-out 1 normal;
  }

  .dedupdIcon {
    position: absolute;
    bottom: 7px;
    right: 35px;
    opacity: 0.1;

    svg {
      max-width: 100%;
      height: 21px;
      width: 21px;
    }
  }
`;
const LeadChatCardWrapper = styled.div`
  border-radius: 8px;
  margin-bottom: 10px;
`;
const AppBarContainer = styled.div`
  display: flex;
  position: fixed;
  width: calc(100% - 65px);
  z-index: 99;
  margin-left: 65px;

  .MuiAppBar-colorPrimary {
    background-color: ${props => props.theme.background.universal} !important;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
  }

  .MuiToolbar-root.MuiToolbar-regular {
    background-color: ${props => props.theme.background.universal} !important;
  }
`;
const PageName = styled.div`
  color: ${props => props.theme.text.primary};
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  width: 300px;
`;
const LeadChatStage = styled.div`
  position: relative;
  top: 0;
  background: ${props => props.theme.button.primaryOP10};
  border-radius: 8px 0;
  max-width: 46%;
  width: fit-content;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 5px;
  color: ${props => props.theme.text.tag};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: ${props =>
    props.selected || props.theme.state === 'light' ? 1 : 0.7} !important;
`;
const LeadDate = styled.div`
  color: ${props => props.theme.text.primary} !important;
  position: absolute;
  right: 3%;
  font-size: 10px;
  top: 2%;
  padding: 5px;
  opacity: ${props =>
    props.selected || props.theme.state === 'light' ? 1 : 0.7} !important;
`;
const LeadCardName = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.text.label} !important;
  margin-top: 5px;
  border-radius: 8px;
  padding-left: 5px;
  opacity: ${props =>
    props.selected || props.theme.state === 'light' ? 1 : 0.7} !important;
`;

const LeadPropertyName = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${props => props.theme.text.primary} !important;
  margin-top: 5px;
  border-radius: 8px;
  padding-left: 5px;
  opacity: ${props =>
    props.selected || props.theme.state === 'light' ? 1 : 0.5} !important;
`;

const InboxChatNdHeader = styled.div`
  width: ${props =>
    props.isOnInboxPage
      ? props.openSopDrawer
        ? 'calc(100% - 470px)'
        : '100%'
      : props.openSopDrawer
      ? 'calc(100% - 470px)'
      : '75%'};
  position: relative;
  margin: 0 0.625rem;
  max-height: 90vh;
  z-index: ${props => (props?.isMaximizedPnAOpen ? 0 : 110)};
  border-radius: 8px;
  transition: width 0.25s ease-out;
  transition-delay: 40ms;
  background: ${props => props.theme.background.chatContainer};
  backdrop-filter: blur(25.5px);
  border: ${({ theme }) =>
    `${theme?.state === 'dark' ? '0' : '1px'} solid ${
      theme?.border?.primary
    }50`};

  .v1-kanban-tab {
    position: relative;
    padding: 0.625rem;
    // background-color: #f8f8f8;
    border-radius: 8px 8px 0 0;

    > div {
      &:first-child {
        &:empty {
          display: none;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const KanbanItem = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 110px;
  background: ${props =>
    props.theme?.state === 'light' ? '#F8F8F8' : '#191B25'};
  border-radius: 4px;
`;
const StyledClickableText = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${props => props.theme.text.primary};
`;

const StageContainerExpanded = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 170px;
  padding: 0.625rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 3px 4px 11px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${props => props.theme.background.secondary};
  max-height: 550px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  position: relative;
  z-index: 99;

  &.expanded-view {
    position: absolute;
    background-color: ${props => props.theme.background.secondary};
    z-index: 1;
    top: 15px;
  }
`;
const KanbanListItem = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 110px;
  background-color: ${props => props.theme.background.secondary};
  border-radius: 4px;
  color: ${props => props.theme.text.primary};
  cursor: pointer;
  margin: 5px 0;

  ${props =>
    props.isSelected &&
    css`
      background: ${props =>
        props.theme?.state === 'light' ? '#FFFFFF' : '#191B25'};
      font-weight: 600;
      margin: 0;
    `}

  ${props =>
    props.isLostStage &&
    css`
      cursor: pointer;
    `}
`;
const StagesTitle = styled.div`
  color: ${props => props.theme.text.label};
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  background: ${props => props.theme.background.secondary};
`;
const OwnerContainer = styled.div`
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 1) -5.09%,
    rgba(57, 57, 57, 0) 99.92%
  );
  max-width: 150px;
  overflow: hidden;
`;

const OwnerName = styled.div`
  padding: 10px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.text.primary};
  background-color: ${props =>
    props.theme?.state === 'light' ? '#F8F8F8' : '#191B25'};
  border-radius: 4px;
  display: flex;
  gap: 10px;
`;

const Title = styled.div`
  overflow: hidden;
  max-width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CustomQuill = styled(ReactQuill)`
  .ql-toolbar {
    background-color: #fff;
    display: flex;
    position: absolute;
    justify-content: space-between;
    margin-bottom: 30px; /* Update with your desired margin */
    width: 100%;
    height: 55px;
    border-radius: 8px 8px 0 0;
  }
`;
const SmsEmailOptionComponent = styled.div`
  display: flex;
  justify-content: space-around;
  color: ${props => props.theme.text.primary};
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  div {
    padding: 15px 8px;
    width: 50%;
    border-top: 3px solid transparent;
    opacity: 0.2 !important;
  }

  .active-option {
    color: ${props => props.theme.text.label};
    position: relative;
    opacity: 1 !important;
    top: 0;

    &::after {
      content: '';
      position: absolute;
      background-color: transparent;
      height: 3px;
      top: -6px;
      left: 0;
      width: 100%;
      border-bottom: 4px solid ${props => props.theme.text.tag};
      border-radius: 0 0 4px 4px;
    }
  }
`;
const FormatButton = styled.button`
  /* Common styling for all buttons */
  margin: 0;
  padding: 5px 10px;
  border: none;
  background-color: #f8f8f8;
  cursor: pointer;

  /* Individual button styling based on classNames */

  .ql-stroke {
    stroke: ${props => props.theme.text.primary};
  }

  .ql-fill {
    stroke: ${props => props.theme.text.primary};
  }

  &.ql-bold {
    font-weight: bold;
  }

  &.ql-italic {
    font-style: italic;
  }

  &.ql-underline {
    text-decoration: underline;
  }

  &.ql-link {
    /* Add your styles for link button */
  }

  &.ql-list {
    /* Add your styles for list button */
  }
`;

const ReactQuillContainer = styled.div`
  .text-editor {
    .ql-container.ql-snow {
      background-color: ${props => props.theme.background.secondary};
      border: none;
      min-height: 90px;
      border-radius: 0 0 8px 8px;
      height: auto !important;
    }

    .ql-editor {
      border: none;
      border-top: 0.5px solid #c4c4c4;
      border-radius: 0 0 4px 4px;
      max-height: 150px !important;
      padding-right: 60px;
      height: auto !important;
      color: ${props => props.theme.text.primary};
      font-family: Inter, sans-serif;
      font-size: 14px;
      caret-color: #393939;
    }

    .ql-editor.ql-blank::before {
      font-style: normal;
      font-family: 'Inter', sans-serif;
      color: ${props => props.theme.text.primary};
      opacity: 0.5;
    }
  }
`;
const SenderInitial = styled.div`
  height: 30px;
  width: 30px;
  background: #c4c4c44d;
  color: ${props => props.theme.text.primary};
  fill: rgba(196, 196, 196, 0.3);
  filter: drop-shadow(0px 2px 4px rgba(230, 221, 253, 0.2));
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  margin: 0px 10px;
`;

const StatusContainer = styled.div`
  padding: 5px 10px;
  margin: 0 -10px;
  background: ${({ theme }) => `${theme?.background?.selection}1A`};
  border-radius: 0px 0px 2px 2px;
  color: ${({ theme }) => theme?.text?.primary};
`;

const ScheduledMessageConatiner = styled.div`
  position: absolute;
  cursor: pointer;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  padding: 6px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
  background: #e9ebfb;
`;
const TotalScheduledMessage = styled.div`
  display: flex;
  padding: 1px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'Inter';
`;
const MessagesScheduled = styled.div`
  color: #5267ff;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export {
  LeadListMain,
  LeadChatCard,
  AppBarContainer,
  PageName,
  LeadChatStage,
  LeadDate,
  LeadCardName,
  LeadPropertyName,
  LeadChatCardWrapper,
  InboxChatNdHeader,
  Wrapper,
  KanbanItem,
  StyledClickableText,
  StageContainerExpanded,
  KanbanListItem,
  StagesTitle,
  OwnerContainer,
  OwnerName,
  CustomQuill,
  SmsEmailOptionComponent,
  FormatButton,
  ReactQuillContainer,
  SenderInitial,
  StatusContainer,
  Title,
  ScheduledMessageConatiner,
  TotalScheduledMessage,
  MessagesScheduled
};
