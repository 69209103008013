import { get, post, del, put, patch } from '../../networkClient';
import { Path } from '../path';

export function getAllWorkflows() {
  return get(Path.FETCH_ALL_WORKFLOWS);
}

export function createWorkflowApi({ workflowData }) {
  return post(Path.CREATE_WORKFLOW, workflowData);
}

export function deleteWorkflowApi({ id }) {
  return del(`${Path.DELETE_WORKFLOW}${id}`);
}

export function changeWorkflowStatusApi({ data }) {
  return patch(Path.CHANGE_WORKFLOW_STATUS, data);
}

export function updateWorkflowApi({ workflowData }) {
  return put(Path.UPDATE_WORKFLOW, workflowData);
}
