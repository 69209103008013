import { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMasterCheatsheet } from '../../Redux/Actions/InboxV1';
import { getMasterCheatsheetByTenant } from '../../Redux/util/Apis/inbox';
export const CheatsheetContext = createContext({});

const CheatsheetContextProvider = ({ children }) => {
  const [masterCheatsheetData, setMasterCheatsheetData] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const pathSegments = location.pathname.split('/');
  const id = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    getMasterCheatsheetByTenant(id).then(res => {
      setMasterCheatsheetData(res?.data?.result);
    });
  }, []);

  const value = { data: masterCheatsheetData };

  return (
    <CheatsheetContext.Provider value={value}>
      {children}
    </CheatsheetContext.Provider>
  );
};

export default CheatsheetContextProvider;
