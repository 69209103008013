import { Button, Popover } from '@material-ui/core';
import {
  Checkbox,
  InputAdornment,
  ListItemText,
  MenuItem,
  TextField
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';

const ClientsMultiSelect = props => {
  const {
    allClients,
    selectedClients,
    setSelectedClients,
    searchEnabled = false,
    invalid = false
  } = props;

  const [checkedState, setCheckedState] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const matchClientId = (clients, clientId) => {
    if (clients && clientId) {
      for (const client of clients) {
        if (client._id === clientId) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    let mappedClients = allClients?.map(client => {
      return {
        _id: client?._id,
        name: client?.name,
        checked: matchClientId(selectedClients, client?._id)
      };
    });
    let finalClients = uniqBy(mappedClients, 'name');
    setCheckedState(finalClients);
  }, [selectedClients]);

  const clearSelected = () => {
    let result = checkedState?.map(client => {
      return {
        _id: client?._id,
        name: client?.name,
        checked: false
      };
    });
    setCheckedState(result);
    setSelectedClients([]);
  };

  const selectAll = () => {
    if (checkedState.length === selectedClients.length) {
      clearSelected();
    } else {
      let result = checkedState?.map(client => {
        return {
          _id: client?._id,
          name: client?.name,
          checked: true
        };
      });
      setCheckedState(result);
      setSelectedClients(result);
    }
  };

  const handleOnChange = id => {
    const updatedCheckedState = checkedState.map(item =>
      item._id === id
        ? { _id: id, name: item.name, checked: !item.checked }
        : item
    );
    setCheckedState(updatedCheckedState);
    let checkedClients = updatedCheckedState
      .map(y => (y.checked ? y : null))
      .filter(z => z !== null);
    setSelectedClients(checkedClients);
  };

  const [searchVal, setSearchVal] = useState();
  const handleChange = value => {
    setSearchVal(value);
  };

  let search = checkedState?.filter(({ name }) =>
    name?.toLowerCase()?.startsWith(searchVal?.toLowerCase())
  );

  const handleDropdownClick = event => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
    setAnchorEl(null);
  };

  let finalState = searchEnabled && search.length > 0 ? search : checkedState;

  return (
    <div>
      <TextField
        id="Clients"
        variant="outlined"
        fullWidth
        error={invalid && selectedClients.length === 0}
        value={
          selectedClients && selectedClients.length > 0
            ? selectedClients.map(client => client.name).join(', ')
            : ''
        }
        placeholder="Clients"
        onClick={e => handleDropdownClick(e)}
        style={{ caretColor: 'transparent' }}
        autoComplete="off"
      />
      {dropdownOpen && (
        <Popover
          style={{ zIndex: 9999 }}
          open={dropdownOpen}
          onClose={handleDropdownClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: '10px',
              width: '668px',
              borderRadius: '6px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '10px',
                height: '400px',
                overflow: 'scroll',
                padding: '20px'
              }}
            >
              {searchEnabled && (
                <TextField
                  onChange={e => handleChange(e.target.value)}
                  placeholder="Search Clients"
                  value={searchVal}
                  InputProps={{
                    type: 'search',
                    style: {
                      borderRadius: '4px',
                      padding: '4px'
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#a2a2a2' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              <MenuItem
                key={`select-all`}
                value={'Select All'}
                onClick={() => selectAll()}
                style={{
                  padding: '10px 5px 5px',
                  justifyContent: 'space-between'
                }}
              >
                <ListItemText primary={'Select All'} />
                <Checkbox
                  checked={checkedState.length === selectedClients.length}
                />
              </MenuItem>
              {finalState?.map((x, i) => (
                <MenuItem
                  key={`${x.name}-${i}`}
                  value={x.name}
                  onClick={() => handleOnChange(x._id)}
                  style={{
                    padding: '5px',
                    justifyContent: 'space-between'
                  }}
                >
                  <ListItemText primary={x.name} />
                  <Checkbox checked={x.checked} />
                </MenuItem>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '25px'
              }}
            >
              <Button
                style={{ background: '#5267FF', color: '#fff' }}
                variant="contained"
                onClick={handleDropdownClose}
                id="save-template-clients"
              >
                Save
              </Button>
              <Button onClick={clearSelected} variant="inherit">
                Clear
              </Button>
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default ClientsMultiSelect;
