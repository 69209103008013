import { Alert, Snackbar } from '@mui/material';
import React from 'react';

function SnackBar({ open, setOpen, severity, message }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
      }}
      action={'Success'}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}

export default SnackBar;
