export const callTextHeaders = [
  { displayName: 'Source', key: 'source', sorting: true },
  {
    displayName: 'SSC Number',
    key: 'sscNumber',
    sorting: false,
    showNumber: true
  },
  {
    displayName: 'Vanity Number',
    key: 'vanityNumber',
    clickableLink: 'Bulk Purchase',
    isFaded: true,
    sorting: false,
    showNumber: true
  },
  {
    displayName: 'Actions',
    key: 'actions',
    type: 'actions',
    actions: ['edit', 'delete', 'addNumber'],
    sorting: false
  }
];

function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function getCallTextData(data) {
  return data?.map(item => {
    const { sourceName, sscPhoneNumber, vanityNumber, isSuggested, isCustom } =
      item;
    return {
      source: sourceName,
      sscNumber: sscPhoneNumber,
      vanityNumber: vanityNumber,
      isSuggested: isSuggested,
      isCustom,
      actions: ['edit', 'delete']
    };
  });
}

export const callTextData = [
  {
    source: 'Call',
    sscNumber: '1234567890',
    vanityNumber: '1234567890',
    actions: ['edit', 'delete', 'addNumber']
  },
  {
    source: 'Text',
    sscNumber: '1234567890',
    vanityNumber: '1234567890',
    actions: ['edit', 'delete', 'addNumber']
  },
  {
    source: 'Text',
    sscNumber: '1234567890',
    vanityNumber: '1234567890',
    isSuggested: true,
    actions: ['edit', 'delete']
  }
];

export const count = (data, key) => {
  return data.reduce((acc, item) => {
    if (item[key]) {
      acc++;
    }
    return acc;
  }, 0);
};
