import styled from 'styled-components';

const CommentContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 20%;
  display: flex;
  flex-direction: column;
  width: 23.5rem;
  height: 350px;
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 1.25rem;
  padding-bottom: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  z-index: 10001;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #42526e;
  margin: 0;
  font-style: normal;
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
`;

const CommentForm = styled.div`
  min-height: 140px;
  width: 100%;
  border: 0.5px solid #c4c4c4;
  border-radius: 0.25rem;
  padding: 6px;
  position: relative;
  margin-bottom: 0.625rem;
`;

const CommentInput = styled.textarea`
  width: 100%;
  height: 90px;
  align-content: top;
  margin-bottom: 0.625rem;
  resize: none;
`;

const CTASection = styled.div`
  display: flex;
  gap: 6px;
  position: absolute;
  bottom: 6px;
`;

const CommentsList = styled.ul`
  list-style: none;
  margin: 10px 0 0;
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0;
`;

const EmptyState = styled.p`
  margin: auto;
  font-size: 0.75rem;
  line-height: 15px;
  color: #393939;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
`;
const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding-right: 1.25rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 5rem;
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  background: '#F8F8F8';
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: ${({ toHide }) => (toHide ? 0 : 1)};
  svg {
    path {
      fill: '#42526E';
    }
  }
`;

const IconsListWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const AddIconWrapper = styled.div`
  background-color: '#E9EBFB';
  border-radius: 50%;
  padding: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 5.25rem;
  padding-left: 5rem;
  position: sticky;
  margin-left: 1.25rem;
`;

const EditPNAWrapper = styled.div`
  display: flex;
`;
export {
  CommentContainer,
  Header,
  Title,
  Icons,
  CommentForm,
  CommentInput,
  CTASection,
  CommentsList,
  EmptyState,
  MainWrapper,
  AddIconWrapper,
  IconsListWrapper,
  IconWrapper,
  HeaderWrapper,
  EditPNAWrapper
};
