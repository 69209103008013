import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appartmentIcon from '../../../Media/appartment.svg';
import CheckCircleIcon from '../../../Media/SelfServe/CheckCircleIcon';
import RefreshIcon from '../../../Media/SelfServe/Refresh';
import SettingsIcon from '../../../Media/Sidebar/SettingsBlack';
import {
  applySettings as applySettingsAction,
  configIntegration,
  getIntegration,
  getTenantDetails
} from '../../../Redux/Actions/SelfServe';
import {
  addSelectedAppartment,
  removeSelectedAppartment
} from '../../../Redux/Reducer/SelfServe';
import Grid from './Grid';
import OnlivePopUp from './onLiveModal';
import SettingsModal from './SettingsModal';
import styles from './styles.module.scss';

const Integrations = ({ adminTenantId: tenantId }) => {
  const selfserve = useSelector(state => state.SelfServe);
  const { integrationApartment, selectedApprtment } = selfserve;
  const NotLiveAppartment = integrationApartment?.filter(
    item => !item.isLive && (item.integrations || item.integration)
  );
  const LiveAppartments = integrationApartment?.filter(item => item.isLive);
  const settingsEnabled =
    selfserve?.userData?.data?.crmSetup ||
    selfserve?.userData?.data?.pmsSetup ||
    false;
  const tenantData = selfserve?.userData?.data || {};
  const crmSetup = selfserve?.userData?.data?.crmSetup || false;
  const pmsSetup =
    selfserve?.userData?.data?.pmsSetup ||
    selfserve?.userData?.data?.propertyIngestion ||
    false;
  const propertyIngestion = selfserve?.userData?.data?.propertyIngestion;
  const initalStatusMapping =
    selfserve?.userData?.data?.statusMappings?.mappings || [];
  const statusMappings = selfserve?.userData?.data?.statusMappings || {};
  const settingsName = selfserve?.userData?.data?.settingsName || '';
  const assignToKelsey = selfserve?.userData?.data?.assignLeadToKelsey || false;
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState(false);
  const [open, setOpenPopup] = useState(false);
  const [applliedSettings, setApplySettings] = useState([]);
  const [onLiveSettings, setOnLiveSettings] = useState([]);
  const [notification, setNotification] = useState({
    success: false,
    msg: ''
  });
  const [showupdatedApartment, setShowUpdatedApartment] = useState([]);

  const closePop = () => {
    setOnLiveSettings([]);
    setOpenPopup(false);
  };

  const [isSettingsModal, setIsSettingsModal] = React.useState(false);
  const [searchVal, setSearchVal] = useState('');

  const handleSettingsClose = () => {
    setIsSettingsModal(false);
    if (tenantId) {
      dispatch(getTenantDetails(tenantId));
    }
  };

  const handleSettingsOpen = () => {
    if (!settingsEnabled) {
      setIsSettingsModal(true);
    } else {
      setOnLiveSettings([]);
      setOpenPopup(true);
    }
  };

  const editSettings = e => {
    e.stopPropagation();
    if (tenantId) {
      dispatch(getTenantDetails(tenantId));
    }
    setIsSettingsModal(true);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#4CAF50',
      '&:hover': {
        backgroundColor: '#4CAF50'
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50'
    }
  }));

  const removeOnLive = data => {
    setOnLiveSettings(prev => {
      const updatedData = prev.filter(item => item !== data);
      return updatedData;
    });
  };

  const addOnLiveData = data => {
    setOnLiveSettings(prev => {
      return [...prev, data];
    });
  };

  const applySettingsData = data => {
    setApplySettings(prev => {
      const filter = integrationApartment.findIndex(item => item?._id === data);
      dispatch(addSelectedAppartment(integrationApartment[filter]));
      return [...prev, data];
    });
  };

  const removeOnboard = data => {
    setApplySettings(prev => {
      const updatedData = prev.filter(item => item !== data);
      const filter = selectedApprtment?.filter(item => item?._id !== data);
      dispatch(removeSelectedAppartment(filter));
      return updatedData;
    });
  };
  const updateUiForSelectedView = selectedView
    ? selectedApprtment
    : NotLiveAppartment;
  const filteredApartment = searchVal?.length
    ? showupdatedApartment
    : updateUiForSelectedView;

  const handleChangeSearch = value => {
    setSearchVal(value);

    setShowUpdatedApartment(prev => {
      const searchTerm = value.toLowerCase();
      const filtered = updateUiForSelectedView.filter(item =>
        item?.name?.toLowerCase().startsWith(searchTerm)
      );
      return filtered || [];
    });
  };
  const showNotification = (msg = '', success = false) => {
    setNotification(prev => ({ ...prev, msg, success }));
  };

  const handleApplySettings = () => {
    if (!applliedSettings?.length) {
      setNotification(prev => ({
        ...prev,
        msg: 'please select appartment',
        success: false
      }));
      return;
    }
    const payload = {
      tenantId,
      apartmentIds: applliedSettings,
      syncProperty: true,
      leadIngestion: true,
      isSettingsEnable: true,
      isLive: true
    };
    dispatch(
      applySettingsAction({
        payload: payload,
        showNotification: showNotification
      })
    );
  };

  const handleRemoveLive = () => {
    if (!onLiveSettings?.length) {
      setNotification(prev => ({
        ...prev,
        msg: 'please select appartment',
        success: false
      }));
      return;
    }
    const payload = {
      tenantId,
      apartmentIds: onLiveSettings,
      syncProperty: true,
      leadIngestion: false,
      isSettingsEnable: false,
      isLive: false
    };
    dispatch(
      applySettingsAction({
        payload: payload,
        showNotification: showNotification
      })
    );
  };

  const closeNotification = () => {
    setNotification(prev => ({ ...prev, msg: '' }));
  };
  const handleRefresh = () => {
    dispatch(getIntegration({ tenantId }));
  };
  useEffect(() => {
    if (tenantId) {
      dispatch(getTenantDetails(tenantId));

      dispatch(getIntegration({ tenantId }));
    }
  }, [tenantId]);

  const count = LiveAppartments?.map(item => {
    if (item?.integrations && item?.isLive) {
      return item;
    }
  }).filter(x => x != null).length;

  return (
    <div className="bg-white integrationContainer p-20 mt-20 mr-20">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div className={styles.search}>
          <TextField
            onChange={e => handleChangeSearch(e.target.value)}
            placeholder="Search anything"
            variant="outlined"
            value={searchVal}
            InputProps={{
              type: 'search',
              style: {
                borderRadius: '4px',
                padding: '4px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#a2a2a2' }} />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.rightSection}>
          <div onClick={handleRefresh} className={styles.refreshCont}>
            <RefreshIcon />
          </div>
          <button
            onClick={handleApplySettings}
            className={
              settingsEnabled
                ? styles.stdButton
                : `${styles.disableBtn} ${styles.stdButton}`
            }
          >
            Apply Settings
          </button>
          {/* <div className={styles.selectedView}>
            <span>Selected view</span>
            <GreenSwitch
              onChange={() => setSelectedView((prev) => !prev)}
              size="small"
              checked={selectedView}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div> */}
        </div>
      </div>
      <div className={styles.integrationGrid}>
        {filteredApartment && filteredApartment.length ? (
          filteredApartment?.map(item => {
            if (item?.integrations) {
              return (
                <Grid
                  tag={true}
                  key={item?._id}
                  apptId={item?._id}
                  header={item.name}
                  address={item.addressId}
                  timeLabel={item.createdAt}
                  applySettingsData={applySettingsData}
                  removeOnboard={removeOnboard}
                />
              );
            }
          })
        ) : (
          <h4 className={styles.h4}>No data</h4>
        )}
      </div>
      {isSettingsModal && (
        <SettingsModal
          statusMappings={statusMappings}
          tenantData={tenantData}
          tenantId={tenantId}
          configIntegration={configIntegration}
          handleClose={handleSettingsClose}
          settingsInitailValue={settingsName}
          assignToKelsey={assignToKelsey}
          propertyIngestion={propertyIngestion}
          crmSetupInitial={crmSetup}
          pmsSetupInitial={pmsSetup}
          initalStatusMapping={initalStatusMapping}
        />
      )}
      <div onClick={handleSettingsOpen} className={styles.configSettings}>
        <div className={styles.settingsBox}>
          {settingsEnabled ? (
            <>
              <div className={styles.confHeader}>
                <h3 className={styles.h3}>Live Communities</h3>
                <span className={styles.box}>
                  <img src={appartmentIcon} alt="" />
                  {count || 0}
                </span>
                <div onClick={editSettings} className={styles.editContainer}>
                  <SettingsIcon />
                </div>
              </div>
              <p className={styles.para}>
                <CheckCircleIcon />
                Property ingestion(PMS)
              </p>
              <p className={styles.para}>
                <CheckCircleIcon />
                Lead ingestion(CRM)
              </p>
            </>
          ) : (
            <>
              <SettingsIcon />
              <p className={styles.settingsText}> Configure Integration</p>
            </>
          )}
        </div>
      </div>
      {notification?.msg && (
        <div
          className={
            notification?.success
              ? `${styles.snackbarCont} ${styles.successSnakc}`
              : styles.snackbarCont
          }
        >
          <Snackbar
            autoHideDuration={4000}
            open={notification?.msg}
            onClose={closeNotification}
            message={notification?.msg || 'failed'}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </div>
      )}
      <OnlivePopUp
        removeOnLive={removeOnLive}
        addOnLiveData={addOnLiveData}
        handleRemoveLive={handleRemoveLive}
        appartmentData={LiveAppartments}
        open={open}
        closePop={closePop}
        onLiveSettings={onLiveSettings}
      />
    </div>
  );
};

export default Integrations;
