import React from 'react';

const ArrowRightSmall = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8965 2.89664C5.85003 2.94304 5.81316 2.99814 5.788 3.0588C5.76285 3.11945 5.7499 3.18448 5.7499 3.25014C5.7499 3.31581 5.76285 3.38083 5.788 3.44149C5.81316 3.50215 5.85003 3.55725 5.8965 3.60364L7.793 5.50014H3C2.86739 5.50014 2.74021 5.55282 2.64645 5.64659C2.55268 5.74036 2.5 5.86753 2.5 6.00014C2.5 6.13275 2.55268 6.25993 2.64645 6.3537C2.74021 6.44746 2.86739 6.50014 3 6.50014H7.793L5.8965 8.39664C5.85003 8.44304 5.81316 8.49814 5.788 8.5588C5.76285 8.61945 5.7499 8.68448 5.7499 8.75014C5.7499 8.81581 5.76285 8.88083 5.788 8.94149C5.81316 9.00215 5.85003 9.05725 5.8965 9.10364C6.0915 9.29864 6.4085 9.29864 6.604 9.10364L9.3535 6.35364C9.44405 6.26344 9.49649 6.14191 9.5 6.01414V5.98564C9.49642 5.85804 9.44398 5.73669 9.3535 5.64664L6.604 2.89664C6.55756 2.85016 6.50242 2.81328 6.44172 2.78811C6.38102 2.76295 6.31596 2.75 6.25025 2.75C6.18454 2.75 6.11948 2.76295 6.05878 2.78811C5.99808 2.81328 5.94294 2.85016 5.8965 2.89664Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default ArrowRightSmall;
