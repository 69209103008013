import React from 'react';
import { PillContainer, PillText } from './pill.styles';

const Pill = ({
  text,
  bgColor,
  onClick,
  color = '#393939',
  clickable = false
}) => {
  return (
    <PillContainer bgColor={bgColor} clickable={clickable} onClick={onClick}>
      <PillText color={color}>{text}</PillText>
    </PillContainer>
  );
};

export default Pill;
