import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChatMessage from './ChatComponentsV2/ChatMessage';
import Loader from '../../Component/Loader';
import TemplateRecommendation from './TemplateRecommendation';
import { useInboxContext } from '../../utils/ContextProviders/InboxContextProvider';
import NewMessageButton from './ChatComponentsV2/NewMessageButton';

const ChatContainer = props => {
  const {
    closedReason,
    botTurningOffReason,
    chatArchivedMsg,
    leadData,
    setMessageInput,
    timeZone,
    selectedLeadId,
    archiveClick
  } = props;

  const {
    agentAnnouncementsLoading,
    fetchNewTimeLineEvents,
    leadMessage,
    isUpdatingMessageList
  } = useInboxContext();
  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const prevInnerDivHeight = useRef(null);

  const [showScrollToNewMessageButton, setShowScrollToNewMessageButton] =
    useState(false);

  useEffect(() => {
    setShowScrollToNewMessageButton(false);
    fetchNewTimeLineEvents(selectedLeadId);
    const interval = setInterval(() => {
      fetchNewTimeLineEvents(selectedLeadId);
    }, 4000);
    return () => clearInterval(interval);
  }, [selectedLeadId]);

  // scroll smoothly on change of leadMessage and loading states
  useEffect(() => {
    if (!agentAnnouncementsLoading && !isUpdatingMessageList && !archiveClick) {
      const outerHeight = outerRef?.current?.clientHeight;
      const innerHeight = innerRef?.current?.clientHeight;
      const outerDivScrollTop = outerRef?.current?.scrollTop;

      if (
        !prevInnerDivHeight.current ||
        outerDivScrollTop === 0 ||
        Math.floor(prevInnerDivHeight.current - outerDivScrollTop + 62) ===
          outerHeight
      ) {
        outerRef?.current?.scrollTo({
          top: innerHeight - outerHeight + 65,
          left: 0,
          behavior: prevInnerDivHeight.current ? 'smooth' : 'auto'
        });
      } else {
        setShowScrollToNewMessageButton(true);
      }

      prevInnerDivHeight.current = innerHeight;
    }
  }, [
    leadMessage,
    agentAnnouncementsLoading,
    isUpdatingMessageList,
    archiveClick
  ]);

  const handleScrollButtonClick = useCallback(() => {
    const outerHeight = outerRef?.current?.clientHeight;
    const innerHeight = innerRef?.current?.clientHeight;

    outerRef?.current?.scrollTo({
      top: innerHeight - outerHeight + 65,
      left: 0,
      behavior: 'smooth'
    });

    setShowScrollToNewMessageButton(false);
  }, []);

  return (
    <>
      <div
        style={{ position: 'relative', overflow: 'scroll', height: '86%' }}
        ref={outerRef}
      >
        {agentAnnouncementsLoading || isUpdatingMessageList ? (
          <div style={{ margin: '25% auto', height: '100px', width: '100px' }}>
            <Loader />
          </div>
        ) : (
          <div
            id="message-container"
            style={{ position: 'relative', paddingBottom: '50px' }}
            ref={innerRef}
          >
            {!chatArchivedMsg &&
              leadMessage?.map(singleLeadMessage => (
                <ChatMessage
                  key={singleLeadMessage._id}
                  {...{
                    ...singleLeadMessage,
                    customerName: singleLeadMessage?.leadId?.name ?? '',
                    closedReason,
                    botTurningOffReason,
                    leadData,
                    timeZone
                  }}
                />
              ))}
          </div>
        )}
        <TemplateRecommendation setMessageInput={setMessageInput} />
      </div>
      <NewMessageButton
        onClick={handleScrollButtonClick}
        show={showScrollToNewMessageButton}
      />
    </>
  );
};
export default React.memo(ChatContainer);
