import styled from 'styled-components';

const inputConfig = {
  fontSize: {
    lg: '1rem',
    md: '1rem',
    sm: '0.875rem',
    xs: '0.75rem',
    xxs: '0.625rem'
  }
};

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: ${({ toHide }) => (toHide ? 0 : 1)};
  svg {
    path {
      fill: '#42526E';
    }
  }
`;
export const DateInputWrapper = styled.div`
  display: flex;
  align-items: 'center';
  flex-direction: ${({ inverse }) => (inverse ? 'row-reverse' : 'row')};
  border: ${({ border }) => (border ? '0.5px solid #C4C4C4;' : 'none')};
`;
export const InputIconWrapper = styled(IconWrapper)`
  margin-left: ${({ append }) => append && '1rem'};
  /* margin-right: ${({ prepend }) => prepend && '1rem'}; */
  flex-direction: ${({ inverse }) => (inverse ? 'row-reverse' : 'row')};
  line-height: 0;
`;

export const CustomInputStyle = styled.input`
  width: 100%;
  color: ${({ theme }) => theme?.text?.primary};
  font-size: ${({ size = 'md' }) => inputConfig['fontSize'][size]};
  background-color: transparent;
  padding-left: 5px;
  font-family: 'Inter', sans-serif;
  border: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: #9d9d9d;
    font-weight: 300;
  }
`;

export const DateInputContainer = styled.div`
  width: 100%;
`;

export const CustomHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #ffffff;
  font-family: inter;

  border-radius: 8px;
  margin: 10px 40px;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6.7px 7.5px;
`;
