import * as React from 'react';
import { Dialog, IconButton } from '@mui/material';
import { Gif } from '@material-ui/icons';
import Giphy from './Giphy';

const GiphyPopover = ({ handleChatEmojis }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onGifClick = (gif, e) => {
    e.preventDefault();
    handleClose();
    handleChatEmojis(gif.images.original.url, 'gif');
  };

  return (
    <div>
      <IconButton style={{ padding: 0 }} onClick={handleClick}>
        <Gif
          style={{ width: '60px', height: '60px', color: 'rgb(34, 22, 95)' }}
        />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <Giphy onGifClick={onGifClick} />
      </Dialog>
    </div>
  );
};

export default GiphyPopover;
