import { Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MaterialTable from 'material-table';
import React, { useContext, useEffect, useState } from 'react';
import {
  getTenantDetails,
  getUserData
} from '../../../Redux/Actions/SelfServe';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber, ROLES } from '../../../utils';
import ManageUserModal from './ManageUserModal';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { filter, find } from 'lodash';
import { getAllUsers } from '../../../gql/queries/userData';
import { useQuery } from '@apollo/client';

const Workspace = ({ tenantId, tenantName }) => {
  const dispatch = useDispatch();
  const adminData = useSelector(state => state.Admin);
  const tenantData = useSelector(state => state.SelfServe.userData);
  const [usersList, setUserList] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const { userData } = useContext(GlobalContext);

  const { data } = useQuery(getAllUsers, {
    variables: {
      uid: userData?.user_id
    },
    enabled: false,
    skip: false
  });

  useEffect(() => {
    if (data?.getAllUsers) {
      const assignedData = filter(data?.getAllUsers, o =>
        find(o?.userAccess?.clients?.trained, {
          _id: tenantId
        })
      );
      let finalData = assignedData.map(x => {
        return {
          user_id: x?.uid,
          tenantId: x?.userAccess?.clients?.trained?.map(z => z._id),
          name: x?.name,
          email: x?.email,
          phone: x?.mightyCall,
          role: x?.role?.name
        };
      });
      setUserList(finalData);
    }
  }, [data?.getAllUsers.length > 0]);
  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (adminData?.users?.data || tenantId) {
      dispatch(getTenantDetails(tenantId));
    }
  }, [adminData?.users, tenantId]);
  const [searchVal, setSearchVal] = useState('');

  const handleChange = val => {
    setSearchVal(val);

    setFilteredValues(prev => {
      const searchTerm = val.toLowerCase();
      const filtered = usersList.filter(item =>
        item?.name?.toLowerCase().startsWith(searchTerm)
      );
      return filtered || [];
    });
  };

  const editable =
    searchVal?.length || tenantId
      ? filteredValues.map((o, i) => ({ ...o, sno: i + 1 }))
      : usersList?.map((o, i) => ({ ...o, sno: i + 1 }));

  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleUserModal = () => {
    setOpen(!open);
    if (isEdit) setIsEdit(false);
  };

  const handleDeleteModal = () => {
    setDeleteOpen(!deleteOpen);
  };

  useEffect(() => {
    if (searchVal.length) {
      setFilteredValues(() => {
        const searchTerm = searchVal.toLowerCase();
        const filtered = usersList.filter(item =>
          item?.name?.toLowerCase().startsWith(searchTerm)
        );
        return filtered || [];
      });
    } else if (tenantId) {
      setFilteredValues(() => {
        const filtered = usersList.filter(
          item => item?.tenantId?.indexOf(tenantId) > -1
        );

        return filtered || [];
      });
    }
  }, [searchVal, tenantId, usersList]);

  return (
    <div className="bg-white mt-20 mr-20 br-4 p-20 workspaceContainer">
      {open && (
        <ManageUserModal
          open={open}
          setOpen={setOpen}
          tenantId={tenantId}
          tenantData={tenantData}
          tenantName={tenantName}
        />
      )}

      <div className="flex mb-20">
        <TextField
          onChange={e => handleChange(e.target.value)}
          placeholder="Search anything"
          variant="outlined"
          value={searchVal}
          InputProps={{
            type: 'search',
            style: {
              borderRadius: '4px',
              padding: '4px'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#a2a2a2' }} />
              </InputAdornment>
            )
          }}
        />
        <Button onClick={handleUserModal} className="save-btn">
          Manage Users
        </Button>
      </div>
      <div className="customTable">
        <MaterialTable
          tableLayout="auto"
          stickyHeader
          columns={[
            {
              title: (
                <p style={{ paddingLeft: '20px' }}>
                  S.No
                  <span className="user-length-text">{editable?.length}</span>
                </p>
              ),
              field: 'sno',
              cellStyle: {
                whiteSpace: 'nowrap',
                paddingLeft: '50px'
              }
            },
            {
              title: 'Name',
              field: 'name',
              cellStyle: {
                whiteSpace: 'nowrap'
              }
            },
            {
              title: 'Email',
              field: 'email',
              cellStyle: {
                whiteSpace: 'nowrap'
              }
            },
            {
              title: 'Phone Number',
              field: 'phone',
              cellStyle: {
                whiteSpace: 'nowrap',
                marginTop: '10px'
              },
              render: rowData => <>{formatPhoneNumber(rowData.phone)}</>
            },
            {
              title: 'Role',
              field: 'role',
              cellStyle: {
                whiteSpace: 'nowrap'
              },
              render: rowData => <>{ROLES[rowData.role]}</>
            },
            {
              title: 'Actions',
              field: 'actions',
              rowStyle: {
                whiteSpace: 'nowrap'
              }
            }
          ]}
          options={{
            toolbar: false,
            search: false,
            actionsColumnIndex: -1,
            paging: false,
            maxBodyHeight: '522px',
            overflowY: 'inherit',
            cellStyle: {
              padding: '10px 10px',
              fontFamily: 'Inter !important',
              fontWeight: '500',
              fontSize: '14px'
            },
            rowStyle: x => {
              if (x.tableData.id % 2) {
                return {
                  backgroundColor: '#ffffff',
                  fontSize: '12px',
                  fontFamily: 'Inter !important',
                  position: 'relative',
                  borderBottom: '10px solid white',
                  marginTop: '10px'
                };
              } else {
                return {
                  fontSize: '12px',
                  fontFamily: 'Inter !important',
                  position: 'relative',
                  borderBottom: '10px solid white',
                  backgroundColor: 'rgba(196, 196, 196, 0.1)',
                  marginTop: '10px'
                };
              }
            },
            headerStyle: {
              backgroundColor: '#EDEDED',
              color: '#42526E',
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: '500',
              padding: '6px 10px',
              fontFamily: 'Inter',
              borderBottom: 'none'
            },
            sorting: true
          }}
          actionsCellStyle={{ innerWidth: '200px' }}
          data={editable}
        />
      </div>
    </div>
  );
};

export default Workspace;
