import { Button, Dialog } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import CrossIcon from '../../../Media/InboxV1/CrossIcon';
import NumberConfirm from '../../../Media/InboxV1/NumberConfirm';
import { PurchaseNumber } from '../../../Redux/Actions/Apartment';
const VanityNumberPopup = ({
  open,
  setOpen,
  handleNumberChange,
  setSelectedNumber,
  selectedNumber,
  id,
  keyName
}) => {
  const dispatch = useDispatch();
  const handleNumberPurchase = () => {
    dispatch(PurchaseNumber({ _id: id, [keyName]: selectedNumber }));
  };
  return (
    <Dialog
      className="d-flex-just-align-center"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ padding: '10px' }}
    >
      <CrossIcon
        style={{
          position: 'absolute',
          marginLeft: '340px',
          marginTop: '20px',
          width: '24px',
          height: '24px',
          cursor: 'pointer'
        }}
        onClick={() => setOpen(false)}
      />
      <div style={{ padding: '20px', width: '340px', height: '294px' }}>
        <div className="d-flex-just-align-center" style={{ marginTop: '42px' }}>
          <NumberConfirm />
        </div>
        <div
          className="inter-16px-500 d-flex-just-align-center"
          style={{
            marginTop: '10px',
            color: '#393939',
            fontWeight: '600',
            letterSpacing: '0.5px'
          }}
        >
          Purchasing new vanity number!
        </div>
        <div
          className="inter-16px-500 d-flex-just-align-center"
          style={{
            marginTop: '10px',
            fontSize: '12px',
            color: '#EB5757',
            fontWeight: '400',
            letterSpacing: '0.5px'
          }}
        >
          Do you really want to buy this vanity number?
        </div>
        <div className="d-flex-just-align-center" style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            style={{
              marginTop: '10px',
              textTransform: 'none',
              width: '77px',
              height: '40px',
              background: '#5267FF'
            }}
            onClick={() => {
              handleNumberChange();
              handleNumberPurchase();
              setOpen(false);
            }}
            color={'primary'}
            fullWidth={false}
            size={'small'}
          >
            <span style={{ fontSize: '18px', fontWeight: '500' }}>Yes</span>
          </Button>
          <Button
            variant="inherit"
            style={{ marginTop: '10px', textTransform: 'none' }}
            onClick={() => {
              setSelectedNumber('');
              setOpen(false);
            }}
            fullWidth={false}
            size={'small'}
          >
            <span
              style={{ color: '#393939', fontSize: '18px', fontWeight: '500' }}
            >
              No
            </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default VanityNumberPopup;
