import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    backgroundColor: 'transparent',
    padding: 20
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '56px',
    height: '56px',
    oveflow: 'scroll'
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: '#FFF1EF'
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#FF6F62'
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#FF6F62'
  })
};

const MySelect = props => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options]}
        name={props.name}
        value={props.value}
        styles={customStyles}
        placeholder={props.placeholder}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  name: PropTypes.string,
  placeholder: PropTypes.string
};

MySelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*'
  },
  name: '',
  placeholder: ''
};

export default MySelect;
