import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiFormControl-root': {
      marginTop: '10px',
      borderRadius: '5px'
    }
  }
}));
