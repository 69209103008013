import styled from 'styled-components';

const JourneyContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 15px 15px 8px 8px;
  position: relative;
  top: -30px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const Header = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
  color: ${({ theme }) => theme.text.primary};
  margin: 0;
`;

const Requirements = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0 10px;
  justify-content: space-between;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  min-width: 192px;
  gap: 10px;
`;

const Progress = styled.div`
  height: 100%;
  margin: auto 15px;
  z-index: 0;
`;

export {
  JourneyContainer,
  TitleBar,
  Header,
  Requirements,
  Information,
  Progress
};
