import { GET_SEARCHED_DATA } from '../Constant';
import { get } from '../networkClient';

const GlobalData = data => ({
  type: GET_SEARCHED_DATA,
  data: data
});

export const GetGlobalSearchData = data => async dispatch => {
  await get('/search', { q: data }).then(resp => {
    dispatch(GlobalData(resp.data));
  });
};

export const ClearGlobalSearch = data => async dispatch => {
  dispatch(GlobalData(data));
};
