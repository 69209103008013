import React from 'react';
import TextLabelComponent from '../../globalcomponents/TextLabelComponent';
import { PropertyCodeWrapper, SearchInput } from './styles.callToText';

const PropertyCodeComponent = ({
  name,
  propertyId,
  areaCode,
  handleSearch,
  searchInput,
  stateLong,
  city
}) => {
  return (
    <PropertyCodeWrapper>
      <TextLabelComponent
        id="text-label-component"
        value={`${propertyId} [${city}, ${stateLong}, ${
          areaCode?.split('-')[0]
        }]`}
        label={'Property ID'}
      />
      <SearchInput
        style={{ width: '30%' }}
        onChange={handleSearch}
        value={searchInput}
        type="search"
        placeholder="Search..."
      />
    </PropertyCodeWrapper>
  );
};

export default PropertyCodeComponent;
