import React from 'react';

const RefreshIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fill-opacity="0.01" />
      <path
        d="M8.00004 6.003V8.998C8.00004 9.26322 8.10539 9.51757 8.29293 9.70511C8.48046 9.89264 8.73482 9.998 9.00004 9.998C9.26525 9.998 9.51961 9.89264 9.70714 9.70511C9.89468 9.51757 10 9.26322 10 8.998V5.102C10 4.494 9.50703 4 8.90003 4H5.00004C4.73442 4 4.47969 4.10551 4.29187 4.29333C4.10405 4.48115 3.99854 4.73589 3.99854 5.0015C3.99854 5.26711 4.10405 5.52185 4.29187 5.70967C4.47969 5.89748 4.73442 6.003 5.00004 6.003H8.00004Z"
        fill="#42526E"
      />
      <path
        d="M9.42804 18.018C7.35104 16.989 6.00004 14.807 6.00004 12.37C6.00004 10.104 7.16704 8.051 9.02004 6.945C9.50004 6.659 9.66604 6.023 9.39104 5.524C9.32919 5.40677 9.24418 5.30331 9.14116 5.21991C9.03814 5.13651 8.91926 5.0749 8.79172 5.0388C8.66419 5.00271 8.53065 4.99289 8.3992 5.00994C8.26776 5.027 8.14114 5.07056 8.02704 5.138C5.55704 6.611 4.00004 9.35 4.00004 12.37C4.00004 15.618 5.80204 18.528 8.57003 19.899C9.06804 20.146 9.66504 19.925 9.90204 19.406C10.139 18.886 9.92704 18.265 9.42804 18.018Z"
        fill="#42526E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 15.002V18.898C14 19.506 14.493 20 15.1 20H19C19.2656 20 19.5204 19.8945 19.7082 19.7067C19.896 19.5188 20.0015 19.2641 20.0015 18.9985C20.0015 18.7329 19.896 18.4782 19.7082 18.2903C19.5204 18.1025 19.2656 17.997 19 17.997H16V15.002C16 14.7368 15.8947 14.4824 15.7071 14.2949C15.5196 14.1074 15.2653 14.002 15 14.002C14.7348 14.002 14.4805 14.1074 14.2929 14.2949C14.1054 14.4824 14 14.7368 14 15.002Z"
        fill="#42526E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.097 4.596C13.86 5.116 14.072 5.736 14.571 5.983C16.648 7.012 17.999 9.193 17.999 11.63C17.999 13.896 16.832 15.95 14.978 17.055C14.7457 17.1988 14.5776 17.4265 14.5087 17.6909C14.4398 17.9553 14.4754 18.2361 14.608 18.475C14.882 18.975 15.493 19.148 15.972 18.862C18.442 17.389 19.999 14.65 19.999 11.63C19.999 8.382 18.197 5.472 15.429 4.102C15.2965 4.03537 15.1503 4.00045 15.002 4C14.8099 4.00188 14.6223 4.05909 14.4618 4.16479C14.3013 4.27049 14.1747 4.4202 14.097 4.596Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default RefreshIcon;
