import MaterialTable, { MTableBodyRow, MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react';
import SortIcon from '../../Media/InboxV1/SortIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import CrossWithBg from '../../Media/InboxV1/CrossWithBg';
import Loader from '../../Component/Loader';
import MapModal from './PriceMatrix/MapModal';
import PriceCalcModal from './PriceMatrix/PriceCalcModal';
import { MONTHS_LIST_SHORT } from '../../utils';
import { useTheme } from 'styled-components';
import { capitalize } from 'lodash';

const useStyles = makeStyles({
  toolbarWrapper: {
    display: 'none',
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  viewText: {
    '&.MuiTypography-root': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '15px',
      color: '#5267FF'
    }
  },
  rowText: {
    '&.MuiTypography-root': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '15px',
      color: '#393939'
    }
  }
});

const CustomPriceTable = ({
  data = [],
  loading = true,
  handleAutofillData,
  bedRoom,
  bathRoom,
  saveLead,
  setFilteredRes,
  min,
  max,
  currentApartmentId,
  tenantName
}) => {
  bedRoom = bedRoom ? parseInt(bedRoom) : null;
  bathRoom = bedRoom ? parseInt(bathRoom) : null;

  const classes = useStyles();
  const theme = useTheme();

  const [showOverlay, setShowOverlay] = useState(false);

  const [open, setOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const [showIndex, setShowIndex] = useState(null);

  const [imageUrl, setImageUrl] = useState('');

  const [priceData, setPriceData] = useState({});
  const [editableData, setEditAbleData] = useState([]);
  const handleCloseOverlay = e => {
    e.stopPropagation();
    setShowOverlay(false);
    setShowIndex(null);
  };

  const handleRowClick = (e, data) => {
    e.stopPropagation();
    setShowOverlay(true);
    setShowIndex(data.tableData.id);
  };

  const handleModalOpen = (e, props) => {
    e.stopPropagation();
    setOpen(true);
    setPriceData(props.data);
  };

  const onMapViewClick = (e, data) => {
    e.stopPropagation();
    setMapOpen(true);
    setImageUrl(data?.floorMap);
  };

  const handleMapClose = () => {
    setMapOpen(false);
  };

  const handleCalcClose = () => {
    setOpen(false);
  };

  const handleAutofillClick = (e, props) => {
    e.stopPropagation();
    setPriceData(props.data);
    handleCloseOverlay(e);
    handleAutofillData(props.data);
    saveLead({
      _id: props.data._id,
      bedrooms: props.data.bedRooms,
      bathrooms: props.data.bathRooms
    });
  };

  let editable = [];
  useEffect(() => {
    const hasChildUnits = data.filter(
      info => info.aptId !== currentApartmentId
    ).length;

    editable = data.map(o => ({
      ...o,
      isParent: hasChildUnits && o.aptId === currentApartmentId ? true : null,
      isChild: hasChildUnits && o.aptId !== currentApartmentId ? true : null
    }));
    if (
      bedRoom !== undefined &&
      bathRoom !== undefined &&
      bedRoom >= 0 &&
      bathRoom > 0
    ) {
      editable = editable.filter(({ bedRooms, bathRooms }) => {
        return bedRooms === bedRoom && bathRooms === bathRoom;
      });
    } else if (bedRoom !== null && bedRoom >= 0) {
      editable = editable.filter(({ bedRooms }) => {
        return bedRooms === bedRoom;
      });
    } else if (bathRoom !== null && bathRoom > 0) {
      editable = editable.filter(({ bathRooms }) => {
        return bathRooms === bathRoom;
      });
    }

    if (min || max) {
      editable = editable.filter(({ rent }) => {
        return (
          rent?.minRent === undefined ||
          min <= rent?.minRent ||
          (min <= rent?.minRent && min <= rent?.maxRent)
        );
      });
    }

    setEditAbleData([...editable]);
    setFilteredRes(editable?.length);
  }, [bedRoom, bathRoom, data, min, max]);

  return (
    <React.Fragment>
      <MaterialTable
        columns={[
          {
            title: 'Price',
            field: 'rent[minRent]',
            cellStyle: {
              whiteSpace: 'nowrap'
            },
            render: rowData => (
              <>{rowData?.rent?.minRent ? '$' + rowData.rent.minRent : ''}</>
            )
          },
          {
            title: 'Available',
            field: 'avlFrom',
            cellStyle: {
              whiteSpace: 'nowrap'
            },
            render: rowData => {
              const d1 = new Date(rowData.avlFrom);
              const tick = "'";
              const d2 = new Date();
              const date = `${
                MONTHS_LIST_SHORT[d1.getMonth()]
              } ${d1.getDate()} ${tick}${d1
                .getFullYear()
                .toString()
                .slice(-2)}`;
              return d2 - d1 >= 0 ? <>Today</> : <>{date}</>;
            }
          },
          {
            title: 'Type',
            field: 'type',
            cellStyle: {
              whiteSpace: 'nowrap'
            },
            render: rowData => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {rowData.isParent ? (
                    <div
                      style={{
                        width: '8px',
                        height: '14px',
                        borderRadius: '2px',
                        padding: '1px 2px',
                        backgroundColor: 'rgba(82, 103, 255, 1)',
                        color: 'white',
                        marginRight: '4px',
                        lineHeight: '15px'
                      }}
                    >
                      P
                    </div>
                  ) : rowData.isChild ? (
                    <div
                      style={{
                        width: '8px',
                        height: '14px',
                        border: '0.5px solid #C4C4C4',
                        color: 'black',
                        padding: '1px 2px',
                        marginRight: '4px',
                        lineHeight: '15px',
                        borderRadius: '2px'
                      }}
                    >
                      C
                    </div>
                  ) : null}
                  <>{capitalize(rowData?.type)}</>
                </div>
              );
            }
          },
          {
            title: 'Bed',
            field: 'bedRooms',
            cellStyle: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'Bath',
            field: 'bathRooms',
            cellStyle: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'unit',
            field: 'unitName',
            cellStyle: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'Floor Plan',
            field: 'meta[floorPlanName]',
            cellStyle: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'Lease Term',
            field: 'leaseData[leaseTerm]',
            cellStyle: {
              whiteSpace: 'nowrap'
            },
            render: rowData => (
              <>{rowData ? rowData?.leaseData?.leaseTerm : 12} month</>
            )
          },
          {
            title: 'Area',
            field: 'sqft',
            cellStyle: {
              whiteSpace: 'nowrap'
            },
            render: rowData => <>{rowData.sqft ? rowData.sqft : '0'} sqft</>
          }
        ]}
        actions={[
          {
            icon: 'save',
            tooltip: 'Save User'
          }
        ]}
        options={{
          search: false,
          paging: false,
          actionsColumnIndex: -1,
          cellStyle: {
            fontSize: '12px',
            padding: '6px 10px'
          },
          rowStyle: {
            backgroundColor: theme?.background?.secondary,
            fontSize: '12px',
            position: 'relative',
            color: theme?.text?.primary
          },
          headerStyle: {
            backgroundColor: theme?.state === 'dark' ? '#353642' : '#EFF0FF',
            color: theme?.text?.primary,
            whiteSpace: 'nowrap',
            fontSize: '12px',
            padding: '6px 10px'
          },
          maxBodyHeight: '260px',
          sorting: true
        }}
        data={editableData?.length ? editableData : editable}
        icons={{
          SortArrow: SortIcon
        }}
        onRowClick={handleRowClick}
        components={{
          Toolbar: props => (
            <div className={classes.toolbarWrapper}>
              <MTableToolbar {...props} />
            </div>
          ),
          Action: props =>
            showOverlay && showIndex === props.data.tableData.id ? (
              <div className="overlay " style={{ display: 'flex' }}>
                <CrossWithBg handleClick={e => handleCloseOverlay(e)} />
                <div className="flex">
                  <Button
                    id="btn-autofill"
                    onClick={e => handleAutofillClick(e, props)}
                    className="autofill-btn"
                  >
                    Autofill
                  </Button>
                </div>
              </div>
            ) : null,
          OverlayLoading: () => {
            return loading ? <Loader /> : null;
          },
          Row: props => <MTableBodyRow id="pna-row" {...props} />
        }}
      />
      <MapModal
        open={mapOpen}
        handleClose={handleMapClose}
        imageUrl={imageUrl}
      />
      <PriceCalcModal
        open={open}
        handleClose={handleCalcClose}
        priceData={priceData}
      />
    </React.Fragment>
  );
};

export default CustomPriceTable;
