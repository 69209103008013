import React, { useState } from 'react';
import Button from '../../../Component/Common/Button';
import TickIcon from '../../../Media/tick.svg';
import ErrorIcon from '../../../Media/error.svg';
import Checkbox from '@mui/material/Checkbox';
import styles from './styles.module.scss';

const Grid = ({
  header = 'Kormangala Koramangala',
  error,
  clickHandler,
  address,
  applySettingsData,
  apptId,
  removeOnboard,
  tag
}) => {
  const [checked, setChecked] = useState(false);
  const handleChange = e => {
    if (e) {
      e.stopPropagation();
    }
    setChecked(prev => {
      if (!prev) {
        applySettingsData(apptId);
      } else {
        removeOnboard(apptId);
      }
      return !prev;
    });
  };
  return (
    <div
      onClick={clickHandler}
      className={`communtity-grid ${styles.gridContainer}`}
    >
      <h6>{header || 'Kormangala Koram...'}</h6>
      <p>{address?.fullAdd || '138 Hyde street, Sano..'}</p>
      <div className={styles.footer}>
        <div className={styles.rightSection}>
          {error && (
            <div className={styles.status}>
              <img src={error ? ErrorIcon : TickIcon} alt="" />
            </div>
          )}
          {tag && <Button overRideClass={'greyButton'} label={'In Queue'} />}
        </div>
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          sx={{
            color: 'rgba(0, 0, 0, 0.6)',
            '&.Mui-checked': {
              color: '#5267FF'
            }
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={checked}
          onClick={handleChange}
        />
      </div>
      {!tag && <span className={styles.liveBox}>Live</span>}
    </div>
  );
};

export default Grid;
