import {
  GET_ALL_PROPERTY,
  FILTERED_DATA,
  PROPERTY_BY_ID,
  GET_PLACE_AUTOSUGGEST
} from '../Constant';

const initialState = {
  PropertyData: '',
  PropertyEditId: '',
  PropertyById: '',
  PropertyLoader: true,
  PlaceObj: '',
  PropObj: '',
  ApartmentList: null,
  BuildingData: ''
};
const Property = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTY:
      return { ...state, PropertyData: action.data, PropertyLoader: false };
    case FILTERED_DATA:
      return {
        ...state,
        PropertyData: action.data
      };
    case PROPERTY_BY_ID:
      return {
        ...state,
        PropertyById: action.data
      };
    case GET_PLACE_AUTOSUGGEST:
      return {
        ...state,
        PlaceObj: action.data
      };
    default:
      return state;
  }
};

export default Property;
