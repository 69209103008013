import React from 'react';

const EmailIconMsg = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4284 8.13826V15.7857C19.4284 16.5245 19.1349 17.2331 18.6125 17.7555C18.0901 18.2779 17.3815 18.5714 16.6427 18.5714H3.357C2.61819 18.5714 1.90963 18.2779 1.38721 17.7555C0.864783 17.2331 0.571289 16.5245 0.571289 15.7857V8.13826C0.571289 8.04998 0.576432 7.96169 0.58586 7.87512L9.65872 13.5451C9.74775 13.6008 9.84907 13.6338 9.95382 13.6413C10.0586 13.6488 10.1635 13.6305 10.2596 13.588L10.341 13.5451L19.4139 7.87512C19.4233 7.96169 19.4284 8.04998 19.4284 8.13826ZM10.353 0.891976L18.3673 6.1694C18.579 6.30826 18.7624 6.47798 18.9159 6.66998L9.99986 12.2414L1.08386 6.66998C1.23729 6.47798 1.42157 6.30826 1.63243 6.1694L9.64672 0.891976C9.75168 0.822851 9.87461 0.786011 10.0003 0.786011C10.126 0.786011 10.2489 0.822851 10.3539 0.891976H10.353Z"
        fill="url(#paint0_linear_4737_56774)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4737_56774"
          x1="0.571289"
          y1="0.786011"
          x2="21.7467"
          y2="15.1549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68BEFD" />
          <stop offset="1" stop-color="#0591F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmailIconMsg;
