import Tabs from '../../globalcomponents/Tabs';
import Tab from '../../globalcomponents/Tabs/Tab';
import Icon from '../../globalcomponents/Icon';
import { useTheme } from 'styled-components';
import Blink from '../Animations/Blink';
const LeadInfoOptions = props => {
  const { selectedOption, setSelectedOptions, isKelseyQueryListFilled } = props;

  const cardSelected = selectedOption === 'card';
  const unitSelected = selectedOption === 'unit';
  const cheatSheetSelected = selectedOption === 'cheatSheet';
  const aiSelected = selectedOption === 'ai';
  const kelseyFeedbackSelected = selectedOption === 'kelseyFeedback';
  const theme = useTheme();

  return (
    <div
      style={{ margin: '0px' }}
      className=" d-flex-jst-space-around align-middle"
    >
      <Tabs
        background={theme?.background?.secondary}
        padding={'0 8px'}
        borderRadius={'4px'}
      >
        <Tab
          color="#5267FF"
          onClick={() => {
            setSelectedOptions('All');
          }}
          active={selectedOption === 'All'}
          inactiveOpacity="1"
        >
          <Icon
            src={`all-${theme.state}.svg`}
            tooltipText="All"
            imgStyle={{ opacity: selectedOption !== 'All' && '0.5' }}
          />
        </Tab>
        <Tab
          color="#5267FF"
          onClick={() => {
            setSelectedOptions('card');
          }}
          active={cardSelected}
          inactiveOpacity="1"
        >
          <Icon
            src={`lead-details-${theme.state}.svg`}
            tooltipText="Lead Details"
            imgStyle={{ opacity: !cardSelected && '0.5' }}
          />
        </Tab>
        <Tab
          color="#5267FF"
          onClick={() => {
            setSelectedOptions('unit');
          }}
          active={unitSelected}
          inactiveOpacity="1"
        >
          <Icon
            src={`unit-preference-${theme.state}.svg`}
            tooltipText="Unit Details"
            imgStyle={{ opacity: !unitSelected && '0.5' }}
          />
        </Tab>
        <Tab
          color="#5267FF"
          onClick={() => {
            setSelectedOptions('cheatSheet');
          }}
          active={cheatSheetSelected}
          inactiveOpacity="1"
        >
          <Icon
            src={`cheatsheet-${theme.state}.svg`}
            tooltipText="Cheatsheet"
            imgStyle={{ opacity: !cheatSheetSelected && '0.5' }}
          />
        </Tab>
        <Tab
          color="#5267FF"
          onClick={() => {
            setSelectedOptions('kelseyFeedback');
          }}
          active={kelseyFeedbackSelected}
          inactiveOpacity="1"
        >
          <Icon
            src={`queries-${theme.state}.svg`}
            tooltipText="Kelsey Feedback"
            imgStyle={{ opacity: !kelseyFeedbackSelected && '0.5' }}
          />
          {!kelseyFeedbackSelected && isKelseyQueryListFilled && <Blink />}
        </Tab>
      </Tabs>
    </div>
  );
};
export default LeadInfoOptions;
