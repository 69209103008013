import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changeAgentApi,
  changeStageApi,
  confirmTourApi,
  createTourApi,
  createTourNotesApi,
  deleteTourApi,
  downloadApartmentCheatsheetApi,
  getAllPropertyApartmentApi,
  getApartmentsApi,
  getConversationSummaryApi,
  getInboxMetaData,
  getLeadMessageApi,
  getMasterCheatsheetByTenant,
  getNotesByTourIdApi,
  getPricingListings,
  getSessionData,
  getTemplateApi,
  getTimeSlotsApi,
  sendNewMessageApi,
  updateContextApi,
  updateSession
} from '../util/Apis/inbox';

export const fetchLeadMessage = createAsyncThunk(
  'inbox/fetchLeadMessage',
  async ({ leadId }, { rejectWithValue }) => {
    try {
      return await getLeadMessageApi({ leadId });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emptyMetaData = createAsyncThunk(
  'inbox/emptyMetaData',
  async (_args, { rejectWithValue }) => {
    return true;
  }
);
export const emptypriceListing = createAsyncThunk(
  'inbox/emptypriceListing',
  async (_args, { rejectWithValue }) => {
    return true;
  }
);

export const fetchPropertyByApartmentIdOffPlatform = createAsyncThunk(
  'inbox/fetchPropertyByApartmentIdOffPlatform',
  async ({ apartmentId }, { rejectWithValue }) => {
    try {
      return await getAllPropertyApartmentApi(apartmentId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchMetaData = createAsyncThunk(
  'inbox/fetchMetaData',
  async ({ leadId }, { rejectWithValue }) => {
    try {
      return await getInboxMetaData({ leadId });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendLeadMessage = createAsyncThunk(
  'inbox/sendLeadMessage',
  async ({ messageDetails, dispatch }, { rejectWithValue }) => {
    try {
      let resp = await sendNewMessageApi({ messageDetails });
      if (resp.status === 200) {
        dispatch(fetchLeadMessage({ leadId: messageDetails.leadId }));
      }
      return resp;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchTemplateData = createAsyncThunk(
  'inbox/fetchTemplateData',
  async ({ leadId, propId }, { rejectWithValue }) => {
    try {
      return await getTemplateApi({ leadId, propId });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchTimeSlots = createAsyncThunk(
  'inbox/fetchTimeSlots',
  async ({ communityId, leadId, tourType }, { rejectWithValue }) => {
    try {
      return await getTimeSlotsApi({ communityId, leadId, tourType });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchInboxMetaData = createAsyncThunk(
  'inbox/fetchInboxMetaData',
  async ({ leadId }, { rejectWithValue }) => {
    try {
      return await getInboxMetaData({ leadId });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createTour = createAsyncThunk(
  'inbox/createTour',
  async ({ data }, { rejectWithValue }) => {
    try {
      let resp = await createTourApi({ data });
      if (resp.status !== 200) {
        return;
        // return alert('error in creating the tour');
      } else {
        return resp;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getConversationSummary = createAsyncThunk(
  'inbox/getConversationSummary',
  async ({ leadId }, { rejectWithValue }) => {
    try {
      let resp = await getConversationSummaryApi({ leadId });
      if (resp.status !== 200) {
        return;
      } else {
        return resp;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteTour = createAsyncThunk(
  'inbox/deleteTour',
  async ({ tourId, cancelReason }, { rejectWithValue }) => {
    try {
      return await deleteTourApi({ tourId, cancelReason });
      // if(resp.status === 200){
      //     return alert("tour cancelled successfully")
      // }else {
      //     return alert("error in cancelling the appointment")
      // }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchTourConfirmation = createAsyncThunk(
  'inbox/fetchTourConfirmation',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await confirmTourApi({ id });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchApartments = createAsyncThunk(
  'inbox/fetchApartments',
  async (_args, { rejectWithValue }) => {
    try {
      return await getApartmentsApi();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createNewTourNotes = createAsyncThunk(
  'inbox/createTourNotes',
  async ({ notesPayload }, { rejectWithValue }) => {
    try {
      return await createTourNotesApi({ notesPayload });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotesByTourId = createAsyncThunk(
  'inbox/getNotesByTourId',
  async ({ notesPayload }, { rejectWithValue }) => {
    try {
      return await getNotesByTourIdApi({ id: notesPayload });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateStage = createAsyncThunk(
  'inbox/updateStage',
  async ({ stagePayload }, { rejectWithValue }) => {
    try {
      return await changeStageApi({ stagePayload });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const downloadApartmentCheatsheet = createAsyncThunk(
  'inbox/downloadApartmentCheatsheet',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await downloadApartmentCheatsheetApi({ id });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAgentDetails = createAsyncThunk(
  'inbox/updateAgentDetails',
  async ({ agentPayload }, { rejectWithValue }) => {
    try {
      return await changeAgentApi({ agentPayload });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchPriceListings = createAsyncThunk(
  'inbox/fetchPriceListings',
  async ({ apartmentPayload }, { rejectWithValue }) => {
    try {
      return await getPricingListings({ apartmentPayload });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchSessionData = createAsyncThunk(
  'inbox/fetchSessionData',
  async ({ leadId }, { rejectWithValue }) => {
    try {
      return await getSessionData(leadId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSessionData = createAsyncThunk(
  'inbox/updateSessionData',

  async ({ data }, { rejectWithValue }) => {
    try {
      return await updateSession(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContextData = createAsyncThunk(
  'inbox/updateContextData',
  async ({ data }, { rejectWithValue }) => {
    try {
      return await updateContextApi(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMasterCheatsheet = createAsyncThunk(
  'inbox/fetchMasterCheatsheet',
  async ({ tenantId }, { rejectWithValue }) => {
    try {
      return await getMasterCheatsheetByTenant(tenantId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
