import React, { useEffect, useState } from 'react';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import SliderValue from '../../../globalcomponents/SliderValue';
import SuccessIcon from '../../../globalcomponents/SliderValue/SuccessIcon';
import Badge from '../../../globalcomponents/Badge';
import Announcement from '../../../Media/InboxV1/Announcement';
import SopIcon from '../../../Media/InboxV1/SopIcon';
import {
  AnnouncementsListContainer,
  BadgeHolder,
  SOPCardHeader,
  SOPInstructions,
  SliderSuccessContaniner,
  Timeline,
  TimelineMilestone
} from './sop.styles';
import { getDateWithoutYear } from '../../../utils';
import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';
import OfficeBuilding from '../../../Media/InboxV1/OfficeBuilding';
import { useTheme } from 'styled-components';

const NotificationPopup = ({ selectedLeadId, apartmentId }) => {
  const {
    agentAnnouncements,
    toggleAnnouncementsModalVisibility,
    showAnnouncementsModal,
    sopAcknowledgementByAgent,
    storeAnnouncementsByCommunity,
    fetchAgentAnnouncements
  } = useInboxContext();
  const [currStep, setCurrStep] = useState(0);
  const [value, setValue] = useState('1');
  const [announcementsByCommunity, setAnnouncementsByCommunity] = useState([]);
  const [classList, setClassList] = useState('');

  const theme = useTheme();

  const handleValueChange = value => {
    if (value) {
      setValue(value);
    }
    if (value == '100') {
      setCurrStep(1);
      sopAcknowledgementByAgent();
      setTimeout(() => {
        setClassList('bottom-fade-out');
      }, 1000);

      setTimeout(() => {
        toggleAnnouncementsModalVisibility(false);
        storeAnnouncementsByCommunity([]);
      }, 1500);
    }
  };

  useEffect(() => {
    toggleAnnouncementsModalVisibility(false);
    setAnnouncementsByCommunity([]);
    setCurrStep(0);
    setValue('1');
    setClassList('');
    fetchAgentAnnouncements();
  }, [selectedLeadId]);

  useEffect(() => {
    const announcementsByApartment = agentAnnouncements?.filter(item =>
      item.apartments?.some(apt => apt._id === apartmentId)
    );
    setAnnouncementsByCommunity(announcementsByApartment);
    if (announcementsByApartment.length > 0) {
      toggleAnnouncementsModalVisibility(true);
      setClassList('bottom-fade-out');
      storeAnnouncementsByCommunity(
        announcementsByApartment.map(item => item._id)
      );
    }
  }, [apartmentId]);

  return (
    <div className={classList}>
      {showAnnouncementsModal && (
        <Popup
          width={'300px'}
          maxHeight={'400px'}
          overlayRadius={'6px'}
          overlayColor="rgba(0, 0, 0, 0.25)"
          overlayPosition="absolute"
          borderRadius="12px"
        >
          <PopupTitle borderRadius="12px 12px 0 0" showCloseBtn={false}>
            New Notifications
          </PopupTitle>
          <PopupBody>
            <AnnouncementsListContainer>
              {announcementsByCommunity?.map((item, idx) => {
                const messageHtmlContent = { __html: item?.message };
                const allCommunities = item?.isForAllCommunities;
                return (
                  <div key={`sop-notification-${item?._id}`}>
                    {idx !== announcementsByCommunity?.length - 1 && (
                      <Timeline />
                    )}
                    <SOPCardHeader>
                      <BadgeHolder>
                        <TimelineMilestone />
                        <Badge
                          background={'#BA7508'}
                          borderRadius={'36px'}
                          maxWidth={'75px'}
                          items={[item?.name]}
                          color={'#FFFFFF'}
                        >
                          {item?.name}
                        </Badge>
                        <Badge
                          color={'#BA7508'}
                          background={'rgba(186, 117, 8, 0.10)'}
                          borderRadius={'36px'}
                          items={
                            allCommunities
                              ? ['All communities']
                              : item?.apartments?.map(apt => apt.name)
                          }
                          endAdornment={<OfficeBuilding color="#BA7508" />}
                        >
                          {allCommunities ? 'All' : item?.apartments?.length}
                        </Badge>
                        {item?.category === 'announcement' && (
                          <Badge
                            endAdornment={<Announcement />}
                            padding="0px"
                            items={['Announcement']}
                          />
                        )}
                        {item?.category === 'sop' && (
                          <Badge
                            endAdornment={<SopIcon />}
                            padding="0px"
                            items={['SOP']}
                          />
                        )}
                      </BadgeHolder>
                      <Badge color={theme?.text?.primary}>
                        {getDateWithoutYear(item?.updatedAt)}
                      </Badge>
                    </SOPCardHeader>
                    <SOPInstructions
                      padding={'0 0 0 20px'}
                      dangerouslySetInnerHTML={messageHtmlContent}
                    />
                  </div>
                );
              })}
            </AnnouncementsListContainer>
          </PopupBody>
          <PopupFooter padding="0.625rem 1.25rem">
            {currStep === 0 ? (
              <SliderValue
                value={value}
                setValue={handleValueChange}
                textOnSlider="Slide to acknowledge"
                sliderIcon="https://assets.getzuma.com/images/AnnouncementForSlider.svg"
                background="linear-gradient(146deg, rgba(165, 96, 3, 0.39) 0%, rgba(250, 250, 250, 0.00) 100%)"
                color="42526E"
              />
            ) : null}
            {currStep === 1 ? (
              <SliderSuccessContaniner>
                <SuccessIcon
                  handleValueChange={handleValueChange}
                  setCurrStep={setCurrStep}
                  background={'#A5600333'}
                />
              </SliderSuccessContaniner>
            ) : null}
          </PopupFooter>
        </Popup>
      )}
    </div>
  );
};

export default NotificationPopup;
