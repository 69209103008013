import { gql } from '@apollo/client';

export const markLeadQueryAsAnswered = gql`
  mutation markLeadMissedQueryAsAnswered(
    $queryId: MongoID!
    $agentName: String!
    $agentId: String
  ) {
    markLeadMissedQueryAsAnswered(
      queryId: $queryId
      agentName: $agentName
      agentId: $agentId
    ) {
      _id
      query
      messageId
      tenantId
      aptId
      kelseyTool
      status
      hasAgentAnswered
      hasKelseyAnswered
      createdAt
      updatedAt
    }
  }
`;
