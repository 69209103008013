import React from 'react';
import Icon from '../../../globalcomponents/Icon';
import {
  CreatedByContainer,
  EventContainer,
  EventHeader,
  NoteBody,
  NoteContainer,
  NoteTitle,
  SpecialEventComponent
} from './styles.chatcomponents';
import moment from 'moment';
import { SenderInitial } from '../styles.inboxv2';
import SyncIcon from '../../../Media/InboxV1/SyncIcon';

const SpecialEvent = ({ props }) => {
  const compProps = props.props;
  const meta = compProps?.meta;
  const timeStamp = compProps?.timeStamp;
  const body = compProps?.body;
  let notesBody = body;
  let eventHeader = '';
  let iconName = '';
  let iconSize = '20px';
  switch (compProps.eventType) {
    case 'waitlist-lead-event':
      notesBody = body.split(',');
      let waitlistTill = notesBody?.shift();
      notesBody = notesBody.join(',');
      eventHeader = `On Waitlist till ${moment(waitlistTill)
        .local()
        .format('DD-MMM-YYYY, HH:mm A')}`;
      iconName = 'event-hourglass';
      iconSize = '20px';
      break;
    case 'notes-event':
      eventHeader = 'Notes';
      iconName = 'event-notes';
      iconSize = '16px';
      break;
    case 'tour-notes-event':
      eventHeader = 'Tour Notes';
      iconName = 'event-tour-notes';
      break;
    case 'outgoing-call-notes-event':
      eventHeader = 'Call Log Notes';
      iconName = 'event-call-log';
      break;
    default:
      eventHeader = 'Notes';
      iconName = 'event-notes';
      iconSize = '16px';
      break;
  }

  return (
    <SpecialEventComponent>
      <EventContainer isSynced={meta?.sync?.[0]?.syncStatus === 'Success'}>
        <EventHeader type="specialEvent">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            <Icon src={`${iconName}.svg`} imgStyle={{ width: iconSize }} />
            <div>{eventHeader}</div>
          </div>

          {meta?.sync &&
          meta?.sync?.length &&
          meta?.sync[0].syncStatus === 'Success' ? (
            <div className="nunito-10px-400" style={{ padding: '5px 0' }}>
              <Icon src="sync-icon.svg" />
            </div>
          ) : null}
        </EventHeader>

        <NoteContainer>
          <NoteTitle>Note:</NoteTitle>
          <NoteBody>{notesBody}</NoteBody>
        </NoteContainer>
      </EventContainer>

      <CreatedByContainer>
        <div style={{ padding: '5px 0' }}>
          {moment(timeStamp).format('MMM DD, YYYY hh:mm a')}
        </div>
        {meta?.sync &&
        meta?.sync?.length &&
        meta?.sync[0].syncStatus === 'Success' ? (
          <div style={{ padding: '5px 0', display: 'flex' }}>
            <SyncIcon /> Sync'd w {meta?.sync[0].syncSource}
          </div>
        ) : null}
      </CreatedByContainer>
    </SpecialEventComponent>
  );
};

const SpecialEvents = props => {
  const { type, customerName, meta } = props.props;
  return (
    <>
      <SpecialEvent props={props} />
      <SenderInitial>
        {type === 'incoming'
          ? customerName
            ? customerName[0]
            : 'C'
          : meta?.agentName
          ? meta?.agentName[0]
          : 'K'}
      </SenderInitial>
    </>
  );
};

export default SpecialEvents;
