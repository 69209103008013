import React from 'react';
import {
  LabelWrapper,
  TextLabelComponentWrapper,
  ValueWrapper
} from './styles.TextLabelComponent';

const TextLabelComponent = ({ label, value }) => {
  return (
    <TextLabelComponentWrapper>
      <LabelWrapper>{label}&nbsp;:&nbsp;</LabelWrapper>
      <ValueWrapper>{value}</ValueWrapper>
    </TextLabelComponentWrapper>
  );
};

export default TextLabelComponent;
