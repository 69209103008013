import { GET_TOAST } from '../Constant';

const ToastClear = data => ({
  type: GET_TOAST,
  data: data
});
const GetToast = data => ({
  type: GET_TOAST,
  data: data
});

export const ToastFireAction = (tost, severity, msg) => dispatch => {
  let data = {
    tost: tost,
    severity: severity,
    msg: msg
  };

  dispatch(GetToast(data));
};
export const ToastClearAction = () => dispatch => {
  let data = {
    tost: false,
    severity: '',
    msg: ''
  };
  dispatch(ToastClear(data));
};
