import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Popup from '../../../globalcomponents/Popup';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import SliderValue from '../../../globalcomponents/SliderValue';
import { DeleteInfo } from '../../LeadWaitList/LeadWaitList.styles';
import { deleteSource } from '../../../gql/mutations/sourceApartment';
import { fetchApartmentSourcesById } from '../../../gql/queries/apartmentSources';
import { UploadContainer } from '../styles.callToText';
import Loading from './uploading.gif';

const DeletePopup = ({
  show = false,
  handleClose,
  editData,
  aptId,
  filePopupData,
  handleFileUpload,
  fileUploadStates,
  width = '400px',
  isDeleteVanityNumber,
  deleteVaityNumberCallback
}) => {
  const [value, setValue] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const handleValueChange = value => {
    setValue(value);
  };
  const [deleteSourceById] = useMutation(deleteSource, {
    onCompleted: () => {
      setIsDeleted(true);
      setValue(0);
    }
  });

  useEffect(() => {
    if (value == 100) {
      filePopupData
        ? handleFileUpload()
        : isDeleteVanityNumber
        ? deleteVaityNumberCallback()
        : deleteSourceById({
            variables: {
              source: editData?.source,
              aptId
            },
            refetchQueries: [
              {
                query: fetchApartmentSourcesById,
                variables: { apartmentId: aptId }
              }
            ]
          });
    }
  }, [value]);

  return show ? (
    <Popup
      show={show}
      width={filePopupData?.name ? '300px' : width}
      zIndex={1200}
      onClose={() => {
        handleClose();
        setValue(0);
      }}
    >
      <PopupTitle onClose={handleClose} showCloseBtn>
        &nbsp;
      </PopupTitle>
      <PopupBody padding={'20px'}>
        {isDeleted ? (
          <DeleteInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <img src="/deleteWaitConfirm.png" />
            </div>
            <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
              {' '}
              {deleteVaityNumberCallback
                ? 'Vanity Number Deleted!'
                : 'Source Deleted!'}
            </div>
          </DeleteInfo>
        ) : (
          <>
            <DeleteInfo>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  alignItems: 'center'
                }}
              >
                <img
                  width="auto"
                  src={
                    !fileUploadStates?.isSuccess
                      ? 'https://assets.getzuma.com/images/delete-warning.svg'
                      : '/tick2.svg'
                  }
                />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  color: fileUploadStates?.isSuccess ? '#000' : '#D83A52',
                  fontSize: '14px',
                  fontWeight: 400
                }}
              >
                {filePopupData ? (
                  <>
                    {!fileUploadStates?.isSuccess ? (
                      <>
                        {' '}
                        <div style={{ color: '#000', paddingBottom: '10px' }}>
                          File Name: {filePopupData?.name}
                        </div>{' '}
                        Ensure the accuracy of source and SSC numbers before
                        bulk uploading
                      </>
                    ) : (
                      <>SSC numbers updated successfully</>
                    )}
                  </>
                ) : deleteVaityNumberCallback ? (
                  'Disclaimer: Once deleted, you may not able to purchase the same number.'
                ) : (
                  'Source deletion warning'
                )}
              </div>
            </DeleteInfo>
            {fileUploadStates?.isLoading ? (
              <UploadContainer>
                <img
                  width={'100%'}
                  height={'100%'}
                  src={Loading}
                  alt="loadingText"
                />
              </UploadContainer>
            ) : fileUploadStates?.isSuccess ? (
              <UploadContainer>
                <img src="/successIcon.svg" alt="successIcon" />
              </UploadContainer>
            ) : (
              <SliderValue
                value={value}
                setValue={handleValueChange}
                textOnSlider="Slide to confirm"
                sliderIcon="https://assets.getzuma.com/images/RightArrow.svg"
                disabled={false}
                sliderFontSize="14px"
              />
            )}
          </>
        )}
      </PopupBody>
    </Popup>
  ) : (
    <></>
  );
};

export default DeletePopup;
