import React from 'react';
import { IconWrapper } from './icon.styles';
import { Tooltip } from '@mui/material';

const Icon = ({
  id = '',
  size = '24px',
  src = '',
  tooltipText = '',
  onClick,
  alt = 'icon-zuma',
  imgStyle = {
    margin: 0
  },
  wrapperStyle = {}
}) => {
  const zumaS3Url = process.env.REACT_APP_ZUMA_ASSETS;
  return (
    <Tooltip title={tooltipText}>
      <IconWrapper id={id} size={size} onClick={onClick} style={wrapperStyle}>
        <img src={`${zumaS3Url}/${src}`} alt={alt} style={imgStyle} />
      </IconWrapper>
    </Tooltip>
  );
};

export default Icon;
