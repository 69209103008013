import React from 'react';
import { Message, MessageBody } from './styles.chatcomponents';

const MessageComponent = ({ type, body, media }) => {
  const htmlContent = { __html: body };
  return (
    <MessageBody type={type}>
      {media?.length ? <img width="100%" src={media[0]} /> : null}
      <Message dangerouslySetInnerHTML={htmlContent} type={type} />
    </MessageBody>
  );
};

export default MessageComponent;
