export const convertArrayToObj = array => {
  let res = {};
  for (let i = 0; i < array.length; i++) {
    res[array[i].slotNumber] = array[i];
  }

  return res;
};
const slotsMapping = {
  slot_1: 'Slot 1',
  slot_2: 'Slot 2',
  slot_3: 'Slot 3',
  slot_4: 'Slot 4',
  slot_5: 'Slot 5',
  slot_6: 'Slot 6',
  slot_7: 'Slot 7',
  slot_8: 'Slot 8',
  slot_9: 'Slot 9'
};

const audioMapping = {
  AUDIO: 'audio',
  FORWARDING_NUMBER: 'number',
  CALL_TO_TEXT: 'call-text',
  NONE: 'NA',
  LEAD_INGESTION: 'LEAD_INGESTION'
};
export const convertSlotsUseable = slotsData => {
  let res = {};
  slotsData?.forEach(({ name, enabled, forwardTo, action, description }) => {
    res[slotsMapping[name]] = {
      checked: enabled,
      kelseyText: description,
      type: audioMapping[action],
      audioValue: action === 'AUDIO' ? forwardTo : '',
      numberValue: action === 'FORWARDING_NUMBER' ? forwardTo : ''
    };
  });
  return res;
};

export const convertObjToArr = obj => {
  let arr = [];
  for (let i in obj) {
    if (i) {
      obj[i].slotNumber = i;
      obj[i].callNumber = i.split(' ')[1];
      arr.push(obj[i]);
    }
  }

  return arr;
};

export const callToTextSelected = slotsData => {
  for (let i in slotsData) {
    if (slotsData[i].type === 'call-text') {
      return true;
    }
  }
  return false;
};
