import { gql } from '@apollo/client';

export const leadUpdateById = gql`
  mutation leadUpdateById($leadId: MongoID!, $record: leadsInput!) {
    leadUpdateById(_id: $leadId, record: $record) {
      record {
        _id
        name
        botTurningOffReason
        meta
        source
        leasingAgent {
          name
          username
        }
        callOptIn
        propertyId
        emailOptIn
        enableBot
        followupToggle
        isArchived
        closedReason
        textOptIn
        smsEnabled
        notes
        stage
        preference {
          isFurnished
          interest
          unit
          bedroom
          bathroom
          budgetMax
          budgetMin
          occupant
          monthlyRent
          moveIn
          pet
          deposit
          income
          creditScore
          leaseTerm
          petType
        }
        leasingAgent {
          name
          username
        }
        tenant {
          name
          _id
        }
        apartment {
          name
          _id
        }
      }
    }
  }
`;
