export const LEAD_STAGE_NAMES = {
  Inbound: 'Inbound',
  Contacted: 'Contacted',
  Tour_Scheduled: 'Tour Scheduled',
  Tour_Cancelled: 'Tour Cancelled',
  No_Show: 'Tour No Show',
  Tour_Completed: 'Tour Completed',
  Applied: 'Applied',
  Tour_No_Show: 'Tour No Show',
  Lost: 'Lost',
  Application_Filed: 'Application Filed',
  Application_Cancelled: 'Application Cancelled',
  Application_Completed: 'Application Completed',
  Application_Waiting: 'Application Waiting'
};

export const LEAD_STAGES_LIST = [
  {
    name: 'Inbound',
    value: 'Inbound'
  },
  {
    name: 'Contacted',
    value: 'Contacted'
  },
  {
    name: 'Tour Scheduled',
    value: 'Tour Scheduled'
  },
  {
    name: 'Tour Cancelled',
    value: 'Tour Cancelled'
  },
  {
    name: 'Tour Completed',
    value: 'Tour Completed'
  },
  {
    name: 'Applied',
    value: 'Applied'
  },
  {
    name: 'Lost',
    value: 'Lost'
  },
  {
    name: 'Tour No Show',
    value: 'Tour No Show'
  },
  { name: 'Application Filed', value: 'Application Filed' },
  { name: 'Application Cancelled', value: 'Application Cancelled' },
  { name: 'Application Waiting', value: 'Application Waiting' },
  { name: 'Application Completed', value: 'Application Completed' }
];

export const LEAD_STAGES_OPTIONS = [
  {
    label: 'Inbound',
    value: 'Inbound'
  },
  {
    label: 'Contacted',
    value: 'Contacted'
  },
  {
    label: 'Tour Scheduled',
    value: 'Tour_Scheduled'
  },
  {
    label: 'Tour Cancelled',
    value: 'Tour_Cancelled'
  },
  {
    label: 'Tour No Show',
    value: 'No_Show'
  },
  {
    label: 'Tour Completed',
    value: 'Tour_Completed'
  },
  {
    label: 'Applied',
    value: 'Applied'
  },
  {
    label: 'Lost',
    value: 'Lost'
  },
  {
    name: 'Tour No Show',
    value: 'Tour_No_Show'
  },
  { label: 'Application Filed', value: 'Application_Filed' },
  { label: 'Application Cancelled', value: 'Application_Cancelled' },
  { label: 'Application Waiting', value: 'Application_Waiting' },
  { label: 'Application Completed', value: 'Application_Completed' }
];

export const NO_OF_OCCUPANTS = [
  {
    name: 'Studio',
    value: 0
  },
  {
    name: '1',
    value: 1
  },
  {
    name: '2',
    value: 2
  },
  {
    name: '3',
    value: 3
  },
  {
    name: '4',
    value: 4
  },
  {
    name: '5',
    value: 5
  }
];

export const BEDROOMS = [
  {
    name: 'Studio',
    value: 0
  },
  {
    name: '1',
    value: 1
  },
  {
    name: '2',
    value: 2
  },
  {
    name: '3',
    value: 3
  },
  {
    name: '4',
    value: 4
  },
  {
    name: '5',
    value: 5
  },
  {
    name: '6',
    value: 6
  },
  {
    name: '7',
    value: 7
  },
  {
    name: '8',
    value: 8
  },
  {
    name: '9',
    value: 9
  },
  {
    name: '10',
    value: 10
  },
  {
    name: '11',
    value: 11
  },
  {
    name: '12',
    value: 12
  }
];

export const PET_TYPES = [
  {
    name: 'N/A',
    value: 'N/A'
  },
  {
    name: 'Cat',
    value: 'Cat'
  },
  {
    name: 'Dog',
    value: 'Dog'
  },
  {
    name: 'Cat+Dog',
    value: 'Cat+Dog'
  },
  {
    name: 'Others',
    value: 'Others'
  }
];

export const LEASE_MONTHS = [
  {
    name: 'NA',
    value: 0
  },
  {
    name: '3 Months',
    value: 3
  },
  {
    name: '6 Months',
    value: 6
  },
  {
    name: '9 Months',
    value: 9
  },
  {
    name: '12 Months',
    value: 12
  },
  {
    name: '24 Months',
    value: 24
  }
];

export const FURNISHED_TYPES = [
  {
    name: 'True',
    value: true
  },
  {
    name: 'False',
    value: false
  }
];

export const CALL_TYPES = [
  {
    name: 'Answered',
    value: 'ANSWERED'
  },
  {
    name: 'Missed Voicemail',
    value: 'MISSED_VOICEMAIL'
  },
  {
    name: 'Missed No Voicemail',
    value: 'MISSED_NO_VOICEMAIL'
  },
  {
    name: 'Hung Up',
    value: 'HUNG_UP'
  },
  {
    name: 'Terminated',
    value: 'TERMINATED'
  }
];
