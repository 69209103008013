import React from 'react';
import Layout from '../../Component/layout';
import Analytics from './Analytics';

const Dashboard = props => {
  return (
    <Layout history={props.history}>
      <Analytics />
    </Layout>
  );
};
export default Dashboard;
