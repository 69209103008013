import styled from 'styled-components';

const QAReviewContainer = styled.div`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  bottom: 0.625rem;
  width: 236px;
  background: #fafafa;
`;

const QAReviewWrapper = styled.div`
  position: relative;
`;

const ReviewCompletionCard = styled.div`
  background: linear-gradient(92.18deg, #647dee 0.11%, #7f53ac 101.87%);
  height: 12vh;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 6px 6px 15px 15px;
`;

const CompletionText = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #76fca6;
`;

const SubmitButton = styled.div`
  padding: 2px 6px;
  border: 0.5px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.3)};
  pointer-events: ${({ isEnabled }) => (isEnabled ? 'all' : 'none')};
  margin-bottom: 10px;
  cursor: pointer;
`;

const ReviewCompletionHeader = styled.div`
  padding: 8px 0.625rem 0.625rem 0.625rem;
  border-bottom: 0.5px solid #ffffff33;
  display: flex;
  justify-content: space-between;
`;

const QuestionsContainer = styled.div`
  position: absolute;
  top: 3.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 10px;
  width: 100%;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const EmptyQuestionContainer = styled.div`
  position: ${({ type }) =>
    type === 'not-selected' ? 'absolute' : 'relative'};
  height: ${({ type }) => (type === 'not-selected' ? '500px' : '100%')};
  top: ${({ type }) => (type === 'not-selected' ? '50%' : '0%')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyOuestionTextOne = styled.div`
  padding: 0.5rem 1rem;
  color: #42526e;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
`;

const EmptyOuestionTextTwo = styled.div`
  padding: 0.5rem 1rem;
  color: #5267ff;
  text-align: center;
  text-decoration: ${({ isClickable }) => (isClickable ? 'underline' : 'none')};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
`;

const QaBottomIconWrapper = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ClearTextClick = styled.div`
  cursor: pointer;
  position: relative;
  left: 0;
`;
const ArrowIconWrapper = styled.div``;
export {
  QAReviewWrapper,
  QAReviewContainer,
  ReviewCompletionCard,
  CompletionText,
  SubmitButton,
  ReviewCompletionHeader,
  QuestionsContainer,
  EmptyQuestionContainer,
  EmptyOuestionTextOne,
  EmptyOuestionTextTwo,
  QaBottomIconWrapper,
  ArrowIconWrapper,
  ClearTextClick
};
