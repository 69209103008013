import { getCookie } from '../../utils';
import {
  GET_ALL_BUILDING,
  GET_APARTMENT_AMENITIES,
  GET_BUILDING_BY_ID,
  GET_MASTER_CHEATSHEET,
  GET_TOUR_TYPES_BY_APARTMENT
} from '../Constant';
import { del, get, post, put } from '../networkClient';
import { ToastFireAction } from './Toast';

const getApartmentAmenities = data => ({
  type: GET_APARTMENT_AMENITIES,
  data: data
});
const getMasterCheatSheet = data => ({
  type: GET_MASTER_CHEATSHEET,
  data: data
});
const GetAllBuilding = data => ({
  type: GET_ALL_BUILDING,
  data: data
});

const BuildingById = data => ({
  type: GET_BUILDING_BY_ID,
  data: data
});

const SetApartmentTourTypes = data => ({
  type: GET_TOUR_TYPES_BY_APARTMENT,
  data: data
});

const authorization = getCookie('rb_access_token');

export const getApartmentAmenitiesAction = () => async dispatch => {
  await get('/apartment/amenities').then(resp => {
    if (resp.status === 200) {
      dispatch(getApartmentAmenities(resp.data));
    }
  });
};
export const getMasterCheatSheetAction = () => async dispatch => {
  await get('/apartment/cheat-sheet').then(resp => {
    if (resp.status === 200) {
      dispatch(getMasterCheatSheet(resp.data));
    }
  });
};
export const postApartmentAction = data => async dispatch => {
  await post('/apartment', data)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(ToastFireAction(true, 'success', 'Building Created'));
        dispatch(getAllBuildingActionByPagination(1));
      }
    })
    .catch(err =>
      dispatch(
        ToastFireAction(true, 'error', 'Error occured in building creation')
      )
    );
};
export const getAllBuildingAction = () => async dispatch => {
  await get('/apartment/all')
    .then(resp => {
      if (resp.status === 200) {
        dispatch(GetAllBuilding(resp.data));
      }
    })
    .catch(error => {});
};

export const getAllBuildingActionByPagination =
  (page = 1, tenantIds) =>
  async dispatch => {
    await post(`/apartment/allbypagination-new`, {
      page,
      filter: {
        tenantIds
      }
    })
      .then(resp => {
        if (resp.status === 200) {
          dispatch(GetAllBuilding(resp.data));
        }
      })
      .catch(error => {});
  };

export const BuildingByIdAction = id => async dispatch => {
  await get('/apartment', { id: id }).then(resp => {
    dispatch(BuildingById(resp.data));
  });
};
// deleteBUildingAction
export const deleteBuildingAction = Id => async dispatch => {
  await del('/apartment', null, Id)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(ToastFireAction(true, 'success', 'Building Deleted'));
        dispatch(getAllBuildingAction());
      }
    })
    .catch(err =>
      dispatch(
        ToastFireAction(true, 'error', 'Error occured in building deletion')
      )
    );
};
//updateBuildingAction

export const updateBuildingAction = BuildingData => async dispatch => {
  const body = BuildingData;
  await put(`/apartment/update`, body)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(ToastFireAction(true, 'success', 'Building Updated'));
        dispatch(getAllBuildingActionByPagination(1));
      }
    })
    .catch(error => {
      ToastFireAction(true, 'error', 'Error occurred in building Updation');
    });
};

export const callToTextUpdate = BuildingData => async dispatch => {
  const body = BuildingData;
  await put(`/apartment/update`, body)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(ToastFireAction(true, 'success', 'File Uploaded'));
        dispatch(BuildingByIdAction(body.id));
      }
    })
    .catch(error => {
      ToastFireAction(true, 'error', 'Error occurred in building Updation');
    });
};

export const PurchaseNumber = data => async dispatch => {
  await put('/apartment/phone-new', data)
    .then(resp => {
      if (resp.status === 200) {
        return dispatch(ToastFireAction(true, 'success', 'Phone No Updated'));
      }
    })
    .catch(error => {
      ToastFireAction(true, 'error', 'Error occured in Phone Update');
    });
};

//Community Specific Email actions
export const createCommunityEmail = data => async dispatch => {
  await post('/channel-account', data)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(
          ToastFireAction(
            true,
            'success',
            'Community Email created successfully'
          )
        );
        dispatch(BuildingByIdAction(data.communityId));
      }
    })
    .catch(err =>
      dispatch(
        ToastFireAction(true, 'error', 'Error while updating community email')
      )
    );
};

export const updateCommunityEmail = data => async dispatch => {
  await post('/channel-account/emailstatus', data)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(
          ToastFireAction(
            true,
            'success',
            'Community Email updated successfully'
          )
        );
      }
    })
    .catch(err =>
      dispatch(
        ToastFireAction(true, 'error', 'Error while updating community email')
      )
    );
};

export const deleteCommunityEmail = (data, params) => async dispatch => {
  await del('/channel-account', data, params)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(ToastFireAction(true, 'success', 'Community email deleted'));
        dispatch(BuildingByIdAction(data.communityId));
      }
    })
    .catch(err =>
      dispatch(ToastFireAction(true, 'error', 'Error while deleting email'))
    );
};

export const fetchAvailableTourTypesByApartment = id => async dispatch => {
  await get(
    `/tours/type/${id}`,
    {},
    {
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    true,
    true
  ).then(resp => {
    if (resp.status === 200) {
      const respondeData = resp?.data?.data;
      const { tourTypes } = respondeData || {};
      dispatch(SetApartmentTourTypes(tourTypes));
    } else {
      dispatch(
        ToastFireAction(true, 'error', 'Error while fetching tour types')
      );
      dispatch(SetApartmentTourTypes([]));
    }
  });
};
