import React from 'react';
import { DrawerFooterWrapper } from '../drawer.styles';

const DrawerFooter = ({
  padding,
  margin,
  background,
  border,
  borderRadius,
  children
}) => {
  return (
    <DrawerFooterWrapper
      padding={padding}
      margin={margin}
      background={background}
      border={border}
      borderRadius={borderRadius}
    >
      {children}
    </DrawerFooterWrapper>
  );
};

export default DrawerFooter;
