import { combineReducers } from 'redux';
import Accessibility from './Accessibility';
import Utility from './Utility';
import LeaseLength from './LeaseLength';
import PetPolicy from './PetPolicy';
import Amenities from './Amenities';
import Appliances from './Appliances';
import Property from './Property';
import FileUpload from './Fileupload';
import Filter from './Filter';
import Auth from './Auth';
import Calendar from './Calendar';
import Leads from './Leads';
import Toast from './Toast';
import Workflow from './Workflow';
import Inbox from './Inbox';
import GlobalSearch from './GlobalSearch';
import Admin from './Admin';
import Apartment from './Apartment';
import AgentProfile from './AgentProfile';
import workflowSlice from './workflowV1';
import InboxSlice from './InboxV1';
import SelfServeSlice from './SelfServe';
import callTextSlice from './callText';
import GlobalErrorSlice from './GlobalError';

export default combineReducers({
  Accessibility,
  Utility,
  LeaseLength,
  PetPolicy,
  Amenities,
  Appliances,
  Property,
  FileUpload,
  Filter,
  Auth,
  Calendar,
  Leads,
  Toast,
  Workflow,
  Inbox,
  GlobalSearch,
  Admin,
  Apartment,
  AgentProfile,
  workflowData: workflowSlice.reducer,
  slotsData: InboxSlice.reducer,
  inboxMessages: InboxSlice.reducer,
  callTextData: callTextSlice.reducer,
  SelfServe: SelfServeSlice.reducer,
  GlobalError: GlobalErrorSlice.reducer
});
