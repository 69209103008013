import { FILTER_TOGGLE, FILTERED_DATA } from '../Constant';
import { get } from '../networkClient';

const filterToggle = data => ({
  type: FILTER_TOGGLE,
  data: data
});
const GetFilteredProperty = data => ({
  type: FILTERED_DATA,
  data: data
});

export const filterToggleAction = data => async dispatch => {
  dispatch(filterToggle(data));
};

export const GetFilteredPropertyAction = data => async dispatch => {
  await get('/property/filter', data).then(resp => {
    dispatch(GetFilteredProperty(resp.data));
  });
};
