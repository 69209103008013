import styled from 'styled-components';

const SendButtonWrapper = styled.div`
  display: inline-flex;
  padding: 2px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: #5267ff;
  position: absolute;
  right: 0px;
  bottom: 13px;
  height: 28px;
  width: 70px;
  margin-right: 20px;
  justify-content: center;
`;
const VerticalDivider = styled.div`
  height: 20px;
  width: 0.5px;
  background: #ffffff;
`;
const Timezone = styled.div`
  color: rgba(57, 57, 57, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px 20px;
`;

export { SendButtonWrapper, VerticalDivider, Timezone };
