import { gql } from '@apollo/client';

export const getUserData = gql`
  query getUser {
    getUser {
      _id
      name
      uid
      mightyCall
      email
      status
      role {
        _id
        name
        permissions {
          _id
          name
        }
      }
      clients {
        _id
        name
        isIntegrationConfigured
      }
      trainedClients {
        _id
        name
        isIntegrationConfigured
      }
      assignedClients {
        _id
        name
        isIntegrationConfigured
      }
      userAccess {
        clients {
          trained {
            _id
            name
          }
          assigned {
            _id
            name
          }
        }
      }
    }
  }
`;

export const getAllUsers = gql`
  query getAllUsers($uid: String!) {
    getAllUsers(uid: $uid) {
      name
      email
      mightyCall
      uid
      _id
      role {
        name
      }
      userAccess {
        clients {
          trained {
            _id
            name
          }
          assigned {
            _id
            name
          }
        }
      }
    }
  }
`;

export const getUserNotes = gql`
  query getUser {
    getUser {
      _id
      notes
    }
  }
`;

export const getUserStatus = gql`
  query getUser {
    getUser {
      _id
      status
    }
  }
`;
