import React from 'react';

const AddSmall = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="13.3333"
        height="13.3333"
        transform="translate(0.333374 0.333496)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55556 6.44445V2.55167C7.55497 2.40486 7.49613 2.2643 7.39195 2.16086C7.28778 2.05742 7.14681 1.99956 7 2C6.69111 2 6.44445 2.24722 6.44445 2.55167V6.44445H2.55167C2.40486 6.44503 2.2643 6.50388 2.16086 6.60805C2.05742 6.71222 1.99956 6.8532 2 7C2 7.30945 2.24722 7.55556 2.55167 7.55556H6.44445V11.4483C6.44445 11.7528 6.69334 12 7 12C7.30889 12 7.55556 11.7528 7.55556 11.4483V7.55556H11.4483C11.5951 7.55497 11.7357 7.49613 11.8391 7.39195C11.9426 7.28778 12.0004 7.14681 12 7C12 6.69111 11.7528 6.44445 11.4483 6.44445H7.55556Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default AddSmall;
