import React from 'react';

const TrashIcon = ({ className, handleClick }) => {
  return (
    <svg
      id="icon-trash"
      onClick={handleClick}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={'pointer'}
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <path
        d="M10.7667 13.3333C10.9244 13.3334 11.077 13.2776 11.1973 13.1758C11.3177 13.074 11.398 12.9328 11.4241 12.7773L12.5833 5.83329H12.0764M10.7667 13.3333H5.23008M10.7667 13.3333V12.8333H5.23023L5.23008 13.3333M10.7667 13.3333L10.7671 12.8333C10.8064 12.8333 10.8444 12.8194 10.8744 12.794C10.9044 12.7687 10.9245 12.7335 10.9309 12.6947M12.0764 5.83329L12.1598 5.33329H3.8403L3.92355 5.83329M12.0764 5.83329L10.9309 12.6947M12.0764 5.83329H3.92355M3.92355 5.83329H3.41667L4.57275 12.7766M3.92355 5.83329L5.06589 12.6941L4.57275 12.7766M4.57275 12.7766C4.59877 12.9322 4.67909 13.0735 4.79944 13.1754C4.91979 13.2773 5.07237 13.3332 5.23008 13.3333M4.57275 12.7766L5.06596 12.6945L5.06592 12.6943C5.07246 12.7331 5.09252 12.7684 5.12257 12.7938C5.15262 12.8193 5.19071 12.8332 5.23008 12.8333V13.3333M10.9309 12.6947L10.9309 12.695L11.4239 12.7773L10.9309 12.6947ZM10.0001 3.83329H10.5001H12.6667C12.7109 3.83329 12.7533 3.85085 12.7846 3.88211C12.8159 3.91336 12.8334 3.95576 12.8334 3.99996V4.16663H3.16675V3.99996C3.16675 3.95576 3.18431 3.91336 3.21556 3.88211C3.24682 3.85085 3.28921 3.83329 3.33341 3.83329H5.50008H6.00008H10.0001Z"
        stroke="#525F80"
      />
    </svg>
  );
};

export default TrashIcon;
