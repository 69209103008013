import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TimezoneSelect from 'react-timezone-select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@mui/icons-material/Save';
import Layout from '../../Component/layout';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { GetPlacesAction } from '../../Redux/Actions/Property';
import CircularProgress from '@material-ui/core/CircularProgress';
import BuildingImgUpload from './buildingImgUpload';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useLazyQuery } from '@apollo/client';

import {
  ClearFilesfromStore,
  fileUploadApartAction
} from '../../Redux/Actions/FileUpload';
import { useParams } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
  getApartmentAmenitiesAction,
  getMasterCheatSheetAction,
  postApartmentAction,
  updateBuildingAction
} from '../../Redux/Actions/Apartment';
import {
  BlockHeading,
  closeIconStyle,
  Commonst,
  HeaderStyle,
  NoteStyle,
  SaveMoreStyle,
  SavePropertyStyle,
  typoGraph
} from '../../styles/propertystyles/Buildingstyle';
import OfficeHours from './OfficeHours';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './BuildingAdd.css';
import _ from 'lodash';
import { TextField } from '@mui/material';
import CallToTextV1 from './CallToTextV1';
import { ALL_TIME_ZONES } from './Constants';
import { getTenantDetails, getUserData } from '../../Redux/Actions/SelfServe';
import UploadCheatSheet from './UploadCheatSheet';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import { getNotificationUsers } from '../../gql/queries/propertyInfo';
import LinkIcon from '../../Media/linkOut.svg';
import { getCookie } from '../../utils';
import CallToTextComponent from '../../Component/CallToTextComponent';
import {
  fetchApartmentById,
  fetchApartmentByIdC2T
} from '../../gql/queries/apartmentById';
import { getTenantById } from '../../gql/queries/tenantDetails';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: '2px',
    background: '#043e8a',
    color: '#fff'
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));
function getStyles(name, notificationAgents, theme) {
  return {
    fontWeight:
      notificationAgents.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const BuildingAdd = props => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [BuildingName, setBuildingName] = useState(null);
  const [Units, setUnits] = useState(null);
  const [Manager, setManager] = useState(null);
  const [phone, setPhone] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [duration, setduration] = useState();
  const [paddingBefore, setpaddingBefore] = useState();
  const [paddingAfter, setpaddingAfter] = useState();
  const [PropertyLink, setPropertyLink] = useState(null);
  const [OfficeDirection, setOfficeDirection] = useState(null);
  // const [PetAllowed, setPetAllowed] = useState(true);
  // const [allowRegAnimals, setallowRegAnimals] = useState(false);
  // const [petsPolicy, setpetsPolicy] = useState([]);
  // const [showText, setshowText] = useState(false);
  const [PropertyAddress, setPropertyAddress] = useState(null);
  const [PropertyEmailAddress, setPropertyEmailAddress] = useState(null);
  const [placeId, setplaceId] = useState('');
  const [Active, setActive] = useState([]);
  const [Custom, setCustom] = useState([]);
  const [SelectedBTimezone, setSelectedBTimezone] = useState({});
  const [overview, setoverview] = useState('');
  const [utilitiesOverview, setutilitiesOverview] = useState('');
  const [petsOverview, setpetsOverview] = useState('');
  const [tempFile, settempFile] = useState([]);
  const [Validator, setValidator] = useState(false);
  const [Message, setMessage] = useState(false);
  const [heroImage, setheroImage] = useState();
  const [applicationDirections, setapplicationDirections] = useState('');
  const [notificationAgents, setnotificationAgents] = React.useState([]);
  const [AreaCode, setAreaCode] = useState('');
  const [phnNumb, setphnNumb] = useState('');
  const [voiceType, setVoiceType] = useState('');
  const [text, setText] = useState(null);
  const [urlName, setUrlName] = useState('');
  const [consultantPhone, setConsultantPhone] = useState('');
  const [residentPhone, setResidentPhone] = useState('');
  const [communityOnSiteNumber, setCommunityOnSiteNumber] = useState('');
  const [PlaceObj, setPlaceObj] = useState([
    { description: 'place suggetions' }
  ]);
  const [cheatSheet, setCheatSheet] = useState({});
  const [check1, setcheck1] = useState(false);
  const [knockCallToTextEnableStatus, setknockCallToTextEnableStatus] =
    useState(false);
  const [selectedPhoneNumber, setSelectedPhonenumber] = useState();
  const [enableCommunityBot, setEnableCommunityBot] = useState(true);

  // const [CustomPetResp, setCustomPetResp] = useState("");

  const users = useSelector(state => state?.SelfServe?.usersList);

  useEffect(() => {
    setUsersAdmin(users);
  }, [users]);

  const SelectStyle = {
    width: 200,
    borderRadius: 4,
    border: '1 solid #d2d2d2',
    marginLeft: 10
  };

  const mb = {
    marginBottom: 10,
    marginTop: 10
  };
  const mbC = {
    marginBottom: 16
  };
  const minWidth = {
    width: 400,
    wordBreak: 'break-word'
  };
  const [utility, setutility] = useState([
    {
      name: 'Heat',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Trash',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Water',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Gas',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Internet',
      status: 'Included',
      customResp: ''
    }
  ]);
  const [workingHours, setworkingHours] = useState([
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      status: 'closed',
      unavl: [],
      day: 0
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'open',
      day: 1
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'open',
      day: 2
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'open',
      day: 3
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'open',
      day: 4
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'open',
      day: 5
    },
    {
      avl: [
        {
          hoursStart: 0,
          minsStart: 0,
          hoursEnd: 0,
          minsEnd: 0
        }
      ],
      unavl: [],
      status: 'closed',
      day: 6
    }
  ]);
  const [check, setcheck] = useState([
    { sunday: false },
    { monday: true },
    { tuesday: true },
    { wednesday: true },
    { thrusday: true },
    { friday: true },
    { saturday: false }
  ]);
  const classes = useStyles();
  const theme = useTheme();
  const [UsersAdmin, setUsersAdmin] = useState([]);
  const data = useSelector(state => state.FileUpload.FilesApartment);
  // const apartmentData = useSelector(state => state.Apartment.ApartmentID);
  // const tenantData = apartmentData?.data?.tenantId;
  const adminData = useSelector(state => state.Admin);

  useEffect(() => {
    setUrlName(data?.name ? data?.name : '');
  });

  const { userData } = useContext(GlobalContext);

  useEffect(() => {}, [enableCommunityBot]);
  const FileUploadFunc = e => {
    const files = e.target.files;
    let fileList = Array.from(files);
    fileList.map(res => {
      dispatch(fileUploadApartAction(res));
      // dispatch(LoadingAction(true));
    });
  };

  const [custQuesEdit, setCustQuesEdit] = useState({});
  const [customQuestionsList, setcustomQuestionsList] = useState([]);
  const [cheatSheetList, setCheatSheetList] = useState([]);
  const [cheatsheetUpload, setCheatsheetUpload] = useState(false);

  const cheatsheetUploadHandler = () => {
    setCheatsheetUpload(prevState => !prevState);
  };

  const [fetchApartmentData, { data: { apartmentById = {} } = {} }] =
    useLazyQuery(fetchApartmentByIdC2T);

  useEffect(() => {
    id && fetchApartmentData({ variables: { apartmentId: id } });
  }, [id]);
  useEffect(() => {
    const params = userData?.userAccess?.clients?.trained?.[0]?._id;

    const aptData = apartmentById;
    setEnableCommunityBot(aptData?.enableBot);
    dispatch(getTenantDetails(params));
  }, [apartmentById]);

  const [entrataIVRAndCTT, setEntrataIVRAndCTT] = useState({});
  useEffect(() => {
    let chSheetList = [];
    let custQues = [];
    if (props.buildingType && props.buildingType === 'EDIT') {
      chSheetList = props?.PropertyObj?.data?.cheatSheet || [];
      custQues = props?.PropertyObj?.data?.customQuestions || [];
    } else {
      chSheetList = props.masterCheatSheet;
      custQues = [];
    }
    if (custQues.length > 0) {
      custQues.map((e, i) => {
        e['index'] = i;
      });
    }
    setCheatSheetList(chSheetList || []);
    setcustomQuestionsList(custQues || []);
    setcheck1(props?.PropertyObj?.data?.CallToTextEnabled);
  }, [
    props.buildingType,
    props.PropertyObj,
    props.masterCheatSheet,
    cheatsheetUpload
  ]);
  //functionBlock
  const closeButton = () => {
    props.history.push('/communities');
    dispatch(ClearFilesfromStore());
  };
  const placeHandleChange = event => {
    if (event.target.value.length >= 3) {
      dispatch(GetPlacesAction(event.target.value));
    }
  };
  const AutoCompHandleChange = value => {
    setPropertyAddress({ description: value.description });
    setplaceId(value.place_id);
  };

  // const handlepetsPolicy = (event) => {
  //   if (event.target.name === "other" && event.target.checked === true) {
  //     setshowText(true);
  //   } else {
  //     setshowText(false);
  //   }
  //   let Temp = petsPolicy;
  //   if (event.target.checked) {
  //     Temp = [...petsPolicy, event.target.name];
  //     setpetsPolicy(Temp);
  //   }
  //   if (!event.target.checked) {
  //     Temp = petsPolicy.filter((item) => item !== event.target.name);
  //     setpetsPolicy(Temp);
  //   }
  // };

  const handleChangeSelect = (value, id, canned) => {
    let temp = Active;
    let check = temp.some(item => item.type === id);
    if (check) {
      let newState = temp.map(obj => {
        if (value === '') {
          return obj.type === id ? { ...obj, available: value } : obj;
        } else {
          return obj.type === id
            ? { ...obj, available: value, cannedResp: canned[value].cannedResp }
            : obj;
        }
      });
      setActive(newState);
    } else {
      if (value !== '') {
        let newObj = {
          type: id,
          available: value,
          cannedResp: canned[value].cannedResp
        };
        setActive([...temp, newObj]);
      }
    }
  };
  const handleTextCustom = (value, id) => {
    let temp = Active;
    let check = temp.some(item => item.type === id);
    if (check) {
      let newState = temp.map(obj => {
        return obj.type === id ? { ...obj, customResp: value } : obj;
      });
      setActive(newState);
    } else {
      let newObj = {
        type: id,
        customResp: value
      };
      setActive([...temp, newObj]);
    }
  };
  const CheckFunction = (name, id) => {
    return (
      <Grid item xs={6} style={{ marginBottom: '-10px' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              labelStyle={{ color: 'white' }}
              checked={checkedAvl(id, name)}
              onChange={event => ExtraQOne(name, id, event.target.checked)}
            />
          }
          label={<Typography style={typoGraph}>{name}</Typography>}
        />
      </Grid>
    );
  };
  const ExtraQOne = (value, id, checked) => {
    let temp = Active;
    let idavl = temp.some(item => item.type === id);
    if (id !== 'outerSpace') {
      if (idavl) {
        let newObj;
        if (checked) {
          let objval = temp.map(obj => {
            if (obj.type === id) {
              if (obj.hasOwnProperty(id + 'Type')) {
                let valAray = obj[id + 'Type'];
                valAray.push(value);
                return { ...obj, [id + 'Type']: valAray };
              } else {
                return obj.type === id
                  ? { ...obj, [id + 'Type']: [value] }
                  : obj;
              }
            } else {
              return obj;
            }
          });
          newObj = objval;
        } else {
          let objval = temp.map(obj => {
            if (obj.type === id) {
              let valAray = obj[id + 'Type'];
              let remainVals = valAray.filter(item => item !== value);
              return { ...obj, [id + 'Type']: remainVals };
            } else {
              return obj;
            }
          });
          newObj = objval;
        }
        setActive(newObj);
      }
    }
    if (id === 'outerSpace') {
      if (idavl) {
        let newObj;
        if (checked) {
          let objval = temp.map(obj => {
            if (obj.type === id) {
              if (obj.hasOwnProperty('outdoorSpaces')) {
                let valAray = obj['outdoorSpaces'];
                valAray.push(value);
                return { ...obj, ['outdoorSpaces']: valAray };
              } else {
                return obj.type === id
                  ? { ...obj, ['outdoorSpaces']: [value] }
                  : obj;
              }
            } else {
              return obj;
            }
          });
          newObj = objval;
        } else {
          let objval = temp.map(obj => {
            if (obj.type === id) {
              let valAray = obj['outdoorSpaces'];
              let remainVals = valAray.filter(item => item !== value);
              return { ...obj, ['outdoorSpaces']: remainVals };
            } else {
              return obj;
            }
          });
          newObj = objval;
        }
        setActive(newObj);
      }
    }
  };
  const ExtraQTwo = (value, id) => {
    let temp = Active;
    let check = temp.some(item => item.type === id);
    if (check) {
      let newState = temp.map(obj => {
        return obj.type === id ? { ...obj, [id + 'AddlCost']: value } : obj;
      });
      setActive(newState);
    }
  };
  const handleCost = (value, id) => {
    let temp = Active;
    let check = temp.some(item => item.type === id);
    if (check) {
      let newState = temp.map(obj => {
        return obj.type === id ? { ...obj, [id + 'Cost']: value } : obj;
      });
      setActive(newState);
    }
  };
  const SetCustomAnswer = id => {
    handleChangeSelect('', id);
    let Check = Custom.includes(id);
    if (!Check) {
      setCustom([...Custom, id]);
    }
  };
  const RemoveCustomBlock = (id, type) => {
    let temp = Custom;
    const filteredItems = temp.filter(item => item !== id);
    setCustom(filteredItems);

    let temp1 = Active;
    let temp2 = temp1.map(res => {
      if (res.type === id) {
        delete res.customResp;
        return res;
      } else {
        return res;
      }
    });
    setActive(temp2);
  };
  const BtnComponent = () => {
    return (
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          style={SavePropertyStyle}
          onClick={() => SaveBuilding()}
        >
          Save Building
        </Button>
      </Grid>
    );
  };

  // For > EditBuilding > Functions
  const availableFunc = id => {
    if (Active.length > 0) {
      let available = Active.filter(obj => obj.type === id).map(obj =>
        obj.available !== undefined ? obj.available : ''
      );
      return available[0];
    }
  };
  const custAvl = id => {
    let custom = Active.filter(obj => obj.type === id).map(obj =>
      obj.customResp !== undefined ? obj.customResp : ''
    );
    return custom;
  };
  const checkedAvl = (id, name) => {
    if (id !== 'outerSpace') {
      let TempAry = Active.filter(obj => obj.type === id).map(obj =>
        obj[id + 'Type'] !== undefined ? obj[id + 'Type'] : ''
      );
      let checked = TempAry[0].includes(name);
      return checked;
    }
    if (id === 'outerSpace') {
      let TempAry = Active.filter(obj => obj.type === id).map(obj =>
        obj['outdoorSpaces'] !== undefined ? obj['outdoorSpaces'] : ''
      );
      let checked = TempAry[0].includes(name);
      return checked;
    }
  };
  const extraQ = id => {
    let resp = Active.filter(obj => obj.type === id).map(obj =>
      obj[id + 'AddlCost'] !== undefined ? obj[id + 'AddlCost'] : ''
    );
    return resp[0];
  };

  const Costavl = id => {
    let cost = Active.filter(obj => obj.type === id).map(obj =>
      obj[id + 'Cost'] !== undefined ? obj[id + 'Cost'] : ''
    );
    return cost[0];
  };
  const ChangeTimeZone = tz => {
    setSelectedBTimezone(tz);
  };
  //lifecycle
  useEffect(() => {
    if (props.PlaceObj.data && props.PlaceObj.data.predictions.length > 0) {
      setPlaceObj(props.PlaceObj && props.PlaceObj.data.predictions);
    }
  }, [props.PlaceObj]);
  // useEffect(() => {
  //   dispatch(getApartmentAmenitiesAction());
  //   dispatch(getMasterCheatSheetAction());
  // }, []);

  useEffect(() => {
    if (props.buildingType === 'EDIT') {
      setapplicationDirections(
        props.PropertyObj?.data?.applicationDirections
          ? props.PropertyObj?.data.applicationDirections
          : ''
      );
      setnotificationAgents(
        props?.PropertyObj?.data?.notificationAgents
          ? props.PropertyObj?.data?.notificationAgents
          : []
      );
      if (props?.PropertyObj?.data?.addressId !== null) {
        setplaceId(
          props.PropertyObj && props.PropertyObj.data?.addressId?.placeId
        );
        setPropertyAddress({
          description:
            props.PropertyObj && props.PropertyObj.data.addressId.fullAdd
        });
      }
      setBuildingName(props.PropertyObj && props.PropertyObj.data.name);
      setUnits(
        props.PropertyObj && props.PropertyObj.data.totalUnits
          ? props.PropertyObj.data.totalUnits
          : ''
      );
      setManager(
        props.PropertyObj &&
          props.PropertyObj?.data?.assignedManager?.length > 0
          ? props.PropertyObj?.data?.assignedManager[0]
          : ''
      );
      setPhone(
        props.PropertyObj && props.PropertyObj?.data?.communityPhone
          ? props.PropertyObj?.data?.communityPhone
          : 'Not Available'
      );
      setPropertyEmailAddress(
        props?.PropertyObj && props?.PropertyObj?.data?.emailId
          ? props?.PropertyObj?.data?.emailId
          : ''
      );
      setpaddingBefore(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.paddingBefore
      );
      setpaddingAfter(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.paddingAfter
      );
      setduration(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.duration
      );

      let timeObj = {
        label:
          props?.PropertyObj?.data &&
          ALL_TIME_ZONES[props?.PropertyObj?.data?.timeZone],
        value: props?.PropertyObj?.data && props?.PropertyObj?.data?.timeZone
      };
      setSelectedBTimezone(timeObj);
      //workinghourstart
      if (
        props?.PropertyObj?.data &&
        props?.PropertyObj?.data?.workingHours &&
        props?.PropertyObj?.data?.workingHours?.length > 0
      ) {
        setworkingHours(
          props?.PropertyObj?.data && props?.PropertyObj?.data?.workingHours
        );
      }
      let tempState = [...check];

      if (_.has(props.PropertyObj, 'data')) {
        props.PropertyObj.data &&
          props.PropertyObj.data.workingHours &&
          props.PropertyObj.data.workingHours.length > 0 &&
          props.PropertyObj.data.workingHours.map((res, i) => {
            let tempElement = { ...tempState[i] };
            if (i === 0) {
              tempElement.sunday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 1) {
              tempElement.monday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 2) {
              tempElement.tuesday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 3) {
              tempElement.wednesday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 4) {
              tempElement.thrusday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 5) {
              tempElement.friday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
            if (i === 6) {
              tempElement.saturday = res.status === 'open' ? true : false;
              tempState[i] = tempElement;
              setcheck(tempState);
            }
          });
      }

      //workinghourend
      setPropertyLink(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.website
      );
      setOfficeDirection(
        props?.PropertyObj?.data &&
          props?.PropertyObj?.data?.leaseOfficeDirection
      );
      setutility(props?.PropertyObj && props?.PropertyObj?.data?.utilities);
      setutilitiesOverview(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.utilityOverview
      );
      setpetsOverview(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.petOverview
      );
      settempFile(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.heroImage
      );
      setoverview(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.overview
      );
      setActive(
        props?.PropertyObj?.data && props?.PropertyObj?.data?.facilities
          ? props?.PropertyObj?.data?.facilities
          : []
      );
      let csval =
        props?.PropertyObj?.data &&
        props?.PropertyObj?.data?.facilities
          .filter(obj => obj.customResp !== undefined)
          .map(obj => obj.type);
      if (csval !== undefined && csval.length > 0) {
        setCustom(csval);
      }
    }

    if (props?.PropertyObj?.data) {
      props?.PropertyObj?.data?.consultantPhone
        ? setConsultantPhone(props?.PropertyObj?.data?.consultantPhone)
        : setConsultantPhone('');
      props?.PropertyObj?.data?.residentPhone
        ? setResidentPhone(props?.PropertyObj?.data?.residentPhone)
        : setResidentPhone('');
      props?.PropertyObj?.data?.communityOnSiteNumber
        ? setCommunityOnSiteNumber(
            props?.PropertyObj?.data?.communityOnSiteNumber
          )
        : setCommunityOnSiteNumber('');
    }
  }, [props.PropertyObj, props.buildingType]);

  //SaveBubildingFunction
  const SaveBuilding = () => {
    let custQues = [];
    if (customQuestionsList && customQuestionsList.length > 0) {
      custQues = customQuestionsList;
      custQues.forEach(e => {
        delete e.index;
      });
    }
    if (
      props.buildingType !== 'EDIT' &&
      BuildingName !== null &&
      BuildingName !== '' &&
      PropertyAddress.description !== null &&
      PropertyAddress.description !== '' &&
      Manager !== null &&
      Manager !== '' &&
      Units !== null &&
      Units !== '' &&
      placeId !== '' &&
      placeId !== null
    ) {
      let obj = {};
      obj.name = BuildingName;
      obj.addressId = PropertyAddress.description;
      obj.emailId = PropertyEmailAddress;
      obj.placeId = placeId;
      obj.heroImage = tempFile;
      obj.totalUnits = Units;
      obj.assignedManager = Manager;
      obj.utilities = utility;
      obj.website = PropertyLink;
      obj.leaseOfficeDirection = OfficeDirection;
      obj.petOverview = petsOverview;
      obj.utilityOverview = utilitiesOverview;
      obj.paddingBefore = paddingBefore;
      obj.paddingAfter = paddingAfter;
      obj.duration = duration;
      obj.timeZone = SelectedBTimezone.value;
      obj.workingHours = workingHours;
      obj.applicationDirections = applicationDirections;
      obj.CallToTextEnabled = check1;
      obj.knockCallToTextEnabled = knockCallToTextEnableStatus;
      obj.consultantPhone = consultantPhone;
      obj.residentPhone = residentPhone;
      obj.communityOnSiteNumber = communityOnSiteNumber;
      obj.phone = selectedPhoneNumber;
      // obj.pets = {
      //   allowed: PetAllowed,
      //   allowedPets: petsPolicy,
      //   allowRegAnimals: allowRegAnimals,
      //   customResp: CustomPetResp,
      // };

      obj.overview = overview;
      obj.facilities = Active;
      obj.notificationAgents = notificationAgents;
      obj.cheatSheet = cheatSheetList || [];
      obj.customQuestions = custQues || [];
      dispatch(postApartmentAction(obj));
      props.history.push('/communities');
    } else {
      setValidator(true);
    }
    if (
      props.buildingType === 'EDIT' &&
      BuildingName !== null &&
      BuildingName !== '' //&&
      // PropertyAddress.description !== null &&
      // PropertyAddress.description !== "" &&
      // Manager !== null &&
      // Manager !== "" &&
      // Units !== null &&
      // Units !== "" &&
      // TourAgent !== null &&
      // TourAgent !== ""
    ) {
      let obj = {};
      obj.name = BuildingName;
      obj.id = id;
      obj.addressId = PropertyAddress.description;
      obj.emailId = PropertyEmailAddress;
      obj.placeId = placeId;
      obj.heroImage = tempFile;
      obj.totalUnits = Units;
      obj.assignedManager = Manager;
      obj.utilities = utility;
      obj.website = PropertyLink;
      obj.leaseOfficeDirection = OfficeDirection;
      obj.petOverview = petsOverview;
      obj.utilityOverview = utilitiesOverview;
      obj.paddingBefore = paddingBefore;
      obj.paddingAfter = paddingAfter;
      obj.duration = duration;
      obj.timeZone = SelectedBTimezone.value;
      obj.workingHours = workingHours;
      obj.applicationDirections = applicationDirections;
      obj.CallToTextEnabled = check1;
      obj.knockCallToTextEnabled = knockCallToTextEnableStatus;
      obj.vmType = voiceType;
      obj.vmText =
        voiceType === 'text' ? text : voiceType === 'url' ? urlName : '';
      obj.consultantPhone = consultantPhone;
      obj.residentPhone = residentPhone;
      obj.communityOnSiteNumber = communityOnSiteNumber;
      obj.phone = selectedPhoneNumber;
      // obj.pets = {
      //   allowed: PetAllowed,
      //   allowedPets: petsPolicy,
      //   allowRegAnimals: allowRegAnimals,
      //   customResp: CustomPetResp,
      // };
      obj.enableBot = enableCommunityBot;
      obj.overview = overview;
      obj.facilities = Active;
      obj.notificationAgents = notificationAgents;
      obj.cheatSheet = cheatSheetList || [];
      obj.customQuestions = custQues || [];
      dispatch(updateBuildingAction(obj));
      props.history.push('/communities');
    } else {
      setValidator(true);
    }
  };

  const handleDropDownChange = (e, i) => {
    let cheat_sheet = cheatSheetList;
    cheat_sheet[i]['displayText'][0] = e.target.value;
    setCheatSheetList(cheat_sheet);
  };

  const handleEditQues = id => {
    let custEdit = custQuesEdit;
    custEdit[id] = true;
    setCustQuesEdit(custEdit);
    setcustomQuestionsList([...customQuestionsList]);
  };

  const deleteCustomQues = index => {
    let cList = customQuestionsList;
    cList.splice(index, 1);
    setcustomQuestionsList([...cList]);
  };

  const addCustomQuestion = () => {
    let idx = customQuestionsList.length;
    let list = {
      index: idx,
      key: 'Enter a question would you like to train Kelsey on',
      displayText: [],
      type: 'text',
      dropdown: [],
      intent: ''
    };
    setcustomQuestionsList([...customQuestionsList, list]);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#4CAF50',
      '&:hover': {
        backgroundColor: '#4CAF50'
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50'
    }
  }));
  return (
    <Layout history={props.history}>
      <Card
        style={{
          paddingBottom: '20px',
          height: '90vh',
          overflow: 'scroll'
        }}
      >
        <CardContent>
          <Grid container spacing={3} style={Commonst}>
            <Grid item xs={4}>
              <Grid
                item
                xs={12}
                style={{ ...BlockHeading, marginBottom: '12px' }}
              >
                Building Name
              </Grid>
              <TextField
                disabled
                key={`${Math.floor(Math.random() * 1000)}-min`}
                error={Validator && BuildingName === null}
                variant="outlined"
                fullWidth
                value={apartmentById?.name}
                defaultValue={'Test2'}
                onBlur={event => setBuildingName(event.target.value)}
                size="small"
              />
            </Grid>
            <CallToTextV1
              {...{
                checked: apartmentById?.CallToTextEnabled,
                property: apartmentById,
                entrataIVRAndCTT
              }}
            />
            <CallToTextComponent
              aptId={id}
              setEntrataIVRAndCTT={setEntrataIVRAndCTT}
              tenantId={apartmentById?.tenantId}
            />
            {/* {props?.PropertyObj?.data?.tenantId?.enableKnockCRM === true ? <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginBottom: "6px",
                    display: "inline",
                    marginLeft: "10px"
                  }}
                >
                  Knock's IVR Call-to-Text

                </div>
                <div style={{ marginLeft: '10px', marginTop: '-10px' }}>
                  <FormControlLabel
                    style={{ maginLeft: '20px' }}
                    control={
                      <CallToTextSwitch
                        checked={knockCallToTextEnableStatus}
                        onChange={(e) => setKnockCheckFun(e.target.checked)}
                        name="check"
                      />
                    }
                  />
                </div>
              </div>
              {knockCallToTextEnableStatus === true ? <KnockCallToText
                PropertyObj={props?.PropertyObj}
                setConsultantPhone={setConsultantPhone}
                setResidentPhone={setResidentPhone}
                residentPhone={residentPhone}
                consultantPhone={consultantPhone}
                communityOnSiteNumber={communityOnSiteNumber}
                setCommunityOnSiteNumber={setCommunityOnSiteNumber}
                uniqueId={props?.uniqueId}
              /> : null}
            </div> : <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginBottom: "6px",
                    display: "inline",
                    marginLeft: "10px"
                  }}
                >
                  Call-to-Text

                </div>
                <div style={{ marginLeft: '10px', marginTop: '-10px' }}>
                  <FormControlLabel
                    style={{ maginLeft: '20px' }}
                    control={
                      <CallToTextSwitch
                        checked={check1}
                        onChange={(e) => setCheckFun(e.target.checked)}
                        name="check"
                      />
                    }
                  />
                </div>
              </div>
              {check1 === true ? <CallToText
                PropertyObj={props.PropertyObj}
                setConsultantPhone={setConsultantPhone}
                setResidentPhone={setResidentPhone}
                residentPhone={residentPhone}
                consultantPhone={consultantPhone}
                uniqueId={props?.uniqueId}
                selectedPhoneNumber={selectedPhoneNumber}
                setSelectedPhonenumber={setSelectedPhonenumber}
              /> : null}
            </div>} */}
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                right: '0'
              }}
              dangerouslySetInnerHTML={{
                __html: `<div>
        <button onclick="Storylane.Play({type: 'popup', demo_type: 'image', width: 1448, height: 782, scale: '0.95', demo_url: 'https://app.storylane.io/demo/fzjvs8gz7pds', padding_bottom: 'calc(54.01% + 27px)'})" class="sl-preview-cta" style="background-color:#5E24FF;border:none;border-radius:8px;box-shadow:0px 0px 15px rgba(26, 19, 72, 0.45);color:#FFFFFF;display:inline-block;font-family:Poppins, Arial, sans-serif;font-size:clamp(16px, 1.599vw, 20px);font-weight:600;height:clamp(40px, 3.996vw, 50px);line-height:1.2;padding:0 clamp(15px, 1.776vw, 20px);text-overflow:ellipsis;transform:translateZ(0);transition:background 0.4s;white-space:nowrap;width:auto;z-index:999999;cursor:pointer">Guide<div class="sl-preview-cta-ripple" style="position:absolute;border:1px solid #5E24FF;inset:0;border-radius:inherit;pointer-events:none"><div class="sl-preview-cta-ripple-shadow" style="box-shadow:#5E24FF 0px 0px 4px 4px;opacity:0;border-radius:inherit;position:absolute;inset:0"></div></div></button><style>.sl-preview-cta:hover .sl-preview-cta-ripple{transition:all 1s cubic-bezier(0,0,.2,1);inset:-0.75em!important;opacity:0!important}.sl-preview-cta:hover .sl-preview-cta-ripple-shadow{opacity:0.125!important;}</style>
      </div>`
              }}
            ></div>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
};

const mapStateToProps = state => ({
  PlaceObj: state.Property.PlaceObj,
  Amenities: state.Apartment.ApartmentAmenities,
  Users: state.Admin.users,
  masterCheatSheet: state.Apartment.masterCheatSheet.data
});

export default connect(mapStateToProps)(BuildingAdd);
