import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  Card,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';
import CommunityPhoneImage from '../../Media/communityPhone.png';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { getCookie } from '../../utils';
import Error from '../../Media/InboxV1//error.gif';
import Success from '../../Media/InboxV1/success1.gif';
import Processing from '../../Media/InboxV1/loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegration } from '../../Redux/Actions/SelfServe';
import LoaderIcon from '../../Media/loading1.gif';
import InfoIcon from '@mui/icons-material/Info';

const CommunityPhoneModel = ({
  open,
  handleClose,
  tenantId,
  status,
  setStatus,
  confirmPurchase,
  setConfirmPurchase
}) => {
  const [finalData, setFinalData] = useState([]);
  const dispatch = useDispatch();
  const selfServe = useSelector(state => state.SelfServe);
  const { integrationApartment } = selfServe;
  const getAvailableNumbersUrl =
    'https://properties.getzuma.com/newPhoneNumberForApartment';
  const token = getCookie('rb_access_token');
  const authorization = token ? token : '';

  useEffect(() => {
    dispatch(getIntegration({ tenantId }));
  }, [tenantId]);

  useEffect(() => {
    let result = integrationApartment?.map(x => {
      if (x?.isCommunitySpecificPhoneNoEnabled) {
        return { ...x, checked: false, loading: true };
      } else if (!x?.isCommunitySpecificPhoneNoEnabled) {
        return { ...x, checked: false, loading: true };
      }
    });
    setFinalData(result);
  }, [open]);

  const getAvailableNumbers = async (id, checked) => {
    const getPhoneNumber = await axios.get(`${getAvailableNumbersUrl}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
        'X-Api-Key': 'b48f493e-d68a-4c69-9e1a-b5bdd2d7587b'
      }
    });
    if (getPhoneNumber?.status === 200) {
      let successResult = finalData?.map(x => {
        if (id === x?._id) {
          return {
            ...x,
            checked: checked,
            loading: false,
            number: getPhoneNumber?.data?.responseData?.proposedPhoneNo,
            message: getPhoneNumber?.data?.message
          };
        } else {
          return x;
        }
      });
      setFinalData(successResult);
    } else {
      let result = finalData?.map(x => {
        if (id === x?._id) {
          return {
            ...x,
            checked: checked,
            loading: false,
            number: getPhoneNumber?.data?.responseData?.proposedPhoneNo,
            message: getPhoneNumber?.data?.message
          };
        } else {
          return x;
        }
      });
      setFinalData(result);
      setCheckedState(false);
    }
  };

  const formatPhoneNumber = phoneNumberString => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? '+1 ' : '';
      return [
        intlCode,
        '(',
        match[2],
        ')',
        ' ',
        match[3],
        '-',
        match[4],
        ''
      ].join('');
    }
    return null;
  };

  const [checkedState, setCheckedState] = useState(false);

  const checkState = data => {
    let obj = data.find(o => !o.communityPhone && o.checked === true);

    if (obj) {
      setCheckedState(true);
    } else {
      setCheckedState(false);
    }
  };
  const handleCheck = async event => {
    let result = finalData?.map(x => {
      return event.target.id === x?._id
        ? { ...x, checked: event.target.checked, loading: true }
        : { ...x };
    });
    setFinalData(result);
    checkState(result);

    await getAvailableNumbers(event.target.id, event.target.checked);
  };

  const [loading, setLoading] = useState(false);

  const purchasePhoneNumber = async data => {
    const purchaseUrl = 'https://properties.getzuma.com/createApartmentPhoneNo';
    const purchasePhoneNumber = await axios.patch(purchaseUrl, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
        'X-Api-Key': 'b48f493e-d68a-4c69-9e1a-b5bdd2d7587b'
      }
    });
    if (purchasePhoneNumber?.data?.status === 200) {
      setStatus('success');
      setLoading(false);
    } else {
      setStatus('error');
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    setConfirmPurchase(true);
  };

  const [value, setValue] = useState('');

  const handleSearch = e => {
    const searchValue = e.target.value;
    setValue(searchValue);
  };

  const handleConfirmPurchase = () => {
    setLoading(true);
    finalData.filter(x => {
      if (x?.checked) {
        let data = {
          aptId: x?._id,
          phoneNumber: x?.number
        };
        purchasePhoneNumber(data);
        return x;
      }
    });
  };

  const handleAllCommunities = async event => {
    let result = finalData?.map(x => {
      return !x?.communityPhone
        ? { ...x, checked: event.target.checked, loading: true }
        : { ...x };
    });
    setFinalData(result);
    checkState(result);
    result = await Promise.all(
      finalData?.map(async x => {
        if (!x?.communityPhone) {
          const getPhoneNumber = await axios.get(
            `${getAvailableNumbersUrl}/${x?._id}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'X-Api-Key': 'b48f493e-d68a-4c69-9e1a-b5bdd2d7587b'
              }
            }
          );
          if (getPhoneNumber?.status === 200) {
            x = !x?.communityPhone
              ? {
                  ...x,
                  checked: event.target.checked,
                  loading: false,
                  number: getPhoneNumber?.data?.responseData?.proposedPhoneNo,
                  message: getPhoneNumber?.data?.message
                }
              : { ...x };
          }
        }
        return x;
      })
    );

    setFinalData(result);
  };

  const [hoverBackground, setHoverBackground] = useState({});

  return (
    <Dialog open={open} className="communityPhoneContainer">
      {confirmPurchase ? (
        <Card
          style={{
            height: '700px',
            width: '512px',
            background: '#FAFAFA'
          }}
        >
          <div
            style={{
              position: 'absolute',
              padding: '20px',
              right: '5px'
            }}
          >
            <CloseIcon
              onClick={() => {
                handleClose();
                let result = integrationApartment?.map(x => {
                  return { ...x, checked: false, loading: true };
                });
                setCheckedState(false);
                setFinalData(result);
              }}
            />
          </div>
          {loading ? (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <img
                src={Processing}
                alt={'processing'}
                style={{
                  height: '200px',
                  width: '200px',
                  marginTop: '190px'
                }}
              />
              <div style={{ fontFamily: 'Inter' }}>Purchasing...</div>
            </div>
          ) : (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <img
                alt={'status'}
                src={status === 'success' ? Success : Error}
                style={{ height: '200px', width: '200px', marginTop: '150px' }}
              />
              {status === 'success' ? (
                <div style={{ fontFamily: 'Inter' }}>
                  Phone numbers are purchased.
                </div>
              ) : (
                <div
                  style={{
                    width: '311px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    color: '#FF4D00',
                    textAlign: 'center',
                    fontFamily: 'Inter'
                  }}
                >
                  Disclaimer: Once you purchase these numbers, you cannot revert
                  the changes.
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px',
                  marginTop: '50px'
                }}
              >
                {status ? null : (
                  <Button
                    style={{
                      background: '#5267FF',
                      color: 'white',
                      textTransform: 'capitalize'
                    }}
                    onClick={handleConfirmPurchase}
                  >
                    Yes, Purchase
                  </Button>
                )}
                <Button
                  style={{ textTransform: 'capitalize', color: '#393939' }}
                  onClick={() => {
                    handleClose();
                    let result = integrationApartment?.map(x => {
                      if (!x?.communityPhone) {
                        return { ...x, checked: false, loading: true };
                      }
                    });
                    setFinalData(result);
                  }}
                >
                  {status ? '' : 'No'}
                </Button>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <Card style={{ height: '700px', width: '512px' }}>
          <div
            style={{
              position: 'absolute',
              padding: '20px',
              right: '5px',
              color: 'white'
            }}
          >
            <CloseIcon
              onClick={() => {
                handleClose();
                let result = integrationApartment?.map(x => {
                  return { ...x, checked: false, loading: true };
                });
                setCheckedState(false);
                setFinalData(result);
              }}
            />
          </div>
          <div style={{ height: '170px', background: '#5267FF' }}>
            <img alt={'community-phone-image'} src={CommunityPhoneImage} />
            <div className="communityPhoneTop" style={{ fontFamily: 'Inter' }}>
              Select the communities for which you want to purchase phone
              numbers.
            </div>
          </div>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginTop: '-19px'
            }}
          >
            <TextField
              onChange={handleSearch}
              placeholder="Search clients"
              variant="outlined"
              value={value}
              InputProps={{
                type: 'search',
                style: {
                  borderRadius: '4px',
                  padding: '4px',
                  background: 'white',
                  width: '345px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#a2a2a2' }} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '20px',
              fontFamily: 'Inter',
              fontSize: '14px'
            }}
          >
            <div
              style={{
                borderRadius: '5px',
                width: '28.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                marginTop: '10px',
                marginLeft: '5px'
              }}
            >
              All Communities
              <input
                type="checkbox"
                onChange={handleAllCommunities}
                style={{
                  position: 'inherit',
                  height: '18px',
                  width: '18px'
                }}
              />
            </div>
            <div style={{ overflow: 'scroll', height: '310px' }}>
              {finalData
                ?.filter(
                  obj =>
                    obj?.name?.toLowerCase().includes(value) ||
                    obj?.name?.includes(value)
                )
                ?.map((x, i) => (
                  <div
                    key={x?._id}
                    onMouseEnter={() =>
                      setHoverBackground({
                        _id: x?._id
                      })
                    }
                    onMouseLeave={() => setHoverBackground()}
                    style={{
                      borderRadius: '5px',
                      width: '28.5rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px',
                      boxShadow:
                        hoverBackground?._id === x?._id
                          ? '-2px 0px #5267FF'
                          : null,
                      marginLeft: '5px',
                      marginTop: '20px',
                      height: '40px',
                      opacity: x.communityPhone ? '0.3' : null,
                      background:
                        hoverBackground?._id === x?._id
                          ? 'rgba(82, 103, 255, 0.05)'
                          : 'white'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px'
                      }}
                    >
                      <Tooltip title={x?.name}>
                        <div
                          style={{
                            width: '153px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {x?.name}
                        </div>
                      </Tooltip>
                      <div style={{ fontSize: '12px', color: 'grey' }}>
                        {x?.addressId?.stateShort},{x?.addressId?.postalCode}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '12px'
                      }}
                    >
                      {x?.communityPhone ? (
                        <>
                          <div>Purchased phone number</div>
                          <div>{formatPhoneNumber(x?.communityPhone)}</div>
                        </>
                      ) : null}
                    </div>
                    {x?.checked && !x?.communityPhone ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {x?.loading ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              fontSize: '12px',
                              alignItems: 'center'
                            }}
                          >
                            <div>Suggested phone number</div>
                            <img
                              alt={'loader'}
                              src={LoaderIcon}
                              style={{ height: '26px', width: '26px' }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              fontSize: '12px',
                              gap: '10px'
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'inter',
                                fontSize: '12px',
                                fontWeight: '500',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <div> Suggested phone number</div>
                              <Tooltip title={x?.message}>
                                <InfoIcon
                                  style={{
                                    color: '#42526e',
                                    marginLeft: '5px',
                                    height: '20px'
                                  }}
                                />
                              </Tooltip>
                            </div>
                            {x?.number ? (
                              <div>{formatPhoneNumber(x?.number)}</div>
                            ) : (
                              <div
                                style={{
                                  color: '#FF4D00',
                                  fontFamily: 'inter',
                                  fontSize: '12px'
                                }}
                              >
                                Phone Number is not available
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : null}

                    <input
                      id={x?._id}
                      value={x?.name}
                      type="checkbox"
                      checked={x?.communityPhone ? true : x?.checked}
                      onChange={handleCheck}
                      style={{
                        position: 'inherit',
                        height: '18px',
                        width: '18px'
                      }}
                      disabled={!!x?.communityPhone}
                    />
                  </div>
                ))}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                opacity: `${checkedState === false ? '0.3' : 'unset'}`,
                marginTop: '15px'
              }}
            >
              <Button
                style={{
                  background: '#5267FF',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                onClick={handlePurchase}
                disabled={checkedState !== true}
              >
                Purchase
              </Button>
              <Button
                style={{ textTransform: 'capitalize', color: '#393939' }}
                onClick={() => {
                  let result = integrationApartment?.map(x => {
                    if (!x?.communityPhone) {
                      return { ...x, checked: false, loading: true };
                    }
                  });
                  setFinalData(result);
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </Card>
      )}
    </Dialog>
  );
};

export default CommunityPhoneModel;
