import { Popover } from '@mui/material';
import React from 'react';

import Picker from 'emoji-picker-react';
const SmileyOptions = props => {
  const { anchorEl, setAnchorEl, handleChatEmojis } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEmojiClick = (e, data) => {
    handleChatEmojis(data.emoji, 'emoji');
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Picker onEmojiClick={handleEmojiClick} />
    </Popover>
  );
};

export default SmileyOptions;
