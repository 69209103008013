import React from 'react';
const DropdownArrow = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={'pointer'}
      style={style}
    >
      <rect
        width="20"
        height="20"
        transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 20 20)"
        fill="white"
        fill-opacity="0.01"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8375 12.4962L14.665 8.66875C14.7429 8.59242 14.8049 8.50142 14.8474 8.401C14.8898 8.30058 14.912 8.19273 14.9126 8.08369C14.9131 7.97465 14.892 7.86658 14.8506 7.76573C14.8091 7.66489 14.748 7.57326 14.6709 7.49616C14.5938 7.41905 14.5022 7.358 14.4014 7.31652C14.3005 7.27505 14.1924 7.25398 14.0834 7.25453C13.9744 7.25508 13.8665 7.27724 13.7661 7.31973C13.6657 7.36222 13.5747 7.4242 13.4983 7.50208L10.2483 10.7521L6.99834 7.50208C6.8431 7.34994 6.63409 7.26522 6.41673 7.26631C6.19938 7.26741 5.99123 7.35424 5.83754 7.50794C5.68384 7.66164 5.59701 7.86978 5.59591 8.08714C5.59481 8.3045 5.67954 8.5135 5.83167 8.66875L9.65834 12.4962C9.81461 12.6525 10.0265 12.7402 10.2475 12.7402C10.4685 12.7402 10.6804 12.6525 10.8367 12.4962L10.8375 12.4962Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default DropdownArrow;
