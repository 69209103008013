import React from 'react';
import {
  CollapseContainer,
  DrawerContentWrapper,
  DrawerWrapper
} from './drawer.styles';
import ChevronRight from '../../Media/InboxV1/ChevronRight';

const Drawer = ({
  children,
  open,
  height,
  width,
  top,
  border,
  borderRadius,
  background,
  boxShadow,
  onClose
}) => {
  return (
    <DrawerWrapper open={open} height={height} top={top}>
      <CollapseContainer>
        <ChevronRight
          style={{
            position: 'absolute',
            inset: '24px 0 0 12px',
            cursor: 'pointer'
          }}
          onClick={onClose}
        />
      </CollapseContainer>
      <DrawerContentWrapper
        width={width}
        borderRadius={borderRadius}
        border={border}
        boxShadow={boxShadow}
        background={background}
      >
        {children}
      </DrawerContentWrapper>
    </DrawerWrapper>
  );
};

export default Drawer;
