import { Button, Dialog } from '@mui/material';
import React from 'react';
import CrossIcon from '../../../Media/InboxV1/CrossIcon';
import DeleteSvg from './Delete';

const VanityDeleteSuccessPopup = ({ open, setOpen, text }) => {
  return (
    <Dialog
      className="d-flex-just-align-center"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ padding: '10px' }}
    >
      <CrossIcon
        style={{
          position: 'absolute',
          left: '296px',
          top: '20px',
          width: '24px',
          height: '24px',
          cursor: 'pointer'
        }}
        onClick={() => setOpen(false)}
      />
      <div style={{ height: '294px', width: '340px' }}>
        <div className="d-flex-just-align-center" style={{ marginTop: '79px' }}>
          <DeleteSvg />
        </div>
        <div
          className="inter-16px-500 d-flex-just-align-center"
          style={{ marginTop: '25px' }}
        >
          <span
            style={{
              color: '#393939',
              fontWeight: '500',
              fontSize: '16px',
              letterSpacing: '0.5px',
              lineHeight: '22px'
            }}
          >
            {' '}
            {text} deleted!{' '}
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default VanityDeleteSuccessPopup;
