import React, { useEffect } from 'react';

import Select, { components } from 'react-select';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(props => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

const SortableMultiSelect = ({
  options = [],
  handleChange,
  selected = [],
  placeholder = 'Select'
}) => {
  const [sortedArr, setSortedArr] = React.useState([]);

  useEffect(() => {
    const updatedOptions = (options || []).sort(function (a, b) {
      let keyA = a.label,
        keyB = b.label;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    setSortedArr(updatedOptions);
  }, [options]);

  const optionStyles = {
    container: styles => ({
      ...styles,
      minWidth: '130px',
      marginBottom: '10px'
    }),
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    valueContainer: styles => ({
      ...styles,
      padding: '5px'
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none'
    }),
    indicatorContainer: styles => ({
      ...styles,
      padding: '0px'
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menuList: styles => ({
      ...styles,
      maxHeight: '180px'
    }),
    placeholder: styles => ({
      ...styles,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#C4C4C4'
    }),
    multiValue: styles => ({
      ...styles,
      background: 'rgba(82, 103, 255, 0.1)',
      bordeRadius: '10px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#5267FF'
    })
  };

  return (
    <SortableSelect
      useDragHandle
      axis="xy"
      styles={optionStyles}
      menuPortalTarget={document.body}
      isMulti
      options={sortedArr}
      value={selected}
      placeholder={placeholder}
      onChange={handleChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
      }}
      closeMenuOnSelect={false}
    />
  );
};

export default SortableMultiSelect;
