import React from 'react';

const MessageBuilder = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 4.187C13.3407 4.06212 12.6711 3.99951 12 4C7.037 4 3 7.37 3 11.513C3 15.656 7.037 19.027 12 19.027C13.42 19.027 14.76 18.742 15.957 18.251C16.96 19.273 18.244 19.823 19.197 19.97L19.199 19.967C19.2515 19.9867 19.3069 19.9979 19.363 20C19.448 20 19.5317 19.9789 19.6067 19.9386C19.6816 19.8984 19.7453 19.8402 19.7923 19.7693C19.8392 19.6984 19.8679 19.617 19.8757 19.5323C19.8835 19.4476 19.8702 19.3623 19.837 19.284V19.282C19.837 19.282 18.274 17.022 19.071 16.166L19.034 16.186C20.261 14.902 21 13.279 21 11.513C21.0003 11.3418 20.9936 11.1707 20.98 11H18.972C18.992 11.169 19.002 11.34 19.002 11.513C19.002 14.552 15.861 17.023 12 17.023C8.139 17.023 4.998 14.552 4.998 11.513C4.998 8.475 8.139 6.003 12 6.003C12.695 6.003 13.366 6.083 14 6.232V4.187Z"
        fill="white"
      />
      <path
        d="M19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5V9C17 9.55229 17.4477 10 18 10C18.5523 10 19 9.55229 19 9V5Z"
        fill="white"
      />
      <path
        d="M20 6H16C15.4477 6 15 6.44772 15 7C15 7.55229 15.4477 8 16 8H20C20.5523 8 21 7.55229 21 7C21 6.44772 20.5523 6 20 6Z"
        fill="white"
      />
    </svg>
  );
};

export default MessageBuilder;
