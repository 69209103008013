import {
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import Layout from '../../Component/layout';
import AddIcon from '@mui/icons-material/Add';
import './styles.scss';
import {
  AddApprovedWebsites,
  AddToxicWords,
  ApprovedWebsites,
  DeleteApprovedWebsites,
  DeleteToxicWords,
  ToxicWords
} from './services';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../Redux/Reducer/GlobalError';
import { isValidUrl } from '../../utils';
import SnackBar from '../../Component/Common/SnackBar';
import DeleteIcon from '../../Media/InboxV1/DeleteIcon';

const Toxicity = props => {
  const [approvedWebsites, setApprovedWebsites] = useState();
  const [toxicWords, setToxicWords] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showAlert, setShowAlert] = useState({});
  const [addIcon, setAddIcon] = useState(false);
  const [open, setOpen] = useState({ state: false });
  const dispatch = useDispatch();

  useEffect(() => {
    getWebsitesAndWords();
  }, []);

  const getWebsitesAndWords = async () => {
    const websites = await ApprovedWebsites();
    const words = await ToxicWords();
    setToxicWords(words?.data?.response);
    setApprovedWebsites(websites?.data?.response);
  };

  const deleteToxicWords = async word => {
    const response = await DeleteToxicWords(word);
    if (response?.status === 200) {
      setSearchValue('');
      const words = await ToxicWords();
      setToxicWords(words?.data?.response);
      setOpen({
        state: true,
        message: 'Deleted word successfully',
        severity: 'success'
      });
    } else {
      dispatch(setGlobalError('Unable to delete the word'));
    }
  };
  const deleteApprovedWebiste = async domain => {
    let response = await DeleteApprovedWebsites(domain);
    if (response?.status === 200) {
      setSearchValue('');
      const websites = await ApprovedWebsites();
      setApprovedWebsites(websites?.data?.response);
      setOpen({
        state: true,
        message: 'Deleted website successfully',
        severity: 'success'
      });
    } else {
      dispatch(setGlobalError('Unable to delete the word'));
    }
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  const [value, setValue] = useState('');
  useEffect(() => {
    if (searchValue?.includes('www.')) {
      let res = searchValue?.split('.');
      setValue(res[1]);
    }
    let checkUrl = isValidUrl(searchValue);
    if (checkUrl) {
      let website = approvedWebsites?.filter(name =>
        name?.includes(searchValue.includes('www.') ? value : searchValue)
      );
      if (website?.length <= 0) {
        setAddIcon(true);
        return setShowAlert({
          type: 'website',
          value: searchValue,
          state: true
        });
      }
    } else if (searchValue !== '') {
      let word = toxicWords?.filter(name => name?.includes(searchValue));
      if (word.length <= 0) {
        setAddIcon(true);
        return setShowAlert({ type: 'word', value: searchValue, state: true });
      }
    }
    setAddIcon(false);
    setShowAlert({});
  }, [searchValue]);

  const handleAddData = async () => {
    if (showAlert?.type === 'website') {
      setSearchValue('');
      const response = await AddApprovedWebsites(searchValue);
      if (response?.status === 200) {
        setSearchValue('');
        const websites = await ApprovedWebsites();
        setApprovedWebsites(websites?.data?.response);
        setOpen({
          state: true,
          message: 'Added website successfully',
          severity: 'success'
        });
      } else {
        dispatch(setGlobalError('Unable to Add the website'));
      }
    }
    if (showAlert?.type === 'word') {
      setSearchValue('');
      const response = await AddToxicWords(searchValue);

      if (response?.status === 200) {
        setSearchValue('');
        const words = await ToxicWords();
        setToxicWords(words?.data?.response);
        setOpen({
          state: true,
          message: 'Added word successfully',
          severity: 'success'
        });
      } else {
        dispatch(setGlobalError('Unable to Add the word'));
      }
    }
  };

  const [showIcon, setShowIcon] = useState({});
  const [showIcon1, setShowIcon1] = useState({});

  return (
    <Layout history={props.history}>
      <SnackBar
        open={open?.state}
        setOpen={setOpen}
        severity={open?.severity}
        message={open?.message}
      />
      <div className="toxicity-container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: showAlert?.state ? '0' : '30px'
          }}
        >
          <TextField
            sx={{
              height: '36px',
              width: '409px',
              input: {
                '&::placeholder': {
                  color: '#C4C4C4',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: 1
                }
              }
            }}
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              type: 'search',
              style: {
                borderRadius: '4px',
                padding: '4px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#A2A2A2' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ paddingRight: '10px', cursor: 'pointer' }}
                >
                  {addIcon ? (
                    <AddIcon
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        background: 'rgba(82, 103, 255, 0.1)'
                      }}
                      onClick={handleAddData}
                    />
                  ) : null}
                </InputAdornment>
              )
            }}
          />
        </div>
        {showAlert?.state ? (
          <div className="alert">*You have typed a new {showAlert?.type}</div>
        ) : null}
        <div className="sub-section">
          <div className="toxic-words-container">
            <div className="header">Toxic Words</div>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <List
              sx={{
                width: '100%',
                maxWidth: 550,
                position: 'relative',
                overflow: 'auto',
                maxHeight: 430,
                padding: '10px'
              }}
              subheader={<li />}
            >
              {toxicWords &&
                toxicWords
                  ?.filter(name => name?.includes(searchValue))
                  ?.map((filteredName, i) => (
                    <ListItem
                      key={filteredName}
                      component="div"
                      sx={{
                        ':hover': {
                          background: ' rgba(83, 104, 255, 0.1);'
                        }
                      }}
                      onMouseEnter={() =>
                        setShowIcon({ state: true, position: i })
                      }
                      onMouseLeave={() =>
                        setShowIcon({ state: false, position: i })
                      }
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteToxicWords(filteredName)}
                        >
                          {showIcon?.state && showIcon?.position === i ? (
                            <DeleteIcon />
                          ) : null}
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText primary={filteredName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </div>
          <div className="approved-websites-container">
            <div className="header">Approved Websites</div>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <List
              sx={{
                width: '100%',
                maxWidth: 550,
                position: 'relative',
                overflow: 'auto',
                maxHeight: 430,
                padding: '10px'
              }}
              subheader={<li />}
            >
              {approvedWebsites &&
                approvedWebsites
                  ?.filter(name =>
                    name?.includes(
                      searchValue.includes('www.') ? value : searchValue
                    )
                  )
                  ?.map((filteredName, i) => (
                    <ListItem
                      key={i}
                      component="div"
                      sx={{
                        ':hover': {
                          background: ' rgba(83, 104, 255, 0.1);'
                        }
                      }}
                      onMouseEnter={() =>
                        setShowIcon1({ state: true, position: i })
                      }
                      onMouseLeave={() =>
                        setShowIcon1({ state: false, position: i })
                      }
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteApprovedWebiste(filteredName)}
                        >
                          {showIcon1?.state && showIcon1?.position === i ? (
                            <DeleteIcon />
                          ) : null}
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText primary={filteredName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Toxicity;
