import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import SortableMultiSelect from '../../InboxV1/LeadListComponents/LeadFilterOptions/SortableMultiSelect';
import { LEAD_TYPES } from '../constants';
import CrmModal from './crmModal';
import { setGlobalError } from '../../../Redux/Reducer/GlobalError';
import ZSwitch from '../../../globalcomponents/ZSwitch';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0 20px',
    height: '660px',
    overflow: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '24px',
        color: '#525F80'
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#525F80'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const SettingsModal = ({
  handleClose,
  configIntegration,
  tenantId,
  statusMappings,
  settingsInitailValue,
  assignToKelsey,
  tenantData,
  crmSetupInitial,
  pmsSetupInitial,
  initalStatusMapping
}) => {
  const dispatch = useDispatch();
  const [pmsOn, setPmsOn] = useState(true);
  const [selectedCrmContact, setSelectedCrmContact] = useState([]);
  const [settingsName, setSettingsName] = useState(
    settingsInitailValue || 'Live Communities'
  );
  const [assignLeadToKelsey, setassignLeadToKelsey] = useState(assignToKelsey);
  const [crmData, setCrmData] = useState(initalStatusMapping);
  const [crmType, setCrmType] = useState([]);
  const [pmsSetup, setPmsSetup] = useState({
    propertyIngestion: pmsSetupInitial,
    leadIngestion: !crmSetupInitial && pmsSetupInitial,
    kelsyAssign: assignLeadToKelsey,
    selectedLeadType: []
  });
  const [crmSetup, setcrmSetup] = useState({
    propertyIngestion: crmSetupInitial || false,
    leadIngestion: crmSetupInitial || false,
    kelsyAssign: assignLeadToKelsey,
    selectedLeadType: []
  });
  const settings = {
    source: 'knock',
    sourceType: 'CRM',
    mappings: crmData,
    allowedFirstContactTypes: []
  };
  const payload = {
    tenantId: tenantId,
    settingsName: settingsName,
    pmsSetup: true,
    crmSetup: false,
    propertyIngestion: pmsSetup?.propertyIngestion ? true : false,
    leadIngestion: pmsSetup?.leadIngestion ? true : false
  };
  const crmPayload = {
    tenantId: tenantId,
    settingsName: settingsName,
    pmsSetup: false,
    crmSetup: crmSetup.leadIngestion,
    propertyIngestion: pmsSetup?.propertyIngestion ? true : false,
    leadIngestion: true,
    assignLeadToKelsey: assignLeadToKelsey,
    crmSettings: {
      crm: 'knock',
      autoAssignLeadToKelsey: true
    },
    statusMappingsDefaultKnock: {
      ...settings,
      allowedFirstContactTypes: [...selectedCrmContact]
    }
  };
  const handlePmsChange = e => {
    const { name, value, checked } = e.target;

    if (crmSetup.leadIngestion && name === 'leadIngestion') {
      dispatch(setGlobalError('Please turn off lead ingestion on CRM level.'));
      return;
    }
    setPmsSetup(prev => ({ ...prev, [name]: checked }));
  };

  const handleCrmChange = e => {
    if (pmsSetup.leadIngestion) {
      dispatch(setGlobalError('Please turn off lead ingestion on PMS level '));
      return;
    }
    const { name, value, checked } = e.target;
    setcrmSetup(prev => ({ ...prev, [name]: checked }));
  };

  let z = [];

  const [allKelseyContact, setAllKelseyContact] = useState([
    { name: 'call', checked: false },
    { name: 'form', checked: true },
    { name: 'chat', checked: true },
    { name: 'internet', checked: true },
    { name: 'email', checked: true },
    { name: 'knock-schedule', checked: true },
    { name: 'facebook', checked: true },
    { name: 'sms', checked: true },
    { name: 'landline', checked: true },
    { name: 'walk-in', checked: false }
  ]);

  useEffect(() => {
    if (allKelseyContact) {
      allKelseyContact.map(x => {
        if (x.checked === true) {
          z.push(x.name);
        }
      });
    }
    if (tenantData?.statusMappings?.allowedFirstContactTypes) {
      let array1 = tenantData?.statusMappings?.allowedFirstContactTypes;
      let array2 = allKelseyContact;

      let newObj = [];
      let found = array2.filter((val, index) => {
        if (array1.includes(val.name)) {
          newObj.push({ name: val.name, checked: true });
        } else {
          newObj.push({ name: val.name, checked: false });
        }
      });
      setAllKelseyContact(newObj);
    }
  }, [tenantData]);
  const handleCrmType = clients => {
    if (clients && clients.length > 0) {
      setCrmType([...clients]);
      let selectedCrm = clients.reduce((acc, item) => {
        let newValue = [...acc, item.value];
        return newValue;
      }, []);
      setSelectedCrmContact(selectedCrm);
    } else {
      setCrmType([]);
      setSelectedCrmContact([]);
    }
  };

  const handleconfigIntegration = () => {
    dispatch(
      configIntegration({
        payload: crmSetup?.leadIngestion ? crmPayload : payload
      })
    );
    handleClose();
  };

  const handleSettingsName = e => {
    setSettingsName(e.target.value);
  };

  useEffect(() => {
    if (statusMappings?.allowedFirstContactTypes) {
      const allowedFirstContactTypes = statusMappings?.allowedFirstContactTypes;
      const data = allowedFirstContactTypes?.reduce(
        (acc, item) => {
          const accValue = [
            ...acc.accValue,
            { value: item?.value || item, label: item?.label || item }
          ];
          const payloadData = [...acc.payloadData, item?.value || item];
          return { accValue, payloadData };
        },
        { accValue: [], payloadData: [] }
      );
      setSelectedCrmContact(data.payloadData || []);
      setCrmType(data?.accValue || []);
    }
  }, [statusMappings]);

  return (
    <div>
      <BootstrapDialog
        sx={{ height: '90vh', width: '100%', margin: '0 auto' }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        className="settings-modal"
      >
        <div style={{ display: 'flex' }}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Configure new setting
          </BootstrapDialogTitle>
        </div>
        <DialogContent>
          <div className="settings-tab tabModalCont">
            <span
              className={
                pmsOn
                  ? 'tabs-style active-tab-style tabModal'
                  : 'tabs-style tabModal'
              }
              onClick={() => setPmsOn(true)}
            >
              PMS Setup
            </span>
            <span
              className={
                pmsOn
                  ? 'tabs-style tabModal'
                  : 'tabs-style active-tab-style tabModal'
              }
              onClick={() => setPmsOn(false)}
            >
              CRM Setup
            </span>
          </div>
          {pmsOn ? (
            <div style={{ marginTop: '32px' }}>
              <li className="li-style modalLi">
                <FormControlLabel
                  control={
                    <ZSwitch
                      id="property_ingestion"
                      name="propertyIngestion"
                      checked={pmsSetup.propertyIngestion}
                      onChange={handlePmsChange}
                    />
                  }
                  label="Property Ingestion"
                  labelPlacement="start"
                  className="settings-modal-label"
                />
              </li>
              <li className="li-style modalLi">
                <FormControlLabel
                  control={
                    <ZSwitch
                      id="pms_lead_ingestion"
                      name="leadIngestion"
                      checked={pmsSetup.leadIngestion}
                      onChange={handlePmsChange}
                    />
                  }
                  label="Lead Ingestion"
                  labelPlacement="start"
                  className="settings-modal-label"
                />
              </li>
            </div>
          ) : (
            <div className="modalLi crmMod">
              <FormControlLabel
                control={
                  <ZSwitch
                    id="crm_lead_ingestion"
                    name="leadIngestion"
                    checked={crmSetup.leadIngestion}
                    onChange={handleCrmChange}
                  />
                }
                label="Lead Ingestion"
                labelPlacement="start"
                className="settings-modal-label"
              />
              {crmSetup?.leadIngestion && (
                <ul className="list1 crmBlock">
                  <ul className="dFlexLi">
                    <li className="sub-list-style">
                      <span className="spanMedium">
                        Do you want to assign leads to Kelsey ?
                      </span>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={assignLeadToKelsey ? 'yes' : 'no'}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                          onClick={() => setassignLeadToKelsey(true)}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                          onClick={() => setassignLeadToKelsey(false)}
                        />
                      </RadioGroup>
                    </li>
                    <li className="sub-list-style">
                      Select lead type to connect
                      <span style={{ color: 'red' }}>*</span>
                      <div className="mt-20">
                        <SortableMultiSelect
                          selected={crmType}
                          handleChange={handleCrmType}
                          options={LEAD_TYPES}
                        />
                      </div>
                    </li>
                  </ul>

                  <li className="mapHeader">Configure lead status mapping</li>

                  <CrmModal
                    tenantData={tenantData}
                    setCrmData={setCrmData}
                    autoAssignLeadToKelsey={tenantData.autoAssignLeadToKelsey}
                    allowedFirstContactTypes={z}
                  />
                </ul>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div className="w-100-left">
            <Button
              onClick={handleconfigIntegration}
              className="save-btn"
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={handleClose} className="clear-btn" variant="text">
              Clear
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SettingsModal;
