import React, { useState } from 'react';
import NewTeplate from './modal/NewTeplate';

const Template = props => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <NewTeplate open={open} setOpen={setOpen} onClose={props.onClose} />
    </>
  );
};

export default Template;
