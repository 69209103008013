import { createSlice } from '@reduxjs/toolkit';
import {
  changeWorkflowStatus,
  createWorkflow,
  deleteWorkflow,
  fetchAllWorkflows,
  updateWorkflow
} from '../Actions/WorkflowV1';

const initialState = {
  clientsLoading: false,
  clientsList: [],
  workflowLoading: false,
  workflowData: [],
  isCreating: false
};
const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {},
  extraReducers: {
    [createWorkflow.pending]: state => {
      state.isCreating = true;
    },
    [createWorkflow.fulfilled]: (state, payload) => {
      state.isCreating = false;
    },
    [createWorkflow.rejected]: state => {
      state.isCreating = false;
    },
    [updateWorkflow.pending]: state => {
      state.isCreating = true;
    },
    [updateWorkflow.fulfilled]: (state, payload) => {
      state.isCreating = false;
    },
    [updateWorkflow.rejected]: state => {
      state.isCreating = false;
    },
    [fetchAllWorkflows.pending]: state => {
      state.workflowLoading = true;
    },
    [fetchAllWorkflows.fulfilled]: (state, action) => {
      state.workflowLoading = false;
      state.workflowData = action?.payload?.data?.data;
    },
    [fetchAllWorkflows.rejected]: state => {
      state.workflowLoading = false;
    },
    [deleteWorkflow.pending]: state => {
      state.deleteLoading = true;
    },
    [deleteWorkflow.fulfilled]: (state, action) => {
      state.deleteLoading = false;
    },
    [deleteWorkflow.rejected]: state => {
      state.deleteLoading = false;
    },
    [changeWorkflowStatus.pending]: state => {
      state.changeWorkFlowStatusLoading = true;
    },
    [changeWorkflowStatus.fulfilled]: (state, action) => {
      state.changeWorkFlowStatusLoading = false;
      state.changeWorkFlowStatus = action.payload.data.data;
    },
    [changeWorkflowStatus.rejected]: state => {
      state.changeWorkFlowStatusLoading = false;
    }
  }
});

export default workflowSlice;
