export const Path = {
  CREATE_WORKFLOW: '/v2/workflow',
  FETCH_ALL_WORKFLOWS: '/v2/workflow/all',
  DELETE_WORKFLOW: '/v2/workflow/',
  CHANGE_WORKFLOW_STATUS: '/v2/workflow/status',
  UPDATE_WORKFLOW: '/v2/workflow',
  GET_TIMESLOTS: '/tours/schedule',
  CREATE_TOUR: '/tours/schedule',
  GET_CONVERSATION_SUMMARY: '/summary',
  GET_ALL_MESSAGES: '/message/all',
  UPDATE_LEASING_AGENTS: '/apartment',
  GET_ALL_APARTMENT_BY_ID: '/apartment/',
  GET_ALL_PROPERTY: '/property/all',
  GET_PROPERTY_APARTMENT: '/property/propertyByApartment',
  GET_INBOX_META: '/inboxmeta/',
  SEND_NEW_MESSAGE: '/message/send',
  GET_ALL_TEMPLATES: '/template/v2',
  GET_TOUR_CONFIRMATION: '/tours/confirmation',
  CREATE_NOTES: '/notes',
  CREATE_TOUR_NOTES: '/tournotes',
  GET_TOUR_NOTES_BY_TOURID: '/tournotes',
  UPDATE_STAGE: '/lead/stage-new',
  GET_PRICING_LISTINGS: '/apartment/avlUnits',
  DELETE_TOUR: '/tours/cancel',
  GET_AVAILABLE_NUMBER: '/tenant/communityphones',
  SELF_SERVE_PORTAL_NEW: '/portal/create-portal',
  SELF_SERVE_UPDATE: '/portal',
  GET_TENANT_DETAILS: '/portal/tenant',
  GET_YARDI_COMMUNITY_AGENTS: '/portal/yardiCommunityAgents',
  VERIFY_COMMUNITY: '/portal/verifyCommunity',
  INGEST_COMMUNITY: '/portal/ingestCommunities',
  DOWNLOAD_CHEATSHEET: '/uploadcsv/download',
  UPLOAD_CHEATSHEET: '/apartment/cheatsheet/upload',
  VALIDATE_PMS: '/portal/validate',
  GET_ALL_USERS_DATA: '/management/users/all',
  INTERNAL_SETTINGS: '/mandatoryData',
  RESTRICT_COMMUNICATION: '/portal/restrictCommunication',
  GET_CHANNEL_ACCOUNT: '/channel-account',
  UPDATE_WORKING_HOURS_SELF_SERVE: '/portal/timezoneAndWorkingHours',
  ONBOARD_COMMUNITY: '/apartment/apartments-new',
  CREATE_NEW_CHANNEL_ACCOUNT: '/channel-account',
  UPDATE_CHANNEL_EMAIL_STATUS: '/channel-account/emailstatus',
  DELETE_NEW_CHANNEL_ACCOUNT: '/channel-account',
  INTEGRATION_SELF_SERVE: '/apartment/all',
  GET_INTEGRATION_FLAG: '/portal/updateCommunityFlags',
  CONFIGURE_INTEGRATION: '/portal/onboardingSettings',
  INGESTED_COMMUNITIES: '/portal/ingestedCommunities',
  DELETE_PHONE_NUMBER: '/tenant/deletePhoneNo',
  APARTMENT_CHEATSHEET: '/apartment/apartment-cheatsheet',
  DOWNLOAD_CHEATSHEET_BY_APARTMENT_IDS: '/uploadcsv/downloadByApartmentIds',
  VERIFY_RENT_CAFE: '/portal/verifyRentCafePropertyCode',
  GET_SESSION_DATA: '/v1/kelsey-utils/session/get_session_by_lead_id',
  UPDATE_SESSION_DATA: '/v1/kelsey-utils/session/update_slot_data',
  UPDATE_CONTEXT_AI: '/v1/kelsey-utils/session/update_session',
  TRIGGER_KELSEY: '/kelseyInsights/triggerKelsey',
  KELSEY_CHEATSHEET_URL:
    '/v1/kelsey-utils/smart_cheat_sheet/create_smart_cheatsheet_document',
  KELSEY_CHEATSHEET_UPDATE:
    '/v1/kelsey-utils/smart_cheat_sheet/update_smart_cheatsheet_document',
  KELSEY_AUTOFILL_SUGGESTION_CREATE:
    '/v1/kelsey-utils/auto_fill/create_autofill_response',
  KELSEY_AUTOFILL_SUGGESTION_UPDATE:
    '/v1/kelsey-utils/auto_fill/updated_autofill_response',
  START_KB_INGESTION: '/v1/kelsey-utils/publish/start_kb_ingestion',
  GET_MASTER_CHEATSHEET: '/v1/knowledge-base/tenant_master_qn_list',
  UPDATE_CHEATSHEET_QUESTIONS: '/v1/knowledge-base/custom_dump/update',
  ADD_CHEATSHEET_QUESTION_ACROSS_COMMUNITIES:
    '/v1/knowledge-base/master_tenant/add_qn',
  DELETE_CHEATSHEET_QUESTION_ACROSS_COMMUNITIES:
    '/v1/knowledge-base/master_tenant/remove_qn_for_tenant',
  ADD_CHEATSHEET_QUESTION_ANSWERS: '/v1/knowledge-base/custom_dump/add',
  GET_KB_CHEATSHEET: '/v1/knowledge-base/custom_dump',
  UPLOAD_SOURCESHEET: '/vanity-number/upload/',
  GET_WORKING_HOURS: '/knowledge-base/officeHours',
  UPDATE_WORKING_HOURS_SELF_SERVE_V2: '/knowledge-base/officeHours'
};
