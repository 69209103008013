import React from 'react';
import { RightSideCards } from '../LeadDetails/LeadDetailsEdit/leadDetailsEdit.styles';
import { Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import UnansweredQuery from './UnansweredQuery';
import {
  EmptyState,
  SectionHeader,
  UnansweredQueriesList
} from './kelsey-feedback.styles';

const KelseyFeedback = props => {
  const theme = useTheme();
  const { unansweredLeadQueries, queryAnsweredClickHandler } = props || {};

  return (
    <RightSideCards className="right-side-cards">
      <SectionHeader className="d-flex-row flex">
        <Typography
          className="card-title "
          style={{ color: theme?.text?.heading }}
        >
          Unanswered Questions
        </Typography>
      </SectionHeader>
      <UnansweredQueriesList aria-label="list__unanswered-questions">
        {unansweredLeadQueries?.length ? (
          unansweredLeadQueries?.map(item => {
            return (
              <UnansweredQuery
                key={item?._id}
                queryString={item?.query}
                messageId={item?.messageId}
                onAcknowledge={e => queryAnsweredClickHandler(e, item?._id)}
              />
            );
          })
        ) : (
          <EmptyState>No pending questions.</EmptyState>
        )}
      </UnansweredQueriesList>
    </RightSideCards>
  );
};

export default KelseyFeedback;
