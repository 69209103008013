import { createContext, useCallback, useContext, useState } from 'react';
import { postKelsey } from '../../Redux/networkClient';
import { Path } from '../../Redux/util/path';
import { getAnnouncementsByAgent } from '../../gql/queries/sopAndAnnouncements';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateSopAcknowledgementByAgent } from '../../gql/mutations/sopAndAnnouncements';
import { setGlobalError } from '../../Redux/Reducer/GlobalError';
import { useDispatch } from 'react-redux';
import { socketEmitter, socketListener } from '../socket';
import { GlobalContext } from './GlobalContextProvider';
import _ from 'lodash';

const InboxContext = createContext({});
export const useInboxContext = () => useContext(InboxContext);

const InboxContextProvider = ({ children }) => {
  const [templateRecommendation, setTemplateRecommendation] = useState(null);
  const [scheduledMessages, setScheduledMessages] = useState(null);

  const [selectedOrClosedTemplate, setSelectedOrClosedTemplate] =
    useState(null);
  const [agentAnnouncements, setAgentAnnouncements] = useState([]);
  const [showAnnouncementsModal, setShowAnnouncementsModal] = useState(false);
  const [announcementsByCommunity, setAnnouncementsByCommunity] = useState([]);
  const [leadMessage, setLeadMessage] = useState([]);
  const [lastIncomingMessages, setLastIncomingMessages] = useState([]);
  const [isLeadAngry, setIsLeadAngry] = useState(false);
  const [isUpdatingMessageList, setIsUpdatingMessageList] = useState(false);

  const dispatch = useDispatch();
  const { userData } = useContext(GlobalContext);
  const getTemplateRecommendation = async ({
    leadId = '',
    templateSearchQuery = '',
    tenantId = ''
  }) => {
    if (selectedOrClosedTemplate == null || !selectedOrClosedTemplate.used) {
      if (
        (templateRecommendation?.recommendationId &&
          !templateRecommendation?.used) ||
        selectedOrClosedTemplate?.recommendationId
      ) {
        // Call Update handler
        await updateTemplateRecommendation({
          recommendationId:
            templateRecommendation?.recommendationId ||
            selectedOrClosedTemplate?.recommendationId,
          leadId,
          templateSearchQuery,
          tenantId
        });
      } else {
        // Call create handler
        await createTemplateRecommendation({
          leadId,
          templateSearchQuery,
          tenantId
        });
      }
    }
  };

  const setTemplateDataRes = res => {
    if (
      res?.data?.data?._id &&
      res?.data?.data?.suggested_template &&
      res?.data?.data?.suggested_template.length > 0
    ) {
      setTemplateRecommendation({
        templateText: res?.data?.data?.suggested_template,
        recommendationId: res?.data?.data?._id?.$oid,
        source: res?.data.data?.suggested_template_name,
        closed: false,
        used: false
      });
    }
  };

  const createTemplateRecommendation = async ({
    leadId,
    templateSearchQuery,
    tenantId
  }) => {
    // Create Message Recommendation from Kelsey
    const body = {
      tenant_id: tenantId,
      lead_id: leadId,
      template_search_query: templateSearchQuery
    };
    if (tenantId && leadId && templateSearchQuery) {
      const res = await postKelsey(
        Path.KELSEY_AUTOFILL_SUGGESTION_CREATE,
        body
      );
      setTemplateDataRes(res);
    } else {
      dispatch(setGlobalError('Error in creating template recommendation'));
    }
  };

  const updateTemplateRecommendation = async ({
    recommendationId,
    leadId,
    templateSearchQuery,
    tenantId
  }) => {
    // Updated Message Recommendation from Kelsey
    const body = {
      _id: recommendationId,
      tenant_id: tenantId,
      lead_id: leadId,
      template_search_query: templateSearchQuery
    };

    const res = await postKelsey(Path.KELSEY_AUTOFILL_SUGGESTION_UPDATE, body);
    setTemplateDataRes(res);
  };

  const selectTemplateRecommendation = ({ setMessageInput }) => {
    // Close Template Recommendation
    if (
      templateRecommendation &&
      templateRecommendation.templateText &&
      !templateRecommendation.used
    ) {
      setMessageInput(templateRecommendation.templateText);
    }
    setSelectedOrClosedTemplate({
      ...templateRecommendation,
      used: true
    });
    setTemplateRecommendation({
      ...templateRecommendation,
      used: true
    });

    setTimeout(() => {
      setTemplateRecommendation({
        ...templateRecommendation,
        closed: true,
        used: true
      });
      setTimeout(() => {
        setTemplateRecommendation(null);
      }, 200);
    }, 800);
  };

  const messageSentTemplateEvent = async ({
    messageSent,
    tenantId,
    leadId
  }) => {
    // Notify if template was chosen and final message sent
    let recordId = '';
    let templateUsed = false;
    if (
      selectedOrClosedTemplate &&
      selectedOrClosedTemplate?.recommendationId
    ) {
      recordId = selectedOrClosedTemplate?.recommendationId;
      templateUsed = selectedOrClosedTemplate?.used;
    } else if (
      templateRecommendation &&
      templateRecommendation?.recommendationId
    ) {
      recordId = templateRecommendation?.recommendationId;
      templateUsed = templateRecommendation?.used;
    }

    if (recordId.length > 0) {
      const body = {
        _id: recordId,
        tenant_id: tenantId,
        lead_id: leadId,
        final_message_sent: messageSent,
        is_template_used: templateUsed
      };
      await postKelsey(Path.KELSEY_AUTOFILL_SUGGESTION_UPDATE, body);
    }
  };

  const closeTemplateRecommendation = () => {
    // Close Template Recommendation
    setSelectedOrClosedTemplate({
      ...templateRecommendation,
      used: false
    });
    setTemplateRecommendation({
      ...templateRecommendation,
      closed: true
    });
    setTimeout(() => {
      setTemplateRecommendation(null);
    }, 200);
  };

  const resetTemplateRecommendation = () => {
    // Close Template Recommendation
    if (templateRecommendation) {
      setTemplateRecommendation({
        ...templateRecommendation,
        closed: true
      });
    }

    setTimeout(() => {
      setTemplateRecommendation(null);
      setSelectedOrClosedTemplate(null);
    }, 200);
  };

  const storeAnnouncementsByCommunity = id =>
    setAnnouncementsByCommunity([...id]);

  const [
    fetchAgentAnnouncements,
    { loading: agentAnnouncementsLoading, error }
  ] = useLazyQuery(getAnnouncementsByAgent, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getAgentAnnouncements }) => {
      setAgentAnnouncements(getAgentAnnouncements);
    }
  });

  const [sopAcknowledgementByAgent] = useMutation(
    updateSopAcknowledgementByAgent,
    {
      variables: { id: announcementsByCommunity },
      refetchQueries: [{ query: getAnnouncementsByAgent }]
    }
  );

  const toggleAnnouncementsModalVisibility = updatedState => {
    setShowAnnouncementsModal(updatedState);
  };

  // handle incoming messages from socket
  const handleIncomingSocketData = useCallback(
    (leadId, messages) => {
      setLeadMessage(prev => {
        const unreadMessages = _.differenceBy([...messages], [...prev], '_id');
        if (unreadMessages?.length) {
          setIsUpdatingMessageList(true);
          const lastMessages = [];
          for (let i = messages?.length - 1; i >= 0; i--) {
            messages[i].isUnread = messages[i]?._id === unreadMessages[0]?._id;

            if (messages[i]?.type === 'incoming') {
              lastMessages.push({
                text: messages[i]?.body,
                id: messages[i]?._id
              });
              const lastMsg = messages[messages.length - 1];
              if (
                lastMsg?.type === 'incoming' &&
                lastMsg?.sentiment?.initialCalculatedSentiment === 'negative' &&
                lastMsg?.sentiment?.score >= 60 &&
                lastMsg?.sentiment?.score <= 90
              ) {
                setIsLeadAngry(true);
              }
            } else {
              if (lastMessages?.length) {
                setLastIncomingMessages([...lastMessages]);
                break;
              } else if (messages[i]?.type === 'outgoing') {
                setLastIncomingMessages([
                  {
                    text: messages[i]?.body,
                    id: messages[i]?._id,
                    isOutGoing: true
                  }
                ]);
                setIsLeadAngry(false);
                break;
              }
            }
          }
          setIsUpdatingMessageList(false);
          if (leadId) {
            return messages;
          }
        }

        return prev;
      });
    },
    [leadMessage]
  );

  const handleIncomingSocketDataForScheduledMessages = (leadId, messages) => {
    setScheduledMessages(messages);
  };

  const fetchNewTimeLineEvents = leadId => {
    if (leadId) {
      socketEmitter('getLeadMessages', {
        leadId: leadId,
        user: userData
      });

      socketListener(`messages-${leadId}`, handleIncomingSocketData);
    }
  };

  const fetchScheduledMessages = leadId => {
    if (leadId) {
      socketEmitter('getLeadScheduledMessages', {
        leadId: leadId,
        user: userData
      });
      socketListener(
        `scheduled-messages-${leadId}`,
        handleIncomingSocketDataForScheduledMessages
      );
    }
  };
  //update lead's mood
  const updateLeadMood = state => {
    setIsLeadAngry(state);
  };

  const value = {
    templateRecommendation,
    agentAnnouncements,
    showAnnouncementsModal,
    agentAnnouncementsLoading,
    isLeadAngry,
    leadMessage,
    lastIncomingMessages,
    isUpdatingMessageList,
    closeTemplateRecommendation,
    getTemplateRecommendation,
    resetTemplateRecommendation,
    selectTemplateRecommendation,
    messageSentTemplateEvent,
    toggleAnnouncementsModalVisibility,
    sopAcknowledgementByAgent,
    storeAnnouncementsByCommunity,
    fetchAgentAnnouncements,
    updateLeadMood,
    fetchNewTimeLineEvents,
    handleIncomingSocketData,
    scheduledMessages,
    setScheduledMessages,
    fetchScheduledMessages
  };

  return (
    <InboxContext.Provider value={value}>{children}</InboxContext.Provider>
  );
};

export default InboxContextProvider;
