import { gql } from '@apollo/client';

export const propertyByApartment = gql`
  query propertiesByApartmentId($apartmentId: ID!) {
    propertiesByApartmentId(apartmentId: $apartmentId) {
      name
      unitName
      leaseData {
        bestRent
      }
      bedRooms
      bathRooms
      monthlyRent
      rent {
        minRent
      }
      sqft
      _id
    }
  }
`;
