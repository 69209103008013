import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TextField } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const GifModal = ({ open, handleGifModalClose, image, handleSubmitGif }) => {
  const [message, setMessage] = React.useState('');

  const handleChange = e => {
    setMessage(e.target.value);
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth="sm"
        style={{ textAlign: 'center' }}
        onClose={handleGifModalClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <img style={{ marginBottom: '20px' }} src={image} alt="gif_image" />
          <TextField
            fullWidth
            id="outlined-basic"
            onChange={handleChange}
            value={message}
            label="Message"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGifModalClose}>Cancel</Button>
          <Button
            onClick={e => {
              e.preventDefault();
              handleSubmitGif(message);
            }}
          >
            Send
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default GifModal;
