import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { getAllUsers } from '../../../gql/queries/userData';
import { useMutation, useQuery } from '@apollo/client';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { userUpdateById } from '../../../gql/mutations/updateUser';
import { ROLES } from '../../../utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  }
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const dialogTitleBackground = {
  online: `linear-gradient(180deg, rgba(66, 255, 0, 0.2) -14.15%, rgba(255, 214, 0, 0) 100%)`,
  onBreak: `linear-gradient(180deg, rgba(255, 153, 0, 0.2) -14.15%, rgba(255, 214, 0, 0) 100%)`,
  offline: `linear-gradient(180deg, rgba(196, 196, 196, 0.2) -14.15%, rgba(255, 255, 255, 0) 100%)`
};

export default function CustomizedDialogs({
  open,
  setOpen,
  selected,
  tenantId,
  tenantName
}) {
  const [availableClients, setAvailableClients] = useState([]);
  const [trainedClients, setTrainedClients] = useState([]);

  const { userData } = useContext(GlobalContext);
  const { data } = useQuery(getAllUsers, {
    variables: {
      uid: userData?.user_id
    },
    enabled: false,
    skip: !open
  });

  const [searchValue, setSearchValue] = useState('');

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [userUpdate] = useMutation(userUpdateById, {
    refetchQueries: [
      {
        query: getAllUsers,
        variables: {
          uid: userData?.user_id,
          tenantId
        }
      }
    ]
  });
  function handleClick(data) {
    let check = find(data?.userAccess?.clients?.trained, {
      _id: tenantId
    });
    if (check) {
      alert('This user was already trained');
    } else {
      let addClient = { _id: tenantId, name: tenantName };
      let assignedNewData = [...data?.userAccess?.clients?.trained, addClient];

      let userAccess = {
        clients: {
          assigned: data?.userAccess?.clients?.assigned,
          trained: assignedNewData
        }
      };
      let finalData = { ...data, userAccess: userAccess };
      setTrainedClients([...trainedClients, finalData]);
      userUpdate({
        variables: {
          _id: data?._id,
          record: { userAccess: finalData?.userAccess }
        }
      });
    }
  }

  function handleRemove(data) {
    let removedAssignedData = data?.userAccess?.clients?.trained?.filter(
      item => item._id !== tenantId
    );

    let userAccess = {
      clients: {
        assigned: data?.userAccess?.clients?.assigned,
        trained: removedAssignedData
      }
    };

    let finalData = { ...data, userAccess: userAccess };
    const removeObj = trainedClients.filter(x => x._id !== data._id);
    setTrainedClients(removeObj);
    userUpdate({
      variables: {
        _id: data?._id,
        record: { userAccess: finalData?.userAccess }
      }
    });
  }

  useEffect(() => {
    if (data?.getAllUsers) {
      const assignedData = filter(data?.getAllUsers, o =>
        find(o?.userAccess?.clients?.trained, {
          _id: tenantId
        })
      );

      setAvailableClients(data?.getAllUsers);
      setTrainedClients(assignedData);
    }
  }, [data?.getAllUsers.length > 0]);

  const colors = {
    SUPER_ADMIN: '#D4FCC9',
    AGENT: '#D1A7FA',
    MANAGER: '#FFE380',
    CLIENT_ADMIN: '#F6C4A4',
    TESTER: '#D1A7FA'
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        marginBottom: '-2px',
        '& .MuiDialog-paper': {
          maxWidth: '700px',
          width: '700px',
          backgroundColor: '#F8F8F8'
        }
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{
          marginBottom: '-2px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        Users for {tenantName}
      </BootstrapDialogTitle>
      <DialogContent sx={{ overflow: 'hidden', padding: '0' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '0.25rem'
          }}
        >
          <TextField
            sx={{
              width: '269px',
              height: '36px',
              input: {
                '&::placeholder': {
                  color: '#C4C4C4',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: 1
                }
              }
            }}
            onChange={handleSearch}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              type: 'search',
              style: {
                borderRadius: '4px',
                padding: '4px',
                paddingLeft: '0.5rem'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#a2a2a2' }} />
                </InputAdornment>
              )
            }}
          />
        </div>
        <Grid
          container
          columns={24}
          sx={{ marginTop: '23px', marginLeft: '10px' }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                overflow: 'hidden',
                maxHeight: '440px',
                marginBottom: '10px',
                width: '315px'
              }}
            >
              <div
                style={{
                  padding: '10px',
                  fontFamily: 'inter',
                  fontWeight: '500'
                }}
              >
                All Users
                <Badge
                  badgeContent={selected?.clients?.length}
                  color="primary"
                  sx={{
                    background: '#5267FF',
                    marginLeft: '15px',
                    padding: '5px',
                    borderRadius: '50%',
                    color: 'white'
                  }}
                >
                  {availableClients.length}
                </Badge>
              </div>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableBody>
                    {availableClients
                      ?.filter(({ name }) =>
                        name
                          ?.toLowerCase()
                          ?.includes(searchValue?.toLowerCase())
                      )
                      .map(row => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <TableCell
                            key={row.id}
                            align="left"
                            sx={{
                              '&:hover': {
                                '& .MuiButton-root': {
                                  opacity: 1
                                }
                              }
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div
                                style={{
                                  width: '90px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {row.name}
                              </div>
                              <div
                                style={{
                                  padding: '6px',
                                  borderRadius: '15px',
                                  backgroundColor: colors[row?.role?.name]
                                }}
                              >
                                {ROLES[row?.role?.name]}
                              </div>
                              <Button
                                sx={{
                                  padding: '0px',
                                  opacity: 0,
                                  textTransform: 'none',
                                  fontWeight: 500
                                }}
                                variant="text"
                                onClick={() => handleClick(row)}
                              >
                                Add
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                overflow: 'hidden',
                maxHeight: '440px',
                marginBottom: '10px',
                width: '315px'
              }}
            >
              <div
                style={{
                  padding: '10px',
                  fontFamily: 'inter',
                  fontWeight: '500'
                }}
              >
                Trained Users
                <Badge
                  badgeContent={selected?.clients?.length}
                  color="primary"
                  sx={{
                    background: '#5267FF',
                    marginLeft: '15px',
                    padding: '5px',
                    borderRadius: '50%',
                    color: 'white'
                  }}
                >
                  {trainedClients.length}
                </Badge>
              </div>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table>
                  <TableBody>
                    {trainedClients
                      ?.filter(({ name }) =>
                        name
                          ?.toLowerCase()
                          ?.includes(searchValue?.toLowerCase())
                      )
                      ?.map(row => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <TableCell
                            key={row._id}
                            align="left"
                            sx={{
                              '&:hover': {
                                '& .MuiButton-root': {
                                  opacity: 1
                                }
                              }
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div
                                style={{
                                  width: '90px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {row.name}
                              </div>
                              <div
                                style={{
                                  padding: '6px',
                                  borderRadius: '15px',
                                  backgroundColor: colors[row?.role?.name]
                                }}
                              >
                                {ROLES[row?.role?.name]}
                              </div>
                              <Button
                                sx={{
                                  padding: '0px',
                                  opacity: 0,
                                  color: '#FF4D00',
                                  fontWeight: 500,
                                  textTransform: 'none'
                                }}
                                variant="text"
                                onClick={() => handleRemove(row)}
                              >
                                Remove
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
}
