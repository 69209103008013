import { gql } from '@apollo/client';

export const getUnansweredQueriesByLeadId = gql`
  query getLeadsUnAnsweredQueries($leadId: String!) {
    getLeadsUnAnsweredQueries(leadId: $leadId) {
      _id
      query
      messageId
      tenantId
      aptId
      kelseyTool
      status
      hasAgentAnswered
      hasKelseyAnswered
      createdAt
      updatedAt
    }
  }
`;
