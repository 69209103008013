import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from './styles';
import { TextField } from '@mui/material';
import EmailEditor from './EmailEditor';
import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  convertToRaw,
  Modifier,
  convertFromRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import { TEMPLATE_CONSTANTS } from './constants';
import { htmlToText } from 'html-to-text';

const EmailTemplate = ({
  open,
  handleClose,
  handleSubmit,
  templateMessage
}) => {
  const classes = useStyles();

  const [subject, setSubject] = React.useState(templateMessage.subject);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);

  React.useEffect(() => {
    if (templateMessage.message !== '') {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(templateMessage.message)
          )
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setSubject(templateMessage.subject);
  }, [templateMessage]);

  const onEditorStateChange = editorState => {
    setFocused(false);
    setEditorState(editorState);
  };

  const handleChange = event => {
    const { value } = event.target;
    setSubject(value);
  };

  const addVariable = variable => {
    if (focused) {
      let temp = `${subject}_${variable}_`;
      setSubject(temp);
    } else {
      const currentContent = editorState.getCurrentContent(),
        currentSelection = editorState.getSelection();

      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        `_${variable}_`
      );

      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );
      setEditorState(newEditorState);
    }
  };

  const handleSaveClick = () => {
    const stringifiedObj = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).toString();
    handleSubmit({
      subject: subject,
      message: stringifiedObj
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.modalHeader} id="responsive-dialog-title">
        Add New Template
      </DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.subHeading}>Template Subject *</div>
          <TextField
            className={classes.inputRoot}
            id="Template Subject"
            variant="outlined"
            fullWidth
            name="subject"
            value={subject}
            onFocus={onFocus}
            placeholder="Template Subject"
            onChange={handleChange}
          />
        </div>
        <div className={classes.buttonContainer}>
          {TEMPLATE_CONSTANTS.map((item, i) => {
            return (
              <Button
                key={i}
                variant="contained"
                className={classes.templateButton}
                onClick={() => addVariable(item.value)}
              >
                {item.name}
              </Button>
            );
          })}
        </div>
        <EmailEditor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.backButton}
          autoFocus
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.formButton}
          onClick={handleSaveClick}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailTemplate;
