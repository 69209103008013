import React, { useEffect, useState } from 'react';
import './toggle.scss';

const ZSwitch = ({ id, name, checked, disabled, small, onChange }) => {
  const [toggled, setToggled] = useState(checked);
  const changeHandler = e => {
    setToggled(!toggled);
    onChange(e);
  };

  useEffect(() => {
    setToggled(checked);
  }, [checked]);

  return (
    <div className="p-10 flex" id={id}>
      <label className={`${small ? 'small' : ''} toggle-switch`}>
        <input
          id={`toggle-${id}`}
          name={name ? name : id}
          type="checkbox"
          onChange={changeHandler}
          disabled={disabled}
          className="toggle-switch-checkbox"
          checked={toggled}
        />
        <span className="switch-control"></span>
      </label>
    </div>
  );
};

export default ZSwitch;
