import { createAsyncThunk } from '@reduxjs/toolkit';
import { availableNumberApi, uploadFileApi } from '../util/Apis/chat';

export const fetchAvailableNumbers = createAsyncThunk(
  'callText/fetchAvailableNumbers',
  async ({ code, id }, { rejectWithValue }) => {
    try {
      return await availableNumberApi({ code, id });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'callText/uploadFile',
  async ({ file, type, id }, { rejectWithValue }) => {
    try {
      return await uploadFileApi({ file, type, id });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
