import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, connect } from 'react-redux';
import {
  fileUploadAction,
  LoadingAction
} from '../../Redux/Actions/FileUpload';
import FileUploader from '../../Media/fileUpload.png';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import {
  uploaderStyle,
  ImgStyle,
  deleteStyle,
  radioBtn
} from '../../styles/propertystyles/Propertystyle';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

const ImageUpload = props => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const FileUploadFunc = e => {
    const files = e.target.files;
    let fileList = Array.from(files);
    fileList.map(res => {
      dispatch(fileUploadAction(res));
      dispatch(LoadingAction(true));
    });
    props.setMessage(false);
  };
  const onButtonClick = () => {
    inputFile.current.click();
  };

  useEffect(() => {
    let Files = props.tempFile;
    if (props.Files.name) {
      props.settempFile([...Files, props.Files]);
    }
  }, [props.Files]);

  useEffect(() => {
    if (
      props.tempFile !== undefined &&
      props.tempFile !== null &&
      props.tempFile.length === 0
    ) {
      props.setheroImage(null);
    }
  }, [props.tempFile]);
  const deleteFile = i => {
    //
    const filteredItems = props.tempFile
      .slice(0, i)
      .concat(props.tempFile.slice(i + 1, props.tempFile.length));

    //

    //

    props.settempFile(filteredItems);

    //
  };

  const handleChange = (name, id) => {
    props.setheroImage({
      name: name,
      _id: id
    });
    props.setCoverMessage(false);
  };
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        multiple
        onChange={event => FileUploadFunc(event)}
        onClick={event => {
          event.target.value = null;
        }}
      />
      {props.tempFile &&
        props.tempFile.map((res, i) => {
          return (
            <span>
              <Tooltip title="Select As Cover Photo">
                <GreenRadio
                  style={radioBtn}
                  checked={
                    props.heroImage ? props.heroImage.name === res.name : false
                  }
                  onChange={event => handleChange(res.name, res._id)}
                  value={res.name}
                />
              </Tooltip>
              <img src={res.name} style={ImgStyle} alt="file" />
              <DeleteIcon style={deleteStyle} onClick={() => deleteFile(i)} />
            </span>
          );
        })}
      {props.Loading ? (
        <CircularProgress style={{ padding: '32px' }} />
      ) : (
        <img
          style={uploaderStyle}
          src={FileUploader}
          alt="FileUploader"
          onClick={onButtonClick}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  Files: state.FileUpload.Files,
  Loading: state.FileUpload.loading,
  ImageLoader: state.Property.ImageLoader
});

export default connect(mapStateToProps)(ImageUpload);
