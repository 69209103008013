import React from 'react';
import { Switch } from 'react-router-dom';
import routeConfig from './routesConfig';
import PrivateRoute from './PrivateRoute';

const Routes = props => {
  return (
    <Switch>
      {routeConfig.map((route, i) => (
        <PrivateRoute key={route} {...route} />
      ))}
    </Switch>
  );
};

export default Routes;
