import React from 'react';

const OfficeBuilding = ({ color = '#42526E' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="office-building">
        <rect width="14" height="14" fill="white" fill-opacity="0.01" />
        <g id="Vector">
          <path
            d="M4.66667 3.5H2.92192C2.27617 3.5 1.75 4.06117 1.75 4.75417V10.9958C1.75 11.69 2.275 12.25 2.92192 12.25H6.12267H4.66667V10.9988C3.724 10.9982 2.91667 10.9964 2.91667 10.9964C2.91667 10.9964 2.91958 4.75008 2.92192 4.75008L4.66667 4.75067V3.5Z"
            fill={color}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 4.08333H8.16667V5.25H7V4.08333ZM3.5 5.83333H4.66667V7H3.5V5.83333ZM3.5 7.58333H4.66667V8.75H3.5V7.58333ZM7 5.83333H8.16667V7H7V5.83333ZM7 7.58333H8.16667V8.75H7V7.58333ZM8.16667 9.33333H9.33333V11.0833H8.16667V9.33333ZM9.33333 4.08333H10.5V5.25H9.33333V4.08333ZM9.33333 5.83333H10.5V7H9.33333V5.83333ZM9.33333 7.58333H10.5V8.75H9.33333V7.58333Z"
            fill={color}
          />
          <path
            d="M11.0781 11.0833C11.0822 11.0833 11.0833 2.91375 11.0833 2.91375C11.0833 2.91725 6.42192 2.91667 6.42192 2.91667C6.41783 2.91667 6.41667 11.0833 6.41667 11.0833H11.0781ZM5.25 2.91375C5.25 2.27092 5.77617 1.75 6.42192 1.75H11.0781C11.7256 1.75 12.25 2.27092 12.25 2.91375V11.0863C12.2486 11.3959 12.1244 11.6923 11.9047 11.9105C11.685 12.1287 11.3877 12.2508 11.0781 12.25H5.25V2.91375Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default OfficeBuilding;
