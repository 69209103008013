import { GET_ALL_PET_POLICY } from '../Constant';
import { get } from '../networkClient';

const getAllPetPolicy = data => ({
  type: GET_ALL_PET_POLICY,
  data: data
});

export const getAllPetPolicyAction = () => async dispatch => {
  await get('/pet-policies/all').then(resp => {
    if (resp.status === 200) {
      dispatch(getAllPetPolicy(resp.data));
    }
  });
};
