import styled from 'styled-components';

const SliderContainer = styled.div`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const Slider = styled.input`
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: ${({ value, background }) =>
    value == '1' ? '#f8f8f8' : background};
  opacity: ${({ value }) => (value == '1' ? 1 : 0.8)};
  outline: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 46px;
  margin: 10px 0;
  border: 0.5px solid #c4c4c4;

  &::after {
    content: ${({ textOnSlider }) => `"${textOnSlider}"`};
    text-align: center;
    position: absolute;
    color: ${({ value, color }) => (value == '1' ? '#42526E' : color)};
    opacity: 1;
    z-index: ${({ value }) => (value == '1' ? 0 : -1)};
    font-size: ${({ sliderFontSize }) => sliderFontSize};
    line-height: 15px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 65px;
    height: 40px;
    background: ${({ sliderIcon }) => `url(${sliderIcon})`};
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 46px;
    background-color: #fff;
    box-shadow: 1px 0px 13px rgba(0, 0, 0, 0.06);
    opacity: 1;
  }

  &::-moz-range-thumb {
    width: 23px;
    height: 24px;
    border: 0;
    background: url('lock.png');
    cursor: pointer;
    opacity: 1;
  }
`;

export { SliderContainer, Slider };
