import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Layout from '../../Component/layout';
import PropertyCard from './propertyCard';
import {
  getAllPropertyActionByPagination,
  PropertyResetIdAction
} from '../../Redux/Actions/Property';
import Filter from '../Filter/Filter';
import PropertyLoader from './PropertyLoader';
import CustomizedSnackbars from '../../Component/Toast';
import { CardContainer } from '../../styles/propertystyles/Propertystyle';
import Pagination from '../../Component/Common/ZPagination';

const Property = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPropertyActionByPagination());
    dispatch(PropertyResetIdAction(''));
  }, []);
  const PropertyData = props?.PropertyData?.data?.data;
  const [propertyItems, setPropertyItems] = useState();
  const [pageCount, setPageCount] = useState();

  useEffect(() => {
    setPropertyItems(PropertyData?.data);
    setPageCount(Math.ceil(PropertyData?.count / 20));
  }, [PropertyData]);

  const handlePageClick = data => {
    let currentPage = data.selected + 1;

    setPropertyItems([]);
    dispatch(getAllPropertyActionByPagination(currentPage));
  };
  const propertyContainer = {
    marginLeft: props.Filter ? '320px' : '0px',
    display: 'flex',
    flexWrap: 'wrap'
  };
  return (
    <Layout history={props.history}>
      <CustomizedSnackbars
        open={props.Toast.toast}
        severity={props.Toast.severity}
        msg={props.Toast.msg}
      />
      <div style={{ display: 'flex', height: '85vh', overflow: 'scroll' }}>
        {props.Filter && <Filter data={props.history.location} />}
        <div style={propertyContainer}>
          {propertyItems?.length > 0 ? null : <PropertyLoader />}
          {propertyItems?.map(res => {
            return (
              <div style={CardContainer}>
                {res.status !== 'deleted' && (
                  <PropertyCard
                    key={res._id}
                    id={res._id}
                    name={res.name}
                    address={res.addressL1}
                    UnitName={res.unitName}
                    Bedrooms={res.bedRooms}
                    Bathrooms={res.bathRooms}
                    heroImage={res.heroImage}
                    MonthlyRent={res.monthlyRent}
                    history={props.history}
                    PropertyData={props.PropertyData.data}
                    status={res.status}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    PropertyData: state.Property.PropertyData,
    PropertyLoader: state.Property.PropertyLoader,
    Filter: state.Filter.FilterToggle,
    Toast: state.Toast
  };
};
export default connect(mapStateToProps)(Property);
