import { Dialog } from '@material-ui/core';
import React from 'react';

const ChatPopup = ({ open, handleClose, body }) => {
  return (
    <Dialog
      style={{ padding: '20px' }}
      open={open}
      onClose={handleClose}
      scroll="body"
    >
      <div
        style={{ padding: '20px', fontFamily: 'Inter' }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Dialog>
  );
};

export default ChatPopup;
