import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './TextEditor.css';
const TextEditor = props => {
  const [Editor, setEditor] = useState({
    modules: {
      toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', { color: [] }, 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ]
    },
    propTypes: {
      placeholder: 'Start typing your message'
    },
    formats: [
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'color',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'header',
      'strike',
      'link',
      'image',
      'video'
    ]
  });
  const [value, setValue] = useState('');

  const handleChange = value => {
    setValue(value);
  };
  const { htmlToText } = require('html-to-text');
  const html = value;
  const text = htmlToText(html, {
    wordwrap: 130
  });

  props.setMessage && props.setMessage(text);
  props.EmailVal(value);
  return (
    <ReactQuill
      className="txSq"
      value={value}
      onChange={handleChange}
      formats={Editor.formats}
      style={props.custStyle}
      modules={Editor.modules}
      placeholder={Editor.propTypes.placeholder}
    />
  );
};

export default TextEditor;
