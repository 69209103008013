import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import SnackBarCloseIcon from './SnackBarCloseIcon';
import { useDispatch } from 'react-redux';
import { setMessageReadFlag } from '../../../Redux/Reducer/GlobalError';
const ToastV1 = ({ gError }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    gError.map(({ message, type, isRead, id }) => {
      !isRead &&
        enqueueSnackbar(message, {
          variant: type,
          key: id,
          preventDuplicate: true,
          autoHideDuration: null,
          onEnter: () => {
            if (type === 'success') {
              setTimeout(() => {
                closeSnackbar(id);
                dispatch(setMessageReadFlag({ key: id, gError }));
              }, 2000);
            }
          },
          action: () =>
            type === 'success' ? null : (
              <SnackBarCloseIcon
                onClick={() => {
                  closeSnackbar(id);
                  dispatch(setMessageReadFlag({ key: id, gError }));
                }}
              />
            )
        });
    });
  }, [gError]);
  return <></>;
};

export default ToastV1;
