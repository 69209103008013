import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TrashIcon from '../../Media/Inbox/TrashIcon';
import { useStyles } from './styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTour,
  fetchMetaData,
  getNotesByTourId
} from '../../Redux/Actions/InboxV1';
import { Tooltip } from '@mui/material';
import NotesWithData from '../../Media/InboxV1/NotesWithDataIcon';
import NotesWithoutDataIcon from '../../Media/InboxV1/NotesWithoutDataIcon';
import NotesCard from '../NotesCard';
import AppointmentCancelModal from '../AppointmentCancelModal';
import {
  setGlobalError,
  setSuccessInfo
} from '../../Redux/Reducer/GlobalError';
import { setMessagesEmpty } from '../../Redux/Reducer/InboxV1';
import { useTheme } from 'styled-components';
import Icon from '../../globalcomponents/Icon';

const tourTypeEnumData = {
  inperson: 'In-Person',
  selfguided: 'Self Guided',
  '3d': '3D',
  '3D': '3D',
  virtual: 'Virtual'
};

const AppointmentCard = ({
  key,
  fetchTourBack,
  count,
  schedule,
  leadId,
  SelectedBTimezone,
  lead
}) => {
  const [cancelReason, setCancelReason] = useState('');

  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const [handleCancelModalOpen, setHandleCancelModalOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [interest, setinterest] = useState();
  const [unit, setunit] = useState();
  const { slotsMessage, slotsErrorMessage } = useSelector(
    state => state.slotsData
  );

  useEffect(() => {
    if (slotsMessage) {
      dispatch(setSuccessInfo(slotsMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsMessage]);

  useEffect(() => {
    if (slotsErrorMessage) {
      dispatch(setGlobalError(slotsErrorMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsErrorMessage]);
  const handleDeleteConfimationDate = async () => {
    await dispatch(
      deleteTour({ tourId: schedule?._id, cancelReason: cancelReason })
    );
    await dispatch(fetchMetaData({ leadId }));
    setHandleCancelModalOpen(false);
  };

  const handleNotes = () => {
    setNotesOpen(true);
  };

  useEffect(() => {
    dispatch(getNotesByTourId({ notesPayload: String(schedule?._id) }));
  }, [notesOpen]);

  const NotesData = useSelector(state => state?.inboxMessages?.Notes);

  const handleDeleteClick = () => {
    setHandleCancelModalOpen(true);
  };

  const copyVirtualTourLinkHandler = e => {
    e.preventDefault();
    navigator.clipboard.writeText(schedule?.virtualTourLink);
    dispatch(setSuccessInfo('Virtual tour link copied!'));
  };

  return (
    <div>
      <div>
        <NotesCard
          open={notesOpen}
          setOpen={setNotesOpen}
          ActiveId={leadId}
          isZumaTour={schedule?.isZumaTour}
          count={count}
          tourId={schedule?._id}
          NotesData={NotesData}
        />
        <AppointmentCancelModal
          open={handleCancelModalOpen}
          handleDelete={handleDeleteConfimationDate}
          handleClose={() => setHandleCancelModalOpen(false)}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
        <div
          className={
            schedule?.isZumaTour === false
              ? classes.appointmentContainer
              : classes.appointmentContainer2
          }
        >
          <div className={classes.textContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.appointmentTitle}>
                Appointment {count}{' '}
              </Typography>
              {schedule?.isZumaTour === false || schedule?.isSelfBookedTour ? (
                <div className={classes.platformContainer}>
                  <div className={classes.platformText}>{`${
                    schedule?.isSelfBookedTour
                      ? 'Self scheduled'
                      : 'Off Platform'
                  }`}</div>
                </div>
              ) : schedule?.meta?.remoteFlag === true ? (
                <CheckCircleOutlineIcon
                  id="icon-check"
                  sx={{
                    color: '#67C848',
                    height: 18,
                    width: 18,
                    marginLeft: 1
                  }}
                />
              ) : (
                <ErrorOutlineIcon
                  sx={{
                    color: '#FF6F62',
                    height: 18,
                    width: 18,
                    marginLeft: 1
                  }}
                />
              )}
            </div>
            <div style={{ display: 'flex', gap: '6px' }}>
              {schedule?.virtualTourLink && (
                <Icon
                  id={`tour-link-${key}`}
                  alt="virtual-tour-link"
                  src={`link-${theme?.state}.svg`}
                  size="20px"
                  tooltipText="Onsite link. Click to copy."
                  onClick={copyVirtualTourLinkHandler}
                />
              )}
              <Tooltip title="Notes">
                {schedule?.isNote === true ? (
                  <NotesWithData onClick={handleNotes} />
                ) : (
                  <NotesWithoutDataIcon
                    onClick={handleNotes}
                    isZumaTour={schedule?.isZumaTour}
                  />
                )}
              </Tooltip>
              <Tooltip title="Delete">
                <TrashIcon
                  handleClick={handleDeleteClick}
                  className={classes.deleteIcon}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <Typography className={classes.timeAndDateText}>
              {schedule?.confirmedDate ? (
                <span className={classes.timeStamp}>
                  {SelectedBTimezone
                    ? moment(schedule?.confirmedDate)
                        ?.tz(SelectedBTimezone ? SelectedBTimezone : null)
                        ?.format('h:mm a,ddd,DD-MMM-YYYY')
                    : moment(schedule?.confirmedDate)?.format(
                        'h:mm a,ddd,DD-MMM-YYYY'
                      )}
                </span>
              ) : (
                '-'
              )}
            </Typography>
          </div>
          <div className={classes.appointmentContainerGrid}>
            <Typography className={classes.subTitle}>Community: </Typography>
            <Typography className={classes.subDesc}>
              {schedule?.apartment?.name ? schedule?.apartment?.name : '-'}
            </Typography>

            <Typography className={classes.subTitle}>Client: </Typography>
            <Typography className={classes.subDesc}>
              {lead?.tenant?.name ? lead?.tenant?.name : '-'}
            </Typography>

            <Typography className={classes.subTitle}>Tour type: </Typography>
            <Typography className={classes.subDesc}>
              {tourTypeEnumData[schedule?.tourType]}
            </Typography>

            <Typography className={classes.subTitle}>Booked By: </Typography>
            <Typography className={classes.subDesc}>
              {schedule?.meta?.agentName}
              {schedule?.confirmedDate ? (
                <span>
                  @ {moment(schedule?.createdAt).format('LT')}{' '}
                  {moment(schedule?.createdAt).format('DD')}/
                  {moment(schedule?.createdAt).format('MM')}
                </span>
              ) : (
                '-'
              )}
            </Typography>
            {schedule?.onsiteAgentName ? (
              <>
                <Typography className={classes.subTitle}>
                  Onsite Agent:
                </Typography>
                <Typography className={classes.subDesc}>
                  {schedule?.onsiteAgentName}
                </Typography>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
