import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, connect } from 'react-redux';
import { createStepAction } from '../../../Redux/Actions/workflow';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const DelayModal = props => {
  const [Type, setType] = useState('');
  const [UnitNo, setUnitNo] = useState('');
  const [Unit, setUnit] = useState('seconds');
  const [Title, setTitle] = useState('');
  const [valid, setvalid] = useState(false);
  const [ConditionType, setConditionType] = useState('noResponse');
  const dispatch = useDispatch();

  const cardStyle = {
    width: '400px',
    overflowY: 'auto'
  };
  const BtnContainer = {
    marginTop: '16px',
    textAlign: 'right'
  };
  const Cancel = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #023e8a',
    backgroundColor: '#ffffff',
    marginRight: '20px'
  };
  const saveTemp = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff',
    marginRight: '20px'
  };
  const btnTemp = {
    width: '130px',
    height: '40px',
    fontSize: '11px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff'
    // marginLeft: "15px",
  };
  const heading = {
    color: '#20065f',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
    marginBottom: '40px'
  };
  const subHeading = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    marginBottom: '8px'
  };
  const TextFm = {
    marginBottom: '20px'
  };

  //funcBlock
  const CancelThis = () => {
    props.setOpen(false);
    setType(null);
    setUnitNo(null);
    setUnit(null);
    props.handleClose();
  };

  const setUnitNoFun = e => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums >= 0) {
      setUnitNo(onlyNums);
    }
  };
  const ContinueThis = () => {
    if (
      Type !== null &&
      UnitNo !== null &&
      Unit !== null &&
      Title !== null &&
      Type !== '' &&
      UnitNo !== '' &&
      Unit !== '' &&
      Title !== ''
    ) {
      let o = {};
      o.title = Title;
      o.type = 'Delay';
      o.sequenceId = props.paramid;
      o.delaySchema = {
        type: Type,
        subType: Type === 'Conditional' ? ConditionType : 'none',
        delay: UnitNo,
        unit: Unit
      };
      dispatch(createStepAction(o));
      setType(null);
      setUnitNo(null);
      setUnit(null);
      setTitle(null);
      props.setOpen(false);
      props.handleClose();
    } else {
      setvalid(true);
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card style={cardStyle}>
          <CardContent>
            <div>
              <div>
                <div>
                  <div style={heading}>
                    Delay
                    <span>
                      <ClearIcon
                        onClick={() => CancelThis()}
                        style={{
                          cursor: 'pointer',
                          float: 'right'
                        }}
                      />
                    </span>
                  </div>

                  <hr
                    style={{
                      marginBottom: '20px',
                      marginTop: '-26px',
                      width: '703px',
                      marginLeft: '-16px'
                    }}
                  />
                  {/* <div>
                    <div style={subHeading}>Title</div>
                    <div style={TextFm}>
                      <TextField
                        id="Title"
                        variant="outlined"
                        fullWidth
                        error={valid && Title === null}
                        value={Title}
                        placeholder="Subject"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div>
                    <div style={subHeading}>Type</div>
                    <div style={TextFm}>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          native
                          value={Type}
                          onChange={e => setType(e.target.value)}
                          inputProps={{
                            Type: 'type',
                            id: 'filled-age-native-simple'
                          }}
                        >
                          <option value="">None</option>
                          <option value="Simple">Simple</option>
                          <option value="Conditional">Conditional</option>
                        </Select>
                      </FormControl>
                      {Type === 'Simple' && (
                        <>
                          <div style={{ marginTop: '7px' }}>
                            <TextField
                              id="Template Name"
                              variant="outlined"
                              fullWidth
                              value={Title}
                              placeholder="Sequence Name"
                              onChange={e => setTitle(e.target.value)}
                              error={valid && (Title === '' || Title === null)}
                            />
                          </div>
                          <div style={{ marginTop: '7px' }}>
                            <TextField
                              id="Template Name"
                              variant="outlined"
                              fullWidth
                              value={UnitNo}
                              placeholder="Enter Delay"
                              onChange={e => setUnitNoFun(e)}
                              error={
                                valid && (UnitNo === '' || UnitNo === null)
                              }
                            />
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                native
                                value={Unit}
                                onChange={e => setUnit(e.target.value)}
                                inputProps={{
                                  Unit: 'unit',
                                  id: 'filled-age-native-simple'
                                }}
                              >
                                <option value="seconds">Seconds</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                                <option value="days">Day</option>
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      )}
                      {Type === 'Conditional' && (
                        <>
                          <div style={{ marginTop: '10px' }}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                native
                                value={ConditionType}
                                onChange={e => setConditionType(e.target.value)}
                                inputProps={{
                                  ConditionType: 'conditionType',
                                  id: 'filled-age-native-simple'
                                }}
                              >
                                <option value="noResponse">No Response</option>
                              </Select>
                            </FormControl>
                          </div>
                          <div style={{ marginTop: '7px' }}>
                            <TextField
                              id="Template Name"
                              variant="outlined"
                              fullWidth
                              error={valid && (Title === '' || Title === null)}
                              value={Title}
                              placeholder="Sequence Name"
                              onChange={e => setTitle(e.target.value)}
                            />
                          </div>
                          <div style={{ marginTop: '7px' }}>
                            <TextField
                              id="Template Name"
                              variant="outlined"
                              fullWidth
                              error={
                                valid && (UnitNo === '' || UnitNo === null)
                              }
                              value={UnitNo}
                              placeholder="Enter Delay"
                              onChange={e => setUnitNoFun(e)}
                            />
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                native
                                value={Unit}
                                onChange={e => setUnit(e.target.value)}
                                inputProps={{
                                  Unit: 'unit',
                                  id: 'filled-age-native-simple'
                                }}
                              >
                                <option value="seconds">Seconds</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                                <option value="days">Day</option>
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={saveTemp}
                    onClick={() => ContinueThis()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

export default DelayModal;
