import React from 'react';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import AppointmentCard from '../AppointmentCard';

const AppointmentModal = ({
  open,
  setOpenAllAppointments,
  unitSelected,
  interest,
  fetchTourBack,
  schedule,
  leadId,
  propertyData,
  propertyList,
  apartmentData,
  setFeedBackModal,
  lead,
  startDate
}) => {
  const appointmentText = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#525F80'
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={props.handleClosed}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card
          style={{
            width: '746px',
            height: '399px',
            borderRadius: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px'
            }}
          >
            <Typography style={appointmentText}>Appointments</Typography>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenAllAppointments(false)}
            />
          </div>
          <div
            style={{
              flexWrap: 'wrap',
              display: 'flex',
              gap: '20px',
              padding: '20px',
              marginTop: '-25px',
              overflow: 'scroll',
              height: '299px'
            }}
          >
            {schedule?.length > 0 &&
              schedule?.map((res, i) => {
                return (
                  <AppointmentCard
                    count={i + 1}
                    unitSelected={unitSelected}
                    interest={interest}
                    fetchTourBack={fetchTourBack}
                    schedule={res}
                    leadId={leadId}
                    propertyData={propertyData}
                    propertyList={propertyList}
                    leadData={lead}
                    apartmentData={apartmentData}
                    SelectedBTimezone={res?.aptId?.timeZone}
                    setFeedBackModal={setFeedBackModal}
                    lead={lead}
                    startDate={startDate}
                  />
                );
              })}
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default AppointmentModal;
