import React from 'react';
import { useTheme } from 'styled-components';
import Card from '../../../globalcomponents/Card';
import { USER_STATUS_LIST } from '../constants';
import {
  Loader,
  MenuItem,
  MenuList,
  StatusTag,
  UserControlsHeader,
  Username,
  UserStatus
} from './user-controls.styles';

const UserControls = ({
  currentUserName,
  currentUserStatus,
  currentTheme,
  onToggleTheme,
  onToggleUserStatus,
  onSignOut,
  onEditProfileClick,
  isLoadingUserStatus,
  lastClickedStatus
}) => {
  const theme = useTheme();
  const availableAgentStatusToggles = USER_STATUS_LIST.filter(
    statusObj => statusObj?.key !== currentUserStatus
  );
  const currentStatusObject = USER_STATUS_LIST.find(
    ({ key }) => key === currentUserStatus
  );
  return (
    <Card
      id="card__user-controls"
      data-testid="card__user-controls"
      borderRadius="4px"
      boxShadow={'4px 4px 10px 0px #00000040'}
      width="200px"
      padding="10px 0"
      background={theme?.background?.secondary}
    >
      <UserControlsHeader>
        <Username>{currentUserName}</Username>
        <UserStatus>
          <div
            style={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              backgroundColor: currentStatusObject?.indicatorColor
            }}
          />
          <output>{currentStatusObject?.text}</output>
        </UserStatus>
      </UserControlsHeader>
      <MenuList>
        {availableAgentStatusToggles?.map(({ key, indicatorColor, text }) => (
          <MenuItem onClick={evt => onToggleUserStatus(evt, key)} key={key}>
            <span>
              Set yourself{' '}
              <StatusTag color={indicatorColor}>
                {text?.toLowerCase()}
              </StatusTag>
            </span>
            {isLoadingUserStatus && key === lastClickedStatus && <Loader />}
          </MenuItem>
        ))}
      </MenuList>
      <MenuList>
        <MenuItem onClick={onEditProfileClick}>Profile</MenuItem>
        <MenuItem onClick={onToggleTheme}>
          Switch to {currentTheme === 'light' ? 'dark' : 'light'} mode
        </MenuItem>
      </MenuList>
      <MenuList>
        <MenuItem onClick={onSignOut}>Sign out</MenuItem>
      </MenuList>
    </Card>
  );
};

export default UserControls;
