import { get, postUpload, uploadFile, put, post } from '../../networkClient';
import { Path } from '../path';
import axios from 'axios';
import { getCookie } from '../../../utils';

export function availableNumberApi({ code, id }) {
  return get(Path.GET_AVAILABLE_NUMBER, { code, id }, null, true, true);
}

export async function uploadFileApi({ file, type, id }) {
  try {
    const filename = `${Date.now()}-${id}-${file?.name}`;
    const data = new FormData();
    data.append(filename, file);
    const response = await post(
      `/fileUpload/vm`,
      data,
      {},
      {
        headers: {
          'Content-Type': 'audio/mpeg;',
          Authorization: `${getCookie('rb_access_token')}`
        }
      }
    );
    return {
      status: 200,
      message: `file Uploaded successfully`,
      url: response?.data?.location,
      filename,
      type,
      id
    };
  } catch (error) {
    return error;
  }
}

export async function uploadFileApiV2({ file, type, id }) {
  try {
    const filename = `${Date.now()}-${id}-${file?.name}`;
    const data = new FormData();
    data.append(filename, file);
    const response = await post(
      `/fileUpload/ivr-vm`,
      data,
      {},
      {
        headers: {
          'Content-Type': 'audio/mpeg;',
          Authorization: `${getCookie('rb_access_token')}`
        }
      }
    );
    return {
      status: 200,
      message: `file Uploaded successfully`,
      url: response?.data?.location,
      filename,
      type,
      id
    };
  } catch (error) {
    return error;
  }
}
