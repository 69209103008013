import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { MEDIUM_LIST, WORKFLOW_STATUS_LIST } from './constants';

import ZDropDown from '../../Component/Common/ZDropDown';
import ZTextfield from '../../Component/Common/ZTextField';
import { createWorkflow, updateWorkflow } from '../../Redux/Actions/WorkflowV1';
import CreateIcon from '@mui/icons-material/Create';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SmsTemplate from './TemplateModals/SmsTemplate';
import EmailTemplate from './TemplateModals/EmailTemplate';
import HybridModal from './TemplateModals/HybridModal';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { htmlToText } from 'html-to-text';
import { styled } from '@mui/system';
import ZSelect from '../../Component/Common/ZSelect';
import { ContentCopy } from '@mui/icons-material';
import { Delete } from '@material-ui/icons';
import SkipPopup from './SkipPopup';
import { useLazyQuery } from '@apollo/client';
import { getApartmentsByTenantIds } from '../../gql/queries/apartmentByTenant';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import RadioButton from '../../globalcomponents/RadioButton';
import Select from 'react-select';
import { intervalMap } from '.';
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    placement="left"
    arrow={false}
    classes={{ popper: className }}
  />
))(() => ({
  '& .MuiTooltip-tooltip': {
    background: '#fff',
    color: '#696969',
    border: '1px solid #6969694d'
  }
}));

const SequenceForm = ({
  classes,
  showFollowUpMessage = false,
  showAutomatedMessage = false,
  handleNavigation,
  selectedData
}) => {
  const fixedIntervalOptions = [
    { value: 0, label: 'Everyday' },
    { value: 1, label: 'Every alternate day' },
    { value: 2, label: 'Every third day' },
    { value: 3, label: 'Every fourth day' },
    { value: 4, label: 'Every fifth day' }
  ];
  const dispatch = useDispatch();

  const params = useParams();

  const clientData = useSelector(state => state.workflowData);

  const { isCreating } = useSelector(state => state.workflowData);

  const [showFollowUpPage, setShowFollowUpPage] = useState(false);
  const [alignment, setAlignment] = React.useState('yes');
  const { userData } = useContext(GlobalContext);

  const { tenantId } = userData;
  const [
    fetchApartmentByTenantId,
    { data: { apartmentsByTenantIds = [] } = [] }
  ] = useLazyQuery(getApartmentsByTenantIds, {
    onCompleted: ({ apartmentsByTenantIds }) => {
      const { communities } = sequenceData;
      const fetchedApartmentIds = apartmentsByTenantIds?.map(({ _id }) => _id);
      // deselect all communities whose tenant is not selected
      const communitiesWhoseTenantIsSelected = communities?.filter(
        ({ _id }) => fetchedApartmentIds?.indexOf(_id) > -1
      );
      setSequenceData(prevState => {
        return { ...prevState, communities: communitiesWhoseTenantIsSelected };
      });
    }
  });

  const handleLeadChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [followUpMedium, setFollowUpMedium] = useState({
    name: '',
    _id: ''
  });

  const [followUpArr, setFollowUpArr] = useState([
    {
      medium: followUpMedium._id,
      smsTemplateMessage: {
        subject: '',
        message: '',
        media: []
      },
      emailTemplateMessage: {
        subject: '',
        message: ''
      },
      key: '',
      totalNoOfSkip: null
    }
  ]);

  const [submitted, setSubmitted] = useState(false);

  const [showSubmitted, setShowSubmitted] = useState(false);

  const [sequenceData, setSequenceData] = useState({
    workflow_name: '',
    stage: {
      name: 'Inbound',
      _id: 'Inbound'
    },
    clients: [],
    communities: [],
    medium: {
      name: '',
      _id: ''
    },
    smsTemplateMessage: {
      subject: '',
      message: '',
      media: []
    },
    emailTemplateMessage: {
      subject: '',
      message: ''
    }
  });

  const [intervalType, setIntervalType] = useState(
    selectedData?.interval ?? intervalMap.custom
  );

  const initIntervalValue =
    intervalType !== intervalMap.custom && selectedData?.totalNoOfSkip
      ? fixedIntervalOptions[selectedData.totalNoOfSkip]
      : null;
  const [selectedFixedInterval, setSelectedFixedInterval] =
    useState(initIntervalValue);
  const [isEdit, setIsEdit] = useState(false);
  const [enableKelseyAfm, setEnableKelseyAfm] = useState(false);

  useEffect(() => {
    if (_.has(params, 'id') && _.has(selectedData, 'workflow_name')) {
      if (selectedData?.enableKelseyAFM) {
        setEnableKelseyAfm(selectedData?.enableKelseyAFM);
      }
      setIsEdit(true);
      const copyMedium = MEDIUM_LIST.filter(item => {
        if (item._id === selectedData.medium) {
          return item;
        }
      });

      let smsCopyObject = {
        subject: '',
        message: '',
        media: []
      };
      let emailCopyObject = {
        subject: '',
        message: ''
      };
      if (
        selectedData.type === 'firstInquiry' ||
        selectedData.type === 'firstEnquiry'
      ) {
        if (selectedData.medium === 'sms') {
          smsCopyObject = selectedData.sequence[0].smsTemplateMessage;
        } else if (selectedData.medium === 'email') {
          emailCopyObject = selectedData.sequence[0].emailTemplateMessage;
        } else {
          smsCopyObject = selectedData.sequence[0].smsTemplateMessage;
          emailCopyObject = selectedData.sequence[0].emailTemplateMessage;
        }
        setSequenceData({
          workflow_name: selectedData.workflow_name,
          stage: {
            name: selectedData.stage,
            _id: selectedData.stage
          },
          clients: selectedData.clients,
          communities: selectedData.communities,
          medium: copyMedium[0],
          smsTemplateMessage: smsCopyObject,
          emailTemplateMessage: emailCopyObject
        });
      } else {
        let copyFollowUpArr = [];
        setFollowUpMedium(copyMedium[0]);
        for (const key in selectedData.sequence) {
          if (selectedData.medium === 'sms') {
            smsCopyObject = selectedData.sequence[key].smsTemplateMessage;
          } else if (selectedData.medium === 'email') {
            emailCopyObject = selectedData.sequence[key].emailTemplateMessage;
          } else {
            smsCopyObject = selectedData.sequence[key].smsTemplateMessage;
            emailCopyObject = selectedData.sequence[key].emailTemplateMessage;
          }
          copyFollowUpArr.push({
            medium: selectedData.medium,
            smsTemplateMessage: smsCopyObject,
            emailTemplateMessage: emailCopyObject,
            key: key,
            totalNoOfSkip:
              fixedIntervalOptions[selectedData?.sequence[key]?.totalNoOfSkip]
          });
        }
        if (copyFollowUpArr.length === 0) {
          copyFollowUpArr = [...followUpArr];
        }
        setFollowUpArr(copyFollowUpArr);
        setSequenceData({
          workflow_name: selectedData.workflow_name,
          stage: {
            name: selectedData.stage,
            _id: selectedData.stage
          },
          clients: selectedData.clients,
          communities: selectedData.communities,
          medium: copyMedium[0]._id,
          smsTemplateMessage: smsCopyObject,
          emailTemplateMessage: emailCopyObject,
          totalNoOfSkip: fixedIntervalOptions[selectedData?.totalNoOfSkip]
        });
      }
    } else {
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    if (selectedData?.clients) {
      setTimeout(() => {
        const clientsList = selectedData?.clients?.map(({ _id }) => _id);
        fetchApartmentByTenantId({ variables: { tenantIds: clientsList } });
      }, 500);
    }
  }, [selectedData?.clients]);

  const handleFormChange = (name, value) => {
    const formValues = { ...sequenceData };
    formValues[name] = value;
    setSequenceData(formValues);
    if (name === 'clients') {
      const tenantIds = value?.map(({ _id }) => _id);
      fetchApartmentByTenantId({ variables: { tenantIds } });
    }
  };

  // useEffect(() => {
  //   dispatch(fetchClientsAndCommunities());
  // }, []);

  useEffect(() => {
    if (submitted && !isCreating) {
      handleNavigation();
    }
  });

  const [openWorkflow, setOpenWorkflow] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const handleSubmit = () => {
    const {
      workflow_name,
      clients,
      communities,
      medium,
      stage,
      smsTemplateMessage,
      emailTemplateMessage
    } = sequenceData;
    let workflowData = {
      workflow_name: workflow_name,
      type: 'firstEnquiry',
      medium: medium._id,
      clients: clients,
      communities: communities,
      stage: stage._id,
      smsTemplateMessage: null,
      emailTemplateMessage: null,
      enableKelseyAFM: enableKelseyAfm,
      interval: intervalType,
      totalNoOfSkip:
        intervalType === intervalMap.fixed ? selectedFixedInterval?.value : 0
    };
    setShowSubmitted(true);
    if (medium._id === 'sms') {
      workflowData.smsTemplateMessage = smsTemplateMessage;
    } else if (medium._id === 'email') {
      workflowData.emailTemplateMessage = emailTemplateMessage;
    } else {
      workflowData.smsTemplateMessage = smsTemplateMessage;
      workflowData.emailTemplateMessage = emailTemplateMessage;
    }
    if (!isEdit) {
      if (showAutomatedMessage) {
        setSubmitted(true);
        delete workflowData.smsTemplateMessage;
        delete workflowData.emailTemplateMessage;
        workflowData.sequence = {
          0: {
            smsTemplateMessage: smsTemplateMessage,
            emailTemplateMessage: emailTemplateMessage
          }
        };
        dispatch(createWorkflow({ workflowData }));
      } else if (showFollowUpPage) {
        if (
          intervalType === intervalMap.fixed &&
          selectedFixedInterval === null
        ) {
          return alert('Please select fixed interval before proceeding!');
        }
        workflowData.type = 'followUp';
        workflowData.medium = followUpMedium._id;
        delete workflowData.smsTemplateMessage;
        delete workflowData.emailTemplateMessage;
        const selectedWorkflow = WORKFLOW_STATUS_LIST.filter(item => {
          if (item._id === workflowData.stage) {
            return item;
          }
        });
        let sequenceObj = {};
        followUpArr.map((followUp, i) => {
          sequenceObj[selectedWorkflow[0].keys[i]] = {
            smsTemplateMessage: followUp.smsTemplateMessage,
            emailTemplateMessage: followUp.emailTemplateMessage,
            totalNoOfSkip: followUp?.totalNoOfSkip?.value
          };
        });
        workflowData.sequence = sequenceObj;
        workflowData.medium = followUpMedium._id;

        setSubmitted(true);
        dispatch(createWorkflow({ workflowData }));
      } else {
        setShowFollowUpPage(true);
      }
    } else {
      if (showAutomatedMessage) {
        setSubmitted(true);
        workflowData._id = params.id;
        delete workflowData.smsTemplateMessage;
        delete workflowData.emailTemplateMessage;
        workflowData.sequence = {
          0: {
            smsTemplateMessage: smsTemplateMessage,
            emailTemplateMessage: emailTemplateMessage
          }
        };
        dispatch(updateWorkflow({ workflowData }));
      } else if (showFollowUpPage) {
        if (
          intervalType === intervalMap.fixed &&
          selectedFixedInterval === null
        ) {
          return alert('Please select fixed interval before proceeding!');
        }
        workflowData.type = 'followUp';
        workflowData._id = params.id;
        delete workflowData.smsTemplateMessage;
        delete workflowData.emailTemplateMessage;
        const selectedWorkflow = WORKFLOW_STATUS_LIST.filter(item => {
          if (item._id === workflowData.stage) {
            return item;
          }
        });
        let sequenceObj = {};
        followUpArr.map((followUp, i) => {
          sequenceObj[selectedWorkflow[0].keys[i]] = {
            smsTemplateMessage: followUp.smsTemplateMessage,
            emailTemplateMessage: followUp.emailTemplateMessage,
            totalNoOfSkip: followUp?.totalNoOfSkip?.value
          };
        });
        workflowData.sequence = sequenceObj;
        workflowData.medium = followUpMedium._id;

        setSubmitted(true);
        dispatch(updateWorkflow({ workflowData }));
      } else {
        setShowFollowUpPage(true);
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const [followUpIndex, setFollowUpIndex] = useState(0);

  const handleClickOpen = i => {
    if (showFollowUpPage) {
      setFollowUpIndex(i);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessageSubmit = (message, images) => {
    if (!showFollowUpPage) {
      let updateSequenceData = { ...sequenceData };
      updateSequenceData = {
        ...updateSequenceData,
        smsTemplateMessage: {
          ...smsTemplateMessage,
          subject: '',
          message: message,
          media: images
        }
      };
      setSequenceData(updateSequenceData);
      handleClose();
    } else {
      const followUpArrCopy = followUpArr.map((item, i) => {
        if (i === followUpIndex) {
          item = {
            ...item,
            smsTemplateMessage: {
              message: message,
              media: images
            }
          };
        }
        return item;
      });
      setFollowUpArr(followUpArrCopy);
      handleClose();
    }
  };

  const handleSequenceAdd = () => {
    const followUpArrCopy = [...followUpArr];
    followUpArrCopy.push({
      medium: followUpMedium._id,
      smsTemplateMessage: {
        subject: '',
        message: ''
      },
      emailTemplateMessage: {
        subject: '',
        message: ''
      },
      totalNoOfSkip:
        intervalType === intervalMap.fixed ? selectedFixedInterval : null
    });
    setFollowUpArr(followUpArrCopy);
    setFollowUpIndex(followUpArrCopy.length - 1);
  };

  const handleEmailSubmit = template => {
    if (!showFollowUpPage) {
      const updateSequenceData = { ...sequenceData };
      updateSequenceData.emailTemplateMessage = template;
      setSequenceData(updateSequenceData);
      handleClose();
    } else {
      const followUpArrCopy = followUpArr.map((item, i) => {
        if (i === followUpIndex) {
          item = {
            ...item,
            emailTemplateMessage: template
          };
        }
        return item;
      });
      setFollowUpArr(followUpArrCopy);
      handleClose();
    }
  };

  const handleHybridSubmit = (smsTemp, emailTemp, images) => {
    if (!showFollowUpPage) {
      const updateSequenceData = { ...sequenceData };
      updateSequenceData.smsTemplateMessage = {
        subject: '',
        message: smsTemp,
        media: images
      };
      updateSequenceData.emailTemplateMessage = emailTemp;
      setSequenceData(updateSequenceData);
      handleClose();
    } else {
      const followUpArrCopy = followUpArr.map((item, i) => {
        if (i === followUpIndex) {
          item = {
            ...item,
            smsTemplateMessage: {
              message: smsTemp,
              media: images
            },
            emailTemplateMessage: emailTemp
          };
        }
        return item;
      });
      setFollowUpArr(followUpArrCopy);
      handleClose();
    }
  };

  const handleFollowUpMedium = (name, value) => {
    setFollowUpMedium(value);
  };

  const handleBack = () => {
    setShowFollowUpPage(false);
  };

  const handleDuplicate = i => {
    const followUpArrCopy = [...followUpArr];
    if (followUpArr.length < 6) {
      followUpArrCopy.push(followUpArr[i]);
      setFollowUpArr(followUpArrCopy);
    }
  };

  const handleDelete = i => {
    setFollowUpIndex(0);
    const followUpArrCopy = [...followUpArr];
    followUpArrCopy.splice(i, 1);
    setFollowUpArr(followUpArrCopy);
  };

  const handleSkip = () => {
    followUpArr.map((x, key, i) => {
      if (key === selectedTemplate.key) {
        x.skip = selectedTemplate?.skip ? !selectedTemplate?.skip : true;
      }
    });
    setFollowUpArr([...followUpArr]);
  };
  const [text, setText] = useState('');
  const handleSkipModal = (followUpData, i, text) => {
    setText(text);
    setSelectedTemplate(followUpData);
    setOpenWorkflow(true);
  };
  const {
    workflow_name,
    stage,
    medium,
    smsTemplateMessage,
    clients,
    communities,
    emailTemplateMessage,
    enableKelseyAFM
  } = sequenceData;

  const handleEnableKelseyAfm = e => {
    setEnableKelseyAfm(!enableKelseyAfm);
  };

  const handleFixedIntervalChange = selectedValue => {
    setSelectedFixedInterval(selectedValue);
    const updatedFollowUpArray = followUpArr?.map(followUpData => {
      return {
        ...followUpData,
        totalNoOfSkip: selectedValue
      };
    });
    setFollowUpArr([...updatedFollowUpArray]);
  };

  const handleIndividualTemplateIntervalChange = (
    selectedValue,
    updatedIndex
  ) => {
    const updatedFollowUpArray = followUpArr?.map((data, idx) => {
      if (idx === updatedIndex) {
        return {
          ...followUpArr[idx],
          totalNoOfSkip: selectedValue
        };
      }
      return data;
    });
    setFollowUpArr([...updatedFollowUpArray]);
  };

  const selectCustomStyles = {
    container: (styles, { disabled }) => ({
      ...styles,
      width: '180px',
      marginLeft: '20px'
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#FFFFFF' : '#FFFFFF',
      boxShadow: 'none',
      border: 0,
      cursor: 'pointer',
      borderRadius: '4px'
    }),
    valueContainer: (styles, { isDisabled }) => ({
      ...styles,
      fontSize: '12px'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#ffffff',
        color: '#393939',
        fontSize: '12px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ':hover': {
          backgroundColor: '#c4c4c41d'
        }
      };
    },
    singleValue: (styles, state) => ({
      ...styles,
      color: '#393939',
      fontSize: '12px'
    }),
    indicatorSeparator: (styles, state) => ({
      ...styles,
      display: 'none'
    }),
    menuList: (styles, state) => ({
      ...styles,
      backgroundColor: '#ffffff',
      maxWidth: '200px'
    }),
    input: (styles, state) => ({
      ...styles,
      color: '#393939'
    })
  };

  const secondarySelectCustomStyles = {
    ...selectCustomStyles,
    container: (styles, { disabled }) => ({
      ...styles,
      width: '180px',
      height: '28px'
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 0,
      cursor: !isDisabled && 'pointer',
      borderRadius: '4px',
      border: '0.5px solid #c4c4c4',
      minHeight: 0,
      height: '28px'
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({
      ...styles,
      padding: 0,
      height: 'fit-content'
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
      ...styles,
      padding: '3px 8px',
      height: 'fit-content'
    })
  };

  return (
    <div className={classes.formContainer}>
      {medium._id === 'sms' || followUpMedium._id === 'sms' ? (
        <SmsTemplate
          templateMessage={
            showFollowUpPage
              ? followUpArr[followUpIndex]?.smsTemplateMessage
              : smsTemplateMessage
          }
          open={open && (medium._id === 'sms' || followUpMedium._id === 'sms')}
          handleClose={handleClose}
          handleSubmit={handleMessageSubmit}
        />
      ) : null}
      {medium._id === 'email' || followUpMedium._id === 'email' ? (
        <EmailTemplate
          templateMessage={
            showFollowUpPage
              ? followUpArr[followUpIndex].emailTemplateMessage
              : emailTemplateMessage
          }
          open={
            open && (medium._id === 'email' || followUpMedium._id === 'email')
          }
          handleClose={handleClose}
          handleSubmit={handleEmailSubmit}
        />
      ) : null}
      {medium._id === 'sms-email' ||
      followUpMedium._id === 'sms-email' ||
      followUpMedium._id === 'email&sms' ||
      medium._id === 'email&sms' ? (
        <HybridModal
          smsTemplateMessage={
            showFollowUpPage
              ? followUpArr[followUpIndex]?.smsTemplateMessage
              : smsTemplateMessage
          }
          emailTemplateMessage={
            showFollowUpPage
              ? followUpArr[followUpIndex]?.emailTemplateMessage
              : emailTemplateMessage
          }
          open={
            open &&
            (medium._id === 'sms-email' ||
              followUpMedium._id === 'sms-email' ||
              followUpMedium._id === 'email&sms' ||
              medium._id === 'email&sms')
          }
          handleClose={handleClose}
          handleSubmit={handleHybridSubmit}
        />
      ) : null}
      {!showFollowUpPage ? (
        <div className={classes.workflowContainer}>
          <div className={classes.flexItem}>
            <Typography className={classes.labelText}>
              Add workflow name:
            </Typography>
            <ZTextfield
              error={workflow_name.length === 0 && showSubmitted}
              helperText={
                workflow_name.length === 0 && showSubmitted
                  ? 'Workflow name is required'
                  : ''
              }
              value={workflow_name}
              name="workflow_name"
              label="Sequence Name"
              handleInputChange={handleFormChange}
            />
          </div>
          <div className={classes.flexItem}>
            <Typography className={classes.labelText}>
              Select a lead stage for sequence :
            </Typography>
            <ZDropDown
              error={stage.length === 0 && showSubmitted}
              helperText={
                stage.length === 0 && showSubmitted ? 'Stage is required' : ''
              }
              name="stage"
              value={stage}
              handleChange={handleFormChange}
              options={WORKFLOW_STATUS_LIST}
              label={'Select Lead Stage'}
            />
            {!showFollowUpMessage && stage?.name == 'Inbound' ? (
              <div className={classes.kelseyCheck}>
                <Checkbox
                  style={{ marginTop: '-4px' }}
                  onClick={handleEnableKelseyAfm}
                  checked={enableKelseyAfm}
                />
                <span>
                  Should kelsey attempt to send the first enquiry message
                </span>
              </div>
            ) : null}
          </div>
          <div className={classes.flexItem}>
            <Typography className={classes.labelText}>
              Select client name:
            </Typography>
            <ZSelect
              placeholder="Select clients"
              name="clients"
              handleSelectedData={handleFormChange}
              selectedOptions={clients}
              optionsList={tenantId}
            />
          </div>
          <div className={classes.flexItem}>
            <Typography className={classes.labelText}>
              Select preferred communities:
            </Typography>
            <ZSelect
              placeholder="Select communities"
              name="communities"
              handleSelectedData={handleFormChange}
              selectedOptions={communities}
              optionsList={apartmentsByTenantIds}
            />
          </div>
          {!showFollowUpMessage ? (
            <>
              <div className={classes.flexItem}>
                <Typography className={classes.labelText}>
                  Choose your medium of communication:
                </Typography>
                <ZDropDown
                  name="medium"
                  value={medium}
                  handleChange={handleFormChange}
                  options={MEDIUM_LIST}
                  label={'Select medium'}
                />
              </div>
              <div className={classes.flexItem}>
                <Typography className={classes.labelText}>
                  Create template:
                </Typography>
                <Button
                  onClick={handleClickOpen}
                  className={classes.createTemplateButton}
                  variant="outlined"
                >
                  {(medium._id === 'sms' &&
                    smsTemplateMessage.message.length === 0) ||
                  (medium._id === 'email' &&
                    emailTemplateMessage.message.length === 0) ||
                  ((medium._id === 'sms-email' || medium._id === 'email&sms') &&
                    smsTemplateMessage.message.length === 0 &&
                    emailTemplateMessage.message.length === 0) ? (
                    <AddIcon />
                  ) : (
                    <CreateIcon />
                  )}
                  {(medium._id === 'sms' &&
                    smsTemplateMessage.message.length === 0) ||
                  (medium._id === 'email' &&
                    emailTemplateMessage.message.length === 0) ||
                  ((medium._id === 'sms-email' || medium._id === 'email&sms') &&
                    smsTemplateMessage.message.length === 0 &&
                    emailTemplateMessage.message.length === 0)
                    ? 'New'
                    : ''}{' '}
                  {medium._id} template
                </Button>
              </div>
              {(medium._id === 'sms' &&
                smsTemplateMessage.message.length === 0) ||
              (medium._id === 'email' &&
                emailTemplateMessage.message.length === 0) ||
              ((medium._id === 'sms-email' || medium._id === 'email&sms') &&
                smsTemplateMessage.message.length === 0 &&
                emailTemplateMessage.message.length === 0) ? (
                ''
              ) : (
                <BootstrapTooltip
                  title={
                    <div>
                      {medium._id === 'email' ? (
                        <>
                          <p className={classes.tooltipTitle}>Email Subject</p>
                          <p className={classes.tooltipBody}>
                            {emailTemplateMessage.subject}
                          </p>
                        </>
                      ) : null}

                      {medium._id === 'email' ? (
                        <>
                          {' '}
                          <p className={classes.tooltipTitle}>Email Body</p>
                          <p className={classes.tooltipBody}>
                            {htmlToText(emailTemplateMessage.message)}
                          </p>
                        </>
                      ) : null}
                      {medium._id === 'sms' ? (
                        <>
                          <p className={classes.tooltipTitle}>Sms Body</p>
                          <p className={classes.tooltipBody}>
                            {smsTemplateMessage.message}
                          </p>
                        </>
                      ) : null}
                      {medium._id === 'sms-email' ||
                      medium._id === 'email&sms' ? (
                        <>
                          <p className={classes.tooltipTitle}>Email Subject</p>
                          <p className={classes.tooltipBody}>
                            {emailTemplateMessage.subject}
                          </p>
                          <p className={classes.tooltipTitle}>Email Body</p>
                          <p className={classes.tooltipBody}>
                            {htmlToText(emailTemplateMessage.message)}
                            <p className={classes.tooltipTitle}>Sms Body</p>
                            <p className={classes.tooltipBody}>
                              {smsTemplateMessage.message}
                            </p>
                          </p>
                        </>
                      ) : null}
                    </div>
                  }
                >
                  <InfoOutlinedIcon
                    sx={{
                      position: 'fixed',
                      right: '5.5%',
                      cursor: 'pointer',
                      bottom: '14%',
                      position: 'absolute',
                      color: 'grey'
                    }}
                  />
                </BootstrapTooltip>
              )}
            </>
          ) : (
            <>
              <div />
              <div />
            </>
          )}
          <div></div>
          <Button
            disabled={
              workflow_name.length === 0 ||
              communities.length === 0 ||
              clients.length === 0 ||
              medium.length === 0 ||
              (((medium._id === 'sms' &&
                smsTemplateMessage.message.length === 0) ||
                (medium._id === 'email' &&
                  emailTemplateMessage.message.length === 0) ||
                ((medium._id === 'sms-email' || medium._id === 'email&sms') &&
                  smsTemplateMessage.message.length === 0 &&
                  emailTemplateMessage.message.length === 0)) &&
                showAutomatedMessage)
            }
            onClick={handleSubmit}
            className={classes.formButton}
          >
            {showAutomatedMessage ? 'Save' : 'Next'}
          </Button>
        </div>
      ) : (
        <div className={classes.workflowFormContainer}>
          <div className={classes.workflowGlobalSettings}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'max-content'
              }}
            >
              <RadioButton
                id="interval-fixed"
                label="Fixed Interval"
                value={intervalMap.fixed}
                checked={intervalType === intervalMap.fixed}
                onChange={e => setIntervalType(intervalMap.fixed)}
              />
              <Select
                options={fixedIntervalOptions}
                styles={selectCustomStyles}
                placeholder="Select Interval"
                maxMenuHeight={200}
                onChange={handleFixedIntervalChange}
                value={selectedFixedInterval}
                isDisabled={intervalType === intervalMap.custom}
              />
            </div>
            <div>
              <RadioButton
                id="interval-custom"
                label="Custom Interval"
                value={intervalMap.custom}
                checked={intervalType === intervalMap.custom}
                onChange={e => {
                  setIntervalType(intervalMap.custom);
                  setSelectedFixedInterval(null);
                }}
              />
            </div>
          </div>
          <div className={classes.workflowContainer}>
            {followUpArr.map((followUpData, i) => {
              followUpData.key = i;
              return (
                <div key={i} className={classes.sequenceContainer}>
                  <div className={classes.flexItem}>
                    <Typography className={classes.labelText}>
                      <span className={classes.followUpNumber}>{i + 1}</span>{' '}
                      Choose your medium of communication:
                    </Typography>
                    <ZDropDown
                      disabled={i !== 0}
                      name="followUpMedium"
                      value={followUpMedium}
                      handleChange={handleFollowUpMedium}
                      options={MEDIUM_LIST}
                      label={'Select medium'}
                    />
                  </div>
                  <div className={classes.flexItem}>
                    <Typography className={classes.labelText}>
                      Create template:
                    </Typography>
                    <Button
                      onClick={() => handleClickOpen(i)}
                      className={classes.createTemplateButton}
                      variant="outlined"
                    >
                      {(followUpData.medium._id === 'sms' &&
                        followUpData.smsTemplateMessage.message.length === 0) ||
                      (followUpData.medium._id === 'email' &&
                        followUpData.emailTemplateMessage.message.length ===
                          0) ||
                      ((followUpData.medium._id === 'sms-email' ||
                        followUpData.medium._id === 'email&sms') &&
                        followUpData.smsTemplateMessage.message.length === 0 &&
                        followUpData.emailTemplateMessage.message.length ===
                          0) ? (
                        <AddIcon />
                      ) : (
                        <CreateIcon />
                      )}
                      {(followUpData.medium._id === 'sms' &&
                        followUpData.smsTemplateMessage.message.length === 0) ||
                      (followUpData.medium._id === 'email' &&
                        followUpData.emailTemplateMessage.message.length ===
                          0) ||
                      ((followUpData.medium._id === 'sms-email' ||
                        followUpData.medium._id === 'email&sms') &&
                        followUpData.smsTemplateMessage.message.length === 0 &&
                        followUpData.emailTemplateMessage.message.length === 0)
                        ? 'New'
                        : ''}{' '}
                      {followUpMedium._id} template
                    </Button>
                  </div>

                  <div
                    style={{
                      marginTop: '-37px',
                      fontFamily: 'inter',
                      fontWeight: 500,
                      color: '#5267ff',
                      fontSize: '16px',
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '6.5%'
                    }}
                  >
                    {/* Individual template interval setting */}
                    <Select
                      options={fixedIntervalOptions}
                      styles={secondarySelectCustomStyles}
                      placeholder="Schedule Interval"
                      maxMenuHeight={200}
                      onChange={value =>
                        handleIndividualTemplateIntervalChange(value, i)
                      }
                      value={
                        intervalType === intervalMap.fixed
                          ? selectedFixedInterval
                          : followUpData?.totalNoOfSkip
                      }
                      isDisabled={intervalType === intervalMap.fixed}
                    />
                  </div>
                  <BootstrapTooltip
                    title={
                      <MenuList sx={{ padding: 0 }}>
                        <MenuItem
                          onClick={() => handleDuplicate(i)}
                          sx={{ color: '#393939' }}
                        >
                          <ListItemIcon>
                            <ContentCopy sx={{ color: '#393939' }} />
                          </ListItemIcon>
                          <ListItemText>Duplicate</ListItemText>
                        </MenuItem>
                        <Divider />
                        {i !== 0 ? (
                          <MenuItem
                            onClick={() => handleDelete(i)}
                            sx={{ color: '#393939' }}
                          >
                            <ListItemIcon>
                              <Delete sx={{ color: '#393939' }} />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        ) : null}
                      </MenuList>
                    }
                  >
                    <MoreVertIcon
                      sx={{
                        position: 'fixed',
                        right: '2.5%',
                        cursor: 'pointer',
                        top: '10%',
                        position: 'absolute',
                        color: 'grey'
                      }}
                    />
                  </BootstrapTooltip>
                  <BootstrapTooltip
                    title={
                      <div>
                        {followUpMedium._id === 'sms-email' ||
                        followUpMedium._id === 'email&sms' ? (
                          <>
                            <p>Email Subject</p>
                            <p className={classes.tooltipTitle}>
                              {followUpData.emailTemplateMessage.subject}
                            </p>
                            <p className={classes.tooltipTitle}>Email Body</p>
                            <p className={classes.tooltipBody}>
                              {followUpData.emailTemplateMessage.message}
                            </p>
                            <p className={classes.tooltipTitle}>Sms Body</p>
                            <p className={classes.tooltipBody}>
                              {followUpData.smsTemplateMessage.message}
                            </p>
                          </>
                        ) : null}
                        {followUpMedium._id === 'email' ? (
                          <>
                            <p className={classes.tooltipTitle}>Subject</p>
                            <p className={classes.tooltipBody}>
                              {followUpData.emailTemplateMessage.subject}
                            </p>
                            <p className={classes.tooltipTitle}>Email Body</p>
                            <p className={classes.tooltipBody}>
                              {followUpData.emailTemplateMessage.message !== ''
                                ? htmlToText(
                                    followUpData.emailTemplateMessage.message
                                  )
                                : ''}
                            </p>
                          </>
                        ) : null}

                        {followUpMedium._id === 'sms' ? (
                          <>
                            <p className={classes.tooltipTitle}>Sms Body</p>
                            <p className={classes.tooltipBody}>
                              {followUpData.smsTemplateMessage.message}
                            </p>
                          </>
                        ) : null}
                      </div>
                    }
                  >
                    <InfoOutlinedIcon
                      sx={{
                        position: 'absolute',
                        right: '2%',
                        top: '55%',
                        cursor: 'pointer',
                        color: 'grey'
                      }}
                    />
                  </BootstrapTooltip>
                </div>
              );
            })}
            <div className={classes.addSequenceContainer}>
              {followUpArr.length !== 20 ? (
                <AddCircleOutlinedIcon
                  onClick={handleSequenceAdd}
                  sx={{
                    height: 44,
                    width: 44,
                    color: '#20065F',
                    cursor: 'pointer',
                    marginBottom: '18px'
                  }}
                />
              ) : null}
              <div style={{ display: 'flex' }}>
                <Typography sx={{ marginTop: '10px', marginRight: '14px' }}>
                  Should the lead close out ?
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleLeadChange}
                >
                  <ToggleButton value="yes">Yes</ToggleButton>
                  <ToggleButton value="no">No</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div className={classes.footerContainer}>
              <Button
                variant="outlined"
                className={classes.backButton}
                onClick={handleBack}
              >
                {' '}
                Back
              </Button>
              <Button onClick={handleSubmit} className={classes.formButton}>
                {' '}
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SequenceForm;
