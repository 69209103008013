import { createSlice } from '@reduxjs/toolkit';
import {
  applySettings,
  channelAccountSettings,
  configIntegration,
  createInternalSettings,
  deleteChannelAccount,
  downloadCheatSheetByApartmentIds,
  downloadCsv,
  getChannelAccountDetails,
  getIntegration,
  getInternalSettings,
  getTenantDetails,
  getUserData,
  getYardiCommunityAgents,
  ingestCommunityGet,
  onBoardCommunities,
  updateAccount,
  updateChannelAccountEmailStatus,
  updateLeasingAgents,
  updateRestrictCommunication,
  updateWorkingHours,
  uploadFiles,
  validatePms,
  verifyCommunity,
  verifyRentCafe
} from '../Actions/SelfServe';

const initialState = {
  userData: [],
  successPopup: false,
  userDataError: false,
  downloadCsv: {},
  uploadFiles: {},
  communityId: false,
  individualCommunityData: {},
  apiLoading: false,
  clientsList: [],
  individualCommunity: [
    [
      {
        label: 'Community Name',
        name: 'communityName',
        value: '',
        error: false,
        disable: true,
        verify: false
      },
      {
        label: 'Community Website',
        value: '',
        disable: false,
        name: 'communityWebsite',
        endAdornment: 'Fetch Infos',
        error: false,
        verify: false
      },
      {
        label: 'Time zone',
        value: { label: '', value: '' },
        disable: false,
        name: 'timeZone',
        type: 'select',
        error: false
      },
      {
        label: 'Office Hours',
        value: '',
        disable: false,
        name: 'officeHours',
        type: 'date',
        error: false,
        verify: false
      }
    ],
    [
      {
        label: 'Knock ID',
        value: '',
        disable: false,
        name: 'knockId',
        endAdornment: 'verify',
        error: false,
        verify: false
      },
      {
        label: 'Kelsey ID',
        value: '',
        disable: true,
        name: 'kelseyId',
        error: false,
        verify: false
      },
      {
        label: 'House Account ID',
        name: 'houseAccountId',
        value: '',
        disable: true,
        error: false,
        verify: false
      }
    ],
    [
      {
        label: 'Company Code',
        value: '',
        disable: false,
        name: 'CompanyCode',
        error: false
      },
      {
        label: 'API Token',
        value: '',
        disable: false,
        name: 'rentcafeApiV2Token',
        error: false
      },
      {
        label: 'Property Code',
        value: '',
        disable: false,
        name: 'propertyCode',
        endAdornment: 'verify',
        error: false,
        verify: false
      },
      {
        label: 'Leasing Agent',
        value: '',
        disable: false,
        type: 'select',
        name: 'leasingAgents',
        error: false
      }
    ]
  ],
  workingHours: [],
  appartmentData: [],
  integrationApartment: [],
  selectedApprtment: [],
  usersList: [],
  settingsList: {},
  isUserCreated: false,
  isUserUpdated: false,
  isUsersLoading: false,
  listingCommunity: true,
  validatePms: { verified: false, error: false },
  pendingUpload: false,
  onboardedCommunites: {},
  configuredSettings: {
    tenantId: '',
    settingsName: '',
    pmsSetup: false,
    crmSetup: false,
    propertyIngestion: false,
    leadIngestion: false,
    assignLeadToKelsey: false,
    statusMappingsDefaultKnock: {
      source: 'knock',
      sourceType: 'CRM',
      mappings: [
        {
          stage: 'Inbound',
          overrideZumaStatus: false,
          thirdPartyStatuses: ['NEW']
        },
        {
          stage: 'Contacted',
          thirdPartyStatuses: ['OPEN', 'WAITING'],
          overrideZumaStatus: false
        },
        {
          stage: 'Tour Scheduled',
          thirdPartyStatuses: ['BOOKED'],
          overrideZumaStatus: true
        },
        {
          stage: 'Tour Cancelled',
          thirdPartyStatuses: ['CANCELLED_MANAGER', 'CANCELLED_RENTER'],
          overrideZumaStatus: true
        }
      ],
      allowedFirstContactTypes: [
        'internet',
        'email',
        'knock-schedule',
        'facebook',
        'sms',
        'landline'
      ]
    }
  },
  showSuccess: false
};

const SelfServeSlice = createSlice({
  name: 'selfServe',
  initialState,
  reducers: {
    openCommunity: (state, action) => {
      state.individualCommunity = initialState.individualCommunity;
    },
    verifyCommunityError: (state, action) => {
      const { name, value, step } = action.payload;
      const index = state.individualCommunity[step].findIndex(
        item => item.name === name
      );
      state.individualCommunity[step][index].value = value;
      state.individualCommunity[step][index].verify = false;
      state.individualCommunity[step][index].error = false;
    },
    addCommunityName: (state, action) => {
      state.individualCommunity[0][0].value = action.payload;
    },
    // addCommunityId: (state, action) => {
    //   state.individualCommunity[0][1].value = action.payload;
    //   state.individualCommunity[0][1].disable = true;
    // },
    addCommunityWebsite: (state, action) => {
      state.individualCommunity[0][1].value = action.payload;
    },
    addTimeZone: (state, action) => {
      state.individualCommunity[0][2].value = action?.payload?.value;
      state.individualCommunity[0][2].disable = action.payload.disable
        ? true
        : false;
    },
    addPropertyCode: (state, action) => {
      state.individualCommunity[2][2].value = action?.payload?.value;
      state.individualCommunity[2][2].disable = action.payload.disable
        ? true
        : false;
    },
    addCompanyCode: (state, action) => {
      state.individualCommunity[2][0].value = action?.payload?.value;
      state.individualCommunity[2][0].disable = action.payload.disable
        ? true
        : false;
    },
    addRentcafeApiToken: (state, action) => {
      state.individualCommunity[2][1].value = action?.payload?.value;
      state.individualCommunity[2][1].disable = action.payload.disable
        ? true
        : false;
    },
    userCreationNotification: state => {
      state.userDataError = !state.userDataError;
    },
    listingCommunityNotification: state => {
      state.listingCommunity = !state.listingCommunity;
    },
    validatePmsError: (state, action) => {
      state.validatePms = { verified: false, error: action.payload || false };
    },
    addSelectedAppartment: (state, action) => {
      state.selectedApprtment = [...state.selectedApprtment, action.payload];
    },
    removeSelectedAppartment: (state, action) => {
      state.selectedApprtment = action.payload;
    },
    addKnockRelatedDetails: (state, action) => {
      state.individualCommunity[1][0].value = action.payload?.knockId;
      state.individualCommunity[1][0].disable = true;
      state.individualCommunity[1][1].value = action.payload?.kelsey;
      state.individualCommunity[1][2].value = action.payload?.house;
    },
    configuredSettings: (state, action) => {
      state.configuredSettings = {
        ...state.configuredSettings,
        ...action.payload
      };
    },
    hideSuccessMessage: (state, action) => {
      state.showSuccess = false;
    }
  },
  extraReducers: {
    updateClientList: (state, action) => {
      if (action?.payload) {
        state.clientsList = action.payload;
      }
    },
    [updateRestrictCommunication.pending]: (state, action) => {
      state.nonOwnerKelsey = false;
    },
    [updateRestrictCommunication.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.nonOwnerKelsey = true;
      }
    },
    [updateRestrictCommunication.rejected]: (state, action) => {
      if (action?.payload) {
        state.nonOwnerKelsey = false;
      }
    },
    [channelAccountSettings.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.channelData = action.payload?.data;
        //state.usersList = [...state.usersList, action.payload.data];
        state.userDataError = false;
      }
    },
    [channelAccountSettings.rejected]: (state, action) => {
      if (action?.payload) {
        state.userDataError = true;
      }
    },
    [deleteChannelAccount.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.channelData = [];
        //state.usersList = [...state.usersList, action.payload.data];
        state.userDataError = false;
      }
    },
    [deleteChannelAccount.rejected]: (state, action) => {
      if (action?.payload) {
        state.userDataError = true;
      }
    },
    [updateAccount.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [updateAccount.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.userData = action.payload.data;
        state.apiLoading = false;
      }
    },
    [updateAccount.rejected]: (state, action) => {
      if (action?.payload) {
        state.userDataError = true;
        state.apiLoading = false;
      }
    },
    [verifyRentCafe.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [verifyRentCafe.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.verifyRentCafeData = action.payload.data;
        state.individualCommunity[2][2].verify = true;
        state.individualCommunity[2][2].error = false;
        state.apiLoading = false;
      }
    },
    [verifyRentCafe.rejected]: (state, action) => {
      if (action?.payload) {
        state.userDataError = true;
        state.verifyRentCafeData = false;
        state.individualCommunity[2][2].error = true;
        state.apiLoading = false;
      }
    },
    [updateChannelAccountEmailStatus.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.channelEmailStatus = action.payload.data;
      }
    },
    [updateChannelAccountEmailStatus.rejected]: (state, action) => {
      if (action?.payload) {
        state.channelEmailStatusError = true;
      }
    },
    [getTenantDetails.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.userData = action.payload?.data;
      }
    },
    [getChannelAccountDetails.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.channelData = action.payload?.data;
      }
    },
    [downloadCsv.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [downloadCsv.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.downloadCsv = action.payload?.data;
        state.apiLoading = false;
      }
    },
    [downloadCsv.rejected]: (state, action) => {
      if (action?.payload) {
        state.downloadCsv = action.payload?.data;
        state.apiLoading = false;
      }
    },
    [validatePms.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [validatePms.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.validatePms = {
          verified: action?.payload?.data?.data || false,
          error: !action?.payload?.data?.data
        };
        state.apiLoading = false;
      }
    },
    [validatePms.rejected]: (state, action) => {
      if (action?.payload) {
        state.validatePms = { verified: true, error: true };
        state.apiLoading = false;
      }
    },
    [uploadFiles.pending]: state => {
      state.pendingUpload = true;
      state.apiLoading = true;
    },
    [uploadFiles.fulfilled]: (state, action) => {
      if (action.payload) {
        state.uploadFiles = action.payload;
        state.pendingUpload = false;
        state.apiLoading = false;
      }
    },
    [uploadFiles.rejected]: (state, action) => {
      if (action.payload) {
        state.uploadFiles = {};
        state.pendingUpload = false;
        state.apiLoading = false;
      }
    },
    [verifyCommunity.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [verifyCommunity.fulfilled]: (state, action) => {
      if (action.payload) {
        state.individualCommunity[1][0].verify = true;
        state.individualCommunity[1][2].value = action?.payload?.data?.data
          ?.meta?.knockHouseAgentUserName
          ? action?.payload?.data?.data?.meta?.knockHouseAgentUserName
          : '';
        state.individualCommunity[1][1].value = action?.payload?.data?.data
          ?.meta?.knockKelseyUserName
          ? action?.payload?.data?.data?.meta?.knockKelseyUserName
          : '';
        state.individualCommunity[1][0].error = false;

        state.apiLoading = false;
      }
    },
    [verifyCommunity.rejected]: (state, action) => {
      if (action.payload) {
        state.verifyCommunity = false;
        state.individualCommunity[0][1].verify = true;
        state.individualCommunity[1][0].error = true;
        state.individualCommunity[1][2].value = '';
        state.individualCommunity[1][1].value = '';
        state.apiLoading = false;
      }
    },
    [ingestCommunityGet.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [ingestCommunityGet.fulfilled]: (state, action) => {
      if (action.payload) {
        const data = action?.payload?.data?.data;
        const filterCommunity = data?.filter(item => !item.integrations);
        state.appartmentData = filterCommunity || [];
        state.listingCommunity = true;
        state.selectedApprtment = [];
        state.apiLoading = false;
      }
    },
    [ingestCommunityGet.rejected]: (state, action) => {
      if (action.payload) {
        state.appartmentData = [];
        state.listingCommunity = false;
        state.apiLoading = false;
      }
    },
    [getUserData.pending]: (state, action) => {
      state.isUsersLoading = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      state.isUsersLoading = false;
      state.usersList = action?.payload?.data?.data;
    },
    [getUserData.rejected]: (state, action) => {
      state.isUsersLoading = false;
      state.error = action.payload;
    },
    [createInternalSettings.pending]: (state, action) => {
      state.isSettingsUpdated = true;
      state.showSuccess = false;
    },
    [createInternalSettings.fulfilled]: (state, action) => {
      state.isSettingsUpdated = false;
      state.showSuccess = true;
    },
    [createInternalSettings.rejected]: (state, action) => {
      state.isSettingsUpdated = false;
      state.error = action.payload;
    },
    [getInternalSettings.pending]: (state, action) => {
      state.isSettingsUpdated = true;
    },
    [getInternalSettings.fulfilled]: (state, action) => {
      state.isSettingsUpdated = false;
      if (action?.payload?.data?.data) {
        state.settingsList = action.payload.data.data;
      }
    },
    [getInternalSettings.rejected]: (state, action) => {
      state.isSettingsUpdated = false;
      state.error = action.payload;
    },
    [updateWorkingHours.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [updateWorkingHours.fulfilled]: (state, action) => {
      state.workingHours = action?.payload?.data;
      state.apiLoading = false;
    },
    [updateWorkingHours.rejected]: (state, action) => {
      state.apiLoading = false;
    },
    [getYardiCommunityAgents.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [getYardiCommunityAgents.fulfilled]: (state, action) => {
      state.yardiCommunityAgents = action?.payload?.data;
      state.apiLoading = false;
    },
    [getYardiCommunityAgents.rejected]: (state, action) => {
      state.apiLoading = false;
    },
    [updateLeasingAgents.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [updateLeasingAgents.fulfilled]: (state, action) => {
      state.apiLoading = false;
      state.individualCommunity[2][3].error = false;
    },
    [updateLeasingAgents.rejected]: (state, action) => {
      state.apiLoading = false;
      state.individualCommunity[2][3].error = true;
    },
    [onBoardCommunities.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [onBoardCommunities.fulfilled]: (state, action) => {
      if (action.payload) {
        state.onboardedCommunites = action?.payload;
        state.apiLoading = false;
      }
    },
    [onBoardCommunities.rejected]: (state, action) => {
      state.apiLoading = false;
    },
    [getIntegration.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [getIntegration.fulfilled]: (state, action) => {
      if (action.payload) {
        state.integrationApartment = action?.payload?.data?.data;
        state.apiLoading = false;
      }
    },
    [getIntegration.rejected]: (state, action) => {
      if (action.payload) {
        state.integrationApartment = [];
        state.apiLoading = false;
      }
    },
    [configIntegration.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [configIntegration.fulfilled]: (state, action) => {
      state.userData = action?.payload?.data;
      state.apiLoading = false;
    },
    [configIntegration.rejected]: (state, action) => {
      state.apiLoading = false;
    },
    [applySettings.pending]: (state, action) => {
      state.apiLoading = true;
    },
    [applySettings.fulfilled]: (state, action) => {
      state.apiLoading = false;
    },
    [applySettings.rejected]: (state, action) => {
      state.apiLoading = false;
    },
    [downloadCheatSheetByApartmentIds.pending]: (state, action) => {
      state.cheatSheetByApartmentByIdsLoading = true;
    },
    [downloadCheatSheetByApartmentIds.fulfilled]: (state, action) => {
      state.cheatSheetByApartmentByIdsLoading = false;
      state.cheatSheetByApartmentByIdsData = action?.payload;
    },
    [downloadCheatSheetByApartmentIds.rejected]: (state, action) => {
      state.cheatSheetByApartmentByIdsLoading = false;
      state.cheatSheetByApartmentByIdsData = [];
    }
  }
});

export const {
  openCommunity,
  verifyCommunityError,
  userCreationNotification,
  listingCommunityNotification,
  addCommunityName,
  // addCommunityId,
  validatePmsError,
  addTimeZone,
  addKnockRelatedDetails,
  removeSelectedAppartment,
  addSelectedAppartment,
  hideSuccessMessage,
  addCompanyCode,
  addPropertyCode,
  addRentcafeApiToken,
  addCommunityWebsite
} = SelfServeSlice.actions;

export default SelfServeSlice;
