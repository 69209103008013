import React from 'react';
import { BlueDot, Ripple } from './blink.styles';

const Blink = props => {
  return (
    <div id="blink-notification" aria-label="blink">
      <BlueDot />
      <Ripple />
    </div>
  );
};

export default Blink;
