import { GET_AVAILABLE_NUMBER } from '../Constant';

const initialState = {
  OnboardData: '',
  OnboardQuestion: '',
  OnboardQuestionSecond: '',
  OnboardQuestionThird: '',
  Loader: true,
  users: '',
  tenantData: '',
  availableNumbers: ''
};
const Admin = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_NUMBER:
      return { ...state, availableNumbers: action.data };
    default:
      return state;
  }
};

export default Admin;
