import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import find from 'lodash/find';
import React, { useState } from 'react';
import agentData from '../agent.json';
import clientsData from '../client.json';
import CustomizedDialogs from '../Dialog';
import RefreshIcon from '../RefreshIcon';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../stringAvatar';
import { STATUS_COLOR } from '../colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import IconButton from '@mui/material/IconButton';
import upperFirst from 'lodash/upperFirst';
import Tooltip from '@mui/material/Tooltip';
import useSortableData from '../../../hooks/useSortable';

const status = [
  {
    type: 'total',
    count: 25,
    styles: {
      background: '#5267FF'
    }
  },
  {
    type: 'off-break',
    count: 10,
    styles: {
      background: '#FF9900'
    }
  },
  {
    type: 'online',
    count: 5,
    styles: {
      background: '#67C848'
    }
  },
  {
    type: 'offline',
    count: 10,
    styles: {
      background: '#C4C4C4'
    }
  }
];

const columns = {
  agents: [
    {
      id: 'agentName',
      label: 'Agent Name',
      align: 'left',
      icon: {
        isExist: false
      },
      tableType: 'agents'
    },
    {
      id: 'unarchivedLeads',
      label: 'Unarchived Leads',
      align: 'left',
      icon: {
        isExist: true
      },
      tableType: 'agents'
    },
    {
      id: 'clients',
      label: 'Clients',
      align: 'left',
      icon: {
        isExist: false
      },
      tableType: 'agents'
    }
  ],
  clients: [
    {
      id: 'clientName',
      label: 'Client Name',
      align: 'left',
      icon: {
        isExist: false
      },
      tableType: 'clients'
    },
    {
      id: 'waitingLeads',
      label: 'Waiting Leads',
      align: 'left',
      icon: {
        isExist: true
      },
      tableType: 'clients'
    },
    {
      id: 'agents',
      label: 'Agents',
      align: 'left',
      icon: {
        isExist: true
      },
      tableType: 'clients'
    }
  ]
};
const tableCellStyles = {
  color: '#393939',
  fontSize: '14px',
  lineHeight: '17px'
};

const getClients = (clients = []) =>
  clients.map(client => client?.name).toString();

function createData(agentName, unarchivedLeads, clients, _id, status) {
  return { agentName, unarchivedLeads, clients, _id, status };
}

const agentRows = agentData.data.map(
  ({ agentName, leads, clients, _id, status }) =>
    createData(agentName, leads?.archived, clients, _id, status)
);
const clientRows = clientsData.data.map(({ client, leads, agents, _id }) => ({
  clientName: client,
  waitingLeads: leads?.waitingLeads,
  agents: agents?.length,
  _id
}));
export default function SummaryTable(props) {
  const { type } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const { items, requestSort } = useSortableData(
    type === 'agents' ? agentRows : clientRows
  );
  function handleClickOpen() {
    setOpen(true);
  }

  function handleSelectRow(row) {
    const { data } = type === 'agents' ? agentData : clientsData;
    const response = find(data, { _id: row?._id });
    setSelected(response);
  }
  return (
    <Paper sx={{ width: '100%' }}>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        selected={selected}
        type={type}
      />
      <TableContainer sx={{ height: '410px' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none'
            },
            [`& .MuiTableCell-root:first-child`]: {
              borderRadius: '8px 0 0 8px'
            },
            [`& .MuiTableCell-root:last-child`]: {
              borderRadius: '0 8px 8px 0'
            },
            [`& .MuiTableRow-root:nth-of-type(odd)`]: {
              background: `rgba(82, 103, 255, 0.05)`
            },
            paddingRight: '20px',
            paddingLeft: '20px'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ padding: '16px 2px' }}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={1} sx={{ marginRight: '10px' }}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: '#42526E',
                        fontWeight: 500,
                        display: 'inline'
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {upperFirst(type)}
                    </Typography>
                  </Grid>
                  {type === 'agents' &&
                    status.map(({ count, styles }) => (
                      <Grid item xs={1}>
                        <Button
                          sx={{
                            width: '100%',
                            height: '26px',
                            padding: 0,
                            margin: 0
                          }}
                          startIcon={
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '10px',
                                background: styles.background
                              }}
                            ></div>
                          }
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              lineHeight: '17px',
                              fontWeight: '600',
                              color: '#393939'
                            }}
                          >
                            {count}
                          </span>
                        </Button>
                      </Grid>
                    ))}
                  {type === 'agents' && <Grid xs={3} />}
                  <Grid item xs={2} textAlign="right">
                    <SearchIcon />
                    <RefreshIcon />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns[type].map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    top: 63,
                    padding: 10,
                    fontSize: 14,
                    background: 'rgba(82, 103, 255, 0.8)',
                    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.04)`,
                    ...(column.id === 'agentName' && { width: '120px' }),
                    ...(column.id === 'clientName' && { width: '106px' })
                  }}
                >
                  <Typography
                    style={{
                      color: '#ffffff',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '17px'
                    }}
                  >
                    {column.tableType === type && column.label}
                    {column?.icon?.isExist && (
                      <IconButton
                        sx={{
                          display: 'inline',
                          padding: '0px',
                          '&:hover': { background: 'none' }
                        }}
                        onClick={() => requestSort(column.id)}
                      >
                        <ImportExportOutlinedIcon sx={{ color: '#fff' }} />
                      </IconButton>
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={index}>
                  {columns[type].map(column => {
                    const value =
                      column.id === 'clients'
                        ? row[column.id]?.length
                        : row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        onClick={() => {
                          handleClickOpen(), handleSelectRow(row);
                        }}
                      >
                        <Grid
                          container
                          justifyContent={
                            column.id === 'agentName' ||
                            column.id === 'clientName'
                              ? 'start'
                              : 'center'
                          }
                          spacing={2}
                        >
                          {column.id === 'agentName' && (
                            <Grid item>
                              <Badge
                                sx={{
                                  '& .MuiBadge-badge': {
                                    background: STATUS_COLOR[row.status],
                                    border: `1px solid rgba(255, 255, 255, 0.7)`
                                  }
                                }}
                                variant="dot"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                overlap="circular"
                              >
                                <Avatar
                                  {...stringAvatar(value, '24px', '24px')}
                                />
                              </Badge>
                            </Grid>
                          )}
                          <Grid item>
                            <span
                              style={{
                                ...tableCellStyles,
                                fontWeight: '500',
                                ...(column.id === 'clients' && {
                                  position: 'relative',
                                  top: '3px'
                                })
                              }}
                            >
                              {value}
                            </span>
                          </Grid>
                          {column.id === 'clients' && (
                            <Grid item>
                              <Tooltip title={getClients(row?.clients)}>
                                <InfoOutlinedIcon
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    fontSize: 0
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
