import {
  GET_MSG_TEMPLATE,
  ADD_WORKFLOW,
  GET_ALL_WORKFLOW,
  ADD_SEQUENCE,
  ADD_SEQUENCE_CONTENT,
  CREATE_CAMPAGIN,
  GET_ALL_CAMPAIGN,
  CREATE_STEP,
  GET_ALL_STEP,
  GET_STEP_BY_ID
} from '../Constant';

const initialState = {
  msgTemplate: [],
  TemplateLoader: true,
  WorkflowAdd: false,
  sequenceAdd: false,
  sequenceContentAdd: false,
  AllWorkflow: [],
  AllCampaign: '',
  AllStep: '',
  StepById: ''
};
const Workflow = (state = initialState, action) => {
  switch (action.type) {
    case GET_MSG_TEMPLATE:
      return { ...state, msgTemplate: action.data, TemplateLoader: false };
    case ADD_WORKFLOW:
      return { ...state, WorkflowAdd: action.data };
    case ADD_SEQUENCE:
      return { ...state, sequenceAdd: action.data };
    case ADD_SEQUENCE_CONTENT:
      return { ...state, sequenceContentAdd: action.data };
    case GET_ALL_WORKFLOW:
      return { ...state, AllWorkflow: action.data };
    case GET_ALL_CAMPAIGN:
      return { ...state, AllCampaign: action.data };
    case GET_ALL_STEP:
      return { ...state, AllStep: action.data };
    case GET_STEP_BY_ID:
      return { ...state, StepById: action.data };
    default:
      return state;
  }
};

export default Workflow;
