import styled from 'styled-components';

const ProgressContainer = styled.div`
  height: ${({ length }) => `${length}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
`;

const Milestone = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export { ProgressContainer, Milestone };
