import styled, { keyframes } from 'styled-components';

const ripple = keyframes`
    0% {
        opacity: 0;
        transform: scale(0)
    }
    25% {opacity: 0.5; transform: scale(.7)}
    50% {opacity: 1; transform: scale(1.4) }
    100% {opacity: 0; transform: scale(2.25)}
`;

const BlueDot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(82, 103, 255, 1);
  box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  border-radius: 50%;
  right: 12px;
  top: 12px;
  z-index: 100;
`;

const Ripple = styled(BlueDot)`
  animation: ${ripple} 2s linear infinite;
`;

export { BlueDot, Ripple };
