// Property > Building part

// Building.js
export const CardContainer = {
  marginRight: '20px',
  marginBottom: '20px'
};
export const propertyContainer = {
  marginLeft: '0px',
  display: 'flex',
  flexWrap: 'wrap'
};

//BuildingAdd.js
export const Commonst = {
  fontFamily: 'Inter',
  fontWeight: 400
};
export const HeaderStyle = {
  display: 'flex',
  color: '#20065f',
  fontSize: '16px',
  letterSpacing: -0.16,
  textTransform: 'uppercase'
};
export const closeIconStyle = {
  marginTop: '-3px',
  marginRight: '25px',
  cursor: 'pointer'
};
export const SaveMoreStyle = {
  width: '220px',
  height: '40px',
  borderRadius: '8px',
  border: '1px solid #023e8a',
  backgroundColor: '#ffffff',
  marginRight: '20px'
};
export const SavePropertyStyle = {
  width: '220px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#023e8a',
  color: '#fff'
};
export const NoteStyle = {
  color: '#525f80',
  fontFamily: 'Inter',
  fontSize: '12',
  fontWeight: '300'
};
export const BlockHeading = {
  color: '#0077b6',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: -0.16
};
export const subheading = {
  ...BlockHeading,
  fontSize: '14px'
};
export const mr = {
  marginRight: '20px'
};
export const df = {
  display: 'flex'
};
export const mb = {
  marginBottom: '10px'
};
export const mlC = {
  marginLeft: '16px'
};
export const typoGraph = {
  font: 'normal 14px Inter'
};
export const ptCan = {
  paddingTop: '18px'
};
export const custom = {
  ...ptCan,
  textDecoration: 'underline',
  color: 'rgb(34, 22, 95)',
  cursor: 'pointer',
  marginLeft: '16px'
};

//BuildingCard.js
export const font = {
  fontFamily: 'Inter'
};
export const cardStyle = {
  width: '291px',
  height: '230px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
  borderRadius: '8px',
  ...font
};
export const cardStyleList = {
  width: '1370px',
  height: '70px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
  display: 'flex',
  borderRadius: '8px',
  ...font
};
export const heroImage = {
  width: '292px',
  height: '120px'
};
export const Details = {
  display: 'flex'
};
export const heroImageList = {
  width: '212px',
  height: '134px',
  margin: '-17px'
};
export const heroImageList2 = {
  width: '180px',
  height: '73px',
  display: 'flex'
};
export const containerList = {
  marginRight: '10px',
  display: 'flex',
  float: 'right'
};

export const CardContentStyle = {
  padding: 0
};
export const HeadingStyle = {
  position: 'absolute',
  color: '#FFF',
  marginTop: '-40px',
  backgroundImage:
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)'
};
export const HeadingStyleList = {
  position: 'relative',
  color: '#FFF',
  marginTop: '-40px',
  backgroundImage:
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)'
};
export const popertyHeading = {
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '12px',
  marginLeft: '10px'
};
export const AddressStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  marginLeft: '10px',
  marginTop: '2px',
  whiteSpace: 'nowrap',
  maxWidth: '280px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const popertyHeadingList = {
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '12px'
};
export const AddressStyleList = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  marginTop: '2px',
  whiteSpace: 'nowrap',
  maxWidth: '280px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const DivStyle = {
  position: 'absolute',
  marginTop: '-122px',
  marginLeft: '218px'
};

export const CheckBoxDivStyle = {
  position: 'absolute',
  marginTop: '-122px'
};
export const editIcon = {
  cursor: 'pointer',
  color: 'green',
  marginTop: '10px'
};
export const deleteIcon = {
  cursor: 'pointer',
  color: 'red',
  marginTop: '10px'
};
export const BlockContent = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '13px',
  marginLeft: '10px',
  marginRight: '10px',
  fontSize: '12px'
};
export const BlockContentList = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '10px',
  marginRight: '10px',
  fontSize: '12px',
  flexWrap: 'wrap'
};
export const dflex = {
  display: 'flex'
};
export const iconStyle = {
  marginRight: '12px'
};
export const dataCnt = {
  minWidth: '139px'
};
export const dataCntList = {
  minWidth: '110px'
};
export const initialHead = {
  color: '#969696',
  fontWeight: '400'
};
export const initialCont = {
  fontSize: '14px'
};

//buildingImgUpload.js
export const uploaderStyle = {
  cursor: 'pointer',
  marginBottom: '20px'
};
export const ImgStyle = {
  width: '120px',
  height: '110px',
  borderRadius: '4px',
  border: '1px solid #d2d2d2',
  marginRight: '35px',
  marginBottom: '20px'
};
export const deleteStyle = {
  position: 'absolute',
  marginLeft: '-48px',
  marginTop: '-10px',
  color: 'red',
  cursor: 'pointer'
};
