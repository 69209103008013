import { GET_ALL_AMENITIES } from '../Constant';

const initialState = {
  AmenitiesData: ' '
};
const Amenities = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AMENITIES:
      return { ...state, AmenitiesData: action.data };
    default:
      return state;
  }
};

export default Amenities;
