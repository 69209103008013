import { gql } from '@apollo/client';

export const createCommentById = gql`
  mutation createComment($record: CreateOnecommentsInput!) {
    createComment(record: $record) {
      record {
        message
      }
    }
  }
`;

export const deleteCommentById = gql`
  mutation deleteCommentById($commentId: MongoID!) {
    deleteCommentById(_id: $commentId) {
      record {
        _id
      }
    }
  }
`;

export const updateByCommentId = gql`
  mutation updateComment(
    $commentId: MongoID!
    $record: UpdateByIdcommentsInput!
  ) {
    updateComment(_id: $commentId, record: $record) {
      record {
        _id
      }
    }
  }
`;
