import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Layout from '../../../Component/layout';
import { ReactComponent as Mail } from '../../../Media/workflow/Mail.svg';
import { ReactComponent as Refresh } from '../../../Media/workflow/Refresh.svg';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import AddStep from './AddStep';
import SequenceList from './SequenceList';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const PurpleSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    '&$checked': {
      color: 'white'
    },
    '&$checked + $track': {
      backgroundColor: '#20065f'
    }
  },
  checked: {},
  track: {}
})(Switch);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const NewSequence = props => {
  const [check, setCheck] = useState(false);
  const handleChange = event => {
    setCheck(event.target.checked);
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { id, setActiveId } = props;
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const part1 = {
    display: 'flex',
    backgroundColor: '#cdccec',
    width: '100%',
    flexDirection: 'column'
  };
  const textstyle = {
    display: 'flex',
    color: '#20065f',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600
  };
  const btnstyle1 = {
    borderRadius: 8,
    backgroundColor: '#525f80',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 600
  };
  const btnstyle2 = {
    borderRadius: 8,
    backgroundColor: '#20065f',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 600
  };
  const activestyle = {
    color: 'rgb(82, 95, 128)',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: '600',
    position: 'absolute',
    right: '20px',
    marginTop: '38px',
    zIndex: '1',
    display: 'flex'
  };
  const part2r1 = {
    display: 'flex'
  };
  const counttext = {
    backgroundColor: '#f1f5ff',
    width: 136,
    height: 60,
    marginRight: '20px'
  };
  const dflex = {
    display: 'flex'
  };
  const ml10 = {
    marginLeft: 10
  };
  const cursor = {
    cursor: 'pointer'
  };
  const posAb = {
    ...cursor
  };
  return (
    <div>
      <div style={part1}>
        <div
          style={{
            display: 'flex',
            marginTop: '22px',
            justifyContent: 'space-between',
            padding: '0px 20px'
          }}
          className="row1"
        >
          <div style={textstyle}>
            <div style={dflex}>
              <div style={{ paddingTop: '1px' }}>
                <ArrowBackIcon style={posAb} onClick={() => setActiveId('')} />
              </div>
              <span style={ml10}>
                {props.StepById &&
                  props.StepById.data &&
                  props.StepById.data.sequence &&
                  props.StepById.data.sequence.name &&
                  props.StepById.data.sequence.name}
              </span>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: '7px', cursor: 'pointer' }}>
              <Mail />
            </div>
            <div style={{ margin: '7px 20px 0px 20px', cursor: 'pointer' }}>
              <Refresh />
            </div>
            <div>
              <Button style={btnstyle1}>ADD PROSPECTS</Button>
            </div>
          </div>
        </div>
        <div
          className="row2"
          style={{
            marginTop: '19px',
            marginBottom: '15px',
            marginLeft: '35px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button style={btnstyle2}>AUTO SEND</Button>
          </div>
          <div style={{ position: 'relative' }}>
            <div className="actswitch" style={activestyle}>
              <div style={{ paddingTop: '10px' }}>ACTIVE</div>
              <PurpleSwitch
                checked={check}
                onChange={e => handleChange(e)}
                name="check"
              />
            </div>
          </div>
        </div>

        <div className="row3">
          <hr />
        </div>
        <div className="row4" style={{ display: 'flex', width: '100%' }}>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab label="SUMMARY" {...a11yProps(0)} />
                <Tab disabled label="TEXTS" {...a11yProps(1)} />
                <Tab disabled label="EMAILS" {...a11yProps(2)} />
                <Tab disabled label="CALLS" {...a11yProps(3)} />
                <Tab disabled label="SETTINGS" {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div style={part2r1}>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    CONTACTED
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    REACHED
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    OPENED
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    REPLIED
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    BOUNCED
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={counttext}>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px',
                      marginTop: '10px'
                    }}
                  >
                    OPTED OUT
                  </div>
                  <div
                    style={{
                      color: '#ff6f62',
                      fontFamily: 'Inter',
                      fontSize: 24,
                      fontWeight: 600,
                      textAlign: 'left',
                      marginLeft: '10px'
                    }}
                  >
                    0
                  </div>
                </div>
              </div>
              <SequenceList paramid={id} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              TEXTS
            </TabPanel>
            <TabPanel value={value} index={2}>
              EMAILS
            </TabPanel>
            <TabPanel value={value} index={3}>
              CALLS
            </TabPanel>
            <TabPanel value={value} index={4}>
              SETTINGS
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  StepById: state.Workflow.StepById
});

export default connect(mapStateToProps)(NewSequence);
