import React from 'react';
import { TabsWrapper } from './tabs.styles';

const Tabs = ({ children, background, padding, borderRadius }) => {
  return (
    <TabsWrapper
      background={background}
      padding={padding}
      borderRadius={borderRadius}
    >
      {children}
    </TabsWrapper>
  );
};

export default Tabs;
