import { DateRange, SignalCellularNull } from '@material-ui/icons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTour,
  fetchInboxMetaData,
  fetchMetaData,
  fetchPropertyByApartmentIdOffPlatform,
  fetchTimeSlots,
  fetchTourConfirmation
} from '../../Redux/Actions/InboxV1';
import {
  setGlobalError,
  setSuccessInfo
} from '../../Redux/Reducer/GlobalError';
import { setMessagesEmpty } from '../../Redux/Reducer/InboxV1';
import { DAYS_LIST, MONTHS_LIST } from '../../utils';
import Loader from '../Loader';
import { useStyles } from './styles';
import TourBookingModal from './TourBookingModal';
import DateInput from '../DateInput';
import { useTheme } from 'styled-components';
import { getListOfAvailableTourTypesForUI } from './utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    height: '660px',
    overflow: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, theme, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '24px',
        color: `${theme.text.heading}`
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          id="btn-close"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: `${theme.text.heading}`
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const TourSchedulingWidget = ({
  handleClose,
  schedule,
  fetchTourBack,
  interest,
  apartmentData,
  propertyData = [],
  leadData,
  leadId,
  SelectedBTimezone,
  unitSelected,
  lead
}) => {
  // apartmentData = apartmentData.filter((apartment) => apartment.isLive);
  const { availableTourTypes } = useSelector(state => state.Apartment);

  const [selectedDate, setSelectedData] = useState(new Date());

  useEffect(() => {
    let selectedDateTemp =
      dates?.length > 0
        ? new Date(moment(dates[0])?.format('YYYY MM DD'))
        : new Date();

    setSelectedData(selectedDateTemp);
  }, []);
  const [onPlatformTourExist, setOnPlatformTourExist] = useState(false);
  const [isZumaTour, setZumaTour] = useState(false);
  const [offPlatformProperty, setOffPlatformProperty] = useState([
    ...propertyData
  ]);

  useEffect(() => {
    setOffPlatformProperty([...propertyData]);
  }, [propertyData]);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <button className={classes.customDatePicker} onClick={onClick} ref={ref}>
      <DateRange />
      <Typography sx={{ marginLeft: '10px' }}>
        {value ? value : 'Select Date'}
      </Typography>
    </button>
  ));

  useEffect(() => {
    let res = schedule?.map(check => {
      if (check.isZumaTour === true) {
        setZumaTour(true);
        setOriginal(false);
      }
    });
    dispatch(fetchInboxMetaData({ leadId: leadId }));
  }, []);

  const [tourList, setTourList] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [original, setOriginal] = useState(true);
  const [tourTypeValue, setTourTypeValue] = useState('');

  useEffect(() => {
    if (apartmentData?.length > 0 && leadData) {
      const apartment = apartmentData?.find(apartment => {
        return apartment._id === leadData?.apartment?._id;
      });

      const availableTourTypesByApartment =
        getListOfAvailableTourTypesForUI(availableTourTypes);
      if (apartment) {
        setTourList(availableTourTypesByApartment);
      }
    } else {
      const availableTourTypesByApartment =
        getListOfAvailableTourTypesForUI(availableTourTypes);
      setCommunity({ name: 'Placeholder Community', _id: '123' });
      setTourList(availableTourTypesByApartment);
    }
    setContactTypes([
      { key: 'Call', value: 'call' },
      { key: 'Text', value: 'text' },
      { key: 'Email', value: 'email' }
    ]);
  }, [apartmentData, original]);

  const [unitsData, setUnitsData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (leadId && tourTypeValue !== '' && original) {
      dispatch(
        fetchTimeSlots({
          communityId: lead?.apartment?._id,
          leadId: leadId,
          tourType: tourTypeValue === 'selfguided' ? 'inperson' : tourTypeValue
        })
      );
    }
  }, [tourTypeValue, leadId]);

  const {
    slotsLoading,
    slots,
    dates,
    tourId,
    isTourCreated,
    tourConfirmationData,
    inboxMetaData,
    inboxMetaLoading,
    offPlatformPropertyByApi,
    slotsMessage,
    slotsErrorMessage
  } = useSelector(state => state.slotsData);

  const selectCustomDate = item => {
    setSelectedData(moment(item).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (slotsMessage) {
      dispatch(setSuccessInfo(slotsMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsMessage]);

  useEffect(() => {
    if (slotsErrorMessage) {
      dispatch(setGlobalError(slotsErrorMessage));
      dispatch(setMessagesEmpty());
    }
  }, [slotsErrorMessage]);

  useEffect(() => {
    if (!slotsLoading && dates?.length > 0) {
      setSelectedData(dates[0]);
    }
  }, [slotsLoading, dates]);

  useEffect(() => {
    if (offPlatformPropertyByApi && offPlatformPropertyByApi.length)
      setOffPlatformProperty([...offPlatformPropertyByApi]);
  }, [offPlatformPropertyByApi]);

  const memoisedCallGenerateDates = useCallback(() => {
    const listLength = 60; // days
    let result = [];

    for (let i = 0; i < listLength; i++) {
      let itemDate = new Date(); // starting today
      itemDate.setDate(itemDate.getDate() + i);
      result.push(itemDate);
    }

    return result.map((item, i) => {
      const formattedItemDate = moment(item).format('YYYY-MM-DD');
      const isDateAvailable = dates?.includes(formattedItemDate);
      return (
        <div
          onClick={() => selectCustomDate(item)}
          id="date-select"
          title="date-select"
          key={i}
          style={{
            backgroundColor:
              formattedItemDate == selectedDate
                ? theme.background?.selection
                : null,
            color:
              formattedItemDate == selectedDate ? theme.text?.secondary : null
          }}
          className={
            isDateAvailable
              ? `${classes.datesWrapper} date-box-custom`
              : `${classes.datesWrapper}  date-custom-disable date-box-custom`
          }
        >
          <span className={classes.dayWrapper}>
            {DAYS_LIST[item.getDay()].substring(0, 3).toLowerCase()}
          </span>
          <span>
            {item.getDate()}
            {MONTHS_LIST[item.getMonth()].substring(0, 3).toUpperCase()}
          </span>
        </div>
      );
    });
  }, [dates, selectCustomDate, selectedDate]);

  useEffect(() => {
    const updateUnitsData = [];
    if (offPlatformProperty?.length > 0 && leadData) {
      let b = offPlatformProperty?.map(property => {
        if (property) {
          const val =
            property.unitName !== 'Unit Placeholder'
              ? '$' +
                (!property?.monthlyRent ? 0 : property?.monthlyRent) +
                ' - ' +
                (!property?.bedRooms ? 0 : property?.bedRooms) +
                'bd ' +
                (!property?.bathRooms ? 0 : property?.bathRooms) +
                'ba - ' +
                (!property?.sqft
                  ? 0 + ' sqft - '
                  : property?.sqft + ' sqft - ') +
                (property?.name || '')
              : '$' +
                0 +
                ' - ' +
                0 +
                'bd ' +
                0 +
                'ba - ' +
                0 +
                ' sqft - ' +
                'Placeholder Unit';
          updateUnitsData.push({ label: val, value: val, _id: property._id });
        }
      });
    }
    setUnitsData(updateUnitsData);
  }, [offPlatformProperty]);

  const [nextStep, setNextStep] = useState(false);

  const [timeSelected, setTimeSelected] = useState(null);

  const [timeSlotsActive, settimeSlotsActive] = useState([]);

  const regex = /(\w{6}).+([.]\w*)/g;

  const handleTourType = e => {
    setTourTypeValue(e.target.value);
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openoffPlatformOn, setOffPlatformNo] = useState(false);

  useEffect(() => {
    if (isTourCreated && tourId !== null && nextStep && tourId) {
      let isMounted = true;
      const intervalId = setInterval(() => {
        //assign interval to a variaable to clear it
        dispatch(fetchTourConfirmation({ id: tourId }));
      }, 5000);
      fetchTourBack();
      return () => {
        clearInterval(intervalId); //This is important
        isMounted = false; // Let us know the component is no longer mounted.
      };
    }
  }, [nextStep, isTourCreated, tourId]);

  const [errorText, setErrorText] = useState('');
  const [contactTypeValue, setContactTypeValue] = useState('');
  const [community, setCommunity] = useState({ interest });
  const [newUnit, setNewUnit] = useState(unitSelected);
  const [time, setTime] = useState('07:30');
  const [website, setWebsite] = useState(
    interest?.website ? interest?.website : ''
  );
  const [openSummary, setOpenSummary] = useState(false);

  const handleContactType = e => {
    setContactTypeValue(e.target.value);
  };

  useEffect(() => {
    setCommunity(interest);
    setNewUnit(unitSelected);
  }, [original]);

  const handleNext = async () => {
    setNextStep(true);
    let obj = {
      confirmedDate: timeSelected,
      leadId: leadId,
      aptId: community?._id,
      propId: newUnit?._id,
      tourType: tourTypeValue,
      isZumaTour: original,
      contactType: contactTypeValue
    };
    await dispatch(createTour({ data: obj }));
    await dispatch(fetchMetaData({ leadId }));
  };
  let date = moment(selectedDate).format().slice(0, 11) + time + ':00';
  const handleSave = async () => {
    setNextStep(true);
    setOpenSummary(false);
    let obj = {
      confirmedDate: date,
      leadId: leadId,
      aptId: community?._id,
      propId: newUnit?._id,
      tourType: tourTypeValue,
      isZumaTour: original,
      contactType: contactTypeValue
    };
    await dispatch(createTour({ data: obj }));
    await dispatch(fetchMetaData({ leadId }));
  };

  const handleSummary = () => {
    setOpenSummary(true);
  };
  const handleCommunity = (e, newValue) => {
    setCommunity({
      _id: newValue?._id,
      name: newValue?.name
    });
    setNewUnit('');
    setWebsite(newValue?.website);
    dispatch(
      fetchPropertyByApartmentIdOffPlatform({ apartmentId: newValue?._id })
    );
    const updateUnitsData = [];

    let b = offPlatformProperty?.filter(property => {
      if (property && property._id && property.aptId === newValue?._id) {
        if (property) {
          const val =
            property.unitName !== 'Unit Placeholder'
              ? '$' +
                (!property?.monthlyRent ? 0 : property?.monthlyRent) +
                ' - ' +
                (!property?.bedRooms ? 0 : property?.bedRooms) +
                'bd ' +
                (!property?.bathRooms ? 0 : property?.bathRooms) +
                'ba - ' +
                (!property?.sqft
                  ? 0 + ' sqft - '
                  : property?.sqft + ' sqft - ') +
                (property?.name || '')
              : '$' +
                0 +
                ' - ' +
                0 +
                'bd ' +
                0 +
                'ba - ' +
                0 +
                ' sqft - ' +
                'Placeholder Unit';
          updateUnitsData.push({ label: val, value: val, _id: property._id });
        }
      }
    });
    setUnitsData(updateUnitsData);
  };

  const handleUnit = (e, newValue) => {
    setNewUnit({ _id: newValue?._id, value: newValue?.value });
  };

  const handleTimeCLick = val => {
    setTimeSelected(val);
  };

  const handleTime = e => {
    setTime(e.target.value);
  };

  useEffect(() => {
    let selectedDay = selectedDate
      ? moment(selectedDate).format('YYYY-MM-DD')
      : moment(dates?.[0]).format('YYYY-MM-DD');
    if (slots?.length > 0) {
      let newslots = slots?.filter(val => val.includes(selectedDay));
      if (newslots?.length > 0) {
        let finalslots = newslots?.map(e => e.slice(0, 19));
        settimeSlotsActive(finalslots);
      }
    }
  }, [selectedDate, slots]);

  const handleWidgetClose = () => {
    setErrorText('');
    setShowSuccess(false);
    setShowError(false);
    handleClose();
  };

  const handleBack = () => {
    setNextStep(false);
    setErrorText('');
    setShowSuccess(false);
    setShowError(false);
  };

  const handleOriginal = () => {
    setOriginal(true);
  };

  const handleAdditional = () => {
    setOriginal(false);
  };
  useEffect(() => {
    if (original === false) {
      if (isTourCreated === true) {
        setShowSuccess(true);
      }
    } else if (original === true) {
      if (tourConfirmationData?.confirmed) {
        setShowSuccess(true);
      } else if (
        !tourConfirmationData?.confirmed &&
        tourConfirmationData?.errorType &&
        tourConfirmationData?.errorType !== ''
      ) {
        setShowError(true);
        if (tourConfirmationData?.errorType === 'TIME_NOT_AVAILABLE') {
          setErrorText('Selected time slot is not available.');
        }
      }
    }
  }, [nextStep, isTourCreated, tourConfirmationData]);
  const theme = useTheme();
  const classes = useStyles(theme);

  function disabledDate(current) {
    return moment(current).day() === 0;
  }

  return (
    <div>
      <BootstrapDialog
        sx={{ height: '701px' }}
        onClose={handleWidgetClose}
        className={'tour-schedule-popup'}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <div
          style={{
            display: 'flex',
            minWidth: '600px',
            background: theme.background.secondary
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            theme={theme}
          >
            Schedule Tour
          </BootstrapDialogTitle>
          <div
            style={
              openSummary === true || nextStep === true || isZumaTour === true
                ? { pointerEvents: 'none', opacity: '70%' }
                : null
            }
          >
            <div className={classes.toggler}>
              <div
                className={
                  original === true ? classes.activeTab : classes.inactiveTab
                }
                onClick={handleOriginal}
              >
                On Platform
              </div>
              <div
                className={
                  original === false ? classes.activeTab : classes.inactiveTab
                }
                onClick={handleAdditional}
              >
                Off Platform
              </div>
            </div>
          </div>
        </div>
        {original === true ? (
          <>
            {!nextStep ? (
              <DialogContent
                sx={{
                  background: theme.background.secondary
                }}
              >
                <div className={classes.dialogContainer}>
                  <div style={{ width: '276px' }}>
                    <Typography className={classes.labelRoot}>
                      Preferred community
                    </Typography>
                    <Autocomplete
                      id="on-platform-preferred-community"
                      componentName="on-platform-preferred-community"
                      disabled={
                        leadData?.preference?.interest ||
                        leadData?.apartment?._id ||
                        (leadData?.tenant?.name &&
                          leadData?.tenant?.name !== 'Skylight')
                      }
                      className={classes.inputRoot}
                      disablePortal
                      value={interest}
                      placeholder="Select Preferred community"
                      options={apartmentData}
                      getOptionLabel={option => option.name}
                      onChange={handleCommunity}
                      style={{
                        WebkitTextFillColor: theme?.text?.primary
                      }}
                      renderInput={params => (
                        <TextField
                          size="small"
                          {...params}
                          variant="outlined"
                          placeholder="Select preferred community"
                          sx={{
                            color: theme.text.primary
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: '276px', marginLeft: '41px' }}>
                    <Typography className={classes.labelRoot}>
                      Preferred unit
                    </Typography>
                    <Autocomplete
                      disabled
                      className={classes.inputRoot}
                      disablePortal
                      id="combo-box-demo"
                      options={unitsData || []}
                      value={
                        unitSelected
                          ? '$' +
                            unitSelected?.monthlyRent +
                            ' - ' +
                            unitSelected?.bedRooms +
                            'bd ' +
                            unitSelected?.bathRooms +
                            'ba - ' +
                            (!unitSelected?.sqft
                              ? 0 + ' sqft - '
                              : unitSelected?.sqft + ' sqft - ') +
                            unitSelected?.name
                          : 'No unit selected'
                      }
                      renderInput={params => (
                        <TextField
                          size="small"
                          {...params}
                          variant="outlined"
                          placeholder="Select preferred unit"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className={classes?.dialogContainer2}>
                  <div style={{ width: '276px' }}>
                    <Typography className={classes?.labelRoot}>
                      Tour type
                    </Typography>
                    <FormControl className={classes?.inputRoot} fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tourTypeValue}
                        required={!tourTypeValue}
                        onChange={handleTourType}
                        placeholder="Select tour type"
                        name="on-platform-tour-type"
                      >
                        {tourList &&
                          tourList.length > 0 &&
                          tourList?.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ width: '276px', marginLeft: '41px' }}>
                    <Typography className={classes.labelRoot}>
                      Contact type
                    </Typography>
                    <FormControl className={classes.inputRoot} fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={contactTypeValue}
                        required={!contactTypeValue}
                        onChange={handleContactType}
                        name="off-platform-contact-type"
                        placeholder="Select contact type"
                      >
                        {contactTypes.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={classes.bodyContainer}>
                  <Typography className={classes.labelRoot}>
                    Select slot
                  </Typography>
                  <div className={classes.slotsContainer}>
                    {!slotsLoading ? (
                      <>
                        <div className={classes.calendarContainer}>
                          <DateInput
                            id="select-tour-date"
                            name="select-tour-date"
                            selected={new Date(moment(selectedDate))}
                            placeholderText="Select Date"
                            onChange={date => setSelectedData(date)}
                            minDate={new Date()}
                            includeDates={dates?.map(res => {
                              let finale = moment(res)?.format('YYYY MM DD');
                              return new Date(finale);
                            })}
                            customInput={<CustomDateInput />}
                          />
                          <div className={classes.datesWrapperContainer}>
                            <div className={classes.datesContainer}>
                              {memoisedCallGenerateDates()}
                            </div>
                          </div>
                        </div>
                        <div className={classes.calendarAndSlotsContainer}>
                          <p className={classes.timeSlotsText}>
                            *Available time slots
                          </p>
                          <div className={classes.timeContainer}>
                            {tourTypeValue === '' ? (
                              <span
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  color: theme.text.primary
                                }}
                              >
                                Select tour type to view available slots
                              </span>
                            ) : timeSlotsActive.length === 0 ? (
                              <span
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  color: theme.text.primary
                                }}
                              >
                                No slots available
                              </span>
                            ) : (
                              timeSlotsActive?.map((time, i) => {
                                return (
                                  <p
                                    id="time-select"
                                    key={i}
                                    style={{
                                      background:
                                        timeSelected === time
                                          ? theme.background.selection
                                          : '',
                                      color:
                                        timeSelected === time
                                          ? theme.text.secondary
                                          : ''
                                    }}
                                    onClick={() => handleTimeCLick(time)}
                                    className={classes.timeWrapper}
                                  >
                                    {moment(time).format('LT')}
                                  </p>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          height: '235px',
                          width: '235px',
                          margin: '15px auto'
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </DialogContent>
            ) : (
              <DialogContent
                sx={{
                  margin: '0 auto',
                  background: theme.background.secondary
                }}
              >
                <TourBookingModal
                  handleBack={handleBack}
                  status={showSuccess ? 'success' : showError ? 'error' : ''}
                  theme={theme}
                />
              </DialogContent>
            )}
            <DialogActions
              sx={{
                background: theme.background.secondary
              }}
            >
              <div>
                <span
                  style={{ left: !nextStep ? '20px' : '20px' }}
                  className={
                    !nextStep ? classes.activeDot : classes.disabledDot
                  }
                ></span>
                <span
                  style={{ left: nextStep ? '40px' : '60px' }}
                  className={nextStep ? classes.activeDot : classes.disabledDot}
                ></span>
              </div>
              {!nextStep ? (
                <Button
                  id="btn-next"
                  title="btn-next"
                  style={
                    onPlatformTourExist === true
                      ? { pointerEvents: 'none', opacity: '70%' }
                      : null
                  }
                  disabled={
                    tourTypeValue === '' ||
                    timeSelected === SignalCellularNull ||
                    !unitSelected
                  }
                  autoFocus
                  onClick={handleNext}
                >
                  <ArrowForwardIcon
                    id="icon-arrow-forward"
                    sx={{
                      background:
                        tourTypeValue === '' ||
                        timeSelected === null ||
                        !unitSelected
                          ? 'rgba(82, 103, 255, 0.3)'
                          : ''
                    }}
                    className={classes.forwardIcon}
                  />
                </Button>
              ) : (
                <div>
                  <Button autoFocus onClick={handleBack}>
                    <ArrowBackIcon className={classes.backIcon} />
                  </Button>
                </div>
              )}
            </DialogActions>
          </>
        ) : (
          <>
            {openSummary === true ? (
              <DialogContent sx={{ background: theme.background.secondary }}>
                <div className={classes.dialogContainer3}>
                  <div style={{ width: '276px' }}>
                    <div className={classes.summaryContainer}>
                      <div className={classes.summaryAppointmentContainer}>
                        <div className={classes.appointmentText}>
                          Appointment
                        </div>
                      </div>
                      <div className={classes.summaryDetails}>
                        <div className={classes.dateTime}>
                          {moment(date).format('h:mm a,ddd,DD-MMM-YYYY')}
                        </div>
                        <div className={classes.detailsContainer}>
                          <span className={classes.leftDetails}>
                            Community :
                          </span>
                          <span className={classes.rightDetails}>
                            {community?.name?.length > 7
                              ? community?.name.substring(0, 7) + '...'
                              : community?.name}
                          </span>
                        </div>
                        <div className={classes.detailsContainer}>
                          <span className={classes.leftDetails}>Client :</span>
                          <span className={classes.rightDetails}>
                            {lead?.tenant?.name
                              ? lead?.tenant?.name?.length > 7
                                ? lead?.tenant?.name.substring(0, 7) + '...'
                                : lead?.tenant?.name
                              : '-'}
                          </span>
                        </div>
                        <div className={classes.detailsContainer}>
                          <span className={classes.leftDetails}>Unit :</span>
                          <span className={classes.rightDetails}>
                            {newUnit
                              ? newUnit?.value?.length > 7
                                ? newUnit?.value?.substring(0, 7) + '...'
                                : newUnit?.value
                              : '$' +
                                unitSelected?.monthlyRent +
                                ' - ' +
                                unitSelected?.bedRooms +
                                'bd ' +
                                unitSelected?.bathRooms +
                                '...'}
                          </span>
                        </div>
                        <div className={classes.detailsContainer}>
                          <span className={classes.leftDetails}>Contact :</span>
                          <span className={classes.rightDetails}>
                            {contactTypeValue}
                          </span>
                        </div>
                        <div className={classes.detailsContainer}>
                          <span className={classes.leftDetails}>
                            Tour Type :
                          </span>
                          <span className={classes.rightDetails}>
                            {tourTypeValue}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: 'flex', marginTop: '25px' }}>
                      <div>
                        <ErrorOutlineIcon sx={{ color: '#FF6F62' }} />
                      </div>
                      <div
                        style={{
                          marginTop: '3px',
                          display: 'flex',
                          marginLeft: '10px',
                          fontFamily: 'Inter',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#393939'
                        }}
                      >
                        Have you booked the tour on the
                        <div
                          onClick={() =>
                            website ? null : alert('website not available')
                          }
                        >
                          {website ? (
                            <a
                              style={{ marginLeft: '5px' }}
                              href={website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              website
                            </a>
                          ) : (
                            <a href="#">website</a>
                          )}
                        </div>
                        ?
                      </div>
                    </div>
                  </div>
                  <div className={classes.saveContainer}>
                    <div className={classes.yesButton} onClick={handleSave}>
                      Yes
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal'
                      }}
                      onClick={() => setOffPlatformNo(true)}
                    >
                      No
                    </div>
                  </div>
                  {openoffPlatformOn === true ? (
                    <div
                      style={{
                        color: '#FF4D00',
                        marginTop: '16px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal'
                      }}
                    >
                      *Please book the tour on client’s website
                    </div>
                  ) : null}
                </div>
              </DialogContent>
            ) : (
              <>
                {!nextStep ? (
                  <DialogContent
                    sx={{ background: theme.background.secondary }}
                  >
                    <div className={classes?.dialogContainer}>
                      <div style={{ width: '276px' }}>
                        <Typography className={classes?.labelRoot}>
                          Preferred community
                        </Typography>
                        <FormControl className={classes?.inputRoot} fullWidth>
                          <Autocomplete
                            value={community}
                            disableClearable
                            placeholder="Select Preferred community"
                            options={apartmentData || []}
                            getOptionLabel={option => option.name}
                            onChange={handleCommunity}
                            key={`${Math.floor(Math.random() * 1000)}-min`}
                            renderInput={params => (
                              <TextField
                                size="small"
                                required={!community}
                                {...params}
                                variant="outlined"
                                placeholder="Select preferred community"
                              />
                            )}
                          >
                            {tourList &&
                              tourList.length > 0 &&
                              tourList?.map((item, i) => {
                                return (
                                  <MenuItem key={i} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                          </Autocomplete>
                        </FormControl>
                      </div>
                      <div style={{ width: '276px', marginLeft: '41px' }}>
                        <Typography className={classes.labelRoot}>
                          Preferred unit
                        </Typography>
                        <Autocomplete
                          className={classes.inputRoot}
                          disablePortal
                          disableClearable
                          id="combo-box-demo"
                          value={
                            unitSelected
                              ? '$' +
                                unitSelected?.monthlyRent +
                                ' - ' +
                                unitSelected?.bedRooms +
                                'bd ' +
                                unitSelected?.bathRooms +
                                'ba - ' +
                                (!unitSelected?.sqft
                                  ? 0 + ' sqft - '
                                  : unitSelected?.sqft + ' sqft - ') +
                                unitSelected?.name
                              : 'No unit selected'
                          }
                          options={unitsData || []}
                          onChange={handleUnit}
                          key={`${Math.floor(Math.random() * 1000)}-min`}
                          renderInput={params => (
                            <TextField
                              {...params}
                              required={!newUnit}
                              placeholder="Select preferred unit"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className={classes.dialogContainer2}>
                      <div style={{ width: '276px' }}>
                        <Typography className={classes.labelRoot}>
                          Tour type
                        </Typography>
                        <FormControl className={classes.inputRoot} fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tourTypeValue}
                            required={!tourTypeValue}
                            onChange={handleTourType}
                            placeholder="Select tour type"
                          >
                            {tourList &&
                              tourList.length > 0 &&
                              tourList.map((item, i) => {
                                return (
                                  <MenuItem key={i} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ width: '276px', marginLeft: '41px' }}>
                        <Typography className={classes.labelRoot}>
                          Contact type
                        </Typography>
                        <FormControl className={classes.inputRoot} fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={contactTypeValue}
                            required={!contactTypeValue}
                            onChange={handleContactType}
                            placeholder="Select contact type"
                          >
                            {contactTypes.map((item, i) => {
                              return (
                                <MenuItem key={i} value={item.value}>
                                  {item.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className={classes.bodyContainer}>
                      <Typography className={classes.labelRoot}>
                        Select slot
                        {website ? (
                          <a
                            href={website ? website : ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Website
                          </a>
                        ) : null}
                      </Typography>
                      <div className={classes.slotsContainer}>
                        <div className={classes.calendarContainer}>
                          <DateInput
                            selected={
                              dates?.length > 0
                                ? new Date(
                                    moment(dates[0])?.format('YYYY-MM-DD')
                                  )
                                : selectedDate
                            }
                            placeholderText="Select Date"
                            onChange={date => setSelectedData(date)}
                            minDate={new Date()}
                            customInput={<CustomDateInput />}
                          />
                          <div className={classes.datesWrapperContainer}>
                            <div className={classes.datesContainer}>
                              {memoisedCallGenerateDates()}
                            </div>
                          </div>
                        </div>
                        <div className={classes.calendarAndSlotsContainer}>
                          <p className={classes.timeSlotsText}>
                            *Available time slots
                          </p>
                          <TextField
                            id="time"
                            type="time"
                            defaultValue="07:30"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                background: theme.text.primary
                              }
                            }}
                            inputProps={{
                              step: 300, // 5 min
                              color: theme.text.primary,
                              title: 'off-platform-time-select'
                            }}
                            sx={{
                              width: 150,
                              height: '117px',
                              justifyContent: 'center',
                              marginLeft: '35%',
                              '& .MuiInputBase-input': {
                                color: theme?.text?.primary,
                                border: `1px solid ${theme?.border?.primary}`
                              }
                            }}
                            onChange={handleTime}
                            title="off-platform-time-select"
                          />
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                ) : (
                  <DialogContent
                    sx={{
                      margin: '0 auto',
                      background: theme.background.secondary
                    }}
                  >
                    <TourBookingModal
                      handleBack={handleBack}
                      status={
                        showSuccess ? 'success' : showError ? 'error' : ''
                      }
                      errorText={errorText}
                      theme={theme}
                    />
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions sx={{ background: theme.background.secondary }}>
              <div>
                <span
                  style={{ left: !openSummary ? '20px' : '20px' }}
                  className={
                    !openSummary ? classes.activeDot : classes.disabledDot
                  }
                ></span>
                <span
                  style={{ left: openSummary ? '40px' : '60px' }}
                  className={
                    openSummary ? classes.activeDot : classes.disabledDot
                  }
                ></span>
              </div>
              {openSummary === false && !nextStep ? (
                <Button
                  disabled={
                    !tourTypeValue ||
                    !community ||
                    !newUnit ||
                    !contactTypeValue
                  }
                  autoFocus
                  onClick={handleSummary}
                  id="btn-next"
                >
                  <ArrowForwardIcon
                    id="icon-arrow-forward"
                    sx={{
                      background:
                        !tourTypeValue ||
                        !community ||
                        !newUnit ||
                        !contactTypeValue
                          ? 'rgba(82, 103, 255, 0.3)'
                          : ''
                    }}
                    className={classes.forwardIcon}
                  />
                </Button>
              ) : null}
            </DialogActions>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default TourSchedulingWidget;
