import React from 'react';

const CallIcon = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5187 16.4808L15.6251 14.5672C15.6251 14.5672 15.0954 14.137 14.4514 14.5038L13.4133 15.1293C13.4133 15.1293 12.9885 15.3363 12.6096 14.8645C11.4282 13.509 10.5772 12.0954 9.97203 10.4039C9.7341 9.74416 10.1278 9.57296 10.1278 9.57296L10.8894 9.11445C11.5198 8.72661 11.3986 8.05594 11.3986 8.05594L10.9241 5.32413C10.618 4.07963 8.70535 4.46593 7.97548 5.39473C6.27888 7.54922 7.43221 10.3583 8.337 12.4779C8.76615 13.4846 9.43679 14.4891 10.0034 15.296C10.9968 16.7068 13.2486 19.3834 15.956 18.9384C17.1202 18.746 18.4617 17.3487 17.5187 16.4808Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default CallIcon;
