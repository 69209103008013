import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { cntStyl } from '../../../styles/Inboxstyle';
//
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl } from '@material-ui/core';
import { getAllPropertyAction } from '../../../Redux/Actions/Property';
import {
  addSequenceContentAction,
  createCampaignAction
} from '../../../Redux/Actions/workflow';

const PurpleSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    '&$checked': {
      color: 'white'
    },
    '&$checked + $track': {
      backgroundColor: '#ff3d00'
    }
  },
  checked: {},
  track: {}
})(Switch);

const NewCampaignModal = props => {
  const dispatch = useDispatch();
  const [Type, setType] = useState(null);
  const [valid, setvalid] = useState(false);
  const [check, setCheck] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [Campaign, setCampaign] = useState('');

  const CardStyle = {
    width: '600px',
    fontFamily: '"Inter"',
    boxShadow: '0px 0px 10px black'
  };
  const cardcont = {
    padding: '20px 20px 12px 20px'
  };

  const Cancel = {
    width: 120,
    height: 40,
    borderRadius: 8,
    border: '1px solid #20065f',
    backgroundColor: '#ffffff',
    color: '#20065f',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 700,
    fontStyle: 'normal',
    letterSpacing: 1.4,
    textAlign: 'center',
    marginRight: '20px'
  };

  const SendFeed = {
    width: 120,
    height: 40,
    borderRadius: 8,
    backgroundColor: '#20065f',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 700
  };
  const BtnContainer = {
    // marginBottom: "15px",
    textAlign: 'right',
    marginRight: '14px',
    width: '100%'
  };

  useEffect(() => {
    dispatch(getAllPropertyAction());
  }, []);

  const handleChange = event => {
    setCheck(event.target.checked);
  };

  const setAutoCompleteValueFun = newval => {
    setAutoCompleteValue(newval);
  };

  const Cancelfun = () => {
    props.setOpen(false);
    setType(null);
    setCampaign('');
    setAutoCompleteValue([]);
    setCheck(false);
    if (props.wc) {
      dispatch(addSequenceContentAction(false));
      props.setOpen(true);
    }
  };

  const SaveDate = () => {
    if (
      Campaign !== null &&
      Campaign !== '' &&
      autoCompleteValue !== null &&
      autoCompleteValue.length > 0
    ) {
      let o = {};
      o.name = Campaign;

      o.properties = autoCompleteValue.map(res => {
        return {
          id: res._id,
          type: res.propType
        };
      });
      dispatch(createCampaignAction(o));
      setCampaign('');
      setAutoCompleteValue([]);
      props.setOpen(false);
      setType(null);
      if (props.wc) {
        dispatch(addSequenceContentAction(false));
        props.setOpen(true);
      }
    } else {
      setvalid(true);
    }
  };

  return (
    <div>
      <Modal
        className={'clModal'}
        open={props.open}
        onClose={() => {
          props.setOpen(false);
          if (props.wc) {
            dispatch(addSequenceContentAction(false));
            props.setOpen(true);
          }
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card style={CardStyle}>
          <CardContent style={cardcont}>
            <div
              style={(cntStyl, { display: 'flex', flexDirection: 'column' })}
            >
              <div style={{ margin: '10px' }}>
                <span
                  style={{
                    width: 123,
                    height: 20,
                    fontStyle: 'normal',
                    letterSpacing: 'normal',
                    lineHeight: 'normal',
                    textAlign: 'left',
                    color: '#20065f',
                    fontFamily: 'Inter',
                    fontSize: 16,
                    fontWeight: 700
                  }}
                >
                  New Campaign
                </span>
                <ClearIcon
                  onClick={() => Cancelfun()}
                  style={{
                    cursor: 'pointer',
                    float: 'right'
                  }}
                />
              </div>
              <div style={{ marginLeft: '-1.11rem', width: '37.2rem' }}>
                <hr />
              </div>

              <div style={{ margin: '5px 15px 15px 15px' }}>
                <div
                  style={{
                    height: 19,
                    color: '#393939',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 600,
                    fontStyle: 'normal',
                    letterSpacing: 'normal',
                    lineHeight: 'normal',
                    marginBottom: '12px'
                  }}
                >
                  Campaign Name
                </div>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Campaign Name"
                  value={Campaign}
                  onChange={e => setCampaign(e.target.value)}
                  size="small"
                  error={valid && (Campaign === '' || Campaign === null)}
                />
              </div>
              <div style={{ margin: '5px 15px 15px 15px' }}>
                <div
                  style={{
                    width: 32,
                    height: 19,
                    color: '#393939',
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 600,
                    fontStyle: 'normal',
                    letterSpacing: 'normal',
                    lineHeight: 'normal',
                    marginBottom: '12px'
                  }}
                >
                  Properties
                </div>

                <Autocomplete
                  multiple
                  freeSolo
                  id="tags-outlined"
                  options={props.PropertyData && props.PropertyData.data.data}
                  getOptionLabel={option => option.name}
                  value={autoCompleteValue}
                  onChange={(e, newval, reason) => {
                    setAutoCompleteValueFun(newval);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Type select properties(auto suggest)"
                      placeholder="Properties"
                      error={
                        valid &&
                        (autoCompleteValue === '' ||
                          autoCompleteValue === null ||
                          autoCompleteValue.length === 0)
                      }
                    />
                  )}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '77%', marginLeft: '17px' }}>
                  <span
                    style={{
                      marginRight: '10px',
                      color: '#393939',
                      fontFamily: 'Inter',
                      fontSize: 14,
                      fontWeight: 400
                    }}
                  >
                    Manual
                  </span>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={check}
                        onChange={e => handleChange(e)}
                        name="check"
                      />
                    }
                  />
                  <span
                    style={{
                      marginLeft: '-19px',
                      color: '#393939',
                      fontFamily: 'Inter',
                      fontSize: 14,
                      fontWeight: 400
                    }}
                  >
                    Auto-Send
                  </span>
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => Cancelfun()}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    style={SendFeed}
                    onClick={() => SaveDate()}
                  >
                    save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  PropertyData: state.Property.PropertyData,
  PropObj: state.Property.PropObj
});
export default connect(mapStateToProps)(NewCampaignModal);
