import React from 'react';

const ChevronRight = ({ onClick, style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={style}
    >
      <circle cx="12" cy="12" r="12" fill="#42526E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2759 10.8759C15.4634 11.0635 15.5687 11.3178 15.5687 11.5829C15.5687 11.8481 15.4634 12.1024 15.2759 12.2899L10.6829 16.8829C10.4966 17.0655 10.2458 17.1672 9.985 17.1658C9.72417 17.1645 9.4744 17.0603 9.28996 16.8759C9.10553 16.6915 9.00133 16.4417 9.00001 16.1809C8.9987 15.92 9.10036 15.6692 9.28293 15.4829L13.1829 11.5829L9.28293 7.68293C9.10036 7.49664 8.9987 7.24583 9.00001 6.985C9.00133 6.72417 9.10553 6.4744 9.28996 6.28996C9.4744 6.10553 9.72417 6.00133 9.985 6.00001C10.2458 5.9987 10.4966 6.10036 10.6829 6.28293L15.2759 10.8759Z"
        fill="white"
      />
    </svg>
  );
};

export default ChevronRight;
