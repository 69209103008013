import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Component/layout';
import ChatContainer from './ChatContainer';
import ChatInputBar from './ChatInputBar/ChatinputBar';
import LeadHeader from './LeadHeaders';
import LeadsList from './LeadList';
import Empty from '../../Media/DashboardV1/empty.gif';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { emptyMetaData, fetchMetaData } from '../../Redux/Actions/InboxV1';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import Toaster from '../../Component/Common/Toaster';
import { MONTHS_LIST } from '../../utils';
import { setKelseyTrigger } from '../../Redux/Reducer/InboxV1';
import { setGlobalError } from '../../Redux/Reducer/GlobalError';
import {
  socket,
  socketEmitter,
  socketListener,
  socketRemover
} from '../../utils/socket';
import LeadContainerInfo from '../../Component/LeadContainerInfo';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LeadInfoById } from '../../gql/queries/leadInfo';
import { propertyByApartment } from '../../gql/queries/propertyByApartmentId';
import { leadUpdateById } from '../../gql/mutations/leadUpdateById';
import { fetchApartmentById } from '../../gql/queries/apartmentById';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import ArchiveConfirmation from './ArchiveConfirmation';
import InboxContextProvider from '../../utils/ContextProviders/InboxContextProvider';
import SOPAndAgentNotes from './SOPAndAgentNotes';
import NotificationPopup from './SOPAndAgentNotes/NotificationPopup';
import { InboxChatNdHeader, Wrapper } from './styles.inboxv2';
import MaximisedPnAWidget from '../../Component/MaximizedPnAWidget';
import _ from 'lodash';
import { restartDataCapture, shutdownDataCapture } from '../../fullstory';
import { FullStory } from '@fullstory/browser';

const InboxV2 = props => {
  const { history } = props;
  const dispatch = useDispatch();

  const inboxSlice = useSelector(state => state.inboxMessages);

  const {
    inboxMetaData,
    isLeadsEmpty,
    toastMessage,
    updatedLeadId,
    messageSent,
    loadMoreMessages,
    newNoteInsertionInProgress,
    isStageUpdated
  } = inboxSlice;

  socket.onAny((event, args) => {
    if (event === 'error') {
      setSocketError(args);
    }
  });

  const [messagesLoading, setMessagesLoading] = useState(true);
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [socketError, setSocketError] = useState(null);
  const [selectedLeadId, setSelectedLeadId] = useState('');
  const [apartmentId, setApartmentId] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [chatArchivedMsg, setChatArchivedMsg] = useState('');
  const [snackBarMessage, setSnackbarMessage] = useState({});
  const [archiveClick, setArchiveClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [regularLeads, setRegularLeads] = useState([]);
  const [isCheatSheetUsed, setIsCheatSheetUsed] = useState(false);
  const [openSopDrawer, setOpenSopDrawer] = useState(false);
  const [showNewMsgNotification, setShowNewMsgNotification] = useState(false);
  const [isMaximizedPnAOpen, setIsMaximizedPnAOpen] = useState(false);
  const location = useLocation();
  const params = useParams();
  const isOnInboxPage =
    location.pathname === '/inbox' || location.pathname === '/';

  const { userData, updateLeadData, darkMode } = useContext(GlobalContext);
  const userId = userData?.user_id;
  const { leadEnabled, communityEnabled, clientEnabled } =
    inboxMetaData?.ai || {};

  const handlePNAExpansionToggle = e => {
    setIsMaximizedPnAOpen(prevState => {
      if (!prevState) {
        restartDataCapture();
        FullStory('trackEvent', {
          name: 'P&A Widget Opened',
          properties: {
            popupName: 'P&AWidget'
          }
        });
      } else {
        FullStory('trackEvent', {
          name: 'P&A Widget Closed',
          properties: {
            popupName: 'P&AWidget'
          }
        });
        shutdownDataCapture();
      }
      return !prevState;
    });
  };

  useEffect(() => {
    if (socketError) {
      dispatch(setGlobalError(socketError));
    }
  }, [socketError]);

  useEffect(() => {
    return () => {
      dispatch(emptyMetaData());
    };
  }, []);

  useEffect(() => {
    if (updatedLeadId) {
      handleLeadChange(updatedLeadId);
    }
  }, [updatedLeadId]);

  const [fetchLeadData, { data, loading: leadDataLoading }] = useLazyQuery(
    LeadInfoById,
    {
      onCompleted(data) {
        socketEmitter('getLeadMessages', {
          leadId: data?.leadById?._id,
          user: userData
        });
        updateLeadData(data?.leadById);
        if (data?.leadById?.apartment?._id) {
          setApartmentId(data?.leadById?.apartment?._id);
          fetchPropertyByApartmentId({
            variables: { apartmentId: data?.leadById?.apartment?._id }
          });
        } else {
          setApartmentId('');
        }
        setSnackbarMessage({});
      },
      onError(error) {
        setGlobalError(error?.message);
      },
      notifyOnNetworkStatusChange(d) {}
    }
  );

  const leadData = data?.leadById || {};

  const getScheduledLeadMessages = leadId => {
    socketEmitter('getLeadScheduledMessages', {
      leadId: leadId,
      user: userData
    });
  };
  const [
    fetchPropertyByApartmentId,
    { data: { propertiesByApartmentId } = [] }
  ] = useLazyQuery(propertyByApartment);
  const { data: apartmentData } = useQuery(fetchApartmentById, {
    variables: { apartmentId },
    skip: !apartmentId
  });

  const propertyData = propertiesByApartmentId || [];
  const propertiesWithoutDemoUnits =
    propertyData?.filter(
      pty => !(pty.name?.endsWith('Z') || pty.name?.endsWith('z'))
    ) || [];

  const [leadUpdateMutate] = useMutation(leadUpdateById, {
    onCompleted(data, clientOptions) {
      if (clientOptions?.variables?.record?.isArchived) {
        setChatArchivedMsg('Chat is archived');
        fetchNewGuestCards();
        if (isOnInboxPage) {
          const currentLeadIndex = regularLeads?.findIndex(
            ({ _id }) => _id === data?.leadUpdateById?.record?._id
          );
          handleLeadChange(regularLeads[currentLeadIndex]?._id?._id);
        }
      } else if (
        clientOptions?.variables?.record?.disablePrivacy === 'Accessed.'
      ) {
        setSnackbarMessage({
          message: 'Sensitive Info Accessed',
          type: 'success'
        });
      } else if (clientOptions?.variables?.record?.isArchived === false) {
        setChatArchivedMsg('Chat is unarchived');
        fetchNewGuestCards();
      } else {
        setSnackbarMessage({
          message: 'Lead Details updated Successfully',
          type: 'success'
        });
      }
    },
    refetchQueries: [
      { query: LeadInfoById, variables: { leadId: selectedLeadId } }
    ],
    onError(err, clientOptions) {
      if (clientOptions?.variables?.record?.isArchived) {
        setSnackbarMessage({
          message: 'Failed to archive the lead. Please try again!',
          type: 'error'
        });
        setArchiveClick(false);
      } else if (clientOptions?.variables?.record?.isArchived === false) {
        setSnackbarMessage({
          message: 'Failed to un-archive the lead. Please try again!',
          type: 'error'
        });
        setArchiveClick(false);
      } else {
        setSnackbarMessage({
          message: 'Failed to update the lead details!',
          type: 'error'
        });
      }
    }
  });

  useEffect(() => {
    if (isOnInboxPage && regularLeads?.length > 0 && !selectedLeadId) {
      handleLeadChange(regularLeads?.[0]?._id?._id);
    } else if (!isOnInboxPage && params?.id) {
      handleLeadChange(params.id);
    }
  }, [regularLeads, params]);

  useEffect(() => {
    if (selectedLeadId) {
      fetchLeadData({ variables: { leadId: selectedLeadId } });
      dispatch(fetchMetaData({ leadId: selectedLeadId }));
      dispatch(setKelseyTrigger());
    }
  }, [selectedLeadId]);

  useEffect(() => {
    if (!isStageUpdated && selectedLeadId) {
      fetchLeadData({ variables: { leadId: selectedLeadId } });
    }
  }, [isStageUpdated]); /// To be removed temporary solution

  useEffect(() => {
    if (chatArchivedMsg) {
      let showMsg = setTimeout(() => {
        setChatArchivedMsg('');
        setArchiveClick(false);
      }, 3000);
      return () => {
        clearTimeout(showMsg);
      };
    }
  }, [chatArchivedMsg, archiveClick]);

  const handleAutofillData = data => {
    const d1 = new Date(data?.avlFrom);
    const date = `${MONTHS_LIST[d1.getMonth()]} ${d1.getDate()} ${d1
      .getFullYear()
      .toString()}`;
    setMessageInput(
      prevState =>
        prevState +
        `An apartment that matches your preferences will be available on ${date} for ${
          data?.rent?.minRent ? '$' + data?.rent?.minRent : ''
        }  
       per month. Would you like me to schedule a tour for you to see it once it’s available?  `
    );
  };

  useEffect(() => {
    if (toastMessage.message || snackBarMessage.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setTimeout(() => {
      setOpen(false);
    }, 6000);
  }, [toastMessage, snackBarMessage]);

  const SOPDrawerToggleHandler = e => {
    e.preventDefault();
    setOpenSopDrawer(prevState => !prevState);
  };

  const leadContainerProps = {
    apartmentId,
    setMessageInput,
    selectedLeadId,
    leadData,
    handleAutofillData,
    propertyData: propertiesWithoutDemoUnits,
    isOnInboxPage,
    leadUpdateMutate,
    userId,
    setIsTabOpen,
    setIsCheatSheetUsed,
    openSopDrawer,
    SOPDrawerToggleHandler,
    leadDataLoading,
    isMaximizedPnAOpen,
    handlePNAExpansionToggle
  };

  const hideShowNewMessageNotification = () => {
    setShowNewMsgNotification(false);
  };

  const handleLeadChange = leadId => {
    if (selectedLeadId !== leadId) {
      socketRemover(`messages-${selectedLeadId}`);
      socketRemover(`scheduled-messages-${selectedLeadId}`);
      setSelectedLeadId(leadId);
    }
  };

  const handleOutgoingMessage = messageDetails => {
    socketEmitter('outboundMessage', {
      messageDetails,
      user: userData
    });
  };
  const handleOutgoingScheduledMessage = messageDetails => {
    socketEmitter('outboundScheduledMessage', {
      messageDetails,
      user: userData
    });
  };

  const handleNoteCreation = noteDetails => {
    socketEmitter('create-note', {
      noteDetails,
      user: userData
    });
  };

  const handleCallLogCreation = callLogDetails => {
    socketEmitter('create-call-log', {
      callLogDetails,
      user: userData
    });
  };

  const fetchNewGuestCards = () => {
    if (userId && isOnInboxPage) {
      socketEmitter('get-agents-leads', {
        agentId: userId,
        filters: { limit: 6 }
      });
    }
  };

  const updateLeadsState = leads => {
    const newLeads = _.difference(leads, regularLeads);
    if (leads?.length) {
      if (newLeads?.length) {
        setRegularLeads([...leads]);
        setMessagesLoading(false);
      }
    } else {
      setRegularLeads([]);
      setMessagesLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      socketListener(`send-agents-leads-${userId}`, data => {
        if (data?.success) {
          const leads = data?.data;
          updateLeadsState([...leads]);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (regularLeads?.length === 0) fetchNewGuestCards();

    if (regularLeads?.length < 7 && regularLeads?.length >= 0) {
      const interval = setInterval(() => fetchNewGuestCards(), 30000);

      return () => clearInterval(interval);
    }
  }, [regularLeads?.length]);

  return (
    <Layout disableActions={isTabOpen || openSopDrawer} history={history}>
      <Toaster />
      {!messagesLoading && isOnInboxPage && regularLeads.length === 0 ? (
        <div className="flex-col">
          <div className="inboxChatConatiner" style={{ margin: 'auto' }}>
            <img src={Empty} alt="Empty Gif" />
          </div>
          <div
            style={{
              textAlign: 'center',
              color: darkMode ? '#fff' : ''
            }}
            className="inter-500px-16px"
          >
            No leads are available right now
          </div>
        </div>
      ) : (
        <>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => {
              setOpen(false);
            }}
            action={'Success'}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity={toastMessage.severity || snackBarMessage?.type}>
              {toastMessage?.message || snackBarMessage?.message}
            </Alert>
          </Snackbar>

          <div className={`inboxMainContainer ${isOnInboxPage}`}>
            {isOnInboxPage ? (
              <LeadsList
                inboxMessages={regularLeads}
                userId={userId}
                selectedLeadId={selectedLeadId}
                isLeadsEmpty={isLeadsEmpty}
                onClick={handleLeadChange}
                messagesLoading={messagesLoading}
                loadMoreMessages={loadMoreMessages}
                clientsAssigned={userData?.tenantId}
              />
            ) : null}
            <InboxContextProvider>
              <Wrapper>
                <InboxChatNdHeader
                  isOnInboxPage={isOnInboxPage}
                  openSopDrawer={openSopDrawer}
                  isMaximizedPnAOpen={isMaximizedPnAOpen}
                >
                  {archiveClick && chatArchivedMsg && (
                    <ArchiveConfirmation
                      chatArchivedMsg={chatArchivedMsg}
                      archiveClick={archiveClick}
                    />
                  )}
                  <>
                    <LeadHeader
                      selectedLeadId={selectedLeadId}
                      leadData={leadData || {}}
                      leadUpdateMutate={leadUpdateMutate}
                      apartmentData={apartmentData}
                      getScheduledLeadMessages={getScheduledLeadMessages}
                    />
                    <div className="chatContainer">
                      <ChatContainer
                        {...{
                          closedReason: leadData?.closedReason || '',
                          botTurningOffReason:
                            leadData?.botTurningOffReason || '',
                          chatArchivedMsg,
                          archiveClick,
                          setMessageInput,
                          timeZone: apartmentData?.timeZone,
                          selectedLeadId,
                          showNewMsgNotification,
                          hideShowNewMessageNotification,
                          leadData
                        }}
                      />
                      <ChatInputBar
                        {...{
                          selectedLeadId,
                          messageInput,
                          setMessageInput,
                          isArchived: leadData?.isArchived || false,
                          preference: leadData?.preference || {},
                          emailOptIn: leadData?.emailOptIn,
                          textOptIn: leadData?.textOptIn,
                          leadTenantId: leadData?.tenant?._id,
                          onClick: handleLeadChange,
                          apartmentId,
                          leadUpdateMutate,
                          setArchiveClick,
                          archiveClick,
                          userId,
                          updatedLeadId,
                          messageSent,
                          handleOutgoingMessage,
                          newNoteInsertionInProgress,
                          handleNoteCreation,
                          handleCallLogCreation,
                          setIsCheatSheetUsed,
                          isCheatSheetUsed,
                          handleOutgoingScheduledMessage,
                          apartmentTimezone:
                            apartmentData?.apartmentById?.timeZone,
                          isBotActive:
                            !!leadEnabled &&
                            !!communityEnabled &&
                            !!clientEnabled
                        }}
                      />
                    </div>
                  </>
                  <NotificationPopup
                    selectedLeadId={selectedLeadId}
                    apartmentId={apartmentId}
                  />
                </InboxChatNdHeader>
                <LeadContainerInfo {...{ ...leadContainerProps }} />
              </Wrapper>
            </InboxContextProvider>
          </div>
          <SOPAndAgentNotes
            open={openSopDrawer}
            onClose={SOPDrawerToggleHandler}
            leadData={leadData}
          />
          {leadData?._id && (
            <MaximisedPnAWidget
              onClose={handlePNAExpansionToggle}
              isOpen={isMaximizedPnAOpen}
              leadUpdateMutate={leadUpdateMutate}
              selectedLeadId={leadData?._id}
              handleAutofillData={handleAutofillData}
              key={leadData?._id}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default InboxV2;
