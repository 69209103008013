import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  createNewTourNotes,
  fetchMetaData,
  getConversationSummary
} from '../../Redux/Actions/InboxV1';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';

const NotesCard = props => {
  const [notes, setNotes] = React.useState('');
  const dispatch = useDispatch();
  const conversationSummary = useSelector(
    state => state?.inboxMessages?.conversationSummary
  );
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = e => {
    setNotes(e.target.value);
  };
  React.useEffect(() => {
    if (props.NotesData) {
      setNotes(props?.NotesData?.message?.body);
    }
    dispatch(getConversationSummary({ leadId: props.ActiveId }));
  }, [props?.NotesData]);
  const date = new Date();
  const handleSaveNotes = () => {
    let o = {};
    o.leadId = props.ActiveId;
    o.message = {
      body: notes,
      name: `Appointment${props.count}`
    };
    o.tourId = props.tourId;
    o.isZumaTour = props.isZumaTour;
    o.date = date;
    if (notes != null && notes != '') {
      dispatch(createNewTourNotes({ notesPayload: o }));
    }
    dispatch(fetchMetaData({ leadId: props.ActiveId }));
    setNotes('');
    props.setOpen(false);
  };
  const handleDeleteNotes = () => {
    let o = {};
    o.leadId = props.ActiveId;
    o.message = {
      body: '',
      name: `Appointment${props.count}`
    };
    o.tourId = props.tourId;
    o.isZumaTour = props.isZumaTour;
    o.date = date;

    dispatch(createNewTourNotes({ notesPayload: o }));

    dispatch(fetchMetaData({ leadId: props.ActiveId }));
    setNotes('');
    props.setOpen(false);
  };
  const Heading = {
    font: '16px Inter',
    color: '#42526E',
    fontWeight: '600',
    marginBottom: '12px'
  };

  const BtnContainer = {
    marginTop: '20px'
  };
  const SendFeed = {
    width: '93px',
    height: '36px',
    borderRadius: '4px',
    color: '#393939'
  };
  const Cancel = {
    width: '90px',
    height: '36px',
    color: '#FFFFFF',
    borderRadius: '2px',
    backgroundColor: '#5267FF',
    fontFamily: 'Inter',
    textTransform: 'capitalize'
  };

  const noteText = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px'
  };

  return props.open ? (
    <div className="manual-popup customModalCont">
      <div className="popup-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={Heading}>Appointment {props.count}</div>
              <CloseIcon
                sx={{ height: '20px', marginLeft: '13rem' }}
                onClick={() => props.setOpen(false)}
              />
            </div>
            <label>Note</label>
          </div>
        </div>
        <TextField
          id="outlined-textarea"
          multiline
          variant="outlined"
          rows={5}
          fullWidth
          defaultValue={
            conversationSummary?.summary ? conversationSummary?.summary : notes
          }
          onChange={handleChange}
        />

        <div style={BtnContainer}>
          <Button
            variant="contained"
            className={notes ? 'save-btn' : 'save-btn disable-btn'}
            onClick={() => handleSaveNotes()}
          >
            Save
          </Button>
          <Button
            variant="text"
            style={SendFeed}
            onClick={() => handleDeleteNotes()}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default NotesCard;
