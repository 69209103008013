import React from 'react';

const ClientDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <path
        d="M5.21997 48.9133H52.8363"
        stroke="white"
        stroke-width="2.53578"
        stroke-linecap="round"
      />
      <path
        d="M21.1703 47.178V21.2878C21.1703 21.0803 21.3385 20.9121 21.546 20.9121H37.0264C37.2339 20.9121 37.4021 21.0803 37.4021 21.2878V38.8292"
        stroke="white"
        stroke-width="1.78444"
        stroke-linecap="round"
      />
      <path
        d="M26.0492 27.5254H32.6639"
        stroke="white"
        stroke-width="2.53578"
        stroke-linecap="round"
      />
      <path
        d="M25.9554 34.7485H32.5701"
        stroke="white"
        stroke-width="2.53578"
        stroke-linecap="round"
      />
      <path
        d="M25.9554 41.6902H32.5701"
        stroke="white"
        stroke-width="2.53578"
        stroke-linecap="round"
      />
      <path
        d="M37.4021 43.2849L37.4021 42.5345"
        stroke="white"
        stroke-width="1.78444"
        stroke-linecap="round"
      />
      <path
        d="M39.9353 47.9284V34.2436C39.9353 34.1339 40.0242 34.045 40.1339 34.045H49.9034C50.013 34.045 50.1019 34.1339 50.1019 34.2436V43.5155"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M43.3572 37.5408H46.8535"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M43.3076 41.3586H46.804"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M43.3076 45.0278H46.804"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M50.1019 45.8707L50.1019 45.4741"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M8.50391 47.1779V33.4931C8.50391 33.3835 8.59281 33.2946 8.70248 33.2946H18.472C18.5816 33.2946 18.6705 33.3835 18.6705 33.4931V42.765"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M11.9258 36.7902H15.4221"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M11.8762 40.6082H15.3726"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M11.8762 44.2772H15.3726"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M18.6705 45.1202L18.6705 44.7236"
        stroke="white"
        stroke-width="1.34034"
        stroke-linecap="round"
      />
      <path
        d="M29.9384 11.3781L30.6296 13.5049C30.7931 14.0079 31.2618 14.3484 31.7907 14.3484H34.0271C34.0609 14.3484 34.0757 14.3575 34.0839 14.3638C34.0955 14.3728 34.1087 14.3893 34.1165 14.4133C34.1243 14.4374 34.1233 14.4585 34.1192 14.4725C34.1163 14.4824 34.1096 14.4985 34.0823 14.5184L32.2733 15.8324C31.8453 16.1433 31.6662 16.6945 31.8297 17.1976L32.5207 19.324C32.5312 19.3561 32.5271 19.3731 32.5236 19.3828C32.5187 19.3965 32.507 19.4142 32.4866 19.429C32.4662 19.4439 32.4458 19.4495 32.4312 19.4499C32.4209 19.4502 32.4035 19.4489 32.3762 19.4291L30.5667 18.1146C30.1388 17.8038 29.5594 17.8038 29.1316 18.1146L27.322 19.4291C27.2947 19.4489 27.2773 19.4502 27.267 19.4499C27.2524 19.4495 27.232 19.4439 27.2116 19.429C27.1912 19.4142 27.1795 19.3965 27.1746 19.3828C27.1711 19.3731 27.1671 19.3561 27.1775 19.324L27.8685 17.1976C28.032 16.6945 27.8529 16.1433 27.4249 15.8324L25.6159 14.5184C25.5886 14.4985 25.5819 14.4824 25.579 14.4725C25.5749 14.4585 25.5739 14.4374 25.5817 14.4133C25.5895 14.3893 25.6028 14.3728 25.6143 14.3638C25.6225 14.3575 25.6373 14.3484 25.6711 14.3484H27.9075C28.4364 14.3484 28.9051 14.0079 29.0686 13.5049L29.7598 11.3781C29.7702 11.346 29.7835 11.3347 29.792 11.3289C29.8041 11.3207 29.8239 11.3132 29.8491 11.3132C29.8743 11.3132 29.8941 11.3207 29.9062 11.3289C29.9148 11.3347 29.928 11.346 29.9384 11.3781Z"
        stroke="white"
        stroke-width="1.12701"
      />
    </svg>
  );
};

export default ClientDark;
