import React from 'react';

const GreenTick = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01668 11.033L11.7333 6.31634L10.9667 5.56634L7.01668 9.51634L5.01668 7.51634L4.26668 8.26634L7.01668 11.033ZM8.00001 14.6663C7.05557 14.6663 6.17779 14.4969 5.36668 14.158C4.55557 13.8191 3.85001 13.3497 3.25001 12.7497C2.65001 12.1497 2.18057 11.4441 1.84168 10.633C1.50279 9.8219 1.33334 8.94412 1.33334 7.99967C1.33334 7.06634 1.50279 6.19412 1.84168 5.38301C2.18057 4.5719 2.65001 3.86634 3.25001 3.26634C3.85001 2.66634 4.55557 2.19412 5.36668 1.84967C6.17779 1.50523 7.05557 1.33301 8.00001 1.33301C8.93334 1.33301 9.80557 1.50523 10.6167 1.84967C11.4278 2.19412 12.1333 2.66634 12.7333 3.26634C13.3333 3.86634 13.8056 4.5719 14.15 5.38301C14.4945 6.19412 14.6667 7.06634 14.6667 7.99967C14.6667 8.94412 14.4945 9.8219 14.15 10.633C13.8056 11.4441 13.3333 12.1497 12.7333 12.7497C12.1333 13.3497 11.4278 13.8191 10.6167 14.158C9.80557 14.4969 8.93334 14.6663 8.00001 14.6663ZM8.00001 13.6663C9.61112 13.6663 10.9583 13.1247 12.0417 12.0413C13.125 10.958 13.6667 9.61079 13.6667 7.99967C13.6667 6.38856 13.125 5.04134 12.0417 3.95801C10.9583 2.87467 9.61112 2.33301 8.00001 2.33301C6.3889 2.33301 5.04168 2.87467 3.95834 3.95801C2.87501 5.04134 2.33334 6.38856 2.33334 7.99967C2.33334 9.61079 2.87501 10.958 3.95834 12.0413C5.04168 13.1247 6.3889 13.6663 8.00001 13.6663Z"
        fill="#67C848"
      />
    </svg>
  );
};

export default GreenTick;
