import { gql } from '@apollo/client';

export const apartmentByTenant = gql`
  query apartmentByTenantId($tenantId: ID!) {
    apartmentByTenantId(tenantId: $tenantId) {
      _id
      name
      meta {
        knockCommunity
      }
      addressId
      phone
      isLive
    }
  }
`;

export const getApartmentsByTenantIds = gql`
  query apartmentsByTenantIds($tenantIds: [ID]!) {
    apartmentsByTenantIds(tenantIds: $tenantIds) {
      name
      _id
    }
  }
`;
