import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { InputAdornment, TextField } from '@mui/material';
import Drawer from '../../../globalcomponents/Drawer';
import DrawerHeader from '../../../globalcomponents/Drawer/DrawerHeader';
import DrawerBody from '../../../globalcomponents/Drawer/DrawerBody';
import Card from '../../../globalcomponents/Card';
import Badge from '../../../globalcomponents/Badge';
import DrawerFooter from '../../../globalcomponents/Drawer/DrawerFooter';
import Tabs from '../../../globalcomponents/Tabs';
import Tab from '../../../globalcomponents/Tabs/Tab';
import AgentNotes from './AgentNotes';
import { getAllSopAndAnnouncements } from '../../../gql/queries/sopAndAnnouncements';
import { getUserNotes } from '../../../gql/queries/userData';
import {
  BadgeHolder,
  Loader,
  SOPCardHeader,
  SOPInstructions,
  SOPListContainer
} from './sop.styles';
import OfficeBuilding from '../../../Media/InboxV1/OfficeBuilding';
import Client from '../../../Media/InboxV1/Client';
import Announcement from '../../../Media/InboxV1/Announcement';
import SearchIcon from '../../../Media/InboxV1/SearchIcon';
import { getDateWithoutYear } from '../../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from '../inboxUtils';
import { useTheme } from 'styled-components';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import OfficeBuildingDark from '../../../Media/InboxV1/OfficeBuildingDark';
import AnnouncementDark from '../../../Media/InboxV1/AnnouncementDark';
import ClientDark from '../../../Media/InboxV1/ClientDark';

const SOPAndAgentNotes = ({ open, onClose, leadData }) => {
  const [activeTab, setActiveTab] = useState('SOP');
  const [userNotes, setUserNotes] = useState();
  const [sopsDisplayed, setSopsDisplayed] = useState([]);
  const [page, setPage] = useState(0);
  const [searchWord, setSearchWord] = useState('');
  const { darkMode } = useContext(GlobalContext);
  const theme = useTheme();
  const updateUserNotes = data => {
    setUserNotes(data);
  };

  const [
    fetchSopAndAnnouncements,
    { data: { getAllSopAndAnnouncement } = {}, loading: sopLoading }
  ] = useLazyQuery(getAllSopAndAnnouncements, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        tenants: [leadData?.tenant?._id],
        apartments: [leadData?.apartment?._id],
        isImportant: true,
        searchWord
      },
      page: page,
      limit: 4
    },
    onCompleted: ({ getAllSopAndAnnouncement }) => {
      setSopsDisplayed(prevState =>
        prevState.concat(getAllSopAndAnnouncement?.data)
      );
    }
  });

  const loadMoreSops = () => {
    setPage(prevPage => prevPage + 1);
    fetchSopAndAnnouncements();
  };

  const [fetchUserNotes, { loading: notesLoading }] = useLazyQuery(
    getUserNotes,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getUser }) => {
        setUserNotes(getUser?.notes);
      }
    }
  );

  const handleSearch = e => {
    const searchTerm = e.target.value.toLowerCase();
    debounce(() => {
      setSearchWord(searchTerm);
      setSopsDisplayed([]);
      setPage(0);
      fetchSopAndAnnouncements();
    }, 1500)();
  };

  useEffect(() => {
    if (open) {
      setActiveTab('SOP');
      setSopsDisplayed([]);
      setPage(0);
      fetchSopAndAnnouncements();
      fetchUserNotes();
    }
  }, [open]);

  useEffect(() => {
    if (activeTab == 'Notes') {
      fetchUserNotes();
    }
  }, [activeTab]);

  return (
    <Drawer
      open={open}
      width={'450px'}
      borderRadius={'8px'}
      height={'88vh'}
      top={'66px'}
      onClose={onClose}
    >
      <DrawerHeader
        background={theme?.background?.selection}
        width="100%"
        padding="0 15px"
      >
        <Tabs background={theme?.background?.selection}>
          <Tab
            active={activeTab === 'SOP' ? true : false}
            onClick={e => setActiveTab('SOP')}
            fontSize={'16px'}
            color={theme?.text?.secondary}
            indicatorColor={theme?.background?.secondary}
          >
            SOPs
          </Tab>
          <Tab
            active={activeTab === 'Notes' ? true : false}
            onClick={e => setActiveTab('Notes')}
            fontSize={'16px'}
            color={theme?.text?.secondary}
            indicatorColor={theme?.background?.secondary}
          >
            Notes
          </Tab>
        </Tabs>
      </DrawerHeader>
      {activeTab === 'SOP' && (
        <DrawerBody background={'#F8F8F8'} padding={'10px'}>
          <>
            <TextField
              onChange={handleSearch}
              placeholder="Search SOPs for this tenant / community"
              variant="filled"
              fullWidth
              style={{
                position: 'sticky',
                top: 0
              }}
              InputProps={{
                type: 'search',
                style: {
                  borderRadius: '4px',
                  padding: '10px !important',
                  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.04)',
                  backgroundColor: theme.background.secondary,
                  color: theme.text.placeholder
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    style={{ paddingRight: '10px', display: 'flex' }}
                    position="start"
                  >
                    <SearchIcon
                      style={{
                        position: 'absolute',
                        top: '10px'
                      }}
                    />
                  </InputAdornment>
                )
              }}
              inputProps={{
                style: {
                  padding: '10px 10px',
                  fontSize: '12px',
                  fontFamily: 'Inter, sans-serif'
                }
              }}
            />
            <SOPListContainer id="scrollableSopContainer">
              <InfiniteScroll
                dataLength={sopsDisplayed?.length || 0}
                next={loadMoreSops}
                hasMore={
                  getAllSopAndAnnouncement?.total >
                  getAllSopAndAnnouncement?.end
                }
                loader={
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif'
                    }}
                  >
                    Loading...
                  </p>
                }
                endMessage={
                  getAllSopAndAnnouncement?.total === 0 ? (
                    <p
                      style={{
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif'
                      }}
                    >
                      Nothing to show here.
                    </p>
                  ) : null
                }
                scrollableTarget="scrollableSopContainer"
              >
                {sopsDisplayed?.map(item => {
                  const htmlContent = { __html: item.message };
                  const allClients = item?.isForAllClients;
                  const allCommunities = item?.isForAllCommunities;
                  return (
                    <Card
                      key={`sop-card-${item._id}`}
                      id={`sop-card-${item._id}`}
                      borderRadius={'4px'}
                      background={`${theme?.text?.label}08`}
                      padding={'10px'}
                      margin={'0 0 10px'}
                    >
                      <SOPCardHeader>
                        <BadgeHolder>
                          <Badge
                            background={
                              item?.isImportant ? '#E2445C' : '#C4C4C44D'
                            }
                            color={item?.isImportant ? '#FFFFFF' : false}
                            borderRadius={'36px'}
                            maxWidth={'200px'}
                            items={[item?.name]}
                          >
                            {item?.name}
                          </Badge>
                          <Badge
                            background={'#C4C4C44D'}
                            borderRadius={'36px'}
                            endAdornment={
                              darkMode ? <ClientDark /> : <Client />
                            }
                            items={
                              allClients
                                ? ['All tenants']
                                : item?.tenants?.map(tenant => tenant.name)
                            }
                          >
                            {allClients ? 'All' : item?.tenants?.length}
                          </Badge>
                          <Badge
                            background={'#C4C4C44D'}
                            borderRadius={'36px'}
                            endAdornment={
                              darkMode ? (
                                <OfficeBuildingDark />
                              ) : (
                                <OfficeBuilding />
                              )
                            }
                            items={
                              allCommunities
                                ? ['All communities']
                                : item?.apartments?.map(apt => apt.name)
                            }
                          >
                            {allCommunities ? 'All' : item?.apartments?.length}
                          </Badge>
                          {item.category === 'announcement' && (
                            <Badge
                              endAdornment={
                                darkMode ? (
                                  <AnnouncementDark />
                                ) : (
                                  <Announcement />
                                )
                              }
                            />
                          )}
                        </BadgeHolder>
                        <Badge>{getDateWithoutYear(item.createdAt)}</Badge>
                      </SOPCardHeader>
                      <SOPInstructions dangerouslySetInnerHTML={htmlContent} />
                    </Card>
                  );
                })}
              </InfiniteScroll>
            </SOPListContainer>
          </>
        </DrawerBody>
      )}
      {activeTab === 'Notes' && (
        <DrawerBody>
          {notesLoading ? (
            <Loader>Loading..</Loader>
          ) : (
            <AgentNotes
              userNotes={userNotes}
              darkMode={darkMode}
              updateNotesHandler={updateUserNotes}
            />
          )}
        </DrawerBody>
      )}
      {activeTab === 'Notes' && (
        <DrawerFooter>
          <div className="notes-footer">*This is an autosave feature</div>
        </DrawerFooter>
      )}
    </Drawer>
  );
};

export default SOPAndAgentNotes;
