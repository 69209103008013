export const TEMPLATE_CONSTANTS = [
  {
    name: 'First Name',
    value: 'firstName'
  },
  {
    name: 'Property Name',
    value: 'propertyName'
  },
  {
    name: 'Community Website Link',
    value: 'communityWebsiteLink'
  },
  {
    name: 'Community Address',
    value: 'communityAddress'
  }
];
