import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  //   align-items: center;
  flex-direction: column;
`;

const Progress = styled.div`
  height: ${props =>
    props.height ? (parseInt(props.height[0]) - 1).toString() + 'px' : '3px'};
  width: ${props => (props.completed / props.total) * 100}%;
  background-color: ${props => props.theme?.background?.primaryBadge};
  border-radius: 5px;
`;
const ProgressContainer = styled.div`
  height: ${props => props.height ?? '4px'};
  width: 100%;
  background-color: ${props => props.theme?.border?.primary};
  border-radius: 5px;
`;
const Status = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme?.text?.primary};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
export { Progress, ProgressBarContainer, Status, ProgressContainer };
