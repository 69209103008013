import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appointmentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '10px',
    boxShadow: '0px 4px 10px rgb(0 0 0 / 4%)',
    border: '0.5px solid rgba(82, 103, 255, 0.5)',
    borderRadius: '4px',
    marginBottom: '10px'
  },
  appointmentContainer2: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: `${props.background.selection}1A`,
    padding: '10px',
    boxShadow: '0px 4px 10px rgb(0 0 0 / 4%)',
    border: '0.5px solid rgba(82, 103, 255, 0.5)',
    borderRadius: '4px',
    marginBottom: '10px'
  }),
  appointmentTitle: props => ({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.heading,
    fontFamily: 'Inter',
    whiteSpace: 'nowrap'
  }),
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editIcon: {
    marginRight: '4px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82, 103, 255, 0.1)',
    borderRadius: '50%'
  },
  deleteIcon: {
    cursor: 'pointer'
  },
  notesIcon: {
    cursor: 'pointer'
  },
  timeAndDateText: props => ({
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: props.text.primary,
    margin: '10px 0'
  }),
  timeStamp: props => ({
    color: `${props.text.primary}B3`
  }),
  subTitle: props => ({
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    color: `${props.text.primary}E6`
  }),
  subDesc: props => ({
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: props.text.primary
  }),
  appointmentContainerGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '20px'
  },
  subContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    margin: '5px 0',
    justifyContent: 'space-between'
  },
  platformContainer: {
    width: '72px',
    height: '22px',
    backgroundColor: 'rgba(82, 103, 255, 0.1)',
    borderRadius: '4px',
    marginLeft: '5px',
    marginTop: '-4px'
  },
  platformText: props => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    color: props.text.tag,
    paddingTop: '5px',
    paddingLeft: '4px'
  })
}));
