import React, { useContext, useEffect, useState } from 'react';
import TextEditor from './TextEditor';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { postKelsey } from '../../../Redux/networkClient';
import { Path } from '../../../Redux/util/path';
import './chat-styles.scss';
import SmsEmailOption from '../SmsEmailOption.js';

const ChatInputBar = props => {
  const {
    selectedLeadId,
    messageInput,
    setMessageInput,
    isArchived,
    onClick,
    preference,
    apartmentId,
    leadUpdateMutate,
    setArchiveClick,
    archiveClick,
    userId,
    updatedLeadId,
    messageSent,
    handleOutgoingMessage,
    newNoteInsertionInProgress,
    handleNoteCreation,
    handleCallLogCreation,
    textOptIn,
    emailOptIn,
    updateHeight,
    leadTenantId,
    setIsCheatSheetUsed,
    isCheatSheetUsed,
    handleOutgoingScheduledMessage,
    isBotActive,
    apartmentTimezone

    // disabled,
  } = props;
  const { userData } = useContext(GlobalContext);

  const [medium, setMedium] = useState('sms');
  const [openMessageSendingLoader, setOpenMessageSendingLoader] =
    useState(false);
  const [appear, setAppear] = useState(false);

  const [messageStatus, setMessageStatus] = useState(false);
  const tenantId = userData.tenantId;

  // useEffect(() => {
  //   const lastMessage = leadMessage[leadMessage.length - 1];
  //   if (lastMessage?.md === 'email') {
  //     setMedium('email');
  //   } else {
  //     setMedium('sms');
  //   }
  // }, [leadMessage]);

  const updateCheatSheetData = async message => {
    if (isCheatSheetUsed) {
      const body = {
        agent_response: message,
        _id: isCheatSheetUsed
      };
      await postKelsey(`${Path.KELSEY_CHEATSHEET_UPDATE}`, body);

      setIsCheatSheetUsed(false);
    }
  };

  return (
    <>
      <div className="chatInputBox">
        <div className="more-chat-options">
          <div style={{ display: 'flex' }}>
            {openMessageSendingLoader && messageStatus ? (
              <div>Loading...</div>
            ) : !messageStatus && messageSent === 'failed' && appear ? (
              <div>Try again after some time</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex-align-center">
          <div
            style={{
              width: '100%'
            }}
          >
            <TextEditor
              custStyle={{
                width: '100%',
                maxHeight: '400px',
                marginTop: '55px',
                borderRadius: '4px'
              }}
              updateHeight={updateHeight}
              smsEmailOption={
                <SmsEmailOption {...{ medium, setMedium, setMessageInput }} />
              }
              {...{
                selectedLeadId,
                messageInput,
                setMessageInput,
                isArchived,
                onClick,
                preference,
                apartmentId,
                leadUpdateMutate,
                setArchiveClick,
                archiveClick,
                userId,
                updatedLeadId,
                messageSent,
                handleOutgoingMessage,
                newNoteInsertionInProgress,
                handleNoteCreation,
                handleCallLogCreation,
                textOptIn,
                emailOptIn,
                updateHeight,
                leadTenantId,
                setIsCheatSheetUsed,
                isCheatSheetUsed,
                isBotActive,
                tenantId,
                medium,
                setMedium,
                updateCheatSheetData,
                appear,
                setAppear,
                setOpenMessageSendingLoader,
                messageStatus,
                setMessageStatus,
                handleOutgoingScheduledMessage,
                apartmentTimezone
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatInputBar;
