import { AGENT_CLIENTS, AGENT_ERROR, UPDATE_AGENT_PROFILE } from '../Constant';
import { get, patch, put } from '../networkClient';

const AgentClients = data => ({
  type: AGENT_CLIENTS,
  data: data
});

const AgentError = data => ({
  type: AGENT_ERROR,
  data: data
});
const UpdateAgentProfile = data => ({
  type: UPDATE_AGENT_PROFILE,
  data: data
});

export const AgentClientsAction = (data, props) => async dispatch => {
  await get('/agent/agentClients')
    .then(res => {
      if (res.status === 200) {
        dispatch(AgentClients(res.data.data));
      }
    })
    .catch(err => dispatch(AgentError(true)));
};

export const UpdateAgentProfileAction = data => async dispatch => {
  await put('/management/user', data)
    .then(res => {
      if (res.status === 200) {
        dispatch(UpdateAgentProfile(res.data.data));
      }
    })
    .catch(err => dispatch(AgentError(true)));
};

export const changePassword = data => async dispatch => {
  await patch('/management/user/change_pwd', data)
    .then(res => {
      if (res.status === 200) {
        return alert('Password changed successfully');
      }
    })
    .catch(err => dispatch(AgentError(true)));
};
