import {
  ADD_LEAD_LOADER,
  BACKDROP_STATUS,
  FILTER_STATE,
  GET_ALL_LEAD_MSG,
  GET_INBOX_META,
  GET_INBOX_MSG,
  POST_NEW_LEADS_MSG
} from '../Constant';
import { get, post } from '../networkClient';

const getInboxMsg = data => ({
  type: GET_INBOX_MSG,
  data: data
});
const getAllLeadMsg = data => ({
  type: GET_ALL_LEAD_MSG,
  data: data
});

export const AddLeadLoader = data => ({
  type: ADD_LEAD_LOADER,
  data: data
});

const getInboxMetaAction = data => ({
  type: GET_INBOX_META,
  data: data
});

export const filterState = data => ({
  type: FILTER_STATE,
  data: data
});

const backdropStatus = data => ({
  type: BACKDROP_STATUS,
  data: data
});

const newLeadsMsg = data => ({
  type: POST_NEW_LEADS_MSG,
  data: data
});

export const GetInboxMsgAction = (val, endval) => async dispatch => {
  try {
    await get('/message/inbox', { start: val, end: endval }).then(resp => {
      if (resp.status === 200) {
        if (resp.data.data.length > 0) {
          dispatch(getInboxMsg(resp.data));
          dispatch(AddLeadLoader(false));
        } else {
          alert('No more leads available');
        }
      }
    });
  } catch (error) {
    dispatch(AddLeadLoader(false));
  }
};

export const GetLeadMsgAction = id => async dispatch => {
  await get('/message/all', { id: id }).then(resp => {
    dispatch(getAllLeadMsg(resp.data));
  });
};

export const CreateNewMsgAction = data => async dispatch => {
  dispatch(backdropStatus(true));
  dispatch(newLeadsMsg(''));
  try {
    await post('/message/send', data).then(resp => {
      if (resp.status === 200) {
        dispatch(GetInboxMsgAction());
        dispatch(backdropStatus(false));
      }
    });
  } catch (error) {
    dispatch(GetInboxMsgAction());
    dispatch(backdropStatus(false));
  }
};

export const getInboxMeta = id => async dispatch => {
  await get(`/inboxmeta/${id}`).then(resp => {
    if (resp.status === 200) {
      dispatch(getInboxMetaAction(resp));
    }
  });
};
