import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropertyAdd from './propertyAdd';
import { PropertyByIdAction } from '../../Redux/Actions/Property';

const PropertyEdit = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PropertyByIdAction(props.match.params.id));
  }, [props.match.params.id]);
  return (
    <div>
      <PropertyAdd
        propertyType={'EDIT'}
        history={props.history}
        PropertyObj={props.PropertyObj}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  PropertyObj: state.Property.PropertyById
});
export default connect(mapStateToProps)(PropertyEdit);
