import React, { useState } from 'react';
import { Card, FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateAgentDetails } from '../../../Redux/Actions/InboxV1';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import Button from '../../../globalcomponents/Button';
import { useTheme } from 'styled-components';
import { updateLeadAgentByLeadId } from '../../../gql/mutations/updateLeadAgentByLeadId';
import { useMutation } from '@apollo/client';
import {
  setGlobalError,
  setSuccessInfo
} from '../../../Redux/Reducer/GlobalError';
import { LeadInfoById } from '../../../gql/queries/leadInfo';

const OwnerTransfer = props => {
  const {
    openReason,
    setOpenReason,
    reasonList,
    selectedLeadId,
    leasingAgent
  } = props;
  const [selectedAgent, setSelectedAgent] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleSelect = e => {
    setSelectedAgent(reasonList.find(({ name }) => name === e.target.value));
  };

  const [updateAgentByLeadId] = useMutation(updateLeadAgentByLeadId, {
    onCompleted: ({ updateLeadAgentByLeadId }) => {
      if (updateLeadAgentByLeadId?.data)
        dispatch(setSuccessInfo('Lead transferred successfully!'));
      if (updateLeadAgentByLeadId?.error)
        dispatch(setGlobalError(updateLeadAgentByLeadId?.error));
    },
    refetchQueries: [
      {
        query: LeadInfoById,
        variables: { leadId: selectedLeadId }
      }
    ]
  });

  const Save = () => {
    if (selectedAgent?.name) {
      updateAgentByLeadId({
        variables: {
          agent: selectedAgent?.name,
          leadId: selectedLeadId
        }
      });
      setOpenReason(false);
    }
  };
  return (
    <>
      {openReason && (
        <Popup width={'400px'}>
          <PopupTitle showCloseBtn onClose={() => setOpenReason(false)}>
            Transfer Lead
          </PopupTitle>
          <PopupBody>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: '10px',
                  color: theme?.text?.primary
                }}
              >
                <label className="customPopUpDropDownLabel">
                  Select lead owner
                </label>
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 325
                  }}
                >
                  <Select
                    value={selectedAgent?.name || leasingAgent?.name || ''}
                    displayEmpty
                    onChange={e => handleSelect(e)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      border: `1px solid ${theme?.border?.primary}`,
                      color: theme?.text?.primary,
                      '& .MuiOutlinedInput-input.MuiSelect-select': {
                        color: theme?.text?.primary
                      }
                    }}
                  >
                    {reasonList?.map(a => {
                      return (
                        <MenuItem
                          className="customPopUpdropdownMenu"
                          key={a.name}
                          value={a.name}
                        >
                          {a.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: '10px',
                  color: theme?.text?.primary
                }}
              >
                <label className="customPopUpDropDownLabel">
                  Reason for transfer
                </label>
                <div>
                  <textarea
                    id="outlined-multiline-static"
                    multiline
                    placeholder="Please describe the reason for transfer"
                    style={{
                      width: '100%',
                      border: `1px solid ${theme?.border?.primary}`,
                      borderRadius: '4px',
                      fontFamily: 'Inter, sans-serif',
                      background: theme?.background?.secondary,
                      color: theme?.text?.primary
                    }}
                  />
                </div>
              </div>
            </div>
          </PopupBody>
          <PopupFooter padding="10px 20px">
            <Button onClick={() => Save()} btnType="primary">
              Save
            </Button>
            <Button noStyle onClick={e => setOpenReason(false)}>
              Clear
            </Button>
          </PopupFooter>
        </Popup>
      )}
    </>
  );
};

export default OwnerTransfer;
