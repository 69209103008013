import styled from 'styled-components';

const WarningText = styled.div`
  color: #d83a52;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export { WarningText };
