import React, { useContext, useEffect, useState } from 'react';
import CheatQuestions from '../../Component/CheatQuestions';
import LeadInfoOptions from '../LeadInfoOptions';
import AppointmentSchedule from '../AppointmentSchedule';
import LeadAISettings from '../LeadAISettings';

import LeadDetails from '../LeadDetails';
import AFMSettings from '../AFMSettings';
import { apartmentByTenant } from '../../gql/queries/apartmentByTenant';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import KelseyInsight from '../../Container/KelseyInsight';
import LeadWaitList from '../LeadWaitList';
import Popup from '../../globalcomponents/Popup';
import { useInboxContext } from '../../utils/ContextProviders/InboxContextProvider';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import LeadInfoLoader from '../../globalcomponents/SkeletonLoaders/LeadInfoLoader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSessionData } from '../../Redux/Actions/InboxV1';
import KelseyFeedback from '../KelseyFeedback';
import { getUnansweredQueriesByLeadId } from '../../gql/queries/unansweredLeadQueries';
import { markLeadQueryAsAnswered } from '../../gql/mutations/updateUnansweredLeadQueries';
import {
  setGlobalError,
  setSuccessInfo
} from '../../Redux/Reducer/GlobalError';
import { socketEmitter } from '../../utils/socket';

const LeadContainerInfo = props => {
  const dispatch = useDispatch();
  const { showAnnouncementsModal } = useInboxContext();
  const { darkMode, userData } = useContext(GlobalContext);
  const { sessionDataPending, inboxMetaData: inboxMetaSchedule } = useSelector(
    state => state.inboxMessages
  );

  const {
    setMessageInput,
    selectedLeadId,
    leadData,
    refetchLeadDetails,
    propertyData,
    handleAutofillData,
    isOnInboxPage,
    leadUpdateMutate,
    userId,
    setIsTabOpen,
    setIsCheatSheetUsed,
    openSopDrawer,
    SOPDrawerToggleHandler,
    handlePNAExpansionToggle,
    isMaximizedPnAOpen
  } = props;

  const [selectedOption, setSelectedOptions] = useState('All');
  const [gettingLeadData, setGettingLeadData] = useState(true);

  const clientName = leadData?.tenant?.name;
  const tenantId = leadData?.tenant?._id;
  const tenantName = leadData?.name;
  const apartmentId = leadData?.apartment?._id;

  const {
    loading,
    error,
    data: leadQuestions
  } = useQuery(getUnansweredQueriesByLeadId, {
    variables: {
      leadId: leadData?._id
    }
  });

  const [markQueryAsAnsweredById] = useMutation(markLeadQueryAsAnswered, {
    onCompleted: ({ markLeadMissedQueryAsAnswered }, clientOptions) => {
      if (markLeadMissedQueryAsAnswered)
        dispatch(setSuccessInfo(`Successfully marked question as answered!`));
      socketEmitter('getLeadMessages', {
        leadId: selectedLeadId,
        user: userData
      });
    },
    onError: error => {
      dispatch(setGlobalError('Could not mark question as answered!'));
    },
    refetchQueries: [
      {
        query: getUnansweredQueriesByLeadId,
        variables: { leadId: selectedLeadId }
      }
    ]
  });

  const queryAnsweredClickHandler = (e, queryId) => {
    e.preventDefault();
    markQueryAsAnsweredById({
      variables: {
        queryId: queryId,
        agentName: userData?.name,
        agentId: userData?.user_id
      }
    });
  };

  useEffect(() => {
    if (leadData?._id) {
      dispatch(fetchSessionData({ leadId: leadData?._id }));
    }
  }, [leadData?._id]);

  const [
    fetchApartmentData,
    { data: { apartmentByTenantId } = {}, loading: apartmentDataLoading }
  ] = useLazyQuery(apartmentByTenant, {
    variables: { tenantId },
    skip: !tenantId
  });
  const apartmentData = apartmentByTenantId || [];
  useEffect(() => {
    if (tenantId) {
      setSelectedOptions('All');
      fetchApartmentData();
    }
  }, [tenantId]);

  useEffect(() => {
    if (sessionDataPending || apartmentDataLoading) {
      setGettingLeadData(true);
    } else {
      setGettingLeadData(false);
    }
  }, [sessionDataPending, apartmentDataLoading]);

  return (
    <div
      className={`leadContainerBox${darkMode ? '-dark' : ''}  ${
        isOnInboxPage ? '' : 'leadContainerBoxLead'
      }`}
      style={{
        position: showAnnouncementsModal ? 'relative' : '',
        overflow: showAnnouncementsModal && 'hidden'
      }}
    >
      {gettingLeadData ? (
        <LeadInfoLoader></LeadInfoLoader>
      ) : (
        <>
          {showAnnouncementsModal && (
            <Popup
              width="0px"
              overlayRadius={'6px'}
              overlayColor="rgba(0, 0, 0, 0.25)"
              overlayPosition="absolute"
            />
          )}
          <div className={!showAnnouncementsModal && 'position-sticky'}>
            <LeadInfoOptions
              {...{
                selectedOption,
                setSelectedOptions,
                darkMode,
                isKelseyQueryListFilled:
                  leadQuestions?.getLeadsUnAnsweredQueries?.length > 0
                    ? true
                    : false
              }}
            />
          </div>
          {selectedOption === 'All' ? (
            <KelseyInsight
              {...{
                tenantName,
                clientName,
                userId,
                leadData,
                propertyData,
                apartments: apartmentData,
                leadUpdateMutate,
                handleAutofillData,
                setIsTabOpen,
                enableBot: leadData?.enableBot || false,
                aiBotSettings: inboxMetaSchedule?.ai,
                openSopDrawer,
                SOPDrawerToggleHandler,
                handlePNAExpansionToggle,
                isMaximizedPnAOpen
              }}
            />
          ) : null}
          {selectedOption == 'All' || selectedOption == 'unit' ? (
            <AppointmentSchedule
              leadData={leadData}
              selectedLeadId={selectedLeadId}
              apartmentData={apartmentData}
              propertyData={propertyData}
              inboxMetaSchedule={inboxMetaSchedule}
              selectedOption={selectedOption}
            />
          ) : null}
          {selectedOption == 'All' ? (
            <LeadAISettings
              {...{
                selectedLeadId,
                enableBot: leadData?.enableBot || false,
                aiData: inboxMetaSchedule?.ai,
                leadUpdateMutate,
                leadData
              }}
            />
          ) : null}
          {selectedOption == 'All' ? (
            <AFMSettings
              {...{
                selectedLeadId,
                followupToggle: leadData?.followupToggle || false,
                leadUpdateMutate
              }}
            />
          ) : null}
          {selectedOption == 'All' ? (
            <LeadWaitList
              {...{
                leadId: selectedLeadId,
                waitListExists: leadData?.waitlist ?? []
              }}
            />
          ) : null}
          {selectedOption == 'card' ? (
            <LeadDetails
              {...{
                selectedLeadId,
                leadData: leadData || {},
                phoneType: leadData?.meta?.phoneType || null,
                firstContactType: leadData?.meta?.firstContactType || null,
                apartmentData,
                propertyData,
                leadUpdateMutate,
                refetchLeadDetails
              }}
            />
          ) : null}
          {selectedOption == 'cheatSheet' ? (
            <CheatQuestions
              {...{
                setMessageInput,
                apartmentId,
                apartmentData,
                leadId: leadData?._id,
                setIsCheatSheetUsed
              }}
            />
          ) : null}
          {selectedOption == 'kelseyFeedback' ? (
            <KelseyFeedback
              unansweredLeadQueries={leadQuestions?.getLeadsUnAnsweredQueries}
              queryAnsweredClickHandler={queryAnsweredClickHandler}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default React.memo(LeadContainerInfo);
