import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import NewCampaignModal from './NewCampaignModal';
import {
  deleteCampaignAction,
  getCampaignAction
} from '../../../Redux/Actions/workflow';
import moment from 'moment';
import EditSequence from './EditSequence';

const StTable = props => {
  const dispatch = useDispatch();
  const [openBellMod, setOpenBellMod] = useState(false);
  const [openEditMod, setOpenEditMod] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [valid, setvalid] = useState(false);
  const selectedRow = React.useRef([]);
  const [selectedData, setselectedData] = useState();
  const [Check, setCheck] = useState(false);
  const [rowId, setrowId] = useState('');

  const handleAdd = e => {
    setOpenBellMod(true);
    setvalid(false);
  };

  const handleDelete = e => {
    if (rowId !== '' && rowId !== null && rowId !== undefined) {
      dispatch(deleteCampaignAction(rowId));
      setrowId('');
      setCheck(false);
      setselectedData([]);
    }
  };

  const handleEdit = e => {
    setOpenEditMod(true);
  };

  const handleClick = rows => {
    //
    // selectedRow.current = rows;
    // setid(
    //   selectedRow.current && selectedRow.current && selectedRow.current._id
    // );
    // setselectedData(selectedRow.current);
    //
    //   selectedRow.current && selectedRow.current && selectedRow.current._id,
    //   "nick"
    // );
  };

  //style
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  };

  const textDecor = {
    textDecoration: 'underline',
    color: '#22165f'
  };

  const handleRowSelect = (row, id) => {
    if (id === rowId) {
      setCheck(false);
      setrowId('');
      setselectedData([]);
    } else {
      setselectedData(row);
      setrowId(id);
      setCheck(true);
    }
  };
  //
  //
  //

  useEffect(() => {
    dispatch(getCampaignAction());
  }, []);

  useEffect(() => {
    if (props.AllCampaign && props.AllCampaign.data) {
      let Newdata =
        props.AllCampaign &&
        props.AllCampaign.data.map(res => {
          return {
            defaultprop: res.properties,
            name: res.name,
            select: <Checkbox />,
            _id: res._id,
            propertiesActive: res.properties.map(res => {
              if (res.id && res.id.name) {
                return res.id.name + ', ';
              }
            }),
            dateCreated: moment(res.date).local().format('ll'),
            type: res.type,
            replyRatio: '',
            stages: '',
            lastRun: ''
            // propertyId: res.propertyId,
          };
        });
      setTableData(Newdata);
    }
  }, [props.AllCampaign]);

  return (
    <div style={{ width: '100%' }}>
      <MaterialTable
        title=""
        // onSelectionChange={(rows) => {
        //   handleClick(rows);
        // }}
        columns={[
          {
            title: 'Select',
            field: 'select',
            render: rowData => (
              <Checkbox
                onClick={() => handleRowSelect(rowData, rowData._id)}
                checked={Check && rowData._id === rowId}
              />
            )
          },
          {
            title: 'Name',
            field: 'name',
            render: rowData => (
              <span
                onClick={() => props.setActiveId(rowData._id)}
                style={textDecor}
              >
                {rowData.name}
              </span>
            )
          },
          { title: 'Properties Active', field: 'propertiesActive' },
          { title: 'Date Created', field: 'dateCreated' },
          { title: 'Type', field: 'type' },
          { title: 'Reply Ratio', field: 'replyRatio' },
          { title: 'Stages', field: 'stages', type: 'numeric' },
          { title: 'Last Run', field: 'lastRun' }
        ]}
        data={tableData}
        components={{
          Toolbar: props => (
            <div style={headerStyle}>
              <div>
                <MTableToolbar {...props} />
              </div>

              <div
                style={{
                  marginTop: '15px',
                  display: 'flex',
                  justifyContent: 'space-evenly'
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginRight: '20px',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                    fontSize: 12,
                    borderRadius: '6px',
                    height: '35px',
                    backgroundColor: '#20065f'
                  }}
                  onClick={e => handleAdd(e)}
                >
                  ADD SEQUENCE
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginRight: '20px',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                    fontSize: 12,
                    height: '35px',
                    borderRadius: '8px',
                    backgroundColor: '#525f80'
                  }}
                  disabled={rowId ? false : true}
                  onClick={e => handleEdit(e)}
                >
                  EDIT
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginRight: '20px',
                    backgroundColor: '#525f80',
                    height: '35px',
                    borderRadius: '6px',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                    fontSize: 12
                  }}
                >
                  RUN
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginRight: '20px',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                    fontSize: 12,
                    height: '35px',
                    borderRadius: '6px',
                    backgroundColor: '#ff6f62'
                  }}
                  onClick={e => handleDelete(e)}
                  disabled={rowId ? false : true}
                >
                  DELETE
                </Button>
              </div>
            </div>
          )
        }}
        localization={{
          toolbar: {
            nRowsSelected: ''
          }
        }}
        options={{
          // selection: true,
          headerStyle: {
            color: '#20065f',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600
          },
          rowStyle: {
            color: '#393939',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400
          }
        }}
      />
      <NewCampaignModal
        open={openBellMod}
        setOpen={setOpenBellMod}
        valid={valid}
        setvalid={setvalid}
      />
      <EditSequence
        open={openEditMod}
        setOpen={setOpenEditMod}
        selectedData={selectedData}
        setselectedData={setselectedData}
        // valid={valid}
        // setvalid={setvalid}
        setrowId={setrowId}
        setCheck={setCheck}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  AllCampaign: state.Workflow.AllCampaign
});

export default connect(mapStateToProps)(StTable);
