import {
  ADD_SEQUENCE,
  ADD_SEQUENCE_CONTENT,
  ADD_WORKFLOW,
  CREATE_CAMPAGIN,
  CREATE_STEP,
  GET_ALL_CAMPAIGN,
  GET_ALL_STEP,
  GET_ALL_WORKFLOW,
  GET_MSG_TEMPLATE,
  GET_STEP_BY_ID
} from '../Constant';
import { del, get, post, put } from '../networkClient';
import { ToastFireAction } from './Toast';

const getMsgTemp = data => ({
  type: GET_MSG_TEMPLATE,
  data: data
});
const AddWorkflow = data => ({
  type: ADD_WORKFLOW,
  data: data
});
const GetWorkflows = data => ({
  type: GET_ALL_WORKFLOW,
  data: data
});

const AddSequence = data => ({
  type: ADD_SEQUENCE,
  data: data
});

const AddSequenceContent = data => ({
  type: ADD_SEQUENCE_CONTENT,
  data: data
});

const AddCampaign = data => ({
  type: CREATE_CAMPAGIN,
  data: data
});

const getCampaign = data => ({
  type: GET_ALL_CAMPAIGN,
  data: data
});

const AddStep = data => ({
  type: CREATE_STEP,
  data: data
});

const getStep = data => ({
  type: GET_ALL_STEP,
  data: data
});

const getStepById = data => ({
  type: GET_STEP_BY_ID,
  data: data
});

export const createWorkflowAction = data => async dispatch => {
  await post('/workflow', data).then(resp => {
    if (resp.status === 200) {
      dispatch(getAllWorkflowsAction());
      dispatch(ToastFireAction(true, 'success', 'Workflow Created'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};

export const updateWorkflowAction = data => async dispatch => {
  await put('/workflow', data).then(resp => {
    if (resp.status === 200) {
      dispatch(getAllWorkflowsAction());
      dispatch(ToastFireAction(true, 'success', 'Workflow Updated'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};

export const createMsgTempAction = data => async dispatch => {
  await post('/template', data).then(resp => {
    if (resp.status === 200) {
      dispatch(getTemplateAction());
      dispatch(ToastFireAction(true, 'success', 'Template Created'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};
export const getTemplateAction = () => async dispatch => {
  await get('/template').then(resp => {
    dispatch(getMsgTemp(resp.data));
  });
};
export const UpdateTemplateAction = (data, id) => async dispatch => {
  await put('/template', data, { id: id }).then(resp => {
    if (resp.status === 200) {
      dispatch(getTemplateAction());
      dispatch(ToastFireAction(true, 'success', 'Template Updated'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};
export const deleteTemplateAction = id => async dispatch => {
  await del('/template', null, { id: id }).then(resp => {
    if (resp.status === 200) {
      dispatch(getTemplateAction());
      dispatch(ToastFireAction(true, 'success', 'Template Deleted'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });
};
export const AddWorkflowAction = data => async dispatch => {
  dispatch(AddWorkflow(data));
};

export const getAllWorkflowsAction = () => async dispatch => {
  await get('/workflow/tenant/all').then(resp => {
    dispatch(GetWorkflows(resp.data));
  });
};

export const deleteWorkflowAction = id => async dispatch => {
  await del(`/workflow/${id}`).then(resp => {
    if (resp.status === 200) {
      dispatch(getAllWorkflowsAction());
      dispatch(ToastFireAction(true, 'success', 'Workflow Deleted'));
    }
  });
};

// for icon_name
export const addSequenceAction = data => async dispatch => {
  dispatch(AddSequence(data));
};

export const addSequenceContentAction = data => async dispatch => {
  dispatch(AddSequenceContent(data));
};

// ADD CAMPAGIN

export const createCampaignAction = data => async dispatch => {
  await post('/sequence/', data).then(resp => {
    if (resp.status === 200) {
      dispatch(AddCampaign(resp.data));
      dispatch(getCampaignAction());
      dispatch(ToastFireAction(true, 'success', 'Campaign Created'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Error while creating Campaign'));
    }
  });
};
// export const getCampaignAction = (id) => async (dispatch) => {
//   await get("/sequence/ ", null,{ id: id }).then((resp) => {
//     dispatch(getCampaignAction(resp.data));
//   });
// };
export const getCampaignAction = () => async dispatch => {
  await get('/sequence/all').then(resp => {
    dispatch(getCampaign(resp.data));
  });
};
export const UpdateCampaignAction = data => async dispatch => {
  await put('/sequence/', data).then(resp => {
    if (resp.status === 200) {
      dispatch(getCampaignAction());
      dispatch(ToastFireAction(true, 'success', 'Campaign Updated'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Error while updating Campaign'));
    }
  });
};
export const deleteCampaignAction = id => async dispatch => {
  await del('/sequence/', null, { id: id }).then(resp => {
    if (resp.status === 200) {
      dispatch(getCampaignAction());
      dispatch(ToastFireAction(true, 'success', 'Campaign Deleted'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Error while deleting Campaign'));
    }
  });
};

// step _apis

export const createStepAction = data => async dispatch => {
  await post('/step', data).then(resp => {
    if (resp.status === 200) {
      dispatch(AddStep(resp.data));
      dispatch(getStepAction());
      dispatch(ToastFireAction(true, 'success', 'Step Created'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Error while creating Step'));
    }
  });
};
export const updateStepAction = data => async dispatch => {
  await put('/step', data).then(resp => {
    if (resp.status === 200) {
      dispatch(AddStep(resp.data));
      dispatch(getStepAction());
      dispatch(ToastFireAction(true, 'success', 'Step updated'));
    } else {
      dispatch(ToastFireAction(true, 'error', 'Error while creating Step'));
    }
  });
};

export const getStepAction = () => async dispatch => {
  await get('/step/all').then(resp => {
    dispatch(getStep(resp.data));
  });
};

export const getStepByIdAction = id => async dispatch => {
  await get('/sequence/step', { id: id }).then(resp => {
    dispatch(getStepById(resp.data));
  });
};
