import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import React from 'react';

const Ztable = ({
  rows = [],
  columns = [],
  editRowData,
  duplicateRow,
  editRow,
  deleteRow,
  tableLayout = 'fixed'
}) => {
  const editable = rows.map(o => ({ ...o }));
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={columns}
        data={editable}
        actions={[
          {
            icon: () => <ContentCopyIcon />,
            tooltip: 'Duplicate',
            onClick: (event, rowData) => duplicateRow(rowData)
          },
          {
            icon: () => <EditIcon />,
            tooltip: 'Edit',
            onClick: (event, rowData) => editRow(rowData)
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: 'Delete',
            onClick: (event, rowData) => deleteRow(rowData)
          }
        ]}
        options={{
          toolbar: false,
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          selection: false,
          tableLayout: tableLayout,
          headerStyle: {
            color: '#20065f',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '12px'
          }
        }}
      />
    </div>
  );
};

export default Ztable;
