import { GET_SEARCHED_DATA } from '../Constant';

const initialState = {
  GlobalSearchData: ''
};
const GlobalSearch = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCHED_DATA:
      return { ...state, GlobalSearchData: action.data };
    default:
      return state;
  }
};

export default GlobalSearch;
