import React from 'react';

const CommunityIcon = ({ color = '#fff', size = '28' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill={color} fillOpacity="0.01" />
      <path
        d="M9.33333 7H5.84383C4.55233 7 3.5 8.12233 3.5 9.50833V21.9917C3.5 23.38 4.55 24.5 5.84383 24.5H12.2453H9.33333V21.9975C7.448 21.9963 5.83333 21.9928 5.83333 21.9928C5.83333 21.9928 5.83917 9.50017 5.84383 9.50017L9.33333 9.50133V7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8.16667H16.3333V10.5H14V8.16667ZM7 11.6667H9.33333V14H7V11.6667ZM7 15.1667H9.33333V17.5H7V15.1667ZM14 11.6667H16.3333V14H14V11.6667ZM14 15.1667H16.3333V17.5H14V15.1667ZM16.3333 18.6667H18.6667V22.1667H16.3333V18.6667ZM18.6667 8.16667H21V10.5H18.6667V8.16667ZM18.6667 11.6667H21V14H18.6667V11.6667ZM18.6667 15.1667H21V17.5H18.6667V15.1667Z"
        fill={color}
      />
      <path
        d="M22.1562 22.1667C22.1643 22.1667 22.1667 5.8275 22.1667 5.8275C22.1667 5.8345 12.8438 5.83333 12.8438 5.83333C12.8357 5.83333 12.8333 22.1667 12.8333 22.1667H22.1562ZM10.5 5.8275C10.5 4.54183 11.5523 3.5 12.8438 3.5H22.1562C23.4512 3.5 24.5 4.54183 24.5 5.8275V22.1725C24.4972 22.7918 24.2489 23.3846 23.8094 23.821C23.37 24.2574 22.7754 24.5016 22.1562 24.5H10.5V5.8275Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityIcon;
