import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Gear from '../../../Media/workflow/Gear.png';
import Mailred from '../../../Media/workflow/Mailred.png';
import Popover from '@material-ui/core/Popover';
import StepSelect from './StepSelect';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, connect } from 'react-redux';
import {
  getStepAction,
  getStepByIdAction
} from '../../../Redux/Actions/workflow';
import AddStep from './AddStep';
import SummaryTab from './SequenceTabs/Summary';

const SequenceList = props => {
  const dispatch = useDispatch();

  const [TableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const container = {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column'
  };

  const btnstyle = {
    borderRadius: 8,
    backgroundColor: '#ff6f62',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 600,
    marginTop: '10px',
    width: 120,
    height: 33,
    bottom: '21%',
    left: '1%'
  };

  useEffect(() => {
    dispatch(getStepAction());
  }, []);

  useEffect(() => {
    dispatch(getStepByIdAction(props.paramid));
  }, []);

  useEffect(() => {
    if (props.AllStep && props.AllStep.data) {
      let Newdata =
        props.AllStep &&
        props.AllStep.data.filter(res => res.sequenceId === props.paramid);
      setTableData(Newdata);
    }
  }, [props.AllStep]);

  return (
    <div style={container}>
      {TableData && TableData.length > 0 && (
        <SummaryTab TableData={TableData} />
      )}
      <div>
        {TableData && TableData.length > 0 ? (
          <>
            <Button style={btnstyle} onClick={e => openOptions(e)}>
              <span style={{ marginTop: '5px' }}>
                <AddIcon style={{ color: '#ffffff' }} />
              </span>
              ADD STEP
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
            >
              <StepSelect paramid={props.paramid} handleClose={handleClose} />
            </Popover>
          </>
        ) : (
          <AddStep paramid={props.paramid} handleClose={handleClose} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  AllStep: state.Workflow.AllStep,
  StepById: state.Workflow.StepById
});

export default connect(mapStateToProps)(SequenceList);
