import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PetIcon from '../../Media/AddPropertyIcons/BigIcons/Pet_Icon.png';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
  HeadingPPStyle,
  imgPPStyle,
  Container,
  typoGraph
} from '../../styles/propertystyles/Otherstyle';

const Petpolicy = props => {
  const { Custom, setCustom } = props;
  //functionBlock
  const CheckFunction = (name, value) => {
    return (
      <Grid item xs={6} style={{ marginBottom: '-10px' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              labelStyle={{ color: 'white' }}
              name={value}
              checked={
                props.petsPolicy.length > 0 && props.petsPolicy.includes(value)
              }
              onChange={event => props.handlepetsPolicy(event)}
            />
          }
          label={<Typography style={typoGraph}>{name}</Typography>}
        />
      </Grid>
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container style={{ marginBottom: '10px' }}>
        <div>
          <div style={HeadingPPStyle}>
            Are pets of any kind allowed on the property
          </div>
          <div>
            <span style={imgPPStyle}>
              <img src={PetIcon} alt="PetIcon" />
            </span>
            <FormControl
              variant="outlined"
              style={{ minWidth: '190px', paddingTop: '10px' }}
              size="small"
            >
              <Select
                onChange={event => props.setPetAllowed(event.target.value)}
                value={props.PetAllowed}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex' }}>
        {props.PetAllowed ? (
          <Grid item xs={12} style={{ ...Container, marginRight: 0 }}>
            <div style={HeadingPPStyle}>
              What kind of pets are allowed? (select all that apply)
            </div>
            <Grid container>
              {CheckFunction('Dogs', 'dogs')}
              {CheckFunction('Cats', 'cats')}
              {CheckFunction('Birds', 'birds')}
              {CheckFunction('Reptiles', 'reptiles')}
              {CheckFunction('custom', 'custom')}
              {CheckFunction('All Animals with permission', 'allanimals')}
            </Grid>

            {props.petsPolicy.length > 0 &&
              props.petsPolicy.includes('custom') && (
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <TextField
                    key={`${Math.floor(Math.random() * 1000)}-min`}
                    // value={Custom}
                    defaultValue={Custom}
                    variant="outlined"
                    onBlur={event => setCustom(event.target.value)}
                    size="small"
                    fullWidth
                    multiline
                    rowsMax={3}
                  />
                </Grid>
              )}
          </Grid>
        ) : (
          <div style={Container}>
            <div style={HeadingPPStyle}>
              Do you allow registered service animals
            </div>
            <Grid item xs={6} style={{ marginBottom: '-10px' }}>
              <FormControl variant="outlined" style={{ minWidth: '256px' }}>
                <Select
                  onChange={event =>
                    props.setallowRegAnimals(event.target.value)
                  }
                  value={props.allowRegAnimals}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Petpolicy;
