export const ALL_TOUR_TYPES = [
  {
    value: 'inperson',
    label: 'In Person'
  },
  {
    value: 'virtual',
    label: 'Virtual'
  },
  { value: 'selfguided', label: 'Self-Guided' },
  { value: '3D', label: '3D' }
];
