import React from 'react';

const CrossIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM13.4996 12.3242C13.4995 12.0124 13.3755 11.7134 13.155 11.493L10.662 9L13.155 6.507C13.2641 6.39788 13.3507 6.26832 13.4098 6.12574C13.4688 5.98316 13.4992 5.83034 13.4992 5.676C13.4992 5.52167 13.4688 5.36885 13.4098 5.22627C13.3507 5.08369 13.2641 4.95413 13.155 4.845C13.0459 4.73588 12.9163 4.64931 12.7737 4.59025C12.6312 4.53119 12.4783 4.50079 12.324 4.50079C12.1697 4.50079 12.0169 4.53119 11.8743 4.59025C11.7317 4.64931 11.6021 4.73588 11.493 4.845L9 7.33801L6.506 4.845C6.28299 4.63776 5.98831 4.52507 5.68392 4.53065C5.37953 4.53622 5.08916 4.65962 4.87389 4.87489C4.65862 5.09016 4.53522 5.38053 4.52965 5.68492C4.52407 5.98931 4.63676 6.284 4.844 6.507L7.337 9L4.844 11.493C4.6235 11.7135 4.49962 12.0126 4.49962 12.3245C4.49962 12.6364 4.6235 12.9355 4.844 13.156C4.95292 13.2655 5.0824 13.3524 5.22501 13.4117C5.36763 13.471 5.52055 13.5016 5.675 13.5016C5.82945 13.5016 5.98238 13.471 6.12499 13.4117C6.26761 13.3524 6.39709 13.2655 6.506 13.156L9 10.662L11.493 13.156C11.7135 13.3764 12.0126 13.5002 12.3244 13.5001C12.6361 13.5 12.9351 13.376 13.1555 13.1555C13.3759 12.935 13.4997 12.6359 13.4996 12.3242Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default CrossIcon;
