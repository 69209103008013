import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Header,
  Information,
  JourneyContainer,
  Progress,
  Requirements,
  TitleBar
} from './kelsey-journey-styles';
import InfoContainer from './InfoContainer';
import { FormContainer } from './InfoContainer/info-container-styles';
import BasicRequirementsForm from './Forms/BasicRequirementsForm';
import UnitAssignmentForm from './Forms/UnitAssignmentForm';
import TourDetailsForm from './Forms/TourDetailsForm';
import TourDetailsInfo from './InfoCards/TourDetailsInfo';
import BasicRequirementsInfo from './InfoCards/BasicRequirementsInfo';
import UnitAssignmentInfo from './InfoCards/UnitAssignmentInfo';
import ProgressBar from '../ProgressBar';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import ReactRetool from 'react-retool';
import { getCookie } from '../../../utils';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';

const KelseyJourney = ({
  leadData,
  propertyData,
  sessionData,
  apartments,
  leadUpdateMutate,
  handleAutofillData,
  setIsTabOpen,
  enableBot,
  handlePNAExpansionToggle,
  isMaximizedPnAOpen
}) => {
  const Tabs = {
    BasicRequirements: 1,
    UnitAssignment: 2,
    TourBooking: 3
  };
  const { setUnit } = useContext(GlobalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [tab, setTab] = useState(0);
  const [pnaOpen, setPnaOpen] = useState(false);
  const updateEditing = tab => {
    setIsEditing(prevState => !prevState);

    setTab(tab);
  };
  const authorization = `${getCookie('rb_access_token')}`;
  useEffect(() => {
    if (isEditing) {
      setIsTabOpen(true);
    } else {
      setIsTabOpen(false);
    }
  }, [isEditing]);
  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current && leadData?.preference?.unit && propertyData) {
      setUnit({
        ...propertyData.find(({ _id }) => _id === leadData.preference.unit)
      });
      hasEffectRun.current = true;
    }
  }, [propertyData, leadData]);
  const checkIsEditing = inpTab => {
    return isEditing && tab === inpTab;
  };

  const getProgressValByContext = () => {
    if (sessionData?.unit_id) {
      if (sessionData?.tour_status) return 100;
      else return 55;
    } else if (
      sessionData?.slots?.unit?.bed &&
      sessionData?.slots?.unit?.bath
    ) {
      return 50;
    } else return 0;
  };

  const onCLosePna = () => {
    setPnaOpen(false);
  };
  return (
    <>
      {pnaOpen ? (
        <Popup
          width={'900px'}
          maxHeight="600px"
          minHeight="600px"
          onClose={onCLosePna}
          show={pnaOpen}
        >
          <PopupTitle fontSize={'16'} onClose={onCLosePna}>
            Global Price and Availability
          </PopupTitle>
          <PopupBody padding={'0px'}>
            <ReactRetool
              url={process.env.REACT_APP_RETOOL_PNA}
              data={{
                authorization
              }}
              height="530px"
            />
          </PopupBody>
        </Popup>
      ) : null}
      <JourneyContainer>
        <TitleBar>
          <Header>Kelsey's State</Header>
          {/* <Tooltip title="Info">
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
        </Tooltip> */}
        </TitleBar>
        <Requirements>
          <Progress>
            {sessionData && enableBot && (
              <ProgressBar
                length={272}
                color="#67C848"
                value={getProgressValByContext()}
              />
            )}
          </Progress>
          <Information>
            {checkIsEditing(Tabs.BasicRequirements) &&
            sessionData &&
            enableBot ? (
              <FormContainer>
                <BasicRequirementsForm
                  sessionData={sessionData}
                  leadData={leadData}
                  leadUpdateMutate={leadUpdateMutate}
                  onClose={() => {
                    setTab(0);
                    setIsEditing(false);
                  }}
                />
              </FormContainer>
            ) : null}
            {sessionData && enableBot ? (
              <InfoContainer
                tab={Tabs.BasicRequirements}
                topic="Basic Requirements"
                onEdit={updateEditing}
              >
                <BasicRequirementsInfo
                  sessionData={sessionData}
                  leadData={leadData}
                />
              </InfoContainer>
            ) : null}

            {/* {checkIsEditing(Tabs.UnitAssignment) ? (
              <FormContainer>
                <UnitAssignmentForm
                  leadData={leadData}
                  sessionData={sessionData}
                  apartments={apartments}
                  propertyData={propertyData}
                  leadUpdateMutate={leadUpdateMutate}
                  handleAutofillData={handleAutofillData}
                  isPositionAbsolute={true}
                  onClose={() => {
                    setTab(0);
                    setIsEditing(false);
                  }}
                  handlePNAExpansionToggle={handlePNAExpansionToggle}
                  isMaximizedPnAOpen={isMaximizedPnAOpen}
                />
              </FormContainer>
            ) : null} */}
            <InfoContainer
              tab={Tabs.UnitAssignment}
              topic="Unit Assignment"
              secondary
              setPnaOpen={setPnaOpen}
              onEdit={e => handlePNAExpansionToggle(e)}
            >
              <UnitAssignmentInfo
                leadData={leadData}
                propertyData={propertyData}
              />
            </InfoContainer>

            {checkIsEditing(Tabs.TourBooking) && sessionData && enableBot ? (
              <FormContainer>
                <TourDetailsForm
                  sessionData={sessionData}
                  leadData={leadData}
                  onClose={() => {
                    setTab(0);
                    setIsEditing(false);
                  }}
                />
              </FormContainer>
            ) : null}
            {sessionData && enableBot ? (
              <InfoContainer
                tab={Tabs.TourBooking}
                topic="Tour Details"
                onEdit={updateEditing}
              >
                <TourDetailsInfo sessionData={sessionData} />
              </InfoContainer>
            ) : null}
          </Information>
        </Requirements>
      </JourneyContainer>
    </>
  );
};

export default KelseyJourney;
