import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import closedReasons from '../Common/closedReasonsList';
import Popup from '../../../globalcomponents/Popup';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import SliderValue from '../../../globalcomponents/SliderValue';
import SuccessIcon from '../../../globalcomponents/SliderValue/SuccessIcon';
import {
  ActionItem,
  ActionsHeader,
  ActionsList,
  SliderSuccessContaniner
} from './sentiment.styles';
import { Required } from '../../KelseyInsight/Field/field.styles';
import { useTheme } from 'styled-components';

const SentimentPopup = ({
  show,
  lostReason,
  setLostReason,
  onClose,
  onAck
}) => {
  const [currStep, setCurrStep] = useState(0);
  const [value, setValue] = useState('1');

  const theme = useTheme();

  const handleValueChange = value => {
    if (value) {
      setValue(value);
    }
    if (value == '100') {
      setCurrStep(1);
      onAck();
    }
  };

  return (
    <Popup
      width={'300px'}
      show={show}
      onClose={onClose}
      position={'absolute'}
      top={show ? '-20px' : 0}
      boxShadow={'4px 4px 10px 0px rgba(0, 0, 0, 0.10)'}
    >
      <PopupTitle
        onClose={onClose}
        color="#FFFFFF"
        bgColor="#D83A52"
        padding={10}
        fontSize={14}
        showDefaultCloseIcon={true}
      >
        Lead Sentiment: Angry
      </PopupTitle>
      <PopupBody padding={'0px 10px'}>
        <div>
          <ActionsHeader>Upon sliding the acknowledgement: </ActionsHeader>
          <ActionsList>
            <li
              style={{
                display: 'list-item',
                listStyle: 'disc',
                margin: '10px 0'
              }}
            >
              <ActionItem>
                Lead will move to lost stage.
                <Required>*</Required>
                <FormControl className="custom-menu-dropdown" fullWidth focused>
                  <Autocomplete
                    disabled={false}
                    disableClearable
                    disablePortal
                    options={closedReasons}
                    className="custom-autocomplete"
                    getOptionLabel={option => option}
                    onChange={(e, val) => {
                      setLostReason(val);
                    }}
                    sx={{
                      paddingTop: '10px',
                      '&.MuiAutocomplete-option': {
                        fontWeight: 'regular',
                        fontSize: 12,
                        lineHeight: 14
                      }
                    }}
                    key={uniqueId}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ fontSize: '12px', fontWeight: '400' }}
                      >
                        {option}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="custom-property-dropdown"
                        placeholder="Select lost reason"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& > fieldset': {
                              border: `1px solid ${theme?.border?.primary}`,
                              '& .MuiAutocomplete-input': {
                                color: theme?.text?.primary
                              }
                            }
                          }
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </ActionItem>
            </li>
            <li
              style={{
                display: 'list-item',
                listStyleType: 'disc',
                margin: '10px 0'
              }}
            >
              <ActionItem>
                SMS and call option will be set to opt out.
              </ActionItem>
            </li>
            <li
              style={{
                display: 'list-item',
                listStyleType: 'disc',
                margin: '10px 0'
              }}
            >
              <ActionItem>AFM will be turned off for the lead.</ActionItem>
            </li>
          </ActionsList>
        </div>
        {currStep === 0 && show ? (
          <SliderValue
            value={value}
            setValue={handleValueChange}
            textOnSlider="Slide to take action"
            sliderIcon="https://assets.getzuma.com/images/RightArrow.svg"
            disabled={!lostReason}
          />
        ) : null}
        {currStep === 1 && show ? (
          <SliderSuccessContaniner>
            <SuccessIcon
              handleValueChange={handleValueChange}
              setCurrStep={setCurrStep}
            />
          </SliderSuccessContaniner>
        ) : null}
      </PopupBody>
    </Popup>
  );
};

export default SentimentPopup;
