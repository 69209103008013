import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from './styles';
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import {
  EditorState,
  convertToRaw,
  Modifier,
  ContentState,
  convertFromHTML
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { TEMPLATE_CONSTANTS } from './constants';
import EmailEditor from './EmailEditor';
import { htmlToText } from 'html-to-text';
import ChatPopover from '../../Inbox/ChatElements/ChatPopover';
import GiphyPopover from '../../Inbox/ChatElements/GiphyPopover';
import _ from 'lodash';
import GifContainer from './GifContainer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const HybridModal = ({
  open,
  handleClose,
  handleSubmit,
  smsTemplateMessage,
  emailTemplateMessage
}) => {
  const classes = useStyles();

  const [smsMessage, setSmsMessage] = React.useState(
    smsTemplateMessage?.message
  );
  const [subject, setSubject] = React.useState(emailTemplateMessage?.subject);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const [gifData, setGifData] = React.useState([]);

  React.useEffect(() => {
    if (_.has(smsTemplateMessage, 'media')) {
      setGifData(smsTemplateMessage?.media);
    }
  }, [smsTemplateMessage]);

  React.useEffect(() => {
    setSmsMessage(smsTemplateMessage?.message);
  }, [smsTemplateMessage]);

  React.useEffect(() => {
    if (emailTemplateMessage?.message !== '') {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(emailTemplateMessage?.message)
          )
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setSubject(emailTemplateMessage?.subject);
  }, [emailTemplateMessage]);

  const handleSmsChange = value => {
    setSmsMessage(value);
  };

  const onEditorStateChange = editorState => {
    setFocused(false);
    setEditorState(editorState);
  };

  const handleChange = event => {
    const { value } = event.target;
    setSubject(value);
  };

  const addSmsVariable = variable => {
    let temp = `${smsMessage}_${variable}_`;
    setSmsMessage(temp);
  };

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);

  const addVariable = variable => {
    if (focused) {
      let temp = `${subject}_${variable}_`;
      setSubject(temp);
    } else {
      const currentContent = editorState.getCurrentContent(),
        currentSelection = editorState.getSelection();

      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        `_${variable}_ `
      );

      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );
      setEditorState(newEditorState);
    }
  };

  const handleClick = () => {
    if (tab === 0) {
      if (!smsMessage?.length) {
        return alert('Template message cannot be empty!');
      }
      setTab(1);
    } else if (tab === 1) {
      const stringifiedObj = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ).toString();
      if (!stringifiedObj?.length) return alert('Email cannot be empty!');
      handleSubmit(
        smsMessage,
        {
          subject: subject,
          message: stringifiedObj
        },
        gifData
      );
      setTab(0);
    }
  };

  const handleChatEmojis = (images, type) => {
    if (type === 'emoji') {
      setSmsMessage(smsMessage + images);
    } else {
      const imagesData = [];
      imagesData.push(images);
      setGifData(imagesData);
    }
  };

  const handleCancelGif = event => {
    setGifData([]);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.modalHeader} id="responsive-dialog-title">
        Add New Templateeee
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            className={classes.tabStyle}
            value={tab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="SMS" />
            <Tab label="EMAIL" />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <div className={classes.buttonContainer}>
            {TEMPLATE_CONSTANTS.map((item, i) => {
              return (
                <Button
                  key={i}
                  variant="contained"
                  className={classes.templateButton}
                  onClick={() => addSmsVariable(item.value)}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
          <div>
            <div className={classes.subHeading}>Template Message *</div>
            <TextField
              id="Template Message"
              variant="outlined"
              placeholder="Start typing your template"
              fullWidth
              className={classes.inputRoot}
              multiline
              // error={valid && (Message === null || Message === "")}
              value={smsMessage}
              rows={4}
              onChange={e => handleSmsChange(e.target.value)}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ width: '100px', height: '100px' }}>
                {gifData.map((gif, idx) => {
                  return (
                    <GifContainer
                      key={idx}
                      gif={gif}
                      onCancel={handleCancelGif}
                    />
                  );
                })}
              </div>
              <div style={{ display: 'flex' }}>
                <ChatPopover isInTemplate handleChatEmojis={handleChatEmojis} />
                <GiphyPopover handleChatEmojis={handleChatEmojis} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div>
            <div className={classes.subHeading}>Template Subject *</div>
            <TextField
              className={classes.inputRoot}
              id="Template Subject"
              variant="outlined"
              fullWidth
              name="subject"
              onFocus={onFocus}
              value={subject}
              placeholder="Template Subject"
              onChange={handleChange}
            />
          </div>
          <div className={classes.buttonContainer}>
            {TEMPLATE_CONSTANTS.map((item, i) => {
              return (
                <Button
                  key={i}
                  variant="contained"
                  className={classes.templateButton}
                  onClick={() => addVariable(item.value)}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
          <EmailEditor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.backButton}
          autoFocus
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button className={classes.formButton} onClick={handleClick} autoFocus>
          {tab === 0 ? 'Next' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HybridModal;
