import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateContextData,
  updateSessionData
} from '../../../../Redux/Actions/InboxV1';
import Form from '../../Form';
import Input from '../../Input';

const BasicRequirementsForm = ({
  onClose,
  sessionData,
  leadData,
  leadUpdateMutate
}) => {
  const dispatch = useDispatch();
  const unitData = {
    bed: sessionData?.slots?.unit?.bed,
    bath: sessionData?.slots?.unit?.bath,
    unit_available_date: sessionData?.slots?.unit_available_date,
    budget: sessionData?.slots?.budget
  };

  const [isDateUpdated, setIsDateUpdated] = useState(false);
  const [updatedData, setUpdatedData] = useState({
    lead_id: leadData?._id,
    apartment_id: leadData?.apartment?._id,
    updated_by: 'portal',
    unit: {
      bed: unitData?.bed,
      bath: unitData?.bath
    },
    budget: unitData?.budget
  });

  const handleChange = (key, value) => {
    if (key === 'bed') {
      setUpdatedData(prevData => ({
        ...prevData,
        unit: {
          ...prevData?.unit,
          [`${key}`]: value,
          bath: prevData?.unit?.bath
        }
      }));
    } else if (key === 'bath') {
      setUpdatedData(prevData => ({
        ...prevData,
        unit: {
          ...prevData?.unit,
          [`${key}`]: value,
          bed: prevData?.unit?.bed
        }
      }));
    } else setUpdatedData(prevData => ({ ...prevData, [`${key}`]: value }));
    if (key === 'unit_available_date') {
      setIsDateUpdated(true);
    }
  };
  const handleTimelineUpdate = () => {
    const keyValues = {
      bed: 'bedroom',
      bath: 'bathroom',
      unit_available_date: 'moveIn',
      budget: 'budgetMin'
    };
    const dataTimeline = { ...updatedData };
    dataTimeline.bed = parseInt(updatedData?.unit?.bed);
    dataTimeline.bath = parseInt(updatedData?.unit?.bath);
    const prefrence = {};
    for (const i in dataTimeline) {
      if (keyValues[i] && dataTimeline[i]) {
        prefrence[keyValues[i]] = dataTimeline[i];
      }
    }
    leadUpdateMutate({
      variables: {
        leadId: leadData?._id,
        record: {
          preference: prefrence
        }
      }
    });
  };
  const handleSave = () => {
    dispatch(updateSessionData({ data: updatedData }));
    dispatch(
      updateContextData({
        data: {
          lead_id: leadData?._id,
          apartment_id: leadData?.apartment?._id,
          updated_by: 'portal',
          context: 'unitAssignment'
        }
      })
    );
    handleTimelineUpdate();
    onClose();
  };

  const createDateString = date => {
    if (!date) return null;
    const objDate = new Date(date);
    let day = objDate.getDate();
    let month = objDate.getMonth() + 1;
    let year = objDate.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return `${year}-${month}-${day}`;
  };

  return leadData?._id ? (
    <Form
      header="Basic Requirements"
      onClose={onClose}
      onSave={() => {
        handleSave();
      }}
    >
      <Input
        size="xs"
        onChange={e => handleChange('bed', e.target.value)}
        value={
          updatedData?.unit?.bed !== undefined ? updatedData?.unit?.bed : ''
        }
        placeholder="Enter here"
        label="Bed"
      />
      <Input
        size="xs"
        onChange={e => handleChange('bath', e.target.value)}
        value={
          updatedData?.unit?.bath !== undefined ? updatedData?.unit?.bath : ''
        }
        label="Bath"
        placeholder="Enter here"
      />
      <Input
        size="xs"
        onDateChange={val =>
          handleChange('unit_available_date', createDateString(val))
        }
        value={
          isDateUpdated
            ? updatedData?.unit_available_date &&
              updatedData?.unit_available_date !== null
              ? new Date(updatedData?.unit_available_date)
              : null
            : unitData?.unit_available_date &&
              unitData?.unit_available_date !== null
            ? unitData?.unit_available_date !== 'currently_available'
              ? new Date(unitData?.unit_available_date)
              : new Date()
            : null
        }
        label="Move-in"
        date
        prepend
      />
      <Input
        size="xs"
        onChange={e => handleChange('budget', e.target.value)}
        value={updatedData?.budget !== undefined ? updatedData?.budget : ''}
        label="Budget"
        placeholder="Enter here"
      />
    </Form>
  ) : null;
};

export default BasicRequirementsForm;
