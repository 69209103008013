import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changeWorkflowStatusApi,
  createWorkflowApi,
  deleteWorkflowApi,
  getAllWorkflows,
  updateWorkflowApi
} from '../util/Apis/workflow';

export const fetchAllWorkflows = createAsyncThunk(
  'workflow/fetchAllWorkflows',
  async (_args, { rejectWithValue }) => {
    try {
      return await getAllWorkflows();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createWorkflow = createAsyncThunk(
  'workflow/createWorkflow',
  async ({ workflowData }, { rejectWithValue }) => {
    try {
      await createWorkflowApi({ workflowData }).then(resp => {
        if (resp.status === 200) {
          alert('Saved Succesfully');
        } else {
          alert('Error in Saving Workflow');
        }
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateWorkflow = createAsyncThunk(
  'workflow/updateWorkflow',
  async ({ workflowData }, { rejectWithValue }) => {
    try {
      return await updateWorkflowApi({ workflowData });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteWorkflow = createAsyncThunk(
  'workflow/deleteWorkflow',
  async ({ id }, { rejectWithValue }) => {
    try {
      await deleteWorkflowApi({ id }).then(resp => {
        if (resp.status === 200) {
          alert('Deleted Succesfully');
        } else {
          alert('Error in Deleting Workflow');
        }
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changeWorkflowStatus = createAsyncThunk(
  'workflow/changeWorkFlowStatus',
  async ({ data }, { rejectWithValue }) => {
    try {
      return await changeWorkflowStatusApi({ data });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
