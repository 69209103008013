import { Card, CardContent } from '@material-ui/core';
import { Button } from '@mui/material';
import React from 'react';
import CustomModal from '../../Component/Modal';
import Error from '../../Media/InboxV1/Error';
import CloseIcon from '@mui/icons-material/Close';

const EmailDeletionPopup = ({ open, setOpen, handleSave, data }) => {
  return (
    <CustomModal open={open} onClose={() => setOpen(false)}>
      <Card style={{ width: '300px' }}>
        <div onClick={() => setOpen(false)}>
          <CloseIcon
            sx={{
              height: '20px',
              position: 'absolute',
              zIndex: 999,
              top: '20px',
              right: '15px'
            }}
          />
        </div>

        <CardContent className="flex" style={{ flexDirection: 'column' }}>
          <div>{data?.icon ? <data.icon /> : <Error />}</div>
          <div style={{ marginTop: '20px' }}>{data?.msg}</div>
          {!data?.noBtns && (
            <div className="actionBtns" style={{ marginTop: '20px' }}>
              {data?.btnReverse ? (
                <>
                  <Button
                    className="save-btn"
                    variant="text"
                    color="inherit"
                    onClick={handleSave}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    No
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="save-btn"
                    variant="text"
                    color="inherit"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </CustomModal>
  );
};

export default EmailDeletionPopup;
