import styled, { keyframes } from 'styled-components';

const CallToTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-top: 15px;
`;

const CallToTextTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
const CallToTextHeadingWrapper = styled.div`
  align-self: start;
  margin-bottom: 17px;
`;

const CallToTextHeading = styled.div`
  color: #42526e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const PropertyCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;

const PropertyCodeWrapperMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
`;

const SearchInput = styled.input`
  -webkit-appearance: none;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #c4c4c4;

  :focus {
    outline: none;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const SelectInput = styled.select`
  //   -webkit-appearance: none;
  outline: none;
  border: none;
  border-left: none;
  border-right: none;
  width: 100px;
  color: #5267ff;
  margin-right: 30px;
  width: 120px;
`;

const UploadButton = styled.input`
  background: rgba(82, 103, 255, 0.1);
  border-radius: 2px;

  align-items: center;
  cursor: pointer;
  border: none;
`;

const UploadLabel = styled.label`
  background: rgba(82, 103, 255, 0.1);
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  aling-items: center;
  padding: 5px 10px;
`;
const VoiceMailLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #42526e;
`;

const VoiceMailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
`;

const AddItemButton = styled.div`
  background: rgba(82, 103, 255, 0.1);
  border-radius: 53px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const spin = keyframes`
    to {
        transform: rotate(360deg);
    }
`;
const LoaderWhite = styled.div`
  position: absolute;
  left: ${({ left }) => left ?? '85%'};
  top: ${({ top }) => top ?? '50%'};
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #5267ff; /* Blue */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: ${spin} 2s linear infinite;
`;

const PurchaseText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #5267ff;
  position: absolute;
  left: 50%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  top: ${({ top }) => top ?? '50%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const SuccessIconC2T = styled.div`
  position: relative;

  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid #50be77;
  border-right: ${({ showFull }) => (showFull ? '2px solid #50BE77' : 'none')};
`;

const DeletWrapper = styled.div`
  position: absolute;
  left: 85%;
  top: ${({ top }) => top ?? '50%'};
`;

const LoaderBlue = styled.div`
  position: absolute;
  left: 8%;
  top: ${({ top }) => top ?? '35%'};
  border: 3px solid #5267ff; /* Light grey */
  border-top: 3px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: ${spin} 2s linear infinite;
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius ?? '36px'};
  background: rgba(82, 103, 255, 0.1);
  width: 41px;
  height: ${({ height }) => height ?? '41px'};
  margin-left: ${({ marginLeft }) => marginLeft ?? '120px'};
`;

export {
  CallToTextWrapper,
  CallToTextTopRow,
  PropertyCodeWrapper,
  SearchInput,
  UploadButton,
  VoiceMailLabel,
  VoiceMailWrapper,
  PropertyCodeWrapperMain,
  UploadLabel,
  AddItemButton,
  SelectInput,
  LoaderWhite,
  PurchaseText,
  SuccessIconC2T,
  DeletWrapper,
  LoaderBlue,
  CallToTextHeading,
  CallToTextHeadingWrapper,
  UploadContainer
};
