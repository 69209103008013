import React, { forwardRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CalendarIcon from '../../Media/KelseyInsight/CalendarIcon';
import RightArrow from '../../Media/InboxV1/RightArrow';
import {
  InputIconWrapper,
  CustomInputStyle,
  DateInputContainer,
  CustomHeaderContainer,
  ArrowWrapper,
  DateInputWrapper
} from './DateInput.styles';
import './styles.scss';

export const DefaultDateInput = forwardRef(
  (
    {
      value,
      onClick,
      placeholder,
      size = 'sm',
      prepend = true,
      append = false,
      border = false
    },
    ref
  ) => (
    <DateInputWrapper border={border} inverse={append} onClick={onClick}>
      <InputIconWrapper prepend>
        <CalendarIcon />
      </InputIconWrapper>
      <CustomInputStyle
        ref={ref}
        size={size}
        value={value}
        placeholder={placeholder}
        append={append}
        // onChange={(e) => {
        //   onChange && console.log('======>date', e.target.value);
        //   onChange(e.target.value);
        // }}
      />
    </DateInputWrapper>
  )
);

const DateInput = ({
  selected = new Date(),
  placeholderText = 'Select Date',
  dateFormat = 'yyyy-MM-dd',
  minDate = new Date(),
  onChange = date => {},
  isClearable = undefined,
  includeDates = undefined,
  customInput,
  append = false,
  border,
  showTimeSelect = false,
  showAll = false
}) => {
  const [tempDate, setTempDate] = useState(null);
  const [tempMonth, setTempMonth] = useState(null);

  const customHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => {
    return (
      <CustomHeaderContainer>
        <ArrowWrapper
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <RightArrow
            style={{
              cursor: 'pointer',
              transform: 'rotate(180deg)'
            }}
          />
        </ArrowWrapper>
        <div>
          {moment(date).format('MMMM')}, {moment(date).year()}
        </div>

        <ArrowWrapper
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <RightArrow
            style={{
              cursor: 'pointer'
            }}
          />
        </ArrowWrapper>
      </CustomHeaderContainer>
    );
  };

  const handleDateChange = date => {
    setTempDate(date);
    setTempMonth(date);
  };

  const handleMonthChange = date => {
    setTempMonth(date);
  };

  const resetMonth = () => {
    handleDateChange(selected);
  };

  const containsDate = (currentDate, datesList) => {
    return datesList.find(date => moment(date).isSame(currentDate, 'day'));
  };

  const getStyleForDate = date => {
    const styles = [];

    const currentCalendarDate = moment(
      tempDate ?? selected ?? new Date()
    ).startOf('day');
    const currentViewedMonth = moment(tempMonth ?? new Date()).startOf('day');

    let finale = moment(date);

    if (!showAll) {
      if (includeDates && !containsDate(finale, includeDates)) {
        styles.push('react-datepicker__day--disabled');
      } else {
        if (moment(date).isSame(currentCalendarDate)) {
          styles.push('customDayStyle');
          styles.push('selected');
          if (
            (moment(date).diff(minDate, 'days') > -1 &&
              currentCalendarDate.month() !== currentViewedMonth.month()) ||
            moment(date).diff(minDate, 'days') <= -1
          ) {
            styles.push('diffMonthSelected');
          }
        } else if (includeDates && containsDate(finale, includeDates)) {
          styles.push('includedDates');
        }
        if (moment(date).diff(minDate, 'days') > -1) {
          if (moment(date).month() === currentViewedMonth.month()) {
            styles.push('customDayStyle');
          } else {
            styles.push('otherMonth');
          }
        }
      }
    } else {
      if (moment(date).isSame(currentCalendarDate)) {
        styles.push('customDayStyle');
        styles.push('selected');
      } else if (includeDates && containsDate(finale, includeDates)) {
        styles.push('includedDates');
      }
    }

    return styles.join(' ');
  };

  const formatWkDay = day => day?.substring(0, 3);
  return (
    <DateInputContainer>
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        minDate={minDate}
        isClearable={isClearable}
        formatWeekDay={formatWkDay}
        includeDates={includeDates}
        placeholderText={placeholderText}
        customInput={
          customInput ?? (
            <DefaultDateInput
              // onChange={onChange}
              append={append}
              border={border}
            />
          )
        }
        renderCustomHeader={customHeader}
        dayClassName={date => getStyleForDate(date)}
        shouldCloseOnSelect={false}
        showTimeSelect={showTimeSelect}
      />
    </DateInputContainer>
  );
};

export default DateInput;
