import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  BtnContainer,
  Cancel,
  SendFeed,
  FontStyle
} from '../../styles/Inboxstyle';

function ClosureReason(props) {
  const dispatch = useDispatch();
  const [closedName, setClosedName] = useState([]);
  const { leadUpdateMutate } = props;
  const closedReasons = [
    {
      id: 1,
      reason: 'Wrong unit assignment'
    },
    {
      id: 2,
      reason: 'Wrong tour booking'
    },
    {
      id: 3,
      reason: 'Wrongly answered FAQ'
    },
    {
      id: 4,
      reason: 'Internal testing'
    }
  ];

  const handleChange = event => {
    setClosedName(event.target.value);
    // const {
    //   target: { value },
    // } = event;
    // setClosedName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };
  const CancelThis = () => {
    props.setOpenBotModal(false);
  };
  const ContinueThis = () => {
    if (closedName) {
      props.setOpenBotModal(false);
      props.setLeadLevelCheck(false);
      let botDetails = {};
      botDetails.enableBot = false;
      botDetails.botTurningOffReason = closedName;
      leadUpdateMutate({
        variables: { leadId: props.leadId, record: botDetails }
      });
    } else {
      alert('why are you turning it off the bot?');
    }
  };

  return (
    <div>
      <Modal
        open={props.openBotModal}
        onClose={() => props.setOpenBotModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={FontStyle}>Why are you turning off the bot?</div>
                  <div
                    style={{
                      marginLeft: '22.3%',
                      position: 'fixed',
                      marginTop: '-10px'
                    }}
                  >
                    <CloseIcon onClick={() => CancelThis()} />
                  </div>
                </div>
                <hr style={{ width: '110%', marginLeft: '-17px' }} />
                <div style={{ marginLeft: '26px' }}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={closedName}
                      onChange={handleChange}
                      renderValue={selected => selected.join(', ')}
                    >
                      {closedReasons.map(obj => (
                        <MenuItem key={obj.id} value={obj.reason}>
                          <Checkbox
                            checked={closedName.indexOf(obj.reason) > -1}
                          />
                          <ListItemText primary={obj.reason} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={SendFeed}
                    onClick={() => ContinueThis()}
                    disabled={!closedName.length}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default ClosureReason;
