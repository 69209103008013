import React from 'react';
import { Skeleton } from '@mui/material';
import { LeadContainer } from '../../../Container/KelseyInsight/LeadClientCard/lead-client-card.style.js';
import {
  Header,
  Information,
  JourneyContainer,
  Requirements,
  TitleBar
} from '../../../Container/KelseyInsight/KelseyJourney/kelsey-journey-styles.js';
import Tabs from '../../Tabs/index.jsx';
import { useTheme } from 'styled-components';
import {
  Container,
  Fields
} from '../../../Container/KelseyInsight/KelseyJourney/InfoContainer/info-container-styles.js';
import {
  AppointmentCardLayout,
  KelseyInsightLayout,
  Layout
} from './leadInfoLoader.styles.js';

const LeadInfoLoader = () => {
  const theme = useTheme();

  return (
    <Layout>
      <div
        style={{ margin: '0px' }}
        className=" d-flex-jst-space-around align-middle"
      >
        <Tabs
          background={theme?.background?.secondary}
          padding={'0 8px'}
          borderRadius={'4px'}
        >
          <Skeleton animation="wave" style={{ height: '50px' }}></Skeleton>
        </Tabs>
      </div>
      <KelseyInsightLayout>
        <LeadContainer>
          <Skeleton animation="wave"></Skeleton>
          <Skeleton animation="wave"></Skeleton>
        </LeadContainer>
        <JourneyContainer>
          <TitleBar>
            <Header>Kelsey's State</Header>
          </TitleBar>
          <Requirements>
            <Information>
              <Container style={{ width: '-webkit-fill-available' }}>
                <Fields>
                  <Skeleton
                    animation="wave"
                    style={{ width: '150px' }}
                  ></Skeleton>
                  <Skeleton
                    animation="wave"
                    style={{ width: '150px' }}
                  ></Skeleton>
                  <Skeleton
                    animation="wave"
                    style={{ width: '150px' }}
                  ></Skeleton>
                  <Skeleton
                    animation="wave"
                    style={{ width: '150px' }}
                  ></Skeleton>
                </Fields>
              </Container>
              <Container style={{ width: '-webkit-fill-available' }}>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
              </Container>
              <Container style={{ width: '-webkit-fill-available' }}>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  style={{ width: '150px', margin: '10px' }}
                ></Skeleton>
              </Container>
            </Information>
          </Requirements>
        </JourneyContainer>
      </KelseyInsightLayout>
      <AppointmentCardLayout>
        <Skeleton animation="wave"></Skeleton>
        <Skeleton animation="wave" style={{ height: '100px' }}></Skeleton>
      </AppointmentCardLayout>{' '}
    </Layout>
  );
};

export default LeadInfoLoader;
