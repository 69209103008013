import { Button, Tooltip } from '@mui/material';
import React from 'react';
import Edit from '../../../Media/KelseyInsight/Edit';
import Trash from '../../../Media/KelseyInsight/Trash';

import {
  CommentForm,
  CommentInput,
  CTASection,
  IconWrapper
} from './comment-modal.styles';
import {
  CommentBody,
  CommentContainer,
  CommentNum,
  CommentOptions,
  CommentTitle
} from './comment.styles';

const Comment = ({
  item,
  title,
  inputValue,
  isEditing,
  onChange,
  onUpdate,
  onDelete,
  onClickEdit,
  onCancelEdit
}) => {
  const { _id, message } = item;
  return (
    <>
      {!isEditing ? (
        <CommentContainer>
          <CommentTitle>
            <CommentNum>{title}</CommentNum>
            <CommentOptions>
              <Tooltip title="Edit">
                <IconWrapper onClick={() => onClickEdit && onClickEdit(_id)}>
                  <Edit />
                </IconWrapper>
              </Tooltip>
              <Tooltip title="Delete" dialogLeft="-24px">
                <IconWrapper onClick={() => onDelete && onDelete(_id)}>
                  <Trash />
                </IconWrapper>
              </Tooltip>
            </CommentOptions>
          </CommentTitle>
          <CommentBody>{message}</CommentBody>
        </CommentContainer>
      ) : (
        <CommentForm>
          <CommentInput
            placeholder="Type here"
            value={inputValue}
            onChange={onChange}
          />
          <CTASection>
            <Button
              size="xs"
              variant="contained"
              style={{
                textTransform: 'capitalize',
                background: '#5267FF'
              }}
              onClick={() => {
                onUpdate && onUpdate(_id);
              }}
            >
              <span style={{ color: '#FFFFFF' }}>Save</span>
            </Button>
            <Button size="xs" onClick={() => onCancelEdit && onCancelEdit()}>
              Cancel
            </Button>
          </CTASection>
        </CommentForm>
      )}
    </>
  );
};

export default Comment;
