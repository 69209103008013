import { Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import FailedStatus from '../../../Media/InboxV1/FailedStatus';
import ToastV1 from './ToastV1';
import './styles.scss';
import CheckCircle from '../../../Media/ErrorBoundary/CheckCircle';
import { StyledMaterialDesignContent } from './utils';
const SnackbarComponent = () => {
  const GlobalError = useSelector(state => state.GlobalError);
  const { gError } = GlobalError;

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      iconVariant={{
        warning: <FailedStatus />,
        success: <CheckCircle />
      }}
      Components={{
        warning: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent
      }}
      maxSnack={10}
    >
      <ToastV1 gError={gError} />
    </SnackbarProvider>
  );
};

export default SnackbarComponent;
