import React from 'react';
import Field from '../../Field';
import { Fields } from '../InfoContainer/info-container-styles';

const BasicRequirementsInfo = ({ sessionData }) => {
  const unit = sessionData?.slots?.unit;
  return (
    <Fields>
      <Field isRequired label="Bed" value={unit?.bed || 'N/A'} />
      <Field isRequired label="Bath" value={unit?.bath || 'N/A'} />
      <Field
        label="Move-in"
        value={sessionData?.slots?.unit_available_date || 'N/A'}
      />
      <Field
        label="Budget"
        value={
          sessionData?.slots?.budget ? `$${sessionData?.slots?.budget}` : 'N/A'
        }
      />
    </Fields>
  );
};

export default BasicRequirementsInfo;
