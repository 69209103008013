import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import {
  Header,
  Topic
} from '../KelseyJourney/InfoContainer/info-container-styles';

import { FormContainer, Fields, CTASection } from './form.styles';
import { useTheme } from 'styled-components';

const Form = ({
  children,
  header,
  onClose,
  onSave,
  secondary = false,
  isPositionAbsolute = false
}) => {
  const { darkMode } = useContext(GlobalContext);
  const theme = useTheme();
  return (
    <FormContainer isPositionAbsolute={isPositionAbsolute}>
      <Header secondary={secondary}>
        <Topic>{header}</Topic>
      </Header>
      <Fields>{children}</Fields>
      <CTASection isPositionAbsolute={false}>
        <Button
          variant="contained"
          style={{
            textTransform: 'capitalize',
            background: theme?.background?.selection,
            fontSize: '12px',
            padding: '6px 0.75rem',
            borderRadius: '4px',
            color: theme?.text?.secondary
          }}
          size="small"
          onClick={() => onSave()}
        >
          Save
        </Button>
        <Button
          variant="inherit"
          className="clearBtn"
          style={{
            textTransform: 'capitalize',
            background: 'transparent',
            fontSize: '12px',
            padding: '6px 0.75rem',
            color: darkMode ? '#fff' : ''
          }}
          size="small"
          onClick={e => onClose()}
        >
          Close
        </Button>
      </CTASection>
    </FormContainer>
  );
};

export default Form;
