import { Button, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CrossIcon from '../../../Media/InboxV1/CrossIcon';
import Error from '../../../Media/InboxV1/Error';
import VanityDeleteSuccessPopup from './VanityDeleteSuccessPopup';

const obj = {
  zumaIvrPhone: 'Vanity number',
  phone: 'Vanity number',
  textableVoiceName: 'Textable voice',
  existingForwardingNumber: 'Existing number',
  nonTextableVoiceName: 'Non-Textable voice',
  voicemailName: 'Voicemail'
};
const VanityDeletePopup = ({ open, setOpen, handleDelete, keyName }) => {
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [text, setText] = useState('');
  useEffect(() => {
    setText(obj[keyName]);
  }, [keyName, open]);
  return (
    <>
      <VanityDeleteSuccessPopup
        text={text}
        open={successPopUp}
        setOpen={setSuccessPopUp}
      />
      <Dialog
        className="d-flex-just-align-center"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ padding: '10px' }}
      >
        <CrossIcon
          style={{
            position: 'relative',
            left: '296px',
            top: '20px',
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            zIndex: 1
          }}
          onClick={() => setOpen(false)}
        />

        <div
          style={{
            position: 'relative',
            height: '294px',
            width: '340px'
          }}
        >
          <div
            className="d-flex-just-align-center"
            style={{ marginTop: '40px' }}
          >
            <Error />
          </div>
          <div
            className="inter-16px-500 d-flex-just-align-center"
            style={{ marginTop: '30px' }}
          >
            <span
              style={{
                color: '#393939',
                fontWeight: '500',
                fontSize: '16px',
                letterSpacing: '0.5px'
              }}
            >
              {text} deletion warning!{' '}
            </span>
          </div>
          <div
            className="d-flex-just-align-center"
            style={{ marginTop: '47px' }}
          >
            <Button
              variant="contained"
              style={{
                marginBottom: '20px',
                background: '#5267FF',
                width: '71px',
                height: '40px',
                textTransform: 'none'
              }}
              onClick={() => setOpen(false)}
              color={'primary'}
              fullWidth={false}
              size={'small'}
            >
              <span style={{ fontSize: '18px', fontWeight: '500' }}> No </span>
            </Button>
            <Button
              variant="inherit"
              style={{ marginBottom: '20px', textTransform: 'none' }}
              onClick={() => {
                setOpen(false);
                handleDelete();
                setSuccessPopUp(true);
              }}
              fullWidth={false}
              size={'small'}
            >
              <span
                style={{
                  color: '#393939',
                  fontSize: '18px',
                  fontWeight: '500'
                }}
              >
                Delete
              </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default VanityDeletePopup;
