import styled from 'styled-components';

const Layout = styled.div`
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
`;

const KelseyInsightLayout = styled.div`
  position: relative;
  bottom: -20px;
  border-radius: 6px;
`;

const AppointmentCardLayout = styled.div`
  background-color: ${({ theme }) => theme?.background?.secondary};
  padding: 10px;
`;

export { Layout, KelseyInsightLayout, AppointmentCardLayout };
