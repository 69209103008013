import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Success from '../../Media/InboxV1/success.gif';
import Error from '../../Media/InboxV1/error.gif';
import Processing from '../../Media/InboxV1/loading.gif';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '80px 0',
      width: '600px'
    },
    imageContainer: {
      height: '200px',
      width: '200px'
    },
    errorTextStyle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#FF6F62'
    },
    defaultTextStyle: props => ({
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '39px',
      color: props.text.primary
    }),
    goBackBtn: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '17px',
      textDecorationLine: 'underline',
      color: '#5267FF'
    }
  })
);

const TourBookingModal = ({
  status = '',
  errorText = 'Error: Selected time slot is not available.',
  handleBack,
  theme
}) => {
  const classes = useStyles(theme);
  const getIcon = () => {
    switch (status) {
      case 'success':
        return { image: Success, text: 'Tour Scheduled' };
      case 'error':
        return { image: Error, text: errorText };
      default:
        return { image: Processing, text: 'Processing..' };
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img style={{ height: '100%', width: '100%' }} src={getIcon().image} />
      </div>
      <Typography
        className={
          status === 'error' ? classes.errorTextStyle : classes.defaultTextStyle
        }
      >
        {status === 'error' ? 'Error:' : ''}
        {getIcon().text}
      </Typography>
      {status === 'error' ? (
        <Typography onClick={handleBack} className={classes.goBackBtn}>
          {errorText === 'TIME_NOT_AVAILABLE'
            ? 'Select different time slot'
            : 'Try again'}
        </Typography>
      ) : null}
    </div>
  );
};

export default TourBookingModal;
