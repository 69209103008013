import { FILE_UPLOAD, FILE_UPLOAD_APART, LOADING } from '../Constant';
import { post } from '../networkClient';
import { ToastFireAction } from './Toast';
import { callToTextUpdate } from './Apartment';

const FileDownload = require('js-file-download');

const fileUpload = data => ({
  type: FILE_UPLOAD,
  data: data
});
const fileUploadApart = data => ({
  type: FILE_UPLOAD_APART,
  data: data
});
const loading = data => ({
  type: LOADING,
  data: data
});

export const fileUploadAction = File => async dispatch => {
  const data = new FormData();
  data.append('fileImage', File, File.name);
  await post('/fileUpload', data)
    .then(resp => {
      if (resp.status === 200) {
        dispatch(fileUpload(resp.data));
        dispatch(
          ToastFireAction(true, 'success', 'File Uploaded successfully')
        );
      } else {
        dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
      }
    })
    .catch(error => {});
};

export const fileUploadApartAction = (File, id) => async dispatch => {
  let Obj = {};
  const data = new FormData();
  data.append('fileImage', File, File.name);
  await post('/fileUpload/vm', data)
    .then(resp => {
      Obj.id = id;
      Obj.CallToTextName = resp.data.name;
      Obj.CallToTextUrl = resp.data.location;
      Obj.CallToTextSize = resp.data.size.toString();
      Obj.CallToTextId = resp.data._id;
      if (resp.status === 200) {
        dispatch(fileUploadApart(resp.data));
        dispatch(callToTextUpdate(Obj));
        dispatch(ToastFireAction(true), 'success', 'file uploaded');
      } else {
        dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
      }
    })
    .catch(error => {});
};

export const ClearFilesfromStore = () => async dispatch => {
  const data = '';
  dispatch(fileUpload(data));
};

export const fileDownloadAction = data => async dispatch => {
  await post('/property/downloading', data).then(resp => {
    FileDownload(resp.data, 'File.zip');
  });
};

export const LoadingAction = data => async dispatch => {
  dispatch(loading(data));
};
