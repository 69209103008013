import styled from 'styled-components';

const CardWrapper = styled.div`
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  margin: ${({ margin }) => margin ?? 0};
  padding: ${({ padding }) => padding ?? 0};
  background-color: ${({ background }) => background ?? '#FFFFFF'};
  color: ${({ color }) => color ?? '#393939'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  font-family: Inter, sans-serif;
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 0};
  overflow: ${({ height }) => height && 'scroll'};
  box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
`;

export { CardWrapper };
