import React from 'react';
import { Doughnut } from 'react-chartjs-2';
const DoughnutChart = () => {
  const data = {
    labels: ['one', 'two', 'three', 'four', 'five', 'six'],
    datasets: [
      {
        data: [110, 50, 100, 100, 50, 80],
        backgroundColor: [
          '#6e85f6',
          '#e7c46a',
          '#79ca96',
          '#2f425f',
          '#54867b',
          '#4f64ab'
        ],
        hoverBackgroundColor: [
          '#6e85f6',
          '#e7c46a',
          '#79ca96',
          '#2f425f',
          '#54867b',
          '#4f64ab'
        ]
      }
    ]
  };
  return (
    <div>
      <Doughnut data={data} legend={{ display: false }} height={'108px'} />
    </div>
  );
};

export default DoughnutChart;
