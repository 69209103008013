import styled from 'styled-components';

const QueryContainer = styled.article`
  background-color: #e9ebfb;
  padding: 10px;
  border-radius: 4px;
`;

const QueryHeader = styled.h6`
  font-weight: 500;
  color: #393939;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  margin-bottom: 10px;
  overflow-wrap: anywhere;
`;

export { QueryContainer, QueryHeader };
