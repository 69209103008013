import React from 'react';
import { CardWrapper } from './card.styles';

const Card = ({
  id,
  children,
  width,
  height,
  minHeight,
  margin,
  padding,
  background,
  color,
  border,
  borderRadius,
  fontSize,
  boxShadow
}) => {
  return (
    <CardWrapper
      id={id}
      data-testid={id}
      width={width}
      height={height}
      minHeight={minHeight}
      margin={margin}
      padding={padding}
      background={background}
      color={color}
      border={border}
      borderRadius={borderRadius}
      fontSize={fontSize}
      boxShadow={boxShadow}
    >
      {children}
    </CardWrapper>
  );
};

export default Card;
