import { gql } from '@apollo/client';

export const updateLeadAgentByLeadId = gql`
  mutation updateLeadAgentByLeadId($leadId: MongoID!, $agent: String!) {
    updateLeadAgentByLeadId(leadId: $leadId, agent: $agent) {
      data
      error
    }
  }
`;
