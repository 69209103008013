import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CommentModal from './CommentModal';
import { Wrapper } from './kelsey-insight.styles';
import KelseyJourney from './KelseyJourney';
import LeadClientCard from './LeadClientCard';

const KelseyInsight = ({
  tenantName,
  clientName,
  userId,
  leadData,
  propertyData,
  apartments,
  leadUpdateMutate,
  handleAutofillData,
  setIsTabOpen,
  aiBotSettings,
  openSopDrawer,
  SOPDrawerToggleHandler,
  handlePNAExpansionToggle,
  isMaximizedPnAOpen
}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);

  const handleShowComments = () => {
    setShowCommentModal(prevState => !prevState);
  };
  const { sessionData } = useSelector(state => state.inboxMessages);

  const { clientEnabled, communityEnabled, leadEnabled } = aiBotSettings || {};

  return (
    <>
      {showCommentModal && <Wrapper disable={showCommentModal} />}
      <div style={{ bottom: '-20px', position: 'relative' }}>
        <LeadClientCard
          name={tenantName}
          client={clientName}
          leadData={leadData}
          onShowComments={handleShowComments}
          openSopDrawer={openSopDrawer}
          SOPDrawerToggleHandler={SOPDrawerToggleHandler}
        />
        <KelseyJourney
          leadData={leadData}
          propertyData={propertyData}
          sessionData={sessionData}
          apartments={apartments}
          leadUpdateMutate={leadUpdateMutate}
          handleAutofillData={handleAutofillData}
          setIsTabOpen={setIsTabOpen}
          handlePNAExpansionToggle={handlePNAExpansionToggle}
          isMaximizedPnAOpen={isMaximizedPnAOpen}
          enableBot={!!leadEnabled && !!communityEnabled && !!clientEnabled}
        />
      </div>
      {showCommentModal ? (
        <CommentModal
          userId={userId}
          leadInfo={leadData}
          onClose={handleShowComments}
          setIsTabOpen={setIsTabOpen}
        />
      ) : null}
    </>
  );
};

export default KelseyInsight;
