import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullStory } from '@fullstory/browser';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { Card, CardContent, Typography } from '@mui/material';
import AppointmentCard from '../AppointmentCard';
import TourSchedulingWidget from '../TourSchedulingWidget';
import { getInboxMeta } from '../../Redux/Actions/Inbox';
import { fetchAvailableTourTypesByApartment } from '../../Redux/Actions/Apartment';
import Loader from '../../Component/Loader';
import AppointmentModal from '../AppointmentModal';
import { useLazyQuery } from '@apollo/client';
import { sensitiveLeadInfo } from '../../gql/queries/sensetiveLeadInfo';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import Icon from '../../globalcomponents/Icon';
import '../../../src/fullstory';
import {
  initFullStoryForUser,
  restartDataCapture,
  shutdownDataCapture
} from '../../../src/fullstory';
const textContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px'
};
const textContainer1 = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '-12px',
  marginTop: '-4px'
};

const appointmentTitle = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '17px',
  color: '#525F80',
  fontFamily: 'Inter'
};

const view = {
  textAlign: 'center',
  marginBottom: '-15px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '15px',
  color: '#5267FF',
  cursor: 'pointer'
};
const AppointmentSchedule = props => {
  const {
    leadData,
    selectedLeadId,
    apartmentData,
    propertyData,
    inboxMetaSchedule,
    selectedOption
  } = props;

  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openAllAppointments, setOpenAllAppointments] = useState(false);
  const [open, setOpen] = useState(false);
  const [interest, setInterest] = useState();
  const [unit, setUnit] = useState();
  const [startDate, setStartDate] = useState();
  const [addTourButtonVisible, setAddTourButtonVisible] = useState(true);

  const CLIENTS_REQUIRING_EMAIL_AND_PHONE = [
    '62aacc02f4cf5cca7aad3d53' // Sares Regis
  ];

  const [getSensitiveInfo, { data: phoneAndEmailData, error }] =
    useLazyQuery(sensitiveLeadInfo);

  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    setInterest({
      _id: leadData?.apartment?._id,
      name: leadData?.apartment?.name
    });

    setUnit(
      propertyData?.find(({ _id }) => _id === leadData?.preference?.unit)
    );

    if (
      selectedLeadId &&
      CLIENTS_REQUIRING_EMAIL_AND_PHONE.indexOf(leadData?.tenant?._id) > -1
    ) {
      getSensitiveInfo({
        variables: {
          leadId: selectedLeadId
        }
      });
    }

    const phone = phoneAndEmailData?.leadById?.phone;
    const email = phoneAndEmailData?.leadById?.email;
    if (
      (!phone || !email) &&
      CLIENTS_REQUIRING_EMAIL_AND_PHONE.indexOf(leadData?.tenant?._id) > -1
    ) {
      setAddTourButtonVisible(false);
    } else {
      setAddTourButtonVisible(true);
    }
  }, [leadData, propertyData]);
  useEffect(() => {
    setStartDate(
      moment(inboxMetaSchedule?.schedule?.confirmedDate)?.format('LT')
    );
  }, [selectedLeadId, inboxMetaSchedule?.schedule]);

  const fetchTourBack = () => {
    dispatch(getInboxMeta(selectedLeadId));
  };

  const handleScheduleOpen = () => {
    setOpenScheduleModal(true);
    restartDataCapture();
    FullStory('trackEvent', {
      name: 'Tour Scheduling Widget Opened',
      properties: {
        popupName: 'TourSchedulingWidget'
      }
    });
  };

  const handleScheduleClose = () => {
    setOpenScheduleModal(false);
    FullStory('trackEvent', {
      name: 'Tour Scheduling Widget Closed',
      properties: {
        popupName: 'TourSchedulingWidget'
      }
    });
    shutdownDataCapture();
  };
  const handleAllAppointments = () => {
    setOpenAllAppointments(true);
  };
  const handleClosed = () => {
    setOpen(false);
  };

  const inboxMetaLoading = useSelector(
    state => state.inboxMessages.inboxMetaLoading
  );
  const { darkMode, userData } = useContext(GlobalContext);

  useEffect(() => {
    if (leadData?.apartment?._id)
      dispatch(fetchAvailableTourTypesByApartment(leadData?.apartment?._id));
  }, [leadData]);

  useEffect(() => {
    if (userData?._id) initFullStoryForUser(userData);
  }, [userData?._id]);

  const appointmentTitle = {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme?.text?.heading,
    fontFamily: 'Inter'
  };
  return (
    <div
      className=""
      style={{ marginTop: `${selectedOption === 'unit' ? '10px' : '0'}` }}
    >
      <Card
        style={{
          background: darkMode ? '#1D202D' : '',
          boxShadow: '0px 4px 10px rgb(0 0 0 / 4%);'
        }}
      >
        <CardContent>
          <div>
            <div
              style={
                inboxMetaSchedule?.schedule?.length > 0
                  ? textContainer
                  : textContainer1
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={appointmentTitle}>Appointment</Typography>
                <div
                  style={{
                    margin: '0 4px 0 10px',
                    cursor: 'pointer',
                    backgroundColor: `${theme.background.selection}1A`,
                    borderRadius: '50%'
                  }}
                >
                  <Icon
                    src={`add-${theme?.state}.svg`}
                    tooltipText={`${
                      addTourButtonVisible
                        ? 'Schedule tour'
                        : 'Disabled: Phone or email data not present'
                    }`}
                    onClick={addTourButtonVisible ? handleScheduleOpen : null}
                    alt="Add tour"
                    wrapperStyle={{
                      cursor: addTourButtonVisible ? 'pointer' : 'not-allowed'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <AppointmentModal
            open={openAllAppointments}
            setOpenAllAppointments={setOpenAllAppointments}
            unitSelected={unit}
            interest={interest}
            fetchTourBack={fetchTourBack}
            schedule={inboxMetaSchedule?.schedule}
            leadId={selectedLeadId}
            propertyData={propertyData}
            propertyList={propertyData}
            leadData={leadData}
            apartmentData={apartmentData}
            SelectedBTimezone={
              inboxMetaSchedule?.lead?.propertyId?.aptId?.timeZone
            }
            lead={leadData}
          />
          {inboxMetaLoading ? (
            <div style={{ height: '100px', width: '100px', margin: '0 auto' }}>
              <Loader />
            </div>
          ) : inboxMetaSchedule?.schedule?.length ? (
            inboxMetaSchedule?.schedule?.map((res, i) => {
              if (i < 2) {
                return (
                  <AppointmentCard
                    key={res?._id}
                    count={i + 1}
                    unitSelected={unit}
                    interest={interest}
                    fetchTourBack={fetchTourBack}
                    schedule={res}
                    leadId={selectedLeadId}
                    propertyData={propertyData}
                    propertyList={propertyData}
                    leadData={leadData}
                    apartmentData={apartmentData}
                    SelectedBTimezone={res?.aptId?.timeZone}
                    lead={leadData}
                    startDate={startDate}
                    handleClosed={handleClosed}
                  />
                );
              }
            })
          ) : null}
          {inboxMetaSchedule?.schedule?.length > 2 ? (
            <div style={view} onClick={handleAllAppointments}>
              View +{inboxMetaSchedule?.schedule?.length - 2}
            </div>
          ) : null}
          <div>
            {openScheduleModal ? (
              <TourSchedulingWidget
                schedule={inboxMetaSchedule?.schedule}
                unitSelected={unit}
                interest={interest}
                fetchTourBack={fetchTourBack}
                propertyList={propertyData}
                leadId={selectedLeadId}
                propertyData={propertyData}
                leadData={leadData}
                apartmentData={apartmentData?.filter(
                  ({ isLive, name }) => isLive && name
                )}
                handleClose={handleScheduleClose}
                SelectedBTimezone={
                  inboxMetaSchedule?.lead?.propertyId?.aptId?.timeZone
                }
                lead={leadData}
                startDate={startDate}
              />
            ) : null}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AppointmentSchedule;
