import styled from 'styled-components';
const TextLabelComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #393939;
  font-family: Inter;
`;

const ValueWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #393939;
  font-family: Inter;
`;

export { TextLabelComponentWrapper, LabelWrapper, ValueWrapper };
