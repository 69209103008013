import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { ToastClearAction } from '../Redux/Actions/Toast';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    zIndex: 99,
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function CustomizedSnackbars(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.open);
  const classes = useStyles();
  const vertical = 'top';
  const horizontal = 'right';
  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        dispatch(ToastClearAction());
      }, 4000);
    }
  }, [props.open]);

  return (
    <div className={classes.root} style={{ zIndex: 100 }}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setOpen(false)}
      >
        <Alert severity={props.severity} onClose={() => setOpen(false)}>
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
