import React from 'react';

const SopIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="book">
        <rect width="20" height="20" fill="white" fill-opacity="0.01" />
        <g id="Vector">
          <path
            d="M5.83464 5.00167V13.3317C5.83464 13.7867 6.2063 14.1667 6.6638 14.1667H13.3388C13.7888 14.1667 14.168 13.7925 14.168 13.3317V5.00167C14.168 4.54667 13.7963 4.16667 13.3388 4.16667H6.6638C6.2138 4.16667 5.83464 4.54083 5.83464 5.00167ZM4.16797 5.00167C4.16863 4.33939 4.43161 3.70435 4.89936 3.23551C5.36711 2.76666 6.00153 2.5022 6.6638 2.5H13.3388C14.0012 2.50176 14.6359 2.76608 15.1037 3.23502C15.5716 3.70396 15.8344 4.33926 15.8346 5.00167V13.3317C15.834 13.9939 15.571 14.629 15.1032 15.0978C14.6355 15.5667 14.0011 15.8311 13.3388 15.8333H6.6638C6.0014 15.8316 5.36671 15.5672 4.89887 15.0983C4.43102 14.6294 4.16819 13.9941 4.16797 13.3317V5.00167Z"
            fill="#42526E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5013 5.83333H12.5013V9.16667H7.5013V5.83333ZM7.5013 10.8333H12.5013V12.5H7.5013V10.8333ZM8.33464 17.3808V16.6667H5.83464V17.3808L7.08464 16.6667L8.33464 17.3808ZM5.83464 15H8.33464V16.6667H5.83464V15Z"
            fill="#42526E"
          />
        </g>
      </g>
    </svg>
  );
};

export default SopIcon;
