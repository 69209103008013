import React, { useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../Container/KelseyInsight/Input';
import Icon from '../../globalcomponents/Icon';
import { throttle } from '../../Container/InboxV2/inboxUtils';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 224px;
  padding: 1.5px 10px;

  border-radius: 4px;
  border: 0.5px solid #c4c4c4;
`;

const DollarSign = styled.span`
  margin-right: 8px;
`;
const TextStyle = styled.span`
  color: ${({ theme }) => theme?.text?.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const MinMaxRange = ({ type, value, onChange }) => {
  const [min, setMin] = React.useState(value.min);
  const [max, setMax] = React.useState(value.max);
  const handleThrottle = throttle(({ min, max }) => {
    onChange({ min, max });
  }, 2000);
  useEffect(() => {
    handleThrottle({ min, max });
  }, [min, max]);
  return (
    <Container>
      {type === 'currency' && <TextStyle>$</TextStyle>}
      <Input
        placeholder="min"
        borderNone={true}
        width={'50px'}
        size="sm"
        value={min}
        onChange={e => setMin(e.target.value)}
      />
      <TextStyle>to</TextStyle>
      <Input
        placeholder="max"
        borderNone={true}
        width={'50px'}
        size="sm"
        value={max}
        onChange={e => setMax(e.target.value)}
      />
    </Container>
  );
};

export default MinMaxRange;
