import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  ActionsContainer,
  ClickableHeaderLinks,
  FlexAlignItemsCenter,
  HoverOverlay,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeaderCell,
  StyledTableRow,
  TableTooltip,
  WidthMaxContent
} from './styles.tablecomponent';
import ProgressBar from '../ProgressBar';
import EditIcon from '../../Media/InboxV1/EditIcon';
import DeleteIcon from '../../Media/InboxV1/DeleteIcon';
import { LoaderWhite } from '../../Component/CallToTextComponent/styles.callToText';
import Button from '../Button';
import { useWindowDimensions } from '../../utils/customHooks';

const TableComponent = ({
  data,
  headers,
  onItemClick = () => {},
  handleEdit,
  handleDelete,
  onPurchase,
  sourceEditLoading,
  pinnedColumns,
  height = 'auto',
  cellWeight = 500,
  cellSize = 14,
  actionOnHover = false,
  onDoubleClickRow,
  defaultSelectedRowId,
  tooltipOnDoubleClick,
  selectedLeadId
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'none'
  });

  const { windowWidth, windowHeight } = useWindowDimensions();
  const handleSort = key => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'desc') {
        setSortConfig({ key, direction: 'asc' });
      } else {
        setSortConfig({ key: null, direction: 'none' });
      }
    } else {
      setSortConfig({ key, direction: 'desc' });
    }
  };

  const handlePinColumn = key => {
    setPinnedColumns(prev =>
      prev.includes(key) ? prev.filter(col => col !== key) : [...prev, key]
    );
  };

  const sortedData =
    data &&
    [...data].sort((a, b) => {
      if (sortConfig.direction === 'none') return 0;
      const getNestedValue = (obj, keyPath) => {
        return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
      };

      const valueA = getNestedValue(a, sortConfig.key);
      const valueB = getNestedValue(b, sortConfig.key);

      const parsedValueA =
        typeof valueA === 'string' ? parseFloat(valueA) : valueA;
      const parsedValueB =
        typeof valueB === 'string' ? parseFloat(valueB) : valueB;

      if (sortConfig.direction === 'asc') {
        if (isNaN(parsedValueA) || isNaN(parsedValueB)) {
          return valueA?.localeCompare(valueB);
        } else {
          return parsedValueA - parsedValueB;
        }
      }

      if (sortConfig.direction === 'desc') {
        if (isNaN(parsedValueA) || isNaN(parsedValueB)) {
          return valueB?.localeCompare(valueA);
        } else {
          return parsedValueB - parsedValueA;
        }
      }

      return 0;
    });

  const renderCell = (item, header) => {
    if (header.render) {
      console.log('render', header.render(item));
      return header.render(item);
    }
    switch (header.type) {
      case 'percent':
        return `${item[header.key]}%`;
      case 'progress':
        return (
          <ProgressBar
            total={item[header.key]?.total}
            completed={item[header.key]?.completed}
            showStatus={true}
            height={'4px'}
          />
        );
      case 'actions':
        return (
          <ActionsContainer>
            {item.actions.map(action =>
              action === 'number-call' ? (
                <img
                  onClick={() => onPurchase(item)}
                  src="/on-call.svg"
                  alt="on-call"
                />
              ) : action === 'edit' ? (
                <EditIcon width={24} onClick={() => handleEdit(item)} />
              ) : item?.isCustom ? (
                <DeleteIcon onClick={() => handleDelete(item)} />
              ) : null
            )}
          </ActionsContainer>
        );
      default:
        const cellContent = item[header.key] ?? '-';
        if (cellContent.length > 20) {
          return `${cellContent.slice(0, 20)}...`;
        }
        if (item?.isSuggested && header.key === 'vanityNumber') {
          return `Suggested: ${cellContent}`;
        }
        if (item?.isCustom && header.key === 'source') {
          return `(Unknown) ${cellContent}`;
        }
        return cellContent;
    }
  };

  const renderHeaders = headers =>
    headers.map((header, index) => (
      <StyledTableHeaderCell
        key={index}
        onClick={() => handleSort(header.key)}
        firstColumn={index === 0}
        lastColumn={index === headers.length - 1}
        className={
          sortConfig.key === header.key ? `sorted-${sortConfig.direction}` : ''
        }
      >
        <FlexAlignItemsCenter
          style={{
            width: 'max-content'
          }}
        >
          <WidthMaxContent>{header.displayName}</WidthMaxContent>
          {sortConfig.key === header.key ? (
            <img
              src={`https://assets.getzuma.com/images/${
                sortConfig.direction === 'asc' ? 'arrow-up' : 'arrow-down'
              }.svg`}
              alt="sorting"
              width={15}
              height={15}
              style={{ marginLeft: '2px', minWidth: '20px' }}
            />
          ) : (
            <img
              src="https://assets.getzuma.com/images/table-sort.svg"
              alt="sorting"
              width={14}
              height={14}
              style={{ marginLeft: '2px', minWidth: '20px' }}
            />
          )}

          {header?.clickableLink && (
            <ClickableHeaderLinks>{header?.clickableLink}</ClickableHeaderLinks>
          )}
        </FlexAlignItemsCenter>
      </StyledTableHeaderCell>
    ));

  const renderRows = (data, headers, defaultSelectedRowId = null) => {
    const [hoveredRowIndex, setHoveredRowIndex] = React.useState(null);
    const [selectedRowId, setSelectedRowId] =
      React.useState(defaultSelectedRowId);
    const [tooltip, setTooltip] = useState({
      visible: false,
      text: '',
      x: 0,
      y: 0
    });
    const handleDoubleClick = (event, item) => {
      if (onDoubleClickRow) {
        setSelectedRowId(item._id);
        setTooltip({
          visible: true,
          text: tooltipOnDoubleClick, // Change this to whatever text you need
          x: event.clientX,
          y: event.clientY
        });
        onDoubleClickRow(item);
        setTimeout(() => {
          setTooltip({ ...tooltip, visible: false });
        }, 1000);
      }
    };
    useEffect(() => {
      const hideTooltip = () => setTooltip({ ...tooltip, visible: false });
      window.addEventListener('click', hideTooltip);
      return () => window.removeEventListener('click', hideTooltip);
    }, [tooltip]);
    const calculateLeftPosition = () => {
      const baseLeft = tooltip.x - height;
      const maxLeft = windowWidth - height;
      return Math.min(baseLeft, maxLeft);
    };
    const calculateTopPosition = () => {
      const baseTop = tooltip.y - height / 2;
      const maxTop = windowHeight - height / 2;
      return Math.min(baseTop, maxTop);
    };

    return data.map((item, rowIndex) => (
      <>
        <StyledTableRow
          isSelectable={actionOnHover}
          isSelected={selectedRowId === item._id}
          key={rowIndex}
          onDoubleClick={event => handleDoubleClick(event, item)}
          onClick={() => onItemClick && onItemClick(item)}
          onMouseEnter={() => setHoveredRowIndex(rowIndex)}
          onMouseLeave={() => setHoveredRowIndex(null)}
        >
          {headers.map((header, colIndex) => (
            <StyledTableCell
              isFaded={header?.isFaded && item.isSuggested}
              key={colIndex}
              cellWeight={cellWeight}
              cellSize={cellSize}
            >
              {renderCell(item, header)}
            </StyledTableCell>
          ))}
          {/* {actionOnHover && hoveredRowIndex === rowIndex && (
          <HoverOverlay>
            <Button
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
                position: 'sticky',
                right: '10px'
              }}
              btnType="primary"
              onClick={() => actionOnHover(item)}
            >
              Select
            </Button>
          </HoverOverlay>
        )} */}
        </StyledTableRow>
        {tooltipOnDoubleClick && (
          <TableTooltip
            className={tooltip.visible ? 'visible' : ''}
            style={{
              top: calculateTopPosition(),
              left: calculateLeftPosition()
            }}
          >
            {tooltip.text}
          </TableTooltip>
        )}
      </>
    ));
  };

  const pinnedHeaders = headers.filter(header =>
    pinnedColumns?.includes(header.key)
  );
  const unpinnedHeaders = headers.filter(
    header => !pinnedColumns?.includes(header.key)
  );

  return (
    <div
      style={{
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        height: height
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>{renderHeaders(pinnedHeaders)}</StyledTableRow>
          </StyledTableHead>
          <tbody>
            {renderRows(sortedData, pinnedHeaders, defaultSelectedRowId)}
          </tbody>
        </StyledTable>
      </div>
      <div style={{ overflowX: 'auto', flex: 1 }}>
        <StyledTable>
          <StyledTableHead style={{ position: 'sticky' }}>
            <StyledTableRow>{renderHeaders(unpinnedHeaders)}</StyledTableRow>
          </StyledTableHead>
          <tbody>
            {renderRows(sortedData, unpinnedHeaders, defaultSelectedRowId)}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};

export default TableComponent;
