// export const Development = "https://api.residentboost.com/api";
// export const DevGraphQl = 'https://stageapi.residentboost.com';
// export const DevGraphQl = 'http://localhost:8101';
export const DevGraphQl = 'https://apis-v2.residentboost.com';

// export const Development = 'http://localhost:8101/api';
export const Development = 'https://api.residentboost.com/api';
// export const Development = "https://demoapi.residentboost.com/api"
// export const Development = 'https://stageapi.residentboost.com/api';
// export const Development = 'https://qaapp.residentboost.com/api';
//export const Development = "https://stageapi.residentboost.com/api";

// export const websocketUrl = 'http://localhost:8101';
export const websocketUrl = 'https://api.residentboost.com';
// export const websocketUrl = 'https://stageapi.residentboost.com';

export const kelseyBasePath = 'https://kelsey.getzuma.com';
export const Production = process.env.REACT_APP_SERVERURL;
export const ProductionGraphql = process.env.REACT_APP_GRAPHQL;
export const googleAuthUrl = process.env.REACT_APP_googleAuthUrl;
export const dittoApi = process.env.REACT_APP_BOT_API;
export const kelseyAi = process.env.REACT_APP_KELSEYAI_API;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
