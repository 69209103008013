import {
  ClientInfo,
  ClientLink,
  ControlOptions,
  Detail,
  InfoContainer,
  Label,
  LeadContainer,
  Title
} from './lead-client-card.style';
import { useEffect, useState } from 'react';
import CommentIcon from '../../../Media/InboxV1/CommentIcon';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { getOneSopAndAnnouncement } from '../../../gql/queries/sopAndAnnouncements';
import moment from 'moment';
import Icon from '../../../globalcomponents/Icon';
import { useTheme } from 'styled-components';

const LeadClientCard = ({
  name,
  client,
  leadData,
  onShowComments,
  openSopDrawer,
  SOPDrawerToggleHandler
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const tenantId = leadData?.tenant?._id;
  const apartmentId = leadData?.apartment?._id;
  const [recentSopApplicable, setRecentSopApplicable] = useState(false);

  const [fetchFirstSop, { loading: firstSopLoading }] = useLazyQuery(
    getOneSopAndAnnouncement,
    {
      variables: {
        filters: {
          tenants: [tenantId],
          apartments: [apartmentId]
        },
        page: 0,
        limit: 1
      },
      onCompleted: ({ getAllSopAndAnnouncement }) => {
        const createdWithin2Days =
          moment(getAllSopAndAnnouncement?.data?.[0]?.createdAt ?? null) >=
          moment().subtract(2, 'days');
        setRecentSopApplicable(createdWithin2Days);
      }
    }
  );

  useEffect(() => {
    setRecentSopApplicable(false);
    fetchFirstSop();
  }, [tenantId, apartmentId]);

  return (
    <LeadContainer recentSopApplicable={recentSopApplicable}>
      <Title>
        <InfoContainer>
          <Label>Name: </Label>
          <Detail data-testid="lead-detail__name">{name}</Detail>
        </InfoContainer>
        <ControlOptions>
          {!recentSopApplicable ? (
            <Icon
              src={`bulb-${theme?.state}.svg`}
              onClick={SOPDrawerToggleHandler}
              alt="sop-icon"
              tooltipText="SOP & Notes"
            />
          ) : (
            <Icon
              src="bulb filled.svg"
              tooltipText="Recent SOPs applicable"
              alt="active-sops"
              onClick={SOPDrawerToggleHandler}
            />
          )}
        </ControlOptions>
      </Title>
      <ClientInfo>
        <InfoContainer>
          <Label>Client: </Label>
          <Detail>
            <ClientLink>{client}</ClientLink>
          </Detail>
        </InfoContainer>
      </ClientInfo>
    </LeadContainer>
  );
};

export default LeadClientCard;
