import React, { useEffect, useRef, useState } from 'react';
import TableComponent from '../../globalcomponents/TableComponent';
import { callTextHeaders, getCallTextData } from './constants';
import {
  AddItemButton,
  CallToTextHeading,
  CallToTextHeadingWrapper,
  CallToTextTopRow,
  CallToTextWrapper,
  PropertyCodeWrapperMain,
  SelectInput
} from './styles.callToText';
import PropertyCodeComponent from './PropertyCodeComponent';
import DeletePopup from './DeletePopup';
import AddPopup from './AddPopup';
import { useLazyQuery, useMutation } from '@apollo/client';
import { fetchApartmentSourcesById } from '../../gql/queries/apartmentSources';
import { CSVLink } from 'react-csv';
import { createSourceApartment } from '../../gql/mutations/sourceApartment';
import axios from 'axios';
import { getCookie } from '../../utils';
import { Path } from '../../Redux/util/path';
import { post } from '../../Redux/networkClient';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../Redux/Reducer/GlobalError';

const CallToTextComponent = ({ aptId, setEntrataIVRAndCTT, tenantId }) => {
  const [tableData, setTableData] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [dropdownValue, setDropdownValue] = useState('select');
  const [isEditable, setIsEditable] = useState(false);
  const [purchaseLoader, setPurchaseLoader] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [filePopupData, setFilePopupData] = useState(false);
  const [fileUploadStates, setFileUploadStates] = useState({
    isLoading: false,
    isSuccess: false
  });
  const [isDeleteVanityNumber, setIsDeleteVanityNumber] = useState(false);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  function handleEdit(index) {
    if (index > -1) {
      setEditData({ ...tableData[index] });
      setIsEditable(true);
      !tableData[index]?.vanityNumber &&
        handleSuggestedNumbers([index], tableData);
    } else {
      handleSuggestedNumbers([], tableData, true);
      setIsEditable(false);
    }
    setShowEditPopup(prev => !prev);
  }

  function handleDelete(index) {
    setShowDeletePopup(prev => !prev);
    setEditData({ ...tableData[index] });
  }

  const handleClose = () => {
    setShowDeletePopup(false);
    setShowEditPopup(false);
    setFilePopupData(null);
    setFileUploadStates({ isLoading: false, isSuccess: false });
    setEditData({});
  };

  const [getSourcesData, { data: { getCommunitySources } = {} }] = useLazyQuery(
    fetchApartmentSourcesById,
    {
      onCompleted: data => {
        setEntrataIVRAndCTT(data?.getCommunitySources?.entrataIVRAndCTT);
      }
    }
  );

  const fileUpload = async e => {
    const file = e.target.files[0];
    setFilePopupData(file);
    setShowDeletePopup(true);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('fileImage', filePopupData);

    setFileUploadStates({ isLoading: true, isSuccess: false });
    const data = await post(
      `${Path.UPLOAD_SOURCESHEET}${aptId}`,
      formData,
      {},
      { 'Content-Type': 'multipart/form-data' }
    );
    if (data?.status === 200) {
      getSourcesData({
        variables: { apartmentId: aptId }
      });
      setFileUploadStates({ isLoading: false, isSuccess: true });
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };

  useEffect(() => {
    if (getCommunitySources) {
      setEntrataIVRAndCTT(getCommunitySources?.entrataIVRAndCTT);
      const newData = getCallTextData(getCommunitySources?.leadSources);
      setTableData(newData);
    }
  }, [getCommunitySources]);

  const [createSource, { loading: sourceEditLoading }] = useMutation(
    createSourceApartment,
    {
      onCompleted: () => {
        setShowTick(true);
        setPurchaseLoader(false);
        setTimeout(() => {
          setShowTick(false);
          handleClose();
        }, 2000);
      },
      onError: () => {
        setPurchaseLoader(false);
      },
      refetchQueries: [
        {
          query: fetchApartmentSourcesById,
          variables: { apartmentId: getCommunitySources?._id }
        }
      ]
    }
  );

  const handleSearch = e => {
    const value = e.target.value;
    setSearchVal(value);
    const wholeData = getCallTextData(getCommunitySources?.leadSources);
    if (!value) {
      setTableData([...wholeData]);
      return;
    }
    const newData = wholeData.filter(({ source, sscNumber, vanityNumber }) => {
      return (
        source?.toLowerCase().includes(value.toLowerCase()) ||
        sscNumber?.toLowerCase().includes(value.toLowerCase()) ||
        vanityNumber?.toLowerCase().includes(value.toLowerCase())
      );
    });
    setTableData([...newData]);
  };
  useEffect(() => {
    if (aptId) {
      getSourcesData({
        variables: { apartmentId: aptId }
      });
    }
  }, [aptId]);
  console.log('editData', editData);
  const csvLink = useRef();
  const handleSelect = e => {
    const value = e.target.value;
    if (value === 'bulk_download') {
      csvLink.current.link.click();
    }

    setDropdownValue(value);
  };
  const handleSourceSave = (existingData = null) => {
    const { source, sscNumber, vanityNumber, isSuggested } = existingData
      ? existingData
      : editData;
    if (!source) {
      dispatch(setGlobalError(`Source can't be empty`));
      return;
    }
    if (!sscNumber && !sscNumber?.includes('+1')) {
      dispatch(setGlobalError(`SSC number should start with +1`));
      return;
    }
    createSource({
      variables: {
        aptId: getCommunitySources?._id,
        record: {
          source: source?.trim(),
          sscPhoneNumber: sscNumber,
          vanityNumber: isSuggested ? '' : vanityNumber
        }
      }
    });
  };
  const handleSuggestedNumbers = async (
    index,
    tableData,
    callApi,
    isSetEditData = true
  ) => {
    if (!index.length && !callApi) {
      setTableData([...tableData]);
      return;
    }
    const getAvailableNumbersUrl =
      'https://communications.residentboost.com/twilio/getPhoneNumbersByAreaCode';
    const token = getCookie('rb_access_token');
    const authorization = token ? token : '';

    const data = await axios.post(
      `${getAvailableNumbersUrl}`,
      {
        inPostalCode: getCommunitySources?.address?.postalCode,
        limit: index.length || 1,
        city: getCommunitySources?.address?.city,
        streetAddress: getCommunitySources?.address?.streetAddress?.replaceAll(
          ',',
          ''
        ),
        stateShort: getCommunitySources?.address?.stateShort,
        stateLong: getCommunitySources?.address?.stateLong,
        tenantId: tenantId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
          'X-Api-Key': 'b48f493e-d68a-4c69-9e1a-b5bdd2d7587b'
        }
      }
    );
    const responseData = data?.data?.responseData;
    if (index?.length) {
      for (let i = 0; i < index.length; i++) {
        const existingData = tableData[index[i]];
        if (!existingData) continue;
        existingData.vanityNumber = responseData?.[i].phone;
        existingData.isSuggested = true;
        existingData.actions = ['number-call', ...existingData.actions];
      }
    }
    if (editData && index?.length <= 1 && isSetEditData) {
      let newEditData =
        index?.[0] > -1 ? { ...tableData?.[index[0]] } : { ...editData };
      newEditData = {
        ...newEditData,
        vanityNumber: responseData?.[0]?.phone,
        isSuggested: true
      };
      newEditData.actions =
        newEditData?.actions?.length > 0
          ? ['number-call', ...newEditData.actions]
          : ['number-call', 'edit', 'delete'];
      setEditData({ ...newEditData });
    }
    setTableData([...tableData]);
  };

  const purchaseData = async (index, updateEditData = true) => {
    const editData1 =
      index !== null && index > -1 ? tableData[index] : editData;
    console.log('editData1', editData1?.source);
    if (!editData1?.source) {
      return;
    }
    if (index > -1) {
      setEditData({ ...editData1 });
    }
    const phone = editData1?.vanityNumber;
    const purchaseNoUrl =
      'https://communications.residentboost.com/twilio/purchase-vanity-phone-number';
    setPurchaseLoader(true);
    const data = await axios.post(
      `${purchaseNoUrl}`,
      { number: phone, tenantId: tenantId },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': 'b48f493e-d68a-4c69-9e1a-b5bdd2d7587b'
        }
      }
    );
    if (data?.data) {
      handleSourceSave({ ...editData1, isSuggested: false });
      updateEditData &&
        setEditData({ ...editData, isSuggested: false, vanityNumber: phone });
    }
  };
  useEffect(() => {
    if (!getCommunitySources) return;
    const newData = getCallTextData(getCommunitySources?.leadSources);
    const index = newData.reduce((acc, { sscNumber, vanityNumber }, index) => {
      if (sscNumber && !vanityNumber) {
        acc.push(index);
      }
      return acc;
    }, []);

    handleSuggestedNumbers([...index], newData, true, false);
  }, [getCommunitySources]);

  useEffect(() => {
    if (dropdownValue === 'bulk_update') {
      fileInputRef.current.click();
    }
  }, [dropdownValue]);

  const handleBulkPurchase = async tableData => {
    console.log('tableData', tableData);
    if (!tableData?.length) return;
    for (let i = 0; i < tableData.length; i++) {
      tableData[i]?.isSuggested && purchaseData(i, false);
    }
  };

  const handleDeleteVanityNumber = async () => {
    const index = tableData.findIndex(
      ({ source }) => source === editData?.source
    );
    if (index > -1) {
      setEditData({
        ...editData,
        vanityNumber: ''
      });
      createSource({
        variables: {
          aptId: getCommunitySources?._id,
          record: {
            source: editData?.source,
            sscPhoneNumber: editData?.sscNumber,
            vanityNumber: ''
          }
        }
      });
      setIsDeleteVanityNumber(false);
      handleSuggestedNumbers([index], tableData);
    }
  };

  return (
    <CallToTextWrapper>
      <DeletePopup
        editData={editData}
        show={showDeletePopup}
        handleClose={handleClose}
        aptId={getCommunitySources?._id}
        filePopupData={filePopupData}
        handleFileUpload={handleFileUpload}
        fileUploadStates={fileUploadStates}
        isDeleteVanityNumber={isDeleteVanityNumber}
        deleteVaityNumberCallback={handleDeleteVanityNumber}
      />
      <AddPopup
        editData={editData}
        show={showEditPopup}
        handleClose={handleClose}
        setEditData={setEditData}
        onSave={() => handleSourceSave(null)}
        isEditable={isEditable}
        onPurchase={() => purchaseData(null)}
        sourceEditLoading={sourceEditLoading}
        showTick={showTick}
        purchaseLoader={purchaseLoader}
        handleDeleteVanityNumber={() => {
          setIsDeleteVanityNumber(true);
          setShowDeletePopup(true);
        }}
      />
      <CallToTextHeadingWrapper>
        <CallToTextHeading>SSC Table</CallToTextHeading>
      </CallToTextHeadingWrapper>

      <CallToTextTopRow>
        <PropertyCodeWrapperMain>
          <PropertyCodeComponent
            name={getCommunitySources?.name}
            propertyId={getCommunitySources?.meta?.propertyId}
            areaCode={getCommunitySources?.address?.postalCode}
            city={getCommunitySources?.address?.city}
            stateLong={getCommunitySources?.address?.stateLong}
            handleSearch={handleSearch}
            searchInput={searchVal}
          />
        </PropertyCodeWrapperMain>

        <SelectInput value={dropdownValue} onChange={handleSelect}>
          <option value="select">Bulk Options</option>
          <option value="bulk_update">
            <input
              ref={fileInputRef}
              type="file"
              onChange={fileUpload}
              key={filePopupData ? 'filePopupData' : 'filePopupData1'}
              id="csvupload"
              style={{ display: 'none' }}
            />
            <label for="csvupload"> Upload</label>
          </option>
          <option value="bulk_download">
            <CSVLink
              ref={csvLink}
              filename={`${aptId}.csv`}
              headers={['Source', 'SSC Phone Number', 'Vanity Number']}
              data={tableData?.map(({ source, sscNumber, vanityNumber }) => [
                source,
                sscNumber,
                vanityNumber
              ])}
            >
              Download
            </CSVLink>
          </option>
        </SelectInput>
      </CallToTextTopRow>
      <TableComponent
        headers={callTextHeaders}
        data={tableData}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        onPurchase={purchaseData}
        sourceEditLoading={purchaseLoader}
        bulkPurchase={handleBulkPurchase}
        editData={editData}
      />
      <AddItemButton id="add-item-button" onClick={handleEdit}>
        <img alt="add" src="/add.png" />
      </AddItemButton>
    </CallToTextWrapper>
  );
};

export default CallToTextComponent;
