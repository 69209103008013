export const USER_STATUS_LIST = [
  {
    key: 'online',
    indicatorColor: '#00875A',
    text: 'Online'
  },
  {
    key: 'offline',
    indicatorColor: '#808080',
    text: 'Offline'
  },
  {
    key: 'onbreak',
    indicatorColor: '#7F5347',
    text: 'On Break'
  }
];
