import React from 'react';

const PMSIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 10.725C9.2501 10.725 7.01676 10.2833 5.3001 9.4C3.58343 8.51667 2.7251 7.525 2.7251 6.425C2.7251 5.30833 3.57926 4.3125 5.2876 3.4375C6.99593 2.5625 9.23343 2.125 12.0001 2.125C14.7501 2.125 16.9834 2.5625 18.7001 3.4375C20.4168 4.3125 21.2751 5.30833 21.2751 6.425C21.2751 7.525 20.4209 8.51667 18.7126 9.4C17.0043 10.2833 14.7668 10.725 12.0001 10.725ZM12.0001 16.275C9.5001 16.275 7.32926 15.8833 5.4876 15.1C3.64593 14.3167 2.7251 13.3667 2.7251 12.25V9.175C2.7251 9.775 2.9876 10.3208 3.5126 10.8125C4.0376 11.3042 4.7376 11.7292 5.6126 12.0875C6.4876 12.4458 7.47926 12.725 8.5876 12.925C9.69593 13.125 10.8334 13.225 12.0001 13.225C13.1668 13.225 14.3043 13.1292 15.4126 12.9375C16.5209 12.7458 17.5126 12.4708 18.3876 12.1125C19.2626 11.7542 19.9626 11.325 20.4876 10.825C21.0126 10.325 21.2751 9.775 21.2751 9.175V12.25C21.2751 13.3667 20.3584 14.3167 18.5251 15.1C16.6918 15.8833 14.5168 16.275 12.0001 16.275ZM12.0001 21.85C9.5001 21.85 7.32926 21.4583 5.4876 20.675C3.64593 19.8917 2.7251 18.9417 2.7251 17.825V14.75C2.7251 15.35 2.9876 15.8958 3.5126 16.3875C4.0376 16.8792 4.7376 17.3042 5.6126 17.6625C6.4876 18.0208 7.47926 18.2958 8.5876 18.4875C9.69593 18.6792 10.8334 18.775 12.0001 18.775C13.1668 18.775 14.3043 18.6792 15.4126 18.4875C16.5209 18.2958 17.5126 18.025 18.3876 17.675C19.2626 17.325 19.9626 16.9042 20.4876 16.4125C21.0126 15.9208 21.2751 15.3667 21.2751 14.75V17.825C21.2751 18.9417 20.3584 19.8917 18.5251 20.675C16.6918 21.4583 14.5168 21.85 12.0001 21.85Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default PMSIcon;
