import React from 'react';
import { DrawerHeaderWrapper } from '../drawer.styles';

const DrawerHeader = ({
  padding,
  margin,
  background,
  border,
  borderRadius,
  children
}) => {
  return (
    <DrawerHeaderWrapper
      padding={padding}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      background={background}
    >
      {children}
    </DrawerHeaderWrapper>
  );
};

export default DrawerHeader;
