import { FormControl, MenuList, Select } from '@mui/material';
import React from 'react';
import { CallType } from '../Constants';

const SelectType = ({
  index,
  value,
  handleChange,
  hideCallText = false,
  style = {}
}) => {
  return (
    <FormControl style={{ width: '25%', height: '40px', ...style }}>
      <Select
        style={{ padding: '0px' }}
        labelId={`type-select-${index}`}
        id={`type-select-${index}`}
        onChange={handleChange}
        name="type"
        value={value}
        sx={{
          fontSize: '0px',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          cursor: 'pointer'
        }}
      >
        {CallType.map(({ name, value, Icon }, i) => {
          if (hideCallText && name === 'Call to Text') {
            return;
          }
          return (
            <MenuList
              sx={{ display: 'flex', alignItems: 'center' }}
              key={value}
              value={value}
            >
              <div
                id={`type-select-${index}-menu-list-div-${i}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon />
                &nbsp;{name}
              </div>
            </MenuList>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectType;
