import styled from 'styled-components';

const IconWrapper = styled.div`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  max-height: ${({ size }) => size};
  max-width: ${({ size }) => size};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export { IconWrapper };
