import React, { useEffect } from 'react';
import SavedField from './SavedField';
import SelectType from './SelectType';

const SlotComponent = ({
  name,
  index,
  slotsData,
  setSlotsData,
  updatedCallData,
  setUpdateCallData,
  isCallTextSelected,
  slotsEditable,
  id
}) => {
  const handleChange = e => {
    if (e.target.name === 'type') {
      setSlotsData({
        ...slotsData,
        [name]: {
          ...slotsData[name],
          [e.target.name]: e.target.value,
          numberValue: '',
          audioValue: ''
        }
      });
    } else
      setSlotsData({
        ...slotsData,
        [name]: {
          ...slotsData[name],
          [e.target.name]:
            e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }
      });
  };
  const type = slotsData[name]?.type;
  const handleSlotChange = value => {
    if (type === 'number') {
      setSlotsData({
        ...slotsData,
        [name]: { ...slotsData[name], numberValue: value }
      });
    } else {
      setSlotsData({
        ...slotsData,
        [name]: { ...slotsData[name], audioValue: value }
      });
    }
  };
  useEffect(() => {
    setUpdateCallData({ ...updatedCallData, slots: { ...slotsData } });
  }, [slotsData]);
  return (
    <div className="slot" key={name}>
      <input
        onChange={handleChange}
        name="checked"
        type="checkbox"
        checked={slotsData[name]?.checked}
        className={
          slotsEditable
            ? 'slot-checkbox disableCheckbox'
            : 'disabled-background disableCheckbox'
        }
      />
      <div
        className={
          slotsEditable ? 'slot-name' : 'slot-name disabled-background'
        }
      >
        {name}
      </div>
      <input
        type="text"
        style={{ height: '35px', width: '40%' }}
        name="kelseyText"
        onChange={handleChange}
        placeholder={'Enter description here...'}
        checked={slotsData[name]?.checked}
        value={slotsData[name]?.kelseyText}
        className={slotsEditable ? '' : 'disabled-background'}
      />
      <SelectType
        index={index}
        handleChange={handleChange}
        value={type || 'NA'}
        hideCallText={
          !(slotsData[name]?.type === 'call-text') && isCallTextSelected
        }
        style={!slotsEditable ? { background: '#f6f6f6' } : {}}
      />
      <SavedField
        {...{
          marginBottom: true,
          type,
          editable: slotsEditable,
          updatedCallData,
          setUpdateCallData,
          handleSlotChange
        }}
        isSlotData
        value={
          type === 'number'
            ? slotsData[name]?.numberValue
            : slotsData[name]?.audioValue
        }
        fileName={slotsData[name]?.audioValue}
        keyName={name}
        id={id}
      />
    </div>
  );
};

export default SlotComponent;
