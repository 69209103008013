import { GET_ALL_AMENITIES } from '../Constant';
import { get } from '../networkClient';

const getAllAmenities = data => ({
  type: GET_ALL_AMENITIES,
  data: data
});

export const getAllAmenitiesAction = () => async dispatch => {
  await get('/property-amenities/all').then(resp => {
    if (resp.status === 200) {
      dispatch(getAllAmenities(resp.data));
    }
  });
};
