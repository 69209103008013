import React, { useContext } from 'react';
import './styles.scss';
import Logo from '../../Media/Sidebar/companyLogo.svg';
import { Tooltip } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookie } from '../../utils';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import { SidebarContainer } from './styles.sidebar';

const SideBar = props => {
  const { sideNavMenu, userData } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  return (
    <SidebarContainer>
      <div>
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/')}
          src={Logo}
          alt="zuma logo"
        />
        <div className="menu-items-container">
          {sideNavMenu.map((navItem, i) => {
            return userData?.user_id && !navItem?.hideOnSideNav ? (
              <React.Fragment>
                <Tooltip arrow key={i} title={navItem.name}>
                  <div
                    onClick={() =>
                      navItem?.newLink
                        ? (window.location.href = `https://app-v2.getzuma.com/${
                            navItem.newLink
                          }?token=${getCookie('rb_access_token')}`)
                        : history.push(navItem.route)
                    }
                    className={`sidenav-icons ${
                      location.pathname === navItem.route ? 'active' : ''
                    }`}
                  >
                    {location.pathname === navItem.route && (
                      <span className="active-item" />
                    )}
                    <div className="nav-item">{navItem.logo}</div>
                  </div>
                </Tooltip>
              </React.Fragment>
            ) : null;
          })}
        </div>
      </div>
    </SidebarContainer>
  );
};

export default SideBar;
