import React, { useState } from 'react';
import Routes from './Router/index';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './history';
import Wrapper from './Component/Wrapper';
import GlobalContextProvider from './utils/ContextProviders/GlobalContextProvider';
import { AuthContextProvider } from './utils/ContextProviders/AuthContextProvider';

export default function App() {
  return (
    <Router history={history}>
      <AuthContextProvider>
        <GlobalContextProvider>
          <Wrapper>
            <Routes history={history} />
          </Wrapper>
        </GlobalContextProvider>
      </AuthContextProvider>
    </Router>
  );
}
