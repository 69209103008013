import {
  Autocomplete,
  FormControl,
  TextField,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ZTextField from '../Common/ZSearch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { postKelsey } from '../../Redux/networkClient';
import { Path } from '../../Redux/util/path';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import {
  CheatSheetQuestion,
  CheatSheetTag,
  RightSideCards
} from '../LeadDetails/LeadDetailsEdit/leadDetailsEdit.styles';
import { useTheme } from 'styled-components';
import { uniqueId } from 'lodash';
import { getKBCheatsheetByApartment } from '../../Redux/util/Apis/inbox';
import { useInboxContext } from '../../utils/ContextProviders/InboxContextProvider';
const CheatQuestions = props => {
  const {
    setMessageInput,
    leadId,
    setIsCheatSheetUsed,
    apartmentData = [],
    apartmentId
  } = props;
  const [showSelectedAnswer, setShowSelectedAnswer] = useState('');
  const [updatedCheatSheetData, setUpdatedCheatSheetData] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState(apartmentId);
  const [cheatSheetData, setCheatsheetData] = useState([]);
  const { darkMode } = useContext(GlobalContext);

  const { lastIncomingMessages } = useInboxContext();
  const theme = useTheme();

  useEffect(() => {
    getKBCheatsheetByApartment(selectedApartment).then(({ data }) => {
      setCheatsheetData(data?.result);
      setUpdatedCheatSheetData(data?.result);
    });
  }, [selectedApartment]);

  const updateKelseyDatabase = async index => {
    //TODO: apply optional chaining wherever var can be undefined
    const selectedAns = updatedCheatSheetData.find(
      (data, index) => index === showSelectedAnswer
    );
    if (lastIncomingMessages[0]?.id && apartmentId) {
      const body = {
        lead_id: leadId,
        apartment_id: apartmentId,
        msg_id: lastIncomingMessages[0]?.id,
        search_string: searchStr || selectedAns?.question,
        lead_query: lastIncomingMessages?.[0]?.isOutGoing
          ? []
          : lastIncomingMessages.map(({ text }) => text),
        QnA: {
          Q1: selectedAns?.question,
          A1: selectedAns?.text
        }
      };
      setIsLoading(true);
      const res = await postKelsey(Path.KELSEY_CHEATSHEET_URL, body);
      setIsLoading(false);
      if (res?.data) {
        setIsCheatSheetUsed(res?.data?.data?._id?.$oid);
        setMessageInput(res?.data?.data?.kelsey_response);
      } else {
        setMessageInput(selectedAns?.text);
      }
    } else {
      setMessageInput(selectedAns?.text);
    }
  };

  const handleSearchCheat = e => {
    const searchValue = e.target.value;
    setSearchStr(e.target.value);
    const searchResults = cheatSheetData.length
      ? searchValue
        ? cheatSheetData?.filter(({ question }) =>
            question?.toLowerCase().includes(searchValue.toLowerCase())
          )
        : cheatSheetData
      : [];
    setUpdatedCheatSheetData([...searchResults]);
    if (lastIncomingMessages[0]?._id) {
      aiKnowledgeUpdates(e.target.value);
    }
  };

  return (
    <RightSideCards className="right-side-cards">
      <div
        style={{
          marginBottom: '10px',
          position: 'relative'
        }}
        className="d-flex-row flex"
      >
        <Typography
          className="card-title "
          style={{ color: theme?.text?.heading }}
        >
          Cheat Sheet
        </Typography>
        {isLoading ? <div className="loader"></div> : null}
      </div>
      <FormControl className="custom-menu-dropdown" fullWidth focused>
        <Autocomplete
          disabled={false}
          disableClearable
          disablePortal
          options={apartmentData?.map(({ _id, name }) => {
            return {
              _id,
              name
            };
          })}
          className="custom-autocomplete"
          getOptionLabel={option => option?.name}
          onChange={(e, { _id }) => {
            setSelectedApartment(_id);
          }}
          sx={{
            paddingBottom: '10px',
            '&.MuiAutocomplete-option': {
              fontWeight: 'regular',
              fontSize: 12,
              lineHeight: 14
            }
          }}
          key={uniqueId}
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: '12px', fontWeight: '400' }}>
              {option?.name}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              className="custom-property-dropdown"
              placeholder="Select community"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': {
                    border: `1px solid ${theme?.border?.primary}`
                  }
                },
                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                  color: theme?.text?.primary
                }
              }}
              variant="outlined"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <ZTextField
          darkMode={darkMode}
          style={{ background: darkMode ? '#1D202D' : '' }}
          handleChange={handleSearchCheat}
        />
      </FormControl>
      {updatedCheatSheetData.length ? (
        updatedCheatSheetData?.map(({ question, text, q_id }, index) => {
          return (
            <CheatSheetQuestion
              onClick={() => setShowSelectedAnswer(index)}
              key={q_id}
              className={`cursor-pointer ${
                showSelectedAnswer === index ? 'active-question' : ''
              }`}
            >
              {question}
              {showSelectedAnswer === index ? (
                <div className="flex" style={{ marginTop: '6px' }}>
                  <CheatSheetTag key={text} className="answer-text-style">
                    Answer: {' ' + text}
                  </CheatSheetTag>
                  <AddCircleIcon
                    onClick={() => {
                      updateKelseyDatabase(index);
                    }}
                    sx={{
                      color: '#42526E',
                      fontSize: '16'
                    }}
                  />
                </div>
              ) : null}
            </CheatSheetQuestion>
          );
        })
      ) : (
        <p
          style={{
            fontSize: '14px',
            color: theme?.text?.primary,
            margin: '10px 0 0 0'
          }}
        >
          Uh-oh! Nothing to show here.
        </p>
      )}
    </RightSideCards>
  );
};

export default CheatQuestions;
