import Button from '@material-ui/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Component/layout';
import {
  changePassword,
  UpdateAgentProfileAction
} from '../../Redux/Actions/AgentProfile';
import { identity, isEmpty, pickBy } from 'lodash';
import { formatPhoneNumber } from '../../utils';
import { Alert, Snackbar } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { connect, useDispatch } from 'react-redux';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Client from './clients';
import './AgentProfileStyles.css';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';

const AgentProfile = props => {
  const dispatch = useDispatch();

  const { userData } = useContext(GlobalContext);

  const [editEmail, setEditEmail] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editMightyCall, setEditMightyCall] = useState(false);
  const [newMightyCall, setNewMightyCall] = useState('');
  const [name, setName] = useState();
  const [newEmail, setNewEmail] = useState();
  const [newPhone, setNewPhone] = useState();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const SendFeed = {
    width: '185px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#023e8a',
    color: '#fff'
  };
  const Cancel = {
    width: '185px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #023e8a',
    backgroundColor: '#ffffff',
    marginRight: '20px'
  };
  const pwdText = {
    paddingBottom: '10px',
    fontFamily: 'Inter',
    fontSize: '16px'
  };
  const pwdInput = {
    paddingBottom: '30px',
    width: '24rem'
  };

  useEffect(() => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }, []);

  const saveAdminProfile = (email, id) => {
    if (!oldPassword) {
      return alert('please enter old password ');
    }
    if (!newPassword) {
      return alert('please enter new password ');
    }
    if (!confirmPassword) {
      return alert('please enter confirm password ');
    }

    if (newPassword !== confirmPassword) {
      return alert('new password and confirm passwords are not matching');
    }
    return dispatch(changePassword({ oldPassword, newPassword, email }));
  };
  const handleUpdateProfile = () => {
    let obj = {
      name: name,
      user_id: userData?.uid,
      email: newEmail,
      user_metadata: {
        mightyCall: newMightyCall,
        phone: newPhone
      }
    };
    const data = pickBy(obj, identity);
    if (!isEmpty(data)) {
      dispatch(UpdateAgentProfileAction(data));
      setOpen(true);
    }
  };

  const handleEditEmail = () => {
    setEditEmail(true);
  };
  const handleEditPhone = () => {
    setEditPhone(true);
  };
  const handleEditName = () => {
    setEditName(true);
  };
  const handleEditMightyCall = () => {
    setEditMightyCall(true);
  };

  const role = userData?.role?.name;
  const email = userData?.email;
  const firstName = userData?.name;
  const phone = userData?.phone;
  const user_id = userData?.uid;
  const mightyCall = userData?.mightyCall;

  return (
    <Layout history={props.history}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={'success'}>{'Data saved successfully'}</Alert>
      </Snackbar>
      <div class="container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '250px'
          }}
        >
          <div className="profileImage">
            <div className="profile">Profile</div>
            <div className="fullimage">
              <div className="demoimage">image</div>
              <div className="addPhoto">Add Photo</div>
            </div>
          </div>
          <div style={{ padding: '30px' }}>
            <Button
              variant="contained"
              style={{ ...SendFeed, float: 'right' }}
              onClick={handleUpdateProfile}
            >
              Update
            </Button>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            height: '100%',
            padding: '15px'
          }}
        >
          <div className="profileInformation">
            <div className="agentSpace">
              <div className="agentText">Full Name</div>
              <div style={{ marginTop: '10px' }}>
                {!editName ? (
                  firstName ? (
                    <div style={{ display: 'flex' }}>
                      <div style={{ textTransform: 'uppercase' }}>
                        {firstName}
                      </div>
                      <EditTwoToneIcon
                        color="disabled"
                        onClick={handleEditName}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <input
                        className="agentInput"
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ display: 'flex' }}>
                    <input
                      className="agentInput"
                      defaultValue={firstName}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="agentSpace">
              <div className="agentText" style={{ marginTop: '15px' }}>
                Phone Number
              </div>
              <div style={{ marginTop: '10px' }}>
                {!editPhone ? (
                  phone ? (
                    <div style={{ display: 'flex' }}>
                      <div> {phone} </div>
                      <EditTwoToneIcon
                        color="disabled"
                        onClick={handleEditPhone}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <input
                        className="agentInput"
                        onChange={e => setNewPhone(e.target.value)}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ display: 'flex' }}>
                    <input
                      className="agentInput"
                      defaultValue={phone}
                      onChange={e => setNewPhone(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="agentSpace">
              <div className="agentText" style={{ marginTop: '15px' }}>
                Email
              </div>
              {!editEmail ? (
                email ? (
                  <div style={{ display: 'flex' }}>
                    <div> {email} </div>
                    <EditTwoToneIcon
                      color="disabled"
                      onClick={handleEditEmail}
                    />
                  </div>
                ) : (
                  <div style={{ display: 'flex' }}>
                    <input
                      className="agentInput"
                      onChange={e => setNewEmail(e.target.value)}
                    />
                  </div>
                )
              ) : (
                <div style={{ display: 'flex' }}>
                  <input
                    className="agentInput"
                    defaultValue={email}
                    onChange={e => setNewEmail(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="agentSpace">
              <div className="agentText" style={{ marginTop: '15px' }}>
                Role
              </div>
              <div style={{ marginTop: '5px' }}>{role}</div>
            </div>
            <div className="agentSpace">
              <div className="agentText" style={{ marginTop: '15px' }}>
                Mighty Call
              </div>
              <div style={{ marginTop: '10px' }}>
                {!editMightyCall ? (
                  mightyCall ? (
                    <div style={{ display: 'flex' }}>
                      <div>+1 {formatPhoneNumber(mightyCall)} </div>
                      <EditTwoToneIcon
                        color="disabled"
                        onClick={handleEditMightyCall}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <input
                        className="agentInput"
                        onChange={e => setNewMightyCall(e.target.value)}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ display: 'flex' }}>
                    <input
                      className="agentInput"
                      defaultValue={mightyCall}
                      onChange={e => setNewMightyCall(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="assignClients">
            Clients
            <Client />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    data: state
  };
};
export default connect(mapStateToProps)(AgentProfile);
