import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextEditor from './TextEditor';
import { useDispatch, connect } from 'react-redux';
import { createStepAction } from '../../../Redux/Actions/workflow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const EmailModal = props => {
  //StateBlock
  const [Name, setName] = useState(null);
  const [Subject, setSubject] = useState(null);
  const [Message, setMessage] = useState(null);
  const [Title, setTitle] = useState(null);
  const [valid, setvalid] = useState(false);
  const dispatch = useDispatch();

  //popper
  const [Thread, setThread] = useState('New');

  const cardStyle = {
    width: '700px',
    height: '500px',
    overflowY: 'auto'
  };
  const BtnContainer = {
    marginTop: '16px',
    textAlign: 'right'
  };
  const Cancel = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #023e8a',
    backgroundColor: '#ffffff',
    marginRight: '20px'
  };
  const saveTemp = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff',
    marginRight: '20px'
  };
  const btnTemp = {
    width: '130px',
    height: '40px',
    fontSize: '11px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff'
    // marginLeft: "15px",
  };
  const heading = {
    color: '#20065f',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
    marginBottom: '40px'
  };
  const subHeading = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    marginBottom: '8px'
  };
  const TextFm = {
    marginBottom: '20px'
  };

  //funcBlock
  const CancelThis = () => {
    props.setOpen(false);
    setName(null);
    setSubject(null);
    setMessage(null);
    setTitle(null);
    props.handleClose();
  };
  const setThreadfun = e => {
    setThread(e.target.value);
  };
  const ContinueThis = () => {
    if (
      Title !== null &&
      Title !== '' &&
      Name !== null &&
      Name !== '' &&
      Message !== null &&
      Message !== '' &&
      Subject !== null &&
      Subject !== ''
    ) {
      let o = {};
      o.title = Title;
      o.type = 'Email';
      o.sequenceId = props.paramid;
      o.emailSchema = {
        type: Thread,
        message: Message,
        subject: Subject,
        cc: Name
      };
      dispatch(createStepAction(o));
      setName(null);
      setSubject(null);
      setMessage(null);
      setTitle(null);
      props.setOpen(false);
      props.handleClose();
    } else {
      setvalid(true);
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card style={cardStyle}>
          <CardContent>
            <div>
              <div>
                <div>
                  <div style={heading}>
                    Email
                    <span>
                      <ClearIcon
                        onClick={() => CancelThis()}
                        style={{
                          cursor: 'pointer',
                          float: 'right'
                        }}
                      />
                    </span>
                  </div>

                  <hr
                    style={{
                      marginBottom: '20px',
                      marginTop: '-26px',
                      width: '703px',
                      marginLeft: '-16px'
                    }}
                  />
                  <div>
                    <div style={subHeading}>Title</div>
                    <div style={TextFm}>
                      <TextField
                        id="Title"
                        variant="outlined"
                        fullWidth
                        error={valid && (Title === '' || Title === null)}
                        value={Title}
                        placeholder="Subject"
                        onChange={e => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div style={subHeading}>Type</div>
                    <div style={TextFm}>
                      <FormControl>
                        <Select
                          native
                          value={Thread}
                          onChange={e => setThreadfun(e)}
                          inputProps={{
                            Thread: 'thread',
                            id: 'filled-age-native-simple'
                          }}
                        >
                          <option selected value="New">
                            NewThread
                          </option>
                          <option value="Old">Old Thread</option>
                        </Select>
                      </FormControl>
                      <TextField
                        id="Template Name"
                        variant="outlined"
                        fullWidth
                        error={valid && (Name === '' || Name === null)}
                        value={Name}
                        placeholder=""
                        onChange={e => setName(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">CC</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div style={subHeading}>Subject</div>
                    <div style={TextFm}>
                      <TextField
                        id="Subject"
                        variant="outlined"
                        fullWidth
                        error={valid && (Subject === '' || Subject === null)}
                        value={Subject}
                        placeholder="Subject"
                        onChange={e => setSubject(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <div style={subHeading}>Message</div>
                    <div style={TextFm}>
                      {/* <TextField
                        id="Message"
                        variant="outlined"
                        placeholder="Start typing your message"
                        fullWidth
                        multiline
                        error={valid && Message === null}
                        value={Message}
                        rows={4}
                        onChange={(e) => setMessage(e.target.value)}
                      /> */}
                      <TextEditor
                        custStyle={{ minHeight: '240px' }}
                        setMessage={setMessage}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-21px ' }}>
                  <div
                    style={{
                      marginRight: '45px',
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 16,
                      fontWeight: 600,
                      cursor: 'pointer'
                    }}
                  >
                    Templates
                  </div>
                  <div
                    style={{
                      color: '#525f80',
                      fontFamily: 'Inter',
                      fontSize: 16,
                      fontWeight: 600,
                      cursor: 'pointer'
                    }}
                  >
                    Save as Templates
                  </div>
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={saveTemp}
                    onClick={() => ContinueThis()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

export default EmailModal;
