import React from 'react';

const Client = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27 253H273.291"
          stroke="#42526E"
          stroke-width="13.1161"
          stroke-linecap="round"
        />
        <path
          d="M109.502 244.024V110.109C109.502 109.036 110.372 108.166 111.445 108.166H191.516C192.589 108.166 193.459 109.036 193.459 110.109V200.84"
          stroke="#42526E"
          stroke-width="9.22986"
          stroke-linecap="round"
        />
        <path
          d="M134.737 142.373H168.951"
          stroke="#42526E"
          stroke-width="13.1161"
          stroke-linecap="round"
        />
        <path
          d="M134.252 179.734H168.466"
          stroke="#42526E"
          stroke-width="13.1161"
          stroke-linecap="round"
        />
        <path
          d="M134.252 215.639H168.466"
          stroke="#42526E"
          stroke-width="13.1161"
          stroke-linecap="round"
        />
        <path
          d="M193.459 223.888L193.459 220.006"
          stroke="#42526E"
          stroke-width="9.22986"
          stroke-linecap="round"
        />
        <path
          d="M206.562 247.905V177.122C206.562 176.555 207.022 176.095 207.589 176.095H258.121C258.688 176.095 259.148 176.555 259.148 177.122V225.08"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M224.262 194.176H242.346"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M224.005 213.923H242.09"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M224.005 232.902H242.09"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M259.148 237.262L259.148 235.21"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M43.9856 244.024V173.24C43.9856 172.673 44.4454 172.213 45.0127 172.213H95.5446C96.1118 172.213 96.5717 172.673 96.5717 173.24V221.198"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M61.6853 190.294H79.7698"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M61.4287 210.042H79.5132"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M61.4287 229.02H79.5132"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M96.5717 233.38L96.5717 231.329"
          stroke="#42526E"
          stroke-width="6.9328"
          stroke-linecap="round"
        />
        <path
          d="M154.854 58.852L158.429 69.8526C159.275 72.4544 161.699 74.2159 164.435 74.2159H176.003C176.177 74.2159 176.254 74.263 176.296 74.2956C176.356 74.342 176.424 74.4274 176.465 74.5516C176.505 74.6758 176.5 74.7851 176.479 74.8578C176.464 74.909 176.429 74.9922 176.288 75.0948L166.931 81.8918C164.717 83.4999 163.791 86.3508 164.637 88.9531L168.211 99.9518C168.265 100.118 168.244 100.205 168.226 100.255C168.2 100.327 168.14 100.418 168.034 100.495C167.929 100.572 167.823 100.601 167.748 100.603C167.694 100.605 167.605 100.598 167.463 100.495L158.104 93.696C155.89 92.0884 152.894 92.0884 150.681 93.696L141.321 100.495C141.18 100.598 141.09 100.605 141.037 100.603C140.961 100.601 140.855 100.572 140.75 100.495C140.644 100.418 140.584 100.327 140.558 100.255C140.54 100.205 140.519 100.118 140.573 99.9518L144.148 88.9531C144.994 86.3508 144.067 83.4999 141.853 81.8918L132.496 75.0948C132.355 74.9922 132.32 74.909 132.306 74.8578C132.284 74.7851 132.279 74.6758 132.32 74.5516C132.36 74.4274 132.428 74.342 132.488 74.2956C132.53 74.263 132.607 74.2159 132.782 74.2159H144.349C147.085 74.2159 149.51 72.4544 150.355 69.8526L153.93 58.852C153.984 58.6861 154.053 58.6276 154.097 58.5976C154.159 58.5549 154.262 58.5164 154.392 58.5164C154.523 58.5164 154.625 58.5549 154.688 58.5976C154.732 58.6276 154.8 58.6861 154.854 58.852Z"
          stroke="#42526E"
          stroke-width="5.82938"
        />
      </svg>
    </div>
  );
};

export default Client;
