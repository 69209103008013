import React from 'react';
import {
  CrossIconWrapper,
  ModalHeaderWrapper,
  ModalIcons,
  ModalTitle
} from '../../Popup/popup.styles';
import Icon from '../../Icon';
import { useTheme } from 'styled-components';

const PopupTitle = ({
  children,
  onClose,
  color,
  bgColor,
  padding,
  fontSize,
  showCloseBtn = true,
  borderRadius = '8px 8px 0 0',
  showAddButton = false,
  onAddClick,
  showDefaultCloseIcon = false
}) => {
  const theme = useTheme();
  return (
    <ModalHeaderWrapper bgColor={bgColor} borderRadius={borderRadius}>
      <ModalTitle color={color} padding={padding} fontSize={fontSize}>
        {children}
        <ModalIcons>
          {showAddButton ? (
            <Icon
              onClick={onAddClick}
              src={`add-${theme?.state}.svg`}
              alt="add"
            />
          ) : null}
          {showCloseBtn &&
            (showDefaultCloseIcon ? (
              <Icon src="cross-dark.svg" alt="close" onClick={onClose} />
            ) : (
              <Icon
                src={`cross-${theme?.state}.svg`}
                onClick={onClose}
                alt="close"
                imgStyle={{ marginRight: padding !== '0px' ? 0 : '12' }}
              />
            ))}
        </ModalIcons>
      </ModalTitle>
    </ModalHeaderWrapper>
  );
};

export default PopupTitle;
