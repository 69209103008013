import React, { useContext } from 'react';
import { getCookie } from '../../utils';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import Layout from '../../Component/layout';
import Retool from 'react-retool';

const Leads = props => {
  const { userData } = useContext(GlobalContext);
  const retoolLeadsPage = process.env.REACT_APP_RETOOL_LEADS;
  const authorization = `${getCookie('rb_access_token')}`;
  const tenantsByUser = userData?.clients;
  return (
    <Layout history={props.history}>
      <Retool
        url={retoolLeadsPage}
        data={{
          authorization,
          tenantsByUser
        }}
        height="700px"
        styling={{ backgroundColor: '#F8F8F8' }}
      />
    </Layout>
  );
};

export default Leads;
