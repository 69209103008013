import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modalHeader: {
    color: '#20065f'
  },
  subHeading: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 8
  },
  inputRoot: {
    '&.MuiFormControl-root': {
      marginBottom: 20
    }
  },
  buttonContainer: {
    display: 'flex',
    rowGap: 10,
    columnGap: 34,
    flexWrap: 'wrap',
    marginBottom: 20
  },
  templateButton: {
    '&.MuiButton-root': {
      backgroundColor: '#20065f',
      fontSize: 10
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#20065f'
    },
    width: 175,
    height: 32,
    borderRadius: 8
  },
  tabStyle: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
      backgroundColor: 'transparent'
    }
  },
  formButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: '8px 34px',
      color: '#fff',
      background: '#20065F'
    },
    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
      background: 'grey'
    },
    float: 'right',
    borderRadius: 4
  },
  backButton: {
    marginRight: 18,
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: '8px 34px',
      color: '#20065F',
      border: '1px #20065F solid',
      marginRight: '18px'
    },
    borderRadius: 4
  }
}));
