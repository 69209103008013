import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  BtnContainer,
  Cancel,
  FontStyle,
  DeleteButton
} from '../../styles/Inboxstyle';
import {
  deleteWorkflow,
  fetchAllWorkflows
} from '../../Redux/Actions/WorkflowV1';

function DeletePopUp({ id, open, setOpen }) {
  const dispatch = useDispatch();

  const CancelThis = () => {
    setOpen(false);
  };
  const ContinueThis = () => {
    dispatch(deleteWorkflow({ id }));
    setOpen(false);
    dispatch(fetchAllWorkflows());
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={FontStyle}>Are you sure you want to delete ?</div>
                  <div
                    style={{
                      marginLeft: '22.3%',
                      position: 'fixed',
                      marginTop: '-10px'
                    }}
                  >
                    <CloseIcon onClick={() => CancelThis()} />
                  </div>
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    style={DeleteButton}
                    onClick={() => ContinueThis()}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default DeletePopUp;
