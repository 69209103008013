import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, connect } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Tooltip } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import NewTeplate from './newRules/RuleStage/modal/NewTeplate';
import { tableStyle } from '../../styles/Workflowstyle';
import { getMessageTemplates } from '../../gql/queries/messageTemplate';
import { deleteMessageTemplateById } from '../../gql/mutations/messageTemplate';
import { ToastFireAction } from '../../Redux/Actions/Toast';
import { sortedClients } from './Wutils';
import Loader from '../../Component/Loader';

const MtTable = props => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [DataEdit, setDataEdit] = useState('');
  const [loading, setLoading] = useState(false);

  const [fetchMessageTemplates, { data: messageTemplateData }] = useLazyQuery(
    getMessageTemplates,
    {
      variables: {
        filters: {
          clients: []
        }
      },
      onCompleted: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
        dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
      }
    }
  );

  const [deleteTemplate] = useMutation(deleteMessageTemplateById, {
    refetchQueries: [
      {
        query: getMessageTemplates,
        variables: {
          filters: {
            clients: []
          }
        }
      }
    ],
    onCompleted: () => {
      dispatch(ToastFireAction(true, 'success', 'Template Deleted'));
    },
    onError: () => {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });

  const columnObj = [
    { title: 'Name', field: 'Name' },
    {
      title: 'Clients',
      field: 'Clients',
      render: rowData => (
        <span>
          {rowData.Clients.length === 1 ? (
            <span>{rowData.Clients[0].name}</span>
          ) : null}
          {rowData.Clients.length === 2 ? (
            <span>
              {rowData.Clients[0].name} | {rowData.Clients[1].name}{' '}
            </span>
          ) : null}
          {rowData.Clients.length > 2 ? (
            <Tooltip
              title={rowData?.Clients?.map((client, i) => {
                return (
                  <span key={i}>
                    {client.name}{' '}
                    {rowData.Clients.length === i + 1 ? null : '|'}{' '}
                  </span>
                );
              })}
              placement="right-start"
            >
              <span>
                {rowData.Clients[0].name} | {rowData.Clients[1].name} |{' '}
                {rowData.Clients.length - 2}{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    },
    {
      title: 'Message',
      field: 'Message',
      render: rowData => <span id={rowData._id}>{rowData.Message}</span>
    }
  ];

  useEffect(() => {
    setLoading(true);
    fetchMessageTemplates();
  }, []);

  useEffect(() => {
    if (messageTemplateData && messageTemplateData.getMessageTemplates) {
      let newData = messageTemplateData.getMessageTemplates.map(template => {
        return {
          _id: template._id,
          Name: template.name,
          Subject: template.subject,
          Message: template.body,
          Clients: sortedClients(template.clients)
        };
      });

      setTableData(newData);
    }
  }, [messageTemplateData]);

  //functionBlock
  const EditFunc = rowData => {
    setOpen(true);
    setDataEdit(rowData);
  };
  return loading ? (
    <div style={{ height: '100px', width: '100px', margin: '0 auto' }}>
      <Loader />
    </div>
  ) : (
    <div>
      <NewTeplate
        open={open}
        setOpen={setOpen}
        DataEdit={DataEdit}
        status={'edit'}
      />
      <MaterialTable
        style={tableStyle}
        title=""
        columns={columnObj}
        data={tableData}
        totalCount={tableData.length}
        actions={[
          {
            icon: () => <EditIcon />,
            tooltip: 'Edit',
            onClick: (event, rowData) => EditFunc(rowData)
          }
        ]}
        editable={{
          onRowDelete: newData =>
            new Promise(resolve => {
              deleteTemplate({
                variables: {
                  id: newData._id
                }
              });
              setLoading(true);
              resolve();
            })
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 30, 50, 75, 100, 200],
          toolbar: false,
          paging: false,
          page: 1,
          search: true,
          actionsColumnIndex: -1,
          totalCount: tableData.length,
          headerStyle: {
            color: '#20065f',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600
          },
          rowStyle: {
            color: '#393939',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400
          }
        }}
      />
    </div>
  );
};
const mapStateToProps = state => ({
  TemplateData: state.Workflow.msgTemplate
});

export default connect(mapStateToProps)(MtTable);
