import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: ${({ overlayPosition }) => overlayPosition};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ overlayColor }) => overlayColor};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '1110')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ overlayRadius }) => overlayRadius};
`;
const CustomModalWrapper = styled.div`
  position: ${({ overlayPosition }) => overlayPosition};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  width: 100vw;
  height: 100vh;
  background-color: ${({ overlayColor }) => overlayColor};
  z-index: 1110;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ overlayRadius }) => overlayRadius};
`;
const ModalContent = styled.div`
  width: ${({ width }) => (width ? `${width}` : ' 600px')};
  position: ${({ position }) => position === 'absolute' && position};
  top: ${({ position, top }) => position && (top ?? '')};
  left: ${({ position, left }) => position && (left ?? '')};
  min-height: ${({ minHeight }) => minHeight ?? ''};
  max-height: ${({ maxHeight }) => maxHeight ?? ''};
  background-color: ${({ theme }) => theme?.background?.secondary || '#fff'};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin-top: 0px;
  padding-bottom: ${({ minHeight }) => (minHeight ? '1.25rem' : 0)};
  align-items: space-between;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-family: Inter, sans-serif;
  box-shadow: ${({ boxShadow }) => boxShadow};
`;
const ModalHeaderWrapper = styled.div`
  margin: 0;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme?.background?.secondary || '#fff'};
`;

const ModalTitle = styled.div`
  padding: ${({ padding }) => (padding ? `${padding}px` : '20px')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '1rem')};
  /* line-height: 29px; */
  font-weight: 500;
  color: ${({ color, theme }) =>
    color ? color : theme?.text?.heading || '#fff'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalSubTitle = styled.div`
  padding: ${({ padding }) => (padding ? `${padding}px` : '10px')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '1rem')};
  /* line-height: 29px; */
  font-weight: 500;
  color: ${({ color, theme }) =>
    color ? color : theme?.text?.heading || '#fff'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalIcons = styled.div`
  img {
    cursor: pointer;
    margin-right: 12px;
  }
`;

const CrossIconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background: ${({ theme }) =>
    theme?.state === 'dark' ? 'transparent' : '#fff'};
  border-radius: 50%;
`;
const ModalBodyWrapper = styled.div`
  padding: ${({ padding }) => padding ?? '0px 20px'};
  border-radius: 8px;
  position: relative;
`;

const ModalFooter = styled.div`
  display: flex;
  position: relative;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 0.75rem;
  padding: ${({ padding }) => padding};
`;
export {
  ModalWrapper,
  ModalContent,
  ModalHeaderWrapper,
  ModalTitle,
  ModalBodyWrapper,
  ModalFooter,
  ModalIcons,
  CrossIconWrapper,
  CustomModalWrapper
};
