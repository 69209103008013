import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BuildingAdd from './BuildingAdd';
import { BuildingByIdAction } from '../../Redux/Actions/Apartment';

const BuildingEdit = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(BuildingByIdAction(props.match.params.id));
  }, [props.match.params.id]);
  return (
    <div>
      <BuildingAdd
        buildingType={'EDIT'}
        history={props.history}
        PropertyObj={props.buildingObj}
        uniqueId={props.match.params.id}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  buildingObj: state.Apartment.ApartmentID
});
export default connect(mapStateToProps)(BuildingEdit);
