import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { AddWorkflowAction } from '../../../../../Redux/Actions/workflow';
import { ToastFireAction } from '../../../../../Redux/Actions/Toast';
import ClientsMultiSelect from '../../../../../Component/ClientsMultiSelect';
import { GlobalContext } from '../../../../../utils/ContextProviders/GlobalContextProvider';
import {
  createMessageTemplate,
  updateMessageTemplateById
} from '../../../../../gql/mutations/messageTemplate';
import { getMessageTemplates } from '../../../../../gql/queries/messageTemplate';
import { sortedClients } from '../../../Wutils';

function NewTeplate(props) {
  const dispatch = useDispatch();

  //StateBlock
  const [Name, setName] = useState(null);
  const [Message, setMessage] = useState('');
  const [Clients, setClients] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const { userData } = useContext(GlobalContext);

  const cardStyle = {
    width: '700px'
  };
  const BtnContainer = {
    marginTop: '30px',
    textAlign: 'center'
  };
  const Cancel = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #023e8a',
    backgroundColor: '#ffffff',
    marginRight: '20px'
  };
  const saveTemp = {
    width: '150px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff',
    marginRight: '20px'
  };
  const btnTemp = {
    width: '175px',
    height: '32px',
    fontSize: '10px',
    borderRadius: '8px',
    backgroundColor: '#20065f',
    color: '#fff'
    // marginLeft: "15px",
  };
  const heading = {
    color: '#20065f',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
    marginBottom: '20px'
  };
  const subHeading = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    marginBottom: '8px'
  };
  const TextFm = {
    marginBottom: '20px'
  };

  const [createTemplate] = useMutation(createMessageTemplate, {
    refetchQueries: [
      {
        query: getMessageTemplates,
        variables: {
          filters: {
            clients: []
          }
        }
      }
    ],
    onCompleted: () => {
      dispatch(ToastFireAction(true, 'success', 'Template Created'));
    },
    onError: () => {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });

  const [updateTemplate] = useMutation(updateMessageTemplateById, {
    refetchQueries: [
      {
        query: getMessageTemplates,
        variables: {
          filters: {
            clients: []
          }
        }
      }
    ],
    onCompleted: () => {
      dispatch(ToastFireAction(true, 'success', 'Template Updated'));
    },
    onError: () => {
      dispatch(ToastFireAction(true, 'error', 'Something Went Wrong'));
    }
  });

  //funcBlock
  const CancelThis = () => {
    props.setOpen(false);
    dispatch(AddWorkflowAction(false));
  };

  const ContinueThis = () => {
    if (
      Name !== null &&
      Clients !== null &&
      Clients.length > 0 &&
      Message !== null &&
      Message !== '' &&
      Message !== undefined &&
      Message.match(/\S/)
    ) {
      const idsArray = Clients.map(({ _id }) => {
        return { _id };
      });
      let record = {
        name: Name,
        body: Message.trim(),
        clients: idsArray
      };

      if (props.status === 'edit') {
        updateTemplate({
          variables: {
            record: {
              _id: props.DataEdit._id,
              ...record
            }
          }
        });
      } else {
        createTemplate({
          variables: {
            record: {
              ...record
            }
          }
        });
      }
      props.setOpen(false);
      dispatch(AddWorkflowAction(false));

      setName('');
      setMessage('');
      setClients([]);
    } else {
      setInvalid(true);
    }
  };
  const addVariable = Variable => {
    let Temp = Message + ' ' + '{' + Variable + '}';
    setMessage(Temp);
  };

  useEffect(() => {
    if (props.status === 'edit') {
      setName(props.DataEdit.Name);
      setMessage(props.DataEdit.Message);
      setClients(sortedClients(props.DataEdit.Clients));
    }
  }, [props.DataEdit]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card style={cardStyle}>
          <CardContent>
            <div>
              <div>
                <div>
                  <div style={heading}>Add New Template</div>
                  <div>
                    <div style={subHeading}>
                      Clients <p style={{ color: '#FF4D00', margin: 0 }}>*</p>
                    </div>
                    <div style={TextFm}>
                      <ClientsMultiSelect
                        {...{
                          allClients: sortedClients(userData?.clients),
                          selectedClients: Clients,
                          setSelectedClients: setClients,
                          invalid: invalid,
                          searchEnabled: true
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: '100%' }}>
                      <div style={subHeading}>
                        Template Name
                        <p style={{ color: '#FF4D00', margin: 0 }}>*</p>
                      </div>
                      <div style={TextFm}>
                        <TextField
                          id="Template Name"
                          variant="outlined"
                          fullWidth
                          error={invalid && Name === null}
                          value={Name ?? ''}
                          placeholder="Template Name"
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...TextFm,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('firstName')}
                    >
                      First Name
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('email')}
                    >
                      Email
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('propertyName')}
                    >
                      Property Name
                    </Button>
                  </div>
                  <div
                    style={{
                      ...TextFm,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('phoneNumber')}
                    >
                      Phone Number
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('link')}
                    >
                      Link
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('tourDate')}
                    >
                      Tour Date
                    </Button>
                  </div>
                  <div
                    style={{
                      ...TextFm,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('tourTime')}
                    >
                      Tour Time
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('tourAgentPhone')}
                    >
                      Tour Agent Phone
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('assignedAgent')}
                    >
                      Assigned Agent
                    </Button>
                  </div>
                  <div
                    style={{
                      ...TextFm,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('propertyAddress')}
                    >
                      Property Address
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('planName')}
                    >
                      Plan Name
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('price')}
                    >
                      Price
                    </Button>
                  </div>
                  <div
                    style={{
                      ...TextFm,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('officeDirections')}
                    >
                      Leasing Office Directions
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('websiteLink')}
                    >
                      Property Website Link
                    </Button>
                    <Button
                      variant="contained"
                      style={btnTemp}
                      onClick={() => addVariable('applicationDirections')}
                    >
                      Application Directions
                    </Button>
                  </div>
                  <div>
                    <div style={subHeading}>
                      Template Message
                      <p style={{ color: '#FF4D00', margin: 0 }}>*</p>
                    </div>
                    <div style={TextFm}>
                      <TextField
                        id="Template Message"
                        variant="outlined"
                        placeholder="Start typing your template"
                        fullWidth
                        multiline
                        error={invalid && (Message === null || Message === '')}
                        value={Message ?? ''}
                        minRows={4}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={saveTemp}
                    onClick={() => {
                      ContinueThis();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default NewTeplate;
