import React from 'react';
import CrossIcon from '../../../Media/workflow/CrossIcon';

const GifContainer = ({ gif, onCancel }) => {
  return (
    <div style={{ position: 'relative' }}>
      <img
        style={{
          width: '100%',
          height: '100%'
        }}
        src={gif}
        alt="gif"
      />
      <div
        style={{
          position: 'absolute',
          top: '-9px',
          right: '-9px',
          height: '18px',
          width: '18px',
          borderRadius: '50%',
          backgroundColor: '#FFFFFF',
          cursor: 'pointer'
        }}
        onClick={onCancel}
      >
        <CrossIcon />
      </div>
    </div>
  );
};

export default GifContainer;
