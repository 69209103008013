import { gql } from '@apollo/client';

export const createSourceApartment = gql`
  mutation apartmentAddCustomSource($aptId: ID!, $record: JSON!) {
    apartmentAddCustomSource(aptId: $aptId, record: $record) {
      _id
    }
  }
`;

export const deleteSource = gql`
  mutation deleteApartmentSource($source: String!, $aptId: ID!) {
    deleteApartmentSource(source: $source, aptId: $aptId) {
      _id
    }
  }
`;
