import React from 'react';

const SyncIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6875 6.56278C10.3125 8.43778 8.8988 10.2033 6.91505 10.5978C5.94754 10.7905 4.94391 10.673 4.04705 10.2621C3.15019 9.85117 2.40584 9.16778 1.91997 8.30922C1.4341 7.45066 1.23149 6.46069 1.34098 5.48028C1.45047 4.49987 1.86649 3.579 2.5298 2.84878C3.8903 1.35028 6.18755 0.937779 8.06255 1.68778"
        stroke="#50BE77"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.3125 5.8125L6.1875 7.6875L10.6875 2.8125"
        stroke="#50BE77"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SyncIcon;
