import { FullStory, init } from '@fullstory/browser';

const initFullStoryForUser = user => {
  init({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID });
  setUserIdentity(user);
  shutdownDataCapture();
};

const setUserIdentity = user => {
  FullStory('setIdentity', {
    uid: user._id,
    properties: {
      displayName: user.name,
      email: user.email,
      role: user.role
    }
  });
};

const shutdownDataCapture = () => {
  FullStory('shutdown');
};
const restartDataCapture = () => {
  FullStory('restart');
};

export { initFullStoryForUser, restartDataCapture, shutdownDataCapture };
