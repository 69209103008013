import React from 'react';
import {
  EmptyOuestionTextOne,
  EmptyOuestionTextTwo,
  EmptyQuestionContainer
} from '../qa-review.styles';

const QAErrorComponent = ({ text2, text1, icon, type, onClick }) => {
  const handleOnCLick = () => {
    if (onClick) {
      onClick(false);
    }
    return;
  };
  return (
    <EmptyQuestionContainer type={type}>
      {icon}
      {text1 ? <EmptyOuestionTextOne>{text1} </EmptyOuestionTextOne> : null}
      {text2 ? (
        <EmptyOuestionTextTwo
          isClickable={onClick ? true : false}
          onClick={handleOnCLick}
        >
          {text2}
        </EmptyOuestionTextTwo>
      ) : null}
    </EmptyQuestionContainer>
  );
};

export default QAErrorComponent;
