import Popup from '../../../globalcomponents/Popup';
import PopupBody from '../../../globalcomponents/PopupComponents/PopupBody';
import PopupTitle from '../../../globalcomponents/PopupComponents/PopupHeader';
import {
  DateWaitListWrapper,
  Labels,
  WTextArea
} from '../../../Component/LeadWaitList/LeadWaitList.styles';
import { useEffect, useState } from 'react';
import PopupFooter from '../../../globalcomponents/PopupComponents/PopupFooter';
import Button from '../../../globalcomponents/Button';
import { useMutation } from '@apollo/client';
import { createWaitList } from '../../../gql/mutations/waitList';
import { LeadInfoById } from '../../../gql/queries/leadInfo';
import dayjs from 'dayjs';
import {
  CommentBody,
  CommentOptions,
  CommentTitle,
  CommentContainer,
  CommentNum
} from '../../KelseyInsight/CommentModal/comment.styles';
import { Tooltip } from '@mui/material';
import { IconWrapper } from '../../KelseyInsight/Field/field.styles';
import Trash from '../../../Media/KelseyInsight/Trash';
import WaitListDeletePopup from './WaitListDeletePopup';
import DateInput from '../../../Component/DateInput';
import { WaitListWrapper } from './styles.waitlist';

const WaitlistPopup = ({ show, onClose, leadId, waitListExists, timeZone }) => {
  const [waitlistData, setWaitListData] = useState({});
  const [addWaitlist, setAddWaitlist] = useState(false);
  const [waitListDeleteable, setWaitListDeleteable] = useState(false);
  const handleDateChange = (e, type) => {
    type === 'date'
      ? setWaitListData({
          ...waitlistData,
          date: e
        })
      : setWaitListData({
          ...waitlistData,
          notes: e.target.value
        });
  };

  useEffect(() => {
    if (!show) {
      setAddWaitlist(false);
      handleEmptyWaitlistData();
      if (waitListDeleteable) setWaitListDeleteable(false);
    } else {
      setWaitListData({
        date: new Date()
      });
    }
  }, [show]);

  const [createWailist] = useMutation(createWaitList, {
    onCompleted() {
      onClose();
    },
    refetchQueries: [{ query: LeadInfoById, variables: { leadId } }]
  });

  const handleEmptyWaitlistData = () => {
    setWaitListData({});
  };

  const handleCancelDelete = () => {
    setWaitListDeleteable(false);
  };

  const handleDelete = date => {
    setWaitListDeleteable(true);
    setWaitListData({ date });
  };
  return show ? (
    <Popup
      width={'300px'}
      minHeight={'340px'}
      maxHeight={'340px'}
      show={show}
      onClose={onClose}
    >
      {waitListDeleteable ? (
        <WaitListDeletePopup
          {...{
            leadId,
            existingDate: waitlistData?.date,
            handleCancelDelete,
            handleEmptyWaitlistData
          }}
        />
      ) : (
        <>
          <PopupTitle
            onClose={onClose}
            padding={20}
            fontSize={14}
            showCloseBtn
            showAddButton={waitListExists?.length && !addWaitlist}
            onAddClick={() => setAddWaitlist(true)}
          >
            Waitlist
          </PopupTitle>
          <PopupBody>
            {waitListExists?.length && !addWaitlist ? (
              <WaitListWrapper>
                {waitListExists.map(({ notes, date }) => {
                  return (
                    <CommentContainer hideBullets key={date}>
                      <CommentTitle>
                        <CommentNum>
                          {dayjs(date, 'YYYY-MM-DD+h:mm').format(
                            'DD-MMM-YYYY, HH:mm A'
                          )}
                        </CommentNum>
                        <CommentOptions>
                          <Tooltip title="Delete" dialogLeft="-24px">
                            <IconWrapper onClick={() => handleDelete(date)}>
                              <Trash />
                            </IconWrapper>
                          </Tooltip>
                        </CommentOptions>
                      </CommentTitle>
                      <CommentBody>{notes}</CommentBody>
                    </CommentContainer>
                  );
                })}
              </WaitListWrapper>
            ) : (
              <>
                <Labels>
                  <label> Date and Time</label>
                </Labels>

                <DateWaitListWrapper>
                  <DateInput
                    selected={waitlistData?.date ?? new Date()}
                    showTimeSelect
                    onChange={date => handleDateChange(date, 'date')}
                    append
                    dateFormat=" dd-MMM-yyyy hh:mm aa"
                  />
                </DateWaitListWrapper>
                <Labels marginTop={'20px'}>
                  <label style={{ marginTop: '20px' }}> Reminder Notes</label>
                </Labels>
                <WTextArea
                  value={waitlistData?.notes ?? ''}
                  onChange={handleDateChange}
                  name="Reminder Notes"
                />
              </>
            )}
          </PopupBody>

          {waitListExists?.length && !addWaitlist ? null : (
            <PopupFooter>
              <Button
                onClick={() =>
                  createWailist({
                    variables: {
                      _id: leadId,
                      record: waitlistData
                    }
                  })
                }
                disabled={!waitlistData?.date || !waitlistData?.notes}
              >
                Save
              </Button>
              <Button onClick={handleEmptyWaitlistData} noStyle={true}>
                Clear
              </Button>
            </PopupFooter>
          )}
        </>
      )}
    </Popup>
  ) : null;
};

export default WaitlistPopup;
