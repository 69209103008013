import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  gError: [],
  snackbarVisible: false
};

const GlobalErrorSlice = createSlice({
  name: 'globalError',
  initialState,
  reducers: {
    setGlobalError: (state, action, type = 'warning') => {
      state.snackbarVisible = true;
      const updatedSnackbar = state.gError.filter(({ isRead }) => !isRead);
      state.gError = [
        ...updatedSnackbar,
        {
          message: action?.payload,
          type: type,
          isRead: false,
          id: `${new Date().getTime()}-warning`,
          autoHideDuration: null
        }
      ];
    },
    setSuccessInfo: (state, action, type = 'success') => {
      state.snackbarVisible = true;
      const updatedSnackbar = state.gError.filter(({ isRead }) => !isRead);
      state.gError = [
        ...updatedSnackbar,
        {
          message: action?.payload,
          type: type,
          isRead: false,
          id: `${new Date().getTime()}-success`,
          autoHideDuration: 2000
        }
      ];
    },
    setMessageReadFlag: (state, action, prevData) => {
      // const updateMessage = state.gError[action.payload]
      // updateMessage.isRead = true;
      let selectedSnack = JSON.parse(JSON.stringify(state.gError));

      const index = selectedSnack.findIndex(
        ({ id }, index) => action?.payload?.key === id
      );
      selectedSnack.splice(index, 1);
      state.gError = [...selectedSnack];
    }
  },
  extraReducers: {}
});

export const { setGlobalError, setMessageReadFlag, setSuccessInfo } =
  GlobalErrorSlice.actions;

export default GlobalErrorSlice;
