import styled, { keyframes } from 'styled-components';

const UserControlsHeader = styled.hgroup`
  padding: 10px 20px;
`;

const Username = styled.h3`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme?.text?.primary};
`;

const UserStatus = styled.div`
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  gap: 6px;
  align-items: center;
  color: ${({ theme }) => `${theme?.text?.primary}80`};
`;

const MenuList = styled.ul`
  border-top: 0.5px solid ${({ theme }) => theme?.border?.primary}4d;
  margin: 0;
  padding: 5px 0;
`;

const MenuItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${({ theme }) => theme?.text?.primary};
  padding: 5px 20px;
  cursor: pointer;
  transition: all 75ms ease-in;
  &:hover {
    background-color: ${({ theme }) => theme?.background?.hover};
  }
`;

const StatusTag = styled.strong`
  color: ${({ color }) => color};
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  box-sizing: border-box;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #5267ff; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: ${spin} 2s linear infinite;
`;

export {
  UserControlsHeader,
  Username,
  UserStatus,
  MenuList,
  MenuItem,
  StatusTag,
  Loader
};
