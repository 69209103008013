import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import Template from './RuleStage/Template';
import {
  createWorkflowAction,
  AddWorkflowAction,
  updateWorkflowAction
} from '../../../Redux/Actions/workflow';
import { getTemplateAction } from '../../../Redux/Actions/workflow';
let _ = require('lodash');

const NewRules = props => {
  const dispatch = useDispatch();
  const [Stage, setStage] = useState(['Type']);
  const [ActiveNow, setActiveNow] = useState('Type');
  const [TypeActive, setTypeActive] = useState('');
  const [ScheduleActive, setScheduleActive] = useState('New Inquiry');
  const [afterInquery, setafterInquery] = useState('');
  const [minutes, setminutes] = useState(0);
  const [TimeSelect, setTimeSelect] = useState('minutes');
  const [allProperty, setallProperty] = useState(false);
  const [SelectedProperty, setSelectedProperty] = useState([]);
  const [SelectedTemplate, setSelectedTemplate] = useState(null);
  const [SelectedTemp, setSelectedTemp] = useState(null);
  const [FollowUp, setFollowUp] = useState('');
  const CardStyle = {
    width: '100%',
    height: '628px'
  };
  //functionBlock
  const handleNext = val => {
    setActiveNow(val);
    let temp = Stage;
    temp.push(val);
    setStage(temp);
  };
  const handleBack = (to, from) => {
    setActiveNow(to);
    let temp = Stage.filter(vals => vals !== from);
    setStage(temp);
  };
  const CreateWorkFlow = () => {
    const propertyIds = SelectedProperty.filter(
      val => val.type === 'property'
    ).map(res => {
      return res.id ? res.id : res._id;
    });
    const apartmentIds = SelectedProperty.filter(
      val => val.type === 'building'
    ).map(res => {
      return res.id ? res.id : res._id;
    });
    let obj = {
      name: TypeActive,
      type: ScheduleActive,
      delay: {
        val: minutes,
        unit: TimeSelect
      },
      tourType: afterInquery !== '' ? afterInquery : 'none',
      templateMessageId: SelectedTemplate._id,
      propertyIds: propertyIds,
      apartmentIds: apartmentIds,
      allProperties: allProperty,
      followUpType: FollowUp
    };
    if (!props.update) {
      dispatch(createWorkflowAction(obj));
      dispatch(AddWorkflowAction(false));
    }

    if (props.update) {
      obj.id = props.WUdata._id;
      dispatch(updateWorkflowAction(obj));
      dispatch(AddWorkflowAction(false));
      props.setOpen(false);
    }
  };

  useEffect(() => {
    if (props.update) {
      setTypeActive(props.WUdata.channel);
      setScheduleActive(props.WUdata.type);
      let property = props.WUdata.properties ? props.WUdata.properties : [];
      let building = props.WUdata.apartments ? props.WUdata.apartments : [];
      setSelectedProperty([...property, ...building]);
      setSelectedTemplate(props.WUdata.templateMessageId);
      if (props.TemplateData.data && props.TemplateData.data.length > 0) {
        let index = _.findIndex(props.TemplateData.data, {
          _id:
            props.WUdata.templateMessageId !== null
              ? props.WUdata.templateMessageId._id
              : null
        });
        setSelectedTemp(index);
      }
      setminutes(props.WUdata.delayObj.val ? props.WUdata.delayObj.val : 0);
      setTimeSelect(
        props.WUdata.delayObj.unit ? props.WUdata.delayObj.unit : 'minutes'
      );
      setafterInquery(props.WUdata && props.WUdata.tourType);
    }
  }, []);

  return (
    <Card style={CardStyle}>
      <CardContent>
        {props.WUdata && props.WUdata._id && (
          <div style={{ position: 'relative' }}>
            <ClearIcon
              onClick={() => props.setOpen(false)}
              style={{
                cursor: 'pointer',
                float: 'right',
                fontSize: '32px',
                position: 'absolute',
                right: '-18px',
                zIndex: '99',
                top: '-19px'
              }}
            />
          </div>
        )}
        <div>
          <Template
            handleNext={handleNext}
            handleBack={handleBack}
            setSelectedTemplate={setSelectedTemplate}
            SelectedTemp={SelectedTemp}
            setSelectedTemp={setSelectedTemp}
            onClose={props.onClose}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  TemplateData: state.Workflow.msgTemplate,
  TemplateLoader: state.Workflow.TemplateLoader
});

export default connect(mapStateToProps)(NewRules);
