import React from 'react';

const WorkflowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 11C19 12.3 18.2 13.4 17 13.8V15C17 17.2 15.2 19 13 19H10.8C10.4 20.2 9.3 21 8 21C6.3 21 5 19.7 5 18C5 16.7 5.8 15.6 7 15.2V8.8C5.9 8.4 5 7.3 5 6C5 4.3 6.3 3 8 3C9.7 3 11 4.3 11 6C11 7.3 10.2 8.4 8.9 8.8V15.2C9.8 15.5 10.5 16.1 10.8 17H13C14.1 17 15 16.1 15 15V13.8C13.8 13.4 13 12.3 13 11C13 9.3 14.3 8 16 8C17.7 8 19 9.3 19 11ZM8 5C7.5 5 7 5.4 7 6C7 6.6 7.4 7 8 7C8.6 7 9 6.6 9 6C9 5.4 8.6 5 8 5ZM16 12C16.6 12 17 11.6 17 11C17 10.4 16.6 10 16 10C15.4 10 15 10.4 15 11C15 11.6 15.4 12 16 12ZM8 19C8.6 19 9 18.6 9 18C9 17.4 8.6 17 8 17C7.4 17 7 17.4 7 18C7 18.6 7.4 19 8 19Z"
        fill="white"
      />
    </svg>
  );
};

export default WorkflowIcon;
