import React from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.css';

const pagination = ({ pageCount, handlePageClick, key }) => {
  return (
    <div>
      <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        key={key}
      />
    </div>
  );
};

export default pagination;
