import React, { useEffect, useState } from 'react';

import {
  AddIconWrapper,
  IconWrapper
} from '../CommentModal/comment-modal.styles';
import Loader from './loaderv1.gif';
import QAErrorComponent from '../QAErrorComponent';
import Comment from './Comment';
import {
  CommentContainer,
  CommentForm,
  CommentInput,
  CommentsList,
  CTASection,
  EmptyState,
  Header,
  Icons,
  Title
} from './comment-modal.styles';
import { Button, Tooltip } from '@mui/material';
import AddSmall from '../../../Media/KelseyInsight/AddSmall';
import Close from '@mui/icons-material/Close';
import { useMutation, useQuery } from '@apollo/client';
import { fetchcommentsByLeadId } from '../../../gql/queries/comments';
import {
  createCommentById,
  deleteCommentById,
  updateByCommentId
} from '../../../gql/mutations/comments';

const CommentModal = ({ leadInfo, userId, onClose, setIsTabOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [commentInputVisible, setCommentInputVisible] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [commentToDelete, setCommentToDelete] = useState('');
  const [commentToUpdate, setCommentToUpdate] = useState('');

  const lead = leadInfo?._id;
  const user = userId;
  const tenant = leadInfo?.tenant?._id;

  const showCommentInput = () => {
    setCommentInputVisible(true);
  };

  const hideCommentInput = () => {
    setCommentInputVisible(false);
  };

  const handleCommentInput = e => {
    setCommentInput(e.target.value);
  };

  const handleUpdateComment = id => {
    updateComment({
      variables: {
        commentId: id,
        record: {
          message: commentInput,
          tenantId: tenant,
          userId: user,
          leadId: lead
        }
      }
    });
  };

  const handleClickEdit = id => {
    const selectedComment = comments.commentsByLeadId.find(c => c._id === id);

    setCommentToUpdate(id);
    setCommentInput(selectedComment.message);
    setIsEditingComment(true);
  };

  const handleEditCancel = e => {
    e?.preventDefault();
    setCommentToUpdate('');
    setIsEditingComment(false);
    setCommentInput('');
  };

  const handleDeleteComment = id => {
    setCommentToDelete(id);
    commentToDelete && deleteComment();
  };

  const handleCreateComment = () => {
    createComment({
      variables: {
        record: {
          message: commentInput,
          tenantId: tenant,
          userId: user,
          leadId: lead
        }
      }
    });
  };

  const {
    loading,
    error,
    data: comments
  } = useQuery(fetchcommentsByLeadId, {
    variables: {
      leadId: lead
    }
  });

  const [createComment] = useMutation(createCommentById, {
    refetchQueries: [
      {
        query: fetchcommentsByLeadId,
        variables: { leadId: lead }
      }
    ],
    onCompleted: () => {
      hideCommentInput();
      setCommentInput('');
    }
  });

  const [updateComment] = useMutation(updateByCommentId, {
    refetchQueries: [
      {
        query: fetchcommentsByLeadId,
        variables: { leadId: lead }
      }
    ],
    onCompleted: () => {
      setCommentInput('');
      setCommentToUpdate('');
      setIsEditingComment(false);
    }
  });

  const [deleteComment] = useMutation(deleteCommentById, {
    variables: {
      commentId: commentToDelete
    },
    refetchQueries: [
      {
        query: fetchcommentsByLeadId,
        variables: { leadId: lead }
      }
    ],
    onCompleted: () => {
      setCommentToDelete('');
    }
  });

  useEffect(() => {
    if (!commentsList?.length) {
      showCommentInput && showCommentInput();
    }
  }, [commentsList]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      if (comments) {
        setCommentsList(comments.commentsByLeadId);
        hideCommentInput();
      } else {
        showCommentInput();
      }
      setIsLoading(false);
    }
  }, [comments, loading]);

  const CommentsContent = (
    <>
      {commentInputVisible && (
        <CommentForm>
          <CommentInput
            placeholder="Type here"
            value={commentInput}
            onChange={handleCommentInput}
          />
          <CTASection>
            <Button
              size="small"
              variant="contained"
              style={{
                textTransform: 'capitalize',
                background: '#5267FF'
              }}
              onClick={() => {
                handleCreateComment();
              }}
            >
              <span style={{ color: '#FFFFFF' }}>Add</span>
            </Button>

            <Button
              size="small"
              style={{
                textTransform: 'capitalize',
                background: 'transparent'
              }}
              onClick={() => hideCommentInput && hideCommentInput()}
            >
              <span style={{ color: '#393939' }}>Cancel</span>
            </Button>
          </CTASection>
        </CommentForm>
      )}
      {commentsList?.length ? (
        <CommentsList>
          {commentsList?.map((comment, idx) => {
            return (
              <Comment
                key={comment._id}
                item={comment}
                title={`Comment ${idx + 1}`}
                isEditing={commentToUpdate === comment._id}
                onClickEdit={handleClickEdit}
                onCancelEdit={handleEditCancel}
                onUpdate={handleUpdateComment}
                onDelete={handleDeleteComment}
                onChange={handleCommentInput}
                inputValue={commentInput}
              />
            );
          })}
        </CommentsList>
      ) : (
        <EmptyState>No comments available.</EmptyState>
      )}
    </>
  );

  return (
    <CommentContainer>
      <Header>
        <Title>Comments</Title>
        <Icons>
          {!isEditingComment && (
            <Tooltip title="Add Comment">
              <AddIconWrapper
                onClick={() => showCommentInput && showCommentInput()}
              >
                <AddSmall />
              </AddIconWrapper>
            </Tooltip>
          )}
          <IconWrapper onClick={onClose}>
            <Close />
          </IconWrapper>
        </Icons>
      </Header>
      {isLoading ? (
        <QAErrorComponent
          icon={
            <img src={Loader} alt="winner trophy" width={200} height={200} />
          }
          type={'empty-question'}
        />
      ) : (
        CommentsContent
      )}
    </CommentContainer>
  );
};

export default CommentModal;
