import styled from 'styled-components';

const IndicatorWrapper = styled.div`
  position: absolute;
  top: -60px;
  width: 100%;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.5 ease-out;
`;
const Indicator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  padding: 5px 10px;
`;

const Badge = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #c4c4c4;
  border-radius: 85px;
  padding: 5px 10px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  background-color: rgba(248, 248, 248, 1);
  color: #393939;
`;

const RulerLeft = styled.div`
  position: absolute;
  top: 50%;
  width: calc(100% - 20px);
  background-color: rgba(196, 196, 196, 0.5);
  height: 1px;
  left: 10px;
`;
const Button = styled.button`
  background-color: ${({ theme }) => theme?.button?.primary};
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  padding: 5px 10px;
  display: flex;
  gap: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  border-radius: 85px;
  outline: none;
  border: none;
  align-items: center;
`;

export { IndicatorWrapper, Indicator, Badge, RulerLeft, Button };
