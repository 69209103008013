import React, { useContext } from 'react';
import { getCookie } from '../../utils';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import Layout from '../../Component/layout';
import Retool from 'react-retool';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const CommunitySettings = props => {
  const retoolCommunitySettingsPage =
    process.env.REACT_APP_RETOOL_COMMUNITY_SETTINGS;
  const authorization = `${getCookie('rb_access_token')}`;
  const params = useParams();
  const aptId = params.id;

  return (
    <Layout history={props.history}>
      <Retool
        url={retoolCommunitySettingsPage}
        data={{
          authorization,
          aptId
        }}
        height="800px"
        styling={{ backgroundColor: '#F8F8F8' }}
      />
    </Layout>
  );
};

export default CommunitySettings;
