import { gql } from '@apollo/client';

export const getAllSopAndAnnouncements = gql`
  query getAllSopAndAnnouncement(
    $filters: FiltersInputInput!
    $page: Int
    $limit: Int
  ) {
    getAllSopAndAnnouncement(filters: $filters, page: $page, limit: $limit) {
      data {
        _id
        name
        category
        tenants {
          _id
          name
        }
        apartments {
          _id
          name
        }
        message
        isImportant
        createdAt
        isForAllClients
        isForAllCommunities
      }
    }
  }
`;

export const getOneSopAndAnnouncement = gql`
  query getAllSopAndAnnouncement(
    $filters: FiltersInputInput!
    $page: Int
    $limit: Int
  ) {
    getAllSopAndAnnouncement(filters: $filters, page: $page, limit: $limit) {
      data {
        _id
        createdAt
      }
    }
  }
`;

export const getAnnouncementsByAgent = gql`
  query getAgentAnnouncements {
    getAgentAnnouncements {
      _id
      name
      category
      isForAllClients
      isForAllCommunities
      tenants {
        _id
        name
      }
      apartments {
        _id
        name
      }
      message
      updatedAt
      createdAt
      isForAllClients
      isForAllCommunities
    }
  }
`;
