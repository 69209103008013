import styled from 'styled-components';

const BadgeWrapper = styled.div`
  display: inline-flex;
  height: auto;
  width: auto;
  max-width: ${({ maxWidth }) => maxWidth ?? ''};
  align-items: center;
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0px'};
  background-color: ${({ background }) => background ?? 'transparent'};
  padding: ${({ padding }) => padding};
  gap: 4px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
`;

const InnerText = styled.span`
  color: ${({ color, theme }) => (color ? color : theme.text.label)};
  font-family: 'Inter', sans-serif;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  line-height: ${({ lineHeight }) => lineHeight ?? '15px'};
  font-weight: 400;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { BadgeWrapper, IconWrapper, InnerText };
