import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
const ZMuiSelect = props => {
  const {
    label,
    multiple = false,
    value,
    options,
    handleChange,
    outlined,
    variant = 'outlined'
  } = props;
  const classes = useStyles();
  const onChange = e => {
    handleChange(label, e.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        multiple={multiple}
        label={label}
        onChange={onChange}
        variant={variant}
        className={classes.inputRoot}
      >
        {options.map(({ name, value }) => {
          return (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ZMuiSelect;
