import React, { useEffect, useState } from 'react';
import { Slots } from '../Constants';
import SlotComponent from './SlotComponent';
import {
  callToTextSelected,
  convertArrayToObj,
  convertSlotsUseable
} from './utils';

const IvrSlots = ({
  updatedCallData,
  setUpdateCallData,
  callData,
  slotsEditable,
  slotsData,
  setSlotsData,
  id
}) => {
  useEffect(() => {
    if (callData?.zumaIVRSetting?.slots) {
      const getslotsObject = convertSlotsUseable(
        callData?.zumaIVRSetting?.slots
      );

      setSlotsData({ ...getslotsObject });
    }
  }, [callData?.zumaIVRSetting?.slots]);

  const isCallTextSelected = callToTextSelected(slotsData);

  return (
    <div
      className="slots-box"
      style={!slotsEditable ? { pointerEvents: 'none' } : {}}
    >
      {Slots.map(({ name }, index) => (
        <SlotComponent
          key={name}
          {...{
            name,
            index,
            slotsData,
            setSlotsData,
            updatedCallData,
            setUpdateCallData,
            isCallTextSelected,
            id,
            slotsEditable
          }}
        />
      ))}
    </div>
  );
};

export default IvrSlots;
