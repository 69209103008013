import styled from 'styled-components';

const MessageBody = styled.div`
  overflow-wrap: anywhere;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 10px;
  max-width: 451px;
  border-radius: 8px;
  background: ${({ type, theme }) =>
    type === 'incoming'
      ? theme.defaultIncomingMessage
      : theme.defaultOutgoingMessage};
  min-width: 400px;
  border: ${({ type }) =>
    type === 'incoming' && '1px solid rgba(196, 196, 196, 0.5)'};
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  color: ${({ type }) => (type === 'incoming' ? '#393939' : '#fff')};
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SpecialEventComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 344px;
  border-radius: 10px;
  // border: 0.1px solid rgba(0, 0, 0, 0.3);
  color: #393939;
`;
const CreatedByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.placeholder};
`;
const EventHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-bottom: ${({ type }) =>
    type === 'specialEvent' ? '0.5px solid #C4C4C4' : 'none'};
`;
const Line = styled.div`
  width: 99%;
  border: 0.4px solid #c4c4c4;
`;
const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  word-break: break-word;
  white-space: pre-wrap;
`;
const NoteTitle = styled.div`
  font-weight: 500;
  padding-left: 10px;
  padding-top: 10px;
`;
const NoteBody = styled.div`
  font-weight: 400;
  padding: 3px 10px 10px 10px;
  white-space: pre-wrap;
`;
const EventContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  background: ${({ isSynced }) =>
    isSynced ? '#ffcb00' : 'rgba(231, 233, 249, 1)'};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const TourComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 344px;
  border-radius: 10px;
  color: #fff;
`;
const LeadInfoComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 344px;
  border-radius: 10px;
  color: ${props => props.theme.text.primary};
`;

const TourSuccessfulEventContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  background: #037f4c;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;
const TourCancelledEventContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  background: #d83a52;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;
const LeadInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid #c4c4c4;
  opacity: 0.7;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;
const SenderName = styled.div`
  color: ${props => props.theme.text.primary};
`;

const ScheduledMessageComp = styled.div`
  display: flex;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px;
  background: ${props => (props.isDeleted ? '#D83A52' : '#5559DF')};
  width: 400px;
  flex-shrink: 0;
`;
const PrimaryInfo = styled.div`
  padding-top: 10px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;
const SecondaryInfoTitle = styled.span`
  opacity: 0.7;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;
export {
  MessageBody,
  Message,
  CreatedByContainer,
  SpecialEventComponent,
  EventHeader,
  EventContainer,
  NoteContainer,
  NoteTitle,
  NoteBody,
  Line,
  TourSuccessfulEventContainer,
  TourComponent,
  LeadInfoContainer,
  LeadInfoComponent,
  TourCancelledEventContainer,
  SenderName,
  ScheduledMessageComp,
  PrimaryInfo,
  SecondaryInfoTitle
};
