import { gql } from '@apollo/client';

export const fetchApartmentSourcesById = gql`
  query getCommunitySources($apartmentId: MongoID!) {
    getCommunitySources(_id: $apartmentId) {
      name
      tenantId
      _id
      meta {
        propertyId
      }
      address {
        city
        stateLong
        postalCode
        streetAddress
        stateShort
      }
      entrataIVRAndCTT {
        voicemail
        isEnabled
      }
      leadSources
    }
  }
`;
