import React from 'react';

const PrivacyLock = ({ onClick, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
      style={style}
    >
      <g clip-path="url(#clip0_7691_65464)">
        <path
          d="M14.8 7.21429H14.4V5.32805C14.4 4.27998 13.6493 3.32308 12.6085 3.21712C11.4125 3.09613 10.4 4.03549 10.4 5.21032V7.21429H10C9.34 7.21429 8.8 7.75536 8.8 8.41667V11.623C8.8 12.2843 9.34 12.8254 10 12.8254H14.8C15.46 12.8254 16 12.2843 16 11.623V8.41667C16 7.75536 15.46 7.21429 14.8 7.21429ZM12.4 10.8214C11.9595 10.8214 11.6 10.4612 11.6 10.0198C11.6 9.57847 11.9595 9.21825 12.4 9.21825C12.8405 9.21825 13.2 9.57847 13.2 10.0198C13.2 10.4612 12.84 10.8214 12.4 10.8214ZM13.2 7.21429H11.6V5.21032C11.6 4.76894 11.9595 4.40873 12.4 4.40873C12.8405 4.40873 13.2 4.76894 13.2 5.21032V7.21429ZM5.6 6.4127C7.3675 6.4127 8.8 4.97711 8.8 3.20635C8.8 1.43559 7.3675 0 5.6 0C3.8325 0 2.4 1.43559 2.4 3.20635C2.4 4.97711 3.8325 6.4127 5.6 6.4127ZM8 8.41667C8 8.19944 8.04345 7.99408 8.10758 7.79794C7.715 7.68021 7.2975 7.61508 6.8675 7.61508H4.3325C1.94025 7.61508 0 9.56143 0 11.9587C0 12.4371 0.388 12.8254 0.8665 12.8254H8.409C8.1575 12.4897 8 12.0764 8 11.623V8.41667Z"
          fill="#42526E"
        />
      </g>
      <defs>
        <clipPath id="clip0_7691_65464">
          <rect width="16" height="12.8254" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PrivacyLock;
