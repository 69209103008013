import styled, { keyframes } from 'styled-components';

const SuccessIconContainer = styled.div`
  width: 40px;
  height: 40px;
  padding-top: 2px;
  background: ${({ background }) => background};
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TickIcon = styled.div`
  position: relative;

  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid #50be77;
  border-right: ${({ showFull }) => (showFull ? '2px solid #50BE77' : 'none')};
`;
const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;
const Loader = styled.div`
  position: absolute;
  left: 50%;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #5267ff; /* Blue */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: ${spin} 2s linear infinite;
`;
export { SuccessIconContainer, TickIcon, Loader };
