import { TextField } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';

const ZTextfield = ({
  styles,
  error,
  label = 'Enter',
  variant = 'outlined',
  helperText,
  handleInputChange,
  name,
  value = ''
}) => {
  const classes = useStyles();

  return (
    <TextField
      error={error}
      value={value}
      helperText={helperText}
      className={classes.textfield}
      name={name}
      sx={styles}
      fullWidth
      variant={variant}
      label={label}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
    />
  );
};

export default ZTextfield;
