import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import HeatIcon from '../../Media/AddPropertyIcons/BigIcons/Heat-Icon.png';
import TrashIcon from '../../Media/AddPropertyIcons/BigIcons/Trash-Icon.png';
import WaterIcon from '../../Media/AddPropertyIcons/BigIcons/Water-Icon.png';
import GasIcon from '../../Media/AddPropertyIcons/BigIcons/Gas-Icon.png';
import InternetIcon from '../../Media/AddPropertyIcons/BigIcons/Internet-Icon.png';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  HeadingStyle,
  imgStyle,
  Containert
} from '../../styles/propertystyles/Otherstyle';

const Utility = props => {
  const handleChange = (name, status, customResp) => {
    let Temp = props.utility;
    Temp.find(v => v.name === name).status = status;
    Temp.find(v => v.name === name).customResp = customResp;
    props.setutility([...Temp]);
  };

  const RemoveCustomBlock = (name, status) => {
    handleChange(name, 'Included');
  };
  const iconFunc = name => {
    if (name === 'Heat') {
      return HeatIcon;
    }
    if (name === 'Trash') {
      return TrashIcon;
    }
    if (name === 'Water') {
      return WaterIcon;
    }
    if (name === 'Gas') {
      return GasIcon;
    }
    if (name === 'Internet') {
      return InternetIcon;
    }
  };

  const Tabfunction = data => {
    return (
      <div
        key={`${Math.floor(Math.random() * 1000)}-min`}
        style={{
          display: 'flex',
          flexWrap: 'wrap-reverse'
        }}
      >
        <Grid item xs={3} style={{ ...Containert, display: 'flex' }}>
          <div
            style={{ ...HeadingStyle, display: 'flex', alignItems: 'center' }}
          >
            <span style={{ marginRight: '25px', minWidth: '50px' }}>
              {data.name}
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span style={{ ...imgStyle, marginRight: '25px' }}>
              <img src={iconFunc(data.name)} alt="HeatIcon" />
            </span>
            {data.status !== 'Custom' && (
              <FormControl
                variant="outlined"
                style={{ paddingTop: '6px', marginRight: '25px', width: 190 }}
                size="small"
              >
                <Select
                  onChange={event =>
                    handleChange(data.name, event.target.value)
                  }
                  value={data.status}
                >
                  <MenuItem aria-label="None" value="" />
                  <MenuItem value={'Included'}>Included</MenuItem>
                  <MenuItem value={'TenantPays'}>Tenant Responsible</MenuItem>
                  <MenuItem value={'Custom'}>Custom</MenuItem>
                </Select>
              </FormControl>
            )}
            <div style={{ width: '500px', paddingTop: '6px' }}>
              {data.status === 'Custom' && data.name === 'Heat' && (
                <TextField
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={props.utility[0].customResp}
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax={3}
                  onBlur={event =>
                    handleChange(data.name, data.status, event.target.value)
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          RemoveCustomBlock(data.name, data.status)
                        }
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              {data.status === 'Custom' && data.name === 'Trash' && (
                <TextField
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={props.utility[1].customResp}
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax={3}
                  onBlur={event =>
                    handleChange(data.name, data.status, event.target.value)
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          RemoveCustomBlock(data.name, data.status)
                        }
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              {data.status === 'Custom' && data.name === 'Water' && (
                <TextField
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={props.utility[2].customResp}
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax={3}
                  onBlur={event =>
                    handleChange(data.name, data.status, event.target.value)
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          RemoveCustomBlock(data.name, data.status)
                        }
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              {data.status === 'Custom' && data.name === 'Gas' && (
                <TextField
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={props.utility[3].customResp}
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax={3}
                  onBlur={event =>
                    handleChange(data.name, data.status, event.target.value)
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          RemoveCustomBlock(data.name, data.status)
                        }
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              {data.status === 'Custom' && data.name === 'Internet' && (
                <TextField
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  defaultValue={props.utility[4].customResp}
                  variant="outlined"
                  fullWidth
                  multiline
                  rowsMax={3}
                  onBlur={event =>
                    handleChange(data.name, data.status, event.target.value)
                  }
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          RemoveCustomBlock(data.name, data.status)
                        }
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Grid
        container
        style={{ marginLeft: '12px', display: 'flex', flexDirection: 'column' }}
      >
        {props.utility &&
          props.utility.map(res => {
            return Tabfunction(res);
          })}
      </Grid>
    </>
  );
};

export default Utility;
