import Icon from '../../globalcomponents/Icon';

const LeadCardStatus = ({ leadSources = [] }) => {
  return (
    <div className="d-flex-row" style={{ alignItems: 'center', gap: '10px' }}>
      {leadSources?.includes('wait-list') && (
        <Icon
          src={'gamified-waitlist.svg'}
          key={`lead-status-waitlist`}
          alt="Waitlisted"
          tooltipText="Waitlisted Lead"
          size="20px"
        />
      )}
      {leadSources?.includes('phone-call-followup') && (
        <Icon
          src={'gamified-phone-call.svg'}
          key={`lead-status-phone`}
          alt="Phone Call Follow-up"
          tooltipText="Phone Call Follow-up"
          size="20px"
        />
      )}
      {(leadSources?.includes('cross-inquiry') ||
        leadSources?.includes('re-inquiry')) && (
        <Icon
          src={'gamified-cross-inquiry.svg'}
          key={`lead-status-cross-inquiry`}
          alt="Cross Inquiry"
          tooltipText="Cross Inquiry"
          size="20px"
        />
      )}
    </div>
  );
};

export default LeadCardStatus;
