import React from 'react';
import { SmsEmailOptionComponent } from '../styles.inboxv2';

const SmsEmailOption = props => {
  const { setMedium, medium, setMessageInput } = props;
  return (
    <SmsEmailOptionComponent>
      <div
        className={` d-flex-just-align-center ${
          medium == 'sms' ? `active-option ` : ''
        }`}
        onClick={() => {
          setMedium('sms');
          setMessageInput('');
        }}
      >
        SMS
      </div>
      <div
        className={`d-flex-just-align-center ${
          medium == 'email' ? `active-option` : ''
        }`}
        onClick={() => setMedium('email')}
      >
        Email
      </div>
    </SmsEmailOptionComponent>
  );
};

export default SmsEmailOption;
