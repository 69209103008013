import React from 'react';

const WrongPhone = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54998 8.78301H8.54998V4.56634H7.54998V8.78301ZM7.99998 11.333C8.15553 11.333 8.28609 11.2802 8.39165 11.1747C8.4972 11.0691 8.54998 10.9386 8.54998 10.783C8.54998 10.6275 8.4972 10.4969 8.39165 10.3913C8.28609 10.2858 8.15553 10.233 7.99998 10.233C7.84442 10.233 7.71387 10.2858 7.60831 10.3913C7.50276 10.4969 7.44998 10.6275 7.44998 10.783C7.44998 10.9386 7.50276 11.0691 7.60831 11.1747C7.71387 11.2802 7.84442 11.333 7.99998 11.333ZM7.99998 14.6663C7.05553 14.6663 6.17776 14.4969 5.36665 14.158C4.55554 13.8191 3.84998 13.3497 3.24998 12.7497C2.64998 12.1497 2.18054 11.4441 1.84165 10.633C1.50276 9.8219 1.33331 8.94412 1.33331 7.99967C1.33331 7.06634 1.50276 6.19412 1.84165 5.38301C2.18054 4.5719 2.64998 3.86634 3.24998 3.26634C3.84998 2.66634 4.55554 2.19412 5.36665 1.84967C6.17776 1.50523 7.05553 1.33301 7.99998 1.33301C8.93331 1.33301 9.80553 1.50523 10.6166 1.84967C11.4278 2.19412 12.1333 2.66634 12.7333 3.26634C13.3333 3.86634 13.8055 4.5719 14.15 5.38301C14.4944 6.19412 14.6666 7.06634 14.6666 7.99967C14.6666 8.94412 14.4944 9.8219 14.15 10.633C13.8055 11.4441 13.3333 12.1497 12.7333 12.7497C12.1333 13.3497 11.4278 13.8191 10.6166 14.158C9.80553 14.4969 8.93331 14.6663 7.99998 14.6663ZM7.99998 13.6663C9.55553 13.6663 10.8889 13.1108 12 11.9997C13.1111 10.8886 13.6666 9.55523 13.6666 7.99967C13.6666 6.44412 13.1111 5.11079 12 3.99967C10.8889 2.88856 9.55553 2.33301 7.99998 2.33301C6.44442 2.33301 5.11109 2.88856 3.99998 3.99967C2.88887 5.11079 2.33331 6.44412 2.33331 7.99967C2.33331 9.55523 2.88887 10.8886 3.99998 11.9997C5.11109 13.1108 6.44442 13.6663 7.99998 13.6663Z"
        fill="#FF6F62"
      />
    </svg>
  );
};

export default WrongPhone;
