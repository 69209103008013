import styled from 'styled-components';

const LeadContainer = styled.div`
  height: ${({ isSummarizing }) => (isSummarizing ? 'auto' : '70px')};
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${({ theme }) => theme.background.gradient};
  border-radius: 6px;
  padding: 10px;
  color: #42526e;
  font-weight: 500;
  line-height: 17px;
  transition: height cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms;
  font-family: 'inter';
  position: relative;
  top: -10px;
`;

const Label = styled.span`
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 20px;
  color: ${({ theme }) => theme.text.primary};
`;

const Detail = styled.p`
  display: inline;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const ControlOptions = styled.div`
  display: flex;
  gap: 10px;
`;

const InfoContainer = styled.div`
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.text.primary};
`;

const ClientInfo = styled.div`
  display: flex;
`;

const ClientLink = styled.span``;

const SummaryContainer = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0 10px 20px;
`;

const SummaryTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
  margin: 0;
`;

export {
  LeadContainer,
  Label,
  Title,
  ControlOptions,
  Detail,
  InfoContainer,
  ClientInfo,
  ClientLink,
  SummaryContainer,
  SummaryTitle
};
