import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const CrmModal = props => {
  const [finalList, setFinalList] = useState([]);
  const [value, setValue] = useState();
  const [finalFeeds, setFinalFeeds] = useState(['null']);
  let feedBacks = [
    'NEW',
    'OPEN',
    'WAITING',
    'BOOKED',
    'CANCELLED_RENTER',
    'CANCELLED_MANAGER',
    'NO_SHOW',
    'VISITED',
    'APPLIED_LOST',
    'IN_REVIEW',
    'APPLIED_LEASED',
    'LOST'
  ];

  const array2 = [
    {
      stage: 'Inbound',
      thirdPartyStatuses: ['NEW'],
      overrideZumaStatus: false
    },
    {
      stage: 'Contacted',
      thirdPartyStatuses: ['OPEN', 'WAITING'],
      overrideZumaStatus: false
    },
    {
      stage: 'Tour Scheduled',
      thirdPartyStatuses: ['BOOKED'],
      overrideZumaStatus: false
    },
    {
      stage: 'Tour Cancelled',
      thirdPartyStatuses: ['CANCELLED_MANAGER', 'CANCELLED_RENTER'],
      overrideZumaStatus: false
    },
    {
      stage: 'No Show',
      thirdPartyStatuses: ['NO_SHOW'],
      overrideZumaStatus: false
    },
    {
      stage: 'Tour Completed',
      thirdPartyStatuses: ['VISITED'],
      overrideZumaStatus: false
    },
    {
      stage: 'Applied',
      thirdPartyStatuses: ['APPLIED_LOST', 'IN_REVIEW', 'APPLIED_LEASED'],
      overrideZumaStatus: false
    },
    {
      stage: 'Lost',
      thirdPartyStatuses: ['LOST'],
      overrideZumaStatus: false
    },
    {
      stage: 'Ignore Status Change',
      thirdPartyStatuses: [],
      overrideZumaStatus: false
    }
  ];

  const handleStyles = stage => {
    if (stage == 'Inbound') {
      return {
        marginLeft: '10rem'
      };
    }
    if (stage == 'Contacted') {
      return {
        marginLeft: '147px'
      };
    }
    if (stage == 'Tour Scheduled') {
      return {
        marginLeft: '110px'
      };
    }
    if (stage == 'Tour Cancelled') {
      return {
        marginLeft: '120px'
      };
    }
    if (stage == 'No Show') {
      return {
        marginLeft: '10rem'
      };
    }
    if (stage == 'Tour Completed') {
      return {
        marginLeft: '7rem'
      };
    }
    if (stage == 'Applied') {
      return {
        marginLeft: '170px'
      };
    }
    if (stage == 'Lost') {
      return {
        marginLeft: '197px'
      };
    }
    if (stage == 'Ignore Status Change') {
      return {
        marginLeft: '73px'
      };
    }
  };

  const zumStatus = {
    marginLeft: '3rem',
    marginTop: '18px'
  };

  const check = {
    marginLeft: '10px',
    color: 'grey'
  };

  const fullCheck = {
    backgroundColor: '#f1f5ff',
    borderRadius: '5px',
    marginTop: '5px',
    display: 'flex',
    height: '60px'
  };

  useEffect(() => {
    if (props?.tenantData?.statusMappings?.mappings) {
      let array1 = props?.tenantData?.statusMappings?.mappings;
      for (let i = array2?.length - 1; i >= 0; i--) {
        for (let j = 0; j < array1?.length; j++) {
          if (array2[i] && array2[i].stage === array1[j].stage) {
            array2.splice(i, 1);
          }
        }
      }

      let list = array1?.concat(array2);
      setFinalList(list);
      props.setCrmData(list);
    } else {
      setFinalList(array2);
      props.setCrmData(array2);
    }
  }, []);

  useEffect(() => {
    let forDeletion = [];
    let z = finalList?.map(x => {
      x.thirdPartyStatuses.length > 0 &&
        x.thirdPartyStatuses.map(y => forDeletion.push(y));
    });
    forDeletion = forDeletion.filter(function (item, pos) {
      return forDeletion.indexOf(item) == pos;
    });
    let arr = feedBacks;
    let difference = arr?.filter(x => !forDeletion.includes(x));

    setFinalFeeds(difference);
  }, [props]);

  const handleCheck = (e, x) => {
    finalList.map(y => {
      if (y.stage === x.stage) {
        y.overrideZumaStatus = e;
      }
    });
    setFinalList(finalList);
    props.setCrmData(finalList);
  };
  const deleteStatus = (y, x, i, e) => {
    let ass = finalList?.map((y, index) => {
      let value = y?.thirdPartyStatuses[i];
      if (y.stage == x?.stage) {
        let arr = y?.thirdPartyStatuses?.filter(item => item != value);

        return { ...y, thirdPartyStatuses: arr };
      }
      return y;
    });
    setFinalList([...ass]);
    props.setCrmData([...ass]);
  };

  const handleMappings = (e, x, index, y) => {
    const check = x.thirdPartyStatuses.includes('');

    if (check === true) {
      const newArr = x?.thirdPartyStatuses?.map(element => {
        if (element === '') {
          return e.target.value;
        }
        return element;
      });
      finalList.map((y, index) => {
        if (y?.stage == x?.stage) {
          y.thirdPartyStatuses = [...newArr];
        }
      });
    }
    if (check === false) {
      const newArr = x?.thirdPartyStatuses?.map((element, i) => {
        if (index === i) {
          return e.target.value;
        }
        return element;
      });
      finalList.map((y, index) => {
        if (y?.stage == x?.stage) {
          y.thirdPartyStatuses = [...newArr];
        }
      });
    }
    let rtr = finalFeeds.filter(function (item) {
      return item !== e.target.value;
    });
    setFinalFeeds(rtr);
    setFinalList(finalList);
    props.setCrmData(finalList);
  };

  const handleAdd = (e, x) => {
    const latestdata = finalList.map((y, index) => {
      if (y?.stage == x?.stage) {
        return { ...y, thirdPartyStatuses: [...y.thirdPartyStatuses, ''] };
      }
      return y;
    });

    setFinalList([...latestdata]);
    props.setCrmData([...latestdata]);
  };

  return (
    <>
      {finalList?.map((x, i) => {
        return (
          <div className="mappings-new-cont" style={fullCheck} key={x.stage}>
            {/* <Checkbox style={check} onChange={(e) => handleCheck(e.target.checked, x)} defaultChecked={x?.overrideZumaStatus == true ? true : false} /> */}
            <span style={zumStatus}>{x?.stage}</span>
            <div className="mappings-new" style={handleStyles(x?.stage)}>
              <div className="mappings-flex" style={{ display: 'flex' }}>
                {x?.thirdPartyStatuses.length > 0 &&
                  x?.thirdPartyStatuses.map((y, index) => {
                    return (
                      <div className="mappings-new-inner">
                        <CancelIcon
                          style={{
                            color: 'grey',
                            height: '17px',
                            cursor: 'pointer'
                          }}
                          onClick={e => deleteStatus(y, x, index, value)}
                        />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            defaultValue={y}
                            onChange={e => handleMappings(e, x, index, y)}
                            value={value ? value : y}
                            style={{ marginLeft: '-20px' }}
                          >
                            <MenuItem defaultValue={y} value={y}>
                              {y}
                            </MenuItem>
                            {finalFeeds?.length &&
                              !!y === false > 0 &&
                              finalFeeds?.map(x => {
                                return (
                                  <MenuItem defaultValue={x} value={x}>
                                    {x}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    );
                  })}
                <AddCircleIcon
                  style={{
                    color: '#ff6f62',
                    marginBottom: '-17px',
                    height: '41px',
                    width: '28px',
                    marginLeft: '10px',
                    marginTop: '10px'
                  }}
                  onClick={e => handleAdd(e, x)}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CrmModal;
