import React, { useState } from 'react';
import {
  ClickableHeaderLinks,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeaderCell,
  StyledTableRow
} from './styles.tablecomponent';
import ProgressBar from '../ProgressBar';
import EditIcon from '../../Media/InboxV1/EditIcon';
import DeleteIcon from '../../Media/InboxV1/DeleteIcon';
import {
  LoaderWhite,
  UploadContainer
} from '../../Component/CallToTextComponent/styles.callToText';
import { count } from '../../Component/CallToTextComponent/constants';

const TableComponent = ({
  data,
  headers,
  onItemClick = () => {},
  handleEdit,
  handleDelete,
  onPurchase,
  sourceEditLoading = true,
  bulkPurchase,
  editData = {}
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'none'
  });

  const handleSort = key => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'desc') {
        setSortConfig({ key, direction: 'asc' });
      } else {
        setSortConfig({ key: null, direction: 'none' });
      }
    } else {
      setSortConfig({ key, direction: 'desc' });
    }
  };

  const sortedData =
    data &&
    [...data].sort((a, b) => {
      const valueA =
        typeof a[sortConfig.key] === 'string'
          ? parseFloat(a[sortConfig.key])
          : a[sortConfig.key];

      const valueB =
        typeof b[sortConfig.key] === 'string'
          ? parseFloat(b[sortConfig.key])
          : b[sortConfig.key];

      if (sortConfig.direction === 'asc') {
        if (isNaN(valueA) || isNaN(valueB)) {
          return a[sortConfig.key]?.localeCompare(b[sortConfig.key]);
        } else {
          return valueA - valueB;
        }
      }

      if (sortConfig.direction === 'desc') {
        if (isNaN(valueA) || isNaN(valueB)) {
          return b[sortConfig.key]?.localeCompare(a[sortConfig.key]);
        } else {
          return valueB - valueA;
        }
      }

      return 0;
    });

  return (
    <div style={{ height: '600px', overflow: 'auto', width: '100%' }}>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>
            {headers.map((header, index) => (
              <StyledTableHeaderCell
                key={index}
                onClick={() => header?.sorting && handleSort(header.key)}
                firstColumn={index === 0}
                lastColumn={index === headers.length - 1}
                className={
                  sortConfig.key === header.key
                    ? `sorted-${sortConfig.direction}`
                    : ''
                }
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <div>{header.displayName}</div>
                  {header?.showNumber ? (
                    <UploadContainer
                      height={'20px'}
                      marginLeft={'10px'}
                      borderRadius={'47px'}
                      style={{ padding: '3px 6px' }}
                    >
                      {count(data, header?.key)}/{data?.length}
                    </UploadContainer>
                  ) : null}
                  {sortConfig.key === header.key && header?.sorting ? (
                    <div
                      style={{
                        marginLeft: '2px',
                        minWidth: '20px',
                        display: 'flex'
                      }}
                    >
                      <img
                        src={`https://assets.getzuma.com/images/${
                          sortConfig.direction === 'asc'
                            ? 'arrow-up'
                            : 'arrow-down'
                        }.svg`}
                        alt={'sorting'}
                        width={15}
                        height={15}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        marginLeft: '2px',
                        minWidth: '20px',
                        display: 'flex'
                      }}
                    >
                      {sourceEditLoading &&
                      count(data, 'isSuggested') &&
                      header?.key === 'vanityNumber' ? (
                        <LoaderWhite left={'58%'} top={'0%'} />
                      ) : header?.sorting ? (
                        <img
                          src={`https://assets.getzuma.com/images/table-sort.svg`}
                          alt={'sorting'}
                          width={15}
                          height={15}
                        />
                      ) : null}
                    </div>
                  )}
                  {header.tooltip && (
                    <Tooltip
                      text={header.tooltipText}
                      customStyle="left:150%"
                    />
                  )}
                  {header?.clickableLink ? (
                    <ClickableHeaderLinks
                      disabled={!count(data, 'isSuggested')}
                      onClick={() => bulkPurchase(data)}
                    >
                      {header?.clickableLink}
                    </ClickableHeaderLinks>
                  ) : null}
                </div>
              </StyledTableHeaderCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>

        <tbody>
          {sortedData?.map((item, rowIndex) => (
            <StyledTableRow key={rowIndex} onClick={() => onItemClick(item)}>
              {headers.map((header, colIndex) => (
                <StyledTableCell
                  isFaded={header?.isFaded && item.isSuggested}
                  key={colIndex}
                >
                  {header?.type === 'percent' ? (
                    `${item[header.key]}%`
                  ) : header.type === 'progress' ? (
                    <ProgressBar
                      total={item[header.key]?.total}
                      completed={item[header.key]?.completed}
                      showStatus={true}
                      height={'4px'}
                    />
                  ) : header.type === 'actions' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingRight: '20px',
                        gap: '10px',
                        position: 'relative'
                      }}
                    >
                      {item?.source === editData?.source &&
                      sourceEditLoading ? (
                        <LoaderWhite top={'0%'} left={'10%'} />
                      ) : null}
                      {item.actions.map(action => {
                        return action === 'number-call' ? (
                          <img
                            onClick={() => onPurchase(rowIndex)}
                            src="/on-call.svg"
                            alt="on-call"
                          />
                        ) : action === 'edit' ? (
                          <EditIcon
                            width={24}
                            index={rowIndex}
                            onClick={() => handleEdit(rowIndex)}
                          />
                        ) : item?.isCustom ? (
                          <DeleteIcon onClick={() => handleDelete(rowIndex)} />
                        ) : null;
                      })}
                    </div>
                  ) : item?.isSuggested && header.key === 'vanityNumber' ? (
                    'Suggested:' + item[header.key]
                  ) : item?.isCustom && header.key === 'source' ? (
                    '[Custom - Unknown] ' + item[header.key] ?? '-'
                  ) : (item[header.key] ?? '-').length > 20 ? (
                    (item[header.key] ?? '-').slice(0, 20) + '...'
                  ) : (
                    item[header.key] ?? '-'
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default TableComponent;
