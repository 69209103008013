import React, { useContext } from 'react';
import {
  FlexContainer,
  HeaderOptions,
  HeaderTitle,
  MessageBody,
  MessageHeader,
  ScheduledMessageContainer,
  TootltipText
} from './styles.schudledmessages';
import { Tooltip } from '@mui/material';
import Icon from '../../../globalcomponents/Icon';
import DeletePopup from '../../../Component/ReusableDeletePopup';
import { useMutation } from '@apollo/client';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import { deleteMessage } from '../../../gql/mutations/message';
import { useInboxContext } from '../../../utils/ContextProviders/InboxContextProvider';

export const SingleMessage = ({ message }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const { fetchScheduledMessages } = useInboxContext();
  const customBackdropStyling = {
    top: '-67px',
    left:
      window && window.location.href.includes('inbox/searchlead')
        ? '-86px'
        : '-281px',
    width: '100vw',
    height: '100vh'
  };
  const { userData, leadData } = useContext(GlobalContext);

  const [deleteScheduledMessage, { data, loading, error }] =
    useMutation(deleteMessage);
  const handleDelete = async messageId => {
    await deleteScheduledMessage({
      variables: {
        messageId: messageId,
        deletedBy: {
          uid: userData?._id,
          name: userData?.name
        },
        deletedAt: new Date().toISOString()
      }
    });
    fetchScheduledMessages(leadData?._id);
  };

  return (
    <ScheduledMessageContainer key={message.id}>
      <MessageHeader>
        <HeaderTitle>
          <span style={{ fontWeight: '500' }}>Scheduled for:</span>
          &nbsp; {message.scheduledFor}
        </HeaderTitle>
        <HeaderOptions>
          {/* <Tooltip title="Send link"> */}
          <Icon
            src="info-icon.svg"
            tooltipText={
              <FlexContainer style={{ padding: '5px' }}>
                <TootltipText>
                  <span style={{ fontWeight: 600 }}>Scheduled by: &nbsp;</span>
                  {message.scheduledBy}
                </TootltipText>
                <TootltipText>
                  <span style={{ fontWeight: 600 }}>Created At: &nbsp;</span>
                  {message.createdAt}
                </TootltipText>
              </FlexContainer>
            }
          ></Icon>
          {/* </Tooltip> */}
          <Icon onClick={() => setShowPopup(true)} src="delete-icon.svg"></Icon>
        </HeaderOptions>
      </MessageHeader>
      <MessageBody>{message.message}</MessageBody>
      <DeletePopup
        show={showPopup}
        handleClose={() => setShowPopup(false)}
        deleteFunction={() => handleDelete(message.id)}
        body={null}
        customBackdropStyling={customBackdropStyling}
        confirmationMessage="Source deletion warning"
        successMessage="Scheduled Message Deleted!"
        deleteIcon="/deleteWaitConfirm.png"
        warningIcon="https://assets.getzuma.com/images/delete-warning.svg"
        confirmationText="Disclaimer: Once deleted, you will not
        be able to retrieve the message."
        sliderText="Slide to confirm deletion"
        sliderIcon="https://assets.getzuma.com/images/RightArrow.svg"
        sliderDisabled={false}
      />
    </ScheduledMessageContainer>
  );
};
