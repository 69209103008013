import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '100px',
      height: '44px'
    },
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: '4px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B0B8CB'
    },
    '& .MuiOutlinedInput-input': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  filterIconContainer: {
    paddingLeft: 8
  }
}));
