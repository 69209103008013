import ChatPopup from '../Container/InboxV2/ChatComponents/ChatPopup';
import React, { useState } from 'react';
import VectorIcon from './../Media/Vector.svg';
import Icon from '../globalcomponents/Icon';

const processEmailBody = body => {
  body = body.replace(/style\s*=\s*(['"])[^'"]*?\1/gi, '');
  body = body.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');
  return body;
};
export const EmailTimelineEvent = props => {
  const [open, setOpen] = useState(false);

  const { from, to, body, type } = props;
  return (
    <div className={`email-message email-${type}`}>
      <div style={{ padding: '10px' }}>
        <div className="email-sender">
          <span className="inter-12px-400">From:</span>{' '}
          <span className="inter-12px-600">{from?.id || ''}</span>
        </div>
        <div className="email-sender">
          <span className="inter-12px-400">To:</span>{' '}
          <span className="inter-12px-600">{to?.id || ''}</span>
        </div>
      </div>

      <ChatPopup
        {...{
          body: processEmailBody(body),
          open,
          handleClose: () => setOpen(false)
        }}
      />
      <div className="email-separator" />
      <div
        style={{
          padding: '0 10px 10px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          className={`message-text inter-12px-400 ${type}-message`}
          style={{ maxHeight: '100px', overflow: 'hidden' }}
          dangerouslySetInnerHTML={{ __html: processEmailBody(body) }}
        />
        {body?.length > 200 ? (
          <div
            className={`read-moreEmail inter-10px-400 ${type}-readMoreEmail`}
            onClick={() => setOpen(true)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {type === 'outgoing' ? (
              <Icon
                src="readmore-white.svg"
                imgStyle={{ height: '18px', width: '15px' }}
              />
            ) : (
              <img
                alt="vector"
                src={VectorIcon}
                style={{ marginRight: '5px' }}
              />
            )}
            Read More
          </div>
        ) : null}
      </div>
    </div>
  );
};
