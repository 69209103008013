import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33 3.33333H12.67C13.4047 3.33333 14 3.93 14 4.66267V12.6707C14 12.8453 13.9656 13.0182 13.8987 13.1795C13.8319 13.3409 13.7339 13.4874 13.6104 13.6109C13.4869 13.7343 13.3403 13.8322 13.1789 13.899C13.0176 13.9658 12.8446 14.0001 12.67 14H3.33C2.97738 14 2.63919 13.86 2.38978 13.6107C2.14038 13.3614 2.00018 13.0233 2 12.6707V4.66267C2 3.92867 2.59467 3.33333 3.33 3.33333ZM3.33333 6V12C3.33333 12.1768 3.40357 12.3464 3.5286 12.4714C3.65362 12.5964 3.82319 12.6667 4 12.6667H12C12.1768 12.6667 12.3464 12.5964 12.4714 12.4714C12.5964 12.3464 12.6667 12.1768 12.6667 12V6H3.33333ZM4 2.66667C4 2.48986 4.07024 2.32029 4.19526 2.19526C4.32029 2.07024 4.48986 2 4.66667 2C4.84348 2 5.01305 2.07024 5.13807 2.19526C5.2631 2.32029 5.33333 2.48986 5.33333 2.66667V3.33333H4V2.66667ZM10.6667 2.66667C10.6667 2.48986 10.7369 2.32029 10.8619 2.19526C10.987 2.07024 11.1565 2 11.3333 2C11.5101 2 11.6797 2.07024 11.8047 2.19526C11.9298 2.32029 12 2.48986 12 2.66667V3.33333H10.6667V2.66667ZM4.66667 8.66667V7.33267H6V8.66667H4.66667ZM10 8.66667V7.33267H11.3333V8.66667H10ZM7.33333 8.66667V7.33267H8.66733V8.66667H7.33333ZM4.66667 11.3333V10H6V11.3333H4.66667ZM7.33333 11.3333V10H8.66733V11.3333H7.33333ZM10 11.3333V10H11.3333V11.3333H10Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default CalendarIcon;
