import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import './index.css';
import store from './Redux/store';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo/config';
import { LicenseInfo } from '@mui/x-license-pro';
import { PostHogProvider } from 'posthog-js/react';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://14a6ccc8a4454044af4c949480ef4c17@o1336309.ingest.sentry.io/6605307',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        networkDetailAllowUrls: ['https://api.residentboost.com/graphql'],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
        maskAllText: false,
        blockAllMedia: false
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
        }}
      >
        <App />
      </PostHogProvider>
    </ApolloProvider>
  </Provider>
);
serviceWorker.unregister();
