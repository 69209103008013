import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  font-size: ${({ labelFontSize }) => labelFontSize};
  font-weight: 400;
  color: #393939;
  cursor: pointer;
  line-height: 22px;
  margin-bottom: -2px;
`;

const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1rem;
  height: 1rem;
  border: ${({ checked }) => (checked ? 'none' : '1px solid #393939')};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.15s linear;

  &:hover {
    border-color: #0073ea;
  }

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    border: ${({ checked }) => (checked ? '4px solid #0073EA' : 'none')};
    background: ${({ checked }) => (checked ? '#FFFFFF' : 'transparent')};
    box-sizing: border-box;
  }
`;

export { Wrapper, Label, Radio };
