import React from 'react';

const TimeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 2.66663C5.05875 2.66663 2.66675 5.05863 2.66675 7.99996C2.66675 10.9413 5.05875 13.3333 8.00008 13.3333C10.9414 13.3333 13.3334 10.9413 13.3334 7.99996C13.3334 5.05863 10.9414 2.66663 8.00008 2.66663ZM8.00008 12.148C6.9004 12.1465 5.84616 11.7091 5.06856 10.9315C4.29097 10.1539 3.85349 9.09964 3.85208 7.99996C3.85349 6.90027 4.29097 5.84603 5.06856 5.06844C5.84616 4.29084 6.9004 3.85337 8.00008 3.85196C9.09977 3.85337 10.154 4.29084 10.9316 5.06844C11.7092 5.84603 12.1467 6.90027 12.1481 7.99996C12.1467 9.09964 11.7092 10.1539 10.9316 10.9315C10.154 11.7091 9.09977 12.1465 8.00008 12.148ZM8.59275 7.75463V5.63196C8.59275 5.30596 8.32608 5.03929 8.00008 5.03929C7.67408 5.03929 7.40741 5.30596 7.40741 5.63263V8.00263C7.40741 8.16796 7.47608 8.31663 7.58608 8.42463L9.04941 9.88729C9.16073 9.99807 9.31138 10.0603 9.46841 10.0603C9.62545 10.0603 9.7761 9.99807 9.88741 9.88729C9.99798 9.77601 10.06 9.6255 10.06 9.46863C10.06 9.31175 9.99798 9.16124 9.88741 9.04996L8.59275 7.75463Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default TimeIcon;
