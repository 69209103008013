import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    height: '100vh'
  },
  addWorkflowContainer: {
    height: '100vh',
    '& .MuiBreadcrumbs-separator': {
      display: 'none'
    }
  },
  templateButton: {
    position: 'fixed',
    zIndex: '99',
    color: '#fff',
    bottom: '10%',
    right: '42%',
    background: '#FF6F62',
    textTransform: 'none',
    borderRadius: '8px',
    borderColor: 'transparent',
    '&.MuiButton-root:hover': {
      background: '#FF6F62'
    }
  },
  title: {
    '&.MuiTypography-root.MuiLink-root': {
      color: '#393939'
    },
    '&.MuiTypography-root': {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Inter',
      color: '#393939'
    },
    '&.MuiTypography-root.MuiLink-root:hover': {
      textDecoration: 'none'
    },
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'Inter'
  },
  backArrow: {
    height: 28,
    width: 28,
    paddingRight: 14,
    paddingTop: 8,
    cursor: 'pointer'
  },
  formContainer: {
    margin: 24,
    background: '#fff'
  },
  workflowContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '64px 10%'
  },
  flexItem: {
    width: '44%',
    flex: '44%'
  },
  labelText: {
    '&.MuiTypography-root': {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: 'Inter'
    },
    paddingBottom: 24,
    color: '#393939'
  },
  kelseyCheck: {
    marginLeft: '-10px'
  },
  createTemplateButton: {
    width: '100%',
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: 14
    }
  },
  formButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: '8px 34px',
      color: '#fff',
      background: '#20065F'
    },
    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
      background: 'grey'
    },
    float: 'right',
    borderRadius: 4
  },
  mediumContainer: {
    padding: '8px 12px',
    borderRadius: 6
  },
  switchStyle: {
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#79CA96'
    },
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
      color: '#4CAF50'
    }
  },
  sequenceContainer: {
    display: 'flex',
    background: '#eeeaf666',
    flex: '100%',
    justifyContent: 'normal',
    gap: '64px 10%',
    padding: 64,
    borderRadius: 16,
    position: 'relative'
  },
  darkSequenceContainer: {
    display: 'flex',
    background: '#C4C4C4',
    flex: '100%',
    justifyContent: 'normal',
    gap: '64px 10%',
    padding: 64,
    borderRadius: 16,
    position: 'relative'
  },
  followUpNumber: {
    padding: '6px 12px',
    background: '#fff',
    borderRadius: '50%'
  },
  addSequenceContainer: {
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '100%'
  },
  footerContainer: {
    display: 'flex',
    flex: '100%',
    justifyContent: 'flex-end'
  },
  backButton: {
    marginRight: 18,
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: '8px 34px',
      color: '#20065F',
      border: '1px #20065F solid',
      marginRight: '40px'
    },
    borderRadius: 4
  },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tooltipTitle: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    color: '#696969'
  },
  tooltipBody: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#696969'
  },
  workflowFormContainer: {
    padding: 20,
    fontFamily: 'Inter',
    fontSize: '16px'
  },
  workflowGlobalSettings: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 40,
    padding: '0 20px'
  },
  workflowFilters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 30,
    width: 200
  }
}));
