import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Layout from '../../Component/layout';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getAllAccessibilityAction } from '../../Redux/Actions/Accessibility';
import { getAllUtilityAction } from '../../Redux/Actions/Utility';
import { getLeaseLengthAction } from '../../Redux/Actions/LeaseLength';
import { getAllPetPolicyAction } from '../../Redux/Actions/PetPolicy';
import { getAllAmenitiesAction } from '../../Redux/Actions/Amenities';
import { getAllAppliancesAction } from '../../Redux/Actions/Appliances';
import { ClearFilesfromStore } from '../../Redux/Actions/FileUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfirmModal from './modal/ConfirmModal';
import { DateStrip } from '../../utils';
import {
  postPropertyAction,
  updatePropertyAction,
  getAllPropertyAction,
  GetPlacesAction
} from '../../Redux/Actions/Property';
import { getAllBuildingAction } from '../../Redux/Actions/Apartment';
import ImageUpload from './imageUpload';
import Utility from './Utility';
import Petpolicy from './Petpolicy';
import Appliances from './Appliances';
import GeneralAmenities from './GeneralAmenities';
import Accessibility from './Accessibility';
import AddAparatment from './modal/AddAparatment';
import {
  Common,
  HeaderStyle,
  closeIconStyle,
  SaveMoreStyle,
  SavePropertyStyle,
  BlockHeading,
  typoGraph
} from '../../styles/propertystyles/Propertystyle';
import { set } from 'lodash';

let _ = require('lodash');
const PropertyAdd = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setopen] = useState(false);

  //State Block-------------
  const [PropertyName, setPropertyName] = useState('');
  const [aptId, setaptId] = useState('');
  const [placeId, setplaceId] = useState('');
  const [PropertyAddress, setPropertyAddress] = useState('');
  const [UnitName, setUnitName] = useState('');
  const [Bedrooms, setBedrooms] = useState('');
  const [Bathrooms, setBathrooms] = useState('');
  const [powderRooms, setpowderRooms] = useState('');
  const [MonthlyRent, setMonthlyRent] = useState('');
  const [Deposit, setDeposit] = useState('');
  const [Discription, setDiscription] = useState('');
  const [Type, setType] = useState('');
  const [Area, setArea] = useState('');
  const [AvailableFrom, setAvailableFrom] = useState('');
  const [incomeReq, setincomeReq] = useState('');
  const [allowSection8, setallowSection8] = useState('');
  const [emergencyAvailable, setemergencyAvailable] = useState('');
  const [subleasing, setsubleasing] = useState('');
  const [lowestCredit, setlowestCredit] = useState('');
  const [rentalApplicationFee, setrentalApplicationFee] = useState('');
  const [covidSplReq, setcovidSplReq] = useState('');
  const [holdDuration, setholdDuration] = useState('');
  const [flooring, setflooring] = useState('');
  const [guestPolicy, setguestPolicy] = useState('');
  const [noticePeriod, setnoticePeriod] = useState('');
  const [earlyBreak, setearlyBreak] = useState('');
  const [personalization, setpersonalization] = useState('');
  const [numKeys, setnumKeys] = useState('');
  const [noRefundRules, setnoRefundRules] = useState('');
  const [tempFile, settempFile] = useState([]);
  const [heroImage, setheroImage] = useState();
  const [openModal, setopenModal] = useState(false);
  const [accesibility, setaccesibility] = useState([]);
  const [utility, setutility] = useState([
    {
      name: 'Heat',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Trash',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Water',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Gas',
      status: 'Included',
      customResp: ''
    },
    {
      name: 'Internet',
      status: 'Included',
      customResp: ''
    }
  ]);

  const [LeaseLength, setLeaseLength] = useState('');
  const [Custom, setCustom] = useState('');
  const [petsPolicy, setpetsPolicy] = useState([]);
  const [PetAllowed, setPetAllowed] = useState(true);
  const [allowRegAnimals, setallowRegAnimals] = useState(false);
  const [generalAmneties, setgeneralAmneties] = useState([]);
  const [Appliance, setAppliance] = useState([]);
  const [PlaceObj, setPlaceObj] = useState([
    { description: 'place suggetions' }
  ]);
  const [Validator, setValidator] = useState(false);
  const [Message, setMessage] = useState(false);
  const [CoverMessage, setCoverMessage] = useState(false);

  // function Block -------->

  const SaveProperty = value => {
    const obj = {};
    if (
      Type !== null &&
      Type !== '' &&
      PropertyName !== null &&
      PropertyName !== '' &&
      MonthlyRent !== null &&
      Bedrooms !== null &&
      Bedrooms !== '' &&
      Bedrooms !== 0 &&
      Bathrooms !== null &&
      (PropertyAddress !== null || Type === 'Apartment') &&
      // tempFile.length &&
      // heroImage !== null &&
      UnitName !== null &&
      Discription !== null
    ) {
      obj.aptId = aptId;
      obj.name = PropertyName;
      obj.propType = Type;
      obj.files = tempFile;
      obj.heroImage = heroImage;
      obj.unitName = UnitName;
      obj.monthlyRent = MonthlyRent;
      obj.bedRooms = Bedrooms;
      obj.bathRooms = Bathrooms;
      obj.deposit = Deposit;
      obj.powderRooms = powderRooms;
      obj.addressL1 =
        PropertyAddress && PropertyAddress.description
          ? PropertyAddress.description
          : null;
      obj.placeId = placeId;
      obj.discription = Discription;
      obj.leaselength = LeaseLength;
      obj.accesibility = accesibility;
      obj.utilities = utility;
      obj.appliances = Appliance;
      obj.pets = {
        allowed: PetAllowed,
        allowRegAnimals: allowRegAnimals,
        allowedPets: petsPolicy,
        customResp: Custom
      };
      obj.generalAmneties = generalAmneties;
      obj.status = value === 'listed' ? 'listed' : 'draft';
      obj.created = new Date();
      obj.sqft = Area;
      obj.avlFrom = AvailableFrom;
      obj.incomeReq = incomeReq;
      obj.allowSection8 = allowSection8;
      obj.emergencyAvailable = emergencyAvailable;
      obj.subleasing = subleasing;
      obj.rentalApplicationFee = rentalApplicationFee;
      obj.covidSplReq = covidSplReq;
      obj.holdDuration = holdDuration;
      obj.flooring = flooring;
      obj.guestPolicy = guestPolicy;
      obj.noticePeriod = noticePeriod;
      obj.earlyBreak = earlyBreak;
      obj.personalization = personalization;
      obj.numKeys = numKeys;
      obj.noRefundRules = noRefundRules;
      obj.lowestCredit = lowestCredit;
    }

    if (
      props.propertyType !== 'EDIT' &&
      Type !== null &&
      Type !== '' &&
      PropertyName !== null &&
      PropertyName !== '' &&
      MonthlyRent !== null &&
      Bedrooms !== null &&
      Bedrooms !== '' &&
      Bedrooms !== 0 &&
      Bathrooms !== null &&
      Bathrooms !== '' &&
      Bathrooms !== 0 &&
      (PropertyAddress !== null || Type === 'Apartment') &&
      // tempFile.length &&
      // heroImage !== null &&
      UnitName !== null &&
      Discription !== null
    ) {
      dispatch(postPropertyAction(obj));
      props.history.push('/property');
      dispatch(ClearFilesfromStore());
    }

    if (props.propertyType === 'EDIT') {
      if (
        PropertyName &&
        PropertyName?.length > 1 &&
        MonthlyRent !== '' &&
        // tempFile.length &&
        // heroImage !== null &&
        UnitName !== null &&
        UnitName !== ''
      ) {
        dispatch(updatePropertyAction(obj, id));
        props.history.push('/property');
        dispatch(ClearFilesfromStore());
      } else {
        setValidator(true);
        setMessage(true);
        if (heroImage === null) {
          setCoverMessage(true);
        }
      }
    } else {
      setValidator(true);
      setMessage(true);
      setCoverMessage(true);
    }
  };
  const openCraiglist = () => {
    props.history.push(`/property/Craigslist/${id}`);
  };
  const closeButton = () => {
    props.history.push('/property');
    dispatch(ClearFilesfromStore());
  };
  const ModalOpen = () => {
    if (props.propertyType === 'EDIT') {
      SaveProperty();
    } else {
      setopen(true);
    }
  };

  // settype function
  const setTypefun = event => {
    setType(event.target.value);
    setPropertyName('');
  };

  // handleFunctions----------------->>>>>>
  const handleAccesibility = (event, id) => {
    let Temp = accesibility;
    if (event.target.checked) {
      let obj = {
        name: event.target.name,
        _id: id
      };
      Temp = [...accesibility, obj];
      setaccesibility(Temp);
    }
    if (!event.target.checked) {
      accesibility.some(
        accesibility => accesibility.name === event.target.name
      );
      Temp = accesibility.filter(function (el) {
        return el.name !== event.target.name;
      });
      setaccesibility(Temp);
    }
  };
  const handleLeaseLength = event => {
    setLeaseLength(event.target.value);
  };
  const handlepetsPolicy = event => {
    let Temp = petsPolicy;
    if (event.target.checked) {
      Temp = [...petsPolicy, event.target.name];
      setpetsPolicy(Temp);
    }
    if (!event.target.checked) {
      Temp = petsPolicy.filter(item => item !== event.target.name);
      setpetsPolicy(Temp);
    }
  };
  const handlegeneralAmneties = (event, id) => {
    let Temp = generalAmneties;
    if (event.target.checked) {
      let obj = {
        name: event.target.name,
        _id: id
      };
      Temp = [...generalAmneties, obj];
      setgeneralAmneties(Temp);
    }
    if (!event.target.checked) {
      generalAmneties.some(
        generalAmneties => generalAmneties.name === event.target.name
      );
      Temp = generalAmneties.filter(function (el) {
        return el.name !== event.target.name;
      });
      setgeneralAmneties(Temp);
    }
  };
  const handleAppliance = (event, id) => {
    let Temp = Appliance;
    if (event.target.checked) {
      let obj = {
        name: event.target.name,
        _id: id
      };
      Temp = [...Appliance, obj];
      setAppliance(Temp);
    }
    if (!event.target.checked) {
      Appliance.some(Appliance => Appliance.name === event.target.name);
      Temp = Appliance.filter(function (el) {
        return el.name !== event.target.name;
      });
      setAppliance(Temp);
    }
  };
  const placeHandleChange = event => {
    if (event.target.value?.length >= 3) {
      dispatch(GetPlacesAction(event.target.value));
    }
  };
  const AutoCompHandleChange = value => {
    setPropertyAddress({ description: value.description });
    setplaceId(value.place_id);
  };
  // const CreateAppartment = () => {
  //   setopenModal(true);
  // };
  const HandleApartment = v => {
    setPropertyName(v.name);
    setaptId(v._id);
  };

  const setMonthlyRentfun = e => {
    // const onlyNums = e.target.value.replace(/[^0-9]?./g, "");
    let str = e.target.value;
    let patt = /[^(\d+)\.(\d+)]/g;
    let s = str.replace(patt, '');
    if (s !== '') {
      let count = s.split('.').length - 1;
      count > 1 ? setMonthlyRent('') : setMonthlyRent(parseFloat(s));
    } else {
      setMonthlyRent('');
    }
  };
  const setDepositfun = e => {
    // const onlyNums = e.target.value.replace(/[^0-9.]+/g, "");
    let str = e.target.value;
    let patt = /[^(\d+)\.(\d+)]/g;
    let s = str.replace(patt, '');
    if (s !== '') {
      let count = s.split('.').length - 1;
      count > 1 ? setDeposit('') : setDeposit(parseFloat(s));
    } else {
      setDeposit('');
    }
  };

  //LifeCycleBlock
  useEffect(() => {
    dispatch(getAllAccessibilityAction());
    dispatch(getAllUtilityAction());
    dispatch(getLeaseLengthAction());
    dispatch(getAllPetPolicyAction());
    dispatch(getAllAmenitiesAction());
    dispatch(getAllAppliancesAction());
    dispatch(getAllPropertyAction());
    dispatch(getAllBuildingAction());
  }, []);
  useEffect(() => {
    if (props.propertyType === 'EDIT') {
      if (
        props.PropertyObj &&
        props.PropertyObj.data.propType === 'Apartment'
      ) {
        setPropertyName(props.PropertyObj && props.PropertyObj.data.aptId.name);
      }
      if (
        props.PropertyObj &&
        props.PropertyObj.data.propType === 'SingleFamily'
      ) {
        setPropertyName(props.PropertyObj && props.PropertyObj.data.name);
      }
      setDeposit(props.PropertyObj && props.PropertyObj.data.deposit);
      setPropertyAddress({
        description: props.PropertyObj && props.PropertyObj.data.addressL1
      });
      setaptId(props.PropertyObj && props.PropertyObj.data.aptId);
      setPetAllowed(
        props.PropertyObj &&
          props.PropertyObj.data.pets &&
          props.PropertyObj.data.pets.allowed
      );
      setCustom(
        props.PropertyObj &&
          props.PropertyObj.data.pets &&
          props.PropertyObj.data.pets.customResp
      );
      setallowRegAnimals(
        props.PropertyObj &&
          props.PropertyObj.data.pets &&
          props.PropertyObj.data.pets.allowRegAnimals
      );
      setUnitName(props.PropertyObj && props.PropertyObj.data.unitName);
      setBedrooms(props.PropertyObj && props.PropertyObj.data.bedRooms);
      setBathrooms(props.PropertyObj && props.PropertyObj.data.bathRooms);
      setMonthlyRent(props.PropertyObj && props.PropertyObj.data.monthlyRent);
      setType(props.PropertyObj && props.PropertyObj.data.propType);
      setDiscription(props.PropertyObj && props.PropertyObj.data.discription);
      setpowderRooms(props.PropertyObj && props.PropertyObj.data.powderRooms);
      setheroImage(
        props.PropertyObj &&
          props.PropertyObj.data.heroImage &&
          props.PropertyObj.data.heroImage[0]
      );
      settempFile(props.PropertyObj && props.PropertyObj.data.files);
      setaccesibility(props.PropertyObj && props.PropertyObj.data.accesibility);
      setutility(props.PropertyObj && props.PropertyObj.data.utilities);
      setLeaseLength(props.PropertyObj && props.PropertyObj.data.leaselength);
      setpetsPolicy(
        props.PropertyObj &&
          props.PropertyObj.data.pets &&
          props.PropertyObj.data.pets.allowedPets
      );
      setgeneralAmneties(
        props.PropertyObj && props.PropertyObj.data.generalAmneties
      );
      setAppliance(props.PropertyObj && props.PropertyObj.data.appliances);
      setArea(props.PropertyObj && props.PropertyObj.data.sqft);
      setAvailableFrom(props.PropertyObj && props.PropertyObj.data.avlFrom);
      setincomeReq(props.PropertyObj && props.PropertyObj.data.incomeReq);
      setallowSection8(
        props.PropertyObj && props.PropertyObj.data.allowSection8
      );
      setemergencyAvailable(
        props.PropertyObj && props.PropertyObj.data.emergencyAvailable
      );
      setsubleasing(props.PropertyObj && props.PropertyObj.data.subleasing);
      setrentalApplicationFee(
        props.PropertyObj && props.PropertyObj.data.rentalApplicationFee
      );
      setcovidSplReq(props.PropertyObj && props.PropertyObj.data.covidSplReq);
      setholdDuration(props.PropertyObj && props.PropertyObj.data.holdDuration);
      setflooring(props.PropertyObj && props.PropertyObj.data.flooring);
      setguestPolicy(props.PropertyObj && props.PropertyObj.data.guestPolicy);
      setnoticePeriod(props.PropertyObj && props.PropertyObj.data.noticePeriod);
      setearlyBreak(props.PropertyObj && props.PropertyObj.data.earlyBreak);
      setpersonalization(
        props.PropertyObj && props.PropertyObj.data.personalization
      );
      setnumKeys(props.PropertyObj && props.PropertyObj.data.numKeys);
      setnoRefundRules(
        props.PropertyObj && props.PropertyObj.data.noRefundRules
      );
      setlowestCredit(props.PropertyObj && props.PropertyObj.data.lowestCredit);
    }
  }, [props.PropertyObj, props.propertyType]);

  useEffect(() => {
    if (props.PlaceObj.data && props.PlaceObj.data.predictions.length > 0) {
      setPlaceObj(props.PlaceObj && props.PlaceObj.data.predictions);
    }
  }, [props.PlaceObj]);

  return (
    <Layout history={props.history}>
      <AddAparatment open={openModal} setOpen={setopenModal} />
      {props.propertyType !== 'EDIT' && (
        <ConfirmModal
          open={open}
          setOpen={setopen}
          proceed={SaveProperty}
          propVal={'listed'}
          Msg={'ARE YOU SURE WANT TO PUBLISH THIS LISTING ?'}
          identifier={'property'}
        />
      )}
      <Card style={{ width: '90vw', paddingBottom: '20px' }}>
        <CardContent>
          <Grid container spacing={3} style={Common}>
            <Grid container style={{ margin: '20px' }}>
              <Grid item xs={6} style={HeaderStyle}>
                <CloseIcon
                  style={closeIconStyle}
                  onClick={() => closeButton()}
                />
                <div>
                  {props.propertyType !== 'EDIT'
                    ? 'New Property'
                    : 'Edit Property'}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {props.propertyType === 'EDIT' ? (
                  <Button
                    variant="contained"
                    style={SaveMoreStyle}
                    onClick={() => openCraiglist()}
                  >
                    post to Craigslist
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={SaveMoreStyle}
                    onClick={() => SaveProperty('draft')}
                  >
                    Save As Draft
                  </Button>
                )}
                <Button
                  variant="contained"
                  style={SavePropertyStyle}
                  onClick={() => ModalOpen()}
                >
                  Publish
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{ ...BlockHeading, marginBottom: '12px' }}
              >
                Property Type *
              </Grid>
              <FormControl
                variant="outlined"
                style={{ width: '100%' }}
                size="small"
              >
                <Select
                  error={Type === null && Validator}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={setTypefun}
                  value={Type}
                  fullWidth
                >
                  <MenuItem value={'SingleFamily'}>Single Family</MenuItem>
                  <MenuItem value={'Apartment'}>Apartment</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{ ...BlockHeading, marginBottom: '12px' }}
              >
                Property Name *
              </Grid>
              {/* add check for Apartmet Select with PopUpModal */}
              {Type !== 'Apartment' && (
                <TextField
                  key={
                    props.propertyType === 'EDIT'
                      ? `${Math.floor(Math.random() * 1000)}-min`
                      : ''
                  }
                  error={
                    Validator && (PropertyName === null || PropertyName === '')
                  }
                  variant="outlined"
                  onBlur={event => setPropertyName(event.target.value)}
                  fullWidth
                  defaultValue={PropertyName}
                  size="small"
                />
              )}
              {Type === 'Apartment' && (
                <FormControl
                  variant="outlined"
                  style={{ width: '100%' }}
                  size="small"
                >
                  {/* <Select
                    error={Validator && PropertyName === null}
                    labelId="ApartmentSelect"
                    id="Apartment"
                    onChange={(event, child) => HandleApartment(event, child)}
                    value={PropertyName}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.ApartmentList &&
                      props.ApartmentList.data.map((res) => {
                        return (
                          <MenuItem id={res._id} value={res.name}>
                            {res.name}
                          </MenuItem>
                        );
                      })}
                  </Select> */}
                  <Autocomplete
                    size="small"
                    id="combo-box-demo"
                    options={props?.ApartmentList || []}
                    getOptionLabel={option => option?.name}
                    onChange={(event, value) => HandleApartment(value)}
                    disableClearable={true}
                    defaultValue={{ name: PropertyName }}
                    renderInput={params => (
                      <TextField
                        size="small"
                        error={
                          Validator &&
                          (PropertyName === null || PropertyName === '')
                        }
                        {...params}
                        label="Select Apartment"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              )}
              {/* <span
                style={fireAppartmentStyle}
                onClick={() => CreateAppartment()}
              >
                Add New Aparatment
              </span> */}
            </Grid>

            <Grid
              item
              xs={12}
              style={
                // Array.isArray(tempFile) && Validator && !tempFile.length
                //   ? { color: "red" }
                //   : BlockHeading
                BlockHeading
              }
            >
              Upload Property Images *
              {/* {Message && (
                <p style={{ color: "red" }}>Atleast one image required</p>
              )} */}
              {/* {CoverMessage && (
                <p style={{ color: "red" }}>Select any image for cover image</p>
              )} */}
            </Grid>
            <Grid item xs={12}>
              {/* image upload Section */}
              <div style={{ display: 'flex' }}>
                {props.propertyType === 'EDIT' && !tempFile && (
                  <CircularProgress style={{ padding: '32px' }} />
                )}
                <ImageUpload
                  propertyType={props.propertyType}
                  tempFile={tempFile}
                  settempFile={settempFile}
                  setheroImage={setheroImage}
                  heroImage={heroImage}
                  Message={Message}
                  setMessage={setMessage}
                  CoverMessage={CoverMessage}
                  setCoverMessage={setCoverMessage}
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <FormControl>
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Unit Name *
                </Grid>
                <TextField
                  key={
                    props.propertyType === 'EDIT'
                      ? `${Math.floor(Math.random() * 1000)}-min`
                      : ''
                  }
                  error={(UnitName === null || UnitName === '') && Validator}
                  style={{
                    minWidth: '140px',
                    marginRight: '20px'
                  }}
                  defaultValue={UnitName}
                  variant="outlined"
                  onBlur={event => setUnitName(event.target.value)}
                  size="small"
                />
              </FormControl>
              <FormControl variant="outlined" size="small">
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Monthly Rent *
                </Grid>
                <OutlinedInput
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  error={
                    Validator && (MonthlyRent === null || MonthlyRent === '')
                  }
                  style={{ width: '120px', marginRight: '20px' }}
                  id="outlined-adornment-amount"
                  defaultValue={MonthlyRent}
                  onBlur={e => setMonthlyRentfun(e)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                style={{ minWidth: '120px', marginRight: '20px' }}
                size="small"
              >
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Bedrooms *
                </Grid>
                <Select
                  error={Validator && Bedrooms === null}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={event => setBedrooms(event.target.value)}
                  value={Bedrooms}
                >
                  {_.times(15, i => (
                    <MenuItem
                      key={`${Math.floor(Math.random() * 1000)}-min`}
                      value={i + 1}
                    >
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                style={{ minWidth: '120px', marginRight: '20px' }}
                size="small"
              >
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Bathrooms *
                </Grid>
                <Select
                  error={Validator && Bathrooms === null}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={event => setBathrooms(event.target.value)}
                  value={Bathrooms}
                >
                  {_.times(15, i => (
                    <MenuItem
                      key={`${Math.floor(Math.random() * 1000)}-min`}
                      value={i + 1}
                    >
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                style={{ minWidth: '120px', marginRight: '20px' }}
                size="small"
              >
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Half Bathroom
                </Grid>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={event => setpowderRooms(event.target.value)}
                  value={powderRooms}
                >
                  <MenuItem value="">
                    <em>0</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small">
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Deposit *
                </Grid>
                <OutlinedInput
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  error={Validator && (Deposit === null || Deposit === '')}
                  style={{ width: '120px', marginRight: '20px' }}
                  id="outlined-adornment-amount"
                  defaultValue={Deposit}
                  onBlur={e => setDepositfun(e)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {Type !== 'Apartment' && (
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Property Address *
                </Grid>
                <Autocomplete
                  id="combo-box-demo"
                  options={PlaceObj || []}
                  value={PropertyAddress}
                  getOptionLabel={option => option?.description}
                  onChange={(event, value) => AutoCompHandleChange(value)}
                  disableClearable={true}
                  renderInput={params => (
                    <TextField
                      error={Validator && PropertyAddress === null}
                      {...params}
                      variant="outlined"
                      size="small"
                      onChange={event => placeHandleChange(event)}
                      placeholder="please type to search address & select from dropdown options only"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                style={{ ...BlockHeading, marginBottom: '12px' }}
              >
                Property Description *
              </Grid>
              <TextField
                key={
                  props.propertyType === 'EDIT'
                    ? `${Math.floor(Math.random() * 1000)}-min`
                    : ''
                }
                size="small"
                multiline
                rows={3}
                rowsMax={100}
                error={Validator && Discription === null}
                variant="outlined"
                onBlur={event => setDiscription(event.target.value)}
                fullWidth
                defaultValue={Discription}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ minWidth: '200px', marginRight: '20px' }}
                size="small"
              >
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Lease Lengths
                </Grid>
                <Select
                  labelId="LeaseLength"
                  id="LeaseLength"
                  onChange={event => handleLeaseLength(event)}
                  value={LeaseLength}
                  // fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'one'}>One Year</MenuItem>
                  <MenuItem value={'morethanone'}>More than one year</MenuItem>
                  <MenuItem value={'lessthanone'}>Less than one year</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Area in SqFt
                </Grid>
                <TextField
                  key={
                    props.propertyType === 'EDIT'
                      ? `${Math.floor(Math.random() * 1000)}-min`
                      : ''
                  }
                  size="small"
                  type="number"
                  style={{
                    minWidth: '140px',
                    marginRight: '20px'
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  defaultValue={Area}
                  variant="outlined"
                  onBlur={event => {
                    event.target.value < 0
                      ? (event.target.value = 0)
                      : setArea(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <Grid
                  item
                  xs={12}
                  style={{ ...BlockHeading, marginBottom: '12px' }}
                >
                  Available From
                </Grid>
                <TextField
                  key={
                    props.propertyType === 'EDIT'
                      ? `${Math.floor(Math.random() * 1000)}-min`
                      : ''
                  }
                  size="small"
                  defaultValue={DateStrip(AvailableFrom)}
                  variant="outlined"
                  id="date"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={e => setAvailableFrom(e.target.value)}
                />
              </FormControl>
            </Grid>

            {Type !== 'Apartment' && (
              <>
                {' '}
                <Grid item xs={12} style={BlockHeading}>
                  Utility
                </Grid>
                <Utility setutility={setutility} utility={utility} />
                <Grid
                  container
                  style={{
                    marginLeft: '12px',
                    marginBottom: '10px'
                  }}
                ></Grid>
                <Grid item xs={12} style={BlockHeading}>
                  Pet Policy
                </Grid>
                <Petpolicy
                  PetAllowed={PetAllowed}
                  setPetAllowed={setPetAllowed}
                  setallowRegAnimals={setallowRegAnimals}
                  allowRegAnimals={allowRegAnimals}
                  handlepetsPolicy={handlepetsPolicy}
                  petsPolicy={petsPolicy}
                  Custom={Custom}
                  setCustom={setCustom}
                />
              </>
            )}

            <Grid item xs={12} style={BlockHeading}>
              General Amenities
            </Grid>
            <GeneralAmenities
              data={props.Amenities.data && props.Amenities.data}
              handlegeneralAmneties={handlegeneralAmneties}
              generalAmneties={generalAmneties}
            />

            <Grid item xs={12} style={BlockHeading}>
              Appliances
            </Grid>
            <Appliances
              key={`${Math.floor(Math.random() * 1000)}-min`}
              data={props.Appliances.data && props.Appliances.data}
              handleAppliance={handleAppliance}
              Appliance={Appliance}
            />

            <Grid item xs={12} style={BlockHeading}>
              Accessibility
            </Grid>
            <Grid
              container
              style={{ marginLeft: '12px', marginBottom: '10px' }}
            >
              <Accessibility
                data={props.Accessibility.data && props.Accessibility.data}
                handleAccesibility={handleAccesibility}
                accesibility={accesibility}
              />
            </Grid>

            {/* Accordion Block start from here------------------> */}
            <Accordion style={{ width: '100%' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Additional Information </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} style={Common}>
                  <Grid
                    container
                    style={{
                      marginTop: '30px',
                      marginLeft: '12px',
                      marginBottom: '10px'
                    }}
                  >
                    <Grid item xs={6}>
                      <Grid style={{ marginBottom: '8px' }}>
                        <Grid
                          item
                          xs={12}
                          style={{ ...BlockHeading, marginBottom: '12px' }}
                        >
                          What is your gross monthly income requirement?
                        </Grid>
                      </Grid>
                      <Grid>
                        <TextField
                          key={
                            props.propertyType === 'EDIT'
                              ? `${Math.floor(Math.random() * 1000)}-min`
                              : ''
                          }
                          size="small"
                          defaultValue={incomeReq}
                          variant="outlined"
                          onBlur={event => setincomeReq(event.target.value)}
                          style={{
                            width: '100px',
                            height: '46px',
                            marginRight: '16px'
                          }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            marginLeft: '120px',
                            marginTop: '-38px'
                          }}
                        >
                          times the monthly rent.
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item style={BlockHeading}>
                        Do you allow section 8?
                      </Grid>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={allowSection8}
                          onChange={event =>
                            setallowSection8(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio color="primary" />}
                            label={
                              <Typography style={typoGraph}>{'Yes'}</Typography>
                            }
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio color="primary" />}
                            label={
                              <Typography style={typoGraph}>{'No'}</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      What is the lowest credit score you are okay with for a
                      lead?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setlowestCredit(event.target.value)}
                      defaultValue={lowestCredit}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      What is the Rental Application Fee?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event =>
                        setrentalApplicationFee(event.target.value)
                      }
                      defaultValue={rentalApplicationFee}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      What's the procedure for submitting a maintenance request
                      (assuming not COVID times), how long does it take to be
                      addressed?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setcovidSplReq(event.target.value)}
                      defaultValue={covidSplReq}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      How long can I hold the unit when i pay the deposit?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setholdDuration(event.target.value)}
                      defaultValue={holdDuration}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      Flooring
                    </Grid>

                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      size="small"
                    >
                      <Select
                        labelId="flooring"
                        id="flooring"
                        onChange={event => setflooring(event.target.value)}
                        value={flooring}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'Carpet'}>Carpet</MenuItem>
                        <MenuItem value={'hardwood'}>Hardwood</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      What is the guest policy?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setguestPolicy(event.target.value)}
                      defaultValue={guestPolicy}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      How much notice do tenants give before vacating?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setnoticePeriod(event.target.value)}
                      defaultValue={noticePeriod}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item style={BlockHeading}>
                      Is there emergency maintenance available?
                    </Grid>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={emergencyAvailable}
                        onChange={event =>
                          setemergencyAvailable(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio color="primary" />}
                          label={
                            <Typography style={typoGraph}>{'Yes'}</Typography>
                          }
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio color="primary" />}
                          label={
                            <Typography style={typoGraph}>{'No'}</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item style={BlockHeading}>
                      Are you open to subleasing?
                    </Grid>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={subleasing}
                        onChange={event => setsubleasing(event.target.value)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio color="primary" />}
                          label={
                            <Typography style={typoGraph}>{'Yes'}</Typography>
                          }
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio color="primary" />}
                          label={
                            <Typography style={typoGraph}>{'No'}</Typography>
                          }
                        />
                        <FormControlLabel
                          value="Sometimes"
                          control={<Radio color="primary" />}
                          label={
                            <Typography style={typoGraph}>
                              {'Sometimes'}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      What are the policies around breaking the lease early?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setearlyBreak(event.target.value)}
                      defaultValue={earlyBreak}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      To what extent can the unit be personalized?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setpersonalization(event.target.value)}
                      defaultValue={personalization}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      How many keys does the property come with?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setnumKeys(event.target.value)}
                      defaultValue={numKeys}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      style={{ ...BlockHeading, marginBottom: '12px' }}
                    >
                      Under what circumstances would I not receive my deposit
                      back?
                    </Grid>
                    <TextField
                      key={
                        props.propertyType === 'EDIT'
                          ? `${Math.floor(Math.random() * 1000)}-min`
                          : ''
                      }
                      onBlur={event => setnoRefundRules(event.target.value)}
                      defaultValue={noRefundRules}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {props.propertyType === 'EDIT' ? (
                <Button
                  variant="contained"
                  style={SaveMoreStyle}
                  onClick={() => openCraiglist()}
                >
                  post to Craigslist
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={SaveMoreStyle}
                  onClick={() => SaveProperty('draft')}
                >
                  Save As Draft
                </Button>
              )}
              <Button
                variant="contained"
                style={SavePropertyStyle}
                onClick={() => ModalOpen()}
              >
                Publish
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
};

const mapStateToProps = state => ({
  Accessibility: state.Accessibility.AccessibilityData,
  Utility: state.Utility.UtilityData,
  LeaseLength: state.LeaseLength.LeaseLengthData,
  PetPolicy: state.PetPolicy.PetPolicyData,
  Amenities: state.Amenities.AmenitiesData,
  Appliances: state.Appliances.AppliancesData,
  PropertyType: state.Property.PropertyAction,
  PropertyEditId: state.Property.PropertyEditId,
  PropertyData: state.Property.PropertyData,
  PlaceObj: state.Property.PlaceObj,
  ApartmentList: state.Apartment?.BuildingData?.data
});

export default connect(mapStateToProps)(PropertyAdd);
