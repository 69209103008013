import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../utils/ContextProviders/GlobalContextProvider';
import { useLocation } from 'react-router-dom';
import NoAccess from './NoAccess';
import { getCookie } from '../utils';

const Wrapper = ({ children }) => {
  const { fetchUserData, sideNavRoutes } = useContext(GlobalContext);
  const [access, setAccess] = useState(false);
  const path = useLocation()?.pathname;
  const fetchUserProfileData = () => {
    fetchUserData();
  };
  const searchParams = useLocation().search?.slice(7);
  const tokenKey = useLocation().search?.slice(1, 6);
  const prevToken = getCookie('rb_access_token');
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (path === '/' && tokenKey === 'token' && searchParams) {
      setToken(searchParams);
    } else if (!searchParams) {
      setToken(prevToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if (sideNavRoutes?.length) {
      const initialPath = path?.split('/')[1];
      let isAccessible = false;
      for (let i = 0; i < sideNavRoutes.length; i++) {
        if (sideNavRoutes[i].includes(`/${initialPath}`)) {
          isAccessible = true;
          setAccess(true);
          break;
        }
      }

      if (!isAccessible) {
        setAccess(false);
      }
    } else {
      setAccess(true);
    }
  }, [path, sideNavRoutes]);
  useEffect(() => {
    if (token !== null) {
      fetchUserProfileData();
    }
  }, [token]);

  if (!access) {
    return <NoAccess />;
  } else return children;
};

export default Wrapper;
