// property > (Utility.js , Petpolicy.js , GeneralAmenities.js , Appliances.js , Accessibility,js)

// Utility.js
export const HeadingStyle = {
  marginBottom: '10px',
  font: 'normal 14px Inter',
  paddingLeft: '10px'
};
export const imgStyle = {
  marginRight: '10px'
};
export const Containert = {
  marginBottom: '15px'
};

// PetPolicy.js
export const HeadingPPStyle = {
  marginBottom: '10px',
  font: 'normal 14px Inter'
};
export const imgPPStyle = {
  marginRight: '10px'
};
export const Container = {
  marginBottom: '15px',
  marginRight: '20px'
};
export const typoGraph = {
  font: 'normal 14px Inter'
};

// GeneralAmenities.js
export const HeadingGAStyle = {
  marginBottom: '10px',
  font: 'normal 14px Inter'
};
export const ContainerGA = {
  marginBottom: '10px'
};

// Appliances.js  and Accessibility.js
export const HeadingAStyle = {
  marginBottom: '10px',
  font: 'normal 14px Inter'
};
export const ContainerA = {
  marginBottom: '10px'
};
