import { MaterialDesignContent } from 'notistack';
import styled from 'styled-components';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-warning': {
    background: '#FFEDE5',
    color: '#FF4D00',
    width: '210px'
  },
  '&.notistack-MuiContent-success': {
    background: ' #DFEDE8;',
    color: '#00875A',
    width: '210px'
  }
}));

export { StyledMaterialDesignContent };
