import React from 'react';
import CallToTextComponent from '../../Component/CallToTextComponent';
import Layout from '../../Component/layout';
const CallToTextView = props => {
  return (
    <Layout history={props.history}>
      <CallToTextComponent />
    </Layout>
  );
};

export default CallToTextView;
