import React from 'react';

const NotesWithData = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ cursor: 'pointer', marginTop: '-1px' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V14.6667C4 15.0203 4.14048 15.3594 4.39052 15.6095C4.64057 15.8595 4.97971 16 5.33333 16H10.6667L16 10.6667V5.33333C16 4.97971 15.8595 4.64057 15.6095 4.39052C15.3594 4.14048 15.0203 4 14.6667 4ZM10 14.6667V10H14.6667L10 14.6667Z"
        fill="#525F80"
      />
    </svg>
  );
};

export default NotesWithData;
