import { GET_TOAST } from '../Constant';

const initialState = {
  toast: false,
  severity: '',
  msg: ''
};
const Toast = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOAST:
      return {
        ...state,
        toast: action.data.tost,
        severity: action.data.severity,
        msg: action.data.msg
      };
    default:
      return state;
  }
};

export default Toast;
