import { gql } from '@apollo/client';

export const fetchApartmentById = gql`
  query apartmentById($apartmentId: MongoID!) {
    apartmentById(_id: $apartmentId) {
      name
      _id
      meta {
        knockHouseAgentUserName
        knockKelseyUserName
      }
      timeZone
      CallToTextEnabled
      phone
      purchasedNumber
    }
  }
`;

export const fetchApartmentByIdC2T = gql`
  query apartmentById($apartmentId: MongoID!) {
    apartmentById(_id: $apartmentId) {
      name
      _id
      zumaIvr
      timeZone
      existingForwardingNumber
      existingType
      existingVoiceUrl
      textableVoiceUrl
      existingVoiceName
      nonTextableVoiceUrl
      nonTextableVoiceName
      slots {
        _id
      }
      textableVoiceName
      CallToTextEnabled
      phone
      purchasedNumber
      zumaIvrPhone
      voicemailUrl
      voicemailName
      tenantId
      zumaIVRSetting {
        ivrType
        isEnabled
        vanityPhoneNumber
        voicemail
        slots {
          name
          enabled
          description
          action
          forwardTo
        }
      }
    }
  }
`;
