import {
  get,
  post,
  patch,
  postKelsey,
  getKelsey,
  patchKelsey,
  putKelsey,
  deleteKelsey
} from '../../networkClient';
import { Path } from '../path';
export function getSessionData(leadId) {
  return getKelsey(`${Path.GET_SESSION_DATA}?lead_id=${leadId}`);
}

export function getMasterCheatsheetByTenant(tenantId) {
  return getKelsey(`${Path.GET_MASTER_CHEATSHEET}?tenant_id=${tenantId}`, {
    api_key: 'test123'
  });
}

export function updateCheatsheetQuestion(apartmentId, body) {
  return patchKelsey(
    `${Path.UPDATE_CHEATSHEET_QUESTIONS}?apartment_id=${apartmentId}`,
    body,
    {
      api_key: 'test123'
    }
  );
}

export function addQuestionAcrossCommunities(tenant_id, body) {
  return putKelsey(
    `${Path.ADD_CHEATSHEET_QUESTION_ACROSS_COMMUNITIES}?tenant_id=${tenant_id}`,
    body,
    {
      api_key: 'test123'
    }
  );
}
export function addCheatsheetQuestionAnswer(apartmentId, body) {
  return putKelsey(
    `${Path.ADD_CHEATSHEET_QUESTION_ANSWERS}?apartment_id=${apartmentId}`,
    body,
    {
      api_key: 'test123'
    }
  );
}
export function deleteQuestionAcrossCommunities(tenant_id, body) {
  return deleteKelsey(
    `${Path.DELETE_CHEATSHEET_QUESTION_ACROSS_COMMUNITIES}?tenant_id=${tenant_id}`,
    body
  );
}

export function getKBCheatsheetByApartment(aptId) {
  return getKelsey(`${Path.GET_KB_CHEATSHEET}?apartment_id=${aptId}`, {
    api_key: 'test123'
  });
}

export function tiggerKelsey(reqObj) {
  return post(Path.TRIGGER_KELSEY, reqObj);
}
export function updateContextApi(data) {
  return postKelsey(Path.UPDATE_CONTEXT_AI, data);
}
export function updateSession(data) {
  return postKelsey(`${Path.UPDATE_SESSION_DATA}`, data);
}

export function getLeadMessageApi({ leadId }) {
  return get(Path.GET_ALL_MESSAGES, { id: leadId });
}

export function getAllPropertyApartmentApi(apartmentId) {
  return get(`${Path.GET_PROPERTY_APARTMENT}?apartmentId=${apartmentId}`, {});
}

export function getInboxMetaData({ leadId }) {
  return get(`${Path.GET_INBOX_META}${leadId}`);
}

export function sendNewMessageApi({ messageDetails }) {
  return post(`${Path.SEND_NEW_MESSAGE}`, { ...messageDetails });
}

export function getApartmentsApi() {
  return get(`${Path.GET_ALL_APARTMENT_BY_ID}/allApartmentsByTenant`);
}

export function getTemplateApi({ leadId, propId }) {
  return get(Path.GET_ALL_TEMPLATES, { leadId, propId });
}

export function getTimeSlotsApi({ communityId, leadId, tourType }) {
  return get(Path.GET_TIMESLOTS, { leadId, communityId, tourType });
}

export function createTourApi({ id, data }) {
  return post(`${Path.CREATE_TOUR}`, data);
}

export function deleteTourApi({ tourId, cancelReason }) {
  return post(`${Path.DELETE_TOUR}`, { tourId: tourId, cancelReason });
}

export function confirmTourApi({ id }) {
  return get(`${Path.GET_TOUR_CONFIRMATION}?tourId=${id}`);
}

export function createTourNotesApi({ notesPayload }) {
  return post(`${Path.CREATE_TOUR_NOTES}`, { ...notesPayload });
}

export function getNotesByTourIdApi({ id }) {
  return get(`${Path.GET_TOUR_NOTES_BY_TOURID}?tourId=${id}`);
}
export function getConversationSummaryApi({ leadId }) {
  return get(`${Path.GET_CONVERSATION_SUMMARY}?leadId=${leadId}`);
}

export function changeStageApi({ stagePayload }) {
  return patch(`${Path.UPDATE_STAGE}`, { ...stagePayload });
}

export function changeAgentApi({ agentPayload }) {
  return patch(`${Path.GET_INBOX_META}agent`, { ...agentPayload });
}

export function downloadApartmentCheatsheetApi({ id }) {
  return get(`${Path.APARTMENT_CHEATSHEET}?id=${id}`);
}

export function getPricingListings({ apartmentPayload }) {
  const { apartmentId, moveInDate } = apartmentPayload;

  const month = new Date(moveInDate).getMonth() + 1;
  const moveIn = `${new Date(moveInDate).getFullYear()}-${
    month >= 10 ? month : `0${month}`
  }-${new Date(moveInDate).getDate()}`;
  const paramUrl = getParamsUrl({
    moveIn,
    apartmentId,
    moveInDate
  });
  return get(`${Path.GET_PRICING_LISTINGS}?${paramUrl}`);
}

const getParamsUrl = params => {
  let url = `apartmentId=${params.apartmentId}`;
  if (params?.moveInDate && params?.moveIn) {
    url += `&moveInDate=${params.moveIn}`;
  }
  return url;
};
