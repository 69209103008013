import React from 'react';
import Filler from './Filler';
import { Milestone, ProgressContainer } from './progress-bar.styles';
import KelseyStatusBlinkGif from '../../../Media/KelseyInsight/kelsey-status-blink-1.gif';

const ProgressBar = ({ length, color, value }) => {
  return (
    <ProgressContainer length={length}>
      {value === 0 ? (
        <img height={15} width={15} src={KelseyStatusBlinkGif} alt="blink" />
      ) : (
        <Milestone color={value > 0 ? '#67C848' : '#C4C4C4'} />
      )}
      <Filler color={color} value={value} />
      {value === 55 ? (
        <img height={15} width={15} src={KelseyStatusBlinkGif} alt="blink" />
      ) : (
        <Milestone color={value >= 100 ? '#67C848' : '#C4C4C4'} />
      )}
    </ProgressContainer>
  );
};

export default ProgressBar;
