import styled from 'styled-components';

const AvatarContainer = styled.div`
  height: ${({ theme, size }) => theme?.size?.avatar[size]};
  width: ${({ theme, size }) => theme?.size?.avatar[size]};
  border-radius: 6px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const AvatarImage = styled.img`
  height: 100%;
  width: 100%;
`;

export { AvatarContainer, AvatarImage };
