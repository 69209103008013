import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  left: -20.5%;
  top: 0;
  width: 230%;
  height: 100vh;
  z-index: 10000;
  background-color: ${({ theme }) => theme.background.secondary};
  pointer-events: ${({ disable }) => disable && 'none'};
`;

export { Wrapper };
