import styled from 'styled-components';
const IvrOptionsWrapper = styled.div``;
const VanityNumbersHeading = styled.div`
  color: #393939;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
`;

export { IvrOptionsWrapper, VanityNumbersHeading };
