import React from 'react';

const Edit = props => {
  return (
    <svg
      width="16"
      onClick={props?.onClick}
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.68001 12.8203C2.65793 12.9283 2.66298 13.0401 2.6947 13.1457C2.72642 13.2512 2.78382 13.3473 2.86177 13.4252C2.93971 13.5032 3.03576 13.5606 3.14133 13.5923C3.2469 13.624 3.35868 13.6291 3.46667 13.607L6.00668 13.087L3.20001 10.2803L2.68001 12.8203ZM6.62734 11.0737L5.21401 9.66033L10.8707 4.00033H10.872L12.286 5.41433L6.62667 11.0743L6.62734 11.0737ZM13.2293 4.47166L11.816 3.05699C11.6921 2.93305 11.5449 2.83479 11.3829 2.76787C11.2209 2.70094 11.0473 2.66666 10.872 2.66699C10.5307 2.66699 10.1893 2.79699 9.92867 3.05699L3.42401 9.56233L6.72401 12.8623L13.2287 6.35699C13.4786 6.10696 13.6191 5.76788 13.6191 5.41433C13.6191 5.06078 13.4786 4.7217 13.2287 4.47166H13.2293Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default Edit;
