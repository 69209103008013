import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callToTextUpdate } from '../../../Redux/Actions/Apartment';
import CallTextLayout from './CallTextLayout';
import './styles/callText.scss';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';
import ZSwitch from '../../../globalcomponents/ZSwitch';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getTenantById } from '../../../gql/queries/tenantDetails';
import {
  updateApartmentById,
  updateIvrSetting
} from '../../../gql/mutations/apartment';
import { slotsMapping } from '../Constants';
import { fetchApartmentById } from '../../../gql/queries/apartmentById';

const useStyles = makeStyles(theme => ({
  label: {
    color: '#42526E',
    fontSize: '16px',
    fontWeight: '500'
  }
}));

const CallToTextV1 = ({ checked, property, entrataIVRAndCTT }) => {
  const { CallToTextEnabled } = property || {};
  const [showCallToText, setShowCallToText] = useState(CallToTextEnabled);
  const [showZumaIvr, setShowZumaIvr] = useState(entrataIVRAndCTT?.isEnabled);
  const [updatedCallData, setUpdateCallData] = useState({});

  const [slotsEditable, setSlotsEditable] = useState(false);
  const classes = useStyles();
  const { userData } = useContext(GlobalContext);
  useEffect(() => {
    setUpdateCallData({
      ...updatedCallData,
      CallToTextEnabled: showCallToText,
      zumaIvr: showZumaIvr,
      id: property?.data?._id || property?._id
    });
  }, [showCallToText, showZumaIvr]);

  useEffect(() => {
    setShowCallToText(CallToTextEnabled);
    setShowZumaIvr(entrataIVRAndCTT?.isEnabled);
  }, [CallToTextEnabled, entrataIVRAndCTT?.isEnabled]);

  useEffect(() => {
    if (property?.data) {
      setUpdateCallData({ id: property?.data?._id || property?._id });
    }
  }, [property?.data]);
  const [fetchTenantDetails, { data: { tenantById = {} } = {} }] =
    useLazyQuery(getTenantById);
  useEffect(() => {
    property?.tenantId &&
      fetchTenantDetails({ variables: { tenantId: property?.tenantId } });
  }, [property?.tenantId]);

  const [updateApartment] = useMutation(updateApartmentById);

  const dispatch = useDispatch();

  const [updateSlotsData] = useMutation(updateIvrSetting, {
    onCompleted: () => {
      setSlotsEditable(false);
    },

    refetchQueries: [
      { query: fetchApartmentById, variables: { apartmentId: property?._id } }
    ]
  });
  const audioMapping = {
    audio: 'AUDIO',
    number: 'FORWARDING_NUMBER',
    'call-text': 'CALL_TO_TEXT',
    LEAD_INGESTION: 'LEAD_INGESTION'
  };

  const handleSave = () => {
    let existingSlotData = property?.zumaIVRSetting?.slots
      ? [...property?.zumaIVRSetting?.slots]
      : [];
    for (let i in updatedCallData?.slots) {
      let slot = updatedCallData?.slots[i];

      let slotIndex = existingSlotData?.findIndex(
        ({ name }) => name === slotsMapping[i]
      );
      let existSlotData = existingSlotData[slotIndex];

      existSlotData = {
        ...existSlotData,
        enabled: slot?.checked,
        description: slot?.kelseyText,
        action: slot?.type ? audioMapping[slot.type] : 'NONE',
        forwardTo:
          slot?.type === 'number'
            ? slot?.numberValue
            : slot?.type === 'audio'
            ? slot?.audioValue
            : ''
      };
      existingSlotData[slotIndex] = existSlotData;
    }
    updateSlotsData({
      variables: {
        aptId: property?._id,
        type: 'old',
        record: {
          zumaIVRSetting: {
            isEnabled:
              updatedCallData?.zumaIvr !== undefined
                ? updatedCallData?.zumaIvr
                : property?.zumaIVRSetting?.isEnabled,
            ivrType:
              updatedCallData?.ivrType !== undefined
                ? updatedCallData?.ivrType
                : property?.zumaIVRSetting?.ivrType,
            voicemail:
              updatedCallData?.voicemail !== undefined
                ? updatedCallData?.voicemail
                : property?.zumaIVRSetting?.voicemail,
            vanityPhoneNumber:
              updatedCallData?.zumaIvrPhone !== undefined
                ? updatedCallData?.zumaIvrPhone
                : property?.zumaIVRSetting?.vanityPhoneNumber,
            slots: existingSlotData
          }
        }
      }
    });
  };

  const handleCallToText = e => {
    setShowCallToText(e.target.checked);
    const updatedData = {
      ...updatedCallData,
      CallToTextEnabled: e.target.checked
    };

    dispatch(callToTextUpdate(updatedData));
  };

  return (
    <div className="call-toText-card">
      <FormControl className="d-flex">
        {tenantById?.enableKnockCRM ? (
          <FormControlLabel
            style={{ width: '36%', justifyContent: 'flex-end' }}
            control={
              <ZSwitch
                id="showCallToText"
                checked={showCallToText}
                onChange={e => handleCallToText(e)}
                name={'showCallToText'}
                value={showCallToText}
              />
            }
            label={<span className={classes.label}>Call-To-Text</span>}
            labelPlacement="start"
          />
        ) : null}
        {
          <>
            <FormControlLabel
              labelPlacement="start"
              sx={{ marginLeft: 0 }}
              control={
                <ZSwitch
                  id="showZumaIvr"
                  checked={showZumaIvr}
                  onChange={e => {
                    setShowZumaIvr(e.target.checked);
                    updateApartment({
                      variables: {
                        aptIds: [property?._id],
                        record: {
                          entrataIVRAndCTT: {
                            isEnabled: e.target.checked
                          }
                        }
                      }
                    });
                  }}
                  name={'showCallToText'}
                  value={showZumaIvr}
                />
              }
              label={<span className={classes.label}>Zuma-Ivr</span>}
            />
            <div className="show-btn-ivr">
              {slotsEditable ? (
                <>
                  <Button
                    variant="contained"
                    style={{
                      background: '#5267FF',
                      marginRight: '10px',
                      textTransform: 'capitalize'
                    }}
                    size="small"
                    onClick={handleSave}
                  >
                    Save
                  </Button>

                  <Button
                    variant="text"
                    color="inherit"
                    size="small"
                    style={{
                      textTransform: 'capitalize'
                    }}
                    onClick={() => setSlotsEditable(false)}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  variant="text"
                  color="inherit"
                  size="medium"
                  style={{
                    textTransform: 'capitalize'
                  }}
                  onClick={() => setSlotsEditable(true)}
                >
                  Edit
                </Button>
              )}
            </div>
          </>
        }
      </FormControl>

      <CallTextLayout
        {...{
          showZumaIvr,
          callData: property,
          updatedCallData,
          setUpdateCallData,
          enableKnockCRM: tenantById?.enableKnockCRM,
          voiceMail: entrataIVRAndCTT?.voicemail,
          updateApartment,
          slotsEditable,
          setSlotsEditable
        }}
      />
    </div>
  );
};

export default CallToTextV1;
