import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Slider } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '260px',
    height: '293px',
    background: '#F3F3F3'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, background: '#f3f3f3' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PriceCalcModal = ({ open, handleClose, priceData }) => {
  const [moveInDate, setMoveInDate] = React.useState(0);

  const handleDateChange = (event, newValue) => {
    setMoveInDate(newValue);
  };

  const [leasingDuration, setLeasingDuration] = React.useState(0);

  const handleLeasingDuration = (event, newValue) => {
    setLeasingDuration(newValue);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        className={'price-modal-title'}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Price Calculator
      </BootstrapDialogTitle>
      <DialogContent>
        <div>
          <Typography className="price-modal-price">
            {priceData?.price}
          </Typography>
          <div className="flex-col price-container">
            <div
              className="flex"
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <div className="flex" style={{ marginRight: '20px' }}>
                <Typography className="price-modal-key">Type:</Typography>
                <Typography className="price-modal-value">NA</Typography>
              </div>
              <div className="flex">
                <Typography className="price-modal-key">Unit:</Typography>
                <Typography className="price-modal-value">
                  {priceData?.unit}
                </Typography>
              </div>
            </div>
            <div className="flex" style={{ marginBottom: '10px' }}>
              <div className="flex" style={{ marginRight: '20px' }}>
                <Typography className="price-modal-key">Area:</Typography>
                <Typography className="price-modal-value">
                  {priceData?.area}
                </Typography>
              </div>
              <div className="flex">
                <Typography className="price-modal-key">Availiable:</Typography>
                <Typography className="price-modal-value">
                  {priceData?.availiable}
                </Typography>
              </div>
            </div>
          </div>
          <div className="slider-container">
            <div>
              <Typography className="slider-title">
                Move-in date <span className="slider-value">(15 jul 2022)</span>
              </Typography>
              <Slider
                aria-label="Volume"
                value={moveInDate}
                onChange={handleDateChange}
              />
            </div>
            <div>
              <Typography className="slider-title">
                Leasing duration{' '}
                <span className="slider-value">({leasingDuration} Months)</span>
              </Typography>
              <Slider
                aria-label="Volume"
                value={leasingDuration}
                onChange={handleLeasingDuration}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default PriceCalcModal;
