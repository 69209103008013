import styled from 'styled-components';

const LeadEditLabel = styled.label`
  color: ${({ theme }) => theme.text.primary};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`;
const LeadDetailsEditToggle = styled.span`
  color: ${({ theme }) => theme.text.primary};
`;

const RightSideCards = styled.div`
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 4px;
  padding: 10px 10px;
  margin-top: 10px;
  font-family: 'Inter';
`;

const CardTitle = styled.div`
font-family: Inter;
font-weight: 600;
font-size: 14px;
line-height: 17px;
color: #525f80;
}
`;

const CheatSheetQuestion = styled.div`
  margin-top: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.primary};
`;

const CheatSheetTag = styled.div`
  color: ${({ theme }) => theme.text.tag};
`;
export {
  LeadEditLabel,
  LeadDetailsEditToggle,
  RightSideCards,
  CardTitle,
  CheatSheetQuestion,
  CheatSheetTag
};
