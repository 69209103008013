import { useEffect, useState } from 'react';
import Popup from '../../globalcomponents/Popup';
import PopupTitle from '../../globalcomponents/PopupComponents/PopupHeader';
import PopupBody from '../../globalcomponents/PopupComponents/PopupBody';
import { DeleteInfo } from '../LeadWaitList/LeadWaitList.styles';
import SliderValue from '../../globalcomponents/SliderValue';
import { WarningText } from './styles.reusabledeletepupup';
import { SliderSuccessContaniner } from '../../Container/InboxV2/SOPAndAgentNotes/sop.styles';
import SuccessIcon from './SucessIcon';
// import SuccessIcon from '../../globalcomponents/SliderValue/SuccessIcon';

const DeletePopup = ({
  show = false,
  handleClose,
  deleteFunction,
  body,
  confirmationMessage,
  successMessage,
  deleteIcon,
  warningIcon,
  confirmationText,
  sliderText,
  sliderIcon,
  sliderDisabled,
  customBackdropStyling
}) => {
  const [currStep, setCurrStep] = useState(0);
  const [value, setValue] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleValueChange = value => {
    setValue(value);
  };

  useEffect(() => {
    console.log('value', value);
    if (value == 100 || value === '100') {
      setCurrStep(1);
      deleteFunction().then(() => {
        console.log('true', value);

        handleClose();
        setValue(0);
      });
    }
  }, [value]);

  return show ? (
    <Popup
      show={show}
      width={'400px'}
      onClose={() => {
        handleClose();
        setValue(0);
      }}
      customBackdropStyling={customBackdropStyling}
    >
      <PopupTitle onClose={handleClose} showCloseBtn>
        &nbsp;
      </PopupTitle>
      <PopupBody padding={'20px'}>
        <>
          <DeleteInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
                alignItems: 'center'
              }}
            >
              <img width="160px" src={warningIcon} />
            </div>
            <WarningText>{confirmationText}</WarningText>
          </DeleteInfo>

          {currStep === 0 && show ? (
            <SliderValue
              value={value}
              setValue={handleValueChange}
              textOnSlider={sliderText}
              sliderIcon={sliderIcon}
              disabled={sliderDisabled}
            />
          ) : null}
          {currStep === 1 && show && (
            <SliderSuccessContaniner>
              <SuccessIcon
                handleValueChange={handleValueChange}
                setCurrStep={setCurrStep}
              />
            </SliderSuccessContaniner>
          )}
        </>
      </PopupBody>
    </Popup>
  ) : null;
};

export default DeletePopup;
