import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BackgroundLetterAvatar from '../Avatar';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  }
}));
const availbaleClientCloumns = [
  { id: 'name', label: 'Available clients', minWidth: 170, align: 'center' }
];
const assignedClientColumns = [
  { id: 'name', label: 'Assigned clients', minWidth: 170, align: 'center' }
];
const availbaleAgentCloumns = [
  { id: 'name', label: 'Available agents', minWidth: 170, align: 'center' }
];
const assignedAgentColumns = [
  { id: 'name', label: 'Assigned agents', minWidth: 170, align: 'center' }
];
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const dialogTitleBackground = {
  online: `linear-gradient(180deg, rgba(66, 255, 0, 0.2) -14.15%, rgba(255, 214, 0, 0) 100%)`,
  onBreak: `linear-gradient(180deg, rgba(255, 153, 0, 0.2) -14.15%, rgba(255, 214, 0, 0) 100%)`,
  offline: `linear-gradient(180deg, rgba(196, 196, 196, 0.2) -14.15%, rgba(255, 255, 255, 0) 100%)`
};

export default function CustomizedDialogs({ open, setOpen, selected, type }) {
  const [rows, setRows] = useState([]);
  const [badge, setBadge] = useState(0);
  const available =
    type === 'agents' ? availbaleClientCloumns : availbaleAgentCloumns;
  const assigned =
    type === 'agents' ? assignedClientColumns : assignedAgentColumns;
  const handleClose = () => {
    setOpen(false);
  };
  function handleClick(event) {
    alert(event);
  }
  function handleRemove(event) {
    alert(event);
  }
  React.useEffect(() => {
    const selectedData =
      type === 'agents'
        ? selected?.clients?.map(({ name }) => ({ name }))
        : selected?.agents?.map(({ name, status }) => ({ name, status }));
    setRows(selectedData ?? []);
    if (type === 'agents') {
      setBadge(selected?.clients?.length);
    } else {
      setBadge(selected?.agents?.length);
    }
  }, [selected]);
  const dialogTitleBackgroundColor =
    type === 'agents' ? selected?.status : `online`;
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            background: dialogTitleBackground[dialogTitleBackgroundColor],
            marginBottom: '-20px',
            ...(type === 'clients' && { height: '64px' })
          }}
        >
          {type === 'agents' && (
            <BackgroundLetterAvatar
              agentName={selected?.agentName}
              statusColor={selected?.status}
              avatarStyles={{ width: '30px', height: '30px' }}
              animate={true}
              titleStyles={{
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#42526E'
              }}
              otherStyles={{ position: 'relative', bottom: '10px' }}
            />
          )}
          {type === 'clients' && (
            <Typography
              sx={{
                color: '#42526E',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '24px'
              }}
            >
              Client: {selected?.client}
            </Typography>
          )}
        </BootstrapDialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              sx={{
                width: '269px',
                height: '36px',
                input: {
                  '&::placeholder': {
                    color: '#C4C4C4',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: 1
                  }
                }
              }}
              onChange={e => {}}
              placeholder="Search"
              variant="outlined"
              InputProps={{
                type: 'search',
                style: {
                  borderRadius: '4px',
                  padding: '4px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#a2a2a2' }} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <Grid container columns={24} sx={{ marginTop: '23px' }}>
            <Grid item xs={12}>
              <Paper
                sx={{ overflow: 'hidden', height: '411px', width: '294px' }}
              >
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {available.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                            <Badge
                              badgeContent={badge}
                              color="primary"
                              sx={{
                                paddingLeft: '15px'
                              }}
                            ></Badge>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TableBody>
                      {rows.map(row => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {available.map(column => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align="left"
                                  sx={{
                                    '&:hover': {
                                      '& .MuiButton-root': {
                                        opacity: 1
                                      }
                                    },
                                    ...(type === 'clients' && {
                                      padding: '0px'
                                    })
                                  }}
                                >
                                  <Grid
                                    container
                                    columns={24}
                                    sx={{
                                      ...(type === 'clients' && { pt: 2 })
                                    }}
                                  >
                                    <Grid item xs={12}>
                                      {type === 'clients' &&
                                      column.id === 'name' ? (
                                        <BackgroundLetterAvatar
                                          avatarRoot={{
                                            width: '24px',
                                            height: '24px'
                                          }}
                                          agentName={row?.name}
                                          statusColor={row?.status}
                                          animate={false}
                                          titleStyles={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            color: '#393939;'
                                          }}
                                          otherStyles={{
                                            position: 'relative',
                                            bottom: '10px'
                                          }}
                                        />
                                      ) : (
                                        value
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        ...(type === 'clients' && { pr: 2 })
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          float: 'right',
                                          opacity: 0,
                                          textTransform: 'none',
                                          fontWeight: 500
                                        }}
                                        variant="text"
                                        onClick={() => handleClick(row?.name)}
                                      >
                                        Add
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                sx={{ overflow: 'hidden', height: '411px', width: '294px' }}
              >
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {assigned.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                            <Badge
                              badgeContent={badge}
                              color="primary"
                              sx={{
                                paddingLeft: '15px'
                              }}
                            ></Badge>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TableBody>
                      {rows.map(row => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {assigned.map(column => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align="left"
                                  sx={{
                                    '&:hover': {
                                      '& .MuiButton-root': {
                                        opacity: 1
                                      }
                                    },
                                    ...(type === 'clients' && {
                                      padding: '0px'
                                    })
                                  }}
                                >
                                  <Grid
                                    container
                                    columns={24}
                                    sx={{
                                      ...(type === 'clients' && { pt: 2 })
                                    }}
                                  >
                                    <Grid item xs={12}>
                                      {type === 'clients' &&
                                      column.id === 'name' ? (
                                        <BackgroundLetterAvatar
                                          avatarRoot={{
                                            width: '24px',
                                            height: '24px'
                                          }}
                                          agentName={row?.name}
                                          statusColor={row?.status}
                                          animate={false}
                                          titleStyles={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            color: '#393939;'
                                          }}
                                          otherStyles={{
                                            position: 'relative',
                                            bottom: '10px'
                                          }}
                                        />
                                      ) : (
                                        value
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        ...(type === 'clients' && { pr: 2 })
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          float: 'right',
                                          opacity: 0,
                                          color: '#FF4D00',
                                          fontWeight: 500,
                                          textTransform: 'none'
                                        }}
                                        variant="text"
                                        onClick={() => handleRemove(row?.name)}
                                      >
                                        Remove
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
