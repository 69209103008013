import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
//internal
import Layout from '../../Component/layout';
import { TABS_DATA } from './constants';
import Integrations from './Integrations';
import PMS from './Pms';
import ClientInfo from './Pms/clientInfo';
import CircularProgress from '@mui/material/CircularProgress';
import Settings from './Settings';
import Communities from './Communities';
import './styles.scss';
import Workspace from './Workspace';
import Client from './Client';
import RightArrow from '../../Media/InboxV1/RightArrow';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';

const SelfServe = () => {
  const history = useHistory();
  const [info, infoPopUPHandler] = useState(false);
  const [tenantId, setTenantId] = useState('');
  const [tenantName, setTenantName] = useState('');
  const selfServe = useSelector(state => state.SelfServe);
  const [notification, setNotification] = useState('');
  const { userData } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState('workspace');

  const handleTabClick = tab => {
    setActiveTab(tab);
  };
  const showAddUser = () => {
    if (userData) {
      infoPopUPHandler(true);
    }
  };

  const integrationSettings = selfServe?.userData?.data?.integrationSettings;
  const crmSettings = selfServe?.userData?.data?.crmSettings;
  const restrictCommunication =
    selfServe?.userData?.data?.restrictCommunication;
  const isGPTEnabled = selfServe?.userData?.data?.isGPTEnable;
  const pmsName = integrationSettings && integrationSettings?.pms;
  const clientName = selfServe?.userData?.data?.name;
  const knockName = crmSettings?.crm;
  const isSuperAdmin = selfServe?.superAdmin;

  const truncate = str => {
    if (str && str.length > 12) {
      return str.substring(0, 12) + '...';
    }
    return str;
  };

  return isSuperAdmin === null ? null : (
    <Layout settingsAction={showAddUser} history={history}>
      {tenantId === '' ? (
        <>
          <div className="clients-selected">
            <div className="client-name-agent">Clients </div>
          </div>
          <Client
            setNotification={setNotification}
            notification={notification}
            info={info}
            infoPopUPHandler={infoPopUPHandler}
            setTenantId={setTenantId}
            setTenantName={setTenantName}
          />
        </>
      ) : (
        <>
          <div className="clients-selected">
            <div
              className="client-name-agent"
              onClick={() => {
                setTenantId('');
                setTenantName('');
              }}
            >
              Clients
              <RightArrow style={{ margin: '0px 20px' }} />
            </div>
            <div className="tenantName">{tenantName}</div>
          </div>
          <div className="flex mt-20">
            {selfServe?.apiLoading && (
              <div className="loaderNew">
                <CircularProgress />
              </div>
            )}

            <div style={{ padding: '5px 20px' }} className="flex tab-container">
              {TABS_DATA.map(tab => {
                return (
                  <div
                    onClick={() => handleTabClick(tab.key)}
                    key={tab.key}
                    className="flex mr-30 pointer"
                  >
                    {tab.icon}
                    <p
                      className="inter-500-16px"
                      style={{ paddingLeft: '10px', position: 'relative' }}
                    >
                      {tab.name}
                      {activeTab === tab.key && <span className="active-tab" />}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              style={{ padding: '5px 20px' }}
              className="flex client-container"
            >
              <p className="mr-20 blue-color-text">{truncate(clientName)} |</p>
              <p className="mr-20 blue-color-text">PMS - {pmsName}</p>
              <p className="mr-20 blue-color-text">
                {knockName ? `CRM - ${knockName}` : ''}
              </p>
            </div>
          </div>
          {activeTab === 'internalSettings' && (
            <Settings
              tenantId={tenantId}
              restrictCommunication={restrictCommunication}
              isGPTEnabled={isGPTEnabled}
            />
          )}
          {activeTab === 'workspace' && (
            <Workspace tenantId={tenantId} tenantName={tenantName} />
          )}
          {activeTab === 'communities' && (
            <Communities tenantId={tenantId} crmSettings={crmSettings} />
          )}
          {activeTab === 'pms' && (
            <PMS
              info={info}
              infoPopUPHandler={infoPopUPHandler}
              tenantId={tenantId}
            />
          )}
          {activeTab === 'integrations' && (
            <Integrations adminTenantId={tenantId} />
          )}
        </>
      )}
      <ClientInfo
        setNotification={setNotification}
        notification={notification}
        info={info}
        infoPopUPHandler={infoPopUPHandler}
      />
    </Layout>
  );
};
export default SelfServe;
