import React from 'react';
import {
  LeadCardName,
  LeadChatCard,
  LeadChatCardWrapper,
  LeadChatStage,
  LeadDate,
  LeadPropertyName
} from '../../../Container/InboxV2/styles.inboxv2.js';

import LeadCardStatus from '../../../Container/LeadCardStatus/index.js';
import { Skeleton } from '@mui/material';

const LeadCardLoader = () => {
  return (
    <LeadChatCardWrapper>
      <LeadChatCard>
        <LeadChatStage id="lead-stage">
          <Skeleton style={{ width: '60px' }} />
        </LeadChatStage>
        <LeadDate>
          <Skeleton style={{ width: '90px' }} />
        </LeadDate>
        <LeadCardName>
          <Skeleton style={{ width: '120px' }} />
        </LeadCardName>
        <LeadPropertyName>
          <Skeleton style={{ width: '90px' }} />
        </LeadPropertyName>
        <div className="lead-agentName pd-left-5px pd-right-5px">
          <LeadCardStatus />
          <div>
            <Skeleton style={{ height: '20px', width: '20px' }} />
          </div>
        </div>
      </LeadChatCard>
    </LeadChatCardWrapper>
  );
};

export default LeadCardLoader;
