import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import {
  BtnContainer,
  Cancel,
  SendFeed,
  SendFeeddis,
  FontStyle
} from '../../../styles/propertystyles/Modalstyle';

function ConfirmModal(props) {
  const [name, setName] = useState(null);

  const CancelThis = () => {
    props.setOpen(false);
  };

  const ContinueThis = () => {
    props.proceed(props.propVal);
    props.setOpen(false);
  };

  const OnPressEnter = e => {
    if (
      e.key === 'Enter' &&
      (props.identifier === 'property' || props.propName === name)
    ) {
      ContinueThis();
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <div>
                  <span style={FontStyle}>
                    {props.Msg}{' '}
                    <span style={{ color: 'red', fontSize: '14px' }}>
                      {props.propName}
                    </span>
                  </span>
                </div>
                {props.identifier !== 'property' && (
                  <div style={{ paddingTop: '13px' }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyPress={e => OnPressEnter(e)}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                )}
                <div style={BtnContainer}>
                  <Button
                    variant="contained"
                    style={Cancel}
                    onClick={() => CancelThis()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={
                      props.identifier === 'property' || props.propName === name
                        ? SendFeed
                        : SendFeeddis
                    }
                    onClick={() => ContinueThis()}
                    disabled={
                      props.identifier === 'property' || props.propName === name
                        ? false
                        : true
                    }
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
