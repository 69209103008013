import React from 'react';
import LeadCard from './LeadCard';
import { LeadListMain } from './styles.inboxv2';
import LeadCardLoader from '../../globalcomponents/SkeletonLoaders/LeadCardLoader';

const LeadsList = props => {
  const {
    inboxMessages,
    selectedLeadId,
    onClick,
    isLeadsEmpty,
    messagesLoading
  } = props;

  return (
    <LeadListMain data-testid="leads-list">
      {inboxMessages.map(
        ({ _id, leadSources, hasKelseyAnswered, wasAFMSent }) => {
          return (
            <LeadCard
              key={_id.id}
              meta={_id?.meta}
              leadSources={leadSources}
              hasKelseyAnswered={hasKelseyAnswered}
              wasAFMSent={wasAFMSent}
              {...{
                name: _id.name,
                apartment: _id?.apartment,
                time: _id?.lastMessageExchanged,
                stage: _id.stage,
                leadId: _id._id,
                onClick,
                selectedLeadId,
                isArchived: _id.isArchived,
                tenantName: _id.tenant.name
              }}
            />
          );
        }
      )}

      {messagesLoading ? (
        <>
          <LeadCardLoader />
        </>
      ) : null}
    </LeadListMain>
  );
};

export default LeadsList;
