import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client';
import { DevGraphQl, ProductionGraphql } from '../serverLinks';
import { getCookie } from '../utils';
const API_URL =
  process.env.NODE_ENV === 'development'
    ? `${DevGraphQl}/graphql`
    : `${ProductionGraphql}/graphql`;
const httpLink = new HttpLink({ uri: API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getCookie('rb_access_token');
  const authorization = token ? token : '';
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: authorization
    }
  }));

  return forward(operation);
});

const link = ApolloLink.from([authMiddleware, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false })
});

export default apolloClient;
