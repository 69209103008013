import { gql } from '@apollo/client';

export const updateSopAcknowledgementByAgent = gql`
  mutation updateSopAcknowledgement($id: [ID!]) {
    updateSopAcknowledgement(_id: $id) {
      acknowledgedBy {
        _id
      }
    }
  }
`;
