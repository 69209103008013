// all property style

// property > modal >

// ConfirmModal.js and ListModal.js and AddAparatment.js
export const BtnContainer = {
  marginTop: '30px',
  textAlign: 'center'
};
export const Cancel = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  border: '1px solid #023e8a',
  backgroundColor: '#ffffff',
  marginRight: '20px'
};
export const SendFeed = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#023e8a',
  color: '#fff',
  marginRight: '20px'
};

export const SendFeeddis = {
  width: '150px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#5a7da9',
  color: '#fff',
  marginRight: '20px'
};

export const FontStyle = {
  textAlign: 'center',
  font: 'normal bold 12px Inter'
};

// AddAparatment.js

export const marginbtm = {
  marginBottom: '10px'
};
