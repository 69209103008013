import { createSlice } from '@reduxjs/toolkit';
import {
  createNewTourNotes,
  createTour,
  downloadApartmentCheatsheet,
  emptyMetaData,
  emptypriceListing,
  fetchApartments,
  fetchInboxMetaData,
  fetchLeadMessage,
  fetchMetaData,
  fetchPriceListings,
  fetchPropertyByApartmentIdOffPlatform,
  fetchSessionData,
  fetchTemplateData,
  fetchTimeSlots,
  fetchTourConfirmation,
  getConversationSummary,
  getNotesByTourId,
  sendLeadMessage,
  updateAgentDetails,
  updateSessionData,
  updateStage,
  deleteTour,
  getMasterCheatsheet
} from '../Actions/InboxV1';
import store from '../store';
import { setSuccessInfo } from './GlobalError';

const initialState = {
  slotsLoading: false,
  apartmentCheatSheetLoading: true,
  slots: [],
  dates: [],
  inboxMetaData: {},
  isTourCreated: false,
  messageDelivered: {},
  messageSent: false,
  tourId: null,
  confirmedTour: false,
  tourConfirmationData: {
    confirmed: false,
    errorType: ''
  },

  inboxMessages: [],
  messagesLoading: false,
  loadMoreMessages: true,
  leadMessageLoading: false,
  leadMessage: [],
  propertyData: [],
  propertyDataLoading: false,
  inboxMetaLoading: false,
  cheatSheetData: [],
  cheatSheetDataLoading: false,
  templateData: [],
  templateDataLoading: false,
  apartmentData: [],
  isApartmentDataLoading: false,
  newNoteInserted: false,
  newNoteInsertionInProgress: false,
  newTourNoteInserted: false,
  newTourNoteInsertionInProgress: false,
  commentsData: [],
  priceListings: [],
  priceListingsLoading: false,
  isPriceListingLoading: false,
  priceListingList: [],
  newCommentInserted: 0,
  updatedLeadId: false,
  isLeadsEmpty: false,
  toastMessage: {},
  priceListingError: false,
  privateInfo: {},
  isPrivateInfoLoading: false,
  allPropertyData: [],
  cheatSheet: [],
  offPlatformPropertyByApi: [],
  isStageUpdated: false,
  isAgentChanged: false,
  isConversationSummaryLoading: false,
  conversationSummary: {},
  sessionData: null,
  sessionDataPending: false,
  showKelseyTrigger: false,
  slotsMessage: '',
  slotsErrorMessage: '',
  deleteTourPending: false
};

const InboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    chatArchived: (state, action) => {
      const leadDataIndex = action?.payload?.guestCards?.findIndex(
        data => data?._id?._id === action?.payload?.id
      );
      if (leadDataIndex > -1) {
        state.updatedLeadId =
          action.payload?.guestCards?.[leadDataIndex + 1]?._id?._id || false;
      }
      const inboxMessages = [
        ...(action.payload.guestCards.map((data, index) => {
          return data._id._id === action.payload.id
            ? { ...data, _id: { ...data._id, isArchived: true } }
            : data;
        }) || [])
      ];
      action?.payload?.setGuestCards([...inboxMessages]);
    },
    setKelseyTrigger: (state, action) => {
      state.showKelseyTrigger = false;
    },
    setDefaultPropertyData: (state, action) => {
      state.propertyData = action?.payload?.data ? action?.payload : {};
    },
    setMessagesEmpty: (state, action) => {
      state.slotsMessage = '';
      state.slotsErrorMessage = '';
    }
  },
  extraReducers: {
    [updateSessionData.fulfilled]: (state, action) => {
      state.sessionData = action?.payload?.data?.success
        ? action?.payload?.data?.data
        : null;
      state.showKelseyTrigger = true;
    },
    [fetchSessionData.pending]: state => {
      state.sessionDataPending = true;
    },
    [fetchSessionData.fulfilled]: (state, action) => {
      state.sessionData = action?.payload?.data?.success
        ? action?.payload?.data?.data
        : null;
      state.sessionDataPending = false;
    },
    [createNewTourNotes.pending]: state => {
      state.newNoteInsertionInProgress = true;
    },
    [createNewTourNotes.fulfilled]: state => {
      state.newNoteInsertionInProgress = false;
    },
    [fetchTimeSlots.pending]: state => {
      state.slotsLoading = true;
    },
    [fetchTimeSlots.fulfilled]: (state, action) => {
      state.slotsLoading = false;

      state.dates = action?.payload?.data?.data?.dates;
      state.slots = action.payload?.data?.data?.slots;
      state.slotsMessage = action.payload?.data?.message;
    },
    [fetchTimeSlots.rejected]: (state, action) => {
      state.slotsLoading = false;
      state.slotsErrorMessage = action?.payload?.data?.message;
    },
    [deleteTour.fulfilled]: (state, action) => {
      state.slotsMessage = action.payload?.data?.message;
      state.deleteTourPending = false;
    },
    [deleteTour.pending]: (state, action) => {
      state.deleteTourPending = true;
    },
    [deleteTour.rejected]: (state, action) => {
      state.slotsErrorMessage = action?.payload?.data?.message;
      state.deleteTourPending = false;
    },
    [downloadApartmentCheatsheet.pending]: state => {
      state.apartmentCheatSheetLoading = true;
    },
    [downloadApartmentCheatsheet.fulfilled]: (state, action) => {
      state.slotsLoading = false;
      state.apartmentCheatSheet = action?.payload?.data?.data;
    },
    [downloadApartmentCheatsheet.rejected]: (state, action) => {
      state.apartmentCheatSheetLoading = false;
    },
    [getNotesByTourId.pending]: state => {
      state.notesData = true;
    },
    [getNotesByTourId.fulfilled]: (state, action) => {
      state.notesData = false;
      state.Notes = action?.payload?.data?.data;
    },
    [getNotesByTourId.rejected]: (state, action) => {
      state.notesData = false;
    },
    [emptyMetaData.fulfilled]: (state, action) => {
      state.inboxMetaData = {};
      state.leadMessage = [];
      state.isLeadsEmpty = false;
      state.loadMoreMessages = true;

      // state.leadMessageLoading = true;
    },
    [emptypriceListing.fulfilled]: (state, action) => {
      state.priceListingList = [];
    },
    [fetchInboxMetaData.pending]: state => {
      state.inboxMetaLoading = true;
    },
    [fetchInboxMetaData.fulfilled]: (state, action) => {
      state.inboxMetaLoading = false;
      state.isNewLeadData = false;
      state.inboxMetaData = action?.payload?.data?.data;
    },
    [fetchInboxMetaData.rejected]: (state, action) => {
      state.inboxMetaLoading = false;
    },
    [createTour.pending]: state => {
      state.isTourCreated = false;
    },
    [createTour.fulfilled]: (state, action) => {
      state.isTourCreated = true;
      state.tourId = action?.payload?.data?.data?._id;
      state.slotsMessage = action?.payload?.data?.message;
    },
    [createTour.rejected]: (state, action) => {
      state.isTourCreated = false;
      state.slotsErrorMessage = action?.payload?.data?.message;
    },
    [getConversationSummary.pending]: state => {
      state.isConversationSummaryLoading = false;
    },
    [getConversationSummary.fulfilled]: (state, action) => {
      state.isConversationSummaryLoading = true;
      state.conversationSummary = action?.payload?.data?.data;
    },
    [getConversationSummary.rejected]: (state, action) => {
      state.isConversationSummaryLoading = false;
    },
    [sendLeadMessage.pending]: state => {
      state.messageSent = true;
    },
    [sendLeadMessage.fulfilled]: (state, action) => {
      state.messageSent = false;
      state.messageDelivered = action?.payload?.data?.data;
    },
    [sendLeadMessage.rejected]: (state, action) => {
      state.messageSent = 'failed';
    },
    [fetchTourConfirmation.pending]: state => {
      state.confirmedTour = true;
    },
    [fetchTourConfirmation.fulfilled]: (state, action) => {
      state.confirmedTour = false;
      state.tourConfirmationData = action?.payload?.data?.data;
    },
    [fetchTourConfirmation.rejected]: (state, action) => {
      state.confirmedTour = false;
    },

    [fetchTemplateData.pending]: state => {
      state.templateDataLoading = true;
    },
    [fetchTemplateData.fulfilled]: (state, action) => {
      state.templateData = action?.payload?.data?.data
        ? [...action?.payload?.data?.data]
        : [];
    },
    [fetchPropertyByApartmentIdOffPlatform.fulfilled]: (state, action) => {
      if (action?.payload?.data?.data?.data) {
        state.offPlatformPropertyByApi = action?.payload?.data?.data?.data;
      }
    },
    [fetchMetaData.pending]: state => {
      state.inboxMetaLoading = true;
    },
    [fetchMetaData.fulfilled]: (state, action) => {
      state.inboxMetaLoading = false;
      state.isNewLeadData = false;
      state.toastMessage = {};
      state.inboxMetaData = { ...action?.payload?.data?.data };
    },
    [updateAgentDetails.pending]: state => {
      state.isAgentChanged = true;
    },
    [updateAgentDetails.fulfilled]: (state, action) => {
      state.inboxMetaData = {
        ...state.inboxMetaData,
        lead: { ...action.payload.data.data }
      };
      state.isAgentChanged = false;
    },
    [fetchLeadMessage.pending]: state => {
      state.leadMessageLoading = true;
    },
    [fetchLeadMessage.fulfilled]: (state, action) => {
      state.leadMessageLoading = false;
      if (action?.payload?.data?.data) {
        state.leadMessage = action?.payload?.data?.data;
      }
    },
    [fetchLeadMessage.rejected]: (state, action) => {
      state.leadMessageLoading = false;
    },
    [fetchApartments.pending]: (state, action) => {
      state.isApartmentDataLoading = true;
    },
    [fetchApartments.fulfilled]: (state, action) => {
      state.apartmentData = action?.payload?.data?.data;
    },
    [updateStage.pending]: state => {
      state.inboxMetaLoading = true;
      state.isStageUpdated = true;
    },
    [fetchPriceListings.pending]: state => {
      state.isPriceListingLoading = true;
      state.priceListingError = false;
    },
    [fetchPriceListings.fulfilled]: (state, action) => {
      if (action?.payload?.data?.data) {
        state.isPriceListingLoading = false;
        state.priceListingList = action?.payload?.data?.data?.availableUnits;
        state.priceListingError = false;
      }
    },
    [fetchPriceListings.rejected]: (state, action) => {
      state.isPriceListingLoading = false;
      state.error = action.payload;
      state.priceListingError = true;
    },
    [updateStage.fulfilled]: (state, action) => {
      state.inboxMetaLoading = false;
      state.isStageUpdated = false;
    }
  }
});
export const { chatArchived, setKelseyTrigger, setMessagesEmpty } =
  InboxSlice.actions;

export default InboxSlice;
