export const sortedClients = clients => {
  let sorted = [];
  if (clients && clients.length > 0) {
    sorted = [...clients].sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  }

  return sorted;
};
