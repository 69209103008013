import React from 'react';
import { Slider, SliderContainer } from './slider-value.styles';

const SliderValue = ({
  value,
  setValue,
  textOnSlider = 'Slide to reset password',
  sliderIcon = 'https://assets.getzuma.com/images/Lock_Grdient.svg',
  disabled = false,
  show = true,
  background = '#42526E',
  color = '#42526E',
  sliderFontSize = '12px'
}) => {
  return (
    <SliderContainer id="reset-pwd-silder" disabled={disabled}>
      <Slider
        textOnSlider={textOnSlider}
        onChange={e => setValue(e.target.value)}
        sliderIcon={sliderIcon}
        type="range"
        show={show}
        min="1"
        max="100"
        value={value || '1'}
        id="myRange"
        background={background}
        color={color}
        sliderFontSize={sliderFontSize}
      />
    </SliderContainer>
  );
};

export default SliderValue;
