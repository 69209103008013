// property > all property styles

// property.js
export const CardContainer = {
  marginRight: '20px',
  marginBottom: '20px',
  position: 'relative'
};

// propertyAdd.js
export const Common = {
  fontFamily: 'Inter',
  fontWeight: 400
};
export const HeaderStyle = {
  display: 'flex',
  color: '#20065f',
  fontSize: '16px',
  letterSpacing: -0.16,
  textTransform: 'uppercase'
};
export const closeIconStyle = {
  marginTop: '-3px',
  marginRight: '25px',
  cursor: 'pointer'
};
export const SaveMoreStyle = {
  width: '220px',
  height: '40px',
  borderRadius: '8px',
  border: '1px solid #023e8a',
  backgroundColor: '#ffffff',
  marginRight: '20px'
};
export const SavePropertyStyle = {
  width: '220px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#023e8a',
  color: '#fff'
};
export const BlockHeading = {
  color: '#0077b6',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: -0.16
};
export const typoGraph = {
  font: 'normal 14px Inter'
};

//PropertyCard.js
export const cardStyle = {
  width: '291px',
  height: '322px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
  borderRadius: '8px'
};
export const CardContentStyle = {
  padding: 0
};
export const dataCnt = {
  textAlign: 'center',
  color: '#393939',
  fontFamily: 'Inter',
  fontSize: '12px',
  marginBottom: '12px',
  fontWeight: 400,
  marginLeft: '-58px'
};
export const infoCont = {
  color: '#969696',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  paddingLeft: '10px',
  flexGrow: '1'
};
export const infoContData = {
  fontSize: '12px',
  color: '#393939',
  marginTop: '2px',
  marginBottom: '10px'
};
export const HeadingStyle = {
  position: 'absolute',
  color: '#FFF',
  marginTop: '-40px',
  backgroundImage:
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)',
  width: '292px'
};
export const popertyHeading = {
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '12px',
  marginLeft: '10px',
  width: '250px'
};
export const AddressStyle = {
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: 400,
  margin: '5px 0px 5px 10px',
  whiteSpace: 'nowrap',
  maxWidth: '280px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const cardFooter = {
  height: '22px',
  backgroundColor: 'rgba(2, 62, 138, 0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  fontFamily: 'Inter',
  color: '#20065f',
  fontSize: '15px',
  fontWeight: 400
};
export const ContentStyle = {
  display: 'flex',
  marginTop: '25px'
};
export const BlockContent = {
  display: 'flex'
};
export const BlockContentIconsStyle = {
  marginRight: '15px'
};
export const editIcon = {
  cursor: 'pointer',
  color: 'green'
};
export const deleteIcon = {
  cursor: 'pointer',
  color: 'red'
};
export const DivStyle = {
  position: 'absolute',
  marginTop: '-122px',
  marginLeft: '218px'
};
export const heroImage = {
  width: '292px',
  height: '125px'
};
export const listedStyle = {
  background: '#20065F',
  color: '#fff',
  height: '20px'
};
export const draft = {
  background: '#fff',
  color: 'black',
  height: '20px'
};
export const lstbtnStyle = {
  borderRadius: '100px 0px 0px 100px'
};
export const drbtnStyle = {
  borderRadius: '0px 100px 100px 0px'
};
export const toggleContainer = {
  position: 'absolute',
  marginTop: '-115px',
  marginLeft: '12px'
};

//imageUpload.js     > (property)
export const uploaderStyle = {
  cursor: 'pointer',
  marginBottom: '20px'
};
export const ImgStyle = {
  width: '120px',
  height: '110px',
  borderRadius: '4px',
  border: '1px solid #d2d2d2',
  marginRight: '35px',
  marginBottom: '20px'
};
export const deleteStyle = {
  position: 'absolute',
  marginLeft: '-48px',
  marginTop: '-10px',
  color: 'red',
  cursor: 'pointer'
};
export const radioBtn = {
  position: 'absolute',
  marginLeft: '101px',
  marginTop: '90px',
  backgroundColor: '#fff',
  height: '2px',
  width: '2px'
};
