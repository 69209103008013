import React from 'react';
import styles from './styles.module.scss';
import { Tooltip } from '@material-ui/core';
const Button = ({
  label,
  overRideClass = '',
  onClick,
  children,
  disabled = false,
  tooltipTitle = ''
}) => {
  console.log('tootip', tooltipTitle);
  return (
    <Tooltip title={tooltipTitle}>
      <button
        onClick={onClick}
        className={`${overRideClass} ${styles.stdButton}`}
        disabled={disabled}
      >
        {label || children}
      </button>
    </Tooltip>
  );
};

export default Button;
