import React from 'react';

const UsersIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9166 15.1768H11.0833C9.47331 15.1768 8.16665 16.4835 8.16665 18.097V22.738C9.89153 23.8958 11.9225 24.5128 14 24.5102C16.0774 24.5129 18.1085 23.8959 19.8333 22.738V18.097C19.8336 17.323 19.5265 16.5806 18.9796 16.033C18.4326 15.4854 17.6906 15.1775 16.9166 15.1768Z"
        fill="white"
      />
      <path
        d="M14 13.9997C15.933 13.9997 17.5 12.4327 17.5 10.4997C17.5 8.56668 15.933 6.99967 14 6.99967C12.067 6.99967 10.5 8.56668 10.5 10.4997C10.5 12.4327 12.067 13.9997 14 13.9997Z"
        fill="white"
      />
      <path
        d="M14 23.333C16.4753 23.333 18.8493 22.3497 20.5996 20.5993C22.35 18.849 23.3333 16.475 23.3333 13.9997C23.3333 11.5243 22.35 9.15035 20.5996 7.40001C18.8493 5.64967 16.4753 4.66634 14 4.66634C11.5246 4.66634 9.15066 5.64967 7.40032 7.40001C5.64998 9.15035 4.66665 11.5243 4.66665 13.9997C4.66665 16.475 5.64998 18.849 7.40032 20.5993C9.15066 22.3497 11.5246 23.333 14 23.333ZM14 25.6663C7.55648 25.6663 2.33331 20.4432 2.33331 13.9997C2.33331 7.55617 7.55648 2.33301 14 2.33301C20.4435 2.33301 25.6666 7.55617 25.6666 13.9997C25.6666 20.4432 20.4435 25.6663 14 25.6663Z"
        fill="white"
      />
    </svg>
  );
};

export default UsersIcon;
