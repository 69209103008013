import { Breadcrumbs, Link, Switch, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import Ztable from '../../Component/Common/ZTable';
import Layout from '../../Component/layout';
import { useStyles } from './styles';
import WorkflowMenu from './WorkflowMenu';
import SequenceForm from './SequenceForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllWorkflows,
  changeWorkflowStatus,
  createWorkflow
} from '../../Redux/Actions/WorkflowV1';
import SearchBar from '../../Component/Common/ZSearch';
import DeletePopUp from './deletePopUpModal';
import _ from 'lodash';
import AfmIcon from '../../Media/AFMIcon.png';
import { useTheme } from 'styled-components';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';
import { getApartmentsByTenantIds } from '../../gql/queries/apartmentByTenant';
import { useLazyQuery } from '@apollo/client';
import ReactSelect from 'react-select';

export const intervalMap = {
  fixed: 'fixed',
  custom: 'custom'
};

const WorkflowV1 = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const workflowSlice = useSelector(state => state.workflowData);
  const { userData } = useContext(GlobalContext);

  const [showAddWorkflow, setShowAddWorkflow] = useState(false);
  const [showFollowUpMessage, setShowFollowupMessage] = useState(false);
  const [showAutomatedMessage, setShowAutomatedMessage] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [open, setOpen] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [workflowsFilteredByClients, setWorkflowsFilteredByClients] = useState(
    []
  );

  const [getCommunitiesByClients, { data: { apartmentsByTenantIds } = {} }] =
    useLazyQuery(getApartmentsByTenantIds);

  const addFollowupMessage = () => {
    setShowAddWorkflow(true);
    setShowFollowupMessage(true);
  };

  const addAutomatedMessage = () => {
    setShowAddWorkflow(true);
    setShowAutomatedMessage(true);
  };

  const handleNavigation = () => {
    setShowAddWorkflow(false);
    setShowAutomatedMessage(false);
    setShowFollowupMessage(false);
    history.push('/new-workflow');
    setSelectedData({});
  };

  const getMediumColor = medium => {
    switch (medium) {
      case 'Email':
        return '#FFF0ED';
      case 'Sms':
        return '#F2ECF6';
      default:
        return '#EBF3F5';
    }
  };

  const handleChecked = (event, rowData) => {
    let changeStatus = {
      _id: rowData?._id,
      isEnabled: !rowData.isEnabled
    };
    let copyTableData = tableData.map(item => {
      if (item._id === rowData?._id) {
        item = { ...item, isEnabled: !item.isEnabled };
      }
      return item;
    });
    setTableData(copyTableData);
    dispatch(changeWorkflowStatus({ data: changeStatus }));
  };

  const columns = [
    { field: 'workflow_name', title: 'Name', width: 500 },
    {
      field: 'stage',
      title: 'Stage',
      render: rowData => (
        <div style={{ display: 'flex' }}>
          <div> {rowData.stage}</div>
          <div>
            {rowData.enableKelseyAFM ? (
              <div>
                <Tooltip
                  title="Kelsey will attempt responding to the first message. The template will be sent out if she fails to do so."
                  placement="top-start"
                >
                  <img
                    alt="afm-icon"
                    src={AfmIcon}
                    style={{ marginTop: '-2px' }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>
      )
    },
    {
      field: 'client',
      title: 'Client',
      render: rowData => (
        <span>
          {rowData.clients.length === 1 ? (
            <span>{rowData.clients[0].name}</span>
          ) : null}
          {rowData.clients.length === 2 ? (
            <span>
              {rowData.clients[0].name} | {rowData.clients[1].name}{' '}
            </span>
          ) : null}
          {rowData.clients.length > 2 ? (
            <Tooltip
              title={rowData?.clients?.map((client, i) => {
                return (
                  <span key={i}>
                    {client.name}{' '}
                    {rowData.clients.length === i + 1 ? null : '|'}{' '}
                  </span>
                );
              })}
              placement="right-start"
            >
              <span>
                {rowData.clients[0].name} |{rowData.clients[1].name} |{' '}
                {rowData.clients.length - 2}{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    },
    {
      field: 'type',
      title: 'Type',
      render: rowData => (
        <span>{rowData.type === 'followUp' ? 'FUM' : 'AFM'}</span>
      )
    },
    {
      field: 'interval',
      title: 'Interval',
      render: rowData => (
        <span>
          {rowData?.interval && rowData?.type === 'followUp'
            ? intervalMap[rowData.interval].toUpperCase() || ''
            : ''}
        </span>
      )
    },
    {
      field: 'sequenceCount',
      title: 'Sequence no(s)',
      align: 'center',
      render: rowData => (
        <span>
          {_.has(rowData, 'sequence')
            ? Object.keys(rowData?.sequence).length
            : '-'}
        </span>
      )
    },
    {
      field: 'medium',
      title: 'Medium',
      align: 'center',
      render: rowData => (
        <span
          style={{ backgroundColor: getMediumColor(rowData.medium) }}
          className={classes.mediumContainer}
        >
          {rowData.medium}
        </span>
      )
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <Switch
          className={classes.switchStyle}
          checked={rowData.isEnabled}
          onChange={event => handleChecked(event, rowData)}
        />
      )
    }
  ];

  useEffect(() => {
    dispatch(fetchAllWorkflows());
    setOpen(false);
  }, []);

  useEffect(() => {
    dispatch(fetchAllWorkflows());
  }, [showAddWorkflow]);

  const { workflowData } = workflowSlice;
  const [tableData, setTableData] = useState(workflowData);

  useEffect(() => {
    setTableData(workflowData);
  }, [workflowData]);

  const [duplicated, setDuplicated] = useState(false);
  const { isCreating } = useSelector(state => state.workflowData);

  const handleDuplicate = data => {
    let workflowData = {
      workflow_name: `${data.workflow_name} duplicated`,
      type: data.type,
      medium: data.medium,
      clients: data.clients,
      communities: data.communities,
      stage: data.stage,
      sequence: data.sequence
    };
    setDuplicated(true);
    dispatch(createWorkflow({ workflowData }));
    dispatch(fetchAllWorkflows());
  };

  useEffect(() => {
    if (duplicated && !isCreating) {
      dispatch(fetchAllWorkflows());
      setDuplicated(false);
    }
  }, [duplicated]);

  const handleSearchBar = e => {
    let filter = workflowData?.map(item => {
      let query = e.target.value.toUpperCase();
      if (
        item?.stage?.toUpperCase().includes(query) ||
        item?.clients[0]?.name?.toUpperCase().includes(query) ||
        item?.medium?.toUpperCase().includes(query) ||
        item?.workflow_name.toUpperCase().includes(query)
      ) {
        return item;
      }

      if (query === 'AFM') {
        if (item?.type.includes('firstEnquiry')) {
          return item;
        }
      }
      if (query === 'FUM') {
        if (item?.type.includes('followUp')) {
          return item;
        }
      }
    });
    const final = filter?.filter(itm => itm !== undefined && itm !== null);
    setTableData(final);
  };

  const handleEditRow = data => {
    history.push(`/new-workflow/${data._id}`);
    if (data.type === 'firstEnquiry' || data.type === 'firstInquiry') {
      setShowAutomatedMessage(true);
    } else {
      setShowFollowupMessage(true);
    }
    setShowAddWorkflow(true);
    setSelectedData(data);
  };

  const handleDeleteRow = data => {
    setOpen(true);
    setSelectedData(data);
  };

  function filterWorkflowsByClients(workflows, selectedClientIds) {
    return workflows.filter(workflow => {
      return workflow.clients.some(({ _id }) =>
        selectedClientIds.includes(_id)
      );
    });
  }

  function filterWorkflowsByCommunities(workflows, selectedCommunityIds) {
    return workflows.filter(workflow => {
      return workflow.communities.some(({ _id }) =>
        selectedCommunityIds.includes(_id)
      );
    });
  }

  const clientSelectHandler = newValue => {
    setSelectedClients([...newValue]);
    if (newValue.length === 0) {
      setTableData([...workflowData]);
    } else {
      const selectedClientIds = newValue?.map(({ _id }) => _id);
      const filteredData = filterWorkflowsByClients(
        workflowData,
        selectedClientIds
      );
      setWorkflowsFilteredByClients(filteredData);
      setTableData(filteredData);
    }

    getCommunitiesByClients({
      variables: { tenantIds: newValue?.map(({ _id }) => _id) || [] }
    });
  };

  const communitySelectHandler = newValue => {
    setSelectedCommunities([...newValue]);
    if (newValue?.length == 0) {
      setTableData([...workflowsFilteredByClients]);
    } else {
      const selectedCommunityIds = newValue?.map(({ _id }) => _id);
      const filteredData = filterWorkflowsByCommunities(
        tableData,
        selectedCommunityIds
      );
      setTableData(filteredData);
    }
  };

  const selectCustomStyles = {
    container: (styles, { disabled }) => ({
      ...styles,
      width: '450px',
      fontFamily: 'Inter',
      zIndex: 100,
      background: theme?.background?.secondary
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      boxShadow: 'none',
      border: 0,
      cursor: 'pointer',
      borderRadius: '4px',
      height: '44px',
      overflow: 'scroll',
      border: '0.5px solid #393939'
    }),
    valueContainer: (styles, { isDisabled }) => ({
      ...styles,
      fontSize: '12px'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#ebf3f5' : '#ffffff',
        color: '#393939',
        fontSize: '12px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ':hover': {
          backgroundColor: '#c4c4c41d'
        }
      };
    },
    singleValue: (styles, state) => ({
      ...styles,
      color: '#393939',
      fontSize: '12px'
    }),
    indicatorSeparator: (styles, state) => ({
      ...styles,
      display: 'none'
    }),
    menuList: (styles, state) => ({
      ...styles,
      backgroundColor: '#ffffff',
      maxWidth: '200px'
    }),
    input: (styles, state) => ({
      ...styles,
      color: '#393939'
    })
  };

  return (
    <Layout history={history}>
      <DeletePopUp open={open} setOpen={setOpen} id={selectedData?._id} />
      {!showAddWorkflow ? (
        <div className={classes.titleBarContainer}>
          <Typography className={classes.title}>Dashboard</Typography>
          <div style={{ display: 'flex', gap: '15px', padding: '10px 0' }}>
            <div className={classes.workflowFilters}>
              <ReactSelect
                isMulti={true}
                options={userData?.clients || []}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?._id}
                noOptionsMessage={obj => 'No options'}
                styles={selectCustomStyles}
                onChange={clientSelectHandler}
                closeMenuOnSelect={false}
                value={selectedClients}
                hideSelectedOptions={false}
                placeholder="Select clients"
              />
            </div>
            <div className={classes.workflowFilters}>
              <ReactSelect
                isMulti
                options={apartmentsByTenantIds ?? []}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?._id}
                noOptionsMessage={obj => 'No options'}
                styles={selectCustomStyles}
                onChange={communitySelectHandler}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedCommunities}
                placeholder="Select communities"
              />
            </div>
            <SearchBar handleChange={handleSearchBar} />
          </div>
        </div>
      ) : null}
      {!showAddWorkflow ? (
        <div
          style={{ height: '85vh', overflow: 'auto' }}
          className={classes.mainContainer}
        >
          <Ztable
            columns={columns}
            rows={tableData}
            duplicateRow={handleDuplicate}
            editRow={handleEditRow}
            deleteRow={handleDeleteRow}
          />
          <WorkflowMenu
            classes={classes}
            addFollowupMessage={addFollowupMessage}
            addAutomatedMessage={addAutomatedMessage}
          />
        </div>
      ) : (
        <div
          style={{ height: '95vh', overflow: 'auto' }}
          className={classes.addWorkflowContainer}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <ArrowBackIcon
              onClick={handleNavigation}
              className={classes.backArrow}
            />
            <Link className={classes.title} underline="hover" color="inherit">
              {showFollowUpMessage ? 'Follow up' : 'Automated first Message'}
            </Link>
          </Breadcrumbs>
          <SequenceForm
            selectedData={selectedData}
            handleNavigation={handleNavigation}
            classes={classes}
            showAutomatedMessage={showAutomatedMessage}
            showFollowUpMessage={showFollowUpMessage}
          />
        </div>
      )}
    </Layout>
  );
};

export default WorkflowV1;
