import { useContext } from 'react';
import CheatsheetContextProvider from '../../utils/ContextProviders/CheatsheetContextProvider';
import MasterCheatsheet from './CheatsheetForm';
import Layout from '../../Component/layout';

const Communities = () => {
  return (
    <Layout>
      <CheatsheetContextProvider>
        <MasterCheatsheet />
      </CheatsheetContextProvider>
    </Layout>
  );
};

export default Communities;
