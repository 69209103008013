import React from 'react';

const DeleteIcon = ({
  style = {},
  onClick = () => {},
  height = '24',
  width = '24'
}) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        d="M7.845 20H16.15C16.3865 20.0002 16.6153 19.9165 16.7959 19.7637C16.9764 19.611 17.0969 19.3992 17.136 19.166M7.845 20L7.84515 19.5H16.15H16.1504C16.2685 19.5001 16.3828 19.4583 16.473 19.382C16.5631 19.3058 16.6233 19.2001 16.6428 19.0837M7.845 20C7.60844 19.9999 7.37956 19.916 7.19904 19.7631C7.01851 19.6102 6.89803 19.3983 6.859 19.165M7.845 20V19.5C7.72677 19.4999 7.61239 19.458 7.52217 19.3816C7.43194 19.3052 7.37171 19.1993 7.35217 19.0827L7.35221 19.0829L6.859 19.165M16.6428 19.0837L17.136 19.166M16.6428 19.0837L16.6429 19.0834M16.6428 19.0837L16.6429 19.0834M17.136 19.166L16.6429 19.0834M17.136 19.166L18.9165 8.5H18.4096M16.6429 19.0834L18.4096 8.5M18.4096 8.5L18.4931 8H5.50688L5.59013 8.5M18.4096 8.5H5.59013M5.59013 8.5H5.08325L6.859 19.165M5.59013 8.5L7.35215 19.0825L6.859 19.165M15 5.5H15.5H19C19.1326 5.5 19.2598 5.55268 19.3536 5.64645C19.4473 5.74021 19.5 5.86739 19.5 6V6.5H4.5V6C4.5 5.86739 4.55268 5.74022 4.64645 5.64645C4.74022 5.55268 4.86739 5.5 5 5.5H8.5H9H15Z"
        stroke="#42526E"
      />
    </svg>
  );
};

export default DeleteIcon;
