import styled from 'styled-components';

const DrawerWrapper = styled.div`
  height: ${({ height }) => height ?? '100%'};
  position: fixed;
  display: flex;
  top: ${({ top }) => top ?? '0'};
  right: ${({ open }) => (open ? '10px' : '0')};
  z-index: 200;
  transition: all 0.25s ease-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  box-sizing: border-box;
`;

const DrawerContentWrapper = styled.div`
  height: 100%;
  width: ${({ width }) => width ?? '300px'};
  background: ${({ theme }) => theme.background.secondary};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0px'};
  box-shadow: ${({ boxShadow }) =>
    boxShadow ?? '1px 0px 7px rgba(0, 0, 0, 0.5)'};
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const DrawerHeaderWrapper = styled.div`
  padding: ${({ padding }) => padding ?? 0};
  margin: ${({ margin }) => margin ?? 0};
  background: ${({ theme, background }) =>
    background ?? theme?.background?.secondary};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 0};
  position: sticky;
  top: 0;
  z-index: 2;
`;

const DrawerBodyWrapper = styled.div`
  padding: ${({ padding }) => padding ?? 0};
  margin: ${({ margin }) => margin ?? 0};
  background: ${({ theme }) => theme.background.secondary};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 0};
  height: 100%;
`;

const DrawerFooterWrapper = styled.div`
  padding: ${({ padding }) => padding ?? 0};
  margin: ${({ margin }) => margin ?? 0};
  background: ${({ theme }) => theme.background.secondary};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 0};
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

const CollapseContainer = styled.div`
  height: 100%;
  width: 24px;
  z-index: 3;
`;

export {
  DrawerWrapper,
  DrawerContentWrapper,
  DrawerHeaderWrapper,
  DrawerBodyWrapper,
  DrawerFooterWrapper,
  CollapseContainer
};
