import React from 'react';
const FilterIcon = ({ iconStyle, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={iconStyle}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1H1L7.4 8.568V13.8L10.6 15.4V8.568L17 1Z"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
