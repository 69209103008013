import { useMutation } from '@apollo/client';
import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RadioButton from '../../../globalcomponents/RadioButton';
import { callToTextUpdate } from '../../../Redux/Actions/Apartment';
import IvrSlots from './IvrSlots';
import SavedField from './SavedField';
import { convertObjToArr } from './utils';
import { updateIvrSetting } from '../../../gql/mutations/apartment';
import { fetchApartmentByIdC2T } from '../../../gql/queries/apartmentById';
import { VanityNumbersHeading } from './ivrCall.styles';

const IvrCall = ({
  showZumaIvr,
  updatedCallData,
  setUpdateCallData,
  callData = {},
  enableKnockCRM,
  voiceMail,
  updateApartment,
  slotsEditable,
  setSlotsEditable
}) => {
  const [ivrRedirect, setIvrRedirect] = useState(
    callData?.zumaIVRSetting?.ivrType
  );
  const [slotsData, setSlotsData] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState({});
  const dispatch = useDispatch();
  const handleSave = () => {
    const slotsArray = convertObjToArr(slotsData);
    const updatedData = {
      ...updatedCallData,
      slots: [...slotsArray],
      id: callData._id
    };
    dispatch(callToTextUpdate(updatedData));
    setSlotsEditable(false);
    setSnackBarOpen(true);
    setSnackbarMessage({
      message: 'Details updated successfully.',
      type: 'success'
    });
  };
  const ivrType = callData?.zumaIVRSetting?.ivrType;
  const [updateSlotsData] = useMutation(updateIvrSetting, {
    refetchQueries: [
      { query: fetchApartmentByIdC2T, variables: { apartmentId: callData._id } }
    ]
  });
  console.log('callData', callData);
  useState(() => {
    if (ivrType) {
      setIvrRedirect(ivrType);
    }
  }, [ivrType]);

  useEffect(() => {
    if (
      (callData?._id && callData?.zumaIVRSetting === null) ||
      callData?.zumaIVRSetting?.slots?.length === 0
    ) {
      updateSlotsData({
        variables: {
          aptId: callData._id,
          record: {},
          type: 'new'
        }
      });
    }
  }, [callData._id]);
  return (
    <>
      {snackBarOpen && (
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setSnackBarOpen(false);
          }}
          action={'Success'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={snackBarMessage.type}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div
        style={{ width: enableKnockCRM ? '62%' : '100%' }}
        className={` ivr-box  ${slotsEditable ? '' : 'pointer-none'}`}
      >
        <div className={!showZumaIvr ? 'show-faded-box' : ''}></div>
        <div
          style={{ cursor: slotsEditable ? '' : 'default' }}
          className="box-1-ivr"
        >
          <VanityNumbersHeading>Vanity Numbers</VanityNumbersHeading>
          <RadioButton
            name={'ivrOptions'}
            value="MULTIPLE"
            label="Route to SSC table vanity numbers"
            labelFontSize="0.9rem"
            checked={
              updatedCallData?.ivrType
                ? updatedCallData?.ivrType === 'MULTIPLE'
                : callData?.zumaIVRSetting?.ivrType === 'MULTIPLE'
            }
            onChange={() => {
              setIvrRedirect('MULTIPLE');
              setUpdateCallData({
                ...updatedCallData,
                ivrType: 'MULTIPLE'
              });
            }}
          />
          <RadioButton
            name={'ivrOptions'}
            value="SINGLE"
            label="Single Vanity number"
            labelFontSize="0.9rem"
            checked={
              updatedCallData?.ivrType
                ? updatedCallData?.ivrType === 'SINGLE'
                : callData?.zumaIVRSetting?.ivrType === 'SINGLE'
            }
            onChange={() => {
              setIvrRedirect('SINGLE');
              setUpdateCallData({
                ...updatedCallData,
                ivrType: 'SINGLE'
              });
            }}
          />

          <SavedField
            type={'select'}
            key={'Vanity Number-2'}
            {...{
              editable: slotsEditable,
              label: 'Vanity Number',
              fullWidth: true,
              updatedCallData,
              setUpdateCallData,
              id: callData._id,
              keyName: 'zumaIvrPhone'
            }}
            value={
              updatedCallData?.zumaIvrPhone !== undefined
                ? updatedCallData?.zumaIvrPhone
                : callData?.zumaIVRSetting?.vanityPhoneNumber
            }
            style={{
              visibility:
                updatedCallData?.ivrType !== undefined
                  ? updatedCallData?.ivrType === 'SINGLE'
                    ? 'visible'
                    : 'hidden'
                  : callData?.zumaIVRSetting?.ivrType === 'SINGLE'
                  ? 'visible'
                  : 'hidden'
            }}
          />

          <SavedField
            type={'audio'}
            {...{
              editable: slotsEditable,
              label: 'Voice Mail',
              fullWidth: true,
              updatedCallData,
              setUpdateCallData
            }}
            value={
              updatedCallData?.voicemail !== undefined
                ? updatedCallData?.voicemail
                : callData?.zumaIVRSetting?.voicemail
            }
            fileName={
              updatedCallData?.voicemail !== undefined
                ? updatedCallData?.voicemail
                : voiceMail
            }
            keyName={'voicemailName'}
            keyUrl={'voicemailUrl'}
            keyVoiceId={'voicemailId'}
          />
        </div>
        <div className="box-2-ivr">
          <div className="heading">
            Slots
            <IvrSlots
              {...{
                updatedCallData,
                setUpdateCallData,
                callData,
                slotsEditable,
                slotsData,
                setSlotsData,
                id: callData._id
                // updateSlotsData
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IvrCall;
