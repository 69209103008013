import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '../../../Media/InboxV1/EditIcon';
import SettingIcon from '../../../Media/InboxV1/SettingIcon';
import UploadIcon from '../../../Media/InboxV1/UploadIcon';
import { fetchAvailableNumbers } from '../../../Redux/Actions/CallToText';
import TrashIcon from '../../../Media/Inbox/TrashIcon';
import SelectType from './SelectType';
import { Tooltip } from '@mui/material';
import Uploading from '../../../Media/InboxV1/uploading.gif';
import VanityNumberPopup from './VanityNumberPopup';
import VanityDeletePopup from './VanityDeletePopup';
import { useParams } from 'react-router-dom';
import { uploadFileApiV2 } from '../../../Redux/util/Apis/chat';

const SavedField = ({
  editable,
  type,
  value,
  label,
  hasEditIcon,
  handleEdit,
  fullWidth,
  marginBottom,
  id,
  keyName,
  keyUrl,
  keyVoiceId,
  updatedCallData,
  setUpdateCallData,
  showSelectType = false,
  setExistingLeadType,
  isSlotData,
  handleSlotChange,
  fileName,
  style
}) => {
  const [textField, setTextField] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const callTextSlice = useSelector(state => state.callTextData);
  const params = useParams();
  const dispatch = useDispatch();
  const {
    availableNumbers,
    uploadingFile,
    uploadingFileField,
    uploadedFileData
  } = callTextSlice;

  useEffect(() => {
    if (textField.length > 2) {
      dispatch(fetchAvailableNumbers({ code: textField, id }));
    }
  }, [textField]);

  const handleNumberChange = newValue => {
    if (isSlotData) {
      handleSlotChange(newValue || selectedNumber);
    } else {
      setUpdateCallData({
        ...updatedCallData,
        [keyName]: newValue || selectedNumber
      });
    }
  };
  const handleFileUpload = async (e, selectedName) => {
    e.preventDefault();
    e.stopPropagation();

    const data = await uploadFileApiV2({
      file: e.target.files[0],
      type: selectedName,
      id: params?.id
    });

    if (selectedName === 'voicemailName') {
      setUpdateCallData({
        ...updatedCallData,
        voicemail: data?.url
      });
    } else if (selectedName === 'textableVoiceName') {
      setUpdateCallData({
        ...updatedCallData,
        textableVoiceName: data?.url,
        textableVoiceUrl: data?.url
      });
    } else if (selectedName === 'nonTextableVoiceName') {
      setUpdateCallData({
        ...updatedCallData,
        nonTextableVoiceName: data?.url,
        nonTextableVoiceUrl: data?.url
      });
    } else {
      const slotsData = updatedCallData?.slots[selectedName];
      slotsData.audioValue = data?.url;
      setUpdateCallData({
        ...updatedCallData,
        slots: { ...updatedCallData?.slots, [selectedName]: slotsData }
      });
    }
  };

  useEffect(() => {
    if (keyName === uploadingFileField && !isSlotData) {
      if (showSelectType) {
        setUpdateCallData({
          ...updatedCallData,
          [keyUrl]: uploadedFileData.url,
          [keyVoiceId]: uploadedFileData.id,
          [keyName]: uploadedFileData.filename,
          existingType: type
        });
      } else
        setUpdateCallData({
          ...updatedCallData,
          [keyUrl]: uploadedFileData.url,
          [keyVoiceId]: uploadedFileData.id,
          [keyName]: uploadedFileData.filename
        });
    }
    if (isSlotData && keyName === uploadingFileField) {
      handleSlotChange({
        url: uploadedFileData.url,
        id: uploadedFileData.id,
        name: uploadedFileData.filename
      });
    }
  }, [uploadedFileData]);

  const isUploading = uploadingFile && keyName === uploadingFileField;

  const handleDelete = () => {
    if (keyUrl === 'voicemailUrl') {
      setUpdateCallData({
        ...updatedCallData,
        voicemail: ''
      });
      return;
    }
    if (keyUrl) {
      setUpdateCallData({
        ...updatedCallData,
        [keyUrl]: '',
        [keyVoiceId]: '',
        [keyName]: ''
      });
    } else {
      setUpdateCallData({
        [keyName]: ''
      });
    }
  };

  return (
    <>
      <div
        className="non-editCard"
        style={
          fullWidth
            ? { width: '100%', ...style }
            : marginBottom
            ? { marginBottom: '0px', width: '20%', ...style }
            : {}
        }
      >
        {label ? (
          <label className="item-label">
            {label}
            {editable && value ? (
              <span>
                <TrashIcon
                  handleClick={() => {
                    keyName === 'zumaIvrPhone' ||
                    keyName === 'phone' ||
                    keyName === 'textableVoiceName' ||
                    keyName === 'existingForwardingNumber' ||
                    keyName === 'nonTextableVoiceName' ||
                    keyName === 'voicemailName'
                      ? setOpenDelete(true)
                      : handleDelete(1);
                  }}
                />
              </span>
            ) : null}
          </label>
        ) : null}
        {hasEditIcon ? <EditIcon {...{ onClick: handleEdit }} /> : null}
        <div
          className={
            marginBottom
              ? `content content-margin-less ${editable ? 'editable' : ''}`
              : `${editable ? 'editable content' : 'content'}`
          }
          style={
            showSelectType && type === 'number'
              ? { justifyContent: 'flex-end' }
              : value && style
              ? { ...style }
              : {}
          }
        >
          {keyName === 'zumaIvrPhone' ||
          keyName === 'phone' ||
          keyName === 'textableVoiceName' ||
          keyName === 'existingForwardingNumber' ||
          keyName === 'nonTextableVoiceName' ||
          keyName === 'voicemailName' ? (
            <>
              <VanityNumberPopup
                {...{
                  open,
                  setOpen,
                  handleNumberChange,
                  setSelectedNumber,
                  id,
                  selectedNumber,
                  keyName
                }}
              />
              <VanityDeletePopup
                {...{
                  keyName,
                  open: openDelete,
                  setOpen: setOpenDelete,
                  handleDelete
                }}
              />
            </>
          ) : null}
          {editable && showSelectType ? (
            <SelectType
              index={0}
              handleChange={e => setExistingLeadType(e.target.value)}
              hideCallText={true}
              value={type}
            />
          ) : null}

          {(editable || value) && type === 'audio' ? (
            <>
              {value ? (
                <Tooltip placement="top" title={fileName}>
                  <audio
                    controls
                    className={editable ? 'editable-audio' : ''}
                    style={
                      showSelectType && type
                        ? { width: '75%', background: '#f6f6f6' }
                        : {}
                    }
                  >
                    <source src={value} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </Tooltip>
              ) : (
                <div>
                  <label
                    className="d-flex-center"
                    style={{ cursor: 'pointer' }}
                    htmlFor={keyName}
                  >
                    {isUploading ? (
                      <>
                        <img
                          alt={'Upload'}
                          style={{ width: '50px', height: '50px' }}
                          src={Uploading}
                        />
                        Uploading
                      </>
                    ) : (
                      <>
                        <UploadIcon />
                        Upload Here
                      </>
                    )}
                    <input
                      type="file"
                      id={keyName}
                      style={{ display: 'none' }}
                      placeholder={keyName}
                      onChange={e => handleFileUpload(e, keyName)}
                    />
                  </label>
                </div>
              )}
            </>
          ) : (editable || value) && type === 'select' ? (
            <>
              {value ? (
                <>{value}</>
              ) : (
                <Autocomplete
                  fullWidth
                  disableClearable={true}
                  value={selectedNumber}
                  disabled={!editable}
                  options={(availableNumbers || []).map(({ phone }) => {
                    return { name: phone, value: phone };
                  })}
                  getOptionLabel={option => option?.name || ''}
                  onInputChange={(e, newValue) => {
                    setTextField(newValue);
                  }}
                  onChange={(e, newValue) => {
                    setSelectedNumber(newValue.value);
                    if (keyName === 'zumaIvrPhone' || keyName === 'phone') {
                      setOpen(true);
                    } else handleNumberChange(newValue.value);
                  }}
                  noOptionsText={'Type first 3 digits'}
                  placeholder={'Type first 3 digits'}
                  style={{
                    width: showSelectType && type ? '76%' : 'inherit',
                    padding: '0px '
                  }}
                  sx={{ padding: '0px ' }}
                  id="combo-box-demo"
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ padding: '0px !important' }}
                      placeholder={'Type first 3 digits'}
                    />
                  )}
                />
              )}
            </>
          ) : (editable || value) && type === 'number' ? (
            <input
              style={
                isSlotData
                  ? { width: '80%' }
                  : !editable
                  ? { border: 'none' }
                  : {}
              }
              placeholder="Enter number"
              value={value}
              type="text"
              disabled={!editable}
              onChange={e => handleNumberChange(e.target.value)}
              className="input-number"
            />
          ) : (
            <>
              {type ? (
                <>
                  {type === 'call-text' ? (
                    'Call To Text'
                  ) : type === 'LEAD_INGESTION' ? (
                    'Lead Ingestion'
                  ) : (
                    <>
                      <SettingIcon /> "Configure"
                    </>
                  )}
                </>
              ) : (
                <>Not Selected </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedField;
