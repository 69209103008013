import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Layout from '../../Component/layout';
import Wnavigation from './Wnavigation';
import Wcontent from './Wcontent';

const Workflow = props => {
  const flex = {
    display: 'flex'
  };
  const NavState = 'Message Template';
  return (
    <Layout history={props.history}>
      <div style={flex}>
        <Wcontent NavState={NavState} />
      </div>
    </Layout>
  );
};

export default Workflow;
