import { del, get, put } from '../../Redux/networkClient';

const baseUrl = process.env.REACT_APP_TOXICITY_URL;
const apiKey = process.env.REACT_APP_TOXICITY_API_KEY;
export const ApprovedWebsites = async () =>
  await get(
    `${baseUrl}/allowed_domains/list`,
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );

export const AddApprovedWebsites = async domain =>
  await put(
    `${baseUrl}/allowed_domains/add?domain=${domain}&api_key=${apiKey}`,
    {},
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );

export const DeleteApprovedWebsites = async domain =>
  await del(
    `${baseUrl}/allowed_domains/delete?domain=${domain}&api_key=${apiKey}`,
    {},
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );

export const ToxicWords = async () =>
  await get(
    `${baseUrl}/block_words/list`,
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );

export const AddToxicWords = async word =>
  await put(
    `${baseUrl}/block_words/add?block_word=${word}&api_key=${apiKey}`,
    {},
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );

export const DeleteToxicWords = async word =>
  await del(
    `${baseUrl}/block_words/delete?block_word=${word}&api_key=${apiKey}`,
    {},
    {},
    {
      'Content-Type': 'application/json'
    },
    false
  );
