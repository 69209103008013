import React from 'react';

const UploadIcon = ({ onClick, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      style={style}
    >
      <rect width="24" height="24" fill="white" fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.208 9.32L9.29 11.253C9.10405 11.4403 8.9997 11.6936 8.9997 11.9575C8.9997 12.2214 9.10405 12.4747 9.29 12.662C9.38133 12.7544 9.4901 12.8278 9.61001 12.8779C9.72991 12.928 9.85856 12.9538 9.9885 12.9538C10.1184 12.9538 10.2471 12.928 10.367 12.8779C10.4869 12.8278 10.5957 12.7544 10.687 12.662L11.977 11.361L13.313 12.708C13.4043 12.8005 13.513 12.874 13.6329 12.9241C13.7527 12.9743 13.8813 13.0002 14.0113 13.0003C14.1412 13.0004 14.2699 12.9747 14.3898 12.9247C14.5098 12.8747 14.6186 12.8014 14.71 12.709C14.8953 12.5216 14.9993 12.2687 14.9995 12.0052C14.9997 11.7417 14.896 11.4887 14.711 11.301L12.746 9.321C12.6456 9.21904 12.5259 9.13806 12.3939 9.08275C12.262 9.02744 12.1203 8.99891 11.9772 8.99881C11.8341 8.99872 11.6924 9.02707 11.5604 9.0822C11.4283 9.13734 11.3085 9.21817 11.208 9.32Z"
        fill="#42526E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 10.007L11.001 19.993C11.001 20.55 11.449 21.001 12.001 21C12.554 21 13.001 20.55 13.001 19.993L13 10.006C13 9.451 12.552 9 12 9C11.448 9 10.999 9.451 11 10.007Z"
        fill="#42526E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.938 5.481C7.68114 5.43897 7.42128 5.4179 7.161 5.418C4.356 5.419 2 7.62 2 10.499C2 13.408 4.385 16 7.1 16H9.981V14.007H7.1C5.443 14.007 3.985 12.344 3.985 10.499C3.985 8.721 5.454 7.412 7.089 7.412H7.101C7.49 7.412 7.787 7.463 8.071 7.562L8.241 7.625C8.846 7.873 9.116 7.379 9.116 7.379L9.266 7.112C9.996 5.765 11.467 5.016 12.982 4.993C13.987 5.003 14.954 5.37825 15.7027 6.04875C16.4514 6.71924 16.9306 7.63919 17.051 8.637L17.097 8.977C17.097 8.977 17.168 9.502 17.762 9.502C17.775 9.502 17.774 9.507 17.785 9.507H18.039C19.175 9.507 20.015 10.466 20.015 11.665C20.015 12.872 19.028 14.007 17.945 14.007H13.981V16H17.945C20.105 16 22 13.955 22 11.665C22 9.666 20.688 8.002 18.862 7.591C18.155 4.884 15.809 3.039 12.976 3C11.001 3.021 9.075 3.901 7.938 5.481Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default UploadIcon;
