import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  enabledButton: {
    background: '#5267FF!important',
    '&:disabled': {
      background: '#5267FF!important',
      opacity: '50%!important'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const reasonText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19px',

  color: '#42526E'
};

const AppointmentCancelModal = ({
  open,
  handleClose,
  handleDelete,
  cancelReason,
  setCancelReason
}) => {
  const { deleteTourPending } = useSelector(state => state.slotsData);
  const [disabled, setDisabled] = React.useState(true);
  const classes = useStyles();
  const handleChange = event => {
    setCancelReason(event.target.value);
    setDisabled(false);
  };

  const cancelList = [
    'Internal testing',
    'Customer request',
    'Sync issue',
    'Agent error',
    'Kelsey Wrong Tour',
    'Lead Rescheduled'
  ];
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={reasonText}>Reason for cancellation</span>
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <FormControl>
          <Select
            value={cancelReason}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            style={{
              color: '#C4C4C4',
              width: '276px',
              height: '36px',
              marginLeft: '20px',
              marginRight: '20px',
              marginBottom: '20px',
              fontFamily: 'Inter',
              color: '#393939',
              fontSize: '12px',
              lineHeight: '15px'
            }}
            renderValue={
              cancelReason !== ''
                ? undefined
                : () => (
                    <span
                      style={{
                        fontFamily: 'Inter',
                        color: '#393939',
                        fontSize: '12px',
                        lineHeight: '15px'
                      }}
                    >
                      Select here
                    </span>
                  )
            }
          >
            {cancelList?.map(name => (
              <MenuItem
                value={name}
                style={{
                  fontFamily: 'Inter',
                  color: '#393939',
                  fontSize: '12px',
                  lineHeight: '15px'
                }}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DialogActions
          style={{
            justifyContent: 'left',
            marginLeft: '10px',
            marginBottom: '10px'
          }}
        >
          <Button
            disabled={disabled}
            variant="contained"
            className={classes.enabledButton}
            onClick={handleDelete}
          >
            {deleteTourPending ? 'Saving..' : 'Save'}
          </Button>
          <Button
            onClick={handleClose}
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#393939',
              opacity: '0.5'
            }}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppointmentCancelModal;
