import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import {
  HeadingAStyle,
  ContainerA
} from '../../styles/propertystyles/Otherstyle';

const Accessibility = props => {
  const SwitchTabs = (res, handle, accesibility) => {
    return (
      <Grid
        item
        xs={6}
        key={`${Math.floor(Math.random() * 1000)}-min`}
        style={ContainerA}
      >
        <Switch
          checked={
            accesibility &&
            accesibility.length > 0 &&
            accesibility.find(accesibility => accesibility.name === res.name)
          }
          onChange={event => handle(event, res._id)}
          name={res.name}
          color="primary"
        />
        <span style={HeadingAStyle}>{res.name}</span>
      </Grid>
    );
  };
  return (
    <div>
      <>
        <Grid
          container
          key={`${Math.floor(Math.random() * 1000)}-min`}
          style={{ marginLeft: '-11px' }}
        >
          {props.data &&
            props.data.map(res => {
              return SwitchTabs(
                res,
                props.handleAccesibility,
                props.accesibility
              );
            })}
        </Grid>
      </>
    </div>
  );
};

export default Accessibility;
