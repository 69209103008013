import React from 'react';

const QaIcon = ({ color = '#fff', size = '28' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'23'}
      height={'23'}
      viewBox="0 0 23 13"
      fill="none"
    >
      <path
        d="M5.48295 8.09091H7.39205L8.52273 9.55682L9.32955 10.5114L11.267 13H9.22159L7.90341 11.3295L7.34091 10.5341L5.48295 8.09091ZM11.5455 6.18182C11.5455 7.43561 11.3106 8.50947 10.8409 9.40341C10.375 10.2936 9.73864 10.9754 8.93182 11.4489C8.12879 11.9223 7.2178 12.1591 6.19886 12.1591C5.17992 12.1591 4.26705 11.9223 3.46023 11.4489C2.6572 10.9716 2.02083 10.2879 1.55114 9.39773C1.08523 8.50379 0.852273 7.43182 0.852273 6.18182C0.852273 4.92803 1.08523 3.85606 1.55114 2.96591C2.02083 2.07197 2.6572 1.38826 3.46023 0.914772C4.26705 0.441287 5.17992 0.204545 6.19886 0.204545C7.2178 0.204545 8.12879 0.441287 8.93182 0.914772C9.73864 1.38826 10.375 2.07197 10.8409 2.96591C11.3106 3.85606 11.5455 4.92803 11.5455 6.18182ZM9.42614 6.18182C9.42614 5.29924 9.28788 4.55492 9.01136 3.94886C8.73864 3.33902 8.35985 2.87879 7.875 2.56818C7.39015 2.25379 6.83144 2.09659 6.19886 2.09659C5.56629 2.09659 5.00758 2.25379 4.52273 2.56818C4.03788 2.87879 3.6572 3.33902 3.38068 3.94886C3.10795 4.55492 2.97159 5.29924 2.97159 6.18182C2.97159 7.06439 3.10795 7.81061 3.38068 8.42045C3.6572 9.02652 4.03788 9.48674 4.52273 9.80114C5.00758 10.1117 5.56629 10.267 6.19886 10.267C6.83144 10.267 7.39015 10.1117 7.875 9.80114C8.35985 9.48674 8.73864 9.02652 9.01136 8.42045C9.28788 7.81061 9.42614 7.06439 9.42614 6.18182ZM14.4233 12H12.1733L16.2699 0.363636H18.8722L22.9744 12H20.7244L17.6165 2.75H17.5256L14.4233 12ZM14.4972 7.4375H20.6335V9.13068H14.4972V7.4375Z"
        fill="white"
      />
    </svg>
  );
};

export default QaIcon;
