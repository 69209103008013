import { GET_ALL_LEASE_LENGTH } from '../Constant';

const initialState = {
  LeaseLengthData: ' '
};
const LeaseLength = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEASE_LENGTH:
      return { ...state, LeaseLengthData: action.data };
    default:
      return state;
  }
};

export default LeaseLength;
