import { gql } from '@apollo/client';

export const sensitiveLeadInfo = gql`
  query leadById($leadId: MongoID!) {
    leadById(_id: $leadId) {
      name
      phone
      email
    }
  }
`;
