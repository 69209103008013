import React from 'react';

const CrossIcon = ({ onClick, style, color }) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="close-icon"
    >
      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
      <path
        d="M8.00003 7.05739L4.47136 3.52872C4.34563 3.40728 4.17722 3.34009 4.00243 3.3416C3.82763 3.34312 3.66042 3.41324 3.53682 3.53684C3.41321 3.66045 3.3431 3.82766 3.34158 4.00245C3.34006 4.17725 3.40726 4.34565 3.52869 4.47139L7.05736 8.00005L3.52869 11.5287C3.40726 11.6545 3.34006 11.8229 3.34158 11.9977C3.3431 12.1725 3.41321 12.3397 3.53682 12.4633C3.66042 12.5869 3.82763 12.657 4.00243 12.6585C4.17722 12.66 4.34563 12.5928 4.47136 12.4714L8.00003 8.94272L11.5287 12.4714C11.6544 12.5928 11.8228 12.66 11.9976 12.6585C12.1724 12.657 12.3396 12.5869 12.4632 12.4633C12.5868 12.3397 12.657 12.1725 12.6585 11.9977C12.66 11.8229 12.5928 11.6545 12.4714 11.5287L8.94269 8.00005L12.4714 4.47139C12.535 4.40989 12.5858 4.33633 12.6208 4.25499C12.6557 4.17365 12.6741 4.08617 12.6749 3.99766C12.6756 3.90914 12.6588 3.82135 12.6252 3.73942C12.5917 3.65749 12.5422 3.58305 12.4796 3.52046C12.417 3.45786 12.3426 3.40836 12.2607 3.37484C12.1787 3.34132 12.0909 3.32445 12.0024 3.32522C11.9139 3.32599 11.8264 3.34438 11.7451 3.37932C11.6638 3.41426 11.5902 3.46505 11.5287 3.52872L8.00003 7.05739Z"
        fill={color ? color : '#42526E'}
      />
    </svg>
  );
};

export default CrossIcon;
