import { gql } from '@apollo/client';

export const LeadInfoById = gql`
  query leadById($leadId: MongoID!) {
    leadById(_id: $leadId) {
      _id
      name
      botTurningOffReason
      callOptIn
      source
      emailOptIn
      enableBot
      followupToggle
      isArchived
      closedReason
      textOptIn
      smsEnabled
      notes
      stage
      website
      waitlist {
        notes
        date
      }
      preference {
        interest
        unit
        bedroom
        bathroom
        budgetMax
        budgetMin
        monthlyRent
        moveIn
        pet
        deposit
        occupant
        income
        creditScore
        leaseTerm
        petType
        isFurnished
      }
      tenant {
        name
        _id
      }
      apartment {
        name
        _id
      }
      meta
      leasingAgent {
        name
        username
      }
    }
  }
`;
