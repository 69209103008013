import { gql } from '@apollo/client';

export const createWaitList = gql`
  mutation createLeadWaitlist($_id: MongoID!, $record: WaitListInputTCInput!) {
    createLeadWaitlist(_id: $_id, record: $record) {
      record {
        waitlist {
          date
          notes
        }
      }
    }
  }
`;

export const deleteWaitlist = gql`
  mutation deleteLeadWaitlist($_id: MongoID!, $existingDate: String!) {
    deleteLeadWaitlist(_id: $_id, existingDate: $existingDate) {
      record {
        waitlist {
          date
          notes
        }
      }
    }
  }
`;
