import React, { useState } from 'react';
import moment from 'moment-timezone';
import TickIcon from '../../../Media/tick.svg';
import ErrorIcon from '../../../Media/error.svg';
import Checkbox from '@mui/material/Checkbox';
import styles from './styles.module.scss';
import Button from '../Button';

const Grid = ({
  header = 'Kormangala Koramangala',
  place = '138 Hyde street, Sano..',
  timeZone = 'Gmt -7',
  category = '2D',
  error,
  integrations,
  clickHandler,
  address,
  timeLabel,
  onBoardCommunities,
  apptId,
  removeOnboard,
  setCheatSheetUploaded,
  cheatSheetUploaded
}) => {
  const [checked, setChecked] = useState(false);
  const handleChange = e => {
    e.stopPropagation();
    if (!integrations.length) {
      setCheatSheetUploaded(false);
      return;
    }
    setChecked(prev => {
      if (!prev) {
        onBoardCommunities(apptId);
      } else {
        removeOnboard(apptId);
      }
      return !prev;
    });
  };
  return (
    <div
      onClick={clickHandler}
      className={`communtity-grid ${styles.gridContainer}`}
    >
      <h6>{header}</h6>
      <p>{address?.fullAdd}</p>
      <div className={styles.footer}>
        <div className={styles.rightSection}>
          {/* {integrations?.length ? (
            <Button overRideClass={"greyButton"} label={"No Settings"} />
          ) : (
            <>
             {timeZone&& <span className={styles.timeContainer}>{timeZone}</span>} 
              {error && (
                <div className={styles.status}>
                  <img src={error ? ErrorIcon : TickIcon} alt="" />
                </div>
              )}
            </>
          )} */}
          {timeZone && <span className={styles.timeContainer}>{timeZone}</span>}
          {error && (
            <div className={styles.status}>
              <img src={error ? ErrorIcon : TickIcon} alt="" />
            </div>
          )}
        </div>

        <span className={styles.category}>
          {timeLabel ? moment(timeLabel).format('MMM D, h:ma') : ''}
        </span>
      </div>
      {!error ? (
        <div className={styles.checkBoxContainer}>
          <Checkbox
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              '&.Mui-checked': {
                color: '#5267FF'
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            checked={checked}
            onClick={handleChange}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Grid;
