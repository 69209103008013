export const GET_ALL_ACCESSIBILITY = 'GET_ALL_ACCESSIBILITY';
export const GET_ALL_UTILITY = 'GET_ALL_UTILITY';
export const GET_ALL_LEASE_LENGTH = 'GET_ALL_LEASE_LENGTH';
export const GET_ALL_PET_POLICY = 'GET_ALL_PET_POLICY';
export const GET_ALL_AMENITIES = 'GET_ALL_AMENITIES';
export const GET_ALL_APPLIANCES = 'GET_ALL_APPLIANCES';

// propertyConst
export const GET_ALL_PROPERTY = 'GET_ALL_PROPERTY';
export const POST_PROPERTY = 'POST_PROPERTY';
export const PROPERTY_BY_ID = 'PROPERTY_BY_ID';
export const GET_PLACE_AUTOSUGGEST = 'GET_PLACE_AUTOSUGGEST';
export const GET_PROP_AUTOSUGGEST = 'GET_PROP_AUTOSUGGEST';
export const GET_APARTMENT = 'GET_APARTMENT';

//File
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_APART = 'FILE_UPLOAD_APART';
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
export const LOADING = 'LOADING';

// filter
export const FILTER_TOGGLE = 'FILTER_TOGGLE';
export const FILTERED_DATA = 'FILTERED_DATA';

//Auth
export const LOGIN_ERROR = 'LOGIN_ERROR';

// Calendar
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const POST_AN_EVENT = 'POST_AN_EVENT ';
export const DELETE_EVENT = 'DELETE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const GET_SETTING_DATA = 'GET_SETTING_DATA';
export const UPDATE_SETTING_DATA = 'UPDATE_SETTING_DATA';

//
export const ATTACH_CONNECTED_ACCOUNTS = 'ATTACH_CONNECTED_ACCOUNTS';

//Leads
export const CREATE_LEAD = 'CREATE_LEAD';
export const GET_LEADS = 'GET_LEADS';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const DELETE_LEAD = 'DELETE_LEAD';
export const LOADER_ACTIVATE = 'LOADER_ACTIVATE';
export const POST_LEADS_MSG = 'POST_LEADS_MSG';
export const POST_NEW_LEADS_MSG = 'POST_NEW_LEADS_MSG';
export const GET_LEAD_BY_PHONE = 'GET_LEAD_BY_PHONE';
export const CLEAR_PHONE_REDUCER = 'CLEAR_PHONE_REDUCER';

//Message
export const GET_ALL_LEAD_MESSAGES = 'GET_ALL_LEAD_MESSAGES';
export const CREATE_LEAD_MESSAGE = 'CREATE_LEAD_MESSAGE';

//Toast
export const GET_TOAST = 'GET_TOAST';

//WorkFlow
export const CREATE_WORKFLOW = 'CREATE_WORKFLOW';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const CREATE_MSG_TEMPLATE = 'CREATE_MSG_TEMPLATE';
export const GET_MSG_TEMPLATE = 'GET_MSG_TEMPLATE';
export const UPDATE_MSG_TEMPLATE = 'UPDATE_MSG_TEMPLATE';
export const ADD_WORKFLOW = 'ADD_WORKFLOW';
export const ADD_SEQUENCE = 'ADD_SEQUENCE';
export const ADD_SEQUENCE_CONTENT = 'ADD_SEQUENCE_CONTENT';
export const GET_ALL_WORKFLOW = 'GET_ALL_WORKFLOW';
export const CREATE_CAMPAGIN = 'CREATE_CAMPAGIN';
export const GET_ALL_CAMPAIGN = 'GET_ALL_CAMPAIGN';
export const CREATE_STEP = 'CREATE_STEP';
export const GET_ALL_STEP = ' GET_ALL_STEP';
export const GET_STEP_BY_ID = ' GET_STEP_BY_ID';

//Inbox
export const GET_INBOX_MSG = 'GET_INBOX_MSG';
export const GET_ALL_LEAD_MSG = 'GET_ALL_LEAD_MSG';
export const CREATE_LEAD_MSG = 'CREATE_LEAD_MSG';
export const GET_INBOX_META = 'GET_INBOX_META';
export const BACKDROP_STATUS = 'BACKDROP_STATUS';
export const ADD_LEAD_LOADER = 'ADD_LEAD_LOADER';
export const GET_LEAD_BY_SEARCH = 'GET_LEAD_BY_SEARCH';
export const FILTER_LEADS = 'FILTER_LEADS';
export const FILTER_STATE = 'FILTER_STATE';

//GlobalSearch
export const GET_SEARCHED_DATA = 'GET_SEARCHED_DATA';

//Admin
export const GET_AVAILABLE_NUMBER = 'GET_AVAILABLE_NUMBER';

// Apartment
export const GET_APARTMENT_AMENITIES = 'GET_APARTMENT_AMENITIES';
export const GET_ALL_BUILDING = 'GET_ALL_BUILDING';
export const GET_BUILDING_BY_ID = 'GET_BUILDING_BY_ID';
export const GET_MASTER_CHEATSHEET = 'GET_MASTER_CHEATSHEET';
export const GET_TOUR_TYPES_BY_APARTMENT = 'GET_TOUR_TYPES_BY_APARTMENT';

//NotificationCard
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const PUT_NOTIFICATION = 'PUT_NOTIFICATION';

//AgentProfile
export const AGENT_CLIENTS = 'AGENT_CLIENTS';
export const AGENT_ERROR = 'AGENT_ERROR';
export const UPDATE_AGENT_PROFILE = 'UPDATE_AGENT_PROFILE';
