import React from 'react';
import { QueryContainer, QueryHeader } from './unanswered-query.styles';
import Button from '../../../globalcomponents/Button';

const UnansweredQuery = ({ queryString, onAcknowledge, messageId, key }) => {
  return (
    <QueryContainer title={queryString}>
      <QueryHeader>{queryString}</QueryHeader>
      <Button size="xs" onClick={onAcknowledge}>
        Answered
      </Button>
    </QueryContainer>
  );
};

export default UnansweredQuery;
