import styled from 'styled-components';

const ScheduledMessageContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #e9ebfb;
`;
const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
`;
const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const HeaderTitle = styled.div`
  color: #393939;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const MessageBody = styled.div`
  color: rgba(57, 57, 57, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;
const TootltipText = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export {
  ScheduledMessageContainer,
  MessagesContainer,
  HeaderOptions,
  HeaderTitle,
  MessageHeader,
  FlexContainer,
  MessageBody,
  TootltipText
};
