import AudioIcon from '../../Media/InboxV1/AudioIcon';
import CallIcon from '../../Media/InboxV1/CallIcon';
import ForwardIcon from '../../Media/InboxV1/ForwardIcon';
import LeadIngestIcon from '../../Media/InboxV1/LeadIngestIcon';
import NAIcon from '../../Media/InboxV1/NAIcon';

export const Slots = [
  { name: 'Slot 1' },
  { name: 'Slot 2' },
  { name: 'Slot 3' },
  { name: 'Slot 4' },
  { name: 'Slot 5' },
  { name: 'Slot 6' },
  { name: 'Slot 7' },
  { name: 'Slot 8' },
  { name: 'Slot 9' }
];

export const slotsMapping = {
  'Slot 1': 'slot_1',
  'Slot 2': 'slot_2',
  'Slot 3': 'slot_3',
  'Slot 4': 'slot_4',
  'Slot 5': 'slot_5',
  'Slot 6': 'slot_6',
  'Slot 7': 'slot_7',
  'Slot 8': 'slot_8',
  'Slot 9': 'slot_9'
};
export const CallType = [
  { name: 'NA', value: 'NA', Icon: NAIcon },

  { name: 'Call to Text', value: 'call-text', Icon: CallIcon },

  { name: 'Audio', value: 'audio', Icon: AudioIcon },

  { name: 'Forwarding Number', value: 'number', Icon: ForwardIcon },

  { name: 'Lead Ingestion', value: 'LEAD_INGESTION', Icon: LeadIngestIcon }
];

export const ALL_TIME_ZONES = {
  'Pacific/Midway': '(GMT-11:00) Midway Island, Samoa',
  'Pacific/Honolulu': '(GMT-10:00) Hawaii',
  'America/Juneau': '(GMT-8:00) Alaska',
  'America/Boise': '(GMT-6:00) Mountain Time (US and Canada)',
  'America/Dawson': '(GMT-7:00) Pacific Time (US and Canada); Tijuana',
  'America/Chihuahua': '(GMT-6:00) Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': '(GMT-7:00) Arizona',
  'America/Chicago': '(GMT-5:00) Central Time',
  'America/Regina': '(GMT-6:00) Saskatchewan',
  'America/Mexico_City': '(GMT-5:00) Guadalajara, Mexico City, Monterrey',
  'America/Belize': '(GMT-6:00) Central America',
  'America/Detroit': '(GMT-4:00) Eastern Time',
  'America/Bogota': '(GMT-5:00) Bogota, Lima, Quito',
  'America/Caracas': '(GMT-4:00) Caracas, La Paz',
  'America/Santiago': '(GMT-3:00) Santiago',
  'America/St_Johns': '(GMT-2:30) Newfoundland and Labrador',
  'America/Sao_Paulo': '(GMT-3:00) Brasilia',
  'America/Tijuana': '(GMT-7:00) Tijuana',
  'America/Montevideo': '(GMT-3:00) Montevideo',
  'America/Argentina/Buenos_Aires': '(GMT-3:00) Buenos Aires, Georgetown',
  'America/Godthab': '(GMT-2:00) Greenland',
  'America/Los_Angeles': '(GMT-7:00) Pacific Time',
  'Atlantic/Azores': '(GMT+0:00) Azores',
  'Atlantic/Cape_Verde': '(GMT-1:00) Cape Verde Islands',
  GMT: '(GMT+0:00) UTC',
  'Europe/London': '(GMT+1:00) Edinburgh, London',
  'Europe/Dublin': '(GMT+1:00) Dublin',
  'Europe/Lisbon': '(GMT+1:00) Lisbon',
  'Africa/Casablanca': '(GMT+0:00) Casablanca, Monrovia',
  'Atlantic/Canary': '(GMT+1:00) Canary Islands',
  'Europe/Belgrade':
    '(GMT+2:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': '(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': '(GMT+2:00) Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam':
    '(GMT+2:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': '(GMT+1:00) West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa"
};
