import React from 'react';
const ClientSetupError = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      style={{ paddingLeft: '5px' }}
    >
      <path
        d="M11.19 13.41H12.99V5.82H11.19V13.41ZM12 18C12.28 18 12.515 17.905 12.705 17.715C12.895 17.525 12.99 17.29 12.99 17.01C12.99 16.73 12.895 16.495 12.705 16.305C12.515 16.115 12.28 16.02 12 16.02C11.72 16.02 11.485 16.115 11.295 16.305C11.105 16.495 11.01 16.73 11.01 17.01C11.01 17.29 11.105 17.525 11.295 17.715C11.485 17.905 11.72 18 12 18ZM12 24C10.3 24 8.72 23.695 7.26 23.085C5.8 22.475 4.53 21.63 3.45 20.55C2.37 19.47 1.525 18.2 0.915 16.74C0.305 15.28 0 13.7 0 12C0 10.32 0.305 8.75 0.915 7.29C1.525 5.83 2.37 4.56 3.45 3.48C4.53 2.4 5.8 1.55 7.26 0.93C8.72 0.31 10.3 0 12 0C13.68 0 15.25 0.31 16.71 0.93C18.17 1.55 19.44 2.4 20.52 3.48C21.6 4.56 22.45 5.83 23.07 7.29C23.69 8.75 24 10.32 24 12C24 13.7 23.69 15.28 23.07 16.74C22.45 18.2 21.6 19.47 20.52 20.55C19.44 21.63 18.17 22.475 16.71 23.085C15.25 23.695 13.68 24 12 24ZM12 22.2C14.8 22.2 17.2 21.2 19.2 19.2C21.2 17.2 22.2 14.8 22.2 12C22.2 9.2 21.2 6.8 19.2 4.8C17.2 2.8 14.8 1.8 12 1.8C9.2 1.8 6.8 2.8 4.8 4.8C2.8 6.8 1.8 9.2 1.8 12C1.8 14.8 2.8 17.2 4.8 19.2C6.8 21.2 9.2 22.2 12 22.2Z"
        fill="#FF6F62"
      />
    </svg>
  );
};

export default ClientSetupError;
