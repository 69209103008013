import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ZTextField from '../../../Component/Common/ZSearch';
import Loader from '../../../Component/Loader';
import { parseTokensLowerCase } from '../../../utils/index';

const ChatOptions = props => {
  const { open, setOpen, setMessageInput, templateData, loading } = props;

  const [updatedTemplateData, setUpdatedTemplateData] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setUpdatedTemplateData(templateData || []);
  };

  useEffect(() => {
    if (templateData && templateData.length > 0) {
      setUpdatedTemplateData([...templateData]);
    } else {
      setUpdatedTemplateData([]);
    }
  }, [templateData]);

  const handleSearchTemplates = e => {
    const searchValue = e.target.value;
    const filteredTemplates = [];
    const templates = templateData;

    if (searchValue && searchValue.length > 0) {
      const searchWords = parseTokensLowerCase(e.target.value, ' ');

      templates.forEach(template => {
        let score = 0;
        const lowerCaseTemplateName = template.name.toLowerCase();

        for (const word of searchWords) {
          if (lowerCaseTemplateName.includes(word)) {
            score += 1;
          }
        }

        if (score == searchWords.length) {
          let templateObj = {
            _id: template._id,
            name: template.name,
            body: template.body
          };
          filteredTemplates.push(templateObj);
        }
      });
    }

    const searchResults =
      searchValue && searchValue.length > 0 ? filteredTemplates : templateData;
    setUpdatedTemplateData([...searchResults]);
  };

  return (
    <div className="chat-options">
      <Dialog
        fullWidth
        style={{ padding: '10px' }}
        id={'simple-dialog-box'}
        open={open}
        onClose={handleClose}
      >
        <ZTextField handleChange={handleSearchTemplates} />
        {loading && <Loader />}
        {!loading && (
          <div style={{ overflowY: 'scroll' }}>
            {updatedTemplateData.map(({ _id, name, body }) => {
              return (
                <div
                  className="template-name inter-12px-500"
                  data-body={body}
                  key={_id}
                  onClick={e => {
                    setMessageInput(body);
                    handleClose();
                  }}
                >
                  {name}
                </div>
              );
            })}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ChatOptions;
