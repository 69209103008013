import styled, { css } from 'styled-components';

const BadgeWrapper = styled.div`
  display: flex;
  width: 349px;
  align-items: flex-end;
  align-content: flex-end;
  gap: 7px 9px;
  flex-wrap: wrap;
`;
const TopBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 10px;
  padding: 0 10px;
`;
const FiltersConatiner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding: 0 10px;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;

  max-height: 0;
  opacity: 0;
  visibility: hidden;

  ${({ showFilters }) =>
    showFilters &&
    css`
      max-height: 500px;
      opacity: 1;
      visibility: visible;
    `}
`;
const FlexContainer = styled.div`
  display: flex;
`;
const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
`;
const SingleFilterWrapper = styled.div`
  width: ${props => props.width || 'auto'};
`;
const DateWrapper = styled.div`
  border: ${({ theme }) => `0.5px solid ${theme?.border?.primary}`};
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  width: 200px;
  .react-datepicker-wrapper {
    width: 100%;
    display: flex;
  }
  .react-datepicker-popper {
    z-index: 99;
  }
`;
const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => theme?.text?.heading};
`;

const SelectedUnit = styled.div`
  display: flex;
  width: 200px;
  padding: 5px 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: rgba(196, 196, 196, 0.1);
`;

const TextSpan = styled.span`
  color: ${({ theme }) => theme?.text?.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const LoaderContainer = styled.div`
  height: 220px;
  width: 220px;
  margin: 150px 290px;
`;
export {
  BadgeWrapper,
  FiltersConatiner,
  SingleFilterWrapper,
  DateWrapper,
  NoResultContainer,
  FlexSpaceBetween,
  TopBarContainer,
  SelectedUnit,
  TextSpan,
  FlexContainer,
  LoaderContainer
};
