import moment from 'moment';
import React from 'react';
import LeadCardStatus from '../LeadCardStatus';
import Icon from '../../globalcomponents/Icon';
import {
  LeadCardName,
  LeadChatCard,
  LeadChatCardWrapper,
  LeadChatStage,
  LeadDate,
  LeadPropertyName
} from './styles.inboxv2';
import { useTheme } from 'styled-components';

const LeadCard = props => {
  const {
    name,
    time,
    apartment,
    leadId,
    stage,
    onClick,
    selectedLeadId,
    tenantName,
    isArchived,
    leadSources,
    hasKelseyAnswered,
    wasAFMSent = true
  } = props;
  const theme = useTheme();
  const truncate = str => {
    if (str && str.length > 14) {
      return str.substring(0, 14) + '...';
    }
    return str;
  };

  return (
    <LeadChatCardWrapper>
      <LeadChatCard
        data-testid="lead-card"
        selected={selectedLeadId === leadId}
        onClick={e => {
          e.preventDefault();
          onClick(leadId);
        }}
        className={`${
          selectedLeadId === leadId ? ' bg-Selected-lgBlue' : ''
        }  ${isArchived ? 'fade-out' : ''}`}
      >
        <LeadChatStage selected={selectedLeadId === leadId} id="lead-stage">
          {stage}
        </LeadChatStage>
        {time ? (
          <LeadDate selected={selectedLeadId === leadId}>
            {moment(time).format('MMM-DD hh:mm a')}{' '}
          </LeadDate>
        ) : // <div className="lead-date">{moment(time).format('MMM-DD hh:mm a')}</div>
        null}
        <LeadCardName selected={selectedLeadId === leadId}>
          {truncate(name)}
        </LeadCardName>
        <LeadPropertyName selected={selectedLeadId === leadId}>
          {truncate(apartment?.name || '')}
          {` (${truncate(tenantName)})`}
        </LeadPropertyName>
        <div className="lead-agentName pd-left-5px pd-right-5px">
          <LeadCardStatus leadSources={leadSources} />
          <div>
            {!wasAFMSent ? (
              <Icon
                src="afm-signal.svg"
                alt="AFM not sent"
                tooltipText="AFM not sent"
              />
            ) : hasKelseyAnswered ? (
              <Icon
                src={`${theme.state === 'light' ? 'qa-light' : 'QA-dark'}.svg`}
                alt="Kelsey messaged"
                tooltipText="Kelsey messaged"
              />
            ) : (
              <Icon
                src={`message-${theme.state}.svg`}
                alt="Kelsey was silent"
                tooltipText="Kelsey was silent"
              />
            )}
          </div>
        </div>
      </LeadChatCard>
    </LeadChatCardWrapper>
  );
};

export default LeadCard;
