import AmenitiesColumn from './AmenitiesColumn';
import moment from 'moment';

const data = {
  headers: [
    {
      displayName: 'Unit Name',
      key: 'unitName',
      tooltip: false
    },
    {
      displayName: 'Bed',
      key: 'bedRooms',
      tooltip: false
    },
    {
      displayName: 'Bath',
      key: 'bathRooms',
      tooltip: false
    },
    {
      displayName: 'Price',
      key: 'rent.minRent',
      tooltip: false,
      render: rowData => (
        <>{rowData?.rent?.minRent ? `$${rowData?.rent?.minRent}` : 'N/A'}</>
      )
    },
    {
      displayName: 'Availability',
      key: 'avlFrom',
      render: rowData => (
        <>{rowData?.avlFrom && moment(rowData?.avlFrom).format("DD' MMM YY")}</>
      )
    },
    {
      displayName: 'Area',
      key: 'sqft',
      tooltip: false,
      render: rowData => <>{rowData.sqft ? rowData.sqft : '0'} sqft</>
    },
    {
      displayName: 'Type',
      key: 'type',
      tooltip: false
    },
    {
      displayName: 'Lease Term',
      key: 'columnName6',
      tooltip: false,
      render: rowData => (
        <>
          {rowData?.leaseData?.leaseTerm ? rowData?.leaseData?.leaseTerm : 12}{' '}
          months
        </>
      )
    },
    {
      displayName: 'Amenities',
      key: 'amenities',
      tooltip: false,
      render: rowData => {
        return <AmenitiesColumn rowData={rowData} />;
      }
    }
  ]
  // pinnedColumns: ['unitName', 'bedRooms', 'bathRooms', 'minRent']
};

export default data;
