import React, { useState } from 'react';

import { ReactComponent as Announce } from '../../../Media/workflow/Announce.svg';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import StepSelect from './StepSelect';
import AddIcon from '@material-ui/icons/Add';

const AddStep = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const container = {
    display: 'flex',
    width: '100%',
    border: '1px solid #cdccec',
    backgroundColor: '#ffffff'
  };
  const main = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    flexDirection: 'column',
    marginTop: '50px',
    marginBottom: '50px'
  };

  const btnstyle = {
    borderRadius: 8,
    backgroundColor: '#ff6f62',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 600,
    marginTop: '10px',
    width: 120,
    height: 33
  };
  return (
    <div style={container}>
      <div style={main}>
        <div>
          <Announce />
        </div>
        <div
          style={{
            color: '#20065f',
            fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 600,
            marginTop: '8px'
          }}
        >
          Add your first step!
        </div>
        <div
          style={{
            color: '#b0b8cb',
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 400,

            marginTop: '10px'
          }}
        >
          Click the button below to add an email call or text
        </div>
        <div>
          <Button style={btnstyle} onClick={e => openOptions(e)}>
            <span style={{ marginTop: '5px' }}>
              <AddIcon style={{ color: '#ffffff' }} />
            </span>
            ADD STEP
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            <StepSelect
              paramid={props.paramid}
              handleClose={props.handleClose}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default AddStep;
