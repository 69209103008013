import { gql } from '@apollo/client';

export const sensitiveInfoUpdate = gql`
  mutation leadUpdateById($leadId: MongoID!, $record: leadsInput!) {
    leadUpdateById(_id: $leadId, record: $record) {
      record {
        name
        phone
        email
      }
    }
  }
`;
