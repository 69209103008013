import React, { useEffect, useState } from 'react';
import MessageComponent from './MessageComponent';
import moment from 'moment';
import EmailIconMsg from '../../../Media/InboxV1/EmailIconMsg';
import MessageIcon from '../../../Media/InboxV1/MessageIcon';
import SyncIcon from '../../../Media/InboxV1/SyncIcon';
import { EmailTimelineEvent } from '../../../Component/EmailTimelineEvent';
import { CreateLeadTimelineEvent } from '../../../Component/CreateLeadTimelineEvent';
import SpecialEvents from './SpecialEvents';
import TourBookingSuccessful from './TourBookingSuccessful';
import TourCancelled from './TourCancelled';
import LeadInfo from './LeadInfo';
import { SenderInitial } from '../styles.inboxv2';
import { SenderName } from './styles.chatcomponents';
import Icon from '../../../globalcomponents/Icon';
import { useTheme } from 'styled-components';
import ScheduledMessage from './ScheduledMessage';
import NewMessageIndicator from './NewMessageIndicator';
import _ from 'lodash';
import { DATA_TRACE_DISPLAY_NAMES } from './constants';

const ChatMessage = props => {
  const {
    body,
    meta,
    subType,
    type,
    md,
    eventType,
    from,
    to,
    timeStamp,
    media,
    customerName,
    subMessageType,
    kelseyIntents,
    dataTrace,
    scheduledData,
    isUnread
  } = props;

  const theme = useTheme();
  const [newMessageIndicatorVisibility, setNewMessageIndicatorVisibility] =
    useState(false);

  const getMessageIcon = () => {
    if (
      eventType === 'sync-lead' ||
      eventType === 'sync-tour' ||
      eventType === 'create-lead' ||
      eventType === 'sync-tour'
    ) {
      return <CreateLeadTimelineEvent meta={meta} />;
    }

    if (type === 'event') {
      return (
        <div className="message-owner d-flex-just-align-center inter-12px-500">
          <SenderName>{meta?.agentName}</SenderName>
        </div>
      );
    }
    if (subType !== 'Bot' || meta?.agentName === 'Kelsey') {
      if (subType) {
        return (
          <div className="message-owner d-flex-just-align-center inter-12px-500">
            {md === 'email' ? (
              <EmailIconMsg style={{ paddingRight: '4px' }} />
            ) : (
              <MessageIcon style={{ paddingRight: '4px' }} />
            )}
            <SenderName>{meta?.agentName}</SenderName>
          </div>
        );
      } else {
        return (
          <div className="message-owner-customer d-flex-just-align-center inter-12px-500">
            {md === 'email' ? (
              <EmailIconMsg style={{ paddingRight: '4px' }} />
            ) : (
              <MessageIcon style={{ paddingRight: '4px' }} />
            )}
            <SenderName>{customerName}</SenderName>
          </div>
        );
      }
    }
  };

  const specialEvents = [
    'notes-event',
    'waitlist-lead-event',
    'outgoing-call-notes-event',
    'tour-notes-event',
    'call-notes-event',
    'internal-notes'
  ];
  const tourBookingSuccessful = 'create-tour';
  const tourCancelled = ['cancel-tour', 'delete-tour'];
  const leadEvents = [
    'update-lead',
    'update-lead-unit',
    'lead-event',
    'create-lead',
    'kelsey-update-event',
    'agent-answered-query'
  ];

  const getMessageMetaData = () => {
    return (
      <div className="date-time-sync">
        <div style={{ padding: '5px 0' }}>
          {moment(timeStamp).format('MMM DD, YYYY hh:mm a')}{' '}
          {subMessageType && `| ${subMessageType}`}
        </div>
        {meta?.sync &&
        meta?.sync?.length &&
        meta?.sync[0].syncStatus === 'Success' ? (
          <div className="d-flex-align-center">
            <SyncIcon /> Sync'd w {meta?.sync[0].syncSource}
          </div>
        ) : null}
      </div>
    );
  };

  const renderDataTraceInfoIcon = () => {
    // get Tooltip JSX with dataTrace and kelseyIntents
    const DataTraceAndKelseyToolsList = () => {
      return (
        <>
          {!_.isEmpty(dataTrace) &&
            Object.keys(dataTrace).map(key => {
              return (
                <p style={{ margin: '5px 0' }}>
                  {DATA_TRACE_DISPLAY_NAMES[key]}: {dataTrace[key]}
                </p>
              );
            })}
          {kelseyIntents?.length > 0 && (
            <>
              <h3 style={{ margin: '5px 0' }}>Tools:</h3>
              {kelseyIntents.map(intent => (
                <p style={{ margin: '5px 0' }}>{intent}</p>
              ))}
            </>
          )}
        </>
      );
    };

    // render Info icon with tooltip
    if (
      type === 'outgoing' &&
      (!_.isEmpty(dataTrace) || kelseyIntents?.length > 0)
    ) {
      return (
        <Icon
          imgStyle={{ marginRight: 6, marginBottom: 20 }}
          size="20px"
          tooltipText={<DataTraceAndKelseyToolsList />}
          src={`Info_${theme?.state === 'dark' ? 'dark' : 'white'}_mode.svg`}
        />
      );
    } else return null;
  };

  useEffect(() => {
    if (isUnread) {
      setNewMessageIndicatorVisibility(true);
      const timer = setTimeout(() => {
        setNewMessageIndicatorVisibility(false);
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, []);

  const scheduledEvents = ['scheduled-message', 'deleted-scheduled-message'];
  const scheduledMessageprops = {
    scheduledAt: scheduledData?.scheduledAt,
    scheduledBy: scheduledData?.scheduledBy?.name,
    deletedBy: scheduledData?.deletedBy?.name,
    deletedAt: scheduledData?.deletedAt,
    isDeleted: scheduledData?.deletedAt ? true : false
  };

  const renderMessage = () => {
    const condition =
      !specialEvents.includes(eventType) &&
      eventType !== tourBookingSuccessful &&
      !tourCancelled.includes(eventType) &&
      !leadEvents.includes(eventType);
    if (
      (condition && type !== 'event') ||
      (specialEvents.includes(eventType) && type === 'outgoing')
    ) {
      return (
        <React.Fragment>
          {getMessageIcon()}

          {md === 'email' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <EmailTimelineEvent from={from} to={to} body={body} type={type} />
              {getMessageMetaData()}
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MessageComponent type={type} body={body} media={media} />
              {getMessageMetaData()}
            </div>
          )}
          <SenderInitial>
            {type === 'incoming'
              ? customerName
                ? customerName[0]
                : 'C'
              : meta?.agentName
              ? meta?.agentName[0]
              : 'K'}
          </SenderInitial>
        </React.Fragment>
      );
    } else return null;
  };
  return (
    <div
      style={{ display: 'flex' }}
      className={`chat-message-container ${
        type === 'incoming' || type === 'incomingReaction'
          ? 'customer-msg'
          : 'agent-msg '
      } ${
        eventType
          ? 'chat-message-container-events'
          : 'chat-message-container-no-events'
      }`}
    >
      <NewMessageIndicator show={newMessageIndicatorVisibility} />
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        {renderDataTraceInfoIcon()}
      </div>

      {specialEvents.includes(eventType) > 0 && (
        <>
          {getMessageIcon()}
          <SpecialEvents props={props} />
        </>
      )}
      {scheduledEvents.includes(eventType) > 0 && type === 'event' && (
        <>
          {getMessageIcon()}
          <ScheduledMessage props={{ ...scheduledMessageprops, ...props }} />
        </>
      )}
      {eventType === tourBookingSuccessful && (
        <>
          {getMessageIcon()}
          <TourBookingSuccessful props={props} />
        </>
      )}
      {tourCancelled.includes(eventType) > 0 && (
        <>
          {getMessageIcon()}
          <TourCancelled props={props} />
        </>
      )}
      {leadEvents.includes(eventType) > 0 && (
        <>
          {getMessageIcon()}
          <LeadInfo props={props} />
        </>
      )}

      {renderMessage()}
    </div>
  );
};

export default ChatMessage;
