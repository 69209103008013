import styled from 'styled-components';

const btnConfig = {
  padding: {
    lg: '0.5rem 1rem',
    md: '0.375rem 0.75rem',
    sm: '0.375rem 0.75rem',
    xs: '0.375rem 0.75rem',
    xxs: '0.375rem 0.75rem'
  },
  fontSize: {
    lg: '1.125rem',
    md: '1rem',
    sm: '0.875rem',
    xs: '0.75rem',
    xxs: '0.625rem'
  }
};

const Button = styled.button`
  font-family: Inter, sans-serif;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  border: none;
  background-color: ${({ btnType = 'primary', noStyle, theme }) =>
    btnType === 'primary' && !noStyle
      ? theme?.background?.selection || 'blue'
      : 'transparent'};
  color: ${({ theme, btnType = 'primary' }) =>
    btnType === 'primary' ? theme?.text?.secondary : theme?.text?.heading};
  padding: ${({ size = 'md' }) => btnConfig['padding'][size]};
  font-size: ${({ size = 'md' }) => btnConfig['fontSize'][size]};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  color: ${({ noStyle, theme }) =>
    noStyle ? theme?.text?.primary : theme?.text?.secondary};
  transition: all ease-in 200ms;
  :hover {
    background-color: ${({ theme, noStyle, btnType = 'primary' }) =>
      !noStyle && btnType === 'primary'
        ? '3F55F3'
        : !noStyle && btnType === 'secondary'
        ? `#3F55F31A`
        : theme?.background?.chatContainer};
  }
`;

export default Button;
