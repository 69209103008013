import CloseIcon from '@mui/icons-material/Close';
import { FormControl, MenuItem, Select } from '@mui/material';
import { isNil, toLower } from 'lodash';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import Button from '../../../Component/Common/Button';
import {
  CustomCommunityWebsite,
  CustomInput
} from '../../../Component/Common/Input';
import Modal from '../../../Component/Modal';
import arrowForwardIcon from '../../../Media/arrowForward.svg';
import arrowLeftIcon from '../../../Media/arrowLeft.svg';
import CheckCircleIcon from '../../../Media/SelfServe/CheckCircleIcon';
import ErrorIcon from '../../../Media/SelfServe/ErrorIcon';
import SuccesGif from '../../../Media/SuccessGif';
import {
  getYardiCommunityAgents,
  ingestCommunityGet,
  updateLeasingAgents,
  updateWorkingHours as updateWorkingHoursSelfServe,
  verifyCommunity,
  verifyRentCafe
} from '../../../Redux/Actions/SelfServe';
import {
  addCommunityWebsite,
  addTimeZone,
  verifyCommunityError
} from '../../../Redux/Reducer/SelfServe';
import { workingHours as hardCodedWorkingHours } from '../utils';
import AvailableSlots from './availableSlots';
import styles from './styles.module.scss';
import LinkIcon from '../../../Media/linkOut.svg';
import { Path } from '../../../Redux/util/path';
import {
  setGlobalError,
  setSuccessInfo
} from '../../../Redux/Reducer/GlobalError';
import { get, postKelsey, put } from '../../../Redux/networkClient';
import { triggerKBIngestion } from '../../../gql/mutations/triggerKBingestion';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { getApartmentsApi } from '../../../Redux/util/Apis/inbox';
import { BuildingByIdAction } from '../../../Redux/Actions/Apartment';

const CommunityInformation = ({
  appartmentId,
  header = 'Community Information',
  info,
  infoPopUPHandler,
  tenantId,
  ingestCommunitiesOnLoad,
  appartmentData,
  crmSettings
}) => {
  const dispatch = useDispatch();
  const selfServe = useSelector(state => state.SelfServe);
  const integrationSettings = selfServe?.userData?.data?.integrationSettings;
  const pmsValue = integrationSettings && integrationSettings?.pms;
  const [notification, setNotification] = useState('');
  const [onBoard, setOnBoard] = useState(false);
  const enableKnockCrm = selfServe?.userData?.data?.enableKnockCRM;
  const { individualCommunity } = selfServe;
  const [step, changeStep] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [workingHours, setWorkingHours] = useState([]);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const { meta = {} } = find(appartmentData, { _id: appartmentId }) ?? {};
    if (pmsValue === 'yardi' && !enableKnockCrm && meta?.propertyId) {
      const { yardiUrl, userName, password, database } =
        integrationSettings ?? {};
      const payload = {
        url: yardiUrl,
        username: userName,
        password,
        database,
        propertyId: meta?.propertyId
      };
      if (meta?.propertyId) {
        dispatch(getYardiCommunityAgents({ payload }));
      }
    }
    if (appartmentId) {
      get(
        `https://apis-v2.residentboost.com${Path.GET_WORKING_HOURS}?tenantId=${tenantId}&apartmentId=${appartmentId}`,
        null,
        null,
        false,
        false
      ).then(res => {
        if (res?.data?.data?.officeHours?.value?.length > 0)
          setWorkingHours(res.data.data.officeHours.value);
        else setWorkingHours(hardCodedWorkingHours);
      });
    }
  }, [appartmentId]);
  const yardiCommunityAgents = useSelector(
    state => state?.SelfServe?.yardiCommunityAgents
  );
  useEffect(() => {
    setOptions(yardiCommunityAgents);
  }, [yardiCommunityAgents, options]);
  const closeNotification = () => {
    infoPopUPHandler(false);
    setNotification(false);
  };
  const showNotification = (onBoard, error = false) => {
    if (error) {
      setOnBoard(false);
      return;
    }
    if (onBoard) {
      setNotification('Community Saved');
    }
    if (selfServe?.verifyRentCafeData?.success) {
      setOnBoard(true);
    }
  };
  useEffect(() => {
    const { leasingAgents = {} } =
      find(appartmentData, { _id: appartmentId }) ?? {};
    if (leasingAgents?.Kelsey) {
      setInputValue(leasingAgents?.Kelsey?.username);
    }
  }, [appartmentId]);
  function handleChange(event) {
    setInputValue(event.target.value);
  }
  const verifyId = step => {
    if (isNil(crmSettings) && toLower(pmsValue) === 'yardi') {
      const payload = {
        tenantId: find(appartmentData, { _id: appartmentId }).tenantId,
        PropertyCode: individualCommunity[step]?.[2]?.value,
        apartmentId: appartmentId,
        CompanyCode: individualCommunity[step]?.[0]?.value,
        rentcafeApiV2Token: individualCommunity[step]?.[1]?.value
      };
      dispatch(verifyRentCafe({ payload, showNotification }));
    } else {
      const payload = {
        communityId: individualCommunity[step]?.[0]?.value,
        aptId: appartmentId,
        tenantId: find(appartmentData, { _id: appartmentId }).tenantId
      };
      dispatch(verifyCommunity({ payload, showNotification }));
    }
  };

  const [triggerKBIngestionG] = useMutation(triggerKBIngestion, {
    onCompleted() {
      dispatch(setSuccessInfo('KB Ingestion triggered!'));
      if (tenantId) {
        dispatch(ingestCommunityGet(tenantId));
      }
    },
    onError(err) {
      dispatch(setGlobalError(err));
    }
  });

  const startKbIngestion = async () => {
    triggerKBIngestionG({
      variables: {
        aptId: appartmentId,
        websiteUrl: selfServe?.individualCommunity?.[0]?.[1].value,
        isReIngestion: false
      }
    });
  };

  const updateWorkingHoursNetworkCall = workingHours => {
    put(
      `https://apis-v2.residentboost.com${Path.UPDATE_WORKING_HOURS_SELF_SERVE_V2}`,
      {
        officeHours: workingHours,
        tenantId: tenantId,
        apartmentId: appartmentId
      },
      null,
      false,
      false
    );
  };

  const updateWorkingHours = data => {
    dispatch(updateWorkingHoursSelfServe({ payload: data }));
  };

  const onInputChange = e => {
    const { value, name } = e.target;
    dispatch(verifyCommunityError({ name, value, step }));
  };
  const changeCommunityWebsite = e => {
    const { value, name } = e.target;
    dispatch(addCommunityWebsite(value));
  };

  const saveCommunity = (onboard = false) => {
    const timeZone = selfServe?.individualCommunity?.[0]?.[2].value;
    const communityWebsite = selfServe?.individualCommunity?.[0]?.[1].value;
    const payload = {
      tenantId: !tenantId
        ? find(appartmentData, { _id: appartmentId }).tenantId
        : tenantId,
      timezone: timeZone?.value,
      apartmentId: appartmentId,
      workingHours: workingHours?.length ? workingHours : hardCodedWorkingHours,
      communityWebsite: communityWebsite
    };
    if (onBoard) {
      dispatch(
        updateWorkingHoursSelfServe({
          payload,
          onboard,
          showNotification,
          ingestCommunitiesOnLoad
        })
      );
      updateWorkingHoursNetworkCall(workingHours);
      changeStep(0);
    }
    if (pmsValue === 'yardi' && !enableKnockCrm) {
      const payload = {
        tenantId: find(appartmentData, { _id: appartmentId }).tenantId,
        apartmentId: appartmentId,
        leasingAgents: {
          Kelsey: {
            username: inputValue
          }
        }
      };
      dispatch(updateLeasingAgents({ payload }));
    }
  };

  const ChangeTimeZone = tz => {
    if (
      selfServe?.individualCommunity?.[1]?.[1].value ||
      pmsValue?.toLowerCase() === 'entrata'
    ) {
      setOnBoard(true);
    }
    dispatch(addTimeZone({ value: tz, disable: false }));
  };
  const closePop = () => {
    changeStep(0);
    infoPopUPHandler(false);
  };

  useEffect(() => {
    if (
      (pmsValue?.toLowerCase() === 'entrata' || !enableKnockCrm) &&
      individualCommunity?.[0]?.[2].value?.value
    ) {
      setOnBoard(true);
    } else if (
      pmsValue?.toLowerCase() === 'realpage' &&
      (individualCommunity?.[1]?.filter(({ value }) => value)).length === 3
    ) {
      setOnBoard(true);
    }
  }, [selfServe?.individualCommunity]);

  const renderInput = individualCommunity?.[step]?.map(
    ({ label, endAdornment, type, name, value, error, verify, disable }) => {
      const { knowledgeBaseStatus = {} } =
        find(appartmentData, { _id: appartmentId }) ?? {};

      function getTextBasedOnFields(data) {
        if (!data) {
          return 'Not started';
        }
        let status = 'Not started';
        if (data.status) {
          if (data.status === 'IN_PROGRESS') status = 'In Progress';
          else if (data.status === 'COMPLETED') status = 'Completed';
          else if (data.status === 'FAILED') status = 'Failed';
          else if (data.status === 'NOT_STARTED') status = 'Not started';
          else if (data.status === 'CHEATSHEET_GENERATION')
            status = 'Cheatsheet Generation';
          else if (data.status === 'KB_INGESTION') status = 'KB Ingestion';
        }

        if (data.updatedAt) {
          return `${moment(data.updatedAt).format(
            'Do MMMM YYYY hh:mm A'
          )}, ${status}`;
        } else {
          return status;
        }
      }
      const textToshow = getTextBasedOnFields(knowledgeBaseStatus);
      return (
        <React.Fragment key={label}>
          {type && type === 'select' ? (
            <div className={styles.timeZoneCont}>
              <label>
                {label}
                {!value?.value && <span className={styles.errorLabel}>*</span>}
              </label>
              {label === 'Time zone' ? (
                <TimezoneSelect
                  isDisabled={false}
                  value={value}
                  onChange={tz => ChangeTimeZone(tz)}
                />
              ) : (
                pmsValue === 'yardi' &&
                !enableKnockCrm && (
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inputValue}
                      onChange={handleChange}
                      sx={{
                        '& .MuiSelect-select': {
                          fontSize: '16px',
                          paddingLeft: '24px !important'
                        }
                      }}
                    >
                      {options?.length > 0 &&
                        options.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )
              )}
            </div>
          ) : type === 'date' ? (
            <AvailableSlots
              tenantId={tenantId}
              workingHours={workingHours}
              appartmentId={appartmentId}
              timeZone={individualCommunity?.[0]?.[2]?.value}
              updateWorkingHours={updateWorkingHours}
              setWorkingHours={setWorkingHours}
            />
          ) : label === 'Community Website' ? (
            <CustomCommunityWebsite
              disable={disable}
              clickHandler={startKbIngestion}
              fetchedDate={textToshow}
              fetchStatus={knowledgeBaseStatus?.status}
              endAdornment={
                error ? (
                  <ErrorIcon />
                ) : verify && !error ? (
                  <CheckCircleIcon />
                ) : (
                  endAdornment
                )
              }
              step={step}
              label={label}
              inputChange={changeCommunityWebsite}
              name={name}
              value={value}
              error={error}
              verify={verify}
            />
          ) : (
            <CustomInput
              disable={disable}
              clickHandler={verifyId}
              endAdornment={
                error ? (
                  <ErrorIcon />
                ) : verify && !error ? (
                  <CheckCircleIcon />
                ) : (
                  endAdornment
                )
              }
              step={step}
              label={label}
              inputChange={onInputChange}
              name={name}
              value={value}
              error={error}
              verify={verify}
            />
          )}
        </React.Fragment>
      );
    }
  );
  return (
    <Modal disableEnforceFocus open={info} onClose={closePop}>
      {notification ? (
        <div className={styles.success}>
          <div className="close-icon">
            <CloseIcon onClick={closeNotification} />
          </div>
          <SuccesGif />
          <p>{notification}</p>
        </div>
      ) : (
        <div className={styles.informationCont}>
          <div className={styles.scroller}>
            <div className={styles.popupTitlebar}>
              <div className="close-icon">
                <CloseIcon onClick={closePop} />
              </div>

              <div style={{ display: 'inline-flex' }}>
                <h2 className={styles.popUpHeader}>{header}</h2>
                {pmsValue === 'yardi' && !enableKnockCrm && (
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      window.open(
                        process.env.REACT_APP_RENTCAFE_RETOOL_DIAGNOSE_URL ??
                          '',
                        'WindowName',
                        'noopener'
                      );
                    }}
                  >
                    <div
                      style={{
                        marginLeft: '20px',
                        marginRight: '6px',
                        fontWeight: '500',
                        color: '#5267FF',
                        fontSize: '14px'
                      }}
                    >
                      Diagnose
                    </div>
                    <div
                      style={{
                        padding: 'auto',
                        display: 'inline-flex',
                        alignItems: 'center'
                      }}
                    >
                      <img src={LinkIcon} alt="Diagnose" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {renderInput}
          </div>
          {!(isNil(crmSettings) && toLower(pmsValue) === 'yardi') ? (
            <div
              className={
                !enableKnockCrm
                  ? `${styles.flexEnd} ${styles.footer}`
                  : styles.footer
              }
            >
              {enableKnockCrm && (
                <div className={styles.navigationFooter}>
                  <span
                    className={step === 0 ? styles.activeDot : styles.normalDot}
                    onClick={() => changeStep(0)}
                  ></span>
                  <span
                    className={step === 1 ? styles.activeDot : styles.normalDot}
                    onClick={() => changeStep(1)}
                  ></span>
                </div>
              )}
              <div className={styles.footerRight}>
                {!enableKnockCrm || step === 1 ? (
                  <>
                    {step === 1 && (
                      <img
                        onClick={() => changeStep(0)}
                        src={arrowLeftIcon}
                        alt=""
                      />
                    )}
                    <Button
                      overRideClass={
                        onBoard && individualCommunity[0]?.[1]?.value != ''
                          ? 'infoSaveBtn'
                          : 'disableButton'
                      }
                      disabled={!individualCommunity[0]?.[1]?.value != ''}
                      tooltipTitle={
                        !individualCommunity[0]?.[1]?.value != ''
                          ? 'One or more mandatory fields are missing'
                          : ''
                      }
                      onClick={() => saveCommunity(true)}
                      label={'Save'}
                    />
                  </>
                ) : (
                  <img
                    onClick={() => changeStep(step === 0 ? 1 : 0)}
                    src={arrowForwardIcon}
                    alt=""
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              className={
                !(isNil(crmSettings) && toLower(pmsValue) === 'yardi')
                  ? `${styles.flexEnd} ${styles.footer}`
                  : styles.footer
              }
            >
              {isNil(crmSettings) && toLower(pmsValue) === 'yardi' && (
                <div className={styles.navigationFooter}>
                  <span
                    className={step === 0 ? styles.activeDot : styles.normalDot}
                    onClick={() => changeStep(0)}
                  ></span>
                  <span
                    className={step === 2 ? styles.activeDot : styles.normalDot}
                    onClick={() => changeStep(2)}
                  ></span>
                </div>
              )}
              <div className={styles.footerRight}>
                {!(isNil(crmSettings) && toLower(pmsValue) === 'yardi') ||
                step === 2 ? (
                  <>
                    {step === 2 && (
                      <img
                        onClick={() => changeStep(0)}
                        src={arrowLeftIcon}
                        alt=""
                      />
                    )}
                    <Button
                      overRideClass={
                        individualCommunity[step]?.[2]?.value
                          ? 'disableButton'
                          : 'infoSaveBtn'
                      }
                      onClick={() => saveCommunity(true)}
                      label={'Save'}
                    />
                  </>
                ) : (
                  <img
                    onClick={() => changeStep(step === 0 ? 2 : 0)}
                    src={arrowForwardIcon}
                    alt=""
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CommunityInformation;
