import { GET_ALL_LEASE_LENGTH } from '../Constant';
import { get } from '../networkClient';

const getLeaseLength = data => ({
  type: GET_ALL_LEASE_LENGTH,
  data: data
});

export const getLeaseLengthAction = () => async dispatch => {
  await get('/lease-leangth/all').then(resp => {
    if (resp.status === 200) {
      dispatch(getLeaseLength(resp.data));
    }
  });
};
