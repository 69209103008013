import { gql } from '@apollo/client';

export const triggerKBIngestion = gql`
  mutation triggerKBIngestion(
    $aptId: ID!
    $websiteUrl: String!
    $isReIngestion: Boolean!
  ) {
    triggerKBIngestion(
      aptId: $aptId
      websiteUrl: $websiteUrl
      isReIngestion: $isReIngestion
    ) {
      data {
        _id
        name
        website
        sitemapUrl
        knowledgeBaseStatus {
          stage
          status
          updatedAt
        }
      }
      error
    }
  }
`;
