import property from '../Container/property/property';
import DashBoard from '../Container/Dashboard/dashboard';
import AddNewProperty from '../Container/property/propertyAdd';
import PropertyEdit from '../Container/property/propertyEdit';
import Redirect from '../Component/redirect';
import AgentProfile from '../Container/AgentProfile/AgentProfile';
import BuildingAdd from '../Container/property/BuildingAdd';
import BuildingEdit from '../Container/property/BuildingEdit';
import NewSequence from '../Container/Workflow/NewSequence/NewSequence';
import SelfServe from '../Container/SelfServe';
import InboxV2 from '../Container/InboxV2';
import Toxicity from '../Container/Toxicity';
import RetoolLeads from '../Container/Leads/RetoolLeads';
import RetoolCommunities from '../Container/Communities/RetoolCommunities';
import CommunitySettings from '../Container/CommunitySettings/CommunitySettings';
import Communities from '../Container/Communities';
import Workflow from '../Container/Workflow/Workflow';
import WorkflowV1 from '../Container/WorkflowV1';
import CallToTextView from '../Container/CallToTextView';

const routesConfig = [
  // main routes ---->>
  {
    path: '/',
    component: InboxV2,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  {
    path: '/dashboard',
    component: DashBoard,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  {
    path: '/inbox',
    component: InboxV2,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  {
    path: '/property',
    component: property,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/property/add',
    component: AddNewProperty,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/property/edit/:id',
    component: PropertyEdit,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/communities',
    component: RetoolCommunities,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/community/add',
    component: BuildingAdd,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/call-to-text/:id',
    component: BuildingEdit,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/community/edit/:id',
    component: CommunitySettings,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/leads',
    component: RetoolLeads,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  // {
  //   path: '/leads/timeline/:id',
  //   component: Timeline,
  //   exact: true,
  //   acl: ['ADMIN', 'AGENT'],
  // },
  // {
  //   path: '/calendar',
  //   component: Calender,
  //   exact: true,
  //   acl: ['ADMIN', 'AGENT'],
  // },
  {
    path: '/new-workflow',
    component: WorkflowV1,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/new-workflow/:id',
    component: WorkflowV1,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/workflow',
    component: Workflow,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/workflow/sequence/:id',
    component: NewSequence,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/oauth2/success',
    component: Redirect,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/admin',
    component: SelfServe,
    exact: true,
    acl: ['ADMIN', 'MANAGER']
  },
  {
    path: '/knowledge-base/:id',
    component: Communities,
    exact: true,
    acl: ['ADMIN']
  },
  {
    path: '/agentProfile',
    component: AgentProfile,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  {
    path: '/inbox/searchLead/:id',
    component: InboxV2,
    exact: true,
    acl: ['ADMIN', 'AGENT']
  },
  {
    path: '/toxicity',
    component: Toxicity,
    exact: true,
    acl: ['ADMIN']
  },
  // {
  //   path: '/control-panel',
  //   component: ControlPanel,
  //   exact: true,
  //   acl: ['ADMIN', 'MANAGER']
  // },
  // {
  //   path: '/admin/:tenantId',
  //   component: ControlPanelClientView,
  //   exact: true,
  //   acl: ['ADMIN', 'MANAGER']
  // },
  {
    path: '/ctt',
    component: CallToTextView,
    exact: true,
    acl: ['ADMIN', 'MANAGER']
  }
];

export default routesConfig;
