import React from 'react';
import { CenterPoint, Path, PathContainer } from './filler.styles';
import KelseyStatusBlinkGif from '../../../Media/KelseyInsight/kelsey-status-blink-1.gif';

const Filler = ({ color, value }) => {
  return (
    <PathContainer color={color}>
      {value === 50 ? (
        <img
          height={20}
          width={20}
          src={KelseyStatusBlinkGif}
          alt="Warning Text"
        />
      ) : (
        <CenterPoint color={value > 50 ? '#67C848' : '#C4C4C4'} />
      )}
    </PathContainer>
  );
};

export default Filler;
