import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteBucket from '../../Media/DeleteBucket';
import CrossIcon from '../../Media/InboxV1/CrossIcon';
import DeleteIcon from '../../Media/InboxV1/DeleteIcon';
import SaveIcon from '../../Media/InboxV1/SaveIcon';
import PhoneIcon from '../../Media/PhoneIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import { ConfigureTenent, getAvailableNumber } from '../../Redux/Actions/Admin';
import {
  channelAccountSettings,
  createInternalSettings,
  deleteChannelAccount,
  deletePhoneNumberApi,
  getChannelAccountDetails,
  getInternalSettings,
  getTenantDetails,
  updateChannelAccountEmailStatus,
  updateRestrictCommunication
} from '../../Redux/Actions/SelfServe';
import { ToastClearAction } from '../../Redux/Actions/Toast';
import { hideSuccessMessage } from '../../Redux/Reducer/SelfServe';
import { SETTINGS_DATA } from './constants';
import EmailDeletionPopup from './EmailDeletionPopup';
import { formatPhoneNumber } from './utils';
import CommunityPhoneModel from './communityPhoneModel';
import ZSwitch from '../../globalcomponents/ZSwitch';

const popupState = {
  phoneDelete: {
    msg: 'Deleting Phone Number!',
    subText: '',
    icon: null,
    active: true
  },
  emailDelete: {
    msg: 'Deleting Email!',
    subText: '',
    icon: null,
    active: false
  },
  phoneAdd: {
    msg: 'Purchasing new phone number!',
    subText: '',
    icon: PhoneIcon,
    active: false,
    btnReverse: true
  },
  phoneDeleted: {
    msg: 'Phone number deleted!',
    subText: '',
    icon: DeleteBucket,
    active: false
  }
};
const label = `Restrict replies to "non-Kelsey” leads`;
const gptLabel = `Allow Kelsey to generate tour notes`;
const Settings = ({ tenantId }) => {
  const [settingsList, setSettingsList] = useState(SETTINGS_DATA);
  const [phoneNum, setPhoneNum] = useState('');
  const [deletePhone, setDeletePhone] = useState('emailDelete');
  const [popUpStates, setpopUpStates] = useState(popupState);

  const [popup, setPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const tenantData = useSelector(state => state.SelfServe);
  const tenant_id = tenantData?.userData?.data?._id;
  const handleChange = (event, item, index) => {
    const updatedSettings = settingsList.map((settingsItem, i) => {
      if (i === index) {
        settingsItem.checked = event.target.checked;
      }
      return settingsItem;
    });
    setSettingsList(updatedSettings);
  };
  const handleCheckboxChange = event => {
    setKelseyOwned(event.target.checked);
  };

  const handleGPTChange = event => {
    setGPTEnabled(event.target.checked);
  };
  const settingsListData = useSelector(state => state.SelfServe.settingsList);
  const toast = useSelector(state => state.Toast);
  const selfServe = useSelector(state => state.SelfServe);
  const phoneNumbers = useSelector(state => state.Admin?.availableNumbers);
  const clientName = selfServe?.userData?.data?.name;
  const clientPhoneNumber = selfServe?.userData?.data?.phone;

  const restrictCommunication =
    selfServe?.userData?.data?.restrictCommunication;
  const isGPTEnabled = selfServe?.userData?.data?.isGPTEnable;
  const [kelseyOwned, setKelseyOwned] = useState(
    restrictCommunication?.kelsey || false
  );
  const [gptEnabled, setGPTEnabled] = useState(isGPTEnabled || false);

  useEffect(() => {
    setGPTEnabled(isGPTEnabled);
  }, [isGPTEnabled]);
  useEffect(() => {
    setKelseyOwned(restrictCommunication?.kelsey);
  }, [restrictCommunication]);

  const adminData = useSelector(state => state.Admin);
  const callTenantApi = () => {
    if (adminData?.users?.data) {
      const params = tenantId || adminData.users?.data?.[0]?.tenantId?.[0];
      dispatch(getTenantDetails(params));
      setDeletePhone('phoneDeleted');
      setPopup(true);
    }
  };

  useEffect(() => {
    if (adminData?.users?.data || tenantId) {
      dispatch(getTenantDetails(tenantId));
    }
  }, [adminData?.users, tenantId]);

  useEffect(() => {
    const UPDATE_SETTINGS_LIST = SETTINGS_DATA.map(item => {
      item.checked = settingsListData[item.key];
      return item;
    });
    setSettingsList(UPDATE_SETTINGS_LIST);
  }, [settingsListData]);
  const channelData = useSelector(state => state?.SelfServe?.channelData);
  const { actions, showSuccess } = useSelector(state => state.SelfServe);

  useEffect(() => {
    setOpen(showSuccess);
    setTimeout(() => dispatch(hideSuccessMessage), 10000);
  }, [showSuccess]);
  useEffect(() => {
    const params = tenantId ? tenantId : '';

    dispatch(getInternalSettings(params));
    dispatch(getChannelAccountDetails(params));
  }, []);
  const [channelEmail, setChannelEmail] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [emailChecked, setEmailChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(!!clientPhoneNumber);
  const [botStatus, setBotStatus] = useState(settingsListData?.enableBot);
  const [dropDown, showDropDown] = useState(false);
  const [status, setStatus] = useState('');
  useEffect(() => {
    setBotStatus(settingsListData?.enableBot);
  }, [settingsListData]);
  useEffect(() => {
    if (channelData?.data?.[0]?.configEmail?.email) {
      setChannelEmail(channelData?.data?.[0]?.configEmail?.email);
    }
    if (channelData?.data?.configEmail?.email) {
      setChannelEmail(channelData?.data?.configEmail?.email);
      setEmailChecked(true);
    }
    if (
      !!channelData?.data?.length > 0 &&
      channelData?.data?.[0]?.incomingEnabled &&
      channelData?.data?.[0]?.outgoingEnabled
    ) {
      setEmailChecked(true);
    }
  }, [channelData, dispatch]);

  const handleSettingsSave = () => {
    let dataObj = {};
    settingsList.map(item => {
      dataObj[item.key] = item.checked;
    });
    dataObj.enableBot = botStatus;
    if (tenantId) {
      dataObj.tenantId = tenantId;
    }
    dispatch(
      updateRestrictCommunication({
        tenantId: tenant_id,
        restrictCommunication: {
          kelsey: kelseyOwned,
          houseAccount: false,
          knockAgent: false
        },
        isGPTEnable: gptEnabled
      })
    );
    dispatch(createInternalSettings({ payload: dataObj }));
  };
  useEffect(() => {
    if (tenantData?.nonOwnerKelsey) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const selectPhn = val => {
    setPhoneNum(val);
    setAreaCode(val);
    showDropDown(false);
  };

  const [signatureOpen, setSignatureModalOpen] = useState(false);
  const [confirmPurchase, setConfirmPurchase] = useState(false);

  const handleClose = () => {
    setPhoneOpen(!phoneOpen);
    setStatus('');
    setConfirmPurchase(false);
  };

  const handleChannelAccountEmail = e => {
    dispatch(
      channelAccountSettings({
        email: channelEmail + '@communications.getzuma.com',
        outgoingEnabled: emailChecked,
        incomingEnabled: emailChecked,
        tenantId
      })
    );
  };

  const handlePhoneNumberSave = () => {
    let obj = {
      tenantName: clientName,
      phone: phoneNum,
      tenantId
    };
    dispatch(ConfigureTenent(obj));
  };

  const handleDeleteChannelAccountEmail = () => {
    if (deletePhone === 'phoneDelete') {
      const payload = {
        tenantName: clientName
      };
      setPopup(false);
      setAreaCode('');
      dispatch(deletePhoneNumberApi({ payload, callTenantApi }));
      return;
    }
    if (deletePhone === 'phoneAdd') {
      setPopup(false);
      handlePhoneNumberSave();
      return;
    }
    dispatch(deleteChannelAccount({ email: channelEmail }));
    setPopup(false);
    setChannelEmail('');
    setEmailChecked(false);
  };

  const handleChannelAccountEmailInput = e => {
    setChannelEmail(e.target.value);
  };

  const handleBot = e => {
    setBotStatus(e.target.checked);
  };

  const handleEmailToggle = e => {
    setEmailChecked(e.target.checked);
    if (tenantData?.channelData?.data?.length > 0) {
      let payload = {
        outgoingEnabled: e.target.checked,
        incomingEnabled: e.target.checked,
        tenantId
      };
      return dispatch(updateChannelAccountEmailStatus({ payload }));
    }
  };

  const handlePhone = e => {
    setPhoneNumber(prev => {
      const updatedSettings = settingsList.map((settingsItem, i) => {
        if (settingsItem.key === 'phoneNo') {
          settingsItem.checked = e.target.checked;
        }
        return settingsItem;
      });
      setSettingsList(updatedSettings);
      return !prev;
    });
  };
  const AreaCodeFunc = e => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length === 3) {
      dispatch(getAvailableNumber({ number: onlyNums, tenantId }));
    }
    if (onlyNums.length <= 3) {
      setAreaCode(onlyNums);
    }
  };

  const deletePhonePopup = () => {
    setDeletePhone('phoneDelete');
    setPopup(prev => !prev);
  };

  const handleDeletePop = () => {
    setDeletePhone('emailDelete');
    setPopup(true);
  };

  const phoneAddPopup = () => {
    setDeletePhone('phoneAdd');
    setPopup(true);
  };

  useEffect(() => {
    if (clientPhoneNumber) {
      setAreaCode(clientPhoneNumber);
    }
  }, [clientPhoneNumber]);
  const closeNotification = () => {
    dispatch(ToastClearAction());
  };

  const [phoneOpen, setPhoneOpen] = useState(false);
  const handleCommunityPhone = () => {
    setPhoneOpen(!open);
  };
  return (
    <div className="bg-white mt-20 mr-20 br-4 p-20 selfServeSettings">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={'success'}>{'Data saved successfully'}</Alert>
      </Snackbar>
      <EmailDeletionPopup
        handleSave={handleDeleteChannelAccountEmail}
        open={popup}
        deletePhone={deletePhone}
        data={popUpStates[deletePhone]}
        warningText={
          deletePhone
            ? 'Phoneno.  deletion warning!'
            : 'Email Deletion Warning!'
        }
        setOpen={setPopup}
      />
      <div className="flex">
        <div className="flex p-10 checkbox-container">
          <FormControlLabel
            control={
              <ZSwitch
                id="bot_status"
                checked={botStatus}
                onChange={handleBot}
              />
            }
            label="Bot Settings"
            labelPlacement="start"
            className="switch-settings-label"
          />
        </div>
        <div
          style={{ width: '52%', justifyContent: 'flex-start' }}
          className="flex checkbox-container p-10"
        >
          <FormControlLabel
            control={
              <ZSwitch
                id="email_setting"
                checked={emailChecked}
                onChange={handleEmailToggle}
              />
            }
            label="Email"
            labelPlacement="start"
            className="switch-settings-label"
          />
          <div
            className="flex inter-500-16px"
            style={{ marginLeft: '20px', flexGrow: 0.6 }}
          >
            {emailChecked ? (
              channelData?.data?.[0]?.configEmail?.email ||
              channelData?.data?.configEmail?.email ? (
                <div style={{ width: '350px' }}>{channelEmail}</div>
              ) : (
                <>
                  <TextField
                    placeholder="Enter email here"
                    sx={{ width: '150px' }}
                    value={channelEmail}
                    onChange={handleChannelAccountEmailInput}
                  />
                  @communications.getzuma.com
                </>
              )
            ) : null}
            <div className="actionCont">
              {emailChecked ? (
                channelData?.data?.[0]?.configEmail?.email ||
                channelData?.data?.configEmail?.email ? (
                  <DeleteIcon onClick={() => handleDeletePop()} />
                ) : (
                  <>
                    <SaveIcon
                      style={{ marginLeft: '20px' }}
                      onClick={handleChannelAccountEmail}
                    />
                    <CrossIcon
                      style={{ marginLeft: '20px' }}
                      onClick={() => {
                        setChannelEmail('');
                      }}
                    />
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex checkbox-container w-30 padding-10 phoneContainer">
          <FormControlLabel
            control={
              <ZSwitch
                id="phone_setting"
                checked={phoneNumber}
                onChange={handlePhone}
              />
            }
            label="Phone"
            labelPlacement="start"
            className="switch-settings-label"
          />
          {phoneNumber && (
            <div
              onMouseOver={() => showDropDown(true)}
              className="inputContainer"
            >
              <TextField
                placeholder="Enter Phone here"
                sx={{ width: '150px' }}
                disabled={clientPhoneNumber}
                value={areaCode}
                onChange={AreaCodeFunc}
              />
              <div className="phoneNumberCont">
                {dropDown &&
                  phoneNumbers &&
                  phoneNumbers.data.map(res => {
                    return (
                      <div
                        className="numbers"
                        onClick={() => selectPhn(res.phone)}
                      >
                        {formatPhoneNumber(res.phone)}
                      </div>
                    );
                  })}
              </div>
              <div className="actionCont">
                {clientPhoneNumber ? (
                  <DeleteIcon onClick={() => deletePhonePopup()} />
                ) : (
                  <>
                    <SaveIcon onClick={phoneAddPopup} />{' '}
                    <CrossIcon
                      style={{ marginLeft: '20px' }}
                      onClick={() => {
                        setAreaCode('');
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
      >
        <p
          style={{
            fontFamily: 'Inter, sans-serif',
            margin: 0,
            color: '#42526E',
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: 500
          }}
        >
          Miscellaneous Settings
        </p>
        <div>
          <div className="flex mt-20">
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px !important',
                  fontWeight: 500,
                  lineHeight: '17px',
                  letterSpacing: '0.04em',
                  color: '#393939'
                }
              }}
              onChange={handleCheckboxChange}
              control={<Checkbox checked={kelseyOwned} />}
              label={label}
            />
          </div>
          <div className="flex mt-20">
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px !important',
                  fontWeight: 500,
                  lineHeight: '17px',
                  letterSpacing: '0.04em',
                  color: '#393939'
                }
              }}
              onChange={handleGPTChange}
              control={<Checkbox checked={gptEnabled} />}
              label={gptLabel}
            />
          </div>
          <div
            style={{
              width: 'fit-content',
              padding: '5px',
              height: '36px',
              backgroundColor: 'rgba(82, 103, 255, 0.1)',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              letterSpacing: '0.04em',
              color: '#42526E',
              alignItems: 'center',
              borderRadius: '4px',
              marginTop: '20px',
              display: 'flex'
            }}
          >
            Community specific phone numbers
            <SettingsIcon
              style={{ paddingLeft: '5px', cursor: 'pointer' }}
              onClick={handleCommunityPhone}
            />
          </div>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className="container-title">Customer Information</p>
          <p className="container-desc ">
            (Select the settings that you want as a mandatory data from user)
          </p>
        </div>
        <Grid container spacing={2}>
          {settingsList.map((item, i) => {
            return (
              <Grid style={{ paddingLeft: 0 }} item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={item.checked}
                      name={item.name}
                      onChange={(e) => handleChange(e, item, i)}
                    />
                  }
                  label={item.label}
                  labelPlacement="start"
                  className="switch-label"
                />
              </Grid>
            );
          })}
        </Grid> */}
        <CommunityPhoneModel
          handleClose={handleClose}
          open={phoneOpen}
          tenantId={tenantId}
          status={status}
          setStatus={setStatus}
          confirmPurchase={confirmPurchase}
          setConfirmPurchase={setConfirmPurchase}
        />
      </div>
      <div className="mt-40">
        <Button
          onClick={handleSettingsSave}
          className="save-btn"
          variant="contained"
        >
          Save
        </Button>
        <Button className="clear-btn" variant="text">
          Clear
        </Button>
      </div>
      <Snackbar
        autoHideDuration={4000}
        open={toast.toast}
        onClose={closeNotification}
        message={'Phone number Saved Successfully'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </div>
  );
};

export default Settings;
