import { gql } from '@apollo/client';

export const fetchcommentsByLeadId = gql`
  query commentsByLeadId($leadId: ID!) {
    commentsByLeadId(leadId: $leadId) {
      message
      date
      _id
    }
  }
`;
