import React from 'react';
import { Label, Radio, Wrapper } from './radio.styles';

const RadioButton = ({
  id = 'radio-button',
  label = 'Radio label',
  key = null,
  checked = false,
  value = null,
  onChange,
  name,
  labelFontSize = '1rem'
}) => (
  <Wrapper key={key}>
    <Radio
      type="radio"
      id={id}
      checked={checked}
      value={value}
      onChange={onChange}
      name={name}
    />
    <Label htmlFor={id} labelFontSize={labelFontSize}>
      {label}
    </Label>
  </Wrapper>
);

export default RadioButton;
