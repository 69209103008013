import {
  GET_ALL_EVENTS,
  ATTACH_CONNECTED_ACCOUNTS,
  GET_SETTING_DATA
} from '../Constant';

const initialState = {
  Allevents: ' ',
  accounts: {},
  calendarSetting: '',
  settingsAvl: false
};
const Calendar = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return { ...state, Allevents: action.data };
    case ATTACH_CONNECTED_ACCOUNTS:
      return {
        ...state,
        accounts: action.data
      };
    case GET_SETTING_DATA:
      return {
        ...state,
        calendarSetting: action.data,
        settingsAvl: true
      };

    default:
      return state;
  }
};

export default Calendar;
