import React from 'react';

const NotesWithoutDataIcon = ({ onClick, isZumaTour }) => {
  return (
    <svg
      onClick={onClick}
      style={{
        cursor: 'pointer',
        marginTop: '-1px',
        marginLeft: isZumaTour === true ? '0px' : '30px'
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M14.6667 4H5.33333C4.598 4 4 4.598 4 5.33333V14.6667C4 15.402 4.598 16 5.33333 16H10.6667C10.7542 16.0001 10.841 15.9829 10.9218 15.9494C11.0027 15.9159 11.0762 15.8667 11.138 15.8047L15.8047 11.138C15.8595 11.0812 15.9037 11.015 15.9353 10.9427C15.9447 10.9227 15.95 10.902 15.9573 10.8807C15.9763 10.8249 15.9878 10.7668 15.9913 10.708C15.9927 10.694 16 10.6807 16 10.6667V5.33333C16 4.598 15.402 4 14.6667 4ZM5.33333 5.33333H14.6667V10H10.6667C10.4899 10 10.3203 10.0702 10.1953 10.1953C10.0702 10.3203 10 10.4899 10 10.6667V14.6667H5.33333V5.33333ZM11.3333 13.724V11.3333H13.724L11.3333 13.724Z"
          fill="#42526E"
        />
      </g>
    </svg>
  );
};

export default NotesWithoutDataIcon;
