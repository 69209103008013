//Filter.js

export const CardStyle = {
  width: '300px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  marginRight: '20px',
  position: 'fixed',
  zIndex: '11',
  height: '100%'
};
export const rowOneStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '14px'
};
export const filterByStyle = {
  color: '#20065f',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 700
};
export const TextFelidStyle = {
  marginBottom: '18px'
};
export const resetStyle = {
  width: '120px',
  height: '40px',
  borderRadius: '8px',
  border: '1px solid #20065f',
  backgroundColor: '#ffffff',
  marginRight: '20px'
};
export const applyStyle = {
  width: '120px',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: '#20065f',
  color: '#FFF'
};
