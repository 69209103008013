import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import ZSwitch from '../../globalcomponents/ZSwitch';
import { GlobalContext } from '../../utils/ContextProviders/GlobalContextProvider';

const AFMSettings = props => {
  const { selectedLeadId, followupToggle, leadUpdateMutate } = props;

  const { darkMode } = useContext(GlobalContext);
  const handleChange = (field, value) => {
    leadUpdateMutate({
      variables: {
        leadId: selectedLeadId,
        record: {
          [field]: value
        }
      }
    });
  };

  return (
    <div
      className="right-side-cards inter-500px-14px"
      style={{ position: 'relative', background: darkMode ? '#1D202D' : '' }}
    >
      <div className="flex" style={{ margin: 0 }}>
        <Typography
          style={{ color: darkMode ? '#fff' : '' }}
          className="card-title"
        >
          Automated Follow Up
        </Typography>
        <ZSwitch
          id="afm_setting"
          checked={followupToggle}
          onChange={e => {
            handleChange('followupToggle', e.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default AFMSettings;
