import { GET_AVAILABLE_NUMBER } from '../Constant';
import { get, post, put } from '../networkClient';
import { ToastFireAction } from './Toast';

const GetAvailNumber = data => ({
  type: GET_AVAILABLE_NUMBER,
  data: data
});

export const getAvailableNumber =
  ({ number, tenantId }) =>
  async dispatch => {
    await post(`/tenant/phones`, { code: `${number}`, tenantId }).then(res => {
      dispatch(GetAvailNumber(res.data));
    });
  };

export const ConfigureTenent = data => async dispatch => {
  await put('/tenant', data).then(res => {
    if (res.status === 200) {
      dispatch(ToastFireAction(true, 'success', 'Step Successfully Completed'));
    }
  });
};
