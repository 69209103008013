import React from 'react';
import {
  InputLabelWrapper,
  LabelRow,
  ValueRow
} from './InputLabelComponent.styles';

const InputLabelComponent = ({
  label,
  value,
  setValue,
  isRequired,
  marginTop = '0px',
  isDeleteAble = false
}) => {
  return (
    <InputLabelWrapper marginTop={marginTop}>
      <LabelRow>
        {label}
        {isRequired ? <span style={{ color: '#D83A52' }}>*</span> : null}
      </LabelRow>
      <ValueRow
        isDeleteAble={isDeleteAble}
        onChange={e => setValue(e.target.value)}
        placeholder="Enter Here"
        value={value ?? ''}
      ></ValueRow>
    </InputLabelWrapper>
  );
};

export default InputLabelComponent;
