import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Other from '../../Media/AddPropertyIcons/GeneralAmenities/Other.png';
import {
  HeadingGAStyle,
  ContainerGA
} from '../../styles/propertystyles/Otherstyle';

const GeneralAmenities = props => {
  const SwitchTabs = (res, handle, generalAmneties) => {
    return (
      <Grid
        item
        xs={3}
        key={`${Math.floor(Math.random() * 1000)}-min`}
        style={ContainerGA}
      >
        <Switch
          checked={
            generalAmneties &&
            generalAmneties.length > 0 &&
            generalAmneties.find(
              generalAmneties => generalAmneties.name === res.name
            )
          }
          onChange={event => handle(event, res._id)}
          name={res.name}
          color="primary"
        />
        <span style={{ marginRight: '40px' }}>
          <img
            src={res.url ? res.url : Other}
            style={{ position: 'absolute' }}
            alt="AirConIcon"
          />
        </span>
        <span style={HeadingGAStyle}>{res.name}</span>
      </Grid>
    );
  };
  return (
    <>
      <Grid container key={`${Math.floor(Math.random() * 1000)}-min`}>
        {props.data &&
          props.data.map(res => {
            return SwitchTabs(
              res,
              props.handlegeneralAmneties,
              props.generalAmneties
            );
          })}
      </Grid>
    </>
  );
};

export default GeneralAmenities;
