import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  textfield: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: 56
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: '#3f51b5'
    }
  }
}));
