import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  filterToggleAction,
  GetFilteredPropertyAction
} from '../../Redux/Actions/Filter';
import { getAllPropertyAction } from '../../Redux/Actions/Property';
import Button from '@material-ui/core/Button';
import {
  CardStyle,
  rowOneStyle,
  filterByStyle,
  TextFelidStyle,
  resetStyle,
  applyStyle
} from '../../styles/Filterstyle';

const Filter = props => {
  const dispatch = useDispatch();
  const [name, setname] = useState('');
  const [unitname, setunitname] = useState('');

  const FilterAction = () => {
    dispatch(filterToggleAction(false));
  };

  const getFilterData = () => {
    let obj = {};
    obj.name = name;
    obj.UnitName = unitname;
    dispatch(GetFilteredPropertyAction(obj));
  };

  const resetFilter = () => {
    dispatch(getAllPropertyAction());
    setname('');
    setunitname('');
  };
  return (
    <Card style={CardStyle}>
      <CardContent>
        <div style={rowOneStyle}>
          <div style={filterByStyle}>Filter by</div>
          <div>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => FilterAction()}
            />
          </div>
        </div>
        <div style={TextFelidStyle}>
          <TextField
            id="PropertyName"
            label="Property Name"
            variant="outlined"
            value={name}
            onChange={event => setname(event.target.value)}
            fullWidth
          />
        </div>
        {props.data.pathname === '/property' && (
          <div style={TextFelidStyle}>
            <TextField
              id="UnitName"
              label="Property ID / Unit Name"
              variant="outlined"
              value={unitname}
              onChange={event => setunitname(event.target.value)}
              fullWidth
            />
          </div>
        )}

        {props.data.pathname === '/leads' && (
          <>
            <div style={TextFelidStyle}>
              <TextField
                id="LeadName"
                label="Lead Name"
                variant="outlined"
                fullWidth
              />
            </div>
            <div style={TextFelidStyle}>
              <TextField
                id="PhoneNumber"
                label="Phone Number"
                variant="outlined"
                fullWidth
              />
            </div>
            <div style={TextFelidStyle}>
              <TextField
                id="LeadSource"
                label="Lead Source"
                variant="outlined"
                fullWidth
              />
            </div>
          </>
        )}

        {/* <div style={TextFelidStyle}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Craigslist Reminder
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Craigslist Reminder"
            >
              <MenuItem value={0}>No Reminder</MenuItem>
              <MenuItem value={1}>Next One Day</MenuItem>
              <MenuItem value={2}>Next Two Day</MenuItem>
              <MenuItem value={3}>Next Three Day</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div>
          <Button
            variant="outlined"
            style={resetStyle}
            onClick={() => resetFilter()}
          >
            RESET
          </Button>
          <Button
            variant="contained"
            disableElevation
            style={applyStyle}
            onClick={() => getFilterData()}
          >
            APPLY
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default Filter;
