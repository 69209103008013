import React from 'react';
import { Fields } from '../InfoContainer/info-container-styles';
import Field from '../../Field';

const UnitAssignmentInfo = ({ leadData, propertyData }) => {
  const { name } = leadData?.apartment || {};
  const selectedProperty = propertyData?.find(
    ({ _id }) => _id === leadData?.preference?.unit
  );

  return (
    <Fields>
      <Field
        isRequired
        label="Community"
        value={name}
        redirectUrl={leadData?.website}
      />
      <Field
        isRequired
        label="Preferred unit"
        value={
          selectedProperty
            ? `${selectedProperty?.unitName} - $${
                selectedProperty?.leaseData?.bestRent ??
                selectedProperty?.rent?.minRent
              }`
            : 'N/A'
        }
      />
    </Fields>
  );
};

export default UnitAssignmentInfo;
