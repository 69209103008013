import React from 'react';

const SnackBarClose = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className="close-Snackbar"
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="6" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM16.4996 15.3242C16.4995 15.0124 16.3755 14.7134 16.155 14.493L13.662 12L16.155 9.507C16.2641 9.39788 16.3507 9.26832 16.4098 9.12574C16.4688 8.98316 16.4992 8.83034 16.4992 8.676C16.4992 8.52167 16.4688 8.36885 16.4098 8.22627C16.3507 8.08369 16.2641 7.95413 16.155 7.845C16.0459 7.73588 15.9163 7.64931 15.7737 7.59025C15.6312 7.53119 15.4783 7.50079 15.324 7.50079C15.1697 7.50079 15.0169 7.53119 14.8743 7.59025C14.7317 7.64931 14.6021 7.73588 14.493 7.845L12 10.338L9.506 7.845C9.28299 7.63776 8.98831 7.52507 8.68392 7.53065C8.37953 7.53622 8.08916 7.65962 7.87389 7.87489C7.65862 8.09016 7.53522 8.38053 7.52965 8.68492C7.52407 8.98931 7.63676 9.284 7.844 9.507L10.337 12L7.844 14.493C7.6235 14.7135 7.49962 15.0126 7.49962 15.3245C7.49962 15.6364 7.6235 15.9355 7.844 16.156C7.95292 16.2655 8.0824 16.3524 8.22501 16.4117C8.36763 16.471 8.52055 16.5016 8.675 16.5016C8.82945 16.5016 8.98238 16.471 9.12499 16.4117C9.26761 16.3524 9.39709 16.2655 9.506 16.156L12 13.662L14.493 16.156C14.7135 16.3764 15.0126 16.5002 15.3244 16.5001C15.6361 16.5 15.9351 16.376 16.1555 16.1555C16.3759 15.935 16.4997 15.6359 16.4996 15.3242Z"
        fill="#42526E"
      />
    </svg>
  );
};

export default SnackBarClose;
