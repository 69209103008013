import React from 'react';
import Modal from '@material-ui/core/Modal';
import './style.scss';

const CustomModal = ({ children, open, onClose, classes }) => {
  return (
    <Modal onClose={onClose} open={open} className={'customPopup '}>
      <div className={'customModalCont ' + classes}>{children}</div>
    </Modal>
  );
};

export default CustomModal;
