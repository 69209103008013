import React from 'react';

const CopyIcon = ({ onClick, style }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      onClick={onClick}
      style={style}
    >
      <g opacity="0.8">
        <rect width="16" height="16" fill="white" fill-opacity="0.01" />
        <path
          d="M6.66683 12.6667H12.0002V5.33333H6.66683V12.6667ZM5.3335 5.328C5.3335 4.59467 5.93483 4 6.67283 4H11.9942C12.7342 4 13.3335 4.59533 13.3335 5.328V12.672C13.3335 13.4053 12.7322 14 11.9942 14H6.6735C6.49831 14.0008 6.32469 13.9671 6.16254 13.9008C6.00039 13.8344 5.85289 13.7368 5.72845 13.6135C5.60402 13.4902 5.5051 13.3436 5.43733 13.182C5.36957 13.0205 5.33428 12.8472 5.3335 12.672V5.328Z"
          fill="#42526E"
        />
        <path
          d="M3.3335 10.6667V3.328C3.3335 2.59467 3.93483 2 4.67283 2H10.0002V10.6667H3.3335ZM4.66683 10.6667H10.0002V3.33333H4.66683V10.6667Z"
          fill="#42526E"
        />
      </g>
    </svg>
  );
};

export default CopyIcon;
