import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

function PropertyLoader() {
  const cardStyle = {
    width: '291px',
    height: '322px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    cursor: 'pointer',
    borderRadius: '8px'
  };
  return (
    <Card style={cardStyle}>
      <CardContent style={{ padding: '92px' }}>
        <CircularProgress size={100} />
      </CardContent>
    </Card>
  );
}

export default PropertyLoader;
