import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card,
  CardContent,
  InputLabel,
  TextField,
  Collapse
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TimePicker from 'react-time-picker';
import ZMuiSelect from '../../../Component/Common/ZMuiSelect';
import { CALL_TYPES } from '../../../Component/Constants/LeadStages';
import CustomModal from '../../../Component/Modal';
import { setGlobalError } from '../../../Redux/Reducer/GlobalError';
import { GlobalContext } from '../../../utils/ContextProviders/GlobalContextProvider';

const NotesPopup = props => {
  const {
    open,
    setOpen,
    selectedLeadId,
    newNoteInsertionInProgress,
    handleNoteCreation,
    handleCallLogCreation
  } = props;

  const [notes, setNotes] = useState('');
  const [phone, setPhone] = useState('');
  const [duration, setDuration] = useState('');
  const [noteType, setNoteType] = useState('notes');
  const [callType, setCallType] = useState(null);
  const [collapseIn, setCollapseIn] = useState(false);
  const handleSaveNotes = () => {
    const notesPayload = {
      leadId: selectedLeadId,
      message: { notes },
      date: new Date()
    };
    handleNoteCreation(notesPayload);
    setOpen(false);
    resetData();
  };
  const dispatch = useDispatch();
  const { userData } = useContext(GlobalContext);
  const { mightyCall } = userData;
  const handleSaveCallLog = () => {
    const [hours = 0, minutes = 0, seconds = 1] = duration?.split(':') ?? [];
    const subtractDuration = moment.duration({ hours, minutes, seconds });
    const sTime = moment().subtract(subtractDuration).format();
    const eTime = moment().format();

    if (!callType) {
      dispatch(setGlobalError(`Prospect's response is required!`));
      return;
    }
    if (!phone?.includes('+1') && phone?.length !== 10) {
      dispatch(setGlobalError(`Phone should be of 10 digits!`));
      return;
    }

    const details = {
      phone: phone.includes('+1') ? phone : `+1${phone}`,
      startTime: sTime,
      endTime: eTime,
      outcome: callType
    };
    const callLogPayload = {
      leadId: selectedLeadId,
      message: { notes: notes !== '' ? notes : 'NA', details },
      date: new Date()
    };
    handleCallLogCreation(callLogPayload);
    setOpen(false);
    resetData();
  };

  const resetData = () => {
    setNotes('');
    setPhone(mightyCall);
    setDuration('');
    setNoteType('notes');
    setCallType('');
  };

  const handleNoteTypes = e => {
    setNoteType(e);
    setNotes('');
    setCallType('');
  };

  useEffect(() => {
    if (!newNoteInsertionInProgress) {
      setOpen(false);
    }
    setPhone(mightyCall);
  }, [newNoteInsertionInProgress, mightyCall]);

  useEffect(() => {
    if (callType === 'ANSWERED' || callType === 'HUNG_UP') {
      setCollapseIn(true);
    } else {
      setCollapseIn(false);
    }
  }, [callType]);
  return (
    <CustomModal
      open={open}
      classes={'notes-transition'}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card>
        <CardContent>
          <div className="inter-500px-16px flex-start">
            <span className="notes-header">Add</span>
            <div className="d-flex-row notes-popup-border cursor-pointer ">
              <div
                className={`${noteType === 'notes' ? 'selected' : ''}`}
                value="notes"
                onClick={() => handleNoteTypes('notes')}
              >
                Notes
              </div>
              <div
                className={`${noteType === 'call' ? 'selected' : ''}`}
                value="call"
                onClick={() => handleNoteTypes('call')}
              >
                Call Log
              </div>
            </div>
            <>
              <CloseIcon
                sx={{
                  height: '20px',
                  position: 'absolute',
                  right: '15px',
                  top: '20px',
                  cursor: 'pointer'
                }}
                onClick={() => setOpen(false)}
              />
            </>
          </div>
          <div>
            {noteType === 'call' ? (
              <div style={{ marginTop: '15px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px'
                  }}
                >
                  <InputLabel
                    style={{ marginBottom: '8px', fontWeight: '500' }}
                    required={true}
                  >
                    Phone Number
                  </InputLabel>
                  <TextField
                    inputProps={{
                      style: {
                        height: '36px'
                      }
                    }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter here"
                    defaultValue={mightyCall}
                    onChange={event => {
                      const { value } = event.target;
                      let onlyNums = value.trim();
                      if (onlyNums.startsWith('+1')) {
                        onlyNums = onlyNums.replace('+1', '');
                      }
                      onlyNums = onlyNums.replace(/[^0-9]/g, '');
                      if (onlyNums.length <= 10) {
                        setPhone(onlyNums);
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px'
                  }}
                >
                  <InputLabel
                    style={{ marginBottom: '8px', fontWeight: '500' }}
                    required={true}
                  >
                    Prospect's Response
                  </InputLabel>
                  <ZMuiSelect
                    value={callType}
                    options={CALL_TYPES}
                    variant="outlined"
                    handleChange={(e, value) => setCallType(value)}
                  />
                </div>
                {(callType === 'ANSWERED' || callType === 'HUNG_UP') && (
                  <Collapse
                    in={collapseIn}
                    timeout={{
                      enter: 500,
                      exit: 10
                    }}
                    unmountOnExit
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                      >
                        <InputLabel
                          style={{ marginBottom: '8px', fontWeight: '500' }}
                          required={true}
                        >
                          Duration
                        </InputLabel>
                        <TimePicker
                          style={{ borderRadius: '2px' }}
                          format="mm:ss"
                          disableClock={true}
                          onChange={time => setDuration(time)}
                          clearIcon={null}
                          minutePlaceholder="mm"
                          secondPlaceholder="ss"
                          maxDetail="second"
                          className={'time-picker-class'}
                        />
                      </div>
                    </div>
                  </Collapse>
                )}
              </div>
            ) : null}
            <InputLabel
              style={{
                marginBottom: '8px',
                marginTop: '15px',
                fontWeight: '500'
              }}
            >
              Note (Optional)
            </InputLabel>
            <TextField
              id="outlined-textarea"
              multiline
              placeholder="NA"
              variant="outlined"
              rows={noteType === 'call' ? 1 : 8}
              fullWidth
              sx={{
                '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
                  height: '192px',
                  padding: '0px'
                }
              }}
              value={notes}
              onChange={event => setNotes(event.target.value)}
            />
          </div>
          <div style={{ padding: '20px 0px 0px 1px' }} className="d-flex-row">
            <Button
              variant="contained"
              className="save-btn mr-0"
              onClick={() =>
                noteType === 'call' ? handleSaveCallLog() : handleSaveNotes()
              }
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="clear-btn"
              onClick={() => resetData()}
            >
              Clear
            </Button>
          </div>
        </CardContent>
      </Card>
    </CustomModal>
  );
};

export default NotesPopup;
