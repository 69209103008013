import { GET_ALL_UTILITY } from '../Constant';

const initialState = {
  UtilityData: ' '
};
const Utility = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_UTILITY:
      return { ...state, UtilityData: action.data };
    default:
      return state;
  }
};

export default Utility;
